import React, { lazy } from "react";

const Dashboard = lazy(() => import("../../containers/fbm/dashboard"));
const OrderFulfillment = lazy(() =>
  import("../../containers/fbm/orderFulfillment")
);
const Orders = lazy(() => import("../../containers/fbm/orders"));
const Settings = lazy(() => import("../../containers/fbm/settings"));
const FbmWallet = lazy(() => import("../../containers/fbmWalletContainer"));
const Lister = lazy(() => import("../../containers/fbm/lister"));

export const FBM_ROUTES = [
  {
    path: "/fbm",
    component: <Dashboard />,
    group: "FBM",
    reducer: [],
  },
  {
    path: "/fbm/orders",
    component: <Orders />,
    group: "FBM",
    reducer: "",
  },
  {
    path: "/fbm/orders/fulfillment",
    component: <OrderFulfillment />,
    group: "FBM",
    reducer: "",
  },
  {
    path: "/fbm/settings",
    component: <Settings />,
    group: "FBM",
    reducer: "",
  },
  {
    path: "/fbm/wallet",
    component: <FbmWallet />,
    group: "FBM",
    reducer: "",
  },
  {
    path: "/fbm/lister",
    component: <Lister />,
    group: "FBM",
    reducer: "",
  },
];
