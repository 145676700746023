import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom'
import Header from '../../header/header'
import { BiLeftArrowAlt } from "react-icons/bi";
import { StoreWrapper } from '../style'
import { isEmpty } from "lodash";
import Button from '../../uiComponent/button/index';
import Input from "../../uiComponent/inputs/labelInput/index"
import { ConnectWalmart, GetStores, setState, ReAuthorizeWalmartStore } from '../../../slices/store';
import WalmartImage1 from "../../../assets/images/newImages/walmart_image1.svg"
import WalmartImage2 from "../../../assets/images/newImages/walmart_iamge2.svg"
import clearRequestArray from '../../../utils/interseptors/pendingRequest';
import axios from '../../../../axios_config';
let controller = null;

const AmazonSuccess = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const { walmartLoading, stores, getStoreLoading, authorizingWalmartStore } = useSelector((state) => state?.store || {});

  const [value, setValue] = useState({
    clientId: '',
    secretKey: ''
  })
  const [isReAuthorize, setIsReAuthorize] = useState(false);

  const { clientId, secretKey } = value;

  useEffect(() => {
    controller = new AbortController();
    axios.defaults.signal = controller?.signal;
    dispatch(GetStores({ platformType: 'Walmart' })).then(({ payload }) => {
      const { stores } = payload || {};
      setIsReAuthorize(localStorage.getItem('re-authorize'));
      setValue({
        ...value,
        secretKey: stores[0]?.auth_token
      });
    });


    return () => {
      clearRequestArray();
      controller.abort();
      controller = null;
      axios.defaults.signal = null;
    };
  }, []);
 
  const handleConnectWalmart = async() => {
    const store = {
      seller_id: clientId,
      auth_token: secretKey,
      platform_type: 'Walmart'
    }
    const response = await dispatch(ConnectWalmart({ store }));
    if(!response.error) {
      dispatch(setState({ field: 'stores', value: []}));
      navigate("/onboarding/walmart-success");
    }
  } 

  const handleInputValues = (event, key) => {
    const data = event.target.value;
    setValue({
      ...value,
      [key]: data
    });
  }

  const handleWalmartDeveloper = () => {
    window.open('https://developer.walmart.com/', '_self');
  }

  const handleCredentialsUpdate = () => {
    const store = {};
    if (stores[0]?.seller_id !== clientId) store.seller_id = clientId;
    if (stores[0]?.auth_token !== secretKey) store.auth_token = secretKey;

    dispatch(ReAuthorizeWalmartStore({ store, id: stores[0]?.id })).then(({ payload }) => {
      const { data } = payload || {};
      const { success } = data || {};
      if (success) {
        dispatch(setState({ field: 'stores', value: []}));
        navigate('/onboarding/walmart-success');
      }
    });
  };

  const isStoreExist = !isEmpty(stores);
  const isDisable = walmartLoading || isEmpty(clientId) || isEmpty(secretKey) || isStoreExist;
  const selectAndNavigate = () => isStoreExist? navigate("/onboarding/select-plan"): navigate("/onboarding/store-add");

  return (
    <StoreWrapper>
      <Header login={true} />
      <div  className='change-background'>
      <div className="select-region">
        <div className="arrow-box grid-column-gap-10 mb-70">
          <BiLeftArrowAlt className="cursor-pointer" onClick={() => navigate(-1)} />
          <h1>Add Marketplace</h1>
        </div>
        <Row>
          <Col lg={1}></Col>
          <Col lg={5} md={6} sm={12}>
            <div className="mb-72">
              <h2 className="color-primary ">Step 1: Navigate to walmart Seller Central</h2>
              <div className="mt-16">
                <ul className="walmart-list">
                  <li>a) Login to Walmart Developer Portal</li>
                  <li>b) Look for the production keys in Solution Provider, First add Sales.Support then allow permissions.</li>
                  <li>c) Generate Production keys.</li>
                </ul>

              </div>
              <Button text="Go to Walmart Developer Portalcount" onClick = {handleWalmartDeveloper} className="w-283 primary" />
            </div>
            <div>
              <h2 className="color-primary">Step 2: Copy and Paste the Credentials</h2>
              <span className="color-secondary fs-16">Copy and paste the Client ID and Client Secret from the walmart developer center to the corresponding fields: </span>
              <Spin tip='Processing...' spinning={getStoreLoading? true: false}>
              <Row>
                <Col lg={9} md={10} sm={12}>
                  <div className="mt-4">
                    <Input
                      disabled={isStoreExist}
                      label="Client ID:"
                      placeholder="ABC123DE456F78"
                      value={isStoreExist ? stores[0]?.seller_id : clientId}
                      onChange={(e) => handleInputValues(e, 'clientId')} 
                    />
                    <Input
                      disabled={isReAuthorize ? false : isStoreExist}
                      label="Client Secret:" 
                      placeholder="amzn.mws.123e4567-e89b-12d3-a456-426655440000" 
                      value={(isStoreExist && !isReAuthorize) ? stores[0]?.auth_token : secretKey} 
                      onChange={(e) => handleInputValues(e, 'secretKey')} 
                    />
                  </div>
                  <div className="d-flex grid-column-gap-30">
                    {
                      isReAuthorize ?
                        <Button text={authorizingWalmartStore ? 'Updating...' : 'Update'} className="primary w-50" onClick={handleCredentialsUpdate} 
                          disabled={stores[0]?.auth_token === secretKey}
                        /> :
                        <>
                          <Button text={isStoreExist ? "Select Price Plan" : "Skip Now"} className="outlined w-100"
                            onClick={() => selectAndNavigate()}
                          />
                          <Button
                            text={walmartLoading ? "Connecting...." : (isStoreExist ? "Connected" : "Connect Now")}
                            className="primary w-100"
                            onClick={handleConnectWalmart}
                            disabled={isDisable}
                          />
                        </>
                    }
                  </div>
                </Col>
              </Row>
              </Spin>
            </div>
          </Col>
          <Col lg={1}></Col>
          <Col lg={3} md={6} sm={12}>
            <div className="mb-74">
              <img src={WalmartImage1} alt="no-marketplace" />
            </div>
            <div >
              <img src={WalmartImage2} alt="no-marketplace" />
            </div>
          </Col>
          <Col lg={2}></Col>
        </Row>
      </div>
    </div>
    </StoreWrapper>
  )
}

export default AmazonSuccess
