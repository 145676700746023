import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  userLocations: [],
  addToWorkOrder: {
    fetching: false
  },
  fetchingDimensions: false,
  fetchingLocations: false,
  creatingNewLocation: false
};

const removalDetails = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GET_USER_LOCATIONS_REQUEST':
    case 'DETAILS_SAVE_ITEM_FOR_STORAGE_REQUEST':
    case 'DISPOSE_ITEM_REQUEST':
    case 'PLACE_ORDER_FOR_FBA_REMOVAL_SHIPMENT_DETAILS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_USER_LOCATIONS_SUCCESS': {
      return {
        ...state,
        fetching: false,
        userLocations: action.payload
      };
    }
    case 'CLEAR_USER_LOCATIONS': {
      return {
        ...state,
        userLocations: []
      };
    }
    case 'DETAILS_SAVE_ITEM_FOR_STORAGE_SUCCESS':
    case 'DISPOSE_ITEM_SUCCESS':
    case 'PLACE_ORDER_FOR_FBA_REMOVAL_SHIPMENT_DETAILS_SUCCESS': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_USER_LOCATIONS_FAILED':
    case 'DETAILS_SAVE_ITEM_FOR_STORAGE_FAILED':
    case 'DISPOSE_ITEM_FAILED':
    case 'PLACE_ORDER_FOR_FBA_REMOVAL_SHIPMENT_DETAILS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_FBA_REMOVAL_SHIPMENT_DETAILS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_FBA_REMOVAL_SHIPMENT_DETAILS_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      };
    }
    case 'GET_FBA_REMOVAL_SHIPMENT_DETAILS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'UPLOAD_IMAGES_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'UPLOAD_IMAGES_SUCCESS': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'UPLOAD_IMAGES_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'UPDATE_SHIPMENT_FOR_REMOVAL_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'UPDATE_SHIPMENT_FOR_REMOVAL_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        fetching: false
      };
    }
    case 'UPDATE_SHIPMENT_FOR_REMOVAL_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'ADD_TO_WORK_ORDER_FOR_SHIMENT_REMOVAL_REQUEST': {
      return {
        ...state,
        addToWorkOrder: {
          ...state.addToWorkOrder,
          fetching: true
        }
      }
    }
    case 'ADD_TO_WORK_ORDER_FOR_SHIMENT_REMOVAL_SUCCESS':
    case 'ADD_TO_WORK_ORDER_FOR_SHIMENT_REMOVAL_FAILED': {
      return {
        ...state,
        addToWorkOrder: {
          ...state.addToWorkOrder,
          fetching: false
        }
      }
    }
    case 'FETCH_DIMENSIONS_REQUEST': {
      return {
        ...state,
        fetchingDimensions: true
      }
    }
    case 'FETCH_DIMENSIONS_SUCCESS':
    case 'FETCH_DIMENSIONS_FAILED': {
      return {
        ...state,
        fetchingDimensions: false
      }
    }
    case 'FETCH_LOCATIONS_REQUEST': {
      return {
        ...state,
        fetchingLocations: true
      }
    }
    case 'FETCH_LOCATIONS_SUCCESS':
    case 'FETCH_LOCATIONS_FAILED': {
      return {
        ...state,
        fetchingLocations: false
      }
    }
    case 'CREATE_NEW_LOCATION_REQUEST': {
      return {
        ...state,
        creatingNewLocation: true
      }
    }
    case 'CREATE_NEW_LOCATION_SUCCESS':
    case 'CREATE_NEW_LOCATION_FAILED': {
      return {
        ...state,
        creatingNewLocation: false
      }
    }
    default:
      return state;
  }
};

export default removalDetails;
