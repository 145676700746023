import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  fetchingForExport: false,
  inboundShipments: [],
  total: 0,
  filters: [],
  users: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  statuses: []
};

const shipments = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'EXPORT_SHIPMENTS_FOR_EXPIRY_DATES_REQUEST': {
      return {
        ...state,
        fetchingForExport: true
      };
    }
    case 'EXPORT_SHIPMENTS_FOR_EXPIRY_DATES_FAILED':
    case 'EXPORT_SHIPMENTS_FOR_EXPIRY_DATES_SUCCESS': {
      return {
        ...state,
        fetchingForExport: false
      };
    }
    case 'GET_USERS_FOR_SHIPMENTS_REQUEST': {
      return {
        ...state
      };
    }
    case 'GET_USERS_FOR_SHIPMENTS_SUCCESS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_USERS_FOR_SHIPMENTS_FAILED': {
      return {
        ...state
      };
    }
    case 'SET_FILTERS_SHIPMENTS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_SORT_OPTIONS_SHIPMENTS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_SHIPMENTS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_SHIPMENTS_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        fetching: false
      };
    }
    case 'GET_SHIPMENTS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_PAGINATION_SHIPMENTS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    case 'GET_SHIPMENTS_STATUS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_SHIPMENTS_STATUS_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        fetching: false
      };
    }
    case 'GET_SHIPMENTS_STATUS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    default:
      return state;
  }
};

export default shipments;
