import { cloneDeep } from 'lodash';
const inittialState = {
  fetching: false,
  fetchingApprovedCount: false,
  pagination: {
    page_num: 1,
    per_page: 25
  },
  statuses: [
    // {
    //   showName: 'Approved',
    //   value: 'APPROVED_BY_USER'
    // },
    // {
    //   showName: 'Disapproved',
    //   value: 'DISAPPROVED_BY_USER'
    // },
    {
      showName: 'Pending',
      value: 'PENDING'
    },
  ],
  search_term: '',
  applied_status: 'PENDING',
  approve_matches: [],
  total: 0,
  approved_matches_count: 0,
  suppliers: [],
  source_type: 'Amazon',
  listing_type: 'Walmart'
};


const appproveMatches = (state = cloneDeep(inittialState), action) => {

  switch (action.type) {

    case 'GETTING_APPROVE_MATCHES_REQUEST':
    case 'CHANGE_STATUS_FOR_MATCHES_REQUEST': {
      return {
        ...state,
        fetching: true
      }
    }

    case 'GETTING_APPROVE_MATCHES_SUCCESS':
    case 'GETTING_APPROVE_MATCHES_FAILED':
    case 'CHANGE_STATUS_FOR_MATCHES_SUCCESS':
    case 'CHANGE_STATUS_FOR_MATCHES_FAILED': {
      return {
        ...state,
        ...action.payload,
        fetching: false
      }
    }

    case 'SET_PAGE_NUMBER_FOR_APPROVE_MATCHES':
    case 'SET_PAGE_SIZE_FOR_APPROVE_MATCHES': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    case 'GET_APPROVED_MATCHES_COUNT_REQUEST': {
      return {
        ...state,
        fetchingApprovedCount: true
      }
    }
    case 'GET_APPROVED_MATCHES_COUNT_SUCCESS': {
      return {
        ...state,
        fetchingApprovedCount: false,
        ...action.payload
      }
    }
    case 'GET_APPROVED_MATCHES_COUNT_FAILED': {
      return {
        ...state,
        fetchingApprovedCount: false
      }
    }

    case 'SET_STATUS':
    case 'SET_KEYWORD_FILTER':
    case 'GETTING_SUPPLIERS_FOR_APPROVE_MATCHES_SUCCESS':
    case 'GETTING_SUPPLIERS_FOR_APPROVE_MATCHES_FAILED':
    case 'SET_SUPPLIER': {
      return {
        ...state,
        ...action.payload
      }
    }
    case 'REFRESHING_APPROVE_MATCHES_REQUEST': {
      return {
        ...state,
        fetching: true
      }
    }
    case 'REFRESHING_APPROVE_MATCHES_SUCCESS':
    case 'REFRESHING_APPROVE_MATCHES_FAILED': {
      return {
        ...state,
        fetching: false
      }
    }
    case 'RESET_FILTER' : {
      return {
        ...state,
        search_term: '',
        source_type: 'Amazon',
        listing_type: 'Walmart'
      }
    }

    default: {
      return {
        ...state
      }
    }
  }
}

export default appproveMatches;
