import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleRejectedResponse } from '../utils';
import { fetch as axios } from '../../../axios_config';
import { notification } from 'antd';

export const GetUsersList = createAsyncThunk(
  '/get-to-ship-users',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/get-users`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Users List', rejectWithValue);
    }
  }
);

export const GetAllShipmentPOs = createAsyncThunk(
  '/po-ship-details',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/po-ship-details`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          params: data
        },
      );
      return {
        ...response,
        data: { ...response.data, page: data.page }
      };
    } catch (err) {
      return handleRejectedResponse(err, 'Get All Shipment POs', rejectWithValue);
    }
  }
);

export const GetUserPoIndices = createAsyncThunk(
  '/user/:id/po-indices',
  async ({ user_id, filters }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/user/${user_id}/po-indices`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          params: { filters }
        },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get User PO Indices', rejectWithValue);
    }
  }
);

export const DeleteArrivedPo = createAsyncThunk(
  '/revert-arrived-po/:pending_po_id',
  async (pending_po_id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/revert-arrived-po/${pending_po_id}`,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Delete Po Check-In', rejectWithValue);
    }
  }
);

export const toShipInitialState = {
  shipmentPOs: [],
  fetchingShipmentPOs: false,
  totalCount: 0,
  userPoIndices: [],
  fetchingUserPoIndices: false,
  usersList: [],
  fetchingUsersList: false,
  deletingArrivedPo: false,
};

export const toShipReducers = {
  [GetUsersList.pending]: (state, action) => ({
    ...state,
    toShip: {
      ...state.toShip,
      usersList: [],
      fetchingUsersList: true
    }
  }),
  [GetUsersList.fulfilled]: (state, action) => {
    const { result } = action?.payload?.data || {};
    return {
      ...state,
      toShip: {
        ...state.toShip,
        usersList: result,
        fetchingUsersList: false,
      }
    }
  },
  [GetUsersList.rejected]: (state, action) => {
    return {
      ...state,
      toShip: {
        ...state.toShip,
        usersList: [],
        fetchingUsersList: false
      }
    }
  },
  [GetAllShipmentPOs.pending]: (state, action) => {
    return {
      ...state,
      toShip: {
        ...state.toShip,
        fetchingShipmentPOs: true,
      }
    }
  },
  [GetAllShipmentPOs.fulfilled]: (state, action) => {
    const { result, count, page } = action?.payload?.data || {};

    return {
      ...state,
      toShip: {
        ...state.toShip,
        fetchingShipmentPOs: false,
        shipmentPOs: (page.pageNumber === 1) ? result : [...state.toShip.shipmentPOs, ...result],
        totalCount: count
      }
    }
  },
  [GetAllShipmentPOs.rejected]: (state, action) => {
    return {
      ...state,
      toShip: {
        ...state.toShip,
        fetchingShipmentPOs: false,
      }
    }
  },
  [GetUserPoIndices.pending]: (state, action) => ({
    ...state,
    toShip: {
      ...state.toShip,
      fetchingUserPoIndices: true,
      userPoIndices: []
    }
  }),
  [GetUserPoIndices.fulfilled]: (state, action) => {
    const { result } = action?.payload?.data || {};

    return {
      ...state,
      toShip: {
        ...state.toShip,
        fetchingUserPoIndices: false,
        userPoIndices: result
      }
    }
  },
  [GetUserPoIndices.rejected]: (state, action) => {
    return {
      ...state,
      toShip: {
        ...state.toShip,
        fetchingUserPoIndices: false,
      }
    }
  },
  [DeleteArrivedPo.pending]: (state, action) => ({
    ...state,
    toShip: {
      ...state.toShip,
      deletingArrivedPo: true,
    }
  }),
  [DeleteArrivedPo.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Delete Po Check-In',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      toShip: {
        ...state.toShip,
        deletingArrivedPo: false
      }
    }
  },
  [DeleteArrivedPo.rejected]: (state, action) => {
    return {
      ...state,
      toShip: {
        ...state.toShip,
        deletingArrivedPo: false,
      }
    }
  },
};
