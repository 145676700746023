import { findIndex, cloneDeep } from 'lodash';
const invoiceId = window.location.pathname.split('/invoices/')[1];

const initialState = {
  fetching: false,
  pendingChanges: false,
  invoiceId,
  invoice: {
  },
  invoiceItems: [],
  toUpdateInvoiceItemIdList: [],
  chargeList: [],
};

const invoices = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'UNAPPROVE_INVOICE_REQUEST':
    case 'APPROVE_INVOICE_REQUEST':
    case 'UPDATE_INVOICE_REQUEST':
    case 'EMAIL_INVOICE_REQUEST':
    case 'GET_INVOICE_REQUEST':
    case 'UPDATE_CHARGE_REQUEST':
    case 'UPDATE_INVOICE_DATA_REQUEST':
    case 'UPDATE_INVOICE_ITEM_COST_INCLUDED_REQUEST':
    case 'UPDATE_INVOICE_ITEM_PACK_QUANTITY_REQUEST':
    case 'UPDATE_INVOICE_ITEM_PACK_COUNT_REQUEST':
    case 'UPDATE_PREP_FEE_REMOVE_REQUEST':
    case 'UPDATE_INVOICE_ITEM_COST_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_INVOICE_FAILED':
    case 'EMAIL_INVOICE_SUCCESS':
    case 'EMAIL_INVOICE_FAILED':
    case 'UNAPPROVE_INVOICE_SUCCESS':
    case 'UNAPPROVE_INVOICE_FAILED':
    case 'APPROVE_INVOICE_SUCCESS':
    case 'APPROVE_INVOICE_FAILED':
    case 'UPDATE_INVOICE_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_INVOICE_SUCCESS': {
      return {
        ...state,
        fetching: false,
        pendingChanges: true,
        ...action.payload,
      };
    }
    case 'UPDATE_INVOICE_ITEM_COST_SUCCESS': {
      const { invoiceItems, toUpdateInvoiceItemIdList } = state;
      const { costPrice, id } = action.payload;

      if (toUpdateInvoiceItemIdList.indexOf(id) === -1) {
        toUpdateInvoiceItemIdList.push(id);
      }

      const index = findIndex(invoiceItems, { id });
      if (index >= 0) {
        invoiceItems[index].cost = costPrice;
      }

      return {
        ...state,
        pendingChanges: true,
        fetching: false,
        invoiceItems,
        toUpdateInvoiceItemIdList
      };
    }
    case 'UPDATE_INVOICE_ITEM_PACK_QUANTITY_SUCCESS': {
      const { invoiceItems, toUpdateInvoiceItemIdList } = state;
      const { packQuantity, id, single } = action.payload;

      if (toUpdateInvoiceItemIdList.indexOf(id) === -1) {
        toUpdateInvoiceItemIdList.push(id);
      }

      const index = findIndex(invoiceItems, { id });
      if (index >= 0) {
        invoiceItems[index].pack_quantity = packQuantity;
        invoiceItems[index].single = single;
      }

      return {
        ...state,
        fetching: false,
        pendingChanges: true,
        invoiceItems,
        toUpdateInvoiceItemIdList
      };
    }
    case 'UPDATE_INVOICE_ITEM_PACK_COUNT_SUCCESS': {
      const { invoiceItems, toUpdateInvoiceItemIdList } = state;
      const { packCount, id, single } = action.payload;

      if (toUpdateInvoiceItemIdList.indexOf(id) === -1) {
        toUpdateInvoiceItemIdList.push(id);
      }

      const index = findIndex(invoiceItems, { id });
      if (index >= 0) {
        invoiceItems[index].quantity_in_case = packCount;
        invoiceItems[index].single = single;
      }
      return {
        ...state,
        fetching: false,
        pendingChanges: true,
        invoiceItems,
        toUpdateInvoiceItemIdList
      };
    }
    case 'UPDATE_PREP_FEE_REMOVE_SUCCESS': {
      const { invoiceItems, toUpdateInvoiceItemIdList } = state;
      const { IncludePrepFee, id } = action.payload;

      if (toUpdateInvoiceItemIdList.indexOf(id) === -1) {
        toUpdateInvoiceItemIdList.push(id);
      }

      const index = findIndex(invoiceItems, { id });
      if (index >= 0) {
        invoiceItems[index].include_prep_fee = IncludePrepFee;
      }
      return {
        ...state,
        fetching: false,
        pendingChanges: true,
        invoiceItems,
        toUpdateInvoiceItemIdList
      };
    }
    case 'UPDATE_INVOICE_ITEM_COST_INCLUDED_SUCCESS': {
      let { invoiceItems, toUpdateInvoiceItemIdList } = state;
      const { id, includeCost } = action.payload;

      if (toUpdateInvoiceItemIdList.indexOf(id) === -1) {
        toUpdateInvoiceItemIdList.push(id);
      }

      const index = findIndex(invoiceItems, { id });
      if (index >= 0) {
        invoiceItems[index].include_cost = includeCost;
      }

      return {
        ...state,
        fetching: false,
        pendingChanges: true,
        invoiceItems,
        toUpdateInvoiceItemIdList
      };
    }
    case 'UPDATE_CHARGE': {
      const { chargeList } = state;
      const { charge } = action.payload;

      const newChargeList = [...chargeList];
      const index = findIndex(chargeList, { charge_type: charge.charge_type });
      newChargeList[index] = charge;

      return {
        ...state,
        pendingChanges: true,
        fetching: false,
        chargeList: newChargeList
      };
    }
    case 'ADD_CHARGE': {
      const { charge } = action.payload;
      const { chargeList } = state;

      const newChargeList = [...chargeList, charge];

      return {
        ...state,
        pendingChanges: true,
        chargeList: newChargeList
      };
    }
    case 'REMOVE_CHARGE': {
      const { charge_type } = action.payload;
      const { chargeList } = state;

      const index = findIndex(chargeList, { charge_type });
      chargeList.splice(index, 1);

      const newChargeList = [...chargeList];

      return {
        ...state,
        fetching: false,
        pendingChanges: true,
        chargeList: newChargeList
      };
    }
    case 'UPDATE_INVOICE_SUCCESS': {
      return {
        ...state,
        pendingChanges: false,
        fetching: false,
        toUpdateInvoiceItemIdList: []
      };
    }
    case 'UPDATE_INVOICE_LOCAL_SUCCESS': {
      const { invoice } = state;

      return {
        ...state,
        fetching: false,
        pendingChanges: true,
        invoice: {
          ...invoice,
          ...action.payload
        }
      };
    }

    default:
      return state;
  }
};

export default invoices;
