import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  fetchingForUploading: false,
  sortOptions: {},
  vendors: []
};

const uploadPurchaseOrder = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GET_VENDORS_FOR_UPLOAD_PO_REQUEST':
    case 'ADD_VENDOR_FOR_UPLOAD_PO_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'SAVE_FILENAME_FOR_UPLOAD_PO_REQUEST': {
      return {
        ...state,
        fetchingForUploading: true
      };
    }
    case 'UPLOAD_PO_FILE_REQUEST': {
      return {
        ...state,
        fetchingForUploading: true
      };
    }
    case 'GET_VENDORS_FOR_UPLOAD_PO_SUCCESS': {
      return {
        ...state,
        fetching: false,
        vendors: action.payload
      };
    }
    case 'UPLOAD_PO_FILE_SUCCESS':
    case 'UPLOAD_PO_FILE_FAILED': {
      return {
        ...state,
        fetchingForUploading: false
      };
    }
    case 'GET_VENDORS_FOR_UPLOAD_PO_FAILED':
    case 'ADD_VENDOR_FOR_UPLOAD_PO_SUCCESS':
    case 'ADD_VENDOR_FOR_UPLOAD_PO_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SAVE_FILENAME_FOR_UPLOAD_PO_FAILED':
    case 'SAVE_FILENAME_FOR_UPLOAD_PO_SUCCESS': {
      return {
        ...state,
        fetchingForUploading: false
      };
    }
    default:
      return state;
  }
};

export default uploadPurchaseOrder;
