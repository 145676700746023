import React, { useState, useEffect } from "react";
import { Button, Input, Spin, notification, DatePicker } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { FaPlus } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import TextArea from "antd/lib/input/TextArea";

import Select from "../../../components/uiComponent/select/index";
import { nonPartneredCarriers } from "../../fbaInbound/constants";

import { AddPurchaseOrderStyle } from "../style";

const EstimateDeliveryInfo = ({
  setDraftPo,
  draft_po,
  openModal,
  setNewDate,
  newDate
}) => {
  const { loading } = useSelector((store) => store.fbaShipment);
  const [carrier, setCarrier] = useState('');

  const handleAddButtonClick = () => {
    let error = '';
    const ifExists = draft_po?.delivery_informations_attributes?.findIndex(({ tracking_info, _destroy }) => {
      return !_destroy && newDate?.tracking_info.trim() === tracking_info.trim();
    });

    if (ifExists >= 0) error = `Tracking id "${newDate?.tracking_info}" already exists`;
    else if (!newDate?.tracking_info || !newDate?.delivery_date) error = 'Please Add Tracking Info and Delivery Date';

    if (error) {
      notification.error({
        message: "Add New Delivery Date",
        description: error,
        top: 65
      });
      return;
    }
    setDraftPo({
      ...draft_po,
      delivery_informations_attributes: [
        ...(draft_po?.delivery_informations_attributes || []),
        {
          carrier_name: newDate?.carrier_name.trim(),
          delivery_date: newDate?.delivery_date.trim(),
          tracking_info: newDate?.tracking_info.trim()
        }
      ]
    });
    setNewDate({
      carrier_name: "",
      delivery_date: "",
      tracking_info: ""
    });
    setCarrier('');
  };

  const handleDeleteEntry = (trackingInfo) => {
    const newData = [...draft_po?.delivery_informations_attributes];
    const index = newData?.findIndex(({ tracking_info, _destroy }) => !_destroy && tracking_info === trackingInfo);

    newData[index] = {
      ...newData[index],
      _destroy: true
    };
    setDraftPo({
      ...draft_po,
      delivery_informations_attributes: [...newData]
    });
  };

  useEffect(() => {
    if (!openModal && newDate?.tracking_info && newDate?.delivery_date) {
      const ifExists = draft_po?.delivery_informations_attributes?.findIndex(({ tracking_info, _destroy }) => {
        return !_destroy && newDate?.tracking_info.trim() === tracking_info.trim();
      });

      if (ifExists >= 0) return;

      const delivery_informations_attributes = [
        ...(draft_po?.delivery_informations_attributes || []),
        {
          carrier_name: newDate?.carrier_name.trim(),
          delivery_date: newDate?.delivery_date.trim(),
          tracking_info: newDate?.tracking_info.trim()
        }
      ];
      setDraftPo({
        ...draft_po,
        delivery_informations_attributes
      });
    }
    setNewDate({
      carrier_name: "",
      delivery_date: "",
      tracking_info: ""
    });
    setCarrier('');
  }, [openModal]);

  return (
    <Spin tip='Loading...' spinning={loading}>
      <AddPurchaseOrderStyle>
        <div className="delivery-row-wrapper">
          <div className="track-info-wrapper">
            <p>Tracking info</p>
            {
              <Input
                value={newDate?.tracking_info}
                onChange={(e) => {
                  setNewDate({
                    ...newDate,
                    tracking_info: e.target.value?.trimStart()
                  });
                }}
                placeholder="Add or edit tracking info"
              />
            }
          </div>
          <div className="track-info-wrapper">
            <p>Carrier</p>
            <Select
              placeholder="Select Carrier"
              width="165px"
              isClearable
              marginBottom="0px"
              value={carrier}
              options={nonPartneredCarriers}
              onChange={(event) => {
                setCarrier(event);
                setNewDate({
                  ...newDate,
                  carrier_name: event?.value || ''
                });
              }}
            />
          </div>
          <div className="estimate-ui w-100">
            <p>Estimated Delivery Date</p>
            <DatePicker
              value={newDate?.delivery_date ? moment(newDate?.delivery_date) : ''}
              disabledDate={(current) => {
                return moment().add(-1, 'days') >= current
              }}
              format="DD MMMM YYYY"
              className="custom-date-picker"
              onChange={(date, dateString) => {
                setNewDate({
                  ...newDate,
                  delivery_date: dateString
                });
              }}
            />
          </div>
          <Button className="add-button" onClick={handleAddButtonClick}>
            <FaPlus />
            <span className="add-button-text">Add More</span>
          </Button>
        </div>
        <div className="fix-height-delivery-date">
          {draft_po?.delivery_informations_attributes?.length > 0 && (
            <div className="pb-5">
              {draft_po?.delivery_informations_attributes?.filter(({ _destroy }) => !_destroy)
                ?.map((data, index) => (
                  <div className="tracking-info-inner-wrapper" key={index}>
                    {/* Displaying data */}
                    <p className="tracking-info-line">{data.tracking_info}</p>
                    <p className="tracking-info-line">{data.carrier_name}</p>
                    {/* Showing formatted date */}
                    <p className="tracking-info-date">{moment(data.delivery_date).format('DD MMMM YYYY')}</p>
                    {/* Delete button */}
                    <button className="delete-button" onClick={() => handleDeleteEntry(data.tracking_info)}><BsTrash color="#CF0909" /></button>
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className="track-info-wrapper">
          <p>Add Notes for Warehouse (if neccessary)</p>
          <TextArea
            onChange={(e) => {
              setDraftPo({
                ...draft_po,
                note: e.target.value
              })
            }}
            placeholder="Add your comments" rows={4}
            value={draft_po?.note}
          />
        </div>
      </AddPurchaseOrderStyle>
    </Spin>
  );
};

export default EstimateDeliveryInfo;
