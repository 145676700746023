import React from "react";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
const Index = (props) => {
  const { text, tooltip, className } = props
  return (
    <OverlayTrigger
      className={className}
      placement="top"
      overlay={<Tooltip id="button-tooltip" className="mytooltip">{tooltip}</Tooltip>}
    >
      <span>{text}</span>
    </OverlayTrigger>
  );
};
export default Index;
