import axios from 'axios';
import { findIndex } from 'lodash';
let requests = [];

const clearRequestArray = () => {
  requests = [];
};

export default clearRequestArray;

export const pendingRequestInterceptors = [
  (req) => {
    const { url } = req;
    const method = req.method.toUpperCase();
    const urlToCheck = url.toString();

    // HANDLING PREVIOUS REQUEST AND CANCELLING IF STILL PANDING
    const index = findIndex(requests, (request) => request.url == url && request.method == method);
    if (index != '-1' && method == 'GET' &&
      !urlToCheck.includes('/api/v2/seller_skus/vendors') &&
      !(urlToCheck.includes('s3')) &&
      !(urlToCheck.includes('presigned_url')) &&
      !(urlToCheck.includes('api/v2/reports/repricer_dashboard_stats'))
    ) {
      const { cancel } = requests[index];
      requests.splice(index, 1);
      cancel('Pending Request cancel due to new request');
    }

    // HANDLING CURRENT REQUEST AND CREATING CANCEL TOKEN
    if (method == 'GET' && !urlToCheck.includes('/api/v2/seller_skus/vendors') && !(urlToCheck.includes('s3')) && !(urlToCheck.includes('presigned_url'))) {
      req.cancelToken = new axios.CancelToken((cancel) => {
        requests.push({
          url,
          method,
          cancel
        });
      })
    }

    if (urlToCheck.includes('/api/v2/users/logged_in_user')) req.signal = null;

    return req;
  },
  (error) => Promise.reject(error)
]

export const pendingRsponseInterceptors = [
  (res) => {
    const { config } = res;
    const { url } = config;
    const method = config.method.toUpperCase();

    // REMOVING REQUEST FROM ARRAY AFTER GETTING RESPONSE
    const index = findIndex(requests, (req) => req.url == url && req.method == method);
    if (index != '-1') requests.splice(index, 1);
    return res;
  },
  (error) => Promise.reject(error)
]
