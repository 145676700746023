import { cloneDeep } from "lodash";
const initialState = {
  requestingSKUs: false,
  fetchingSKUs: false,
  fetching: false,
  suppliersList: [],
  emailsList: [],
  listingSKUsData: [],
  pagination: {
    page_num: 1,
    per_page: 25
  },
  total: 0,
};

const reducer = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GETTING_SUPPLIERS_REQUEST':
    case 'GET_EMAILS_LIST_REQUEST': {
      return {
        ...state,
        fetching: true
      }
    }
    case 'LISTING_SKU_GETTING_SUPPLIERS_SUCCESS':
    case 'GET_EMAILS_LIST_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        fetching: false
      }
    }
    case 'LISTING_SKU_GETTING_SUPPLIERS_FAILED':
    case 'GET_EMAILS_LIST_FAILED': {
      return {
        ...state,
        fetching: false
      }
    }
    case 'UPLOAD_LISTING_SKU_REQUEST': {
      return {
        ...state,
        requestingSKUs: true
      }
    }
    case 'UPLOAD_LISTING_SKU_SUCCESS':
    case 'UPLOAD_LISTING_SKU_FAILED': {
      return {
        ...state,
        requestingSKUs: false
      }
    }
    case 'GET_LISTER_SKUS_DATA_REQUEST': {
      return {
        ...state,
        fetchingSKUs: true
      }
    }
    case 'GET_LISTER_SKUS_DATA_FAILED': {
      return {
        ...state,
        fetchingSKUs: false
      }
    }
    case 'GET_LISTER_SKUS_DATA_SUCCESS': {
      return {
        ...state,
        fetchingSKUs: false,
        ...action.payload
      }
    }
    
    case 'SET_PAGE_NUMBER_FOR_LISTING_SKU': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    case 'SET_PER_PAGE_FOR_LISTING_SKU': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }

    default:
      return state;
  }
};

export default reducer;
