import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { object, string, } from 'yup';
import { storeToken } from "./utils";
import Header from "../../header/header";
import axios from '../../../../axios_config';
import { AuthWrapper } from "../style";
import { Row, Col, Form } from "react-bootstrap";
import InfoVideo from "./infoVideo";
import LabelInput from "../../uiComponent/inputs/labelInput";
import { Link } from "react-router-dom";
import Button from "../../uiComponent/button/index";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { SignIn as UserSignIn, GetCurrentUser } from "../../../slices/auth";
import { useNavigate } from "react-router-dom";
// import ReCAPTCHA from "react-google-recaptcha";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { signInLoading } = useSelector(state => state?.auth || {});
  // const [captchaValue, setCaptchaValue] = useState(true);
  // const [resetCaptcha, setResetCaptcha] = useState('');
  // const onChange = (token) => {
  //   let value = token? false: true;
  //   setCaptchaValue(value);
  // };
  
  const [passwordShow, setPasswordShow] = useState(true);

  const handleSetPassword = () => {
    setPasswordShow(!passwordShow);
  };

  const signIn = async(data) => {
    const { email, password } = data;
    const user = {
      email,
      password
    }
    const response = await dispatch(UserSignIn({user}));
    if(!response.error) {
      const { jwt } = response?.payload?.data || {};
      if(jwt) {
        storeToken(jwt, navigate, dispatch);
        // await dispatch(GetCurrentUser());
      }
    }
    // resetCaptcha.reset();
    // setCaptchaValue(true);
  } 

  const schema = object().shape({
    email: string().email('Invalid Email').required('Enter your email!'),
    password: string().required('Enter your password')
  });

  return (
    <AuthWrapper>
      <Formik
      validationSchema={schema}
      onSubmit={(values) => signIn(values)}
      initialValues={{
        email: '',
        password: ''
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched
      }) => (
      <Form onSubmit={handleSubmit}>
      <Header login={false} />
        <Row className="m-0 vh-100">
          <Col lg={8} sm={12}>
            <InfoVideo />
          </Col>
          <Col lg={4} sm={12} className="pr-0">
            <div className="bg-albaster py-40">
              <div>
                <div className="mb-35">
                  <h2>Enter your Registerd Email Address</h2>
                </div>
                <LabelInput
                  className="mb-3"
                  name='email'
                  value={values.email}
                  inputStatus={touched.email && errors.email}
                  label="Email" 
                  placeholder="Your  Email" 
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="password-input">
                  <LabelInput
                    className="mb-3"
                    name='password'
                    value={values.password}
                    inputStatus={touched.password && errors.password}
                    label="Password"
                    type={passwordShow ? "password" : "text"}
                    placeholder="Password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {passwordShow ? (
                    <AiFillEyeInvisible onClick={handleSetPassword} />
                  ) : (
                    <AiFillEye onClick={handleSetPassword} />
                  )}
                </div>
                <div className="text-end mb-40">
                  <Link to="/auth/forget-password" className="forget-password">Forgot Password</Link>
                </div>
                {/* <div className="mb-20 handle-recaptcha" >
                  <ReCAPTCHA
                    ref={e => setResetCaptcha(e)}
                    name='captcha'
                    sitekey={process.env.RECAPTCHA_SITE_KEY}
                    onChange={onChange}
                    onBlur={handleBlur}
                  />
                </div> */}
                <Button
                  loading={true}
                  disabled={signInLoading}
                  type='submit'
                  text={signInLoading? "Logging...": "Log In"}
                  className="primary w-100"
                />
                <div className="text-center"> <span> If you are not registered, <a href="/auth/sign-up"> Click Here </a> </span>  </div>

              </div>
            </div>
          </Col>
        </Row>
      </Form>
      )}
    </Formik>
    </AuthWrapper>
  );
};

export default SignIn;
