import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { notification } from "antd";
import { useNavigate } from 'react-router-dom'
import { isEmpty } from 'lodash';
import Header from '../../header/header'
import { AuthWrapper, PhoneNumberWrapper } from '../style'
import { Row, Col, Container } from "react-bootstrap"
import InfoVideo from './infoVideo'
import EmailVerifactionImage from '../../../assets/images/newImages/emailVerifaction.png'
import { ResetPasswordVerification } from '../../../slices/auth';

const PasswordEmailVerifaction = () => {
  const dispatch = useDispatch();
  const { user, resetPasswordLoading } = useSelector(state => state?.auth || {});
  const { email } = user || {};

  const handleResendEmail = async() => {
    if(isEmpty(email)) {
      notification.error({
        message: "Verification Email",
        description: 'Email not found',
        top: 65,
      });
      return;
    }
    const user = { email }
    await dispatch(ResetPasswordVerification({user}));
  }

  return (
    <AuthWrapper>
      <Header login={false} />
      <div className="conatiner">
        <Row className="m-0">
          <Col lg={8} sm={12}>
            <InfoVideo />
          </Col>
          <Col lg={4} sm={12}>
            <div className="bg-albaster py-40">
              <div>
                <div className="mb-4">
                  <h2>Reset Password Verification Email Sent</h2>
                  {/* <span>Verification email sent description</span> */}
                </div>
                <div className="pt-120 pb-90 text-center">
                  <img src={EmailVerifactionImage} alt="no-image-email" />
                </div>
                <div className="text-center">
                  <span>If you didn’t receive the email please 
                    { resetPasswordLoading? <a > Sending Email...</a>
                    : <a href = '#' onClick={handleResendEmail}> Click Here</a> }
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </AuthWrapper>
  )
}

export default PasswordEmailVerifaction
