import React from 'react';
import { Spin } from 'antd';
import { Table } from "react-bootstrap";
import { TilesWrapper } from "../style";
import NoInbound from "../../../assets/images/newImages/no-inbound.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TilesPagination from "../../../components/uiComponent/tilesPagination/index";
import { ceil, find } from 'lodash';
import IconInfo from "../../../assets/images/newImages/icon-info.svg";

const StoragePOs = ({
  scrollHight,
  filters,
  handleFilter,
  handlePagination
}) => {
  const navigate = useNavigate();

  const {
    storagePo: {
      fetchingStoragePos,
      storagePos,
      totalStoragePOsCount,
      completedCount
    },
  } = useSelector((state) => state.fbaInbound || {});

  const storageHeader = [
    "PO#",
    "Vendor",
    "Progress",
    "Quantity",
    "Received Qty",
    "To PO’s",
    "To Storage",
    ""
  ];

  const validateValue = (value) => (!value || Number(value) <= 0) ? 0 : Number(value);
  const completedPosToggleStatus = find(filters, { field: "marked_as_hide" })?.value;

  return (
    <div>
      <Spin tip="Loading..." spinning={fetchingStoragePos}>
        {(storagePos.length) ?
          <>
            <TilesWrapper height={scrollHight ? scrollHight : "490px"}>
              {storagePos?.map(
                (
                  {
                    created_at,
                    fulfilled_po_quantity,
                    fulfilled_po_received_quantity,
                    id: storage_po_id,
                    invoice_number,
                    invoice_quantity,
                    order_id,
                    order_quantity,
                    po_code,
                    received_quantity,
                    total_in_storage_location_quantity,
                    total_in_storage_quantity,
                    total_received_quantity,
                    tracking_number,
                    user: {
                      company: {
                        name: company_name
                      } = {},
                      first_name,
                      id: user_id,
                      last_name
                    } = {},
                    vendor: {
                      name: vendor_name,
                      addresses
                    } = {},
                    working_quantity
                  },
                  index
                ) => {
                  const {
                    address_line_1,
                    address_line_2,
                    city,
                    country,
                    state_or_province,
                    zipcode,
                  } = addresses?.[0] || {};
                  const vendor_address = `${address_line_1 ?? ""} ${address_line_2 ?? ""
                    } ${city ?? ""} ${state_or_province ?? ""}`;
                  const missing_quantity = order_quantity - received_quantity;
                  const total_received_to_storage = received_quantity - fulfilled_po_quantity;
                  const pos_remaining_quantity = fulfilled_po_quantity - fulfilled_po_received_quantity;
                  const storage_remaining_quantity = total_in_storage_quantity - total_in_storage_location_quantity;
                  const actual_received_qty = validateValue(total_in_storage_location_quantity) + validateValue(fulfilled_po_received_quantity);
                  const progress = actual_received_qty / total_received_quantity;

                  return (
                    <div className="tiles-box">
                      <Table responsive className="tiles-table">
                        <thead>
                          <tr>
                            {storageHeader.map((header) => (
                              <th className={header === "Quantity" ? "storage-po-quantity-column" : ""}>{header}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span>{po_code}</span>
                            </td>
                            <td>
                              <span>{vendor_name}</span>
                              <span>{vendor_address}</span>
                            </td>
                            <td>
                              <span className="d-flex align-items-center gap-1">
                                <ProgressBar now={ceil(progress * 100)} variant="warning" className="progress-height" />
                                <span>{actual_received_qty} / {total_received_quantity || 0}</span>
                              </span>
                            </td>
                            <td>
                              <div className="receive-tile d-flex align-items-start flex-row">
                                <span className="d-flex flex-column">
                                  <span>{validateValue(order_quantity)}</span>
                                  <span>Ordered</span>
                                </span>
                                <span className="d-flex flex-column ms-2">
                                  <span>{validateValue(invoice_quantity)}</span>
                                  <span>Invoice</span>
                                </span>
                                <span className="d-flex flex-column ms-2">
                                  <span>{validateValue(working_quantity)}</span>
                                  <span>Working</span>
                                </span>
                                <span className="d-flex flex-column ms-2">
                                  <span>{validateValue(received_quantity)}</span>
                                  <span>Received</span>
                                </span>
                                <span className="d-flex flex-column ms-2">
                                  <span>{validateValue(missing_quantity)}</span>
                                  <span>Remaining</span>
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="receive-tile d-flex align-items-start flex-row">
                                <span className="d-flex flex-column">
                                  <span>{validateValue(received_quantity)}</span>
                                  <span>Total</span>
                                </span>
                                <span className="d-flex flex-column ms-2">
                                  <span>{validateValue(fulfilled_po_quantity)}</span>
                                  <span>To PO’s</span>
                                </span>
                                <span className="d-flex flex-column ms-2">
                                  <span>{validateValue(total_received_to_storage)}</span>
                                  <span>To Storage</span>
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="receive-tile d-flex align-items-start flex-row">
                                <span className="d-flex flex-column">
                                  <span>{validateValue(fulfilled_po_quantity)}</span>
                                  <span>Total</span>
                                </span>
                                <span className="d-flex flex-column ms-2">
                                  <span>{validateValue(fulfilled_po_received_quantity)}</span>
                                  <span>3PL Received</span>
                                </span>
                                <span className="d-flex flex-column ms-2">
                                  <span>{validateValue(pos_remaining_quantity)}</span>
                                  <span>Remaining</span>
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="receive-tile d-flex align-items-start flex-row">
                                <span className="d-flex flex-column">
                                  <span>{validateValue(total_received_to_storage)}</span>
                                  <span>Total</span>
                                </span>
                                <span className="d-flex flex-column ms-2">
                                  <span>{validateValue(total_in_storage_location_quantity)}</span>
                                  <span>On Location</span>
                                </span>
                                <span className="d-flex flex-column ms-2">
                                  <span>{validateValue(storage_remaining_quantity)}</span>
                                  <span>On Staging</span>
                                </span>
                              </div>
                            </td>
                            <td>
                              <span
                                class="icon-arrow-right float-end cursor-pointer"
                                onClick={() => navigate(`/fba-inbound/storage-po/${storage_po_id}`)}
                              ></span>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  );
                }
              )}
            </TilesWrapper>
            <TilesPagination total={totalStoragePOsCount} offset={storagePos.length} onClick={handlePagination} />
          </> :
          <div className="empty-data-container">
            {(!completedPosToggleStatus && completedCount && !storagePos.length && !fetchingStoragePos) ?
              <div className="nodata-notification justify-content-between">
                <div className="notification-text">
                  <img className="notification-icon" src={IconInfo} />
                  There are {completedCount} number of PO’s in completed PO’s.
                </div>
                <div className="notification-toggle">
                  <a onClick={() => { handleFilter({ isDropDown: true, field: "marked_as_hide", value: true }); return false; }}>
                    Click Here to View
                  </a>
                </div>
              </div> : null
            }
            <img
              src={NoInbound}
              alt="no-data"
              className="mx-auto d-block checkin-main-nodataimg"
            />
          </div>
        }
      </Spin>
    </div>
  )
}

export default StoragePOs;
