import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Tooltip, Spin } from 'antd';
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Header from '../../header/header'
import { StoreWrapper } from '../style'
import { GetShopifyAuthCode, CreateShopifyStore, ReAuthorizeShopifyStore, IsShopifyStoreExists } from '../../../slices/store';
import { BiLeftArrowAlt } from "react-icons/bi";
import Button from '../../uiComponent/button/index';
import SearchInput from '../../uiComponent/inputs/input'
import MapGrey from '../../../assets/images/newImages/map_gray.svg'
import clearRequestArray from '../../../utils/interseptors/pendingRequest';
import axios from '../../../../axios_config';
let controller = null;

const AddShopifyStore = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    fetchingShopifyAuthCode,
    creatingShopifyStore,
    authorizingShopifyStore,
    validatingShopifyStore
  } = useSelector(state => state?.store || {});

  const [storeName, setStoreName] = useState('');
  const [isStoreNameDisabled, setIsStoreNameDisabled] = useState(false);
  const [authCode, setAuthCode] = useState('');

  useEffect(() => {
    controller = new AbortController();
    axios.defaults.signal = controller?.signal;

    setStoreName(localStorage.getItem('shopifyStoreName') || '');

    const query = new URLSearchParams(window.location.search);
    const code = query.get("code");
    if (!isEmpty(code)) {
      setIsStoreNameDisabled(true);
      setAuthCode(code);
    }

    return () => {
      localStorage.removeItem('shopifyStoreName');
      setStoreName('');
      clearRequestArray();
      controller.abort();
      controller = null;
      axios.defaults.signal = null;
    };
  }, []);

  const handleBtnClick = async () => {
    if (isStoreNameDisabled) {
      if (localStorage.getItem('re-authorize')) {
        dispatch(ReAuthorizeShopifyStore({
          shop_name: storeName,
          authorization_code: authCode
        }))
          .then(({ payload }) => {
            const { data } = payload || {};
            const { success } = data || {};
            if (success) {
              localStorage.removeItem('shopifyStoreName');
              navigate('/onboarding/shopify-success');
            }
          });
      }
      else {
        dispatch(CreateShopifyStore({
          shop_name: storeName,
          authorization_code: authCode
        }))
          .then(({ payload }) => {
            const { data } = payload || {};
            const { success } = data || {};
            if (success) {
              localStorage.removeItem('shopifyStoreName');
              navigate('/onboarding/shopify-success');
            }
          });
      }
    }
    else {
      dispatch(IsShopifyStoreExists(storeName))
        .then(({ payload }) => {
          const { data } = payload || {};
          const { success } = data || {};
          if (success) {
            dispatch(GetShopifyAuthCode({
              shop_name: storeName,
              redirect_url: window.location.href
            }))
              .then(({ payload }) => {
                const { data } = payload || {};
                const { success, url } = data || {};
                if (success) {
                  localStorage.setItem('shopifyStoreName', storeName);
                  window.open(url, "_self");
                }
              });
          }
        });
    }
  }

  const handleStoreName = (e) => {
    setStoreName(e.target.value);
  };

  const disableBtn = isEmpty(storeName);

  return (
    <StoreWrapper>
      <Header login={true} />
      <div className='change-background'>
        <div className="select-region">
          <div className="arrow-box grid-column-gap-10">
            <BiLeftArrowAlt className="cursor-pointer" onClick={() => navigate(-1)} />
            <h1>Add Shopify Store</h1>
          </div>
          <Spin tip='Processing...' spinning={fetchingShopifyAuthCode || creatingShopifyStore || authorizingShopifyStore || validatingShopifyStore || false}>
            <div className="search-box text-center mt-20 mb-20">
              <Row className="align-items-center justify-content-center">
                <Col lg={6} md={8} sm={12}>
                  <SearchInput placeholder="Enter Store Name Here (Required)" value={storeName} onChange={handleStoreName}
                    disabled={isStoreNameDisabled}
                  />
                </Col>
              </Row>
            </div>
            <Row className="justify-content-center">
              <Col lg={3} md={5}>
                <div className="pl-27">
                  <div className="region">
                    <div className="gap-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Tooltip title={disableBtn ? 'Enter Store Name' : ''} placement="right" >
                        <div className='w-100'>
                          <Button
                            className="w-100"
                            text={isStoreNameDisabled ? 'Save' : 'Connect Store'}
                            onClick={handleBtnClick}
                            disabled={disableBtn}
                          />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={9} md={7}>
                <div className="map_region">
                  <img src={MapGrey} alt="no-region" />
                </div>
              </Col>
            </Row>
          </Spin>
        </div>
      </div>
    </StoreWrapper>
  )
}

export default AddShopifyStore
