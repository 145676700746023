import React, { PureComponent } from 'react';
import { Select } from 'antd';
import DropDownWrap from './DropDownWrap.jsx';

const ITEM_ELEMENT_NUMBER = 30;
const ITEM_HEIGHT_CFG = {
  small: 24,
  large: 40,
  default: 32
};

const ARROW_CODE = {
  40: "down",
  38: "up"
};

const DROPDOWN_HEIGHT = 224;

const Option = Select.Option;

class SuperSelect extends PureComponent {
  constructor(props) {
    super(props);

    const { mode, defaultValue, value, optionHeight } = props;
    this.isMultiple = ["tags", "multiple"].includes(mode);

    let defaultV = this.isMultiple ? [] : "";
    defaultV = value || defaultValue || defaultV;

    this.state = {
      children: props.children || [],
      filterChildren: null,
      value: defaultV
    };
    this.ITEM_HEIGHT = optionHeight || ITEM_HEIGHT_CFG[props.size || "default"];
    this.visibleDomHeight = this.ITEM_HEIGHT * ITEM_ELEMENT_NUMBER;
    this.reactDelta = this.visibleDomHeight / 3;
    this.isStopReact = false;
    this.prevScrollTop = 0;
    this.prevTop = 0;

    this.scrollTop = 0;

    this.dropdownClassName = `dc${+new Date()}`;

    this.id = `sid${+new Date()}`;
  }

  componentDidMount() {
    setTimeout(() => {
      this.addEvent();
    }, 500);
  }

  componentDidUpdate(prevProps) {
    const { mode, defaultValue, value, children } = this.props;
    if (prevProps.children !== children) {
      this.isMultiple = ["tags", "multiple"].includes(mode);

      this.setState({
        children: children || [],
        filterChildren: null
      });
    }
    if (prevProps.value !== value) {
      let defaultV = this.isMultiple ? [] : "";
      defaultV = value || defaultValue || defaultV;
      this.setState({ value: defaultV }, () => {
        this.scrollToValue();
      });
    }
  }

  componentWillUnmount() {
    this.removeEvent();
  }

  scrollToValue = () => {
    if (!this.scrollEle) return;
    const { children } = this.props;
    const { value } = this.state;
    const index = children.findIndex((item) => item.key === value) || 0;

    const y = this.ITEM_HEIGHT * index;
    this.scrollEle.scrollTop = y;
    setTimeout(() => {
      this.forceUpdate();
    }, 0);
  };

  getItemStyle = (i) => ({
    position: "absolute",
    top: this.ITEM_HEIGHT * i,
    width: "100%",
    height: this.ITEM_HEIGHT
  });

  addEvent = () => {
    this.scrollEle = document.querySelector(`.${this.dropdownClassName}`);
    if (!this.scrollEle) return;

    this.scrollEle.addEventListener("scroll", this.onScroll, false);
    this.inputEle = document.querySelector(`#${this.id}`);

    if (!this.inputEle) return;
    this.inputEle.addEventListener("keydown", this.onKeyDown, false);
  };

  onKeyDown = (e) => {
    const { keyCode } = e || {};

    setTimeout(() => {
      const activeItem = document.querySelector(
        `.${this.dropdownClassName} .ant-select-dropdown-menu-item-active`
      );
      if (!activeItem) return;

      const { offsetTop } = activeItem;
      const isUp = ARROW_CODE[keyCode] === "up";
      const isDown = ARROW_CODE[keyCode] === "down";

      if (offsetTop - this.prevTop > DROPDOWN_HEIGHT && isUp) {
        this.scrollEle.scrollTo(0, this.allHeight - DROPDOWN_HEIGHT);
        this.prevTop = this.allHeight;

        return;
      }

      if (this.prevTop > offsetTop + DROPDOWN_HEIGHT && isDown) {
        this.scrollEle.scrollTo(0, 0);
        this.prevTop = 0;

        return;
      }

      this.prevTop = offsetTop;
      if (
        offsetTop >
        this.scrollEle.scrollTop + DROPDOWN_HEIGHT - this.ITEM_HEIGHT + 10 &&
        isDown
      ) {
        this.scrollEle.scrollTo(0, this.scrollTop + this.ITEM_HEIGHT);
        return;
      }
      if (offsetTop < this.scrollEle.scrollTop && isUp) {
        this.scrollEle.scrollTo(0, this.scrollTop - this.ITEM_HEIGHT);
      }
    }, 100);
  };

  onScroll = () => this.throttleByHeight(this.onScrollReal);

  onScrollReal = () => {
    this.allList = this.getUseChildrenList();
    const { startIndex, endIndex } = this.getStartAndEndIndex();

    this.prevScrollTop = this.scrollTop;
    const allHeight = this.allList.length * this.ITEM_HEIGHT || 100;
    this.wrap.reactList(allHeight, startIndex, endIndex);
  };

  throttleByHeight = () => {
    this.scrollTop = this.scrollEle.scrollTop;
    let delta = this.prevScrollTop - this.scrollTop;
    delta = delta < 0 ? 0 - delta : delta;

    delta > this.reactDelta && this.onScrollReal();
  };

  getUseChildrenList = () => this.state.filterChildren || this.state.children;

  getStartAndEndIndex = () => {
    const showIndex = Number((this.scrollTop / this.ITEM_HEIGHT).toFixed(0));

    const startIndex =
      showIndex - ITEM_ELEMENT_NUMBER < 0
        ? 0
        : showIndex - ITEM_ELEMENT_NUMBER / 2;
    const endIndex = showIndex + ITEM_ELEMENT_NUMBER;
    return { startIndex, endIndex };
  };

  setSuperDrowDownMenu = (visible) => {
    if (!visible) return;

    this.allList = this.getUseChildrenList();

    if (!this.eventTimer) {
      this.eventTimer = setTimeout(() => this.addEvent(), 0);
    } else {
      const allHeight = this.allList.length * this.ITEM_HEIGHT || 100;
      const { startIndex, endIndex } = this.getStartAndEndIndex();
      setTimeout(() => {
        this.wrap && this.wrap.reactList(allHeight, startIndex, endIndex);
      }, 0);
    }
  };

  onDropdownVisibleChange = (visible) => {
    const { onDropdownVisibleChange } = this.props;
    onDropdownVisibleChange && onDropdownVisibleChange(visible);
    if (!visible) {
      setTimeout(() => {
        this.setState({ filterChildren: null });
      });
    } else {
      this.setDefaultScrollTop();
      this.setSuperDrowDownMenu(visible);
    }
  };

  onDeselect = (value) => {
    const { onDeselect } = this.props;
    onDeselect && onDeselect(value);
  };

  onChange = (value, opt) => {
    const { showSearch, onChange, autoClearSearchValue } = this.props;
    if (showSearch || this.isMultiple) {
      if (autoClearSearchValue !== false) {
        this.setState({ filterChildren: null }, () => {
          this.setSuperDrowDownMenu(true);
        });
      }
    }

    this.setState({ value });
    onChange && onChange(value, opt);
  };

  onSearch = (v) => {
    const { showSearch, onSearch, filterOption, children } = this.props;
    if (showSearch && filterOption !== false) {
      let filterChildren = null;
      if (typeof filterOption === "function") {
        filterChildren = children.filter((item) => filterOption(v, item));
      } else if (filterOption === undefined) {
        filterChildren = children.filter((item) => this.filterOption(v, item));
      }

      this.setState(
        { filterChildren: v === "" ? null : filterChildren },
        () => {
          setTimeout(() => {
            if (filterChildren) {
              this.scrollTop = 0;
              this.scrollEle.scrollTo(0, 0);
            }
            this.setSuperDrowDownMenu(true);
          }, 0);
        }
      );
    }
    onSearch && onSearch(v);
  };

  filterOption = (v, option) => {
    const filterProps = this.props.optionFilterProp || "value";
    return `${option.props[filterProps].toLowerCase()}`.indexOf(v.toLowerCase()) >= 0;
  };

  setDefaultScrollTop = () => {
    const { value } = this.state;
    const { children } = this.props;

    for (let i = 0; i < children.length; i++) {
      const item = children[i];
      const itemValue = item.props.value;
      if (
        itemValue === value ||
        (Array.isArray(value) && value.includes(itemValue))
      ) {
        const targetScrollTop = i * this.ITEM_HEIGHT;

        setTimeout(() => {
          this.scrollEle.scrollTo(0, targetScrollTop);
        }, 100);
        return;
      }
    }
  };

  removeEvent = () => {
    if (!this.scrollEle) return;
    this.scrollEle.removeEventListener("scroll", this.onScroll, false);
    if (!this.inputEle) return;
    this.inputEle.removeEventListener("keydown", this.onKeyDown, false);
  };

  render() {
    let {
      dropdownStyle,
      optionLabelProp,
      notFoundContent,
      dropdownClassName,
      ...props
    } = this.props;

    this.allList = this.getUseChildrenList();

    this.allHeight = this.allList.length * this.ITEM_HEIGHT || 100;
    const { startIndex, endIndex } = this.getStartAndEndIndex();

    dropdownStyle = {
      maxHeight: `${DROPDOWN_HEIGHT}px`,
      ...dropdownStyle,
      overflow: "auto",
      position: "relative"
    };

    const { value } = this.state;
    const _props = { ...props };
    delete _props.value;

    if (typeof value === "string" && !value) {
      _props.value = undefined;
    } else {
      _props.value = value;
    }

    optionLabelProp = optionLabelProp || "children";

    return (
      <Select
        {..._props}
        id={this.id}
        onSearch={this.onSearch}
        getPopupContainer={trigger => trigger.parentNode}
        onChange={this.onChange}
        dropdownClassName={`${this.dropdownClassName} ${dropdownClassName || ""
          }`}
        optionLabelProp={optionLabelProp}
        dropdownStyle={dropdownStyle}
        onDropdownVisibleChange={this.onDropdownVisibleChange}
        onDeselect={this.onDeselect}
        ref={(ele) => (this.select = ele)}
        dropdownRender={(menu) => {
          if (this.allList.length === 0) {
            return <div style={{ padding: "5px 12px" }}>{notFoundContent}</div>;
          }

          return (
            <DropDownWrap
              {...{
                startIndex,
                endIndex,
                allHeight: this.allHeight,
                menu,
                itemHeight: this.ITEM_HEIGHT
              }}
              ref={(ele) => {
                this.wrap = ele;
              }}
            />
          );
        }}
      >
        {this.allList}
      </Select>
    );
  }
}

SuperSelect.Option = Option;

export default SuperSelect;