import React from "react";
import { object, string } from 'yup';
import { isEmpty } from "lodash";
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import Header from "../../header/header";
import { AuthWrapper, PhoneNumberWrapper } from "../style";
import { Row, Col, Container, Form } from "react-bootstrap";
import InfoVideo from "./infoVideo";
import LabelInput from "../../uiComponent/inputs/labelInput";
import Button from "../../uiComponent/button/index";
import { useNavigate } from "react-router-dom";
import { ResetPasswordVerification, setField } from "../../../slices/auth"

const ForgetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { resetPasswordLoading } = useSelector(state => state?.auth || {});

  const handleForgetPassword = async(values) => {
    const { email } = values;
    if(isEmpty(email)) return;
    const user = {
      email
    }
    const response = await dispatch(ResetPasswordVerification({user}))
    if (!response.error) {
      dispatch(setField({
        module: 'user',
        field: 'email',
        value: email
      }));
      navigate("/auth/password-email-verification");
    }
  }

  const schema = object().shape({
    email: string().email('Invalid Email').required('Email is required!')
  });

  return (
    <AuthWrapper>
      <Formik
      validationSchema={schema}
      onSubmit={(values) => handleForgetPassword(values)}
      initialValues={{
        email: '',
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors
      }) => (
      <Form onSubmit={handleSubmit}>
      <Header login={false} />
      <div className="conatiner">
        <Row className="m-0">
          <Col lg={8} sm={12}>
            <InfoVideo />
          </Col>
          <Col lg={4} sm={12}>
            <div className="bg-albaster">
              <div className="py-40 pt-0">
                <div className="mb-35">
                  <h2>Enter your Registerd Email Address</h2>
                </div>
                <LabelInput
                  inputStatus={errors.email}
                  name='email'
                  value={values.email}
                  label="Email" 
                  placeholder="Email" 
                  onChange={handleChange}
                  onBlur= {handleBlur}
                />
                <div className="mt-40">
                <Button
                  type="submit"
                  disabled={resetPasswordLoading}
                  text={resetPasswordLoading? "Sending Verification Email...": "Send Email"}
                  className="primary w-100"
                />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Form>
    )}
    </Formik>
    </AuthWrapper>
  );
};

export default ForgetPassword;
