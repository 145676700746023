import { isArray } from 'lodash';
import { combineReducers } from 'redux';
import adminUsers from '../reducers/admin/adminUsers.js';

import processingCatalogs from '../reducers/catalogs/catalogInProcesses.js';
import catalogJobs from '../reducers/catalogs/catalogJobs.js';
import invalidCatalogs from '../reducers/catalogs/catalogWithErrors.js';
import processedCatalogs from '../reducers/catalogs/processedCatalogs.js';
import vendorProcessedCatalogs from '../reducers/catalogs/vendorProcessedCatalogs.js';

import removalDetails from '../reducers/fbaRemovalShipments/Details.js';
import fbaremovalshipments from '../reducers/fbaRemovalShipments/Grid.js';

import masterStorageInvoices from '../reducers/internalStorageInvoices/masterStorageInvoices.js';
import userStorageInvoices from '../reducers/internalStorageInvoices/userStorageInvoices.js';

import invoices from '../reducers/invoices/invoices.js';

import approveMatches from '../reducers/lister/approveMatches.js';
import listingSku from '../reducers/lister/listingSku.js';
import profitableMatches from '../reducers/lister/profitableMatches.js';
import restrictedBrands from '../reducers/lister/restrictedBrands.js';

import products from '../reducers/product/products.js';

import affiliateInvoices from '../reducers/profitCalculationManager/affiliateInvoices.js';
import profitCalculationManagerDashboard from '../reducers/profitCalculationManager/dashboard';

import combinedOrders from '../reducers/purchaseOrders/combinedOrders/combinedOrders.js';
import pendingPos from '../reducers/purchaseOrders/newPurchaseOrders/pendingPos.js';
import pendingPurchaseOrders from '../reducers/purchaseOrders/newPurchaseOrders/pendingPurchaseOrders.js';
import poInvoices from '../reducers/purchaseOrders/newPurchaseOrders/poInvoices.js';
import purchaseOrderDetails from '../reducers/purchaseOrders/newPurchaseOrders/purchaseOrderDetails.js';
import purchasingInvoices from '../reducers/purchaseOrders/newPurchaseOrders/purchasingInvoices.js';
import simplePurchaseOrders from '../reducers/purchaseOrders/newPurchaseOrders/simplePurchaseOrders.js';
import uploadPurchaseOrder from '../reducers/purchaseOrders/newPurchaseOrders/uploadPurchaseOrders.js';

import allOrders from '../reducers/purchaseOrders/allOrders.js';
import journal from '../reducers/purchaseOrders/journal.js';
import pendingPoIndices from '../reducers/purchaseOrders/pendingPoIndices.js';

import masterRemovalInvoices from '../reducers/removalInvoices/masterRemovalInvoices.js';
import removalInvoices from '../reducers/removalInvoices/userRemovalInvoices.js';

import Dashboard from '../reducers/reprice/dashboard.js';
import History from '../reducers/reprice/priceHistory.js';
import Products from '../reducers/reprice/products.js';
import Setting from '../reducers/reprice/settings.js';
import Strategies from '../reducers/reprice/strategies.js';

import affiliate from '../reducers/affiliate.js';
import affiliateAccountSettings from '../reducers/affiliateAccountSettings.js';
import customerInvoices from '../reducers/CustomerInvoices.js';
import masterRemovalOrders from '../reducers/masterRemovalOrders.js';
import payment from '../reducers/payment.js'; 
import referals from '../reducers/referalUsers.js';
import sales from '../reducers/sales.js';
import shipments from '../reducers/shipments.js';
import userInvoices from '../reducers/userInvoices.js';
import userRemovals from '../reducers/userRemovals.js';
import vendors from '../reducers/vendors.js';
import workers from '../reducers/workers.js';

import fbmWallet from './fbmWallet';

import auth from '../slices/auth';
import internalStorage from '../slices/internalStorage';
import store from '../slices/store';
import dashboard from '../slices/dashboard';
import distroCustomers from '../slices/distroCustomer';
import fbm from '../slices/fbm';
import warehouse from '../slices/warehouse';
import lister from '../slices/lister';

import account from '../slices/account';
import fbaInbound from '../slices/fba-inbound';
import repricerDashboard from '../slices/repricerDashboard.js';
import fbaShipment from '../slices/fba-shipment.js';
import repricerProducts from '../slices/products.js';
import fbaInboundShipment from '../slices/fba-inbound-new.js';

const index = combineReducers({
  adminUsers,

  processingCatalogs,
  catalogJobs,
  invalidCatalogs,
  processedCatalogs,
  vendorProcessedCatalogs,

  removalDetails,
  fbaremovalshipments,

  masterStorageInvoices,
  userStorageInvoices,

  invoices,

  approveMatches,
  listingSku,
  profitableMatches,
  restrictedBrands,

  products,

  affiliateInvoices,
  profitCalculationManagerDashboard,

  combinedOrders,
  pendingPos,
  pendingPurchaseOrders,
  poInvoices,
  purchaseOrderDetails,
  purchasingInvoices,
  simplePurchaseOrders,
  uploadPurchaseOrder,

  allOrders,
  journal,
  pendingPoIndices,

  masterRemovalInvoices,
  removalInvoices,

  Dashboard,
  History,
  Products,
  Setting,
  Strategies,

  affiliate,
  affiliateAccountSettings,

  customerInvoices,
  masterRemovalOrders,
  payment,
  referals,
  sales,
  shipments,

  userInvoices,
  userRemovals,
  vendors,
  workers,

  auth,
  store,
  dashboard,
  fbm,
  
  fbmWallet,
  warehouse,
  distroCustomers,
  account,

  internalStorage,
  lister,
  fbaInbound,
  repricerDashboard,
  fbaShipment,
  repricerProducts,
  fbaInboundShipment
});


const rootReducer = (state, action) => {
  let { reducers } = action || {};
  
  if (reducers && action.type === 'RESET_APP') { 
    if (!isArray(reducers)) reducers = [reducers];
    
    for (let i = 0; i < reducers.length; i += 1) {
      const red = reducers[i];
      if (state[red]) {
        state[red] = undefined;
      }
    }
  }
  
  return index(state, action);
}
export default rootReducer;
