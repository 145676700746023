import { cloneDeep } from "lodash";
const initialState = {
  fetchingVendorsList: false,
  fetchingGrid: false,
  fetchingForProfitData: false,
  vendor_items: [],
  updatedVendorItem: {},
  total_records: 0,
  filters: [],
  result: {},
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  profit_data: {},
  vendors: []
};

const processedCatalogs = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GET_UPDATE_LISTINGS_DATA_REQUEST': {
      return {
        ...state,
        fetchingGrid: true
      }
    }
    case 'CLEAR_UPDATED_ROW': {
      return {
        ...state,
        updatedVendorItem: {}
      }
    }
    case 'GET_UPDATE_LISTINGS_DATA_SUCCESS': {
      const { vendor_item } = action.payload;
      return {
        ...state,
        fetchingGrid: false,
        updatedVendorItem: vendor_item
      }
    }
    case 'GET_UPDATE_LISTINGS_DATA_FAILED': {
      return {
        ...state,
        fetchingGrid: false
      }
    }
    case 'WIPE_CLEAN_REQUEST': {
      return {
        ...state,
        fetchingGrid: true
      };
    }
    case 'WIPE_CLEAN_SUCCESS': {
      return {
        ...state,
        fetchingGrid: false,
        result: action.payload
      };
    }
    case 'WIPE_CLEAN_FAILED': {
      return {
        ...state,
        fetchingGrid: false
      };
    }
    case 'CLEAR_PROFIT_DATA': {
      return {
        ...state,
        profit_data: {}
      };
    }
    case 'GET_PROFIT_DATA_SUCCESS': {
      return {
        ...state,
        fetchingForProfitData: false,
        profit_data: action.payload
      };
    }
    case 'GET_PROFIT_DATA_REQUEST': {
      return {
        ...state,
        fetchingForProfitData: true
      };
    }
    case 'GET_PROFIT_DATA_FAILED': {
      return {
        ...state,
        fetchingForProfitData: false
      };
    }
    case 'GET_ALL_PROCESSED_CATALOGS_SUCCESS': {
      const { vendor_items, total_records } = action.payload;
      return {
        ...state,
        fetchingGrid: false,
        vendor_items,
        total_records
      };
    }
    case 'GET_ALL_PROCESSED_CATALOGS_REQUEST': {
      return {
        ...state,
        fetchingGrid: true
      };
    }
    case 'GET_ALL_PROCESSED_CATALOGS_FAILED': {
      return {
        ...state,
        fetchingGrid: false
      };
    }
    case 'GET_VENDOR_NAMES_ALL_CATALOGS_REQUEST': {
      return {
        ...state,
        fetchingVendorsList: true
      };
    }
    case 'GET_VENDOR_NAMES_ALL_CATALOGS_SUCCESS': {
      return {
        ...state,
        fetchingVendorsList: false,
        vendors: action.payload ? [...action.payload] : []
      };
    }
    case 'GET_VENDOR_NAMES_ALL_CATALOGS_FAILED': {
      return {
        ...state,
        fetchingVendorsList: false
      };
    }
    case 'SET_PAGINATION_FOR_ALL_CATALOGS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    case 'SET_FILTERS_FOR_ALL_CATALOGS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_SORT_OPTIONS_FOR_ALL_CATALOGS': {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
};

export default processedCatalogs;
