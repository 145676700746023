import React from "react";
import "./style.css";

const RadioCard = ({ children, checked, onClick, onChange }) => {
  return (
    <label className={`card ${checked ? "checked" : ""}`} onClick={onClick}>
      <span className="plan-details">
        <input className="radio" type="radio" checked={checked} onChange={onChange} />
        {children}
      </span>
    </label>
  );
};

export default RadioCard;