import moment from 'moment';
import { cloneDeep } from "lodash";

const initialState = {
  fetching: false,
  affiliate_manager_id: null,
  fetchingForGraphData: false,
  summaryLoading: false,
  arrivedGraphLoading: false,
  shippedGraphLoading: false,
  panelLoading: false,
  summary_for_dashboard: [],
  shipped_units_graph: [],
  arrived_units_graph: [],
  four_panels_summary: [],
  totalShippedUnits: 0,
  totalArrivedUnits: 0,
  sales_data: [],
  total_records: 0,
  filters: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25,
  },
  users: [],
  saleStats: {
    total_orders: 0,
    total_units_sold: 0,
    total_sale_amount: 0,
    total_sale_cost_price: 0,
    total_amazon_fee: 0,
    total_taxes: 0,
    total_promotions: 0,
    total_other_finances: 0,
    total_gross_profit: 0,
    total_net_profit: 0,
  },
  dateRange: {
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month"),
  },
  inventoryStats: {
    inventory_count: 0,
    active_inventory_count: 0,
    total_sellable_quantity: 0,
    total_sellable_cost: 0,
    fba_inventory_count: 0,
    fbm_inventory_count: 0,
  },
  selectedUser: null,
  poStats: {
    total_pending_pos: 0,
    received_pending_pos: 0,
  },
  userGridDateRange: {
    startDate: moment().subtract(30, "days").startOf("day"),
    endDate: moment(),
  },
  affiliateStatsObj: {
    total_count: null,
    active_count: null,
    inactive_count: null,
    avg_units_sold: null,
    avg_profit: null
  }
};

const affiliate = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GET_AFFILIATE_USERS_FOR_ADMIN_DASHBOARD_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'SET_FILTERS_ADMIN':
    case 'SET_AFFILIATE_ID':
    case 'GET_AFFILIATE_USERS_FOR_ADMIN_DASHBOARD_SUCCESS':
    case 'GET_AFFILIATES_GRAPH_DATA_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        fetchingForGraphData: false,
        fetching: false
      };
    }
    case 'GET_AFFILIATE_USERS_FOR_ADMIN_DASHBOARD_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_PAGINATION_ADMIN_USERS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    case 'GET_AFFILIATES_GRAPH_DATA_REQUEST': {
      return {
        ...state,
        fetchingForGraphData: true
      };
    }
    case 'SET_SORT_OPTIONS_ADMIN_USERS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_ALL_USERS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_ALL_USERS_SUCCESS': {
      return {
        ...state,
        dropdownUsers: action.payload,
        fetching: false
      };
    }
    case 'GET_SALE_STATS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_SALE_STATS_SUCCESS': {
      return {
        ...state,
        fetching: false,
        saleStats: {
          ...action.payload
        }
      };
    }
    case 'GET_PO_STATS_FAILED':
    case 'GET_INVENTORY_STATS_FAILED':
    case 'GET_SALE_STATS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_DATE_RANGE_FILTER': {
      return {
        ...state,
        dateRange: {
          ...action.payload
        }
      };
    }
    case 'GET_INVENTORY_STATS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_INVENTORY_STATS_SUCCESS': {
      return {
        ...state,
        fetching: false,
        inventoryStats: {
          ...action.payload
        }
      };
    }
    case 'SET_SELECTED_USER': {
      return {
        ...state,
        selectedUser: action.payload
      };
    }
    case 'GET_PO_STATS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_PO_STATS_SUCCESS': {
      return {
        ...state,
        poStats: {
          ...action.payload
        }
      };
    }
    case 'SET_USERS_DATE_RANGE_FILTER': {
      return {
        ...state,
        userGridDateRange: {
          ...action.payload
        }
      };
    }
    case 'GET_ARRIVED_SHIPMENT_GRAPH_DATA_REQUEST': {
      return {
        ...state,
        arrivedGraphLoading: true
      };
    }

    case 'GET_ARRIVED_SHIPMENT_GRAPH_DATA_FAILED': {
      return {
        ...state,
        arrivedGraphLoading: false
      };
    }

    case 'GET_ARRIVED_SHIPMENT_GRAPH_DATA_SUCCESS': {
      const { arrived_units_graph = [], total_arrived_units } = action?.payload || {};
      return {
        ...state,
        arrivedGraphLoading: false,
        totalArrivedUnits: total_arrived_units,
        arrived_units_graph
      };
    }

    case 'GET_SHIPPED_SHIPMENT_GRAPH_DATA_REQUEST': {
      return {
        ...state,
        shippedGraphLoading: true
      };
    }

    case 'GET_SHIPPED_SHIPMENT_GRAPH_DATA_FAILED': {
      return {
        ...state,
        shippedGraphLoading: false
      };
    }
    case 'GET_SHIPPED_SHIPMENT_GRAPH_DATA_SUCCESS': {
      const { shipped_units_graph = [], total_shipped_units } = action?.payload || {};
      return {
        ...state,
        shippedGraphLoading: false,
        totalShippedUnits: total_shipped_units,
        shipped_units_graph
      };
    }

    case 'GET_DASHBOARD_SUMMARY_DATA_REQUEST': {
      return {
        ...state,
        summaryLoading: true
      };
    }

    case 'GET_DASHBOARD_SUMMARY_DATA_FAILED': {
      return {
        ...state,
        summaryLoading: false
      };
    }
    case 'GET_DASHBOARD_SUMMARY_DATA_SUCCESS': {
      const { summary_for_dashboard = [] } = action?.payload || {}
      return {
        ...state,
        summaryLoading: false,
        summary_for_dashboard
      };
    }

    case 'GET_SUMMARY_DATA_FOR_PANEL_REQUEST': {
      return {
        ...state,
        panelLoading: true
      };
    }

    case 'GET_SUMMARY_DATA_FOR_PANEL_FAILED': {
      return {
        ...state,
        panelLoading: false
      }
    }
    case 'GET_SUMMARY_DATA_FOR_PANEL_SUCCESS': {
      const { four_panels_summary = [] } = action?.payload || {}
      return {
        ...state,
        panelLoading: false,
        four_panels_summary
      };
    }
    default:
      return state;
  }
};

export default affiliate;
