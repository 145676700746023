import Styled from 'styled-components';

const CheckBoxWrapper = Styled.span`
label{
  color: ${({ theme }) => theme['regent_gray']};
  font-size: ${({ theme }) => theme['medium-font-size']};
}
.parameter-checkbox{
    input{
   width: 12px;
    height: 12px;
    margin: 6px -18px;
    }
}
.mt-0{
  input{
    margin-top:0px;
  } 
}
  input{
    margin-top:5px;
    &:focus{
      box-shadow:none
    }
  }
  `;
export { CheckBoxWrapper };
