import { cloneDeep } from "lodash";
const initialState = {
  fetchingAccountSettingDetails: false,
  updatingAccountSettingDetails: false,
  fetchingShipmentInvoiceSettingDetails: false,
  updatingShipmentInvoiceSettingDetails: false,
  convertingImageToBase64: false
};

const affiliateAccountSettings = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'FETCHING_AFFILIATE_ACCOUNT_SETTING_DETAILS_REQUEST': {
      return {
        ...state,
        fetchingAccountSettingDetails: true
      };
    }
    case 'FETCHING_AFFILIATE_ACCOUNT_SETTING_DETAILS_SUCCESS': {
      return {
        ...state,
        fetchingAccountSettingDetails: false,
        ...action.payload
      };
    }
    case 'FETCHING_AFFILIATE_ACCOUNT_SETTING_DETAILS_FAILED': {
      return {
        ...state,
        fetchingAccountSettingDetails: false
      };
    }

    case 'UPDATING_AFFILIATE_ACCOUNT_SETTING_DETAILS_REQUEST': {
      return {
        ...state,
        updatingAccountSettingDetails: true
      };
    }
    case 'UPDATING_AFFILIATE_ACCOUNT_SETTING_DETAILS_SUCCESS':
    case 'UPDATING_AFFILIATE_ACCOUNT_SETTING_DETAILS_FAILED': {
      return {
        ...state,
        updatingAccountSettingDetails: false,
      };
    }

    case 'FETCH_SHIPMENT_INVOICE_SETTING_DETAILS_REQUEST': {
      return {
        ...state,
        fetchingShipmentInvoiceSettingDetails: true
      };
    }
    case 'FETCH_SHIPMENT_INVOICE_SETTING_DETAILS_SUCCESS':
    case 'FETCH_SHIPMENT_INVOICE_SETTING_DETAILS_FAILED': {
      return {
        ...state,
        fetchingShipmentInvoiceSettingDetails: false
      };
    }

    case 'UPDATE_SHIPMENT_INVOICE_SETTING_DETAILS_REQUEST': {
      return {
        ...state,
        updatingShipmentInvoiceSettingDetails: true
      };
    }
    case 'UPDATE_SHIPMENT_INVOICE_SETTING_DETAILS_SUCCESS':
    case 'UPDATE_SHIPMENT_INVOICE_SETTING_DETAILS_FAILED': {
      return {
        ...state,
        updatingShipmentInvoiceSettingDetails: false
      };
    }

    case 'CONVERTING_IMAGE_TO_BASE64_REQUEST': {
      return {
        ...state,
        convertingImageToBase64: true
      };
    }
    case 'CONVERTING_IMAGE_TO_BASE64_SUCCESS': {
      return {
        ...state,
        convertingImageToBase64: false
      };
    }

    default:
      return state;
  }
};

export default affiliateAccountSettings;
