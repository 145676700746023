import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleRejectedResponse } from './utils';
import { isEmpty, isArray, filter } from 'lodash';
import axios from '../../axios_config';
import axiosSimple from 'axios';
import { notification } from 'antd';


export const UploadNewLocation = createAsyncThunk(
  '/upload-new-location',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/api/v2/storages`,
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Uplaod Location', rejectWithValue);
    }
  }
);

export const UpdateExistingLocation = createAsyncThunk(
  '/update-existing-location',
  async (data, { getState, rejectWithValue }) => {
    const { id } = data;
    try {
      const response = await axios.patch(
        `/api/v2/storages/${id}`,
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Location', rejectWithValue);
    }
  }
);

export const listExistingLocations = createAsyncThunk(
  '/list-existing-locations',
  async (data, { getState, rejectWithValue }) => {
    const { pagination: { pageNumber, pageLimit } = {}, searchValue, warehouse, userName } = data || {};
    const params = {
      page_num: Number(pageNumber), 
      per_page: Number(pageLimit),
      search_value: searchValue,
      assigned_user_id: userName,
      warehouse_user_id: warehouse
    }
    try {
      const response = await axios.get(
        `/api/v2/storages`,
        { params }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'List Locations', rejectWithValue);
    }
  }
);

export const ListLocationItems = createAsyncThunk(
  '/list-location-items',
  async (data, { getState, rejectWithValue }) => {
    const { locationId, searchValue, pagination } = data || {};
    const { pageNumber, pageLimit } = pagination || {}
    const params = {
      page_num: pageNumber ? Number(pageNumber): null, 
      per_page: pageLimit ? Number(pageLimit): null,
      search_value: searchValue
    };
    try {
      const response = await axios.get(
        `/api/v2/storages/${locationId}`,
        { params }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'List Location Items', rejectWithValue);
    }
  }
);

export const ListUserLocationItems = createAsyncThunk(
  '/list-user-location-items',
  async (data, { getState, rejectWithValue }) => {
    const { locationId, searchValue, pagination } = data || {};
    const { pageNumber, pageLimit } = pagination || {}
    const params = {
      page_num: pageNumber ? Number(pageNumber): null, 
      per_page: pageLimit ? Number(pageLimit): null,
      search_value: searchValue
    };
    try {
      const response = await axios.get(
        '/api/v2/staging_items/all_items',
        { params }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'List Items', rejectWithValue);
    }
  }
);

export const GetScannedItem = createAsyncThunk(
  '/get-scanned-item',
  async (data, { getState, rejectWithValue }) => {
    const { id } = data || {};
    try {
      const response = await axios.get(
        `/api/v2/staging_items/${id}`
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Items', rejectWithValue);
    }
  }
);

export const AddItemToLocation = createAsyncThunk(
  '/add-item-to-location',
  async (data, { getState, rejectWithValue }) => {
    const { id } = data || {};
    try {
      const response = await axios.post(
        'api/v2/storages/add_item',
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Add Items', rejectWithValue);
    }
  }
);

export const TransferItemToNewLocation = createAsyncThunk(
  '/transfer-product-to-new-location',
  async (data, { getState, rejectWithValue }) => {
    const { itemId, oldLocationId, newLocationId } = data || {};
    try {
      const response = await axios.post(
        '/api/v2/staging_items/change_location',
        {
          staging_item_id: itemId,
          old_storage_id: oldLocationId,
          new_storage_id: newLocationId
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Transfer Items', rejectWithValue);
    }
  }
);

export const GetLocationHistories = createAsyncThunk(
  '/get-location-histories',
  async (data, { getState, rejectWithValue }) => {
    const { searchValue, pagination: { pageNumber, pageLimit } = {}, locationId } = data || {};
    const params = {
      page_num: pageNumber? Number(pageNumber): null,
      per_page: pageLimit? Number(pageLimit): null,
      search_value: searchValue
    }
    try {
      const response = await axios.get(
        `/api/v2/storage_histories/${locationId}`,
        { params }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Location Histories', rejectWithValue);
    }
  }
);

export const GetPickListLocations = createAsyncThunk(
  '/get-pick-list-location',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        '/api/v2/pick_up_lists/pickup_locations'
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Locations', rejectWithValue);
    }
  }
);

export const GetPickListLocationDetail = createAsyncThunk(
  '/get-pick-list-location-detail',
  async (data, { getState, rejectWithValue }) => {
    const { id } = data || {};
    try {
      const response = await axios.get(
        `/api/v2/pick_up_lists/${id}/pickup_location_details`
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Location Details', rejectWithValue);
    }
  }
);

export const GetPickListItemsDetail = createAsyncThunk(
  '/get-pick-list-items-detail',
  async (data, { getState, rejectWithValue }) => {
    const { id, storageId } = data || {};
    try {
      const response = await axios.get(
        `api/v2/pick_up_lists/${id}/pickup_item_details?storage_id=${storageId}`
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Item Details', rejectWithValue);
    }
  }
);

export const MarkedAsPicked = createAsyncThunk(
  '/marked-as-picked',
  async (data, { getState, rejectWithValue }) => {
    const { id } = data || {};
    try {
      const response = await axios.post(
        'api/v2/pick_up_lists/mark_as_picked',
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Marked Pick', rejectWithValue);
    }
  }
);

export const RelabelStorageItem = createAsyncThunk(
  '/relabel-storage-item',
  async (data, { getState, rejectWithValue }) => {
    const { id } = data || {};
    try {
      const response = await axios.post(
        `/api/v2/staging_items/${id}/relist`,
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Relabel Item', rejectWithValue);
    }
  }
);

export const LookupExistingListing = createAsyncThunk(
  '/lookup-existing-listing',
  async (data, { getState, rejectWithValue }) => {
    const { asin, user_id } = data || {};
    try {
      const response = await axios.get(
        '/api/v2/fba_removal_shipments/check_valid_asin',
        { params: { 
            asin,
            user_id 
          }
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Validate ASIN', rejectWithValue);
    }
  }
);

export const SubmitAddToWorkOrder = createAsyncThunk(
  '/add-to-work-order',
  async (data, { getState, rejectWithValue }) => {
    const { id, quantity, isLooseQty, pack_count } = data || {};
    try {
      const response = await axios.post(
        `/api/v2/staging_items/${id}/generate_work_order`,
        {
          quantity: quantity / pack_count,
          bundle_quantity: pack_count,
          loose_box: isLooseQty
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Validate ASIN', rejectWithValue);
    }
  }
);

export const GetWarehouseUsers = createAsyncThunk(
  '/get-warehouse-user',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        'api/v2/storages/warehouse_users'
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Validate ASIN', rejectWithValue);
    }
  }
);

export const GetUserWarehouses = createAsyncThunk(
  '/get-user-warehouses',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        'api/v2/storages/assigned_users'
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Validate ASIN', rejectWithValue);
    }
  }
);

export const UploadBulkLocations = createAsyncThunk(
  '/upload-bulk-locations',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/api/v2/storages/bulk_create',
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Upload Bulk Locations', rejectWithValue);
    }
  }
);

export const DeleteWarehouseLocation = createAsyncThunk(
  '/delete-warehouse-location',
  async (data, { getState, rejectWithValue }) => {
    const { locationId } = data;
    try {
      const response = await axios.delete(
        `api/v2/storages/${locationId}`
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Delete Location', rejectWithValue);
    }
  }
);

export const DisposeLocationItem = createAsyncThunk(
  '/dispose-location-item',
  async (data, { getState, rejectWithValue }) => {
    const { id } = data;
    try {
      const response = await axios.post(
        `/api/v2/staging_items/${id}/dispose`
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Dipose Item', rejectWithValue);
    }
  }
);

export const GetEcomExportData = createAsyncThunk(
  '/staging_items/ecom_export',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        '/api/v2/staging_items/ecom_export'
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Ecom Export Data', rejectWithValue);
    }
  }
);

export const GetPickedLocationCount = createAsyncThunk(
  '/get-picked-location-count',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
       'api/v2/pick_up_lists/pickup_locations_count',
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Pick Up Locations', rejectWithValue);
    }
  }
);

export const GetStagingAsins = createAsyncThunk(
  '/api/v2/staging_asins',
  async ({ filters, pagination }, { getState, rejectWithValue }) => {
    try {
      const { page_num, per_page } = pagination || {};
      const params = {
        page_num: Number(page_num), 
        per_page: Number(per_page),
        filters_hash: {
          filters
        }
      }
      const response = await axios.get(
       `/api/v2/staging_asins`,
       { params }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Staging Asins', rejectWithValue);
    }
  }
);

export const GetStagingAsinsWarehouse = createAsyncThunk(
  '/api/v2/staging_asins-warehouse',
  async ({ filters, pagination }, { getState, rejectWithValue }) => {
    try {
      const { page_num, per_page } = pagination || {};
      const params = {
        page_num: Number(page_num), 
        per_page: Number(per_page),
        filters_hash: {
          filters
        }
      }
      const response = await axios.get(
       `/api/v2/staging_asins/warehouse_side`,
       { params }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Staging Asins', rejectWithValue);
    }
  }
);

export const GetStagingItems = createAsyncThunk(
  '/api/v2/staging_asins/:id/staging_items_for_staging_asin',
  async (staging_asin_id, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
       `/api/v2/staging_asins/${staging_asin_id}/staging_items_for_staging_asin`,
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Staging Items', rejectWithValue);
    }
  }
);

export const ListToShopify = createAsyncThunk(
  '/api/v2/staging_asins/list_to_shopify_using_lister',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/api/v2/staging_asins/list_to_shopify_using_lister`,
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'List To Shopify', rejectWithValue);
    }
  }
);

export const GetWarehouses = createAsyncThunk(
  '/api/v2/staging_asins/inventory_dropdown_warehouses',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v2/staging_asins/inventory_dropdown_warehouses`,
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Fetch Warehouses', rejectWithValue);
    }
  }
);

export const UpdateUnitPrice = createAsyncThunk(
  '/api/v2/staging_asins/:staging_asin_id',
  async ({ staging_asin_id, field, value }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `/api/v2/staging_asins/${staging_asin_id}`,
        { [field]: value }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Unit Price', rejectWithValue);
    }
  }
);

export const UpdateUpc = createAsyncThunk(
  '/api/v2/staging_asins/:id/update_upc',
  async ({ staging_asin_id, upc }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/api/v2/staging_asins/${staging_asin_id}/update_upc`,
        { upc }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Upc', rejectWithValue);
    }
  }
);

export const GetAffiliateUsers = createAsyncThunk(
  '/api/v2/staging_asins/affiliate_users',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v2/staging_asins/affiliate_users`
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Fetch Affiliate Users', rejectWithValue);
    }
  }
);

export const ListToShopifyAffiliates = createAsyncThunk(
  '/api/v2/staging_asins/list_to_shopify_affiliates',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/api/v2/staging_asins/list_to_shopify_affiliates`,
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'List To Shopify Affiliates', rejectWithValue);
    }
  }
);

export const RemoveListing = createAsyncThunk(
  '/api/v2/staging_asins/:id/remove_listing',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { id } = data || {};
      delete data.id;
      const response = await axios.post(
        `/api/v2/staging_asins/${id}/remove_listing`,
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Remove Listing', rejectWithValue);
    }
  }
);

export const RemoveAffiliateListing = createAsyncThunk(
  '/api/v2/affiliate_listings/:id/remove_listing',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { id } = data || {};
      delete data.id;
      const response = await axios.post(
        `/api/v2/affiliate_listings/${id}/remove_listing`,
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Remove Affiliate Listing', rejectWithValue);
    }
  }
);

export const GetAffiliatesShopifyListing = createAsyncThunk(
  '/api/v2/staging_asins/:id/affiliate_shopify_listings',
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v2/staging_asins/${id}/affiliate_shopify_listings`,
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Affiliates Shopify Listing', rejectWithValue);
    }
  }
);

const internalStorage = createSlice({
  name: "internalStorage",
  initialState: {
    locations: [],
    createLocationLoading: false,
    updateLocationLoading: false,
    listLocations: false,
    listLocationLoading: false,
    scannedItemLoading: false,
    addItemLoading: false,
    tranferItemLoading: false,
    locationHistoryLoading: false,
    pickLocationLoading: false,
    pickItemsLoading: false,
    pickLocationDetailLoading: false,
    markedPickLoading: false,
    relabelingLoading: false,
    verifyListingLoading: false,
    workorderLoading: false,
    warehouseUsersLoading: false,
    userWarehousesLoading: false,
    uploadBulkLoading: false,
    deleteLocationLoading: false,
    userItemLoading: false,
    disposeItemLoading: false,
    fetchingEcomExportData: false,
    pickupCountLoading: false,
    itemsCount: 0,
    storageCount: 0,
    historyCount: 0,
    locationItemList: [],
    selectedLocation: {},
    scannedItem: {},
    selectedLocationId: {},
    scannedProductToTransfer: {},
    selectedOldLocation: {},
    selectedNewLocation: {},
    storageHistories: [],
    pickUpLocationList: [],
    pickUpLocationDetails: [],
    pickUpItemsList: [],
    warehouseUsers: [],
    userWarehouses: [],
    pickupLocationCount: 0,
    fetchingStagingAsins: false,
    stagingAsins: [],
    stagingAsinsWarehouse: [],
    totalStagingAsinsCount: 0,
    fetchingStagingItems: false,
    stagingItems: [],
    listingToShopify: false,
    fetchingWarehouses: false,
    warehouses: [],
    updatingUnitPrice: false,
    updatingUpc: false,
    fetchingAffiliateUsers: false,
    affiliateUsers: [],
    listingToShopifyAffiliates: false,
    removingListing: false,
    fetchingAffiliatesShopifyListing: false,
    affiliatesShopifyListings: [],
    removingAffiliateListing: false,
  },
  reducers: {
    setField(state, { payload: { field, value } }) {
      state[field] = value;
    },
    clearState(state) {
      state['user']['email'] = '';
      state['token'] = '';
      state['parentToken'] = '';
      state['currentUser'] = {};
      state['parentUser'] = {};
      state['isImpersonated'] = false;
      state['isImpersonatedCurrentUser'] = false;
    },
    clearStoragesData(state, action) {
      state['locations'] = []
      state['locationItemList'] = []
      state['selectedLocation'] = {}
      state['scannedItem'] = {}
      state['selectedLocationId'] = {}
      state['scannedProductToTransfer'] = {}
      state['selectedOldLocation'] = {}
      state['selectedNewLocation'] = {}
      state['storageHistories'] = {}
      state['pickUpLocationList'] = {}
      state['pickUpLocationDetails'] = {}
      state['pickUpItemsList'] = {}
    },
    updateField(state, { payload: { field, index, key, value } }) {
      state[field][index][key] = value;
    },
    removeDataEntry(state, { payload: { field, key, value } }) {
      state[field] = filter(state[field], obj => (obj[key] !== value));
    },
    clearField(state, { payload: { field } }) {
      state[field] = isArray(state[field]) ? [] : {};
    },
  },
  extraReducers: {
    [UploadNewLocation.pending]: (state, action) => ({
      ...state,
      createLocationLoading: true
    }),
    [UploadNewLocation.fulfilled]: (state, action) => {
      notification.success({
        message: 'Location uploaded successfully',
        top: 65,
      })
      return {
        ...state,
        createLocationLoading: false
      }
    },
    [UploadNewLocation.rejected]: (state, action) => {
      return {
        ...state,
        createLocationLoading: false
      }
    },
    [UpdateExistingLocation.pending]: (state, action) => ({
      ...state,
      updateLocationLoading: true
    }),
    [UpdateExistingLocation.fulfilled]: (state, action) => {
      notification.success({
        message: 'Location updated successfully',
        top: 65,
      })
      return {
        ...state,
        updateLocationLoading: false
      }
    },
    [UpdateExistingLocation.rejected]: (state, action) => {
      return {
        ...state,
        updateLocationLoading: false
      }
    },
    [listExistingLocations.pending]: (state, action) => ({
      ...state,
      listLocations: true,
      locations: []
    }),
    [listExistingLocations.fulfilled]: (state, action) => {
      const { locations, count } = action?.payload?.data || {};
      return {
        ...state,
        locations,
        storageCount: count,
        listLocations: false
      }
    },
    [listExistingLocations.rejected]: (state, action) => {
      return {
        ...state,
        listLocations: false
      }
    },
    [ListLocationItems.pending]: (state, action) => ({
      ...state,
      listLocationLoading: true,
      locationItemList: []
    }),
    [ListLocationItems.fulfilled]: (state, action) => {
      const { locations } = action?.payload?.data || {};
      return {
        ...state,
        listLocationLoading: false,
        locationItemList: locations
      }
    },
    [ListLocationItems.rejected]: (state, action) => {
      return {
        ...state,
        listLocationLoading: false
      }
    },
    [GetScannedItem.pending]: (state, action) => ({
      ...state,
      scannedItem: {},
      scannedItemLoading: true
    }),
    [GetScannedItem.fulfilled]: (state, action) => {
      const { staging_item } = action?.payload?.data || {};
      return {
        ...state,
        scannedItemLoading: false,
        scannedItem: staging_item
      }
    },
    [GetScannedItem.rejected]: (state, action) => {
      return {
        ...state,
        scannedItemLoading: false
      }
    },
    [AddItemToLocation.pending]: (state, action) => ({
      ...state,
      addItemLoading: true
    }),
    [AddItemToLocation.fulfilled]: (state, action) => {
      notification.success({
        message: 'Product Added successfully',
        top: 65,
      })
      return {
        ...state,
        addItemLoading: false
      }
    },
    [AddItemToLocation.rejected]: (state, action) => {
      return {
        ...state,
        addItemLoading: false
      }
    },
    [TransferItemToNewLocation.pending]: (state, action) => ({
      ...state,
      tranferItemLoading: true
    }),
    [TransferItemToNewLocation.fulfilled]: (state, action) => {
      notification.success({
        message: 'Product transfered successfully',
        top: 65,
      })
      return {
        ...state,
        tranferItemLoading: false
      }
    },
    [TransferItemToNewLocation.rejected]: (state, action) => {
      return {
        ...state,
        tranferItemLoading: false
      }
    },
    [GetLocationHistories.pending]: (state, action) => ({
      ...state,
      locationHistoryLoading: true,
      storageHistories: [],
      historyCount: 0
    }),
    [GetLocationHistories.fulfilled]: (state, action) => {
      const { storage_histories, count } = action?.payload?.data || {};
      return {
        ...state,
        storageHistories: storage_histories,
        historyCount: count,
        locationHistoryLoading: false
      }
    },
    [GetLocationHistories.rejected]: (state, action) => {
      return {
        ...state,
        locationHistoryLoading: false
      }
    },
    [GetPickListLocations.pending]: (state, action) => ({
      ...state,
      pickLocationLoading: true,
      pickUpLocationList: []
    }),
    [GetPickListLocations.fulfilled]: (state, action) => {
      const { locations } = action?.payload?.data || {};
      return {
        ...state,
        pickUpLocationList: locations,
        pickLocationLoading: false
      }
    },
    [GetPickListLocations.rejected]: (state, action) => {
      return {
        ...state,
        pickLocationLoading: false
      }
    },
    [GetPickListLocationDetail.pending]: (state, action) => ({
      ...state,
      pickLocationDetailLoading: true,
      pickUpLocationDetails: []
    }),
    [GetPickListLocationDetail.fulfilled]: (state, action) => {
      const { locations } = action?.payload?.data || {};
      return {
        ...state,
        pickUpLocationDetails: locations,
        pickLocationDetailLoading: false
      }
    },
    [GetPickListLocationDetail.rejected]: (state, action) => {
      return {
        ...state,
        pickLocationDetailLoading: false
      }
    },
    [GetPickListItemsDetail.pending]: (state, action) => ({
      ...state,
      pickItemsLoading: true,
      pickUpItemsList: []
    }),
    [GetPickListItemsDetail.fulfilled]: (state, action) => {
      const { staging_item } = action?.payload?.data || {};
      return {
        ...state,
        pickUpItemsList: staging_item,
        pickItemsLoading: false
      }
    },
    [GetPickListItemsDetail.rejected]: (state, action) => {
      return {
        ...state,
        pickItemsLoading: false,
        pickUpItemsList: []
      }
    },
    [MarkedAsPicked.pending]: (state, action) => ({
      ...state,
      markedPickLoading: true
    }),
    [MarkedAsPicked.fulfilled]: (state, action) => {
      return {
        ...state,
        markedPickLoading: false
      }
    },
    [MarkedAsPicked.rejected]: (state, action) => {
      return {
        ...state,
        markedPickLoading: false
      }
    },
    [RelabelStorageItem.pending]: (state, action) => ({
      ...state,
      relabelingLoading: true
    }),
    [RelabelStorageItem.fulfilled]: (state, action) => {
      const { message } = action?.payload?.data || {};
      notification.success({
        message: message || 'ReList Job Added Successfully!',
        top: 65,
      })
      return {
        ...state,
        relabelingLoading: false
      }
    },
    [RelabelStorageItem.rejected]: (state, action) => {
      return {
        ...state,
        relabelingLoading: false
      }
    },
    [LookupExistingListing.pending]: (state, action) => ({
      ...state,
      verifyListingLoading: true
    }),
    [LookupExistingListing.fulfilled]: (state, action) => {
      return {
        ...state,
        verifyListingLoading: false
      }
    },
    [LookupExistingListing.rejected]: (state, action) => {
      return {
        ...state,
        verifyListingLoading: false
      }
    },
    [SubmitAddToWorkOrder.pending]: (state, action) => ({
      ...state,
      workorderLoading: true
    }),
    [SubmitAddToWorkOrder.fulfilled]: (state, action) => {
      const { message } = action?.payload?.data || {};
      notification.success({
        message: message || 'Added work order successfully!',
        top: 65,
      });
      return {
        ...state,
        workorderLoading: false
      }
    },
    [SubmitAddToWorkOrder.rejected]: (state, action) => {
      return {
        ...state,
        workorderLoading: false
      }
    },
    [GetWarehouseUsers.pending]: (state, action) => ({
      ...state,
      warehouseUsersLoading: true,
      warehouseUsers: []
    }),
    [GetWarehouseUsers.fulfilled]: (state, action) => {
      const { data } = action?.payload || {};
      return {
        ...state,
        warehouseUsersLoading: false,
        warehouseUsers: data
      }
    },
    [GetWarehouseUsers.rejected]: (state, action) => {
      return {
        ...state,
        warehouseUsersLoading: false
      }
    },
    [GetUserWarehouses.pending]: (state, action) => ({
      ...state,
      userWarehousesLoading: true,
      userWarehouses: []
    }),
    [GetUserWarehouses.fulfilled]: (state, action) => {
      const { data } = action?.payload || {}
      return {
        ...state,
        userWarehousesLoading: false,
        userWarehouses: data
      }
    },
    [GetUserWarehouses.rejected]: (state, action) => {
      return {
        ...state,
        userWarehousesLoading: false
      }
    },
    [UploadBulkLocations.pending]: (state, action) => ({
      ...state,
      uploadBulkLoading: true
    }),
    [UploadBulkLocations.fulfilled]: (state, action) => {
      notification.success({
        message: 'Locations uploaded successfully',
        top: 65,
      })
      return {
        ...state,
        uploadBulkLoading: false,
      }
    },
    [UploadBulkLocations.rejected]: (state, action) => {
      return {
        ...state,
        uploadBulkLoading: false
      }
    },
    [DeleteWarehouseLocation.pending]: (state, action) => ({
      ...state,
      deleteLocationLoading: true
    }),
    [DeleteWarehouseLocation.fulfilled]: (state, action) => {
      notification.success({
        message: 'Locations Delted successfully',
        top: 65,
      })
      return {
        ...state,
        deleteLocationLoading: false,
      }
    },
    [DeleteWarehouseLocation.rejected]: (state, action) => {
      return {
        ...state,
        deleteLocationLoading: false
      }
    },
    [ListUserLocationItems.pending]: (state, action) => ({
      ...state,
      userItemLoading: true,
      userLocationItems: []
    }),
    [ListUserLocationItems.fulfilled]: (state, action) => {
      const { staging_items, count } = action?.payload?.data || {};
      return {
        ...state,
        userLocationItems: staging_items,
        itemsCount: count,
        userItemLoading: false,
      }
    },
    [ListUserLocationItems.rejected]: (state, action) => {
      return {
        ...state,
        userItemLoading: false
      }
    },
    [DisposeLocationItem.pending]: (state, action) => ({
      ...state,
      disposeItemLoading: true
    }),
    [DisposeLocationItem.fulfilled]: (state, action) => {
      notification.success({
        message: 'Status Updated as dispose',
        top: 65,
      })
      return {
        ...state,
        disposeItemLoading: false,
      }
    },
    [DisposeLocationItem.rejected]: (state, action) => {
      return {
        ...state,
        disposeItemLoading: false
      }
    },
    [GetEcomExportData.pending]: (state, action) => ({
      ...state,
      fetchingEcomExportData: true
    }),
    [GetEcomExportData.fulfilled]: (state, action) => {
      return {
        ...state,
        fetchingEcomExportData: false
      }
    },
    [GetEcomExportData.rejected]: (state, action) => ({
        ...state,
        fetchingEcomExportData: false
    }),
    [GetPickedLocationCount.pending]: (state, action) => ({
      ...state,
      pickupCountLoading: true,
      pickupLocationCount: 0
    }),
    [GetPickedLocationCount.fulfilled]: (state, action) => {
      const { count = 0 } = action?.payload?.data || {};
      return {
        ...state,
        pickupCountLoading: false,
        pickupLocationCount: count
      }
    },
    [GetPickedLocationCount.rejected]: (state, action) => ({
        ...state,
        pickupCountLoading: false,
        pickupLocationCount: 0
    }),
    [GetStagingAsins.pending]: (state, action) => ({
      ...state,
      fetchingStagingAsins: true,
    }),
    [GetStagingAsins.fulfilled]: (state, action) => {
      const { staging_asins, count } = action?.payload?.data || {};

      return {
        ...state,
        fetchingStagingAsins: false,
        stagingAsins: staging_asins,
        totalStagingAsinsCount: count
      }
    },
    [GetStagingAsins.rejected]: (state, action) => ({
      ...state,
      fetchingStagingAsins: false,
    }),
    [GetStagingAsinsWarehouse.pending]: (state, action) => ({
      ...state,
      fetchingStagingAsins: true,
    }),
    [GetStagingAsinsWarehouse.fulfilled]: (state, action) => {
      const { staging_asins, count } = action?.payload?.data || {};

      return {
        ...state,
        fetchingStagingAsins: false,
        stagingAsinsWarehouse: staging_asins,
        totalStagingAsinsCount: count
      }
    },
    [GetStagingAsinsWarehouse.rejected]: (state, action) => ({
      ...state,
      fetchingStagingAsins: false,
    }),
    [GetStagingItems.pending]: (state, action) => ({
      ...state,
      fetchingStagingItems: true,
    }),
    [GetStagingItems.fulfilled]: (state, action) => {
      const { staging_items } = action?.payload?.data || {};

      return {
        ...state,
        fetchingStagingItems: false,
        stagingItems: staging_items
      }
    },
    [GetStagingItems.rejected]: (state, action) => ({
      ...state,
      fetchingStagingItems: false,
    }),
    [ListToShopify.pending]: (state, action) => ({
      ...state,
      listingToShopify: true,
    }),
    [ListToShopify.fulfilled]: (state, action) => {
      const { message, success } = action?.payload?.data || {};
      const notificationObj = {
        top: 65,
        message: 'List To Shopify',
        description: message
      };
      success ? notification.success(notificationObj)
        : notification.error(notificationObj);

      return {
        ...state,
        listingToShopify: false,
      }
    },
    [ListToShopify.rejected]: (state, action) => ({
      ...state,
      listingToShopify: false,
    }),
    [GetWarehouses.pending]: (state, action) => ({
      ...state,
      fetchingWarehouses: true,
    }),
    [GetWarehouses.fulfilled]: (state, action) => {
      const { warehouses } = action?.payload?.data || {};

      return {
        ...state,
        fetchingWarehouses: false,
        warehouses
      }
    },
    [GetWarehouses.rejected]: (state, action) => ({
      ...state,
      fetchingWarehouses: false,
    }),
    [UpdateUnitPrice.pending]: (state, action) => ({
      ...state,
      updatingUnitPrice: true,
    }),
    [UpdateUnitPrice.fulfilled]: (state, action) => {
      const { message, success } = action?.payload?.data || {};
      const notificationObj = {
        top: 65,
        message: 'Update Unit Price',
        description: message
      };
      success ? notification.success(notificationObj)
        : notification.error(notificationObj);

      return {
        ...state,
        updatingUnitPrice: false
      }
    },
    [UpdateUnitPrice.rejected]: (state, action) => ({
      ...state,
      updatingUnitPrice: false,
    }),
    [UpdateUpc.pending]: (state, action) => ({
      ...state,
      updatingUpc: true,
    }),
    [UpdateUpc.fulfilled]: (state, action) => {
      const { message, success } = action?.payload?.data || {};
      const notificationObj = {
        top: 65,
        message: 'Update Upc',
        description: message
      };
      success ? notification.success(notificationObj)
        : notification.error(notificationObj);

      return {
        ...state,
        updatingUpc: false
      }
    },
    [UpdateUpc.rejected]: (state, action) => ({
      ...state,
      updatingUpc: false,
    }),
    [GetAffiliateUsers.pending]: (state, action) => ({
      ...state,
      fetchingAffiliateUsers: true,
    }),
    [GetAffiliateUsers.fulfilled]: (state, action) => {
      const { users: affiliateUsers } = action?.payload?.data || {};

      return {
        ...state,
        fetchingAffiliateUsers: false,
        affiliateUsers
      }
    },
    [GetAffiliateUsers.rejected]: (state, action) => ({
      ...state,
      fetchingAffiliateUsers: false,
    }),
    [ListToShopifyAffiliates.pending]: (state, action) => ({
      ...state,
      listingToShopifyAffiliates: true,
    }),
    [ListToShopifyAffiliates.fulfilled]: (state, action) => {
      const { message, success } = action?.payload?.data || {};
      const notificationObj = {
        top: 65,
        message: 'List To Shopify (Affiliates)',
        description: message
      };
      success ? notification.success(notificationObj)
        : notification.error(notificationObj);

      return {
        ...state,
        listingToShopifyAffiliates: false
      }
    },
    [ListToShopifyAffiliates.rejected]: (state, action) => ({
      ...state,
      listingToShopifyAffiliates: false,
    }),
    [RemoveAffiliateListing.pending]: (state, action) => ({
      ...state,
      removingAffiliateListing: true,
    }),
    [RemoveAffiliateListing.fulfilled]: (state, action) => {
      const { message, success } = action?.payload?.data || {};
      const notificationObj = {
        top: 65,
        message: 'Remove Affiliate Listing',
        description: message
      };
      success ? notification.success(notificationObj)
        : notification.error(notificationObj);

      return {
        ...state,
        removingAffiliateListing: false
      }
    },
    [RemoveAffiliateListing.rejected]: (state, action) => ({
      ...state,
      removingAffiliateListing: false,
    }),
    [RemoveListing.pending]: (state, action) => ({
      ...state,
      removingListing: true,
    }),
    [RemoveListing.fulfilled]: (state, action) => {
      const { message, success } = action?.payload?.data || {};
      const notificationObj = {
        top: 65,
        message: 'Remove Listing',
        description: message
      };
      success ? notification.success(notificationObj)
        : notification.error(notificationObj);

      return {
        ...state,
        removingListing: false
      }
    },
    [RemoveListing.rejected]: (state, action) => ({
      ...state,
      removingListing: false,
    }),
    [GetAffiliatesShopifyListing.pending]: (state, action) => ({
      ...state,
      fetchingAffiliatesShopifyListing: true,
    }),
    [GetAffiliatesShopifyListing.fulfilled]: (state, action) => {
      const { affiliate_listings: affiliatesShopifyListings } = action?.payload?.data || {};

      return {
        ...state,
        fetchingAffiliatesShopifyListing: false,
        affiliatesShopifyListings
      }
    },
    [GetAffiliatesShopifyListing.rejected]: (state, action) => ({
      ...state,
      fetchingAffiliatesShopifyListing: false,
    }),
  }
});

const { reducer, actions } = internalStorage;
export const { setField, clearStoragesData, updateField, clearField, removeDataEntry } = actions;
export default reducer;