import Styled from 'styled-components';

const SelectWrapper = Styled.div`
width: ${(props) => (props.width ? props.width : "100%")};
&.feed-select{
    margin-right:4px;
    .select2-container{
      margin-bottom:21px;
    }
}
  label{
    margin-bottom:4px;
    display: flex;
    color: ${({ theme }) => theme['regent_gray']};
    font-size: ${({ theme }) => theme['base-font-size-small']};
    span:nth-child(1){
      color:${({ theme }) => theme['secondary-color']};
      padding: 0px 10px;
      .icon-question{ 
          font-size:${({ theme }) => theme['font-size-small']};
      }
    }
  }
  .select2-selection__menu{
    z-index:4;
  }
  .select2-container {
      display: flex;
      flex-direction:column ;
      margin-bottom:16px;
      margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '16px')};
      width: ${(props) => (props.width ? props.width : '100%')};
      &.w-134{
        min-width:165px;
        margin-bottom:0px;
      }
      &.w-165{
        min-width:165px;
        margin-bottom:0px;
      }
      &.large-select{
          .select2-selection__control{
          min-height:48px;
          max-height: 48px;
        }
      }
      &.label-bold{
        label{
          color:#4E5969;
          font-size:13px;
          font-weight:600
        }
      }
      &.w-190{
        min-width:190px;
        margin-bottom:0px;
      }
      &.w-276{
        min-width:276px;
      }
      &.w-144{
        width:144px;
        margin-bottom:0px;
      }
      &.w-188{
        width:188px;
        margin-bottom:0px;
      }
      &.aw-276{
        width:276px;
      }
      &.w-140{
        max-width:140px;
      }
      &.w-92{
        max-width:92px;
      }
      &.source-order-select{
        margin-top: 1px;
        label{
          margin-bottom: 11px;
          color: #78909C;
          font-size: 12px;
        }
      }
      &.order-select{
        width:134px;
        .select2-selection__control{
          min-height:30px;
          max-height: 30px;
        }
        }
        &.error{
          .select2-selection__control{
            border: 1px solid #CF0909!important;
          }
      }
        &.input-select{
        width: 65px;
        .select2-selection__control{
          border-left:0;
          border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
          .select2-selection__placeholder{
            display: none;
        }
      }
      }
      &.remove-reason-select{
        width: 550px;
        margin-bottom:0px;
        .select2-selection__control{
          .select2-selection__placeholder{
            display: none;
          }
        }
      }
  .select2-selection__option--is-selected{
    h3,span{
      color:#fff!important;
    }
  } 
.select2-selection__placeholder,
.select2-selection__single-value {
  color: ${({ theme }) => theme['regent_gray']};
  font-size: ${({ theme }) => theme['base-font-size-small']};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
  .select2-selection__control {
    border: 1px solid #CFD8DC;
    max-height: 32px;
    min-height:32px;
    &:focus {
      outline: none;
    }
    &.select2-selection__control--is-focused {
      box-shadow: none !important;
    }
    .select2-selection__rendered {
      line-height: 36px;
      padding-left: 12px;
      color: $input-color;
    }

    .select2-selection__arrow {
      height: 34px;
      width: 34px;
      right: 3px;

      b {
        border-color: $gray-500 transparent transparent transparent;
        border-width: 6px 6px 0 6px;
      }
    }

    .select2-selection__placeholder {
      color: #ccc;
    }
    svg{
        color:${({ theme }) => theme['primary-color']};
        height:15px;
        width:15px;
    }
  }
  .select2-selection__indicator-separator {
  display: none;
  }
  .select2-selection__input-container{
      margin:0;
  }
}
.select2-selection__multi-value__remove {
  padding-left: 0;
  padding-right: 0;
}
.select2-selection__multi-value__label {
  font-size: 10px;
}
.select2-selection__multi-value {
  margin-top: 0;
}
.select2-selection__clear-indicator {
  padding-left: 0;
  padding-right: 0;
}
  `
export { SelectWrapper };
