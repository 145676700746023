import styled from "styled-components";

const POWrapper = styled.div`
  padding-top: 32px;
  padding-left: 28px;
  padding-right: 28px;
  .delete-items-button {
      background: none;
      color: #CF0909;
      font-weight: 700;
      border-color: #CF0909;
      &:hover{
        color: #fff;
        border-color: #CF0909;
        background-color: #CF0909;
        img{
          filter: brightness(0) invert(1) grayscale(100%) contrast(100%);
        };
      }
    }
  .po-table{
    font-family: inherit;
    color: #4E5969;
    &.uploads-bar-visible{
    height: calc(100vh - 580px);
    }
    &.uploads-bar-hidden{
    height: calc(100vh - 440px);
    }
    .ag-header{
      min-height: 32px !important;
      height: 32px !important;
      background-color: #F8FAFB !important;
      border-bottom: 1px solid #4E5969;
      .ag-header-row{
        min-height: 32px;
        height: 32px !important;
        span{
          color: inherit;
        }
      }
    }
    .ag-row{
        border-bottom: 0 !important;
        &.ag-row{
          background-color: #fff;
        }
        .ag-cell{
          p{
            font-size: 14px;
            &.bages-wrapper{
              font-size: 12px;
            }
          }
        }
      }
  }
  .edit-button{
    display: contents;
  }
  .ag-cell-value:has(.badges-wrapper ){
    overflow: visible;
    p{
      overflow: visible;
    }
  }
  .po-header-buttons {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
    .btn{
      transition: 0.3s ease-in-out;
      &:hover{
        .icon-arrow-left::before{
          color: #fff;
        }
      }
    }
    h4 {
      color: #000;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
      span {
        color: #1565d8;
      }
    }
  }
  .po-header-bar {
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    background: #f8fafb;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
    .po-header-left {
      display: flex;
      align-items: center;
      padding-right: 16px;
      gap: 20px;
      width: 45%;
      .btn {
        padding: 14px 24px;
        min-width: 120px;
      }
      .input-search {
        max-width: 396px;
        width: 100%;
        input{
          min-width: 100%;
        }
      }
      .po-select {
        margin-bottom: 0;
        max-width: 155px;
        width: 100%;
        .select2-container {
          margin-bottom: 0;
        }
      }
    }
    .po-header-right {
      padding-left: 16px;
      border-left: 1px solid #d9d9d9;
      display: flex;
      margin: auto;
      grid-column-gap: 10px;
      width: 55%;
      .orders {
        border-radius: ${({ theme }) => theme["border-radius-base"]};
        width: 100%;
        padding: 10px;
        border-radius: 8px;
        gap: 10px;
        span {
          line-height: 18px;
        }
        .white-bg {
          width: 36px;
          height: 36px;
          background-color: ${({ theme }) => theme["white-color"]};
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        h1 {
          /* letter-spacing:3px; */
        }
        &.fbm-order {
          padding: 10px;
          overflow: hidden;
          .order-inner-value{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .order-inner-text-wrapper{
            max-width: 70%;
          }
        }
        &.total {
          background-color: ${({ theme }) => theme["primary-color"]}03;
          border: 1px solid ${({ theme }) => theme["primary-color"]};
          .icon-total::before {
            color: ${({ theme }) => theme["black-color"]};
          }
        }
        &.shipped {
          background-color: ${({ theme }) => theme["success-color"]}03;
          border: 1px solid ${({ theme }) => theme["success-color"]};
        }
        &.storage-orders {
          background-color: ${({ theme }) => theme["success-color"]}03;
          border: 1px solid ${({ theme }) => theme["success-color"]};
        }
        &.missing {
          background-color: ${({ theme }) => theme["warning-color"]}05;
          border: 1px solid ${({ theme }) => theme["warning-color"]};
        }
        &.due-date {
          background-color: ${({ theme }) => theme["pink-color"]}03;
          border: 1px solid ${({ theme }) => theme["pink-color"]};
        }
        &.standard {
          background-color: ${({ theme }) => theme["dark-yellow"]}05;
          border: 1px solid ${({ theme }) => theme["dark-yellow"]};
        }
      }
    }
    @media(max-width: 1199px){
      .po-header-left{
        width: 100%;
        padding-right: 0;
        .input-search{
          max-width: 100%;
          min-width: 128px;
          input{
            min-width: 100%;
          }
        }
      }
      .po-header-right{
        margin-top: 10px;
        width: 100%;
        padding-left: 0;
        border-left: 0;
      }
    }
  }
  .buttons-wrapper{
    display: flex;
    justify-content: end;
    margin-top: 16px;
    div{
      display: flex;
      gap: 10px;
      button{
        padding: 14px 24px;
        font-size: 15px;
        line-height: 16px;
        font-weight: 700;
        .icon-arrow-right:before {
              color: #fff;
        }
      }
    }
  }
  .po-uploads-bar {
  display: flex;
  flex-direction: row;
  border: 2px solid #00A3FF;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
  .po-uploads-left{
    display: flex;
    align-items: center;
    h2{
      padding-right: 14px;
      max-width: 148px;
      &.in-progress-status-ss{
        color:#009C34;
      }
    }
  }
  .po-uploads-right{
    display: flex;
    width: 100%;
    border-left: 1px solid #00A3FF;
    justify-content: space-between;
    grid-gap: 15px;
    padding-left: 16px;
    align-items: baseline;
    @media(max-width: 1199px){
      flex-wrap: wrap;
    }
  }
  .po-uploads-heading{
    font-size: 12px !important;
    color: #78909C !important;
    font-weight: 600;
    line-height: 13px !important;
    letter-spacing: 0.5px;
  }
  .po-uploads-name{
    p{
      max-width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .po-uploads-actions{
    display: flex;
    font-weight: 700;
    margin-top: 4px;
    flex-direction: column;
    .btn{
      width: 100%;
      margin-right: 10px; 
      font-size: 14px;
      font-weight: 700;
      &.restart-button{
        &:disabled{
        img{
          filter: brightness(0) invert(1) grayscale(100%) contrast(100%);
        }
      }
      }
      &:hover{
        img{
          filter: brightness(0) invert(1) grayscale(100%) contrast(100%);
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
    .delete-button{
      border-color: #CF0909;
      color: #CF0909;
      &:hover{
        color: #fff;
        background-color: #CF0909;
      }
    }
  }
  .box {
    /* padding: 10px; */
    &:first-child{
      @media(min-width: 1200px){
        /* padding-left: 20px; */
      }
    }
    @media(max-width: 1199px){
      /* padding-left: 20px; */
    }
    p{
      font-size: 14px;
      line-height: 18px;
      font-weight: 600;
      color: #4E5969;
      padding-top: 4px;
    }
}
    p {
      margin-bottom: 5px;
      font-weight: bold;
      min-width: 76px;
    }

    .progress-box {
      display: flex;
      align-items: center;
      .progress-bar {
        width: 100px;
        margin-left: 8px;
        background: #C4C4C4;
        border-radius: 10px;
        height: 10px;
        .progress-fill {
          background-color: #FF9900;
          height: 100%;
          border-radius: 3px;
          &.success{
            background-color: #009c34;
          }
          &.error{
            background-color: #ff3333;
          }
        }
      }
    }
  
}
.ss-po-wrapper-progress {
    display: flex;
    align-items: center;
    grid-gap: 16px;
    .confirm-po-wrapper {
    height: 68px;
    min-width: 180px;
    margin-left: 21px;
}
}
.po-ss-overlay-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 24px;
}
.Po-ss-progress-wrapper{
  display: flex;
    flex-direction: column;
    grid-row-gap: 8px;
.po-items-delete-wrapper {
    display: flex;
    justify-content: end;
    width: 100%;
    margin-bottom: 12px;
}
.progress-bar-wrapper-ss{
  display: flex;
    align-items: baseline;
    .rotate-icon-ss{
      padding-left: 8px;
    }
  .progress-bar-ss {
    min-width: 85px;
    height: 20px;
    background: #009C34;
    border-radius: 30px;
    border: 1px solid #fff;
    &.in-process-bar-ss{
      background-color: #F90;
    }
    &.empty-progress-bar-ss{
      background-color: #E6E6E6;
    }
}
.progress-bar-item-ss {
    display: flex;
    flex-direction: column;
    grid-row-gap: 6px;
    .solid-bg{
        padding: 2px 1px;
        background-color: #00A3FF1A;
      }
    &:first-child{
      .solid-bg{
        padding: 2px 1px 2px 2px;
        border-radius: 20px 0 0 20px;
      }
    }
    &:nth-child(4){
      .solid-bg{
        padding: 2px 2px 2px 1px;
        border-radius: 0 20px 20px 0;
      }
    }
    p {
    color: var(--Primary-Text, #4E5969);
    font-size: 8px !important;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    padding: 0;
    line-height: 5px;
    text-align: center;
}

}
}
  }
`;
const TableWrapper = styled.div`
border: 1px solid #E6E6E6;
overflow: hidden;
border-radius: 8px;
.ag-checkbox-input-wrapper {
  font-size: 14px;
  height: 14px;
  width: 14px;
  line-height: 16px;
  &::after{
    color: #afbbc0;
  }
  &.ag-checked::after{
    color: #1565D8;
  }
  &.ag-indeterminate::after{
    color: #1565D8;
  }
}
.ag-header-row{
  border-bottom: 1px solid ${({ theme }) => theme["secondaryColor"]};
  background-color: #F8FAFB;
} 
.ag-header-cell{
  padding-left: 16px;
  padding-right: 16px;
}
.button-cell{
    display: flex;
    line-height: 14px;
    align-items: center;
    gap: 10px;
    height: 100%;
    justify-content: center;
    p{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    button{
      border: none;
      background-color: transparent;
      padding: 0;
      img{
        height: 16px;
        width: 16px;
        object-fit: contain;
      }
    }
}
.ag-cell{
  p{
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .overflow-text{
    max-width: 62px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: auto;
  }
  .overflow-text-2{
    max-width: 44px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: auto;
  }
  p.text-primary{
    color: ${({ theme }) => theme["primary-color"]} !important;
    font-size: 14px;
  }
    padding-left: 16px;
    padding-right: 16px;
  }

.badges-wrapper{
  height: 24px;
  margin: 0;
  line-height: 12px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  padding: 6px 16px;
  border-radius: 24px;
  &.success{
    border: 1px solid ${({ theme }) => theme["success-color"]};
    color: ${({ theme }) => theme["success-color"]};
  }
  &.warning{
    border: 1px solid ${({ theme }) => theme["warning-color"]};
    color: ${({ theme }) => theme["warning-color"]};
  }
  &.gated{
    border: 1px solid  ${({ theme }) => theme["danger-color"]};
    color:  ${({ theme }) => theme["danger-color"]};
  }
}
  .vendor-wrapper{
    img{
      height: 18px;
      object-fit: contain;
    }
  }
  .vendor{
    text-align: left;
    display: inline-block;
    p{
      line-height: 24px;
      margin-bottom: 0;
      color: ${({ theme }) => theme["secondaryColor"]};
      max-width: 110px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    h6{
      color: ${({ theme }) => theme["placeholder-color"]};
      font-size: 12px;
      margin-bottom: 0;
      max-width: 110px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .center-header{
    text-align: center;
  }
  .ag-header-cell-label{
    justify-content: center;
    span{
      font-size: 12px;
    }
  }
  
  .copy-wrapper{
      display: flex;
      align-items: center;
      gap: 10px;
      height: 100%;
      p{
        margin: 0;
        line-height: 14px;
        max-width: 110px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    input.table-input {
    width: 100%;
    height: 94%;
    border: none;
    text-align: center;
}
`;
export { POWrapper, TableWrapper };
