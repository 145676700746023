import axios from '../../../../axios_config';
import { setItem } from "../../../slices/auth";
import { clearStore } from  '../../../slices/store';

export const storeToken = (token, navigate, dispatch) => {
  dispatch(clearStore());
  dispatch(setItem({
    field: 'token',
    value: token
  }));
  // axios.defaults.headers.common.Authorization = `Bearer ${token}`;
}