import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  fetchingUsers: false,
  skus: [],
  selectedItems: [],
  affiliate_manager_id: null,
  total_records: 0,
  selectedUser: null,
  filters: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  }
};

const profitCalculationManager = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'SET_FILTERS_PROFIT_CALCULATION_MANAGER_DASHBOARD': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_PAGINATION_FOR_PROFIT_CALCULATION_MANAGER': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    case 'GET_USERS_FOR_PROFIT_CALCULATION_DASHBOARD_REQUEST': {
      return {
        ...state,
        fetching: true,
        fetchingUsers: true
      };
    }
    case 'SKIP_ITEMS_FROM_CALCULATION_REQUEST':
    case 'GET_SKUS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_SKUS_SUCCESS': {
      const { skus, totalRecords } = action.payload;
      return {
        ...state,
        fetching: false,
        skus,
        total_records: totalRecords
      };
    }
    case 'GET_USERS_FOR_PROFIT_CALCULATION_DASHBOARD_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        fetching: false,
        fetchingUsers: false
      };
    }
    case 'SET_SELECTED_ITEMS_FOR_PROFIT_CALCULATION_MANAGER': {
      return {
        ...state,
        selectedItems: action.payload
      };
    }
    case 'CLEAR_SELECTED_ITEMS_FOR_PROFIT_CALCULATION_MANAGER': {
      return {
        ...state,
        selectedItems: []
      };
    }
    case 'SET_AFFILIATE_ID_FOR_PROFIT_CALCULATION_DASHBOARD': {
      return {
        ...state,
        ...action.payload,
        fetching: false,
        selectedItems: []
      };
    }
    case 'SET_SELECTED_USER_FOR_PROFIT_CALCULATION_DASHBOARD': {
      return {
        ...state,
        selectedUser: action.payload,
        selectedItems: []
      };
    }
    case 'GET_USERS_FOR_PROFIT_CALCULATION_DASHBOARD_FAILED': {
      return {
        ...state,
        fetching: false,
        fetchingUsers: false
      };
    }
    case 'SKIP_ITEMS_FROM_CALCULATION_FAILED':
    case 'GET_SKUS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    default:
      return state;
  }
};

export default profitCalculationManager;
