import React, { useState, useEffect } from "react";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Input, Button, Checkbox, Spin, notification, Tooltip } from "antd";
import { FaPlus } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";
import BoxPack from "../../../assets/images/box-pack-icon.svg";
import { DeliveryInformationWrapper } from "../style";
import { DatePicker } from 'antd';
import { Box } from "@mui/material";

import Select from "../../../components/uiComponent/select/index";
import { nonPartneredCarriers } from "../../fbaInbound/constants";

import {
  ConfirmPo,
  UpdatePendingPos,
  setFbaShipmentState,
  AddGatedDetail
} from "../../../slices/fba-shipment";

const DeliveryInformation = ({
  setOpenModal,
  draft_po,
  setDraftPo,
  poId,
  resetSteps,
  updatedGatedPos
}) => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { loading } = useSelector((store) => store.fbaShipment);

  const [checked, setChecked] = useState(false);
  const [carrier, setCarrier] = useState('');
  const [newDate, setNewDate] = useState({
    carrier_name: "",
    delivery_date: "",
    tracking_info: ""
  });

  const handleAddButtonClick = () => {
    let error = '';
    const ifExists = draft_po?.delivery_informations_attributes?.findIndex(({ tracking_info, _destroy }) => {
      return !_destroy && newDate?.tracking_info.trim() === tracking_info.trim();
    });

    if (!newDate?.tracking_info || !newDate?.delivery_date) error = 'Please Add Tracking Info and Delivery Date';
    else if (ifExists >= 0) error = `Tracking id "${newDate?.tracking_info.trim()}" already exists`;

    if (error) {
      notification.error({
        message: "Add New Delivery Date",
        description: error,
        top: 65
      });
      return;
    }
    setDraftPo({
      ...draft_po,
      delivery_informations_attributes: [
        ...(draft_po?.delivery_informations_attributes || []),
        {
          carrier_name: newDate?.carrier_name.trim(),
          delivery_date: newDate?.delivery_date.trim(),
          tracking_info: newDate?.tracking_info.trim()
        }
      ]
    });
    setNewDate({
      carrier_name: "",
      delivery_date: "",
      tracking_info: ""
    });
    setCarrier("");
  };

  const handleDeleteEntry = (trackingInfo) => {
    const newData = [...draft_po?.delivery_informations_attributes];
    const index = newData?.findIndex(({ tracking_info, _destroy }) => !_destroy && tracking_info === trackingInfo);

    newData[index] = {
      ...newData[index],
      _destroy: true
    };
    setDraftPo({
      ...draft_po,
      delivery_informations_attributes: [...newData]
    });
  };

  return (
    <Spin tip='Loading...' spinning={loading}>
      <DeliveryInformationWrapper>
        <div className="header">
          <h1>Confirm Delivery Information</h1>
        </div>
        <div className="delivery-row-wrapper">
          <div className="track-info-wrapper">
            <p>Tracking info</p>
            {
              <Input
                value={newDate?.tracking_info}
                onChange={(e) => {
                  setNewDate({
                    ...newDate,
                    tracking_info: e.target.value?.trimStart()
                  });
                }}
                placeholder="Add or edit tracking info"
              />
            }
          </div>
          <div className="track-info-wrapper">
            <p>Carrier</p>
            <Select
              placeholder="Select Carrier"
              width="165px"
              isClearable
              marginBottom="0px"
              value={carrier}
              options={nonPartneredCarriers}
              onChange={(event) => {
                setCarrier(event);
                setNewDate({
                  ...newDate,
                  carrier_name: event?.value || ''
                });
              }}
            />
          </div>
          <div className="estimate-ui-skip-confirm w-100">
            <p>Estimated Delivery Date</p>
            <DatePicker
              value={newDate?.delivery_date ? moment(newDate?.delivery_date) : ''}
              disabledDate={(current) => {
                return moment().add(-1, 'days') >= current
              }}
              format="DD MMMM YYYY"
              className="custom-date-picker"
              onChange={(date, dateString) => {
                setNewDate({
                  ...newDate,
                  delivery_date: dateString
                });
              }}
            />
          </div>
          <Button className="add-button" onClick={handleAddButtonClick}>
            <FaPlus />
            <span className="add-button-text">Add More</span>
          </Button>
        </div>

        <div className="fix-height-delivery-date">
          {draft_po?.delivery_informations_attributes?.length > 0 && (
            <div className="pt-3">
              {draft_po?.delivery_informations_attributes?.filter(({ _destroy }) => !_destroy)
                ?.map((data, index) => (
                  <div className="tracking-info-inner-wrapper" key={index}>
                    <p className="tracking-info-line">{data.tracking_info}</p>
                    <p className="tracking-info-line">{data.carrier_name}</p>
                    <p className="tracking-info-date">{moment(data.delivery_date).format('DD MMMM YYYY')}</p>
                    <button className="delete-button" onClick={() => handleDeleteEntry(data?.tracking_info)}> <BsTrash color="#CF0909" /></button>
                  </div>
                ))}
            </div>
          )}
        </div>
        <div className="text-container">
          <div className="text-item">
            <p>
              If you dont add this info , the warehouse will be confused and your
              package may get lost
            </p>
            <div className="checkbox-wrapper">
              <Checkbox
                checked={checked}
                onClick={() => {
                  setChecked(!checked)
                }}
              />
              <p>I acknowledge this and take all following risks on me</p>
            </div>
          </div>
          <img src={BoxPack} />
        </div>
        <div className="button-wrapper">
          <Button className="skip-button" onClick={() => {
            const { tracking_info, delivery_date } = newDate || {};
            const deliveryDates = [...draft_po?.delivery_informations_attributes];
            if (tracking_info && delivery_date) {
              const ifExists = deliveryDates?.findIndex(({ tracking_info, _destroy }) => {
                return !_destroy && newDate?.tracking_info.trim() === tracking_info.trim();
              });
              if (ifExists >= 0) {
                notification.error({
                  message: "Add New Delivery Date",
                  description: `Tracking id "${newDate?.tracking_info.trim()}" already exists`,
                  top: 65
                });
                return;
              }
              deliveryDates.push({
                carrier_name: newDate?.carrier_name.trim(),
                delivery_date: newDate?.delivery_date.trim(),
                tracking_info: newDate?.tracking_info.trim()
              });
            }
            dispatch(UpdatePendingPos({
              draft_po: {
                ...draft_po,
                delivery_informations_attributes: deliveryDates,
                pos: ''
              },
              poId
            }));
            if (updatedGatedPos?.length) {
              dispatch(AddGatedDetail(updatedGatedPos?.map(({
                id,
                requestPicture,
                putInStorage,
                removeSkuFromPo
              }) => {
                return {
                  id,
                  image_requested: requestPicture,
                  to_storage: putInStorage,
                  remove_from_po: removeSkuFromPo
                }
              })
              ));
            }
            setOpenModal(false);
            resetSteps();
            setNewDate({
              carrier_name: "",
              delivery_date: "",
              tracking_info: ""
            })
            setCarrier('');
          }}>Skip</Button>
          <Tooltip
            trigger='hover'
            title={((!newDate?.delivery_date && newDate?.tracking_info) || (newDate?.delivery_date && !newDate?.tracking_info)) ? 'Please Add Tracking Info and Delivery Date' : ''}
          >
            <Box>
              <Button
                disabled={!checked || (newDate?.tracking_info && !newDate?.delivery_date) || (newDate?.delivery_date && !newDate?.tracking_info)}
                onClick={() => {
                  const { tracking_info, delivery_date } = newDate || {};
                  const deliveryDates = [...draft_po?.delivery_informations_attributes];
                  if (tracking_info && delivery_date) {
                    const ifExists = deliveryDates?.findIndex(({ tracking_info, _destroy }) => {
                      return !_destroy && newDate?.tracking_info.trim() === tracking_info.trim();
                    });
                    if (ifExists >= 0) {
                      notification.error({
                        message: "Add New Delivery Date",
                        description: `Tracking id "${newDate?.tracking_info.trim()}" already exists`,
                        top: 65
                      });
                      return;
                    }
                    deliveryDates.push({
                      carrier_name: newDate?.carrier_name.trim(),
                      delivery_date: newDate?.delivery_date.trim(),
                      tracking_info: newDate?.tracking_info.trim()
                    });
                  }
                  if ((tracking_info && !delivery_date) || (delivery_date && !tracking_info)) {
                    notification.error({
                      message: "Add New Delivery Date",
                      description: 'Please Add Tracking Info and Delivery Date',
                      top: 65
                    });
                    return;
                  }
                  if (updatedGatedPos?.length) {
                    dispatch(AddGatedDetail(updatedGatedPos?.map(({
                      id,
                      requestPicture,
                      putInStorage,
                      removeSkuFromPo
                    }) => {
                      return {
                        id,
                        image_requested: requestPicture,
                        to_storage: putInStorage,
                        remove_from_po: removeSkuFromPo
                      }
                    })
                    )).then(() => {
                      dispatch(UpdatePendingPos({
                        draft_po: {
                          ...draft_po,
                          delivery_informations_attributes: deliveryDates,
                          pos: ''
                        },
                        poId,
                        stopSuccess: true
                      })).then(({ payload }) => {
                        if (payload?.draft_po?.po_count) {
                          dispatch(ConfirmPo({ poId })).then(({ payload }) => {
                            if (payload?.success) {
                              navigation('/purchase-order');
                              resetSteps();
                            }
                          });
                        }
                        else if (payload?.draft_po?.po_count === 0) {
                          notification.error({
                            message: "Confirm Shipment",
                            description: 'There is no item in this draft PO',
                            top: 65
                          });
                          setOpenModal(false);
                        }
                      });
                    });
                  } else {
                    dispatch(UpdatePendingPos({
                      draft_po: {
                        ...draft_po,
                        delivery_informations_attributes: deliveryDates,
                        pos: ''
                      },
                      poId,
                      stopSuccess: true
                    })).then(({ payload }) => {
                      if (payload?.draft_po?.po_count) {
                        dispatch(ConfirmPo({ poId })).then(({ payload }) => {
                          if (payload?.success) {
                            navigation('/purchase-order');
                            resetSteps();
                          }
                        });
                      }
                      else if (payload?.draft_po?.po_count === 0) {
                        notification.error({
                          message: "Confirm Shipment",
                          description: 'There is no item in this draft PO',
                          top: 65
                        });
                        setOpenModal(false);
                      }
                    });
                  }
                  setNewDate({
                    carrier_name: "",
                    delivery_date: "",
                    tracking_info: ""
                  });
                  setCarrier('');
                }
                }
                className={`confirm-button ${!checked || (newDate?.tracking_info && !newDate?.delivery_date) || (newDate?.delivery_date && !newDate?.tracking_info) ? 'disabled' : ''}`}
              >
                Confirm
              </Button>
            </Box>
          </Tooltip>
        </div>
      </DeliveryInformationWrapper>
    </Spin>
  );
};

export default DeliveryInformation;
