import US from '../assets/images/newImages/flags/US.svg'
import CA from '../assets/images/newImages/flags/CA.svg'
import MX from '../assets/images/newImages/flags/MX.svg'
import BR from '../assets/images/newImages/flags/BR.svg'
import EG from '../assets/images/newImages/flags/EG.svg'
import FR from '../assets/images/newImages/flags/FR.svg'
import GB from '../assets/images/newImages/flags/GB.svg'
import IN from '../assets/images/newImages/flags/IN.svg'
import IT from '../assets/images/newImages/flags/IT.svg'
import NL from '../assets/images/newImages/flags/NL.svg'
import PL from '../assets/images/newImages/flags/PL.svg'
import SE from '../assets/images/newImages/flags/SE.svg'
import SA from '../assets/images/newImages/flags/SA.svg'
import TN from '../assets/images/newImages/flags/TN.svg'
import SG from '../assets/images/newImages/flags/SG.svg'
import AU from '../assets/images/newImages/flags/AU.svg'
import JP from '../assets/images/newImages/flags/JP.svg'
import AE from '../assets/images/newImages/flags/AE.svg'
import DE from '../assets/images/newImages/flags/DE.svg'
import ES from '../assets/images/newImages/flags/ES.svg'

export const APP_ID = 'amzn1.sellerapps.app.5ddb8e47-d21c-4ad4-b2d7-db98f8d2b613';

export const NA_MARKETPLACES = [
  {
    name: "US",
    id: 'ATVPDKIKX0DER',
    country: US,
  },
  {
    name: "CA",
    id: 'A2EUQ1WTGCTBG2',
    country: CA,
  },
  {
    name: "MX",
    id: 'A1AM78C64UM0Y8',
    country: MX,
  },
  {
    name: "BR",
    id: 'A2Q3Y263D00KWC',
    country: BR,
  },
]

export const EU_MARKETPLACES = [
  {
    name: "AE",
    id: 'A2VIGQ35RCS4UG',
    country: AE,
  },
  {
    name: "DE",
    id: 'A1PA6795UKMFR9',
    country: DE,
  },
  {
    name: "EG",
    id: 'ARBP9OOSHTCHU',
    country: EG,
  },
  {
    name: "ES",
    id: 'A1RKKUPIHCS9HS',
    country: ES,
  },
  {
    name: "FR",
    id: 'A13V1IB3VIYZZH',
    country: FR,
  },
  {
    name: "GB",
    id: 'A1F83G8C2ARO7P',
    country: GB,
  },
  {
    name: "IN",
    id: 'A21TJRUUN4KGV',
    country: IN,
  },
  {
    name: "NL",
    id: 'A1805IZSGTT6HS',
    country: NL,
  },
  {
    name: "PL",
    id: 'A1C3SOZRARQ6R3',
    country: PL,
  },
  {
    name: "SE",
    id: 'A2NODRKZP88ZB9',
    country: SE,
  },
  {
    name: "SA",
    id: 'A17E79C6D8DWNP',
    country: SA,
  },
  {
    name: "TR",
    id: 'A33AVAJ2PDY3EV',
    country: TN,
  },
]

export const FE_MARKETPLACES = [
  {
    name: "SG",
    id: 'A19VAU5U5O7RUS',
    country: SG,
  },
  {
    name: "AU",
    id: 'A39IBJ37TRP1C6',
    country: AU,
  },
  {
    name: "JP",
    id: 'A1VC38T7YXB528',
    country: JP,
  }
]

export const NA_IMAGES = [
  US, CA, MX, BR
]

export const EU_IMAGES = [
  AE, DE, EG, ES, FR, GB, IN, IT, NL, PL, SE, SA, TN
]

export const FE_IMAGES = [
  SG, AU, JP
]

export const BASIC_PLAN = [
  {
    title: "Catalog Analyser",
    caption: "Scan through distributor catalogs 100K products an hour."
  },
  {
    title: "Purchas Order History",
    caption: "Keep track of all your PO’s with enables history which feeds to inbound flow & repricer."
  },
  {
    title: "Vendor CRM",
    caption: "Vendors management."
  },
  {
    title: "Removals",
    caption: "Keep track of all removals from Amazon."
  },
  {
    title: "Invoices",
    caption: "Contains information about Prep & Pack and Storage Invoices."
  },
  {
    title: "Repricer",
    caption: ""
  },
  {
    title: "Sales Profit",
    caption: ""
  },
  {
    title: "Suffested Reoeders",
    caption: ""
  },
  {
    title: "Inbound Flow",
    caption: ""
  },
  {
    title: "Inventory View",
    caption: ""
  }
]

export const PREMIUM_PLAN = [
  {
    title: "Catalog Analyser",
    caption: "Scan through distributor catalogs 100K products an hour."
  },
  {
    title: "Purchas Order History",
    caption: "Keep track of all your PO’s with enables history which feeds to inbound flow & repricer."
  },
  {
    title: "Vendor CRM",
    caption: "Vendors management."
  },
  {
    title: "Removals",
    caption: "Keep track of all removals from Amazon."
  },
  {
    title: "Invoices",
    caption: "Contains information about Prep & Pack and Storage Invoices."
  },
  {
    title: "Repricer",
    caption: "Instant real time repricing with up to the second AWS que. Setup strategy to match your sku’s and manage your data within one centeral hub."
  },
  {
    title: "Sales Profit",
    caption: "Graphical view of Sales and Profit."
  },
  {
    title: "Suffested Reoeders",
    caption: "Save time with automatically generated reorder predictions."
  },
  {
    title: "Inbound Flow",
    caption: "Automate your Warehouse production with ability to create ship plans, and download FNSKU’s prep instructions, 2D box content labels, shipment ID Labels, and pallet labels."
  },
  {
    title: "Inventory View",
    caption: "Keep track of your products no matter their status or conditions."
  }
]