import React, { useState, useEffect } from "react";
import { Spin } from 'antd';
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { Stepper, Step, StepLabel } from "@material-ui/core";
// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { BsCheck } from "react-icons/bs";
// import Button from "../../components/button/button.styled";
import Upload from "../upload/indexCsv"
import Progresss from "../progress/index";
import { Row, Col } from "react-bootstrap";
import { UploadWrapper } from "../upload/style";
import StepperStyle from "../stepper/style";
import fileUploaded from "../../../assets/images/fileuploaded.png";
import Input from '../inputs/input/index';
import Select from '../select/index';
import Button from "../button/index";


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 5,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "#2589FF",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: "#2589FF",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#E6E6E6",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ffffff",
  zIndex: 1,
  width: 16,
  color: "#77838E",
  border: "1px solid #77838E",
  height: 16,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active &&
  {
    background: "#ffffff",
    color: "#2589FF",
    border: "1px solid #2589FF",

  }),
  ...(ownerState.completed &&
  {
    background: "#2589FF",
    color: "#ffffff",
    border: "1px solid #2589FF",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const icons = {
    1: <BsCheck />,
    2: <BsCheck />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const steps = ["Upload File", "Status"];
// const steps = ["Upload File", "Data Mapping", "Status"];

const Steppers = (props) => {
  const { onChange, activeStep, onClick, progress, uploadBulkLoading } = props
  const [popup, setPopup] = useState(false);
  const [isZebraLabel, setIsZebraLabel] = useState(true);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) =>
  //       prevProgress >= 100 ? 0 : prevProgress + 10
  //     );
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);
  // const handleUploadfile = (e) => {
  //   setActiveStep(activeStep + 1);
  // };
  const handleClick = () => {
    setPopup(true)
  }
  return (

    <Stack sx={{ width: "100%" }} spacing={2}>
      <StepperStyle>
        <Stepper
          alternativeLabel
          className="stepper-steps"
          activeStep={activeStep}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 2 &&
          <React.Fragment>
            <div className="step-congrats">
              <div className="step-image">
                <img src={fileUploaded} alt="Successful Upload Image" />
              </div>
              <h1>File Successfuly Uploaded!</h1>
              {/* <p>Reference site about Lorem Ipsum, giving information on its origins, as well as a random Lipsum generator.</p> */}

              <Button text="Done" onClick={onClick}/>
            </div>
          </React.Fragment>
        }
        {
          <React.Fragment>
            <div>
              {activeStep === 0 ? (
                <div className="upload-step">
                  {/* step 1 part 1 */}
                  <div className="">
                    {/* <span className="step-title">
                      (To create listing, you need to add URL for the lisitng you want to create on Amazon or Walmart.)
                      <a href="#">Download Sample File</a>
                    </span> */}

                    <div className="info add-left-spacing">
                      <span>Label Type</span>
                      <div className="switch-button">
                        <div
                          className={`button ${isZebraLabel ? "active" : ""}`}
                          onClick={() => setIsZebraLabel(true)}
                        >
                          Zebra Label
                        </div>
                        <div
                          className={`button ${!isZebraLabel ? "active" : ""}`}
                          onClick={() => setIsZebraLabel(false)}
                        >
                          30-Up Label
                        </div>
                      </div>
                    </div>

                    <Upload uploadFile={(e) => onChange(e, isZebraLabel)} />
                  </div>
                  {/* step 1 part 2 */}                
                </div>
              ) : (  
                  activeStep === 1 && 
                  <UploadWrapper className="" >
                    <div className="upload-file upload-progress ">
                      <div className="progressbar">
                        <Spin tip="Uploading..." spinning={uploadBulkLoading}></Spin>
                        {/* <Progresss percentage={progress} /> */}
                      </div>
                      <p className="progress-status">Uploading File</p>
                    </div>
                  </UploadWrapper>
              )}
            </div>
          </React.Fragment>
        }
      </StepperStyle>
    </Stack>
  );
};
export default Steppers;
