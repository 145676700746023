import React, { useState, useEffect } from 'react'
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Logo from "../../assets/images/newImages/new_log.png";
import { AuthWrapper, PhoneNumberWrapper } from '../profile/style'
import { Form, Row, Col, Container } from "react-bootstrap"
import InfoVideo from '../profile/component/infoVideo'
import LabelInput from '../uiComponent/inputs/labelInput'
import PhoneInput from '../uiComponent/inputs/phoneNumberInput'
import CheckBox from '../uiComponent/checkBox'
import Button from '../uiComponent/button/index'
import { BiLeftArrowAlt } from "react-icons/bi";
import { AiFillEyeInvisible, AiFillEye, AiOutlineMenu } from "react-icons/ai";
import { clearStoragesData, GetPickedLocationCount } from "../../slices/internalStorage";
import { isEmpty } from 'lodash';
import { MobileWrapper, Header, Content, Footer, Title } from './mobile.styled';

const MobileHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pickupLocationCount, pickupCountLoading } = useSelector(state => state?.internalStorage || {});

  const handleAddnProducts = () => {
    navigate('/scan-location');
  }

  const handleTransferProducts = () => {
    navigate('/scan-transfer-products');
  }

  const handleRemovalProducts = () => {
    navigate('/pick-list-locations')
  }

  useEffect(() => {
    dispatch((clearStoragesData()));
    dispatch(GetPickedLocationCount());
  }, [])


  return (
    <MobileWrapper>
      <div className='mobile-page'>
        <div className='container-fluid px-0'>
          {/* <Header>
            <div className="logo-box d-flex align-items-center">
              <a href="#"><AiOutlineMenu />
              </a>
              <img className='logo' src={Logo} alt="no-log" onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
            </div>
          </Header> */}
          <Content>
            <Title>
              <h2> 
                {/* <span
                className="icon-arrow-left cursor-pointer"></span> */}
                What You Want To Do</h2>
            </Title>
            <div className='home-buttons d-flex align-items-end'>
              <div className='d-flex flex-column w-100'>
                <Spin tip='Processing' spinning={pickupCountLoading} >
                  <Button className="outlined btn-block btn-lg" text="Add Product's" onClick={handleAddnProducts}></Button>
                  <Button className="outlined btn-block btn-lg" text="Transfer Product's" onClick={handleTransferProducts}></Button>
                  <Button className="outlined btn-block btn-lg" text={`Pick List (${pickupLocationCount})`} onClick={handleRemovalProducts}></Button>
              </Spin>
              </div>
            </div>
          </Content>
          {/* <Footer>
            <Button className="outlined btn-block " text="Go to Warehouse" />
          </Footer> */}

        </div>
      </div>
    </MobileWrapper>
  )
}

export default MobileHome;
