import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  invoices: [],
  total: 0,
  filters: [],
  fetchingUsers: false,
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  users: [],
};

const poInvoice = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GET_USERS_FOR_PO_INVOICES_REQUEST': {
      return {
        ...state,
        fetchingUsers: true
      };
    }
    case 'GET_USERS_FOR_PO_INVOICES_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        fetchingUsers: false
      };
    }
    case 'GET_USERS_FOR_PO_INVOICES_FAILED': {
      return {
        ...state,
        fetchingUsers: false
      };
    }
    case 'SET_FILTERS_PO_INVOICES': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_SORT_OPTIONS_PO_INVOICES': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_PO_INVOICES_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_PO_INVOICES_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      };
    }
    case 'GET_PO_INVOICES_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_PAGINATION_PO_INVOICES': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    default:
      return state;
  }
};

export default poInvoice;
