import React, { lazy } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const ListerApp = lazy(() => import('../../components/fbmOld/ListerApp.jsx'));

function AppendNavigationToComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth || {});
  const modifiedUser = {
    ...currentUser,
    authToken: currentUser.auto_ordering_auth_token
  }

  return (
    <ListerApp navigate={navigate}
      dispatch={dispatch}
      currentUser={modifiedUser} />
  );
}

const routesArray = [
  '/fbm/lister',
  '/fbm/lister/feed',
  '/fbm/lister/profitable-matches',
  '/fbm/lister/approve-matches',
  '/fbm/lister/listing-sku',
  '/fbm/lister/restricted-brands',
  // '/fbm/lister/inventory',
];

export const LISTER_ROUTES =
  routesArray.map((route) => ({
    path: route,
    component: <AppendNavigationToComponent />,
    group: 'FBM',
    reducer: []
  }))
