import React from 'react'
import { InfoVideoWrapper } from '../style'
const InfoVideo = () => {
  return (
    <InfoVideoWrapper>
      <h1>Welcome to the Sales Support!</h1>
      <p>Your ONE-STOP solution for anything Amazon Wholesale.</p>
      <iframe
        className="onBorading-innerVideo"
        src="https://player.vimeo.com/video/476975555?autoplay=1&loop=1&autopause=0&muted=1"
        frameBorder="0"
        scrolling="no"
        allow="autoplay; fullscreen"
        allowFullScreen>
      </iframe>
      {/* <p>Description regarding video will come here</p> */}
    </InfoVideoWrapper>
  )
}

export default InfoVideo
