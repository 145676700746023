import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Spin,
  Divider,
  notification,
  Modal,
  Icon,
  Select as LocationSelect
} from "antd";
import { InputGroup, Form, Row, Col } from "react-bootstrap";
import { sumBy, isEmpty, isObject } from "lodash";
import { BsTrash } from "react-icons/bs";

import Input from '../uiComponent/inputs/labelInput/index';
import Select from "../uiComponent/select/index";
import Button from "../uiComponent/button/index";
import LocationModal from "../uiComponent/modal/index";

import {
  CreateNewLocation
} from "../../slices/fba-inbound/to-receive";
import { getDimensions } from "../../actions/fbaRemovalShipments/details";
import { 
  AddManualProductsToStorage,
  GetVendors
 } from "../../slices/fba-inbound/to-check-in";

import { generateStorageBarcode } from "../../utils/locationLabels";
import { DrawerWrapper } from "../../containers/fbmInbound/style";

const { Option } = LocationSelect;

const AddtoStorageModal = ({
  addtoStorage,
  setAddtoStorage,
  userList,
  setLocationsList,
  locationsList
}) => {
  const dispatch = useDispatch();
  const [assignedLocations, setAssignedLocations] = useState([{ storage_id: '', quantity: 0 }]);
  const [currentNewLocationIndex, setCurrentNewLocationIndex] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const [storage, setStorage] = useState({
    identifier: '',
    quantity: '',
    vendorId: '',
    assigned_user_id: '',
  });
  const [storageData, setStorageData] = useState({
    identifier: '',
    quantity: '',
    assigned_user_id: '',
    height: '',
    width: '',
    length: '',
    weight: ''
  });

  const {
    toReceive: {
      fetchingAllLocations,
      creatingNewLocation,
      addingToStorage
    },
    toCheckIn: {
      checkInLoading,
      getVendorsLoading
    }
  } = useSelector((state) => state.fbaInbound || {});

  const [vendorId, setVendorId] = useState('');
  const [loading, setLoading] = useState(false);
  const [vendorsList, setVendorsList] = useState([])
  const [locName, setLocName] = useState("");
  const inputRef = useRef(null);

  const addLocation = (e) => {
    setShowModal(false);
    e.preventDefault();
    dispatch(CreateNewLocation({ storage: { name: locName } }))
      .then(({ payload }) => {
        const { success, location } = payload?.data || {};
        if (success) {
          handleDownloadLocationLabel(location);
          handleLocationChange(location.id, currentNewLocationIndex);
          setLocationsList(locationsList => [...locationsList, [location.id, location.name]]);
          setLocName("");
          inputRef?.current?.value && (inputRef.current.value = "");
        }
      });
  };

  const handleLocationChange = (val, index) => {
    const data = [...assignedLocations];
    data[index].storage_id = val;
    setAssignedLocations(data);
  };

  const resetAddToStorage = () => {
    setStorageData({
      identifier: '',
      quantity: '',
      assigned_user_id: '',
      height: '',
      width: '',
      length: '',
      weight: ''
    });
    setStorage({
      identifier: '',
      quantity: '',
      vendorId: '',
      assigned_user_id: '',
    });
    setAssignedLocations([{ storage_id: '', quantity: 0 }]);
  }

  const handleDownloadLocationLabel = (data) => {
    const { uuid, name, warehouse_user } = data || {};
    generateStorageBarcode({
      warehouseName: warehouse_user,
      locationName: name,
      uuid,
      labelType: 'location'
    })
  };

  const onNameChange = (event) => {
    setLocName(event.target.value);
  };

  const handleGenerateStorageLabels = ({ staging_item, labelQty, expiryDate }) => {
    if (!isEmpty(staging_item)) {
      const { title, uuid, quantity, identifier, warehouse_user } = staging_item || {};
      const warehouseName = isObject(warehouse_user)
        ? !isEmpty(warehouse_user)
          ? `${warehouse_user?.first_name || ""} ${warehouse_user?.last_name || ""}`
          : "N/A"
        : warehouse_user || "N/A";

      generateStorageBarcode({
        warehouseName,
        uuid,
        title,
        stored_quantity: labelQty || quantity,
        identifier,
        labelType: "product",
      });
    }
  };

  const handleAddToStorage = () => {
    for (let location of assignedLocations) {
      const { storage_id, quantity } = location || {};

      if (quantity && !storage_id) {
        return notification.error({
          top: 65,
          message: 'Location',
          description: `Please Select Location`
        });
      }

      if (storage_id && !quantity) {
        return notification.error({
          top: 65,
          message: 'Location',
          description: `Please add quantity against each location`
        });
      }

      if (assignedLocations?.length >= 2 && (!storage_id || !quantity)) {
        return notification.error({
          top: 65,
          message: 'Location',
          description: `Please Remove Extra Location`
        });
      }
    }

    if (![10, 12].includes(storageData?.identifier?.length)) {
      return notification.error({
        top: 65,
        message: 'ASIN/UPC Validation',
        description: 'ASIN/UPC length should be equal to 10 or 12'
      });
    }

    if (assignedLocations?.[0]?.storage_id && sumBy(assignedLocations, 'quantity') !== storageData?.quantity) {
      return notification.error({
        top: 65,
        message: 'Quantity Validation',
        description: 'Sellable quantity should match with sum of location quantities'
      });
    }

    dispatch(AddManualProductsToStorage({
      staging_item: storageData,
      locations: assignedLocations?.[0]?.storage_id ? assignedLocations: [],
      vendor_id: vendorId
    })).then(({ payload }) => {
      const { success, staging_item } = payload?.data || {};
      if (success) {
        setAddtoStorage(false);
        handleGenerateStorageLabels({ staging_item });
        notification.success({
          top: 65,
          message: 'Add To Storage',
          description: 'Product added to storage successfully.'
        });
        resetAddToStorage();
      }
    });
  }

  const handleQtyChange = (e, index) => {
    const { value } = e.target;
    const data = [...assignedLocations];
    data[index].quantity = Number(value);
    setAssignedLocations(data);
  };

  const handleRemoveClick = (index) => {
    const data = [...assignedLocations];
    data.splice(index, 1);
    setAssignedLocations(data);
  };

  const handleAddClick = () => {
    setAssignedLocations([...assignedLocations, { storage_id: '', quantity: 0 }]);
  };

  return <>
    <Modal closable={false} footer={null} visible={addtoStorage} size="30%">
      <Spin tip="Loading..." spinning={checkInLoading}>
        <div className="p-4">
          <div className="d-flex align-items-center gap-3">
            <span class="icon-arrow-left cursor-pointer" onClick={() => {
              setAddtoStorage(false);
              resetAddToStorage();
            }}></span>
            <h2 className="mb-0 color-black">Add Product to Internal Storage</h2>
          </div>
          <Row className="mt-4">
            <Col md={12}>
              <Input
                label="Enter ASIN/UPC"
                placeholder="Enter ASIN/UPC"
                className="h-32"
                value={storage?.identifier}
                onChange={(e) => {
                  setStorageData({
                    ...storageData,
                    identifier: e.target.value
                  });
                  setStorage({
                    ...storage,
                    identifier: e.target.value
                  });

                  if (e.target.value?.length === 10 || e.target.value?.length === 12) {
                    setLoading(true)
                    dispatch(getDimensions({ asin: e.target.value })).then(({ payload }) => {
                      const {
                        item_height,
                        item_length,
                        item_weight,
                        item_width
                      } = payload?.dimensions || {};
                      setStorageData({
                        ...storageData,
                        identifier: e.target.value,
                        height: item_height,
                        length: item_length,
                        weight: item_weight,
                        width: item_width
                      });
                      setLoading(false);
                    });
                  }
                }}
              />
              <Select
                label="Users"
                placeholder="Select User"
                options={userList?.map(({ id, full_name }) => {
                  return {
                    value: id,
                    label: full_name
                  }
                }) || []
                }
                value={storage?.assigned_user_id}
                onChange={(e) => {
                  setStorageData({
                    ...storageData,
                    assigned_user_id: e.value
                  });
                  setStorage({
                    ...storage,
                    assigned_user_id: e,
                    vendorId: ''
                  });
                  setVendorId('');
                  dispatch(GetVendors(e.value))
                    .then(({ payload }) => {
                      const { vendors } = payload?.data || {};
                      if (vendors?.length) {
                        setVendorsList(vendors);
                      }
                    });
                }}
              />
              <Select
                label="Vendors"
                placeholder="Vendors"
                loading={getVendorsLoading}
                disabled={!storageData?.assigned_user_id || !vendorsList?.length || getVendorsLoading}
                options={vendorsList?.map(({ id, name }) => {
                  return {
                    value: id,
                    label: name
                  }
                }) || []
                }
                value={storage?.vendorId}
                onChange={(e) => {
                  setVendorId(e.value);
                  setStorage({
                    ...storage,
                    vendorId: e
                  });
                }}
              />
            </Col>
            <Col md={6}>
              <Input
                label="Sellable Units"
                className="h-32"
                value={storage?.quantity}
                onChange={(e) => {
                  if (isNaN(e.target.value) || Number(e.target.value) < 0) return;
                  setStorageData({
                    ...storageData,
                    quantity: Number(e.target.value)
                  });
                  setStorage({
                    ...storage,
                    quantity: e.target.value
                  });
                }}
              />
            </Col>
          </Row>
          <h3 className="color-secondary">Location Distribution</h3>
          <div className="box-height location-scroll">
            <Spin tip='Loading...' spinning={addingToStorage}>
              {assignedLocations.map((item, index) => (
                <div className="d-flex gap-4 mb-3" key={index}>
                  <div className="d-flex flex-column mt-1">
                    {index === 0 && (
                      <span className="fs-12 color-regent mb-1">Location</span>
                    )}
                    <LocationSelect
                      showSearch
                      optionFilterProp='label'
                      style={{ width: 234, marginTop: index === 0 ? "-2px" : "-4px" }}
                      placeholder="Select Location"
                      listHeight={50}
                      allowClear
                      value={item.storage_id || ''}
                      className="location-select"
                      loading={fetchingAllLocations || creatingNewLocation}
                      disabled={fetchingAllLocations || creatingNewLocation || !storageData?.quantity}
                      dropdownRender={(menu) => {
                        return <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{ padding: "4px 8px 8px", cursor: "pointer" }}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => {
                              setCurrentNewLocationIndex(index);
                              setShowModal(true);
                              setTimeout(() => { inputRef.current?.focus(); }, 0);
                            }}
                            className="d-flex align-items-center gap-2 color-primary fw-semibold"
                          >
                            <Icon type="plus" />
                            <span>Add New Location</span>
                          </div>
                        </div>
                      }}
                      onChange={(val) => {
                        handleLocationChange(val, index);
                      }}
                    >
                      {locationsList?.map((loc) => {
                        return <Option key={loc[0]} value={loc[0]} label={loc[1]}>{loc[1]}</Option>
                      }) || []}
                    </LocationSelect>
                  </div>
                  <div>
                    {index === 0 && (
                      <span className="fs-12 color-regent mb-1">Quantity</span>
                    )}
                    <Input
                      height="32px"
                      width="93px"
                      marginBottom="4px"
                      value={item.quantity || ''}
                      disabled={!storageData?.quantity}
                      onChange={(e) => {
                        if (isNaN(e.target.value) || Number(e.target.value) < 0) return;
                        handleQtyChange(e, index);
                      }}
                    />
                  </div>
                  {index === 0 ? (
                    <div
                      className="add-box d-flex align-items-center justify-content-center mt-4"
                      onClick={handleAddClick}
                    >
                      <span className="icon-add fs-12" />
                    </div>
                  ) : (
                    <div
                      className="add-box d-flex align-items-center justify-content-center"
                      onClick={() => handleRemoveClick(index)}
                    >
                      <BsTrash color="#CF0909" />
                    </div>
                  )}
                </div>
              ))}
            </Spin>
          </div>
          <h3 className="color-secondary">Add Dimensions</h3>
          <Row>
            <DrawerWrapper>
              <Row className="add-weight gap-0 mt-0">
                <Spin tip='Loading...' spinning={loading}>
                  <Row className="add-weight gap-0 mt-0">
                    <Col md={6}>
                      <div className="d-flex flex-column mb-2">
                        <Form.Label htmlFor="basic-url">Length</Form.Label>
                        <InputGroup>
                          <Form.Control
                            name="box_length"
                            value={storageData?.length}
                            onChange={(e) => {
                              if (isNaN(e.target.value) || Number(e.target.value) < 0) return;
                              setStorageData({
                                ...storageData,
                                length: Number(e.target.value)
                              });
                            }}
                          />
                          <InputGroup.Text>in</InputGroup.Text>
                        </InputGroup>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="d-flex flex-column mb-2">
                        <Form.Label htmlFor="basic-url">Width</Form.Label>
                        <InputGroup>
                          <Form.Control
                            name="box_length"
                            value={storageData?.width}
                            onChange={(e) => {
                              if (isNaN(e.target.value) || Number(e.target.value) < 0) return;
                              setStorageData({
                                ...storageData,
                                width: Number(e.target.value)
                              });
                            }}
                          />
                          <InputGroup.Text>in</InputGroup.Text>
                        </InputGroup>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="d-flex flex-column mb-2">
                        <Form.Label htmlFor="basic-url">Height</Form.Label>
                        <InputGroup>
                          <Form.Control
                            name="box_length"
                            value={storageData?.height}
                            onChange={(e) => {
                              if (isNaN(e.target.value) || Number(e.target.value) < 0) return;
                              setStorageData({
                                ...storageData,
                                height: Number(e.target.value)
                              });
                            }}
                          />
                          <InputGroup.Text>in</InputGroup.Text>
                        </InputGroup>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="d-flex flex-column mb-2">
                        <Form.Label htmlFor="basic-url">Weight</Form.Label>
                        <InputGroup>
                          <Form.Control
                            name="bundle_quantity"
                            value={storageData?.weight}
                            onChange={(e) => {
                              if (isNaN(e.target.value) || Number(e.target.value) < 0) return;
                              setStorageData({
                                ...storageData,
                                weight: Number(e.target.value)
                              });
                            }}
                          />
                          <Select
                            value={{ value: "lb", label: "lb" }}
                            disabled={true}
                            options={[
                              { value: "lb", label: "lb" },
                              { value: "oz", label: "oz" },
                            ]}
                            className="input-select"
                          />
                        </InputGroup>
                      </div>
                    </Col>
                  </Row>
                </Spin>
                <Col md={5}>
                  <div className="d-flex justify-content-end ">
                    <Button onClick={() => {
                      setAddtoStorage(false);
                      resetAddToStorage();
                    }} text="cancel" className="outlined" />
                  </div>
                </Col>
                <Col md={7}>
                  <Button
                    onClick={handleAddToStorage}
                    disabled={!storageData?.assigned_user_id || !storageData?.identifier || !storageData?.height || !storageData?.length ||
                      !storageData?.weight || !storageData?.width || !storageData?.quantity || !vendorId
                    }
                    text="Add to Internal Storage"
                    className="contained w-100"
                  />
                </Col>
              </Row>
            </DrawerWrapper>
          </Row>
        </div>
      </Spin>
    </Modal>

    <LocationModal
      size="sm"
      open={showModal}
      onHide={() => setShowModal(false)}
      saveText="Cancel"
      onSave={() => setShowModal(false)}
    >
      <DrawerWrapper>
        <span className="fs-12 fw-semibold color-gray mb-1">
          Create New Location
        </span>
        <div className="d-flex align-items-center gap-2">
          <Input
            placeholder="Enter New Location Name"
            width="210px"
            height="32px"
            marginBottom="0px"
            onChange={onNameChange}
            inputRef={inputRef}
          />
          <div
            className="add-box d-flex align-items-center justify-content-center"
            style={locName.trim() ? {} : { opacity: 0.5, pointerEvents: 'none' }}
            onClick={addLocation}
          >
            <span className="icon-save" />
          </div>
        </div>
      </DrawerWrapper>
    </LocationModal>
  </>
}

export default AddtoStorageModal;
