import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce, uniqBy, trim, lowerCase, extend } from 'lodash';
import downloadCsv from 'download-csv';
import XLSX from 'xlsx';
import moment from "moment";
import FileUpload from "./fileUpload"
import { Button, Checkbox, Input, message, Upload, Spin, notification, Modal } from "antd";

import { BsSearch, BsArrowRightShort } from "react-icons/bs";
import { AiOutlineInbox } from "react-icons/ai";

import {
  ProductsList,
  IsValidateAsin,
  setFbaShipmentState,
  CreateInventoryListing,
  AddPurchaseOrder,
  UploadFileOnS3,
  SaveFileName
} from "../../../slices/fba-shipment";

import { AddProductToPurchaseOrderWrapper, FileUploadWrapper } from "../style";

const UpdateProductToPurchaseOrder = ({
  setVisibility,
  nextButton,
  draft_po,
  addItemButton,
  draft_po_id,
  openDrawer,
  setOpenDrawer,
  po_name,
  vendorId,
  open
}) => {
  const { Dragger } = Upload;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    loading,
    productsList,
    isValidateAsin,
    isPoCreated,
    draftPoInfo,
    fileUploadLoading
  } = useSelector((store) => store.fbaShipment);

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [searchTemp, setSearchTemp] = useState('');
  const [searchedItem, setSearchedItem] = useState([]);
  const [fileColumns, setFileColumn] = useState([]);
  const [fileName, setFileName] = useState('');
  const [fileList, setFileList] = useState([]);
  const [uploadedData, setUploadedData] = useState([]);
  const [showAddProductWrapper, setShowAddProductWrapper] = useState(true);
  const [headerChecks, setHeaderChecks] = useState({});

  useEffect(() => {
    setProductsList();
  }, [productsList]);

  useEffect(() => {
    dispatch(ProductsList({ search, draft_po_id }));
  }, [search]);

  useEffect(() => {
    if (!open) {
      resetProducts();
      clearFieldsAndFileData();
    }
  }, [open]);

  useEffect(() => {
    if (isValidateAsin) {
      setSearch('');
      setSearchTemp('');
    }

    if (isValidateAsin && productsList?.length) {
      setSelectedProducts([
        ...selectedProducts,
        {
          asin: isValidateAsin?.asin,
          image: isValidateAsin?.small_image,
          name: isValidateAsin?.name
        }
      ]);
      setSearchedItem([
        {
          asin: isValidateAsin?.asin,
          image: isValidateAsin?.small_image,
          name: isValidateAsin?.name
        },
        ...searchedItem
      ]);
      const newProduct = {
        asin: isValidateAsin?.asin,
        image: isValidateAsin?.small_image,
        name: isValidateAsin?.name
      };
      setProductsList(newProduct);

      dispatch(setFbaShipmentState({ field: 'isValidateAsin', value: '' }));
    }
  }, [isValidateAsin, productsList]);

  useEffect(() => {
    return () => resetProducts();
  }, []);

  useEffect(() => {
    if (addItemButton && !openDrawer) {
      resetProducts();
      clearFieldsAndFileData();
    }
  }, [openDrawer]);

  const resetProducts = () => {
    setSearch('');
    setSearchTemp('');
    setSearchedItem([]);
    setSelectedProducts([]);
  }

  const setProductsList = (newProduct) => {
    const allProducts = productsList.map(({
      id,
      sku,
      asin,
      name,
      image
    }) => {
      return {
        id,
        sku,
        asin,
        name,
        image
      }
    })
    if (newProduct) setProducts(uniqBy([newProduct, ...searchedItem, ...selectedProducts, ...allProducts], 'asin'));
    else if (searchTemp) setProducts([...allProducts]);
    else if (!allProducts?.length) setProducts([...allProducts]);
    else setProducts(uniqBy([...searchedItem, ...selectedProducts, ...allProducts], 'asin'));
  }

  const searchItem = debounce((search) => {
    setSearch(search);
  }, 1000);

  const parseFileData = (actualKey, value) => {
    const invalidItem = {};
    let parsedValue = value;

    if (actualKey === 'identityColumn' || actualKey === 'vendorUpc') {
      value = trim(value);
      value = trim(value, '\\t');
      value = value.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
    }

    if (actualKey === 'identityColumn' && (!(/^[0-9A-Za-z]{10}$/.test(value)))) {
      invalidItem.reason = 'ASIN must always be a block of 10 letters';
    }
    else if (actualKey === 'vendorUpc' && value && (!(/^[0-9]{1,12}$/.test(value)))) {
      invalidItem.reason = 'The following Vendor UPC is not 12-digit format.';
    }
    else if (actualKey === 'sellerSku' && value && (value.length > 40)) {
      invalidItem.reason = 'The following Seller Sku is not 40-digit format.';
    }
    else if (['minPrice', 'maxPrice', 'unitCost', 'shipFee'].includes(actualKey) && typeof (value) === 'string') {
      parsedValue = value.replace(/[$]/g, "");
      parsedValue = trim(parsedValue);
      parsedValue = (/^[.0-9]*$/.test(parsedValue) ? (Number(parsedValue) || null) : null);
    }
    else if (['varietyPack', 'smallAndLight'].includes(actualKey) && typeof (value) === 'string') {
      if (['', 'true', 'false'].includes(lowerCase(value))) {
        parsedValue = (['', 'false'].includes(lowerCase(value))) ? false : true;
      } else {
        parsedValue = 'invalid';
      }
    }
    else if (['buyQuantity', 'caseQuantity', 'bundleQuantity'].includes(actualKey) && typeof (value) === 'string') {
      parsedValue = trim(parsedValue);
      parsedValue = (/^\d+$/.test(parsedValue) ? (Number(parsedValue) || null) : null);
    }

    return { parsedValue, invalidItem };
  }

  const validateFileData = (uploadedFile) => {
    if (!uploadedFile) return 'File is Empty.';

    const updatedFileData = [];
    let error = '';
    let i = 1;
    for (let data of uploadedFile) {
      const {
        caseQuantity,
        bundleQuantity,
        buyQuantity,
        unitCost,
        smallAndLight,
        varietyPack,
        identityColumn
      } = data || {};
      const newObjData = {}
      if (caseQuantity && buyQuantity && !(buyQuantity % caseQuantity === 0)) {
        error = `At Row # ${i}: Buy Quantity(Singles) must be divisible by Case Quantity (If both provided)`;
      }
      if (bundleQuantity && buyQuantity && !(buyQuantity % bundleQuantity === 0)) {
        error = 'Buy Quantity(Singles) must be divisible by Bundle Quantity (If both provided)';
      }
      else if (!bundleQuantity) error = `At Row # ${i}: Please provide a Valid Bundle Quantity for each record!`;
      else if (!buyQuantity) error = `At Row # ${i}: Please provide a Valid Buy Quantity for each record!`;
      else if (!unitCost) error = `At Row # ${i}: Please provide a Valid Unit Cost for each record!`;
      else if (!identityColumn) error = `At Row # ${i}: Please provide a Valid ASIN for each record!`;
      else if (varietyPack === 'invalid') error = `At Row # ${i}: Invalid variety pack. Value should be true or false`;
      else if (!smallAndLight === 'invalid') error = `At Row # ${i}: Invalid small And Light. Value should be true or false`;

      if (error) return { error };
      for (let key in data) {
        const { parsedValue, invalidItem } = parseFileData(key, data[key]);
        if (invalidItem?.reason) return { error: `At Row # ${i}: ${invalidItem?.reason}` }
        newObjData[key] = parsedValue;
      }
      updatedFileData.push(newObjData);
      i += 1;
    }

    return { updatedFileData }
  }

  const populateFormFieldsWithFileData = (uploadedFile, uploadedFileName) => {
    const { originFileObj } = uploadedFile[0];

    const reader = new FileReader();
    reader.readAsBinaryString(originFileObj);
    reader.onload = (({ target }) => {
      const wb = XLSX.read(target.result, { type: 'binary', raw: true });
      wb.SheetNames.forEach((sheetName) => {
        const fileJson = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], { header: 2, defval: "" });
        if (fileJson && fileJson.length) {
          const fileColumn = Object.keys(fileJson[0]);

          if (fileColumn?.length) {
            const mapping = {};
            for (let col of fileColumn) {
              if (col === 'ASIN') extend(mapping, { [col]: 'ASIN' });
              else if (col === 'Vendor UPC') extend(mapping, { [col]: 'Vendor Upc' });
              else if (col === 'SELLER SKU') extend(mapping, { [col]: 'SKU' });
              else if (col === 'CASE QUANTITY') extend(mapping, { [col]: 'Case QTY' });
              else if (col === 'BUNDLE QUANTITY') extend(mapping, { [col]: 'Bundle Quantity' });
              else if (col === 'BUY QUANTITY') extend(mapping, { [col]: 'Buy Quantity' });
              else if (col === 'UNIT COST') extend(mapping, { [col]: 'Unit Cost' });
              else if (col === 'PRODUCT TITLE') extend(mapping, { [col]: 'Product Title' });
              else if (col === 'MIN PRICE') extend(mapping, { [col]: 'Min Price' });
              else if (col === 'MAX PRICE') extend(mapping, { [col]: 'Max Price' });
              else if (col === 'SMALL AND LIGHT') extend(mapping, { [col]: 'Small And Light' });
              else if (col === 'VARIETY PACK') extend(mapping, { [col]: 'Variety Pack' });
            }
            setHeaderChecks(mapping);
          }
          setFileColumn(fileColumn);
          setShowAddProductWrapper(false);
          setUploadedData(fileJson?.map((data, id) => ({ id, ...data })));
          setFileName(uploadedFileName);
          message.success(`${uploadedFileName} file uploaded successfully.`);
          setVisibility("hidden");
        } else {
          notification.warning({
            top: 65,
            message: 'UPLOAD PO',
            duration: 7,
            description: 'Uploaded PO file contains no data !!'
          });
          clearFieldsAndFileData();
        }
      });
    })
  }

  const handleFileUpload = (data, fileData) => {
    const { poName, poId } = data || {};
    const name = `${fileName.split('.').slice(0, -1).join('.')}-${moment().format('DDMMYYYY-HH:mm:ss')}.json`
    dispatch(UploadFileOnS3({
      fileData,
      fileName: name
    })).then(({ payload }) => {
      const { url } = payload || {};
      dispatch(SaveFileName({
        fileName: name,
        po_name: poName || po_name,
        presignedUrl: url,
        draft_po_id: poId || draft_po_id
      })).then((res) => {
        if (res?.payload?.status) {
          if (!addItemButton) { //if po is first time created from PO screen only then this code will execute
            dispatch(setFbaShipmentState({ field: 'isPoCreated', value: false }));
            dispatch(setFbaShipmentState({ field: 'draftPoInfo', value: {} }));
            if (poId) navigate(`/po-detail?id=${poId}`);
          }
          clearFieldsAndFileData();
          resetProducts();
        }
        setOpenDrawer(false);
      }).catch((err) => {
        clearFieldsAndFileData();
        resetProducts();
      });
    });
  }

  const handleConfirmFileUpload = (mappedHeaders) => {
    const mappedFile = uploadedData?.map((file) => {
      const obj = {
        identityType: "asin",
        vendorId: draft_po?.vendor_id || vendorId || '',
      }
      for (let header in mappedHeaders) {
        if (mappedHeaders?.[header] === 'ASIN') extend(obj, { identityColumn: file[header] });
        else if (mappedHeaders?.[header] === 'SKU') extend(obj, { sellerSku: file[header] });
        else if (mappedHeaders?.[header] === 'Vendor Upc') extend(obj, { vendorUpc: file[header] });
        else if (mappedHeaders?.[header] === 'Case QTY') extend(obj, { caseQuantity: file[header] });
        else if (mappedHeaders?.[header] === 'Bundle Quantity') extend(obj, { bundleQuantity: file[header] });
        else if (mappedHeaders?.[header] === 'Buy Quantity') extend(obj, { buyQuantity: file[header] });
        else if (mappedHeaders?.[header] === 'Unit Cost') extend(obj, { unitCost: file[header] });
        else if (mappedHeaders?.[header] === 'Product Title') extend(obj, { productTitle: file[header] });
        else if (mappedHeaders?.[header] === 'Min Price') extend(obj, { minPrice: file[header] });
        else if (mappedHeaders?.[header] === 'Max Price') extend(obj, { maxPrice: file[header] });
        else if (mappedHeaders?.[header] === 'Small And Light') extend(obj, { smallAndLight: file[header] });
        else if (mappedHeaders?.[header] === 'Variety Pack') extend(obj, { varietyPack: file[header] });
      }
      return obj
    });
    const { error, updatedFileData } = validateFileData(mappedFile);
    if (error) {
      notification.error({
        message: 'Upload File',
        description: error,
        top: 65
      });
      return;
    }
    if (nextButton) handleClick(updatedFileData);
    else {
      handleFileUpload({}, updatedFileData);
      resetProducts();
    }
  }

  const clearFieldsAndFileData = () => {
    setFileColumn([]);
    setFileName('');
    setFileList([]);
    setShowAddProductWrapper(true);
  }

  const handleBeforeUpload = () => {
    return false;
  };


  const props = {
    name: "file",
    accept: '.csv,.xls,.xlsx',
    fileList: fileList,
    // action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    onChange(info) {
      resetProducts();
      let files = info.fileList.splice(0, 1);
      setFileList(files);
      populateFormFieldsWithFileData(files, info.file.name);
    },
    beforeUpload: handleBeforeUpload,
    showUploadList: true,
    onDrop(e) {
      resetProducts();
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleClick = (updatedFileData) => {
    let error = '';
    if (!draft_po?.po_name) error = 'Please Enter PO Name';
    else if (!draft_po?.vendor_id) error = 'Please Select vendor';
    else if (!draft_po?.po_destination) error = 'Please Select Shipping Warehouse';

    if (error) {
      notification.error({
        message: "PO Creation",
        description: error,
        top: 65
      });

      return;
    }

    if (isPoCreated && draftPoInfo) { // check that if po is already created or not
      if (fileName) {
        handleFileUpload({ poName: draftPoInfo?.po_name, poId: draftPoInfo?.id }, updatedFileData); //handle file upload flow
      } else {
        // manual add products to PO
        dispatch(CreateInventoryListing({
          id: draftPoInfo?.id,
          skus: selectedProducts?.map(({ id, asin }) => id ? { id } : { asin })
        }));
      }
    } else { //code for PO is not already created 
      dispatch(AddPurchaseOrder({
        draft_po
      })).then(({ payload }) => {
        if (payload?.draft_po && fileName) { //check for file upload
          handleFileUpload({ poName: payload?.draft_po?.po_name, poId: payload?.draft_po?.id }, updatedFileData);
        } else { //manual add product to PO
          dispatch(CreateInventoryListing({
            id: payload?.draft_po?.id,
            skus: selectedProducts?.map(({ id, asin }) => id ? { id } : { asin })
          })).then((res) => {
            dispatch(setFbaShipmentState({ field: 'isPoCreated', value: false }));
            dispatch(setFbaShipmentState({ field: 'draftPoInfo', value: {} }));
            if (payload?.draft_po?.id) navigate(`/po-detail?id=${payload?.draft_po?.id}`);
          });
        }
      });
    }
  };

  const handleCheckBox = (product, type) => {
    clearFieldsAndFileData();
    if (selectedProducts?.length && selectedProducts?.find((p) => p.asin === product?.asin || p.id === product?.id)) {
      setSelectedProducts(selectedProducts?.filter(({ asin, id: pId }) => {
        if (type === 'asin') {
          return asin !== product?.asin
        } else {
          return pId !== product?.id
        }
      }));
    } else {
      setSelectedProducts([
        ...selectedProducts,
        ...(type === 'asin' ? [{
          asin: product?.asin,
          image: product?.image,
          name: product?.name
        }] : [{
          id: product?.id,
          sku: product?.sku,
          asin: product?.asin,
          image: product?.image,
          name: product?.name
        }])
      ])
    }
  }

  const downloadSampleTemplate = () => {
    const dummyData = [{
      asin: '', vendorUpc: '', sellerSku: '', buyQuantity: '', caseQuantity: '', bundleQuantity: '', unitCost: '', constrSku: '', title: '', minPrice: '', maxPrice: '', smallAndLight: 'true', varietyPack: 'false'
    }];
    const headers = { asin: 'ASIN', vendorUpc: 'Vendor UPC', sellerSku: 'SELLER SKU', buyQuantity: 'BUY QUANTITY', caseQuantity: 'CASE QUANTITY', bundleQuantity: 'BUNDLE QUANTITY', unitCost: 'UNIT COST', vendorSku: 'VENDOR SKU', title: 'PRODUCT TITLE', minPrice: 'MIN PRICE', maxPrice: 'MAX PRICE', smallAndLight: 'SMALL AND LIGHT', varietyPack: 'VARIETY PACK' };

    downloadCsv(dummyData, headers, 'sample_po_template.csv');
  }

  return (
    <>
      {showAddProductWrapper ? (
        <AddProductToPurchaseOrderWrapper>
          <Spin tip='Loading...' spinning={loading}>
            <div className="add-product-wrapper">
              <div className="add-product-item">
                <h5>Choose/Create SKU individually</h5>
                <div className="drawer-add-product">
                  <div className="search-bar-wrapper">
                    <Input value={searchTemp} onChange={e => {
                      const searchedAsin = searchedItem?.filter(x => x.asin.toLowerCase()?.includes(e.target.value.toLocaleLowerCase()));
                      setSearchTemp(e.target.value);
                      if (!e.target.value) dispatch(ProductsList({ search: '' }));
                      else if (e.target.value && searchedAsin?.length) setProducts(searchedAsin);
                      else searchItem(e.target.value);

                      if (!e.target.value) setSearch('');
                    }} placeholder="Search ASIN" />
                    <BsSearch />
                  </div>
                  {
                    search && !products?.length && <div className="create-listing-ui">
                      <h5>No matching result found in existing inventory. Please use ASIN to create Listing</h5>
                      <Button
                        className={search?.length !== 10 ? "disable-btn-wrapper" : ""}
                        variant="outlinedPrimary"
                        disabled={search?.length !== 10}
                        onClick={() => dispatch(IsValidateAsin({ asin: search }))}
                      >Create listing</Button>
                    </div>
                  }
                  {selectedProducts?.length ? <span className="pt-2 selected-sku-text">Selected SKUs {selectedProducts?.length}</span> : ''}
                </div>
                <div className="product-data-box-wrapper">
                  {products?.map((product, index) => (
                    <div className="product-data-item" key={index}>
                      <Checkbox
                        disabled={fileUploadLoading}
                        checked={selectedProducts?.find((p) => p.asin === product.id || p.id === product.id) ? true : false}
                        onClick={() => handleCheckBox(product, product?.id ? 'id' : 'asin')}
                      />
                      <div className="product">
                        <img height={50} width={50} src={product.image || '/images/no-image.png'} alt={product.name} />
                        <div className="product-details">
                          <h6>{product.name}</h6>
                          <div className="product-text">
                            <p>
                              ASIN: <span>{product.asin}</span>
                            </p>
                            <p>
                              SKU: <span>{product.sku}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="upload-product-item">
                <p style={{ textAlign: 'center' }} >
                  Download Sample Template <span
                    style={{ color: 'blue', cursor: 'pointer' }}
                    onClick={downloadSampleTemplate}
                  >here</span>
                </p>
                <Dragger {...props}>
                  <h6>Choose/Create Bulk Upload</h6>
                  <p className="ant-upload-drag-icon">
                    <AiOutlineInbox />
                  </p>
                  <p className="ant-upload-text">
                    Select a file or drag and drop here
                  </p>
                  <p className="ant-upload-hint">
                    Only .xlsx or .csv files are supported
                  </p>
                  <Button>Upload File</Button>
                </Dragger>
              </div>
            </div>
          </Spin>
          {nextButton && <div className="next-button-wrapper">
            <Button
              className={loading || (!fileName && !selectedProducts?.length) ? "disabled" : ''}
              disabled={loading || (!fileName && !selectedProducts?.length)}
              onClick={() => handleClick()}
            >
              Create Draft PO
              <BsArrowRightShort />
            </Button>
          </div>}
          {addItemButton && <div className="next-button-wrapper">
            <Button
              disabled={loading || (!fileName && !selectedProducts?.length) }
              className={loading || (!fileName && !selectedProducts?.length) ? "disabled" : ''}
              onClick={() => {
                dispatch(CreateInventoryListing({
                  id: draft_po_id,
                  skus: selectedProducts
                }));
              }}>
              Add
              <BsArrowRightShort />
            </Button>
          </div>}
        </AddProductToPurchaseOrderWrapper>
      ) : (
        <Modal
            visible={true}
            footer={null}
            width={963}
            closable={null}
            closeIcon={null}
            centered
          >
        <FileUploadWrapper>
          <Spin tip='Loading...' spinning={loading || fileUploadLoading}>
            <FileUpload
              onCancel={() => {
                resetProducts();
                clearFieldsAndFileData();
                setVisibility("visible");
              }}
              uploadedData={uploadedData}
              fileColumns={fileColumns}
              setFileColumn={setFileColumn}
              handleConfirm={(mappedData) => handleConfirmFileUpload(mappedData)}
              headerChecks={headerChecks}
              setHeaderChecks={setHeaderChecks}
              setVisibility={setVisibility}
            />
          </Spin>
        </FileUploadWrapper>
        </Modal>
      )}
    </>
  );
};

export default UpdateProductToPurchaseOrder;
