import React, { lazy } from "react";

const Pl3Payments = lazy(() => import("../../containers/3plPayments/index"));

export const PL3_PAYMENTS_ROUTES = [
  {
    path: "/3pl-payments",
    component: <Pl3Payments />,
    group: "App",
    reducer: [],
  },
];
