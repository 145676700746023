import React, { useState, useMemo } from 'react'
import { Spin } from 'antd'
import { object, string, ref, bool } from 'yup';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Logo from "../../assets/images/newImages/new_log.png";
import { AuthWrapper, PhoneNumberWrapper } from '../profile/style'
import { Form, Row, Col, Container } from "react-bootstrap"
import InfoVideo from '../profile/component/infoVideo'
import LabelInput from '../uiComponent/inputs/labelInput'
import PhoneInput from '../uiComponent/inputs/phoneNumberInput'
import CheckBox from '../uiComponent/checkBox'
import Button from '../uiComponent/button/index'
import { BiLeftArrowAlt } from "react-icons/bi";
import { AiFillEyeInvisible, AiFillEye, AiOutlineMenu } from "react-icons/ai";
import { GetPickListLocationDetail } from "../../slices/internalStorage";
import { isEmpty, debounce } from 'lodash';
import { MobileWrapper, Header, Content, Footer, Title, InputWrapper } from './mobile.styled';

const ScanNewLocation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [value, setValue] = useState() 
  const { pickLocationDetailLoading } = useSelector(state => state?.internalStorage)

  const handleScanNewLocation = debounce(async(event) => {
    const value = event.target.value;
    if(!isEmpty(value)) {
      const response = await dispatch(GetPickListLocationDetail({ id: value }));
      if(!response.error) navigate('/pick-list')
    }
  }, 1000)

  return (
    <MobileWrapper className='fullContent'>
      <div className='mobile-page'>
        <div className='container-fluid px-0'>
          <Spin tip='processing' spinning={pickLocationDetailLoading}>
          <Content className='fullContent'>
            <Title>
              <h2> 
                <span
                className="icon-arrow-left cursor-pointer" onClick={() => navigate('/pick-list-locations')}></span>
                Scan Location </h2>
            </Title>
            <InputWrapper>
                <LabelInput className="input-with-icon" icon="icon-barcode" label="Scan Location Barcode" placeholder="Scan Barcode" onChange= {handleScanNewLocation}/>
            </InputWrapper>
          </Content>
          </Spin>
        </div>
      </div>
    </MobileWrapper>
  )
}

export default ScanNewLocation;
