import Styled from "styled-components";

const TabsWrapper = Styled.div`
    height:${(props, theme) => (props?.height === "0" ? 0 : "100%")};
    .tabs-header{
        &.inner-tabs{
            background-color:${({ theme }) => theme["aqua_haze"]};

            li{
                padding: 0 6px 0 6px;
                position: relative;
                &::after{
                        content:"";
                        position: absolute;
                        right: 0;
                        height: 20px;
                        width:1px;
                        top:8px;
                        background: #E6E6E6;
                    }
                     &:last-child{
                        display: block;
                        button{
                            position: absolute;
                            left: -18px;
                            &.active{
                        background-color:transparent;
                        color:${({ theme }) => theme["primary-color"]};


                    }
                            svg{
                                color:${({ theme }) => theme["primary-color"]};
                            }
                        }
                        &::after{
                            background: transparent;
                        }
                  }
                button{
                    font-weight:${({ theme }) => theme["bold-weight"]};
                    padding:5px 0;
                    min-width:118px;
                    border: none;
                    color:${({ theme }) => theme["secondary-color"]};
                    &.active{
                        .tabs{
                            span{
                                color:${({ theme }) => theme["primary-color"]};
                            }
                        }
                        background-color:transparent;
                        border-bottom:2px solid #1565D8;
                    }
                    .icon-question{
                        font-size:${({ theme }) =>
                          theme["base-font-size-small"]};
                    }
                }


        }
        .add-tabs{
            svg{
                color: #000;
            }
        }
        }
            li{
                button{
                    min-width:224px;
                    border:1px solid #EEF1F5;
                    padding: 6px 24px;
                    height: 100%;
                    border-bottom:none;
                    span{
                        font-size:${({ theme }) => theme["font-size-small"]};
                        text-transform: capitalize;
                        color:${({ theme }) => theme["regent_gray"]};
                        margin-top:-6px;
                    }
                    .pl-8{
                        p
                    }
                    h1{
                        margin-bottom:0;
                    }
                    img{
                        width:66px;
                        height:20px;
                    }
                    .upper-tabs{
                        .tabs-marketplace{
                            color:${({ theme }) => theme["black-color"]};
                        }
                        .grid-row-gap-5{
                            grid-row-gap:5px;
                        }
                    }
                    &.active{
                        background-color:${({ theme }) =>
                          theme["primary-color"]};
                        color:${({ theme }) => theme["white-color"]};
                        .upper-tabs {
                          span,h1,h3{
                            color:${({ theme }) => theme["white-color"]};

                          }

                        }
                    }
                    }
                }
            }
            .tabs-right{
                background-color:${({ theme }) => theme["aqua_haze"]};
                height:36px;
                border-bottom: 1px solid #dee2e6;
                align-items:center;
                justify-content:right;
                display:flex;
                position: relative;
                grid-column-gap:22px;
                padding:0 15px;
                span{
                    cursor: pointer;
                }
                .search{
                    &::after{
                        content: "";
                        position: absolute;
                        right: 77px;
                        height: 20px;
                        width: 1px;
                        top: 8px;
                        background: #E6E6E6;
                    }

                }
                .d-none{
                    display: none;
                }
                .search-input{
                        display: block;
                        position: absolute;
                        top:37px;
                        input{
                        border: 1px solid #CFD8DC;
                        color: ${({ theme }) => theme["cutty_sark"]};
                        font-size: ${({ theme }) => theme["medium-font-size"]};
                        line-height:1.7;
                        height:32px;
                        min-width:288px;
                        &:focus{
                        border-color:#CFD8DC;
                        box-shadow:none;
                        color: ${({ theme }) => theme["cutty_sark"]};
                        }
                        &::placeholder{
                        font-size: ${({ theme }) => theme["medium-font-size"]};
                        color: ${({ theme }) => theme["gray-color"]};
                        }
                             }
                    }
            }
    }
    .order-tabs{
        background-color:${({ theme }) => theme["aqua_haze"]};
        position:relative;
        padding-left:27px;
        max-width:100%;
        border: 1px solid #E6E6E6;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        &.fbm-inbound{
            margin-top: 21px;
            padding-left: 0px;
            li{
                padding: 1px 21px;
                button{
                    border-bottom: none;
                    .title{
                        grid-column-gap:12px;
                    }
                    &.active{
                        .index{
                            background-color:${({ theme }) =>
                              theme["primary-color"]};
                              color:${({ theme }) => theme["white-color"]};
                            border: 1px solid ${({ theme }) =>
                              theme["primary-color"]};
                        }
                        .icon-question{
                             &::before{
                               color:${({ theme }) => theme["primary-color"]};
                         }
                    }
                    }
                    .icon-question{
                        font-size:12px;
                        &::before{
                               color:${({ theme }) => theme["dusty_grey"]};
                        }
                    }
                    .index{
                        border: 1px solid #979797;
                        width:16px;
                        height: 16px;
                        color:${({ theme }) => theme["mid-grey"]};
                        font-size:${({ theme }) =>
                          theme["base-font-size-small"]};
                        border-radius:50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                    }
                }
            }
        }
        &.fbm-listing{
            padding-left:0px;
            border-top-left-radius:0px;
            border-top-right-radius:0px;
            border-bottom-left-radius:4px;
            border-bottom-right-radius:4px;
            max-width: 100%;
            /* li:nth-child(1){
                padding: 0 26px 0 7px;
            } */
            li{
                padding: 0 32px;
                button{
                    .tabs{
                        .title{
                            font-size:${({ theme }) =>
                              theme["medium-font-size"]}
                        }
                        .icon-question{
                            font-size: 12px;
                             margin-left: 8px;
                            
                        }
                    }
                    &.active{
                         /* min-width: 45px; */
                        border-top-left-radius:2px;
                        border-top-right-radius:2px;
                      
                    }
                }
                }
        }
        li{
                padding: 0 24px;
                position: relative;
                &::after{
                        content:"";
                        position: absolute;
                        right: 0;
                        height: 20px;
                        width:1px;
                        top:8px;
                        background: #E6E6E6;
                    }
                    button{
                        background-color:${({ theme }) => theme["aqua_haze"]};
                        border-left:none;
                        border-right:none;
                        border-top:none;
                        padding: 6px 0px;
                        display: flex;
                        justify-content: center;
                        /* width: 100px; */
                        border-bottom:none;
                        .tabs{
                            grid-column-gap:8px;
                            .title{
                                font-size:${({ theme }) =>
                                  theme["medium-font-size"]};
                                color:${({ theme }) =>
                                  theme["secondary-color"]};
                            }
                            .d-flex {
                                font-size:${({ theme }) =>
                                  theme["medium-font-size"]};
                            }
                        }
                        &.active{
                            .tabs{
                                .title{
                                color:${({ theme }) => theme["primary-color"]};
                            }
                                .color-primary{
                                color:${({ theme }) => theme["primary-color"]};
                            }
                        }
                        border-bottom: 2px solid #1565d8;
                        background-color:${({ theme }) => theme["aqua_haze"]};
                        display: flex;
                        /* width: 100px; */
                        justify-content: center
                    }
                    }


            }
            .search-input{
                        display: block;
                        position: absolute;
                        top:37px;
                        input{
                        border: 1px solid #CFD8DC;
                        color: ${({ theme }) => theme["cutty_sark"]};
                        font-size: ${({ theme }) => theme["medium-font-size"]};
                        line-height:1.7;
                        height:32px;
                        min-width:288px;
                        &:focus{
                        border-color:#CFD8DC;
                        box-shadow:none;
                        color: ${({ theme }) => theme["cutty_sark"]};
                        }
                        &::placeholder{
                        font-size: ${({ theme }) => theme["medium-font-size"]};
                        color: ${({ theme }) => theme["gray-color"]};
                        }
                             }
                    }

    }
    .left-icon{
        position: absolute;
        z-index: 1;
        top: 10px;
        left: 18px;
    }
    .search-bar{
        position: absolute;
        right: 1px;
        top: 0px;
        width: 5%;
        background-color: #f8fafb;
        height: 36px;
        border: 1px solid #e6e6e6;
        border-left: 0;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        .search{
            position: absolute;
            right:10px;
            top:8px;

                }
    }
            .search-input{
                            position: absolute;
                                top:37px;
                                right:0;
                                input{
                                border: 1px solid #CFD8DC;
                                color: ${({ theme }) => theme["cutty_sark"]};
                                font-size: ${({ theme }) =>
                                  theme["medium-font-size"]};
                                line-height:1.7;
                                height:32px;
                                min-width:288px;
                                &:focus{
                                border-color:#CFD8DC;
                                box-shadow:none;
                                color: ${({ theme }) => theme["cutty_sark"]};
                                }
                                &::placeholder{
                                font-size: ${({ theme }) =>
                                  theme["medium-font-size"]};
                                color: ${({ theme }) => theme["gray-color"]};
                                }
                                    }
                            }
                            
  `;
export { TabsWrapper };
