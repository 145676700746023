import Styled from "styled-components";
const TilesWrapper = Styled.div`
    overflow:auto;
    margin-top: 8px;
    height: ${(props) => `calc(100vh - ${props.height || ""})`};
    .receive-box{
        width: 1620px;
    }
.drawer-padding{
    padding:30px;
    height:100vh;
    overflow:auto;
    .header{
        grid-column-gap: 12px;
        margin-top: -2px;
        margin-bottom:21px;
    }
    .input-space{
        grid-column-gap:30px;
    }

    .badge-sucess{
        background-color:${({ theme }) => theme["success-color"]}10;
        border:1px solid ${({ theme }) => theme["success-color"]};
        font-size:${({ theme }) => theme["base-font-size-small"]};
        border-radius:50px;
        padding:0 8px;
        max-width: 75px;
        color:${({ theme }) => theme["success-color"]};
        margin: 6px 0px;
    }
    .badge-danger{
        background-color:${({ theme }) => theme["danger-color"]}10;
        border:1px solid ${({ theme }) => theme["danger-color"]};
        font-size:${({ theme }) => theme["base-font-size-small"]};
        border-radius:50px;
        padding:0 8px;
        color:${({ theme }) => theme["danger-color"]};
        max-width: 75px;
        margin: 6px 0px;
    }
    .badge-info{
        background-color:${({ theme }) => theme["dark-yellow"]}10;
        border:1px solid ${({ theme }) => theme["dark-yellow"]};
        font-size:${({ theme }) => theme["base-font-size-small"]};
        border-radius:50px;
        padding:0 8px;
        color:${({ theme }) => theme["dark-yellow"]};
        max-width: 87px;
        margin: 0px 0px 4px 0px;
    }
    &.drawer-checkin{
        padding:24px;
        .drawer-header{
            margin-bottom:16px;
            .btn{
                margin-bottom:0;
                font-size:14px;
                line-height:16px;
                padding:8px 24px;
            }
        }
        .drawer-header-buttons{
            gap:20px;
        }
        .header{
            margin-bottom:0;
        }
        .icon-printer{
            font-size:15px;
        }
    }
}
.badge-warning {
    background-color: rgba(255, 153, 0, 0.05);;
    border:1px solid ${({ theme }) => theme["orange_peel"]};
    font-size:${({ theme }) => theme["base-font-size-small"]};
    border-radius:10px;
    font-weight:600;
    padding:0px 9px;
    color:${({ theme }) => theme["orange_peel"]};
    line-height: 20px;
}
.marketplace-information{
    border: 1px solid ${({ theme }) => theme["outline-color"]};
    background-color: ${({ theme }) => theme["outline-color"]}20;
    border-radius:${({ theme }) => theme["border-radius-base"]};
    padding: ${({ theme }) => theme["base-font-size"]};
    margin-right: 6px;
    margin-bottom: 0px;
    padding: 10px 16px;
    .receiving-product{
     width: 338px;
    }
    .details{
        display:flex;
        &.width-0{
            justify-content:space-between;
            div{
                min-width:auto;
            }
        }
        .border-right{
            width: 1px;
            height: 24px;
            background-color: ${({ theme }) => theme["border-color-base"]};
            margin: 30px 10px 0;
        }
        .vendor {
            span {
                &:nth-child(2) {
                    white-space: nowrap; 
                    width: 120px; 
                    overflow: hidden;
                    text-overflow: ellipsis; 
                }
                &:nth-child(3) {
                    max-width: 120px;
                }
            }
        }
        div{
                display: flex;
                flex-direction:column;
                flex-wrap:wrap;
                min-width: 154px;
              
                &.created-date{
                    min-width: 308px;
                }
                span:nth-child(1){
                    font-size: ${({ theme }) => theme["base-font-size-small"]};
                    padding-bottom:0px;
                    color:${({ theme }) => theme["secondary-color"]} ;
                    text-transform: capitalize;
                    margin-bottom:8px;
                    font-weight: 700;
                }
                span:nth-child(2){
                    font-size: ${({ theme }) => theme["medium-font-size"]};
                    color:${({ theme }) => theme["secondary-color"]} ;
                    line-height:16px;
                }

                span:nth-child(2),
                span:nth-child(3){
                    &.badge-sucess{
                        background-color:${({ theme }) =>
                          theme["success-color"]}10;
                        border:1px solid ${({ theme }) =>
                          theme["success-color"]};
                        font-size:${({ theme }) => theme["font-size-small"]};
                        border-radius:50px;
                        font-weight:600;
                        padding:0 8px;
                        max-width: 75px;
                        color:${({ theme }) => theme["success-color"]};
                        margin: 6px 0px;
                    }
                    &.badge-primary{
                        background-color:${({ theme }) =>
                          theme["primary-color"]}10;
                        border:1px solid ${({ theme }) =>
                          theme["primary-color"]};
                        font-size:${({ theme }) =>
                          theme["base-font-size-small"]};
                        border-radius:50px;
                        padding:0 8px;
                        color:${({ theme }) => theme["primary-color"]};
                        max-width: 39px;
                        margin: 6px 0px 0;
                        font-weight:600;
                    }
                    &.badge-danger{
                        background-color:${({ theme }) =>
                          theme["danger-color"]}10;
                        border:1px solid ${({ theme }) =>
                          theme["danger-color"]};
                        font-size:${({ theme }) =>
                          theme["base-font-size-small"]};
                        border-radius:50px;
                        padding:0 8px;
                        color:${({ theme }) => theme["danger-color"]};
                        max-width: 75px;
                        margin: 6px 0px 0;
                        font-weight:600;
                    }
                }
                span:nth-child(3){
                    font-size:12px;
                    color: ${({ theme }) => theme["placeholder-color"]};
                    margin-top:4px;
                }
                &.unit-details{
                    flex-direction:row;
                    gap:24px;
                    flex-wrap: nowrap;
                    div{
                        span{
                            &:nth-child(1){
                                font-weight:${({ theme }) => theme["bold-weight"]};
                                font-size:${({ theme }) =>
                                theme["font-size-small"]};
                                line-height: 12px;
                                color:${({ theme }) => theme["regent_gray"]};
                                margin-bottom: 4px;
                            }
                            &:nth-child(2){
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                color:${({ theme }) =>
                                  theme["secondary-color"]};
                            }
                            &:nth-child(3){
                                font-weight:${({ theme }) => theme["bold-weight"]};
                                font-size: 10px;
                                color:${({ theme }) =>
                                  theme["secondary-color"]};
                            }
                        }
                    }
                }
            }
        }
    }  
 .box-popover{
    width: 242px;
    &.box-number{
        color: #78909C;
    }
 }

 .tiles-box{
    border: 1px solid #CFD8DC;
    border-radius:8px;
    margin-bottom:${({ theme }) => theme["base-font-size"]};
    .tiles-table{
        margin-bottom:0;

        thead{
            tr{
                th{
                    padding:12px 24px;
                    &:first-of-type{
                        border-top-left-radius: 8px;
                    }
                    background-color:${({ theme }) => theme["aqua_haze"]};
                    color:${({ theme }) => theme["regent_gray"]};
                     font-size:${({ theme }) => theme["base-font-size-small"]};
                     width:200px;
                    max-width:200px;    
                     white-space: nowrap;
                     overflow: hidden;
                     text-overflow: ellipsis;
                                        
                }
            }
        }
        tbody{
            tr{
                td{
                    padding:12px 24px;
                    border-bottom-width:0;
                    width:200px;
                    max-width:215px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    span:nth-child(1){
                    font-size: ${({ theme }) => theme["medium-font-size"]};
                    padding-bottom:0px;
                    color:${({ theme }) => theme["regent_gray"]} ;
                    text-transform: capitalize;
                    display: block;
         
                    &.badge-sucess{
                        background-color:${({ theme }) =>
                          theme["success-color"]}10;
                        border:1px solid ${({ theme }) =>
                          theme["success-color"]};
                        font-size:${({ theme }) =>
                          theme["base-font-size-small"]};
                        border-radius:50px;
                        padding:0 0px;
                        color:${({ theme }) => theme["success-color"]};
                        text-align:center;
                    }
                    &.badge-primary{
                        background-color:${({ theme }) =>
                          theme["primary-color"]}10;
                        border:1px solid ${({ theme }) =>
                          theme["primary-color"]};
                        font-size:${({ theme }) =>
                          theme["base-font-size-small"]};
                        border-radius:50px;
                        padding:0 8px;
                        color:${({ theme }) => theme["primary-color"]};
                        text-align:center;

                    }
      
                }
                span:nth-child(2){
                    font-size: ${({ theme }) => theme["base-font-size-small"]};
                    color:${({ theme }) => theme["placeholder-color"]} ;

                    &.badge-sucess{
                        background-color:${({ theme }) =>
                          theme["success-color"]}10;
                        border:1px solid ${({ theme }) =>
                          theme["success-color"]};
                        font-size:${({ theme }) =>
                          theme["base-font-size-small"]};
                        border-radius:50px;
                        padding:0 8px;
                        max-width: 39px;
                        color:${({ theme }) => theme["success-color"]};
                    }
                    &.badge-primary{
                        background-color:${({ theme }) =>
                          theme["primary-color"]}10;
                        border:1px solid ${({ theme }) =>
                          theme["primary-color"]};
                        font-size:${({ theme }) =>
                          theme["base-font-size-small"]};
                        border-radius:50px;
                        padding:0 8px;
                        color:${({ theme }) => theme["primary-color"]};
                        max-width: 39px;
                        margin-top: 6px;

                    }
                } 
                }
            }
        }
 }
 }
 .stepper{
     display:flex ;
    align-items: center;
    padding:0 32px ;
        margin-bottom: 40px;
     .stepper-border{
         height:1px;
         background: linear-gradient(90deg, #1565D8 0%, #C4C4C4 100%);
         margin:0 12px;
         width: 100%;
     }
     .title{
                        font-size:${({ theme }) =>
                          theme["base-font-size-small"]}; 
                        margin-top:8px;
                        position:absolute ;
                        white-space:nowrap;
                        top: 11px;
                        &.left{
                            left: -32px;
                        }
                        &.right{
                            right:-30px;
                        }
     }
     .selected{
          color:${({ theme }) => theme["success-color"]}; 
     }
     
 }
 .progress-height{
    height:10px;
    min-width:124px;
 }
 .icon-arrow-right{
     &.purple-icon{
        &::before{
         color:${({ theme }) => theme["purple-color"]}; 
        }
     }
 }
  .icon-printer{
     &.success-color{
        &::before{
         color:${({ theme }) => theme["success-color"]}; 
        }
     }
 }
 .checkin-quantity-column{
    min-width:233px;
}
.storage-po-quantity-column{
    min-width:275px;
}
.action-icons {
    min-width: 130px;
}
`;
const ShipmentPlanWrapper = Styled.div`
// .ant-spin-nested-loading{
//     height: calc(100vh - 27vh)
// }
margin-left:4px;
.heading{
    margin-left: 13px;
}
.upper-header{
    margin-bottom: 22px;
}
.left-column{
    padding-left:10px;
    padding-right: 16px;
}
.right-column{
    h2{
        margin-bottom:14px;
    }
    .no-data{
        padding-top: 97px;
    padding-right: 22px;
    }
    .caption{
        margin-top: 44px;
     margin-right: 33px;

    }
}
.user-heading{
    font-size:16px;

}
.details{
    margin-bottom:14px;
}
.margin-bottom-29{
    margin-bottom:29px;  
}
.mb-14{
    margin-bottom: 14px; 
}
.variants{
    word-spacing: 4px;
    margin-bottom: 7px;
}

.quantity-info{
    margin-left: 3px;
    grid-column-gap: 65px;
    span{
    font-size:${({ theme }) => theme["base-font-size-small"]};   
    color:${({ theme }) => theme["regent_gray"]}; 
    margin-bottom: 4px;
    display: inline-block;
}
h4{
    font-size:${({ theme }) => theme["medium-font-size"]};    
    color:${({ theme }) => theme["secondary-color"]};
}

}
.input-range{
    margin-top: 35px;
    width:89%;
}
.add-top-spacing{
    margin-top: 46px;
}
.user-info{
    margin-left:2px;
    span{
        font-size:${({ theme }) => theme["medium-font-size"]};  
        color:${({ theme }) => theme["secondary-color"]};
        position: relative;
        margin-right: 49px;
        &::after{
            content: "";
            display: inline-block;
            width: 1px;
            background-color: #e1e1e1;
            height: 15px;
            margin-left: 24px;
            position: absolute;
            top: 3px;
        }
        &:last-child{
            &::after{
                display:none ;
            }
           
        }
    }
    &.user-info-plans{
        padding-left:19px;
        span{
            font-size: 12px;
            font-weight: 600;
            line-height: 14px; 
            letter-spacing: 0.06px;
            color:${({ theme }) => theme["placeholder-color"]};
            margin-right: 32px;
            &::after{
            margin-left: 16px;
        }
            em{
                color: #4E5969;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }
        }
    }
}
.shipment-plans{
    margin-top:16px;
    height: calc(100vh - 250px);
    overflow:auto;
        .plans{
            border: 1px solid #CCCCCC;
            padding:16px;
            border-radius: 4px;
            margin-bottom:${({ theme }) => theme["base-font-size"]};;
            .plans-inner-content{
                display: flex;
            justify-content: space-between;
            div{
                display: flex;
                flex-direction:column;
                span:nth-child(1){
                    font-size: ${({ theme }) => theme["base-font-size-small"]};
                    padding-bottom:0px;
                    color:${({ theme }) => theme["regent_gray"]} ;
                    text-transform: capitalize;
       
                }
                span:nth-child(2){
                    font-size: ${({ theme }) => theme["medium-font-size"]};
                    color:${({ theme }) => theme["secondary-color"]};
            }
            }
        }
        &.active{
            border: 2px solid #0076ff;
            background:rgba(15, 113, 255, 0.02);
        }
}
}
.shipment-box{
    border: 1px solid #E6E6E6;
    padding:16px;
    display:flex;
    border-radius:4px;
    .ship-column{
        span:first-child{
           font-size: ${({ theme }) => theme["base-font-size-small"]};
                 color:${({ theme }) => theme["regent_gray"]} ;
        }
    }
}
.product-info{
    background: rgba(238, 241, 245, 0.2);
    border-radius: 4px;
    border: 1px solid #E6E6E6;
}
.height-calc{
    height:calc(100vh - 658px);
    overflow:auto ;
}
.count{
      background-color:${({ theme }) => theme["black-color"]}04; 
      color:${({ theme }) => theme["primary-color"]};
      border-radius: 50px;
      font-size:${({ theme }) => theme["base-font-size-small"]}
      margin-left:8px;
      cursor:pointer ;
}
.review-shipping-label {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    background: ${({ theme }) => theme["warning-alert"]}05;
    border: 1px solid ${({ theme }) => theme["warning-alert"]};
    border-radius: 8px;
    padding: 12px;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    margin-bottom: 16px;
    img {
        margin-top: 4px;
    }
}
.percentage-ratio{
    display: flex;
    align-items:center;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px; 
    color: #009C34;
    font-style:normal;
}
.shipment-error-alert{
    color: #CF0909;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding-left: 20px;
    margin:10px 0 0;
}
`;
const DrawerWrapper = Styled.div`
.drawer-padding{
    padding:30px;
    height:100vh;
    overflow:auto;
    .product-details{
    background-color: ${({ theme }) => theme["mercury-color"]}20; 
    padding:24px;
    border:1px solid ${({ theme }) => theme["outline-color"]};
    border-radius:4px;
    .info{
                 div{
                     display:flex;
                     flex-direction:column;
                    span:nth-child(1){
                    font-size: ${({ theme }) => theme["base-font-size-small"]};
                    padding-bottom:0px;
                    color:${({ theme }) => theme["regent_gray"]} ;
                    text-transform: capitalize;
                }
                    span:nth-child(2){
                        font-size: ${({ theme }) => theme["medium-font-size"]};
                        color:${({ theme }) => theme["secondary-color"]} ;
                    }
                 }
                 .ellipse {
                    white-space: nowrap; 
                    overflow: hidden;
                    text-overflow: ellipsis; 
                 }
    }
    .units {
        div {
            span:first-child{
                font-size: ${({ theme }) => theme["medium-font-size"]};
                color:${({ theme }) => theme["secondary-color"]} ;
            }
            span:nth-child(2) {
                font-size: ${({ theme }) => theme["font-size-small"]};
                color:${({ theme }) => theme["regent_gray"]} ;
            }
        }
    }
    .note-text {
        width: 300px;
    }
    .product-status {
        width: 175px;
    }
}

}
.add-weight{
            margin-top:18px;
            grid-column-gap:18px;
            label{
                margin-bottom:3px;
                font-size:${({ theme }) => theme["base-font-size-small"]};
                color:${({ theme }) => theme["regent_gray"]}
            }
            .form-control{
                border-right:0;
                height:32px;
                color:${({ theme }) => theme["disabled-color"]};
                font-size:${({ theme }) => theme["medium-font-size"]};
                &::placeholder{
                color:${({ theme }) => theme["disabled-color"]};
                font-size:${({ theme }) => theme["medium-font-size"]};   
                }
            }
            .input-group-text{
                background:transparent;
                color:${({ theme }) => theme["disabled-color"]};
                font-size:${({ theme }) => theme["medium-font-size"]};
                border-left:0;
                height:32px;
            }
        }

.create-shipment{
    h4{
        font-size:${({ theme }) => theme["base-font-size"]}; 
        margin-bottom:12px;
    }
    .shipment-plan{
        min-width:205px;
        padding:12px;
        border: 1px solid ${({ theme }) => theme["outline-color"]};
        border-radius: ${({ theme }) => theme["border-radius-base"]};;
        cursor:pointer;
        span{
            color:${({ theme }) => theme["secondary-color"]}; 
            font-size:${({ theme }) => theme["medium-font-size"]};
        }
        &.active{
            background-color:${({ theme }) => theme["primary-color"]}; 
            span{
                color:${({ theme }) => theme["white-color"]};  
            }
        }
    }
}
.sub-heading{
    margin-top:24px;
    h4{
        font-size:${({ theme }) => theme["base-font-size"]}; 
        margin-bottom:12px;
    } 
    .input-details {
        width: 100%;
        height: calc(100vh - 400px);
        border: 1px solid ${({ theme }) => theme["outline-color"]};
        border-radius: 4px;
        padding: 16px;
        position: relative;

        .stepper-btn {
            position: absolute;
            bottom: 8px;
            right: 16px;
        }
    }
}
.box-category{
    h4{
        font-size:${({ theme }) => theme["base-font-size"]}; 
        margin-bottom:12px;
    }  
}
.info-status {
    background: rgba(0, 163, 255, 0.05);
    border: 1px solid ${({ theme }) => theme["information-color"]};
    border-radius: 4px;
    padding: 8px 12px;
}
.badge-success {
    background-color:${({ theme }) => theme["success-color"]}10;
    border:1px solid ${({ theme }) => theme["success-color"]};
    font-size:${({ theme }) => theme["base-font-size-small"]};
    border-radius:50px;
    padding:0 8px;
    color:${({ theme }) => theme["success-color"]};
}
.badge-danger {
    background-color:${({ theme }) => theme["danger-color"]}10;
    border:1px solid ${({ theme }) => theme["danger-color"]};
    font-size:${({ theme }) => theme["base-font-size-small"]};
    border-radius:50px;
    padding:0 8px;
    color:${({ theme }) => theme["danger-color"]};
}
.box-height {
    height: 180px;
    overflow: auto;
}
.add-box {
    border: 1px solid ${({ theme }) => theme["primary-color"]};
    border-radius: 4px;
    width: 32px;
    height: 32px;
    color: ${({ theme }) => theme["primary-color"]};
    cursor: pointer;
}
`;
export { TilesWrapper, ShipmentPlanWrapper, DrawerWrapper };
