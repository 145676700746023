import Styled from 'styled-components';
import { Modal } from "react-bootstrap";
const ModalWrapper = Styled(Modal)`
.select-address{
    .modal-header{
        border-bottom:none;
    }
}
&.confirmation-modal {
    .modal-dialog {
      width: 332px;
    }
    .modal-footer {
      button {
        background-color: ${({ theme }) => theme['primary-color']};
        color: ${({ theme }) => theme['white-color']};
        font-size: 14px;
        font-weight: 700;
        width: 180px;
      }
    }
  }
`
export {ModalWrapper}
