import React, { useEffect, useState, useMemo } from 'react';
import { Button, Tooltip, Typography, Collapse } from 'antd';
import { uniq } from 'lodash';
import { Row, Col } from "react-bootstrap";
import { AgGridReact } from 'ag-grid-react';

import FileUploadIcon1 from "../../../assets/images/file-upload-icon-3.svg";
import Arrow from "../../../assets/images/file-map-arrow.svg";
import FileUploadIconSuccess from "../../../assets/images/file-upload-icon-success.svg";
import FileUploadIconReset from "../../../assets/images/file-upload-reset.svg";
import FileUploadIconQ from "../../../assets/images/file-upload-q.svg";
import { UploadStyleWrapper } from '../style';

import EditImg from '../../../assets/images/edit.svg'

const CustomHeaderRenderer = ({ displayName, onHeaderCheck, isChecked, onMouseOver, onMouseLeave }) => {
  const handleHeaderClick = () => {
    onHeaderCheck(displayName, !isChecked);
  };

  return (
    <div
      id='test'
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      className='main-header-label'
      onClick={handleHeaderClick}>
      {isChecked ? (
        <img className='main-header-label-img' src={FileUploadIconSuccess}
          alt='Success' />
      ) : (
        <img className='main-header-label-img' src={FileUploadIconQ}
          alt='Q' />
      )}
      <span>{displayName}</span>
    </div>
  );
};

const { Panel } = Collapse;

const FileUpload = ({
  onCancel,
  uploadedData,
  fileColumns,
  setFileColumn,
  handleConfirm,
  headerChecks,
  setHeaderChecks
}) => {
  const rows = [{
    name: 'ASIN',
    item: 'item-1',
    color: '#D9F1FF',
    icon: FileUploadIcon1,
    required: true,
  },
  {
    name: 'Unit Cost',
    item: 'item-2',
    color: '#A2DCFE',
    icon: FileUploadIcon1,
    required: true,
  },
  {
    name: 'Buy Quantity',
    item: 'item-3',
    color: '#ACE1FE',
    icon: FileUploadIcon1,
    required: true,
  },
  {
    name: 'Bundle Quantity',
    item: 'item-4',
    color: '#BFE7FE',
    icon: FileUploadIcon1,
    required: true,
  },
  {
    name: 'Case QTY',
    item: 'item-5',
    color: '#FFF5E6',
    icon: FileUploadIcon1
  },
  {
    name: 'SKU',
    item: 'item-6',
    color: '#FFF2D8',
    icon: FileUploadIcon1
  },
  {
    name: 'Product Title',
    item: 'item-7',
    color: '#FFEFCA',
    icon: FileUploadIcon1
  },
  {
    name: 'Vendor Upc',
    item: 'item-8',
    color: '#FFECBD',
    icon: FileUploadIcon1
  },
  {
    name: 'Min Price',
    item: 'item-9',
    color: '#FFEAB0',
    icon: FileUploadIcon1
  }, {
    name: 'Max Price',
    item: 'item-10',
    color: '#FFE7A2',
    icon: FileUploadIcon1
  }, {
    name: 'Small And Light',
    item: 'item-11',
    color: '#FFE494',
    icon: FileUploadIcon1
  }, {
    name: 'Variety Pack',
    item: 'item-12',
    color: '#FFE187',
    icon: FileUploadIcon1
  }];

  const [hoveredRow, setHoveredRow] = useState({
    cellName: '',
    colName: ''
  });
  const [skippedColumns, setSkippedColumns] = useState([]);
  const [toBeSelectedTab, setToBeSelectedTab] = useState('');

  const handleHeaderCheck = (displayName, key) => {
    const newObj = { ...headerChecks };
    if (headerChecks?.[key]) delete newObj[key];
    else newObj[key] = displayName;
    setHeaderChecks(newObj);
  };

  const allFieldChecked = () => {
    const requiredFields = rows.filter(({ required }) => required).map(({ name }) => name);
    const includedFields = Object.values(headerChecks);

    return requiredFields.every(field => includedFields.includes(field));
  };

  const headerContent = allFieldChecked() ? (
    <h5 className='upload-title-wrapper'>
      <span className='header-text-inner-wrapper'>
        <img className='main-header-label-img'
          src={FileUploadIconSuccess}
          alt='Success'
        />
      </span>
      REQUIRED columns:
    </h5>
  ) : (
    <h5 className='upload-title-wrapper'>
      <span className='header-text-inner-wrapper'>1.</span>  REQUIRED columns:
    </h5>
  );
  const allOptionalFieldsChecked = () => {
    const optionalFields = rows.filter(({ required }) => !required).map(({ name }) => name);
    const includedFields = Object.values(headerChecks);

    return optionalFields.every(field => includedFields.includes(field));
  };

  const headerOptionalContent = allOptionalFieldsChecked() ? (
    <h5 className='upload-title-wrapper'>
      <span className='header-text-inner-wrapper'>
        <img className='main-header-label-img' src={FileUploadIconSuccess} alt='Success' />
      </span>
      Optional columns:
    </h5>
  ) : (
    <h5 className='upload-title-wrapper'>
      <span className='header-text-inner-wrapper'>2.</span> Optional columns:
    </h5>
  );

  const getColumnName = () => {
    const preObj = Object.values(headerChecks);
    if (toBeSelectedTab) return toBeSelectedTab;
    else if (!skippedColumns?.includes('ASIN') && !preObj.includes('ASIN')) return 'ASIN';
    else if (!skippedColumns?.includes('Unit Cost') && !preObj.includes('Unit Cost')) return 'Unit Cost';
    else if (!skippedColumns?.includes('Buy Quantity') && !preObj.includes('Buy Quantity')) return 'Buy Quantity';
    else if (!skippedColumns?.includes('Bundle Quantity') && !preObj.includes('Bundle Quantity')) return 'Bundle Quantity';
    else if (!skippedColumns?.includes('Case QTY') && !preObj.includes('Case QTY')) return 'Case QTY';
    else if (!skippedColumns?.includes('SKU') && !preObj.includes('SKU')) return 'SKU';
    else if (!skippedColumns?.includes('Product Title') && !preObj.includes('Product Title')) return 'Product Title';
    else if (!skippedColumns?.includes('Vendor Upc') && !preObj.includes('Vendor Upc')) return 'Vendor Upc';
    else if (!skippedColumns?.includes('Min Price') && !preObj.includes('Min Price')) return 'Min Price';
    else if (!skippedColumns?.includes('Max Price') && !preObj.includes('Max Price')) return 'Max Price';
    else if (!skippedColumns?.includes('Small And Light') && !preObj.includes('Small And Light')) return 'Small And Light';
    else if (!skippedColumns?.includes('Variety Pack') && !preObj.includes('Variety Pack')) return 'Variety Pack';
    else if (skippedColumns?.length) setSkippedColumns([]);
  }

  const resetMapping = (key) => {
    const newObj = { ...headerChecks };
    if (headerChecks?.[key]) delete newObj[key];
    setHeaderChecks(newObj);
  }

  useEffect(() => {
    setFileColumn(uniq([...Object.keys(headerChecks), ...fileColumns]));
  }, [headerChecks]);

  const columnDefs = fileColumns?.map((columnName) => {
    return {
      headerName: headerChecks?.[columnName],
      headerGroupComponent: (params) => (
        <CustomHeaderRenderer
          {...params}
          columnName={columnName}
          onHeaderCheck={() => {
            handleHeaderCheck(getColumnName(), columnName);
            setHoveredRow({});
            setToBeSelectedTab('');
          }}
          isChecked={headerChecks[columnName]}
          onMouseOver={() => {
            if (!Object.keys(headerChecks)?.includes(columnName) && hoveredRow?.colName !== columnName) {
              setHoveredRow({
                colName: columnName,
                cellName: getColumnName()
              });
            }
          }}
          onMouseLeave={() => setHoveredRow({})}
        />
      ),
      children: [
        {
          headerName: columnName,
          field: columnName,
          width: '110',
          cellStyle: (params) => {
            if (rows?.find(({ name }) => name === headerChecks[columnName]))
              return { backgroundColor: rows?.find(({ name }) => name === headerChecks[columnName])?.color }
            else return { backgroundColor: getColumnName() && hoveredRow?.colName === columnName ? 'rgba(0, 156, 52, 0.20)' : '' }
          },
          cellRendererFramework: ({ data }) => {
            return (
              <Tooltip title={data?.[columnName]}>
                <span>{data?.[columnName]}</span>
              </Tooltip>
            );
          }
        },
      ]
    }
  });

  return (
    <UploadStyleWrapper>
      <div className="file-upload-header">
        <h2 className="file-upload-heading">Select parameters to finish uploading</h2>
        <p className="file-upload-paragraph">Click the column header which matching with following description to mark and export your fields correctly</p>
      </div>
      <div className="file-upload-main">
        <Collapse defaultActiveKey={['requiredColumns', 'optionalColumns']} >
          <Panel
            header={
              <div className='header-inner-wrapper'>
                {headerContent}
                <button>Change <img src={EditImg} alt="edit-icon" /></button>
              </div>
            }
            key="requiredColumns">
            <Row>
              {
                rows?.filter(({ required }) => required).map(({ name, item, icon, color }) => {
                  return <Col xs="3">
                    <div
                      className={`checklist-item ${item}`}
                      style={name === getColumnName() ? { backgroundColor: 'rgba(0, 156, 52, 0.20)' } : { color }}
                      onClick={() => {
                        if (!headerChecks[Object.keys(headerChecks).find(key => headerChecks[key] === name)]) setToBeSelectedTab(name);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        {headerChecks[Object.keys(headerChecks).find(key => headerChecks[key] === name)] ? (
                          <img src={FileUploadIconSuccess} className="step-icon" alt="step-icon" />
                        ) : (
                          <img src={icon} className="step-icon" alt="step-icon" />
                        )}
                        <span className="item-text">{name}</span>
                      </div>
                      {headerChecks[Object.keys(headerChecks).find(key => headerChecks[key] === name)] &&
                        <button
                          className='reset-button'
                          onClick={(e) => {
                            e.stopPropagation();
                            resetMapping(Object.keys(headerChecks).find(key => headerChecks[key] === name))
                          }}
                        >Reset<img src={FileUploadIconReset} alt='file-upload-icon' className='reset-icon' /></button>
                      }
                    </div>
                  </Col>
                })
              }
            </Row>
          </Panel>
          <Panel
            header={
              <div className='header-inner-wrapper'>
                {headerOptionalContent}
                <button>Skip All</button>
              </div>
            }
            key="optionalColumns">
            <Row>
              {
                rows?.filter(({ required }) => !required).map(({ name, item, icon, color }) => {
                  return <Col xs="3">
                    <div
                      onClick={() => {
                        if (!headerChecks[Object.keys(headerChecks).find(key => headerChecks[key] === name)]) setToBeSelectedTab(name);
                      }}
                      className={`checklist-item ${item}`}
                      style={name === getColumnName() ? { backgroundColor: 'rgba(0, 156, 52, 0.20)' } : { color }}
                    >
                      <div className="d-flex align-items-center">
                        {headerChecks[Object.keys(headerChecks).find(key => headerChecks[key] === name)] ? (
                          <img src={FileUploadIconSuccess} className="step-icon" alt="step-icon" />
                        ) : (
                          <img src={icon} className="step-icon" alt="step-icon" />
                        )}
                        <span className="item-text">{name}</span>
                      </div>
                      {headerChecks[Object.keys(headerChecks).find(key => headerChecks[key] === name)] &&
                        <button
                          className='reset-button'
                          onClick={(e) => {
                            e.stopPropagation();
                            resetMapping(Object.keys(headerChecks).find(key => headerChecks[key] === name))
                          }}
                        >Reset<img src={FileUploadIconReset} alt='file-upload-icon' className='reset-icon' /></button>
                      }
                    </div>
                  </Col>
                })
              }
            </Row>
          </Panel>
        </Collapse>
        {getColumnName() && !headerChecks[getColumnName()] && <div className='table-header-upload-file-wrapper'>
          <img src={Arrow} alt='Arrow' />
          <h5 className='mb-0'><b>Choose the column {getColumnName()} below</b></h5>
          {!rows.find(({ name }) => name === getColumnName())?.required && (
            <Button variant="text" onClick={() => setSkippedColumns([...skippedColumns, getColumnName()])}>
              Skip this column
            </Button>
          )}
        </div>
        }
        <div className="ag-theme-bulham" style={{ height: '205px', width: '100%' }}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={uploadedData.slice(0, 3)}
            defaultColDef={{ suppressMenu: true, }}
            headerHeight={32}
            rowHeight={46}

          />
        </div>
        <div className='d-flex justify-content-end gap-12'>
          <button className='outlined-btn' onClick={() => {
            onCancel();
            setHeaderChecks({});
          }}>Cancel</button>
          <button
            className='outlined-btn'
            onClick={() => {
              if (Object.keys(headerChecks)?.length) handleConfirm(headerChecks);
            }}
          >Confirm and Proceed</button>
        </div>
      </div>
    </UploadStyleWrapper>
  );
};

export default FileUpload;