import React, { useState, useEffect, useRef, useCallback } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { Radio, Box } from '@mui/material';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { BsArrowLeftShort } from "react-icons/bs";
import { Stack } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal, Drawer, Input, Tooltip, Popover, Spin, DatePicker, notification, Select as SelectField, Radio as RadioCheck} from "antd";
import CustomModal from '../../../src/components/uiComponent/modal/index'
import { round, debounce, cloneDeep, findIndex, ceil, isEmpty, set, sortBy } from "lodash";
import dropdownIcon from "../../assets/images/pagination-arrow-down.svg";
import calenderIcon from '../../assets/images/calendar-icon.png';
import InfoRedIcon from '../../assets/images/newImages/info.svg'
import BackArrow from '../../assets/images/newImages/back-arrow.svg';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Steppers from "./stepper";
import "../../components/radioCard/style.css";
import Congratulation from "../newPurchaseOrder/confirmDeliveryInformation/congratulation";
import Button from "../../components/uiComponent/button";
import SearchInput from "../../components/uiComponent/inputs/searchinput/";
import Select from "../../components/uiComponent/select";
import infoIcon from "../../assets/images/info-red-icon.svg";
import AgGrid from "../../components/ag-grid-table";
import editIcon from "../../assets/images/edit-blue-icon.svg";
import BadgeImg from "../../assets/images/BadgeImg.svg";
import RightArrow from "../../assets/images/pagination-arrow-right.svg"
import LeftArrow from "../../assets/images/pagination-arrow-left.svg"
import GrowIcon from "../../assets/images/network-grow-icon.svg"
import Pagination from "../../components/pagination";
// import AddToStorageModal from "../../components/addToStorageModal";
import Storage from "../../containers/fbaInbound/drawer/storage";

import {
  setFbaShipmentState,
  GetWarehouseAddress,
  UpdateCompanyAddress,
  CreateWarehouseAddress,
  UpdatePendingPos,
  GetCompanyAddress
} from "../../slices/fba-shipment";
import {
  GetAllPos,
  GetVendors,
  GetDraftPOs,
  DeleteDraftPOs,
  SwitchPoAddress,
  UpdateDeliveryInfo,
  GetStatusCount,
  UpdateLastSeen,
  getReconExcel,
  GetSinglePo
} from "../../slices/fba-inbound/purchase-order";
import { GetAllLocations } from "../../slices/fba-inbound/to-receive";
import { GetStorageUsersList } from "../../slices/fba-inbound/to-check-in";
import { GetRecievedTrackings, GetAllMatchingPo, AttachMatchingPo, setFbaInboundState, GetUsers } from "../../slices/fba-inbound-new";

import { countries } from "../../constants/Countries";
import { STATES } from "../../constants/vendors";
import { PurchaseOrderWrapper, RecievedShipmentWrapper, MatchingPoWrapper } from "./style";
import DeliveryInfo from "./confirmDeliveryInformation/deliveryInfo";
import InvoicePopup from "./invoicePopup/invoicePopup";
const { RangePicker } = DatePicker;

const statusValues = [
  {
    "value": 0,
    "title": "Submitted",
    "key": "submitted",
    "daysDifference": 0
  },
  {
    "value": 0,
    "title": "Confirmed",
    "key": "confirmed",
    "daysDifference": 0
  },
  {
    "value": 0,
    "title": "Payment",
    "key": "payment",
    "daysDifference": 0
  },
  {
    "value": 0,
    "title": "Pending Shipment",
    "key": "pending-shipment",
    "daysDifference": 0
  },
  {
    "value": 0,
    "title": "Shipped",
    "key": "shipped",
    "daysDifference": 0
  },
  {
    "value": 1,
    "title": "Inbound to Amazon FBA",
    "key": "inbound",
    "daysDifference": 0
  },
  {
    "value": 0,
    "title": "Cancelled",
    "key": "cancelled",
    "daysDifference": 0
  },
  {
    "value": 1,
    "title": "Pending Client Approval",
    "key": "pending-client",
    "daysDifference": 0
  },
  {
    "value": 0,
    "title": "Completed",
    "key": "completed",
    "daysDifference": 0
  },
  {
    "value": 1496,
    "title": "All",
    "key": "all",
    "daysDifference": 0
  }
]

let gridApi;
const PurchaseOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { Option } = SelectField;
  const { confirmSuccess } = useSelector((store) => store.fbaShipment);
  const { usersList } = useSelector((store) => store.fbaInboundShipment);
  const {
    PoList,
    loading,
    vendors,
    draftPOsCount,
    draftPosList,
    statuses,
    poOrdersCount,
    getStatusLoading,
    singlePoList
  } = useSelector((store) => store.fbaInbound);
  const { companyAddresses, loading: fbaLoading, warehouseAddresses } = useSelector((store) => store.fbaShipment);
  const { currentUser } = useSelector((state) => state.auth || {});
  const { received_trackings, loading: recievedTrackingLoading, matchingPos, fetchingMatchingPos, fetchingAttachedPos } = useSelector((state) => state.fbaInboundShipment || {});
  const [confirmPo,setConfirmPo]=useState(false)
  const [open, setOpen] = useState(false);
  const [congratulationModal, setCongratulationModal] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [secondDrawerVisible, setSecondDrawerVisible] = useState(false);
  const [showAddressFields, setShowAddressFields] = useState(false);
  const [filteredAddresses, setFilteredAddresses] = useState([]);
  const [isButtonOutlined, setIsButtonOutlined] = useState(true);
  const [isEdit, setIsEdit] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [addressId, setAddressId] = useState(null);
  const [trackingModalVisible, setTrackingModalVisible] = useState(false);
  const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);
  const [trackingInfo, setTrackingInfo] = useState();
  const [searchValue, setSearchValue] = useState('');
  const [isViewDraftPO, setIsViewDraftPO] = useState(false);
  const [draftPoFilter, setDraftPoFilter] = useState({ page_num: 1, per_page: 25 });
  const [allPoPagination, setAllPoPagination] = useState({ page_num: 1, per_page: 25 });
  const [poIdToDelete, setPoIdToDelete] = useState('');
  const [poId, setPoId] = useState('');
  const [poDestination, setPoDestination] = useState('');
  const [storage, setStorage] = useState(false)
  const [locationsList, setLocationsList] = useState([]);
  const [userList, setUsersList] = useState([]);
  const [selectedRange, setSelectedRange] = useState([]);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [recievedTrackings, setRecievedTrackings] = useState([]);
  const [latestTracking, setLatestTracking] = useState([]);
  const [trackingWithoutPo, setTrackingWithoutPo] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tracking, setTracking] = useState();
  const formRef = useRef(null);
  const dateRangePickerRef = useRef(null);
  const [selectedRadio, setSelectedRadio] = useState('all');
  const [filters, setFilters] = useState([]);
  const [searchInput, setSearchInput] = useState('')
  const [vendorSearch, setVendorSearch] = useState('');
  const [userSearch, setUserSearch] = useState('');
  const [invoiceInfo, setInvoiceInfo] = useState('');
  const [search, setSearch] = useState('');
  const [viewDraftsData, setViewDraftsData] = useState(statusValues);
  const [selectedPo, setSelectedPo] = useState(null);
  const initialAddress = {
    title: '',
    address_line_1: '',
    address_line_2: '',
    city: '',
    state_or_province: '',
    country: '',
    zipcode: '',
  };
  const [addressDetails, setAddressDetails] = useState(initialAddress);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const renderExtraFooter = () => {
    if (selectedRange.length === 2) {
      const start = selectedRange[0].format('MMM DD');
      const end = selectedRange[1].format('MMM DD, YYYY');
      return <div>{`${start} - ${end}`}</div>;
    }
    return null;
  };

  const compareDates = (dates, dateToCompare) => {
    return dateToCompare.isBetween(dates[0], dates[1], null, '[]') || (
      dates[0].isSame(dateToCompare, 'day') ||
      dates[1].isSame(dateToCompare, 'day')
    )
  }
  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  }

  const handleClickOutside = (event) => {
    if (!dateRangePickerRef?.current?.contains(event.target)) {
      setOpenDatePicker(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleAddressFilter = debounce(({ value }) => {
    let filtered = [];
    const lowerCaseValue = value.toLowerCase();
    if (!isViewDraftPO || poDestination === 1) {
      filtered = companyAddresses?.filter((address) => address?.title.toLowerCase().includes(lowerCaseValue));
    } else {
      filtered = warehouseAddresses?.filter((address) => address?.title.toLowerCase().includes(lowerCaseValue));
    }
    setFilteredAddresses(filtered);
  }, 500);

  const handleSearchChange = ({ target }) => {
    const value = target.value;
    setSearchValue(value);
    handleAddressFilter({ value });
  };

  const handleChangeVendor = (selectedOption) => {
    const value = selectedOption || null;
    setVendorSearch(value);
    selectVendorFilter(value);
  };

  const handleChangeUser = (selectedOption) => {
    const value = selectedOption || null;
    setUserSearch(value);
    selectUserFilter(value);
  };

  const selectUserFilter = (userId) => {
    if (isViewDraftPO) {
      setDraftPoFilter({
        ...draftPoFilter,
        page_num: 1,
        user_id: userId?.value
      })
    } else {
      const filtersArray = cloneDeep(filters);
      const userFilterIndex = findIndex(filtersArray, { field: 'user_id' });

      if (userId) {
        if (userFilterIndex === -1) {
          filtersArray.push({
            field: 'user_id',
            operator: '=',
            value: userId.value,
          });
        } else {
          filtersArray[userFilterIndex].value = userId.value;
        }
      } else if (userFilterIndex !== -1) {
        filtersArray.splice(userFilterIndex, 1);
      }

      setFilters(filtersArray);

      const filtersHash = { filters: filtersArray };
      setAllPoPagination({ page_num: 1, per_page: allPoPagination.per_page });
      dispatch(GetAllPos({ filters_hash: filtersHash, page_num: 1, per_page: allPoPagination.per_page }));
    }
  };


  const selectVendorFilter = (vendorId) => {
    if (isViewDraftPO) {
      setDraftPoFilter({
        ...draftPoFilter,
        page_num: 1,
        vendor_id: vendorId?.value
      })
    } else {
      const filtersArray = cloneDeep(filters);
      const vendorFilterIndex = findIndex(filtersArray, { field: 'vendor_id' });

      if (vendorId) {
        if (vendorFilterIndex === -1) {
          filtersArray.push({
            field: 'vendor_id',
            operator: '=',
            value: vendorId.value,
          });
        } else {
          filtersArray[vendorFilterIndex].value = vendorId.value;
        }
      } else if (vendorFilterIndex !== -1) {
        filtersArray.splice(vendorFilterIndex, 1);
      }

      setFilters(filtersArray);

      const filtersHash = { filters: filtersArray };
      setAllPoPagination({ page_num: 1, per_page: allPoPagination.per_page });
      dispatch(GetAllPos({ filters_hash: filtersHash, page_num: 1, per_page: allPoPagination.per_page }));
    }
  };

  const handleConfirmAddress = () => {
    if (isViewDraftPO) {
      dispatch(UpdatePendingPos({ draft_po: { ship_to_address_id: selectedCard }, poId })).then(({ payload }) => {
        if (payload?.success) {
          setDrawerVisible(false);
          setSelectedCard(null);
          setPoId('');
          dispatch(GetDraftPOs(draftPoFilter));
          setDrawerVisible(false);
        }
      });
    } else {
      dispatch(SwitchPoAddress({ id: addressId, company_address_id: selectedCard })).then(({ payload }) => {
        if (payload?.data?.success) {
          setDrawerVisible(false);
          setSelectedCard(null);
          dispatch(GetAllPos({ filters_hash: { filters: filters }, ...allPoPagination }));
        }
      });
    }
  }

  const handleRadioChange = (value) => {
    if (isViewDraftPO) {
      setVendorSearch('')
      setSearchInput('')
    }
    setSelectedRadio(value);
    setIsViewDraftPO(false);

    const filtersArray = cloneDeep(filters);

    if (value === "all") {
      filtersArray.forEach(filter => {
        if (filter.field === 'pending_status') {
          filter.value = [];
        }
      });
    } else {
      const filterIndex = findIndex(filtersArray, { field: 'pending_status' });
      if (filterIndex === -1) {
        if (value) {
          filtersArray.push({
            field: 'pending_status',
            operator: '=',
            value: [radioHashMappings[value]]
          });
        }
      } else if (value) {
        filtersArray[filterIndex].value = [radioHashMappings[value]];
      } else {
        filtersArray.splice(filterIndex, 1);
      }
    }

    setFilters(filtersArray);
    const filtersHash = { filters: filtersArray };
    setAllPoPagination({ page_num: 1, per_page: 25 });
    dispatch(GetAllPos({ filters_hash: filtersHash, page_num: 1, per_page: 25 }));
  };

  const handlePagination = (number, key) => {
    const value = Number(number);
    let paginationClone;
    if (isViewDraftPO) {
      paginationClone = { per_page: draftPoFilter?.per_page, page_num: draftPoFilter?.page_num };
      if (key === "pageLimit") paginationClone = { page_num: 1, per_page: value };
      else paginationClone = { per_page: draftPoFilter?.per_page, page_num: value };

      setDraftPoFilter({
        ...draftPoFilter,
        ...paginationClone
      });
    } else {
      paginationClone = { ...allPoPagination };
      if (key === "pageLimit") paginationClone = { page_num: 1, per_page: value };
      else paginationClone = { ...paginationClone, page_num: value };
      dispatch(GetAllPos({ filters_hash: { filters: filters }, ...paginationClone }));
      setAllPoPagination({
        ...paginationClone
      })
    }
  };

  const refreshGridClick = () => {
    if (isViewDraftPO) dispatch(GetDraftPOs(draftPoFilter));
    else dispatch(GetAllPos({ filters_hash: { filters: filters }, ...allPoPagination }));
  };

  const countryList = countries?.map(({ name, code }, index) => {
    return (
      <Option key={code} value={code}>
        {name}
      </Option>
    );
  });

  const statesOptions = STATES.map(({ value, name }, index) => {
    return (
      <Option value={name} key={index} label={name}>
        {name}
      </Option>
    );
  });

  const handlePoFilter = debounce((value) => {
    setSelectedPo(null);
    dispatch(GetAllMatchingPo({ search: value }));
  }, 500);

  const handleInputChange = ({ target }) => {
    const value = target.value;
    setSearchInput(value);
    debouncedHandleFilter({ field: 'keyword', value });
  };

  const handleFilter = ({ value, field }) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    if (isViewDraftPO) {
      timeoutRef.current = setTimeout(() => {
        setDraftPoFilter({
          ...draftPoFilter,
          page_num: 1,
          search: value
        });
      }, 800);
    } else {
      const filtersArray = cloneDeep(filters);
      const filterIndex = findIndex(filtersArray, { field });

      if (filterIndex === -1) {
        if (value) {
          filtersArray.push({
            field: 'keyword',
            operator: 'contains',
            value,
          });
        }
      } else if (value) {
        filtersArray[filterIndex].value = value;
      } else {
        filtersArray.splice(filterIndex, 1);
      }
      setFilters(filtersArray);

      timeoutRef.current = setTimeout(() => {
        const filtersHash = { filters: filtersArray };
        setAllPoPagination({ page_num: 1, per_page: allPoPagination.per_page });
        dispatch(GetAllPos({ filters_hash: filtersHash, page_num: 1, per_page: allPoPagination.per_page }));
      }, 800);
    }
  };

  const timeoutRef = useRef(null);
  const debouncedHandleFilter = debounce(handleFilter, 200);

  const handleAddAddressClick = () => {
    setShowAddressFields(!showAddressFields)
    setIsButtonOutlined(prevState => !prevState);
    if ((showAddressFields || isEdit) && (String(addressDetails.zipcode)?.length < 4 || String(addressDetails.zipcode)?.length > 6)) {
      notification.info({
        message: "Address Validation",
        description: 'Postal length must in between 4 to 6.',
        top: 65
      });
      return;
    }

    if (isEdit) {
      dispatch(UpdateCompanyAddress({ address: addressDetails, id: selectedId })).then(({ payload }) => {
        setSelectedCard(selectedId);
        if (payload?.success) {
          setIsEdit(false);
          setAddressDetails(initialAddress);
          setShowAddressFields(false);
          if (isViewDraftPO) {
            dispatch(GetWarehouseAddress(currentUser?.company_id));
            dispatch(GetDraftPOs(draftPoFilter));
            setSearchValue('');
          } else {
            dispatch(GetAllPos({ filters_hash: { filters: filters }, ...allPoPagination }));
            dispatch(GetCompanyAddress(currentUser?.company_id));
            setSearchValue('');
          }
        }
      });
    } else if (showAddressFields) {
      dispatch(CreateWarehouseAddress({ address: addressDetails, id: currentUser?.company_id })).then(({ payload }) => {
        if (payload?.success) {
          setSelectedCard(payload?.address?.id);
          setShowAddressFields(false);
          setAddressDetails(initialAddress);
          if (isViewDraftPO) dispatch(GetWarehouseAddress(currentUser?.company_id));
          else dispatch(GetCompanyAddress(currentUser?.company_id));
        }
      });
    }
  };

  const handleEditAddressClick = (address) => {
    setIsButtonOutlined(true);
    setSelectedId(address?.id);
    setSelectedCard(address?.id);
    const {
      title,
      address_line_1,
      address_line_2,
      city,
      state_or_province,
      country,
      zipcode,
    } = address;

    setAddressDetails({
      title,
      address_line_1,
      address_line_2,
      city,
      state_or_province,
      country,
      zipcode,
    });

    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleTrackingModal = () => {
    setTrackingModalVisible(!trackingModalVisible);
  };
  const handleInvoiceModal = (invoice) => {
    setInvoiceInfo(invoice);
    setInvoiceModalVisible(true);
  };
  const handleCloseInvoiceModal = () => {
    setInvoiceModalVisible(false);
  };
  const handleCardClick = (id) => {
    setSelectedCard(id);
  };
  const handleSecondDrawer = () => {
    setSecondDrawerVisible(true);
  };

  const formatAmount = (amount) => {
    let value = 'N/A';
    if (amount || amount === 0) {
      value = round(amount, 2).toFixed(2);
      value = `$${value}`;
    }
    return value;
  }

  const handleRowSelected = (params) => {
    const grid = params.api;
    const rows = grid.getSelectedRows();
    setSelectedRows(rows);
  };

  const handleSuccess = () => {
    dispatch(GetSinglePo(selectedRowId));
  };

  const radioHashMappings = {
    "submitted": 1,
    "confirmed": 2,
    "payment": 3,
    "pending-shipment": 4,
    "shipped": 5,
    "inbound": 7,
    "cancelled": 8,
    "pending-client": 9,
    "completed": 13
  };

  const statusOptions = vendors?.map((vendor) => ({
    value: vendor.id,
    label: vendor.name,
  }));

  const userOptions = usersList?.map((user) => ({
    value: user.id,
    label: user.full_name,
  }));

  const Orders = [
    {
      title: (
        <>
          Units
          <br /> at 3PL
        </>
      ),
      value: 24,
      key: "total",
    },
    {
      title: (
        <>
          Waiting
          <br /> to be Picked
        </>
      ),
      value: 1,
      key: "shipped",
    },
    {
      title: (
        <>
          Unsellable
          <br /> Units
        </>
      ),

      value: 32,
      key: "missing",
    },
  ];
  const mapTitle = (title) => {
    const titleMappings = {
      'Inbound to Amazon FBA': 'inbound',
      'Submitted': 'submitted',
      'Confirmed': 'confirmed',
      'Payment': 'payment',
      'Pending Shipment': 'pending-shipment',
      'Shipped': 'shipped',
      'Cancelled': 'cancelled',
      'Pending Client Approval': 'pending-client',
      'Completed': 'completed',
      'All': 'all'
    };

    return titleMappings[title];
  };

  const defaultColDef = {
    singleClickEdit: true,
    resizable: true,
    sortable: false
  }
  const getTabContent = ({ vendor, warehouse, amazon, discrepancy }) => {
    return (
      <Popover
        placement="bottom"
        trigger="hover"
        content={
          <PurchaseOrderWrapper>
            <div className="tooltip-wrapper-2">
              <div className="data-wrapper">
                <div className="heading">Vendor:</div>
                <div className="data-wrapper-inner">
                  <div className="data">
                    <span>{vendor.order}</span>
                    <span className="label">Order</span>
                  </div>
                  <div className="data">
                    <span>{vendor.invoice}</span>
                    <span className="label">Invoice</span>
                  </div>
                  <div className="data danger">
                    <span>{vendor.pending}</span>
                    <span className="label">Discrepency units</span>
                  </div>
                </div>
              </div>
              <div className="data-wrapper">
                <div className="heading">3PL:</div>
                <div className="data-wrapper-inner">
                  <div className="data">
                    <span>{warehouse.shipped}</span>
                    <span className="label">Shipped</span>
                  </div>
                  <div className="data">
                    <span>{warehouse.received}</span>
                    <span className="label">Received</span>
                  </div>
                  {/* <div className="data danger">
                    <span>{warehouse.pending}</span>
                    <span className="label">Discrepency units</span>
                  </div> */}
                </div>
              </div>
              <div className="data-wrapper">
                <div className="heading">Amazon:</div>
                <div className="data-wrapper-inner">
                  <div className="data">
                    <span>{amazon.shipped}</span>
                    <span className="label">Shipped</span>
                  </div>
                  <div className="data">
                    <span>{amazon.received}</span>
                    <span className="label">Received</span>
                  </div>
                  <div className="data">
                    <span>{amazon.reimbursed}</span>
                    <span className="label">Reimbursed</span>
                  </div>
                  <div className="data danger">
                    <span>{amazon.pending}</span>
                    <span className="label">Discrepency units</span>
                  </div>
                </div>
              </div>
            </div>

          </PurchaseOrderWrapper>
        }
      >
        <div className="tabs-inner-data-wrapper">Discrepancy units - <span>{discrepancy}</span></div>
      </Popover>
    );
  };

  const columnDefinitions = [
    {
      headerName: '',
      field: '',
      menuTabs: [],
      pinned: true,
      width: 40,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      sortable: false,
    },
    {
      headerName: "Vendor Name",
      minWidth: isViewDraftPO ? 100 : 90,
      flex: 1,
      field: "vendor_name",
      cellRendererFramework: ({ value }) => {
        return (
          <Tooltip title={value}>
            <span className="vendor-name">{value}</span>
          </Tooltip>
        )
      }
    },
    {
      headerName: "PO #",
      field: isViewDraftPO ? "po_name" : "po_code",
      minWidth: isViewDraftPO ? 150 : 90,
      flex: 1,
      cellRendererFramework: ({ value, data }) => {
        const { id, po_name } = data;
        const href = isViewDraftPO ? `/po-detail?id=${data?.id}` : `/po/my-orders/${id}`;
        return (
          <Tooltip title={value || po_name || "N/A"}>
            <a target="_blank" href={href}>
              {" "}
              {value || po_name || "N/A"}{" "}
            </a>
          </Tooltip>
        );
      },
    },
    ...(!isViewDraftPO ? [{
      headerName: "Created",
      minWidth: 110,
      flex: 1,
      field: "created_at",
      cellRenderer: ({ value }) => {
        if (value) {
          return (
            <Tooltip title={moment(value).format('MMM, DD YYYY hh:mm:ss')}>
              {moment(value).format('MMM, DD YYYY hh:mm:ss')}
            </Tooltip>
          )
        }
        return 'N/A';
      }
    }, {
      headerName: "PO Status",
      minWidth: 110,
      flex: 1,
      cellClass: "select-field-cell",
      cellRendererFramework: ({ data }) => {
        const { pending_status, id } = data;
        const handleStatusChange = async (selectedStatus) => {
          try {
            const { payload } = await dispatch(UpdateDeliveryInfo({ poId: id, pending_po_index: { pending_status: selectedStatus } }));
            if (payload?.data?.success) {
              dispatch(GetAllPos({ filters_hash: { filters: filters }, ...allPoPagination }));
              dispatch(GetStatusCount());
              notification.success({
                message: 'Status Updated Successfully',
                top: 65,
              });
            }
          } catch (error) {
            console.error('Error updating status:', error);
          }
        };
        const getStatusClass = () => {
          switch (pending_status) {
            case 'Submitted':
              return 'submitted';
            case 'Confirmed':
              return 'confirmed';
            case 'Payment':
              return 'payment';
            case 'Pending Shipment':
              return 'pending-shipment';
            case 'Shipped':
              return 'shipped';
            case 'Inbound to Amazon FBA':
              return 'inbound';
            case 'Cancelled':
              return 'cancelled';
            case 'Pending Client Approval':
              return 'pending-approval';
            case 'Completed':
              return 'completed';
            default:
              return '';
          }
        };
        return (
          <div className={`po-table-status-select ${getStatusClass()}`}>
            <SelectField
              value={pending_status}
              style={{ width: '100%' }}
              onChange={handleStatusChange}
              dropdownClassName="po-table-status-select-dropdown"
            >
              <Option value="" disabled>Select Status</Option>
              <Option value="Submitted">Submitted</Option>
              <Option value="Confirmed">Confirmed</Option>
              <Option value="Payment">Payment</Option>
              <Option value="Pending Shipment">Pending Shipment</Option>
              <Option value="Shipped">Shipped</Option>
              <Option value="Inbound to Amazon FBA">Inbound to Amazon FBA</Option>
              <Option value="Cancelled">Cancelled</Option>
              <Option value="Pending Client Approval">Pending Client Approval</Option>
              <Option value="Completed">Completed</Option>
            </SelectField>
          </div>
        );
      }
    }] : []),
    {
      headerName: "Total Costs",
      minWidth: isViewDraftPO ? 100 : 70,
      flex: 1,
      field: "total_cost",
      cellRendererFramework: ({ value }) => {
        const total_cost = formatAmount(value);
        return (
          <Tooltip title={total_cost}>
            <span>{total_cost}</span>
          </Tooltip>
        )
      }
    },
    {
      headerName: "Total Profit",
      minWidth: isViewDraftPO ? 100 : 70,
      flex: 1,
      field: "total_profit",
      cellRendererFramework: ({ value }) => {
        const total_profit = formatAmount(value);
        return (
          <Tooltip title={total_profit}>
            <span className={value < 0 ? "total-lose" : "total-profit"}>{total_profit}</span>
          </Tooltip>
        )
      }
    },
    ...(isViewDraftPO ? [{
      headerName: "Total Margin",
      minWidth: 80,
      flex: 1,
      field: "total_margin",
      cellRendererFramework: ({ value }) => {
        return (
          <span>{Math.round(value || 0, 2)}%</span>
        )
      }
    }, {
      headerComponentParams: {
        template: `<div>
        <div>Sale Price Revenue</div>
        <div style="color: green;">Buy Box Revenue</div>
      </div>`},
      minWidth: 120,
      flex: 1,
      field: "total_revenue",
      cellRendererFramework: ({ data }) => {
        const sale_price_revenue = formatAmount(data?.sale_price_revenue);
        const buy_box_revenue = formatAmount(data?.buy_box_revenue);
        return (
          <div>
            <div>{sale_price_revenue}</div>
            <span className="sale-price-text" style={data?.buy_box_revenue >= 0 ? { color: "#009C34" } : { color: "#CF0909" }}>{buy_box_revenue}</span>
          </div>
        )
      }
    }] : []),
    {
      headerName: "SKU's",
      width: isViewDraftPO ? 80 : 73 ,
      field: "skus",
      cellRendererFramework: ({ value }) => {
        return (
          <span>{value}</span>
        )
      }
    },
    {
      headerName: "User",
      minWidth: isViewDraftPO ? 100 : 100,
      flex: 1,
      field: "user",
      cellRendererFramework: ({ value, data }) => {
        if (value) {
          const { name, company_name } = value;
          return (
            <Stack direction="column" gap="2px" className="user-designation">
              <Tooltip title={name || data?.user || 'N/A'}>
                <span>{name || data?.user || 'N/A'}</span>
              </Tooltip>
              <Tooltip title={company_name || data?.company_name || 'N/A'}>
                <span>{company_name || data?.company_name || 'N/A'}</span>
              </Tooltip>
            </Stack>
          )
        } else {
          return (
            <span className="user-designation">N/A</span>
          )
        }
      }
    },
    {
      headerName: "Ship to",
      minWidth: isViewDraftPO ? 200 : 110,
      flex: 1,
      field: "ship_to_address",
      cellRendererFramework: ({ data }) => {
        const { ship_to_address, id, last_checked_in_at, pending_status, po_destination } = data || {};
        const showIcon = !(last_checked_in_at || pending_status === "Completed");
        const handleEditClick = () => {
          setSearchValue('');
          setShowAddressFields(false);
          setAddressDetails(initialAddress);
          setIsEdit(false);
          setAddressId(id);
          setDrawerVisible(true);
          if (currentUser?.company_id) {
            if (isViewDraftPO) {
              setPoDestination(po_destination);
              if (po_destination === 1) dispatch(GetCompanyAddress(currentUser?.company_id));
              else dispatch(GetWarehouseAddress(currentUser?.company_id));
              setSelectedCard(ship_to_address.id);
              setPoId(id);
            } else {
              dispatch(GetCompanyAddress(currentUser?.company_id));
              setSelectedCard(data.company_address_id);
            }
          }
        };
        if (ship_to_address) {
          const { title, address_line_1, city, state_or_province } = ship_to_address;
          return (
            <Stack direction="row" alignItems="flex-start" justifyContent="space-between" gap={0.5}>
              <Stack direction="column" gap="2px" className="ship-to">
                <Tooltip title={title}>
                  <span>{title}</span>
                </Tooltip>
                <Tooltip title={state_or_province}>
                  <span>{address_line_1}, {city}, {state_or_province}</span>
                </Tooltip>
              </Stack>
              {showIcon && (
                <img src={editIcon} alt="edit" className="cursor-pointer" onClick={handleEditClick} />
              )}
            </Stack>
          );
        } else {
          return (
            <Stack direction="row" alignItems="flex-start" gap={0.5}>
              <span className="w-100">N/A</span>
              <img src={editIcon} alt="edit" className="cursor-pointer" onClick={handleEditClick} />
            </Stack>
          );
        }
      }
    },
    ...(!isViewDraftPO ? [{
      headerName: "Credit Amount",
      width: 75,
      field: "po_credit_amount",
      cellRendererFramework: ({ value }) => {
        const credit_amount = value ? formatAmount(value) : '$0.00';
        return (
          <span className="credit-amount">{credit_amount}</span>
        );
      }
    },
    {
      headerName: "Quantity",
      width: 170,
      field: "quantities",
      cellRendererFramework: ({ value }) => {
        const { vendor, warehouse, amazon } = value;
        return (
          <Tabs
            defaultActiveKey={vendor.pending === 0 ? "amazon" : "vendor"}
            id="uncontrolled-tab-example"
            className="quantity-tabs"
          >
            <Tab eventKey="vendor" title="Vendor" className="tab-units">
              {getTabContent({ vendor, warehouse, amazon, discrepancy: vendor.pending })}
            </Tab>
            <Tab eventKey="3pl" title="3PL" className="tab-units">
              {getTabContent({ vendor, warehouse, amazon, discrepancy: 0 })}
            </Tab>
            <Tab eventKey="amazon" title="Amazon" className="tab-units">
              {getTabContent({ vendor, warehouse, amazon, discrepancy: amazon.pending })}
            </Tab>
          </Tabs>
        );
      }
    },
    {
      headerName: "Invoice # &Download",
      minWidth: 100,
      flex: 1,
      className: "invoice-popup",
      cellRendererFramework: ({ data }) => {
        const { po_code, id, order_invoices, vendor_name, user } = data || {};
        const invoiceName = order_invoices?.length ? order_invoices?.map(({ invoice_number }) => invoice_number)?.join(',') : '';
        return (
          <Stack direction="column" gap="2px" className="invoice-download">
            <Tooltip title={invoiceName}>
              <span>{invoiceName || 'N/A'}</span>
            </Tooltip>
            <span onClick={() => {
              setSelectedRowId(id);
              handleInvoiceModal({
              id,
              po_code,
              order_invoices,
              vendor_name,
              userName: user?.name
            })}} className="invoice-download d-flex justify-content-center">download</span>
          </Stack>
        );
      }
    },
    {
      headerName: "Tracking Info",
      minWidth: 100,
      flex: 1,
      cellRendererFramework: ({ data }) => {
        const { tracking_informations, id } = data || {};
        const trackingInfoValues = tracking_informations?.map(tracking => tracking.tracking_info).join(', ');

        if (trackingInfoValues.length) {
          return (
            <Stack direction="column" gap="2px" className="tracking-info">
              <Tooltip title={trackingInfoValues}>
                <span className="tracking-info">{trackingInfoValues}</span>
              </Tooltip>
              <span onClick={() => {
                handleTrackingModal();
                setTrackingInfo({ id, tracking_informations });
                setSelectedRowId(id);
              }}>Show more</span>
            </Stack>
          );
        } else {
          return (
            <Stack direction="column" gap="2px" className="tracking-info">
              <span className="error-badge">MISSING TRACKING #</span>
              <span onClick={() => {
                handleTrackingModal();
                setTrackingInfo({ id, tracking_informations });
                setSelectedRowId(id);
              }}>Add tracking #</span>
            </Stack>
          );
        }
      }
    },
    {
      headerName: "Delivery Due Date",
      width: 110,
      cellRendererFramework: ({ data }) => {
        const { id, pending_status, not_received, tracking_informations, last_checked_in_at, in_warehouse } = data;

       if (!isEmpty(tracking_informations)) {
          const allReceived = tracking_informations.every(info => info.received_at);
          const anyReceived = tracking_informations.some(info => info.received_at);
          const isNullTracking = !isEmpty(tracking_informations.filter(info => info.received_at === null));
          const sortedTrackings = sortBy(tracking_informations, 'received_at');
          
          if (allReceived && last_checked_in_at > sortedTrackings[0].received_at) {
            return (
              <Stack direction="column" gap="2px" alignItems="center" className="delivery-date">
                <span className="delivery-badge received-badge">Received</span>
                <span className="fs-10">{getFormattedDate(last_checked_in_at)}</span>
              </Stack>
            );
          } else if ((isNullTracking || !allReceived) && last_checked_in_at) {
            return (
              <Stack direction="column" gap="2px" alignItems="center" className="delivery-date">
                <span className="delivery-badge partially-recieved">Partially Received</span>
              </Stack>
            );
          } else if (in_warehouse) {
            return (
              <Stack direction="column" gap="2px" alignItems="center" className="delivery-date">
                <span className="delivery-badge scanned-in-badge">Scanned In</span>
              </Stack>
            );
            
          } else if (not_received) {
            return (
              <Stack direction="column" gap="2px" alignItems="center" className="delivery-date">
                <span className="error-badge">NOT <br /> RECEIVED</span>
              </Stack>
            );
          } else {
            const sortedTrackings = sortBy(tracking_informations, 'delivery_date').reverse();
            const today = moment();
            const deliveryDate = moment(sortedTrackings[0]?.delivery_date);
            const diffInDays = deliveryDate.diff(today, 'days');
            const isPastDue = diffInDays < 0;
            const displayText = isPastDue ? <span>Overdue <br /> {Math.abs(diffInDays)} Days </span> : diffInDays === 0 ? 'Today' :`In ${diffInDays} Days`;
            return (
              <Stack direction="column" gap="2px" alignItems="center" className="delivery-date">
                <span className={`delivery-badge ${diffInDays < 0 ? 'overdue-badge' : diffInDays === 0 ? 'today' : 'info-badge'} fs-12`}>{displayText}</span>
                <span className="fs-10">{sortedTrackings[0]?.delivery_date}</span>
                <span className="show-more"
                  onClick={() => {
                    handleTrackingModal();
                    setTrackingInfo({ id, tracking_informations });
                    setSelectedRowId(id);
                  }}>Show more</span>
              </Stack>
            );
          }
        } else {
          if (in_warehouse){
            return (
              <Stack direction="column" gap="2px" alignItems="center" className="delivery-date">
                <span className="delivery-badge scanned-in-badge">Scanned In</span>
              </Stack>
            );
          }
           else {
            return (
              <Stack direction="column" gap="2px" alignItems="center" className="delivery-date">
                <span className="error-badge">MISSING DUE DATE</span>
                <span className="show-more"
                  onClick={() => {
                    handleTrackingModal();
                    setTrackingInfo({ id, tracking_informations });
                    setSelectedRowId(id);
                  }}>Add Date</span>
              </Stack>
            );
            }
          
        }
      }
    }] : [{
      headerName: "Actions",
      width: 230,
      cellClass: 'px-0',

      cellRendererFramework: ({ data }) => {
        return (
          <div className="buttons-wrapper">
            <Button
              text="Delete Draft"
              className="delete-button"
              onClick={() => {
                setPoIdToDelete(data?.id);
              }}
            />
            <Button
              text="View"
              className="view-button"
              onClick={() => navigate(`/po-detail?id=${data?.id}`)}
            />
          </div>
        );
      }
    }])
  ];

  const getFormattedDate = (date) => date ? moment(date).format("DD MMM YYYY") : "N/A";

   const columnPO = [
    {
      headerName: "",
      field: "",
      width: 50,
      cellStyle: { display: "flex", 'justify-content': 'center', 'align-items': 'center' },
      cellRendererFramework: ({ data }) => {
        return (
          <RadioCheck
            checked={selectedPo === data.id}
            onChange={() => setSelectedPo(data.id)}
          />
        )
      }
    },
    {
      headerName: "Matching PO's",
      width: 140,
      cellRendererFramework: ({ data }) => {
        const { po_code, id } = data;
        const href = `/po/my-orders/${id}`;
        return (
          <Box color="#1565D8">
            <a target="_ blank" href={href} >
              <Tooltip title={po_code}>
                <span className="text-ellipse">{po_code || 'N/A'}</span>
              </Tooltip>
            </a>
          </Box>
        );
      }
    },
    {
      headerName: "Vendor",
      width: 120,
      cellRendererFramework: ({ data }) => {
        const { name } = data?.vendor;
        return (
          <Tooltip title={name}>
            <span className="inner-text">{name || 'N/A'}</span>
          </Tooltip>
        );
      }
    },
    {
      headerName: "User",
      width: 120,
      cellRendererFramework: ({ data }) => {
        const { name } = data?.user;
        return (
          <Tooltip title={name}>
            <span className="inner-text">{name || 'N/A'}</span>
          </Tooltip>
        );
      }
    }
  ];

  const drawerColumnDefinations = [
    {
      headerName: 'Tracking Number/ Invoice #',
      field: "tracking_number",
      width: 160,
      cellRendererFramework: ({ value }) => {
        return (
          <Tooltip title={value}>
            <span className="vendor-name">{value || 'N/A'}</span>
          </Tooltip>
        );
      }
    },
     {
      headerName: "Matching PO's",
      width: 160,
      cellRendererFramework: ({data}) => {
        const { attached_po_codes } = data;
        return (
          !attached_po_codes ? 
          <Box  color="#1565D8" sx={{cursor:'pointer', textDecoration:"underline"}} onClick={()=>{
            setConfirmPo(true);
            setTracking(data);
            }}>
           <img width="16px" height="16px" src={infoIcon} alt="no-icon" /> Add Matching PO
          </Box> :
          <span className="matching-po">{attached_po_codes || 'N/A'}</span>
        );
      }
    },
    {
      headerName: "QTY",
      field: "quantity",
      width: 90,
      cellRendererFramework: ({ data }) => {
        return (
          <Tooltip title={data?.quantity}>
            <Box display="flex" flexDirection="column">
              <Box component="span" fontSize="12px" color="#4E5969"className="vendor-name" >{data?.quantity}</Box>
              <Box component="span" fontSize="8px" color="#4E5969">{data?.box_type}</Box>
            </Box>
          </Tooltip>
        );
      }
    },
    {
      headerName: "Date",
      field: "received_at",
      width: 80,
      cellRendererFramework: ({ value }) => {
        return (
          <Tooltip title={getFormattedDate(value)}>
            <span className="inner-text">{getFormattedDate(value)}</span>
          </Tooltip>
        );
      }
    },
    {
      headerName: "Vendor",
      field: "vendor",
      width: 90,
      cellRendererFramework: ({ value }) => {
        return (
          <Tooltip title={value}>
            <span className="inner-text">{value || 'N/A'}</span>
          </Tooltip>
        );
      }
    },
    {
      headerName: "User",
      field: "user",
      width: 90,
      cellRendererFramework: ({ value }) => {
        return (
          <Tooltip title={value}>
            <span className="inner-text">{value || 'N/A'}</span>
          </Tooltip>
        );
      }
    }
  ];

  const gridOptions = {
    rowSelection: 'multiple',
  };

  const onGridReady = (params) => {
    gridApi = params.api;
  };

  const handleReconExcel = () => {
    const ids = selectedRows.map(({ id }) => id);

    dispatch(getReconExcel({ ids })).then(({ payload }) => {
      const { file_url } = payload || {};
      if (!file_url) return;

      const anchor = document.createElement('a');
      anchor.href = file_url;
      anchor.setAttribute('download', `RECON._EXCEL_${Date.now()}.pdf`);
      anchor.click();
      gridApi.deselectAll();
      setSelectedRows([]);
    });
  }

  const handleAddToStorage = () => {
    dispatch(GetAllLocations())
      .then(({ payload }) => {
        const { success, locations } = payload?.data || {};
        if (success) {
          setLocationsList(locations);
        }
      });
    dispatch(GetStorageUsersList())
      .then(({ payload }) => {
        if (payload?.data?.length) {
          setUsersList(payload?.data);
        }
      });
    setStorage(true);
  }

  const handleRangeChange = (dates) => {
    setSelectedRange(dates);
    setOpenDatePicker(false);
  }

  const GetMatchingPos = (matchingPos) => {
    const matchingPoList = matchingPos?.map(item => ({
      ...item,
     selectedPo
    }));
    return matchingPoList || [];
  }


  useEffect(() => {
    if(confirmPo) 
    dispatch(setFbaInboundState({ field: 'matchingPos', value: [] }));
  }, [confirmPo]);

  useEffect(() => {
    const filteredData = received_trackings?.filter(({ tracking_number, received_at, user }) => {
      if (selectedRange?.length === 2 && search) {
        const dateToCompare = moment(received_at);
        return compareDates(selectedRange, dateToCompare) && (
          tracking_number?.toLowerCase()?.includes(search?.toLowerCase()) ||
          user?.toLowerCase()?.includes(search?.toLowerCase())
        );
      } else if (selectedRange?.length === 2 && !search) {
        const dateToCompare = moment(received_at);
        return compareDates(selectedRange, dateToCompare)
      } else if (search && selectedRange?.length !== 2) {
        return (
          tracking_number?.toLowerCase()?.includes(search?.toLowerCase()) ||
          user?.toLowerCase()?.includes(search?.toLowerCase())
        );
      }
      else return true;
    });
    setRecievedTrackings(filteredData);
  }, [selectedRange, search]);

  useEffect(() => {
    if (singlePoList && PoList) {
      gridApi.forEachNode(rowNode => {
        if (rowNode.data.id === singlePoList.id) {
          const updatedData = singlePoList;
          rowNode.setData(updatedData);
        }
      });
    }
  }, [singlePoList]);

  useEffect(() => {
    if (confirmSuccess) {
      setCongratulationModal(true);
      dispatch(setFbaShipmentState({ field: 'confirmSuccess', value: false }));
      dispatch(setFbaShipmentState({ field: 'poData', value: [] }));
      dispatch(setFbaShipmentState({ field: 'topStats', value: [] }));
    }
  }, [confirmSuccess]);

  useEffect(() => {
    const newStatues = statuses?.map((status) => {
      const title = Object.keys(status)[0];
      const key = mapTitle(title);
      const value = status[title];
      const daysDifference = Math.abs(moment().diff(moment(status.last_seen), 'days'));
      return {
        value,
        title,
        key,
        daysDifference
      };
    });

    if (statuses && newStatues) setViewDraftsData(newStatues);
  }, [statuses]);

  useEffect(() => {
    dispatch(GetAllPos({ ...allPoPagination }));
    dispatch(GetRecievedTrackings());
    dispatch(GetVendors());
    dispatch(GetStatusCount());
    setIsViewDraftPO(location.state?.isViewDraftPO);
    navigate(location.pathname, { replace: true });
    dispatch(GetUsers());
  }, []);

  useEffect(() => {
    setRecievedTrackings(received_trackings);
    if (received_trackings?.length) {
      const sortedTrackings = sortBy(received_trackings, ['received_at']).reverse();
      setLatestTracking(sortedTrackings[0]);
      const trackingsWithoutPO = sortedTrackings.filter(tracking => !tracking.attached_po_codes);
      if (trackingsWithoutPO) {
        setTrackingWithoutPo(trackingsWithoutPO[0]);
      }
    }
  }, [received_trackings]);

  useEffect(() => {
    if (isViewDraftPO) {
      dispatch(GetDraftPOs(draftPoFilter));
      setSelectedRadio('all');
    }
  }, [isViewDraftPO, draftPoFilter]);

  useEffect(() => {
    if (isViewDraftPO && poDestination === 1) setFilteredAddresses(companyAddresses);
    else if (isViewDraftPO && poDestination === 2) setFilteredAddresses(warehouseAddresses);
    else if (!isViewDraftPO && companyAddresses) setFilteredAddresses(companyAddresses);
  }, [companyAddresses, warehouseAddresses, poDestination]);

  return (
    <PurchaseOrderWrapper>
      <div className="purchase-heading">
        <h1>{!isViewDraftPO ? 'Purchase Order' : "Drafts PO's"}</h1>
        {trackingWithoutPo ? (
          <div className="scanned-pkg">
            <img src={InfoRedIcon} alt="no-info-icon" />
            <p>Received with <br />NO MATCHING PO</p>
            <div className="scanned-content">
              <span className="scanned-number">{trackingWithoutPo?.tracking_number || 'N/A'}</span>
              <div>
                <span>{getFormattedDate(trackingWithoutPo?.received_at) || 'N/A'}</span>
                <span>{trackingWithoutPo?.user || 'N/A'}</span>
              </div>
            </div>
            <Button text='Show More' className='outlined' onClick={handleSecondDrawer} />
          </div>
        ) : (
          <div className="scanned-pkg po-code">
            <p>Recently Scanned Packages</p>
            <div className="scanned-content">
              <span className="scanned-number">{latestTracking?.tracking_number || 'N/A'}</span>
              <div>
                <span>{getFormattedDate(latestTracking?.received_at) || 'N/A'}</span>
                <span>{latestTracking?.user || 'N/A'}</span>
              </div>
            </div>
            <Button text='Show More' className='outlined' onClick={handleSecondDrawer} />
          </div>
        )}
      </div>
      <div className="po-header-bar">
        <div className="po-header-left">
          <Button
            text="Create PO"
            onClick={() => setOpen(true)}
          />
          <Button
            text="Add to Storage"
            className="outlined"
            onClick={handleAddToStorage}
          />
          <div className="input-search">
            <SearchInput
              className="header-input-field"
              value={searchInput}
              placeholder={`Search by Invoice#,PO#,${isViewDraftPO ? '' : 'Order ID,'}Tracking`}
              onChange={handleInputChange}
            />
          </div>
          <div className="po-select ">
            <Select
              placeholder="Search by Vendor"
              options={statusOptions}
              isClearable={true}
              value={vendorSearch ? { value: vendorSearch?.value, label: vendorSearch?.label } : null}
              onChange={handleChangeVendor}
            />
          </div>
          {(currentUser?.id === 5 || currentUser?.email === "steven@wraithco.com") && (
            <div className="po-select ">
              <Select
                placeholder="Search by User"
                options={userOptions}
                isClearable={true}
                value={userSearch ? { value: userSearch?.value, label: userSearch?.label } : null}
                onChange={handleChangeUser}
              />
            </div>
          )}
          <Tooltip title={!selectedRows || !selectedRows.length || loading ? 'Please Select POs for Recon. Excel' : ''}>
            <span>
              <Button
                text="Recon. Excel"
                disabled={!selectedRows || !selectedRows.length || loading}
                onClick={handleReconExcel}
              />
            </span>
          </Tooltip>
        </div>
        {/* <div className="po-header-right">
          {Orders.map((tiles) => (
            <div className={`orders d-flex align-items-center fbm-order ${tiles.key}`}>
              <div className="d-flex justify-content-between align-items-center order-inner-text-wrapper">
                <Tooltip title={tiles?.value}>
                  <h1 className="mb-0 order-inner-value">{tiles?.value}</h1>
                </Tooltip>
              </div>
              <span className="fs-12 color-secondary">{tiles.title}</span>
            </div>
          ))} */}
        {/* <Button text="View More" className="outlined" /> */}
        {/* </div> */}
      </div>
      <Spin tip='Loading...' spinning={getStatusLoading}>
        <div className="view-drafts">
          <div onClick={() => {
            setDraftPoFilter({
              ...draftPoFilter,
              search: null,
              vendor_id: null
            });
            setSearchInput('')
            setVendorSearch('')
            setFilters([])
            setIsViewDraftPO(!isViewDraftPO);
            if (isViewDraftPO) dispatch(GetAllPos({ ...allPoPagination }));
          }} className="drafts-box">
            {isViewDraftPO ? 'Back to Orders' : 'View Drafts'}
          </div>
          {viewDraftsData?.map((item, index) => (
            <div className={`curve ${item.key}`} key={index} onClick={() => {
              handleRadioChange(item.key)
              if (item.title !== 'All') {
                dispatch(UpdateLastSeen({ status: item.title })).then(({ payload }) => {
                  if (payload?.data?.success) dispatch(GetStatusCount());
                });
              }
            }}
            >
              <span className="info-icon">
                <span className="submitted-radio">
                  <Radio
                    checked={selectedRadio === item.key}
                    value={item.key}
                    name="submitted"
                    onChange={() => {
                      handleRadioChange(item.key);
                      if (item.title !== 'All') {
                        dispatch(UpdateLastSeen({ status: item.title })).then(({ payload }) => {
                          if (payload?.data?.success) dispatch(GetStatusCount());
                        });
                      }
                    }}
                  />
                </span>
                <p>{item.value}</p>
                {(item.daysDifference >= 3 && item.title !== 'All') && (
                  <Popover
                    placement="bottom"
                    trigger="hover"
                    content={
                      <PurchaseOrderWrapper>
                        <div className="tooltip-wrapper">
                          <Row className="gx-0 gy-3">
                            <Col xs={6}><span className="heading">Status name:</span></Col>
                            <Col xs={6}><span className="status">{item.title}</span></Col>
                            <Col xs={6}><span className="heading">Total items attached to:</span></Col>
                            <Col xs={6}><span>{item.value}</span></Col>
                            <Col xs={6}><span className="heading">Last time seen:</span></Col>
                            <Col xs={6}><span>{item.daysDifference} days ago</span></Col>
                            {/* <Col xs={6}><span className="heading">Alert:</span></Col> */}
                            {/* <Col xs={6}><span>Alert Text</span></Col> */}
                          </Row>
                        </div>
                      </PurchaseOrderWrapper>
                    }
                  >
                    <img src={infoIcon} alt="info" />
                  </Popover>
                )}
              </span>
              {item.title.length > 16 ? (
                <Tooltip title={item.title}>
                  <p>
                    {item.title}
                  </p>
                </Tooltip>
              ) : (
                <p>
                  {item.title}
                </p>
              )}
            </div>
          ))}
        </div>
      </Spin>
      <Spin tip='Loading...' spinning={loading}>
        <AgGrid
          gridOptions={gridOptions}
          gridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefinitions={columnDefinitions}
          user_referrals={isViewDraftPO ? draftPosList : PoList}
          height="415px"
          rowHeight={75}
          className="purchase-order-grid"
          onSelectionChanged={handleRowSelected}
        />
        <Pagination
          total={isViewDraftPO ? draftPOsCount : poOrdersCount}
          totalPages={ceil((isViewDraftPO ? draftPOsCount : poOrdersCount) / (isViewDraftPO ? draftPoFilter?.per_page : allPoPagination?.per_page) + 1)}
          pageNumber={isViewDraftPO ? draftPoFilter?.page_num : allPoPagination?.page_num}
          pageLimit={isViewDraftPO ? draftPoFilter?.per_page : allPoPagination?.per_page}
          handleLimitChange={(e) => handlePagination(e, "pageLimit")}
          handlePageNumberChange={(e) => handlePagination(e, "pageNumber")}
          handlePageChangeLeft={(e) => handlePagination(e, "pageNumber")}
          handlePageChangeRight={(e) => handlePagination(e, "pageNumber")}
          refreshGridClick={refreshGridClick}
        />
      </Spin>
      <Modal
        width={780}
        visible={open}
        centered
        className="stepper-modal-ui"
        footer={null}
        keyboard={false}
        onCancel={() => setOpen(false)}
      >
        <Steppers page="po" setOpen={setOpen} open={open} />
      </Modal>

      <Modal
        width={446}
        visible={congratulationModal}
        centered
        className="stepper-modal-ui congratulation-modal"
        footer={null}
        keyboard={false}
        closable={true}
        onCancel={() => setCongratulationModal(false)}
      >
        <Congratulation />
      </Modal>
      <Modal
        width={728}
        visible={trackingModalVisible}
        centered
        footer={null}
        keyboard={false}
        closable={null}
        closeIcon={null}
      >
        <DeliveryInfo 
        setOpenModal={handleTrackingModal} 
        poData={trackingInfo}
         onSuccess={handleSuccess} 
         setSelectedRowId={setSelectedRowId}
         />
      </Modal>

      <Modal
        width={610}
        centered
        footer={null}
        keyboard={false}
        visible={invoiceModalVisible}
        closable={null}
        closeIcon={null}
        className="invoice-popup"
      >
        <InvoicePopup
          setInvoiceModalVisible={setInvoiceModalVisible}
          onSuccess={handleSuccess}
          invoiceInfo={invoiceInfo}
          setSelectedRowId={setSelectedRowId}
        />
      </Modal>

      <Drawer
        className="shipment-drawer-wrapper"
        ref={formRef}
        title={
          <div className="drawer-header">
            <BsArrowLeftShort onClick={() => setDrawerVisible(false)} />
            <p>Ship To</p>
          </div>
        }
        placement="right"
        width={406}
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
      >
        <PurchaseOrderWrapper className="shipment-inputs-wrapper">
          <div className="shipment-drawer-input-wrapper">
            <div>
              {(showAddressFields || isEdit) && (
                <Spin tip="Loading..." spinning={fbaLoading} >
                  <Row>
                    <Col xs={12}>
                      <label>Name</label>
                      <Input
                        className="shipment-drawer-input"
                        value={addressDetails?.title}
                        onChange={(e) => setAddressDetails({ ...addressDetails, title: e.target.value })}
                      />
                    </Col>
                    <Col xs={12}>
                      <label>Street 1</label>
                      <Input
                        className="shipment-drawer-input"
                        value={addressDetails?.address_line_1}
                        onChange={(e) => setAddressDetails({ ...addressDetails, address_line_1: e.target.value })}
                      />
                    </Col>
                    <Col xs={12}>
                      <label>Street 2</label>
                      <Input
                        className="shipment-drawer-input"
                        value={addressDetails?.address_line_2}
                        onChange={(e) => setAddressDetails({ ...addressDetails, address_line_2: e.target.value })}
                      />
                    </Col>
                    <Col xs={6}>
                      <label>City</label>
                      <Input
                        className="shipment-drawer-input"
                        value={addressDetails?.city}
                        onChange={(e) => setAddressDetails({ ...addressDetails, city: e.target.value })}
                      />
                    </Col>
                    <Col xs={6}>
                      <label>State</label>
                      {
                        addressDetails?.country === 'US' ? <SelectField
                          className="select-antd-wrapper"
                          placeholder='Select State'
                          showSearch
                          maxTagCount={1}
                          maxTagTextLength={6}
                          allowClear
                          style={{ width: '100%' }}
                          value={addressDetails?.state_or_province}
                          onChange={(value) => {
                            setAddressDetails({
                              ...addressDetails,
                              state_or_province: value
                            })
                          }}
                        >
                          {statesOptions}
                        </SelectField> : <Input
                          className="shipment-drawer-input"
                          value={addressDetails?.state_or_province}
                          onChange={(e) => setAddressDetails({ ...addressDetails, state_or_province: e.target.value })}
                        />
                      }
                    </Col>
                    <Col xs={6}>
                      <label>Country</label>
                      <SelectField
                        className="select-antd-wrapper"
                        showSearch
                        maxTagCount={1}
                        maxTagTextLength={6}
                        allowClear
                        style={{ width: '100%' }}
                        value={addressDetails?.country}
                        filterOption={(input, option) => {
                          return option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                        }}
                        placeholder='Select Country'
                        onChange={(value) => {
                          setAddressDetails({
                            ...addressDetails,
                            country: value,
                            state_or_province: ''
                          });
                        }}
                      >
                        {countryList}
                      </SelectField>
                    </Col>
                    <Col xs={6}>
                      <label>Zip Code</label>
                      <Input
                        className="shipment-drawer-input"
                        value={addressDetails?.zipcode}
                        onChange={(e) => {
                          if (!isNaN(e.target.value) || Number(e.target.value) < 0)
                            setAddressDetails({ ...addressDetails, zipcode: Number(e.target.value) })
                        }}
                      />
                    </Col>
                  </Row>
                </Spin>
              )}
            </div>
            <div className="text-center mt-3 shipment-drawer-button">
              <Button
                text={isEdit ? "Update Address" : "Add New Address"}
                disabled={
                  (isEdit || showAddressFields) &&
                  (!addressDetails?.title || !addressDetails?.zipcode || !addressDetails?.city || !addressDetails?.country ||
                    !addressDetails?.state_or_province || !addressDetails?.address_line_1)}
                onClick={(e) => {
                  handleAddAddressClick();
                }}
              // className={showAddressFields || isEdit ? "" : "outlined"}

              />
            </div>
          </div>
          <Spin tip='Loading...' spinning={fbaLoading}>
            <div className="shipment-drawer-inner-wrapper">
              <h4 className="shipment-drawer-inner-heading">Recent Addresses</h4>
              <div className="shipment-drawer-input-wrapper">
                <SearchInput
                  value={searchValue}
                  className="shipment-drawer-input"
                  placeholder="Search by Address, Company Name"
                  onChange={handleSearchChange}
                />
              </div>
              <div className="card-overlay-wrapper">
                {filteredAddresses?.map((address) => (
                  <div
                    className={`shipment-address-card ${address.id === selectedCard ? 'selected' : ''}`}
                    key={address.id}
                    onClick={() => handleCardClick(address.id)}
                  >
                    <div className="text-wrapper">
                      <p>{address.title}</p>
                      <p>{address.address_line_1}</p>
                      <p>{address.city}, {address.zipcode}</p>
                    </div>
                    {address.type === 'badge' ? (
                      <div className="badge">
                        {address.title} <img src={BadgeImg} alt={`${address.title}-badge`} />
                      </div>
                    ) : (
                      address?.owner_address && address?.addressable_id !== currentUser?.company_id ? <div className="bags-grow-network-wrapper">
                        <p>Sales.Support</p>
                        <img src={GrowIcon} alt="GrowIcon" />
                      </div> : <button className="address-edit-btn" onClick={() => {
                        handleEditAddressClick(address)
                        setIsEdit(true);
                        setShowAddressFields(false);
                      }}>Edit</button>
                    )}
                  </div>
                ))}
              </div>

              {selectedCard !== null && (
                <div className="text-center mt-3 shipment-drawer-button">
                  <Button text="Confirm Address"
                    onClick={handleConfirmAddress}
                  />
                </div>
              )}
            </div>
          </Spin>
        </PurchaseOrderWrapper>
      </Drawer>
      <Drawer
        placement="right" // Set placement as needed
        width={709}
        className="recieved-shipment-drawer-wrapper shipment-drawer-wrapper"
        title={
          <div className="drawer-header">
            <BsArrowLeftShort onClick={() => {
              setSecondDrawerVisible(false)
              setSelectedRange([])
              setOpenDatePicker(false)
              setSearch('')
            }} />
            <p>Previously Scanned Packages</p>
          </div>
        }
        onClose={() => setSecondDrawerVisible(false)}
        visible={secondDrawerVisible}
      >
        <RecievedShipmentWrapper className="recieved-shipment-wrapper">
          <Box my={1} display="flex" justifyContent="space-between">
            <SearchInput
              placeholder="Search by Invoice#, Tracking Id"
              value={search}
              style={{ width: '450px' }}
              onChange={({ target }) => {
                setSearch(target.value)
              }}
            />
            <Box display='flex' alignItems='center'>
              <div>
                <Box
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenDatePicker(!openDatePicker)
                  }}
                  style={{ cursor: 'pointer' }}
                  sx={{
                    border: '1px solid #1565D8',
                    borderRadius: '8px',
                    padding: '5px 16px',
                  }}
                >
                  {selectedRange?.length === 2 ?
                    `${selectedRange[0].format('MMM DD, YYYY')} - ${selectedRange[1].format('MMM DD, YYYY')}`
                    : 'Select Date'}
                  {selectedRange?.length === 2 ?
                    <span
                      style={{ marginLeft: '5px', color: 'rgba(21, 101, 216, 1)' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setOpenDatePicker(false);
                        setSelectedRange([]);
                        handleRangeChange([]);
                      }}>X</span> :
                    <img   style={{
                      marginLeft: '8px',
                      height: '15px',
                      filter: `invert(47%) sepia(81%) saturate(7492%) hue-rotate(210deg) brightness(92%) contrast(84%)`
                    }} src={calenderIcon} alt="Dropdown" />}
                </Box>
                <div ref={dateRangePickerRef} onClick={(e) => e.stopPropagation()}>
                  <RangePicker
                    open={openDatePicker}
                    disabledDate={disabledDate}
                    style={{ visibility: 'hidden', width: '0px' }}
                    onChange={handleRangeChange}
                    value={selectedRange}
                    renderExtraFooter={renderExtraFooter}
                  />
                </div>
              </div>
            </Box>
          </Box>
          <Spin tip='Loading...' spinning={recievedTrackingLoading}>
            <AgGrid
              defaultColDef={defaultColDef}
              columnDefinitions={drawerColumnDefinations}
              user_referrals={recievedTrackings}
              height="268px"
              getRowStyle={(params) => {
                  if(!(params.data.attached_po_codes)){
                    return {
                        background: '#CF09090D'
                      }
                  }
                  }}
              rowHeight={34}
            />
          </Spin>
        </RecievedShipmentWrapper>
      </Drawer>
      <Modal
        width={380}
        bodyStyle={{ minHeight: 50 }}
        visible={poIdToDelete ? true : false}
        centered
        onOk={() => {
          if (poIdToDelete) dispatch(DeleteDraftPOs(poIdToDelete)).then(() => {
            dispatch(GetDraftPOs(draftPoFilter));
          });
          setPoIdToDelete("");
        }}
        okText="Delete"
        cancelText="Cancel"
        onCancel={() => setPoIdToDelete("")}
      >
        <p style={{
          fontWeight: 'bold'
        }}>Are you sure you want to delete items</p>
      </Modal>
      <Drawer visible={storage} width="480px" closable={false}>
        <Box sx={{ height: 'calc(100vh - 50px)' }} overflow="auto">
          <Box className="drawer-header" sx={{ paddingBottom: '0px' }} display="flex" alignItems="center" gap="8px">
            <IconButton onClick={() => setStorage(false)}>
              <ArrowBackIcon sx={{ color: '#1565D8' }} />
            </IconButton>
            <h3 className="mb-0">Add Product to Internal Storage</h3>
          </Box>
          <Storage
            userList={userList}
            setLocationsList={setLocationsList}
            locationsList={locationsList}
            setShowStorage={setStorage}
            visible={storage}
          />
        </Box>
      </Drawer>
      <CustomModal open={confirmPo} >
        <Box display="flex" gap={1}>
          <img src={BackArrow} alt="back-arrow" className="cursor-pointer" onClick={() => {
            setConfirmPo(false);
            setTracking();
            setSelectedPo(null);
          }} />
          <Box fontSize="24px" color="#000000" fontWeight="600">Add Matching PO</Box>
        </Box>
        <Box display="flex" gap={0.5} mt={1}>
          <Box fontWeight="600" fontSize="16x" color="#979797" >
            Tracking Number/Invoice #:
          </Box>
          <Box fontWeight="600" fontSize="16px" color="#000000">
            {tracking?.tracking_number}
          </Box>
        </Box>
        <Box display="flex" gap={0.5} mt={1}>
          <Box fontWeight="600" fontSize="14px" color="#979797" >
            Vendor:
          </Box>
          <Box fontWeight="600" fontSize="14px" color="#000000">
            {tracking?.vendor || 'N/A'}
          </Box>
        </Box>
        <Box mt={1} border="1px solid #E8F0FF" bgcolor="#E8F0FF" borderRadius="8px" padding="20px 10px 20px 10px">
          <Box>
            <SearchInput
              className="drawer-search-input"
              placeholder="Search by PO#, ASIN, SKU"
              onChange={({ target }) => {
                handlePoFilter(target.value);
              }}
            />
            <MatchingPoWrapper className="matching-po">
              <Spin tip='Loading...' spinning={fetchingMatchingPos || fetchingAttachedPos}>
                <AgGrid
                  defaultColDef={defaultColDef}
                  columnDefinitions={columnPO}
                  user_referrals={GetMatchingPos(matchingPos)}
                  height="600px"
                  rowHeight={38}
                  getRowStyle={(params) => {
                    if ((params?.data?.id === params?.data?.selectedPo)) {
                      return {
                        background: '#B3E3FF'
                      }
                    }
                  }}
                />
              </Spin>
            </MatchingPoWrapper>
            <Box mt={1}>
              <Button
                text="Add Matching PO"
                disabled={!selectedPo}
                className="white-button w-100"
                onClick={() => {
                  dispatch(AttachMatchingPo({ id: tracking?.id, pending_po_index_id: selectedPo })).then((data) => {
                    if (data?.payload?.success) {
                      setConfirmPo(false);
                      setTracking();
                      dispatch(GetRecievedTrackings());
                      setSelectedPo(null);
                    }
                  });
                }}
                
              />
            </Box>
          </Box>
        </Box>
      </CustomModal>
    </PurchaseOrderWrapper>
  );
};

export default PurchaseOrder;
