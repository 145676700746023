
export const countryOptions = [
  { value: 'US', label: 'US' , name: 'US' },
  { value: 'CA', label: 'CA' , name: 'CA' },
]

const states = [
  { value: 'AL', label: 'Alabama' , name: 'Alabama' },
  { value: 'AK', label: 'Alaska' , name: 'Alaska' },
  { value: 'AZ', label: 'Arizona' , name: 'Arizona' },
  { value: 'AR', label: 'Arkansas' , name: 'Arkansas' },
  { value: 'CA', label: 'California' , name: 'California' },
  { value: 'CO', label: 'Colorado' , name: 'Colorado' },
  { value: 'CT', label: 'Connecticut' , name: 'Connecticut' },
  { value: 'DC', label: 'District Of Columbia' , name: 'District Of Columbia' },
  { value: 'DE', label: 'Delaware' , name: 'Delaware' },
  { value: 'FL', label: 'Florida' , name: 'Florida' },
  { value: 'GA', label: 'Georgia' , name: 'Georgia' },
  { value: 'HI', label: 'Hawaii' , name: 'Hawaii' },
  { value: 'ID', label: 'Idaho' , name: 'Idaho' },
  { value: 'IL', label: 'Illinois' , name: 'Illinois' },
  { value: 'IN', label: 'Indiana' , name: 'Indiana' },
  { value: 'IA', label: 'Iowa' , name: 'Iowa' },
  { value: 'KS', label: 'Kansas' , name: 'Kansas' },
  { value: 'KY', label: 'Kentucky' , name: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' , name: 'Louisiana' },
  { value: 'ME', label: 'Maine' , name: 'Maine' },
  { value: 'MD', label: 'Maryland' , name: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' , name: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' , name: 'Michigan' },
  { value: 'MN', label: 'Minnesota' , name: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' , name: 'Mississippi' },
  { value: 'MO', label: 'Missouri' , name: 'Missouri' },
  { value: 'MT', label: 'Montana' , name: 'Montana' },
  { value: 'NE', label: 'Nebraska' , name: 'Nebraska' },
  { value: 'NV', label: 'Nevada' , name: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' , name: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' , name: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' , name: 'New Mexico' },
  { value: 'NY', label: 'New York' , name: 'New York' },
  { value: 'NC', label: 'North Carolina' , name: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' , name: 'North Dakota' },
  { value: 'OH', label: 'Ohio' , name: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' , name: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' , name: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' , name: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' , name: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' , name: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' , name: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' , name: 'Tennessee' },
  { value: 'TX', label: 'Texas' , name: 'Texas' },
  { value: 'UT', label: 'Utah' , name: 'Utah' },
  { value: 'VT', label: 'Vermont' , name: 'Vermont' },
  { value: 'VA', label: 'Virginia' , name: 'Virginia' },
  { value: 'WA', label: 'Washington' , name: 'Washington' },
  { value: 'WV', label: 'West Virginia' , name: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' , name: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming'  , name: 'Wyoming' }
];

export default states;
