import Styled from 'styled-components';

const ToggleButtonWrapper = Styled.div`
    .button-group{  
      display:flex;
      flex-direction:column;
      .btn-group{
        border: 1px solid ${({ theme }) => theme['primary-color']};
        border-radius: 8px;
      }
      label{
        font-size: ${({ theme }) => theme['base-font-size-small']};
      }
      .btn-outline-primary{
        border-color:${({ theme }) => theme['primary-color']};
        &:focus{
          box-shadow:none
        }
      }
    }

  `;
export { ToggleButtonWrapper };

