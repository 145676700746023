import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  fetchingForFileUploading: false,
  total_records: 0,
  filters: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  uploaded_file_id: -1,
  files: [],
  result: {},
  vendors: [],
  amazonExtractedValues: [],
  walmartExtractedValues: [],
  user: {},
  checkedAmazonFields: [],
  checkedWalmartFields: [],
  savingUserSetting: false

};

const catalogJobs = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'SET_AMAZON_EXTRATCED_VALUES':
    case 'SET_WALMART_EXTRATCED_VALUES':
    case 'SET_EXTRATCED_VALUES': {
      return {
        ...state,
        ...action.payload
      }
    }
    case 'SAVE_USER_SETTING_REQUETS': {
      return {
        ...state,
        savingUserSetting: true
      }
    }
    case 'ADD_VENDOR_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'ADD_VENDOR_SUCCESS': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'ADD_VENDOR_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'RETRY_FILE_PROCESSING_REQUEST':
    case 'GET_USER_SETTINGS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'RETRY_FILE_PROCESSING_SUCCESS':
    case 'GET_USER_SETTINGS_SUCCESS':
    case 'GET_USER_SETTINGS_FAILED':
    case 'SAVE_USER_SETTING_FAILED':
    case 'SAVE_USER_SETTING_SUCCESS': {
      return {
        ...state,
        fetching: false,
        savingUserSetting: false,
        ...action.payload
      };
    }
    case 'RETRY_FILE_PROCESSING_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_UPLOADED_FILES_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_UPLOADED_FILES_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      };
    }
    case 'GET_UPLOADED_FILES_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SAVE_FILENAME_AND_VENDORID_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'SAVE_FILENAME_AND_VENDORID_SUCCESS': {
      return {
        ...state,
        fetching: false,
        uploaded_file_id: -1
      };
    }
    case 'SAVE_FILENAME_AND_VENDORID_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'IMPORT_PRODUCTS_REQUEST': {
      return {
        ...state,
        fetchingForFileUploading: true
      };
    }
    case 'IMPORT_PRODUCTS_SUCCESS': {
      return {
        ...state,
        fetchingForFileUploading: false,
        ...action.payload,
        result: { status: true, message: 'File imported successfully!!!' }
      };
    }
    case 'IMPORT_PRODUCTS_FAILED': {
      return {
        ...state,
        fetchingForFileUploading: false,
        result: { status: false, message: 'upload custom logic failed' }
      };
    }
    case 'GET_VENDOR_NAMES_REQUEST_FOR_CATALOG_JOBS': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_VENDOR_NAMES_SUCCESS_FOR_CATALOG_JOBS': {
      const vendors = action?.payload || [];
      return {
        ...state,
        fetching: false,
        vendors: [...vendors]
      };
    }
    case 'GET_VENDOR_NAMES_FAILED_FOR_CATALOG_JOBS': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_PAGINATION_CATALOG_JOBS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    case 'SET_FILTERS_CATALOG_JOBS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_SORT_OPTIONS_CATALOG_JOBS': {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
};

export default catalogJobs;
