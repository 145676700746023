import React, { lazy } from 'react';

const CombinedOrdersContainer = lazy(() => import('../../containers/purchaseOrders/combinedOrders/combinedOrdersContainer'));
const Journal = lazy(() => import('../../containers/purchaseOrders/JournalContainer'));
const ChargeClientGridContainer = lazy(() => import('../../containers/chargeClient/chargeClientGridContainer'));
const DistroCustomerContainer = lazy(() => import('../../components/purchaseOrders/distroCustomer.jsx'));

export const DISTRO_ROUTES = [
  {
    path: '/combined-orders',
    component: <CombinedOrdersContainer />,
    group: 'Distro',
    reducer: 'combinedOrders'
  },
  {
    path: '/journal',
    component: <Journal />,
    group: 'Distro',
    reducer: 'journal'
  },
  {
    path: '/charge-client/:id',
    component: <ChargeClientGridContainer />,
    group: 'Distro',
    reducer: ['combinedOrders', 'payment'],
  },
  {
    path: '/distro-customers',
    component: <DistroCustomerContainer />,
    group: 'Distro',
    reducer: 'distroCustomers',
  }
];
