import { createStore, applyMiddleware, combineReducers } from 'redux';
import createFilter from 'redux-persist-transform-filter';
import thunkMiddleware from 'redux-thunk';
import { persistReducer } from 'redux-persist';
import { createLogger } from 'redux-logger';
import combinedReducer from './reducers/index';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth','store'],
};

const persistedReducer = persistReducer(persistConfig, combinedReducer);

const configureStore = () => {
  const middleware = [
    thunkMiddleware,
     createLogger()
  ];

  const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore);

  const store = createStoreWithMiddleware(persistedReducer);

  return store;
}

export default configureStore;
