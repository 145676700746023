import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  pricehistories: [],
  strategies: [],
  filters: {},
  sortOptions: {},
  selectedItems: [],
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  totalRecords: 0
};

const PriceHistory = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GET_PRICE_HISTORY_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_PRICE_HISTORY_SUCCESS': {
      const { priceHistories, totalRecords } = action.payload;
      return {
        ...state,
        fetching: false,
        priceHistories,
        totalRecords
      };
    }
    case 'GET_PRICE_HISTORY_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_HISTORY_PAGINATION': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    case 'SET_KEYWORD_FILTER_FOR_PRICE_HISTORY': {
      const { key } = action.payload;
      return {
        ...state,
        filters: key ? { keyword: { value: key } } : {}
      };
    }
    case 'GET_STRATEGIES_FOR_DROPDOWN_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_STRATEGIES_FOR_DROPDOWN_SUCCESS': {
      const { strategies } = action.payload;
      const strategiesNames = strategies.map((strg) => strg.name);
      return {
        ...state,
        fetching: false,
        strategies,
        strategiesNames
      };
    }
    case 'GET_STRATEGIES_FOR_DROPDOWN_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }

    default:
      return state;
  }
};

export default PriceHistory;
