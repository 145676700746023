import Styled from 'styled-components';
const SwitchWrapper = Styled.div`
    &.switch,&.expire{
        margin-bottom:7px;
        span{
            text-transform:capitalize;
            color:${({ theme }) => theme["black-color"]}; 
            font-size:${({ theme }) => theme["medium-font-size"]}; 

        }
    
    }
    .react-switch-bg{
            /* border: 1px solid rgb(206, 212, 218); */
        }
        &.checkin-main-switch{
            grid-column-gap: 12px;
            font-size:14px;
            font-weight:400;
            span{
                strong{
                    font-weight:700;
                }
            }
            &.unmatched-po{
              grid-column-gap: 28px; 
              span{
                margin-left: -16px;
                margin-right: 8px;
              }
            }
        }

  `;
export { SwitchWrapper };
