import React, { useState, useMemo } from 'react'
import { debounce, isEmpty } from 'lodash'
import { Spin } from 'antd';
import { object, string, ref, bool } from 'yup';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Logo from "../../assets/images/newImages/new_log.png";
import { AuthWrapper, PhoneNumberWrapper } from '../profile/style'
import { Form, Row, Col, Container } from "react-bootstrap"
import InfoVideo from '../profile/component/infoVideo'
import LabelInput from '../uiComponent/inputs/labelInput'
import PhoneInput from '../uiComponent/inputs/phoneNumberInput'
import CheckBox from '../uiComponent/checkBox'
import Button from '../uiComponent/button/index'
import { BiLeftArrowAlt } from "react-icons/bi";
import { AiFillEyeInvisible, AiFillEye, AiOutlineMenu } from "react-icons/ai";
import { ListLocationItems, setField } from "../../slices/internalStorage";
import { MobileWrapper, Header, Content, Footer, Title, InputWrapper } from './mobile.styled';

const ScanLocation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { listLocationLoading } = useSelector(state => state?.internalStorage);

  const [value, setValue] = useState()

  const handleShowLocationDetails = debounce(async(event) => {
    const value = event.target.value;
    if(!isEmpty(value)) {
      const response = await dispatch(ListLocationItems({ locationId: value }));
      if (isEmpty(response.error)) {
        const { locations } = response?.payload?.data || {};
        dispatch(setField({ field: 'selectedLocationId', value: locations }))
        navigate('/location-details');
      }
    }
  }, 1000)


  return (
    <MobileWrapper className='fullContent'>
      <div className='mobile-page'>
        <div className='container-fluid px-0'>
         <Spin tip='Processing' spinning={listLocationLoading}>
          <Content className='fullContent'>
            <Title>
              <h2> 
                <span
                  className="icon-arrow-left cursor-pointer"
                  onClick={() => navigate('/home')}
                ></span>
                Scan Location</h2>
            </Title>
            <InputWrapper>
                <LabelInput onChange={handleShowLocationDetails} className="input-with-icon" icon="icon-barcode" label="Scan Location Barcode" placeholder="Scan Barcode"/>
            </InputWrapper>
          </Content>
          </Spin>
        </div>
      </div>
    </MobileWrapper>
  )
}

export default ScanLocation;
