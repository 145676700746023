import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleRejectedResponse } from './utils';
import { isEmpty } from 'lodash';
import axios from '../../axios_config';
import axiosSimple from 'axios';
import { notification } from 'antd';

export const ResetPasswordVerification = createAsyncThunk(
  '/users/reset-password-verification',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/users/password',
        data
      );
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const SaveNewPassword = createAsyncThunk(
  '/users/save-new-password',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `/users/password`,
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Save New Password', rejectWithValue);
    }
  }
);

export const SignUp = createAsyncThunk(
  '/users/sign-up',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/users',
        data
      );
      return {
        ...response,
        data: { ...response.data, user: data.user }
      };
    } catch (err) {
      return handleRejectedResponse(err, 'Sign Up', rejectWithValue);
    }
  }
);

export const ConfirmTokenVerification = createAsyncThunk(
  '/users/confirm-email-verification',
  async (data, { getState, rejectWithValue }) => {
    const { confirmationToken } = data;
    try {
      const response = await axios.get(
        `/users/confirmation?confirmation_token=${confirmationToken}`,
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Sign Up Confirmation', rejectWithValue);
    }
  }
);

export const ResendEmailVerification = createAsyncThunk(
  '/users/resend-email-verification',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/users/confirmation',
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Resend Verification Email', rejectWithValue);
    }
  }
);

export const SignIn = createAsyncThunk(
  '/users/sign-in',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/users/sign_in',
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Sign In', rejectWithValue);
    }
  }
);

export const GetCurrentUser = createAsyncThunk(
  '/users/current-user',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        '/api/v2/users/logged_in_user',
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Current User', rejectWithValue);
    }
  }
);

export const ImpersonateUser = createAsyncThunk(
  '/impersonate-user',
  async (data, { getState, rejectWithValue }) => {
    const { id } = data;
    try {
      const response = await axios.post(
        `/api/v2/users/${id}/impersonate`
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Impersonate User', rejectWithValue);
    }
  }
);

export const GetRepricerSyncedStatus = createAsyncThunk(
  '/repricer-synced-status',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        '/api/v2/users/is_data_synced'
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Repricer Synced Status', rejectWithValue);
    }
  }
);

export const SignOut = createAsyncThunk(
  '/users/sign-out',
  async (data, { getState, rejectWithValue }) => {
    try {
      let response = {
        data: {
          status: 200,
          message: "Logged out successfully"
        }
      };

      let { email } = getState()?.auth?.currentUser;
      if (email !== 'steven@wraithco.com') {
        response = await axios.delete(
          '/users/sign_out'
        );
      }

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Sign Out', rejectWithValue);
    }
  }
);

const auth = createSlice({
  name: "auth",
  initialState: {
    user: {
      email: ''
    },
    token: '',
    parentToken: '',
    currentUser: {},
    parentUser: {},
    isImpersonated: false,
    signInLoading: false,
    signUpLoading: false,
    resetPasswordLoading: false,
    newPasswordLoading: false,
    resendEmailLoading: false,
    fetchingCurrentUser: false,
    impersonatingUser: false,
    confirmEmailLoading: false,
    isImpersonatedCurrentUser: false,
    signOutLoading: false
  },
  reducers: {
    setField(state, { payload: { module, field, value } }) {
      state[module][field] = value;
    },
    setItem(state, { payload: { field, value } }) {
      state[field] = value;
    },
    stopImpersonatingUser(state) {
      state['token'] = state['parentToken'];
      state['parentToken'] = '';
      state['currentUser'] = state['parentUser'];
      state['parentUser'] = {};
      state['isImpersonated'] = false;
      state['isImpersonatedCurrentUser'] = false;
    },
    clearState(state) {
      state['user']['email'] = '';
      state['token'] = '';
      state['parentToken'] = '';
      state['currentUser'] = {};
      state['parentUser'] = {};
      state['isImpersonated'] = false;
      state['isImpersonatedCurrentUser'] = false;
    }
  },
  extraReducers: {
    [ResetPasswordVerification.pending]: (state, action) => ({
      ...state,
      resetPasswordLoading: true
    }),
    [ResetPasswordVerification.fulfilled]: (state, action) => {
      notification.success({
        message: 'Email sent successfully',
        top: 65,
      })
      return {
        ...state,
        resetPasswordLoading: false
      }
    },
    [ResetPasswordVerification.rejected]: (state, action) => {
      const { email } = action?.payload?.err?.data?.errors || {};
      const errorMessage = email?.[0] === 'not found' ? 'Email not found' : 'Unknown Error';

      notification.error({
        message: 'Email Confirmation',
        description: errorMessage,
        placement: "topRight",
        top: 65,
      });

      return {
        ...state,
        resetPasswordLoading: false
      }
    },
    [SaveNewPassword.pending]: (state, action) => ({
      ...state,
      newPasswordLoading: true
    }),
    [SaveNewPassword.fulfilled]: (state, action) => {
      notification.success({
        message: 'Password updated successfully',
        top: 65,
      })
      return {
        ...state,
        newPasswordLoading: false
      }
    },
    [SaveNewPassword.rejected]: (state, action) => {
      return {
        ...state,
        newPasswordLoading: false
      }
    },
    [SignUp.pending]: (state, action) => ({
      ...state,
      signUpLoading: true,
    }),
    [SignUp.fulfilled]: (state, action) => {
      const { message, user, token } = action?.payload?.data || {};
      notification.success({
        message: message || 'Email sent successfully',
        top: 65,
      });

      const data = {
        ...state,
        signUpLoading: false,
      }

      if (user?.platform === 'Repricer') {
        data.isImpersonated = false;
        data.isImpersonatedCurrentUser = false;
        data.parentUser = {};
        data.parentToken = '';
        data.token = token;
      }

      return data;
    },
    [SignUp.rejected]: (state, action) => {
      return {
        ...state,
        signUpLoading: false,
      }
    },
    [ConfirmTokenVerification.pending]: (state, action) => ({
      ...state,
      confirmEmailLoading: true
    }),
    [ConfirmTokenVerification.fulfilled]: (state, action) => {
      notification.success({
        message: 'Email confirmed',
        top: 65,
      })
      return {
        ...state,
        confirmEmailLoading: false
      }
    },
    [ConfirmTokenVerification.rejected]: (state, action) => {
      return {
        ...state,
        confirmEmailLoading: false
      }
    },
    [ResendEmailVerification.pending]: (state, action) => ({
      ...state,
      resendEmailLoading: true
    }),
    [ResendEmailVerification.fulfilled]: (state, action) => {
      notification.success({
        message: 'Email sent successfully',
        top: 65,
      })
      return {
        ...state,
        resendEmailLoading: false
      }
    },
    [ResendEmailVerification.rejected]: (state, action) => {
      return {
        ...state,
        resendEmailLoading: false
      }
    },
    [SignIn.pending]: (state, action) => ({
      ...state,
      signInLoading: true,
    }),
    [SignIn.fulfilled]: (state, action) => {
      notification.success({
        message: 'Signed In successfully',
        top: 65,
      })
      return {
        ...state,
        signInLoading: false
      }
    },
    [SignIn.rejected]: (state, action) => {
      return {
        ...state,
        signInLoading: false
      }
    },
    [GetCurrentUser.pending]: (state, action) => ({
      ...state,
      fetchingCurrentUser: true
    }),
    [GetCurrentUser.fulfilled]: (state, action) => {
      const { current_user } = action?.payload?.data || {};
      const repricer_hash = current_user?.repricer || {};
      const { isImpersonated } = state;

      return (isImpersonated) ? {
        ...state,
        parentUser: isEmpty(state.parentUser) ? state.currentUser : state.parentUser,
        currentUser: {
          ...current_user,
          ...repricer_hash,
          name: current_user?.full_name
        },
        isImpersonatedCurrentUser: isImpersonated ? true : false,
        fetchingCurrentUser: false,
        ...repricer_hash
      } : {
        ...state,
        currentUser: {
          ...current_user,
          ...repricer_hash,
          name: current_user?.full_name
        },
        fetchingCurrentUser: false,
      };
    },
    [GetCurrentUser.rejected]: (state, action) => {
      const { statusCode } = action?.payload || {};
      if (statusCode == 401) {
        axios.defaults.headers.common.Authorization = '';
        notification.info({
          message: "SESSION EXPIRED",
          description: 'Un-Authorized, Login Requires',
          top: 65
        })
      }

      return {
        ...state,
        user: {
          email: ''
        },
        token: '',
        parentToken: '',
        currentUser: {},
        parentUser: {},
        isImpersonated: false,
        signInLoading: false,
        signUpLoading: false,
        resetPasswordLoading: false,
        newPasswordLoading: false,
        resendEmailLoading: false,
        fetchingCurrentUser: false,
        impersonatingUser: false,
        confirmEmailLoading: false,
        isImpersonatedCurrentUser: false,
        signOutLoading: false
      }
    },
    [ImpersonateUser.pending]: (state, action) => ({
      ...state,
      impersonatingUser: true
    }),
    [ImpersonateUser.fulfilled]: (state, action) => {
      const { impersonated_jwt, success, message } = action?.payload?.data || {}
      axios.defaults.headers.common.Authorization = `Bearer ${impersonated_jwt}`;

      return {
        ...state,
        parentToken: isEmpty(state.parentToken) ? state.token : state.parentToken,
        token: impersonated_jwt,
        isImpersonated: true,
        isImpersonatedCurrentUser: false,
        impersonatingUser: false
      }
    },
    [ImpersonateUser.rejected]: (state, action) => {
      return {
        ...state,
        impersonatingUser: false
      }
    },
    [GetRepricerSyncedStatus.pending]: (state, action) => ({
      ...state
    }),
    [GetRepricerSyncedStatus.fulfilled]: (state, action) => {
      const {
        is_repricer_data_synced,
        is_repricer_enabled,
        is_repricer_live
      } = action?.payload?.data?.repricer || {};

      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          is_repricer_data_synced,
          is_repricer_enabled,
          is_repricer_live
        }
      }
    },
    [GetRepricerSyncedStatus.rejected]: (state, action) => {
      return {
        ...state
      }
    },
    [SignOut.pending]: (state, action) => ({
      ...state,
      signOutLoading: true
    }),
    [SignOut.fulfilled]: (state, action) => {
      const { status, message } = action?.payload?.data || {}
      axios.defaults.headers.common.Authorization = '';

      notification.success({
        message,
        top: 65,
      })

      return {
        ...state,
        user: {
          email: ''
        },
        token: '',
        parentToken: '',
        currentUser: {},
        parentUser: {},
        isImpersonated: false,
        signInLoading: false,
        signUpLoading: false,
        resetPasswordLoading: false,
        newPasswordLoading: false,
        resendEmailLoading: false,
        fetchingCurrentUser: false,
        impersonatingUser: false,
        confirmEmailLoading: false,
        isImpersonatedCurrentUser: false,
        signOutLoading: false
      }
    },
    [SignOut.rejected]: (state, action) => {
      const { message } = action?.payload || {};
      if (message === 'Invalid Token') {
        axios.defaults.headers.common.Authorization = '';

        return {
          ...state,
          user: {
            email: ''
          },
          token: '',
          parentToken: '',
          currentUser: {},
          parentUser: {},
          isImpersonated: false,
          signInLoading: false,
          signUpLoading: false,
          resetPasswordLoading: false,
          newPasswordLoading: false,
          resendEmailLoading: false,
          fetchingCurrentUser: false,
          impersonatingUser: false,
          confirmEmailLoading: false,
          isImpersonatedCurrentUser: false,
          signOutLoading: false
        }
      }

      return {
        ...state,
        signOutLoading: false
      }
    },
  }
});

const { reducer, actions } = auth;
export const { setField, setItem, stopImpersonatingUser } = actions;
export default reducer;
