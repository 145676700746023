import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleRejectedResponse } from '../utils';
import axiosRails, { fetch as axios, awsAxios } from '../../../axios_config';
import { notification } from 'antd';

export const GetUsersList = createAsyncThunk(
  '/get-to-receive-users',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/get-users`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Users List', rejectWithValue);
    }
  }
);

export const GetToReceivePendingPOs = createAsyncThunk(
  '/get-to-receive-pending-pos',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/get-pending-pos`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          params: data
        },
      );

      return {
        ...response,
        data: { ...response.data, page: data.page }
      };
    } catch (err) {
      return handleRejectedResponse(err, 'Get To-Receive Pending POs', rejectWithValue);
    }
  }
);

export const GetPoReceivingHistory = createAsyncThunk(
  '/get-receiving-history',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/get-receiving-history`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          params: data
        },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get PO Receiving History', rejectWithValue);
    }
  }
);

export const GetReceivingPoDetails = createAsyncThunk(
  '/get-pending-po-details/:pending_po_id',
  async (pending_po_id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/get-pending-po-details/${pending_po_id}`,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Receiving Po Details', rejectWithValue);
    }
  }
);

export const GetShipmentOldBoxes = createAsyncThunk(
  '/get-shipment-old-boxes/:shipment_id',
  async (shipment_id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/get-shipment-old-boxes/${shipment_id}`,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Shipment Old Boxes', rejectWithValue);
    }
  }
);

export const CreateOutboundShipment = createAsyncThunk(
  '/create-outbound-shipment',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/create-outbound-shipment`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Create Outbound Shipment', rejectWithValue);
    }
  }
);

export const DeleteOutboundShipment = createAsyncThunk(
  '/delete-outbound-shipment',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.delete(
        `${process.env.FBA_INBOUND_API_URL}/delete-outbound-shipment`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          params: data
        },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Delete Outbound Shipment', rejectWithValue);
    }
  }
);

export const GetSkuStatus = createAsyncThunk(
  '/check-sku-status/:pending_po_id',
  async (pending_po_id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/check-sku-status/${pending_po_id}`,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Sku Status', rejectWithValue);
    }
  }
);

export const ReceiveGatedPo = createAsyncThunk(
  '/receive-add-storage',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/receive-add-storage`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Receive Gated Po', rejectWithValue);
    }
  }
);

export const ReceiveStoragePo = createAsyncThunk(
  '/receive-storage-po/:pending_po_id',
  async (data, { getState, rejectWithValue }) => {
    try {
      const pending_po_id = data.pending_po_id;
      delete data.pending_po_id;
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/receive-storage-po/${pending_po_id}`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Receive Storage Po', rejectWithValue);
    }
  }
);

export const DeleteStoragePo = createAsyncThunk(
  '/staging_items/:id',
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axiosRails.delete(
        `/api/v2/staging_items/${id}`,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Delete Storage Po', rejectWithValue);
    }
  }
);

const printLables = async (data, inbound_shipment_id) => {
  const { s3_file_link, success, message } = data || {};
  if (success) {
    if (s3_file_link) {
      try {
        const res = await awsAxios({
          method: 'get',
          url: s3_file_link,
          headers: {
            'Content-Type': 'application/pdf',
            'Access-Control-Allow-Origin': '*'
          },
          responseType: 'blob',
        });
        const blob = new Blob([res?.data], { type: 'arraybuffer' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', `FBA_label_${inbound_shipment_id}.pdf`);
        link.click();
      } catch (error) {
        console.log('error => ', error);
        const { data } = error || {};
        const { message } = data || {}

        notification.error({
          message: 'FBA LABEL',
          description: message || error.message || "Can't download Fba Labels",
          top: 65
        });
        throw error;
      }
    }
  } else {
    notification.error({
      top: 65,
      message
    })
  }
}

const print2DLabels = async (data, outbound_shipment_id) => {
  const { s3_file_link, success, message } = data || {};
  if (success) {
    if (s3_file_link) {
      try {
        const res = await awsAxios({
          method: 'get',
          url: s3_file_link,
          headers: {
            'Content-Type': 'application/pdf',
            'Access-Control-Allow-Origin': '*'
          },
          responseType: 'blob',
        });

        const blob = new Blob([res?.data],{ type: 'arraybuffer' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', `FBA_label_${outbound_shipment_id}.pdf`);
        link.click();
      } catch(error) {
        const { data } = error || {};
        const { message } = data || {};
        notification.error({
          message: 'FBA LABEL',
          description: message || error.message || "Can't download Fba Labels",
          top: 65
        });
      }
    }
  } else {
    notification.error({
      top: 65,
      message
    });
  }
};

export const GetMix2DLabels = createAsyncThunk(
  '/api/v2/inbound_shipments/:inboundShipmentId/print_mix_fba_labels_for_new_users',
  async ({ data, inbound_shipment_id }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axiosRails.post(
        `/api/v2/inbound_shipments/${inbound_shipment_id}/print_mix_fba_labels_for_new_users`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      await printLables(response?.data, inbound_shipment_id);
      return {
        ...response,
        data: { ...response.data, inbound_shipment_id }
      };
    } catch (err) {
      return handleRejectedResponse(err, 'Get Mix 2D Labels', rejectWithValue);
    }
  }
);

export const Get2DLabels = createAsyncThunk(
  '/api/v2/outbound_shipments/:outbound_shipment_id/print_fba_labels_for_new_users',
  async ({ data, outbound_shipment_id }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axiosRails.post(
        `/api/v2/outbound_shipments/${outbound_shipment_id}/print_fba_labels_for_new_users`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      await print2DLabels(response?.data, outbound_shipment_id);
      return {
        ...response,
        data: { ...response.data, outbound_shipment_id }
      };
    } catch (err) {
      return handleRejectedResponse(err, 'Get 2D Labels', rejectWithValue);
    }
  }
);

export const GetLooseBoxDetails = createAsyncThunk(
  '/loose-box-items-details/:box_id',
  async (box_id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/loose-box-items-details/${box_id}`,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Loose Box Details', rejectWithValue);
    }
  }
);

export const DeleteShipment = createAsyncThunk(
  '/delete-shipment/:shipment_id',
  async (shipment_id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.delete(
        `${process.env.FBA_INBOUND_API_URL}/delete-shipment/${shipment_id}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Delete Shipment', rejectWithValue);
    }
  }
);

export const GetWorkingShipments = createAsyncThunk(
  '/get-working-shipments',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/get-working-shipments`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          params: data
        }
      );

      return {
        ...response,
        data: { ...response.data, page: data.page }
      };
    } catch (err) {
      return handleRejectedResponse(err, 'Get Working Shipments', rejectWithValue);
    }
  }
);

export const GetMatchedDistroPos = createAsyncThunk(
  '/list-matched-distro-pos/:pending_po_id',
  async (pending_po_id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/list-matched-distro-pos/${pending_po_id}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Fetch Matched Distro Pos', rejectWithValue);
    }
  }
);

export const FulfillDistroPo = createAsyncThunk(
  '/fulfill-distro-po',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/fulfill-distro-po`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Fulfill Distro PO', rejectWithValue);
    }
  }
);

export const GetAllLocations = createAsyncThunk(
  'fba-inbound/storages/dropdown',
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axiosRails.get(
        `/api/v2/storages/dropdown `,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Fetch All Locations', rejectWithValue);
    }
  }
);

export const CreateNewLocation = createAsyncThunk(
  '/storages',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axiosRails.post(
        `/api/v2/storages`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Create New Location', rejectWithValue);
    }
  }
);

export const AddToStorage = createAsyncThunk(
  '/staging_items',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axiosRails.post(
        `/api/v2/staging_items`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Add To Storage', rejectWithValue);
    }
  }
);

export const GetStorageItems = createAsyncThunk(
  '/get_staging_items',
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axiosRails.get(
        `api/v2/pending_pos/${id}/stored_items`,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response?.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Storage Items', rejectWithValue);
    }
  }
);

export const toReceiveInitialState = {
  toReceivePendingPos: [],
  fetchingToReceivePendingPOs: false,
  posTotalCount: 0,
  completedPosCount: 0,
  poReceivingHistory: {},
  fetchingPoReceivingHistory: false,
  receivingPoDetails: {},
  fetchingReceivingPoDetails: false,
  shipmentOldBoxes: [],
  newBoxIndex: 0,
  fetchingShipmentOldBoxes: false,
  creatingOutboundShipment: false,
  deletingOutboundShipment: false,
  usersList: [],
  fetchingUsersList: false,
  fetchingSkuStatus: false,
  receivingGatedPo: false,
  receivingStoragePo: false,
  deletingStoragePo: false,
  fetching2dLabels: false,
  fetchingLooseBoxDetails: false,
  fetchingMix2dLabels: false,
  fetchingWorkingShipments: false,
  workingShipments: [],
  shipmentsTotalCount: 0,
  deletingShipment: false,
  fetchingDistroPos: false,
  matchedDistroPos: [],
  fulfillingDistroPo: false,
  fetchingAllLocations: false,
  creatingNewLocation: false,
  addingToStorage: false,
  getStorageItems: false,
  storageItems: []
};

export const toReceiveReducers = {
  [GetUsersList.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      usersList: [],
      fetchingUsersList: true
    }
  }),
  [GetUsersList.fulfilled]: (state, action) => {
    const { result } = action?.payload?.data || {};
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        usersList: result,
        fetchingUsersList: false,
      }
    }
  },
  [GetUsersList.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        usersList: [],
        fetchingUsersList: false
      }
    }
  },
  [GetToReceivePendingPOs.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      fetchingToReceivePendingPOs: true,
    }
  }),
  [GetToReceivePendingPOs.fulfilled]: (state, action) => {
    const { result, count, page, completedPosCount } = action?.payload?.data || {};

    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingToReceivePendingPOs: false,
        toReceivePendingPos: (page.pageNumber === 1) ? result : [...state.toReceive.toReceivePendingPos, ...result],
        posTotalCount: count,
        completedPosCount
      }
    }
  },
  [GetToReceivePendingPOs.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingToReceivePendingPOs: false,
      }
    }
  },
  [GetPoReceivingHistory.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      fetchingPoReceivingHistory: true,
      poReceivingHistory: {}
    }
  }),
  [GetPoReceivingHistory.fulfilled]: (state, action) => {
    const { result } = action?.payload?.data || {};

    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingPoReceivingHistory: false,
        poReceivingHistory: result
      }
    }
  },
  [GetPoReceivingHistory.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingPoReceivingHistory: false,
      }
    }
  },
  [GetReceivingPoDetails.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      fetchingReceivingPoDetails: true,
    }
  }),
  [GetReceivingPoDetails.fulfilled]: (state, action) => {
    const { result, boxCategories } = action?.payload?.data || {};
    const { inbound_shipment_items } = result || {};

    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingReceivingPoDetails: false,
        receivingPoDetails: { inbound_shipment_items, boxCategories }
      }
    }
  },
  [GetReceivingPoDetails.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingReceivingPoDetails: false,
      }
    }
  },
  [GetShipmentOldBoxes.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      fetchingShipmentOldBoxes: true,
    }
  }),
  [GetShipmentOldBoxes.fulfilled]: (state, action) => {
    const { result, box_index } = action?.payload?.data || {};
    const { boxes } = result || {};

    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingShipmentOldBoxes: false,
        shipmentOldBoxes: boxes,
        newBoxIndex: box_index + 1
      }
    }
  },
  [GetShipmentOldBoxes.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingShipmentOldBoxes: false,
      }
    }
  },
  [CreateOutboundShipment.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      creatingOutboundShipment: true,
    }
  }),
  [CreateOutboundShipment.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Receive Po',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        creatingOutboundShipment: false,
      }
    }
  },
  [CreateOutboundShipment.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        creatingOutboundShipment: false,
      }
    }
  },
  [DeleteOutboundShipment.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      deletingOutboundShipment: true,
    }
  }),
  [DeleteOutboundShipment.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Delete Po Receiving History',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        deletingOutboundShipment: false,
      }
    }
  },
  [DeleteOutboundShipment.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        deletingOutboundShipment: false,
      }
    }
  },
  [GetSkuStatus.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      fetchingSkuStatus: true,
    }
  }),
  [GetSkuStatus.fulfilled]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingSkuStatus: false,
      }
    }
  },
  [GetSkuStatus.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingSkuStatus: false,
      }
    }
  },
  [ReceiveGatedPo.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      receivingGatedPo: true,
    }
  }),
  [ReceiveGatedPo.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Receive Gated Po',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        receivingGatedPo: false,
      }
    }
  },
  [ReceiveGatedPo.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        receivingGatedPo: false,
      }
    }
  },
  [ReceiveStoragePo.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      receivingStoragePo: true,
    }
  }),
  [ReceiveStoragePo.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Receive Storage Po',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        receivingStoragePo: false,
      }
    }
  },
  [ReceiveStoragePo.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        receivingStoragePo: false,
      }
    }
  },
  [DeleteStoragePo.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      deletingStoragePo: true,
    }
  }),
  [DeleteStoragePo.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Delete Storage Po',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        deletingStoragePo: false,
      }
    }
  },
  [DeleteStoragePo.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        deletingStoragePo: false,
      }
    }
  },
  [Get2DLabels.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      fetching2dLabels: true,
    }
  }),
  [Get2DLabels.fulfilled]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetching2dLabels: false,
      }
    }
  },
  [Get2DLabels.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetching2dLabels: false,
      }
    }
  },
  [GetLooseBoxDetails.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      fetchingLooseBoxDetails: true,
    }
  }),
  [GetLooseBoxDetails.fulfilled]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingLooseBoxDetails: false,
      }
    }
  },
  [GetLooseBoxDetails.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingLooseBoxDetails: false,
      }
    }
  },
  [GetMix2DLabels.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      fetchingMix2dLabels: true,
    }
  }),
  [GetMix2DLabels.fulfilled]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingMix2dLabels: false,
      }
    }
  },
  [GetMix2DLabels.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingMix2dLabels: false,
      }
    }
  },
  [GetWorkingShipments.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      fetchingWorkingShipments: true,
    }
  }),
  [GetWorkingShipments.fulfilled]: (state, action) => {
    const { count, result, page } = action?.payload?.data || {};

    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingWorkingShipments: false,
        workingShipments: (page.pageNumber === 1) ? result : [...state.toReceive.workingShipments, ...result],
        shipmentsTotalCount: count
      }
    }
  },
  [GetWorkingShipments.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingWorkingShipments: false,
      }
    }
  },
  [DeleteShipment.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      deletingShipment: true,
    }
  }),
  [DeleteShipment.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Delete Shipment',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        deletingShipment: false
      }
    }
  },
  [DeleteShipment.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        deletingShipment: false,
      }
    }
  },
  [GetMatchedDistroPos.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      fetchingDistroPos: true,
    }
  }),
  [GetMatchedDistroPos.fulfilled]: (state, action) => {
    const { poResult } = action?.payload?.data || {};

    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingDistroPos: false,
        matchedDistroPos: poResult
      }
    }
  },
  [GetMatchedDistroPos.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingDistroPos: false,
      }
    }
  },
  [FulfillDistroPo.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      fulfillingDistroPo: true,
    }
  }),
  [FulfillDistroPo.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Fulfill Distro PO',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fulfillingDistroPo: false,
      }
    }
  },
  [FulfillDistroPo.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fulfillingDistroPo: false,
      }
    }
  },
  [GetAllLocations.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      fetchingAllLocations: true,
    }
  }),
  [GetAllLocations.fulfilled]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingAllLocations: false,
      }
    }
  },
  [GetAllLocations.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        fetchingAllLocations: false,
      }
    }
  },
  [CreateNewLocation.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      creatingNewLocation: true,
    }
  }),
  [CreateNewLocation.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Create New Location',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        creatingNewLocation: false,
      }
    }
  },
  [CreateNewLocation.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        creatingNewLocation: false,
      }
    }
  },
  [AddToStorage.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      addingToStorage: true,
    }
  }),
  [AddToStorage.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Add To Interal Storage',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        addingToStorage: false,
      }
    }
  },
  [AddToStorage.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        addingToStorage: false,
      }
    }
  },
  [GetStorageItems.pending]: (state, action) => ({
    ...state,
    toReceive: {
      ...state.toReceive,
      getStorageItems: true,
    }
  }),
  [GetStorageItems.fulfilled]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        getStorageItems: false,
        storageItems: action?.payload?.history
      }
    }
  },
  [GetStorageItems.rejected]: (state, action) => {
    return {
      ...state,
      toReceive: {
        ...state.toReceive,
        getStorageItems: false,
      }
    }
  },
};
