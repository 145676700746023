import React from "react";
import { HamburgerIconContainer, Line } from "./menu-button.styles";

const HamburgerIcon = ({ setIsDropdownShown, rotated }) => {

  const handleClick = () => {
    setIsDropdownShown(isDropdownShown => !isDropdownShown);
  };

  return (
    <HamburgerIconContainer rotated={rotated ? "-90deg" : "0"} onClick={handleClick}>
      <Line rotated={rotated} style={{ width: "66%" }} />
      <Line rotated={rotated} />
      <Line rotated={rotated} style={{ width: "33%" }} />
    </HamburgerIconContainer>
  );
};

export default HamburgerIcon;
