import React, { lazy } from 'react';

const CompanyContainer = lazy(() => import('../../containers/company/companyContainer'));
const WorkersContainer = lazy(() => import('../../containers/WorkersContainer'));
const PaymentContainer = lazy(() => import('../../containers/PaymentContainer'));
const CardInformationContainer = lazy(() => import('../../containers/CardInformationContainer'));

export const SETTINGS_ROUTES = [
  // {
  //   path: '/companies',
  //   component: <CompanyContainer />,
  //   group: 'Settings',
  //   reducer: 'companies'
  // },
  {
    path: '/users',
    component: <WorkersContainer />,
    group: 'Settings',
    reducer: 'workers'
  },
  {
    path: '/payments',
    component: <PaymentContainer />,
    group: 'Settings',
    reducer: ['payment', 'affiliateAccountSettings']
  },
  {
    path: '/payments/card-information',
    component: <CardInformationContainer />,
    group: 'Settings',
    reducer: 'payment'
  }
];
