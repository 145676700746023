import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";

import { isEmpty } from "lodash";
import { AiOutlineAppstore } from "react-icons/ai";
import Header from "../header/header";
import { Div, Content } from "./style";
import { useSelector } from "react-redux";
import FbmInbound from "../../containers/fbmInbound/index";
import './style.css';
import plPaymentIcon from "../../assets/images/newImages/pl-payment.svg";
import TrailPopover from '../../components/trailPopover'

function Layout(props) {
  const headerRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [currentOpenedTab, setCurrentOpenedTab] = useState('');
  const [makeBlur, setMakeBlur] = useState(false);

  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);
  const [linkToOpen, setLinkToOpen] = useState('');
  const [currentUrl, setCurrentUrl] = useState('');
  const [currentSingleTab, setCurrentSingleTab] = useState('');
  const [showTrialPopup, setShowTrialPopup] = useState('');

  const { currentUser: user } = useSelector((state) => state?.auth || {});

  const isOwner = user?.email == "steven@wraithco.com";
  const isAdmin = user?.role_id == 1 || user?.role_id == 3;
  const isWarehouseWorker = user?.warehouse_manager_id;
  const isWorker = user?.role_id == 5;
  const isNotSpecialUser = user?.email != "test@sales.support.com";

  const path = window?.location?.pathname;
  const isFBMPages = path.includes('fbm') || path.includes('orders-fulfillment') || path.includes('warehouse-location') || path.includes('location-names') || path.includes('locations-history') || path.includes('locations') || path.includes('fba-inbound');

  useEffect(() => {
    const { pathname } = window.location;
    if (pathname.includes("/affiliate")) {
      setCurrentOpenedTab('Affiliate');
      setCurrentUrl(pathname);
      setCurrentSingleTab('');
    }
    else if (pathname.includes('/po')) {
      setCurrentOpenedTab('');
      setCurrentUrl('');
      setCurrentSingleTab('PO');
    }
    else if (['/combined-orders', '/journal', '/distro-customers'].includes(pathname)) {
      setCurrentOpenedTab('Distro');
      setCurrentUrl(pathname);
      setCurrentSingleTab('');
    }
    else if (pathname.includes("/reprice")) {
      setCurrentOpenedTab('Repricer');
      setCurrentUrl(pathname);
      setCurrentSingleTab('');
    }
    else if (['/fba-inbound', '/transport-plans'].includes(pathname)) {
      setCurrentOpenedTab('FBA');
      setCurrentUrl(pathname);
      setCurrentSingleTab('');
    }
    else if (['/fba-removal-shipments/search', '/received-removal-orders', '/user-removals'].includes(pathname)) {
      setCurrentOpenedTab('Removals');
      setCurrentUrl(pathname);
      setCurrentSingleTab('');
    }
    else if (['/users', '/account', '/payments'].includes(pathname)) {
      setCurrentOpenedTab('Settings');
      setCurrentUrl(pathname);
      setCurrentSingleTab('');
    }
    else if (['/invoices', '/user-invoices', '/master-removal-invoices', '/removal-invoices', '/subscription-invoices',
      '/two-step-master-invoices', '/two-step-invoices', '/master-storage-invoices', '/storage-invoices', '/invoices/settings']
      .includes(pathname)) {
      setCurrentOpenedTab('Invoices');
      setCurrentUrl(pathname);
      setCurrentSingleTab('');
    }
    else if (pathname === '/admin/user') {
      setCurrentSingleTab('Admin');
      setCurrentOpenedTab('');
      setCurrentUrl('');
    }
    else if (pathname === '/vendors') {
      setCurrentSingleTab('Vendors');
      setCurrentOpenedTab('');
      setCurrentUrl('');
    }
    else if (pathname === '/catalog-jobs') {
      setCurrentSingleTab('Catalog');
      setCurrentOpenedTab('');
      setCurrentUrl('');
    }
    else if (pathname === '/products') {
      setCurrentSingleTab('Inventory');
      setCurrentOpenedTab('');
      setCurrentUrl('');
    }
    else if (pathname === '/orders-fulfillment') {
      setCurrentSingleTab('Orders Fulfillment');
      setCurrentOpenedTab('');
      setCurrentUrl('');
    }
    else if (pathname === '/3pl-payments') {
      setCurrentSingleTab('3pl Payments');
      setCurrentOpenedTab('');
      setCurrentUrl('');
    }
    else if (pathname === '/sales') {
      setCurrentSingleTab('Sales');
      setCurrentOpenedTab('');
      setCurrentUrl('');
    }
    // else if (pathname === '/fba-inbound') {
    //   setCurrentSingleTab('FbaInbound');
    //   setCurrentOpenedTab('');
    //   setCurrentUrl('');
    // }
    else if (pathname.includes('/fbm')) {
      setCurrentOpenedTab('FBM');
      setCurrentUrl(pathname);
      setCurrentSingleTab('');
    }
    else if (pathname === '/warehouse-location') {
      setCurrentSingleTab('InternalStorage');
      setCurrentOpenedTab('');
      setCurrentUrl('');
    }
    else if (pathname === '/home') {
      setCurrentSingleTab('MobileScreen');
      setCurrentOpenedTab('');
      setCurrentUrl('');
    }
    else if (pathname === '/locations') {
      setCurrentSingleTab('Locations');
      setCurrentOpenedTab('');
      setCurrentUrl('');
    }
  }, [window.location.pathname, collapsed])

  useEffect(() => {
    if (user && user?.role?.name !== 'Worker') {
      setShowTrialPopup(!user?.card_added);
    }
  }, [user]);

  const handleContextMenu = useCallback(
    (event, link) => {
      event.preventDefault();
      setLinkToOpen(link);
      setAnchorPoint({ x: event.pageX, y: event.pageY });
      setShow(true);
    },
    [setAnchorPoint]
  );

  const resetAndNavigate = async (link, reducers) => {
    if (window.location.pathname !== link) {
      await dispatch({ type: 'RESET_APP', reducers });
      navigate(link);
    }
  }

  const repricerMenuBar = () => {
    if (isWarehouseWorker || isWorker) return null;

    if (isOwner) {
      return (
        <SubMenu
          title="AI Repricer"
          icon={<span className="icon-repricer"></span>}
          open={currentOpenedTab === 'Repricer'}
          onClick={() => {
            if (currentOpenedTab === 'Repricer') {
              setCurrentOpenedTab('')
            } else {
              setCurrentOpenedTab('Repricer')
            }
          }}
        >
          <MenuItem
            onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/reprice/dashboard`)}
            active={currentUrl === "/reprice/dashboard"}
            className="no-border"
            onClick={() => resetAndNavigate("/reprice/dashboard", ['Dashboard', 'Setting', 'Products'])}
          >
            Dashboard
          </MenuItem>
        </SubMenu>
      );
    }

    if (user?.is_listing_synced) {
      return (
        <SubMenu
          title="AI Repricer"
          icon={<span className="icon-repricer"></span>}
          open={currentOpenedTab === 'Repricer'}
          onClick={() => {
            if (currentOpenedTab === 'Repricer') {
              setCurrentOpenedTab('')
            } else {
              setCurrentOpenedTab('Repricer')
            }
          }}
        >
          <MenuItem
            onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/reprice/dashboard`)}
            active={currentUrl === "/reprice/dashboard"}
            className="no-border"
            onClick={() => resetAndNavigate("/reprice/dashboard", ['Dashboard', 'Setting', 'Products'])}
          >
            Dashboard
          </MenuItem>
          <MenuItem
            onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/reprice/products`)}
            active={currentUrl === "/reprice/products"}
            className="no-border"
            onClick={() => resetAndNavigate("/reprice/products", ['Strategies', 'Products', 'Setting'])}
          >
            Products
          </MenuItem>
          <MenuItem
            onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/reprice/strategies`)}
            active={currentUrl === "/reprice/strategies"}
            className="no-border"
            onClick={() => resetAndNavigate("/reprice/strategies", ['Strategies', 'Products', 'Setting'])}
          >
            Strategies
          </MenuItem>
          <MenuItem
            onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/reprice/price-history`)}
            active={currentUrl === "/reprice/price-history"}
            className="no-border"
            onClick={() => resetAndNavigate("/reprice/price-history", ['History', 'Setting'])}
          >
            Price History
          </MenuItem>
          <MenuItem
            onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/reprice/settings`)}
            active={currentUrl === "/reprice/settings"}
            className="no-border"
            onClick={() => resetAndNavigate("/reprice/settings", ['Strategies', 'Setting', 'Products'])}
          >
            Settings
          </MenuItem>
          {/* <MenuItem
            onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/reprice/walk-through`)}
            active={currentUrl === "/reprice/walk-through"}
            className="no-border"
            onClick={() => resetAndNavigate("/reprice/walk-through", ['Setting', 'Strategies'])}
          >
            Guide
          </MenuItem> */}
          <MenuItem
            onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/reprice/guide?panel=authorization`)}
            active={currentUrl === "/reprice/guide"}
            className="no-border"
            onClick={() => resetAndNavigate("/reprice/guide?panel=authorization", ['repricerDashboard'])}
          >
            Guide
          </MenuItem>
        </SubMenu>
      );
    }

    return (
      <MenuItem
        onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/reprice/guide?panel=authorization`)}
        className="no-border"
        active={currentSingleTab === "Repricer"}
        icon={<span className="icon-repricer"></span>}
        onClick={() => resetAndNavigate("/reprice/guide?panel=authorization", ['repricerDashboard'])}
      >
        AI Repricer
      </MenuItem>
    );
  };

  const { is_public_warehouse, is_affiliate_manager_worker, is_public_warehouse_worker, is_affiliate_manager } = user || {};
  const allowMobileScreens = is_public_warehouse || is_public_warehouse_worker || is_affiliate_manager || is_affiliate_manager_worker;
  const allowRemovalPages = is_public_warehouse || is_affiliate_manager;

  return (
    <Div>
      <Header login={true} onClick={() => setCollapsed(!collapsed)} ref={headerRef} setMakeBlur={setMakeBlur} />
      <div className="d-flex sidebar-collapsed">
        {show && (
          <ul
            className="context-menu"
            style={{
              top: anchorPoint.y,
              left: anchorPoint.x,
            }}
          >
            <li className="context-menu-item" onClick={() => {
              window.open(linkToOpen, '_blank');
              setShow(false);
            }}
            >
              Open Link In New Tab
            </li>
            <li className="context-menu-item" onClick={() => {
              window.open(linkToOpen, '_blank', 'location=yes,height=650,width=1400,scrollbars=yes,status=yes');
              setShow(false);
            }}
            >
              Open Link In New Window
            </li>
          </ul>
        )}
        {/* <ProSidebar collapsed={collapsed} onMouseOver={() => setCollapsed(false)}>
          <Menu iconShape="square"> */}
        {/* <MenuItem
              active={currentUrl === "/dashboard"}
              className="no-border"
              icon={<span className="icon-admin"></span>}
              onClick={() => resetAndNavigate("/dashboard", ['dashboard'])}
            >
              Dashboard
            </MenuItem> */}

        {/* {isOwner && (
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/admin/user`)}
                active={currentSingleTab === "Admin"}
                className="no-border"
                icon={<span className="icon-admin"></span>}
                onClick={() => resetAndNavigate("/admin/user", ["adminUsers"])}
              >
                Admin Users
              </MenuItem>
            )} */}

        {/* {(user?.is_affiliate_manager || isOwner) && (
              <SubMenu
                title="Affiliate"
                icon={<span className="icon-affliate"></span>}
                open={currentOpenedTab === 'Affiliate'}
                onClick={() => {
                  if (currentOpenedTab === 'Affiliate') {
                    setCurrentOpenedTab('')
                  } else {
                    setCurrentOpenedTab('Affiliate')
                  }
                }}
              >
                <MenuItem
                  onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/affiliate`)}
                  active={currentUrl === "/affiliate"}
                  className="no-border"
                  onClick={() => resetAndNavigate("/affiliate", ['affiliate'])}
                >
                  User
                </MenuItem>
                <MenuItem
                  onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/affiliate/profit-calculation-manager/dashboard`)}
                  active={currentUrl === "/affiliate/profit-calculation-manager/dashboard"}
                  className="no-border"
                  onClick={() => resetAndNavigate("/affiliate/profit-calculation-manager/dashboard", ['profitCalculationManagerDashboard'])}
                >
                  Profit Calculation Manager
                </MenuItem>
                <MenuItem
                  onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/affiliate/profit-calculation-manager/invoices`)}
                  active={currentUrl === "/affiliate/profit-calculation-manager/invoices"}
                  className="no-border"
                  onClick={() => resetAndNavigate("/affiliate/profit-calculation-manager/invoices", ['affiliateInvoices'])}
                >
                  Invoices
                </MenuItem>
                {user?.master_shipment_enabled && (
                  <MenuItem
                    onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/affiliate/account-settings`)}
                    active={currentUrl === "/affiliate/account-settings"}
                    className="no-border"
                    onClick={() => resetAndNavigate("/affiliate/account-settings", ['affiliateAccountSettings'])}
                  >
                    Settings
                  </MenuItem>
                )}
              </SubMenu>
            )} */}

        {/* {!isWarehouseWorker && !isWorker && (
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/vendors`)}
                active={currentSingleTab === "Vendors"}
                className="no-border"
                icon={<span className="icon-vendor_crm"></span>}
                onClick={() => resetAndNavigate("/vendors", ['vendors'])}
              >
                Vendors CRM
              </MenuItem>
            )} */}

        {/* {isAdmin && (
              <MenuItem
                active={currentUrl === "/warehouse-vendors"}
                className="no-border"
                icon={<span className="icon-warehouse"></span>}
                onClick={() => resetAndNavigate("/warehouse-vendors", [])}
              >
                // <Link to="/warehouse-vendors" />
                Warehouse Vendor
              </MenuItem>
            )} */}

        {/* <MenuItem
              onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/catalog-jobs`)}
              active={currentSingleTab === "Catalog"}
              className="no-border"
              icon={<span className="icon-catalog"></span>}
              onClick={() => resetAndNavigate("/catalog-jobs", ['catalogJobs', 'processedCatalogs'])}
            >
              Catalog Analyzer
            </MenuItem>

            <MenuItem
              onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/products`)}
              active={currentSingleTab === "Inventory"}
              className="no-border"
              icon={<span className="icon-inventory"></span>}
              onClick={() => resetAndNavigate("/products", ['products', 'Products', 'pendingPoIndices'])}
            >
              Inventory Tab
            </MenuItem>

            {
              allowMobileScreens &&
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/warehouse-location`)}
                active={currentSingleTab === "InternalStorage"}
                className="no-border"
                icon={<span className="icon-fbm"></span>}
                onClick={() => resetAndNavigate("/warehouse-location", ['internalStorage'])}
              >
                Locations
              </MenuItem>
            } */}

        {/* <MenuItem
              onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/warehouse-location`)}
              active={currentSingleTab === "Locations"}
              className="no-border"
              icon={<span className="icon-fbm"></span>}
              onClick={() => resetAndNavigate("/locations", ['internalStorage'])}
            >
              Internal Storage
            </MenuItem> */}

        {/* {allowMobileScreens &&
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/home`)}
                active={currentSingleTab === "MobileScreen"}
                className="no-border"
                icon={<span className="icon-catalog"></span>}
                onClick={() => resetAndNavigate("/home", ['internalStorage'])}
              >
                Scan Locations
              </MenuItem>
            }

            <MenuItem
              onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/po/dashboard`)}
              active={currentSingleTab === "PO"}
              className="no-border"
              icon={<span className="icon-purchaseOrder" />}
              onClick={() => resetAndNavigate("/po/dashboard", ['uploadPurchaseOrder',
                'simplePurchaseOrders',
                'pendingPurchaseOrders',
                'pendingPoIndices',
                'allOrders',
                'poInvoices',
                'purchasingInvoices',
                'dashboard'])}
            >
              Purchase Orders
            </MenuItem> */}

        {/* {(user?.is_owner_warehouse ||
              user?.email == "mikeakrel@gmail.com") && (
                <MenuItem
                  onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/orders-fulfillment`)}
                  active={currentSingleTab === "Orders Fulfillment"}
                  className="no-border"
                  icon={<span className="icon-order_fullmint"></span>}
                  onClick={() => resetAndNavigate("/orders-fulfillment", ['ordersFulfillment'])}
                >
                  Order Fulfillment
                </MenuItem>
              )}
            {
              isOwner &&
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/3pl-payments`)}
                active={currentSingleTab === "3pl Payments"}
                className="no-border"
                icon={<img src={plPaymentIcon} alt="icon" />}
                onClick={() => resetAndNavigate("/3pl-payments", ['3plPayments'])}
              >
                3PL Payments
              </MenuItem>
            }
            {(user?.email == "mikeakrel@gmail.com" ||
              user?.email == "makapakashopify@gmail.com" ||
              user?.email == "steven@dailydistro.com") && (
                <SubMenu
                  title="Distro"
                  icon={<span className="icon-distibution"></span>}
                  open={currentOpenedTab === 'Distro'}
                  onClick={() => {
                    if (currentOpenedTab === 'Distro') {
                      setCurrentOpenedTab('')
                    } else {
                      setCurrentOpenedTab('Distro')
                    }
                  }}
                >
                  <MenuItem
                    onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/combined-orders`)}
                    active={currentUrl === "/combined-orders"}
                    className="no-border"
                    onClick={() => resetAndNavigate("/combined-orders", ['combinedOrders'])}
                  >
                    List
                  </MenuItem>
                  <MenuItem
                    onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/journal`)}
                    active={currentUrl === "/journal"}
                    className="no-border"
                    onClick={() => resetAndNavigate("/journal", ['journal'])}
                  >
                    Journal
                  </MenuItem>
                  <MenuItem
                    onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/distro-customers`)}
                    active={currentUrl === "/distro-customers"}
                    className="no-border"
                    onClick={() => resetAndNavigate("/distro-customers", ['distroCustomers'])}
                  >
                    Customers
                  </MenuItem>
                </SubMenu>
              )} */}

        {/* {!isWarehouseWorker && !isWorker && (
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/sales`)}
                active={currentSingleTab === "Sales"}
                className="no-border"
                icon={<span className="icon-sales_support"></span>}
                onClick={() => resetAndNavigate("/sales", ['sales'])}
              >
                Sales Report
              </MenuItem>
            )} */}

        {/* {repricerMenuBar()} */}
        {/* <MenuItem
              onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/fba-inbound`)}
              active={currentSingleTab === "FbaInbound"}
              className="no-border"
              icon={<span className="icon-sales_support"></span>}
              onClick={() => resetAndNavigate("/fba-inbound", [''])}
            >
              Fba Inbound
            </MenuItem> */}

        {/* <SubMenu
              title="FBA Inbound [New]"
              icon={<span className="icon-fbaInbound"></span>}
              open={currentOpenedTab === 'FBA'}
              onClick={() => {
                if (currentOpenedTab === 'FBA') {
                  setCurrentOpenedTab('')
                } else {
                  setCurrentOpenedTab('FBA')
                }
              }}
            >
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/fba-inbound`)}
                active={currentUrl === "/fba-inbound"}
                className="no-border"
                onClick={() => resetAndNavigate("/fba-inbound", ['fbaInbound'])}
              >
                Start
              </MenuItem>
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/transport-plans`)}
                active={currentUrl === "/transport-plans"}
                className="no-border"
                onClick={() => resetAndNavigate("/transport-plans", ['shipments'])}
              >
                All Shipments
              </MenuItem>
            </SubMenu> */}

        {/* <SubMenu
              title="FBA Inbound"
              icon={<span className="icon-fbaInbound"></span>}
              open={currentOpenedTab === 'FBA'}
              onClick={() => {
                if (currentOpenedTab === 'FBA') {
                  setCurrentOpenedTab('')
                } else {
                  setCurrentOpenedTab('FBA')
                }
              }}
            >
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/inbound-flow`)}
                active={currentUrl === "/inbound-flow"}
                className="no-border"
                onClick={() => resetAndNavigate("/inbound-flow", ['inboundFlow', 'shipment', 'stagingInventory'])}
              >
                Start
              </MenuItem>
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/transport-plans`)}
                active={currentUrl === "/transport-plans"}
                className="no-border"
                onClick={() => resetAndNavigate("/transport-plans", ['shipments'])}
              >
                All Shipments
              </MenuItem>
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/incomming-pos`)}
                active={currentUrl === "/incomming-pos"}
                className="no-border"
                onClick={() => resetAndNavigate("/incomming-pos", ['incommingPOs'])}
              >
                Arrivals
              </MenuItem>
            </SubMenu> */}

        {/* {!isWarehouseWorker && !isWorker && (
              <SubMenu
                title="Removals"
                icon={<span className="icon-removal"></span>}
                open={currentOpenedTab === 'Removals'}
                onClick={() => {
                  if (currentOpenedTab === 'Removals') {
                    setCurrentOpenedTab('')
                  } else {
                    setCurrentOpenedTab('Removals')
                  }
                }}
              >
                {allowRemovalPages && (
                  <MenuItem
                    onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/fba-removal-shipments/search`)}
                    active={currentUrl === "/fba-removal-shipments/search"}
                    className="no-border"
                    onClick={() => resetAndNavigate("/fba-removal-shipments/search", ['fbaremovalshipments'])}
                  >
                    Search Removal
                  </MenuItem>
                )}
                {allowRemovalPages && (
                  <MenuItem
                    onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/received-removal-orders`)}
                    active={currentUrl === "/received-removal-orders"}
                    className="no-border"
                    onClick={() => resetAndNavigate("/received-removal-orders", ['masterRemovalOrders'])}
                  >
                    All Removals
                  </MenuItem>
                )}
                <MenuItem
                  onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/user-removals`)}
                  active={currentUrl === "/user-removals"}
                  className="no-border"
                  onClick={() => resetAndNavigate("/user-removals", ['userRemovals'])}
                >
                  Users Removals
                </MenuItem>
              </SubMenu>
            )}
            <SubMenu
              title="FBM"
              icon={<span className="icon-fbm"></span>}
              open={currentOpenedTab === 'FBM'}
              onClick={() => {
                if (currentOpenedTab === 'FBM') {
                  setCurrentOpenedTab('')
                } else {
                  setCurrentOpenedTab('FBM')
                }
              }}
            >
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/fbm`)}
                active={currentUrl === "/fbm"}
                className="no-border"
                onClick={() => resetAndNavigate("/fbm", [])}
              >
                Dashboard
              </MenuItem>
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/fbm/orders`)}
                active={currentUrl === "/fbm/orders"}
                className="no-border"
                onClick={() => resetAndNavigate("/fbm/orders", [''])}
              >
                Orders
              </MenuItem>
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/fbm/orders/fulfillment`)}
                active={currentUrl === "/fbm/orders/fulfillment"}
                className="no-border"
                onClick={() => resetAndNavigate("/fbm/orders/fulfillment", [])}
              >
                Fulfillment
              </MenuItem>
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/fbm/settings`)}
                active={currentUrl === "/fbm/settings"}
                className="no-border"
                onClick={() => resetAndNavigate("/fbm/settings", [])}
              >
                Settings
              </MenuItem>
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/fbm/wallet`)}
                active={currentUrl === "/fbm/wallet"}
                className="no-border"
                onClick={() => resetAndNavigate("/fbm/wallet", [])}
              >
                Wallet
              </MenuItem>
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/fbm/lister`)}
                active={currentUrl === "/fbm/lister"}
                className="no-border"
                onClick={() => resetAndNavigate("/fbm/lister", [])}
              >
                Lister
              </MenuItem>
            </SubMenu>

            <SubMenu
              title="Settings"
              icon={<span className="icon-setting"></span>}
              open={currentOpenedTab === 'Settings'}
              onClick={() => {
                if (currentOpenedTab === 'Settings') {
                  setCurrentOpenedTab('')
                } else {
                  setCurrentOpenedTab('Settings')
                }
              }}
            >
              {!isWorker && (
                <MenuItem
                  onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/users`)}
                  active={currentUrl === "/users"}
                  className="no-border"
                  onClick={() => resetAndNavigate("/users", ['workers'])}
                >
                  Users
                </MenuItem>
              )}
              <MenuItem
                onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/account`)}
                active={currentUrl === "/account"}
                className="no-border"
                onClick={() => resetAndNavigate("/account", ['account'])}
              >
                Account
              </MenuItem>
              {!isWorker && (
                <MenuItem
                  onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/payments`)}
                  active={currentUrl === "/payments"}
                  className="no-border"
                  onClick={() => resetAndNavigate("/payments", ['payment', 'affiliateAccountSettings'])}
                >
                  Payment
                </MenuItem>
              )}
            </SubMenu>

            {!isWarehouseWorker && !isWorker && (
              <SubMenu
                title="Invoices"
                icon={<span className="icon-invoices"></span>}
                open={currentOpenedTab === 'Invoices'}
                onClick={() => {
                  if (currentOpenedTab === 'Invoices') {
                    setCurrentOpenedTab('')
                  } else {
                    setCurrentOpenedTab('Invoices')
                  }
                }}
              > */}
        {/* {isAdmin &&
                  <MenuItem active={currentUrl === "/invoices"} className="no-border">
                    <Link to="/invoices" />Warehouse
                  </MenuItem>
                } */}
        {/* {(isAdmin || user?.master_shipment_enabled) && (
                  <MenuItem
                    onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/invoices`)}
                    active={currentUrl === "/invoices"}
                    className="no-border"
                    onClick={() => resetAndNavigate("/invoices", ['customerInvoices', 'affiliateAccountSettings'])}
                  >
                    Shipment [Master]
                  </MenuItem>
                )} */}
        {/* {isNotSpecialUser && ( */}
        {/* <MenuItem
                  onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/user-invoices`)}
                  active={currentUrl === "/user-invoices"}
                  className="no-border"
                  onClick={() => resetAndNavigate("/user-invoices", ['userInvoices', 'affiliateAccountSettings'])}
                >
                  Shipment
                </MenuItem> */}
        {/* )} */}
        {/* {isAdmin && (
                  <MenuItem
                    onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/master-removal-invoices`)}
                    active={currentUrl === "/master-removal-invoices"}
                    className="no-border"
                    onClick={() => resetAndNavigate("/master-removal-invoices", ['masterRemovalInvoices'])}
                  >
                    Removals [Master]
                  </MenuItem>
                )} */}
        {/* {isNotSpecialUser && ( */}
        {/* <MenuItem
                  onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/removal-invoices`)}
                  active={currentUrl === "/removal-invoices"}
                  className="no-border"
                  onClick={() => resetAndNavigate("/removal-invoices", ['removalInvoices'])}
                >
                  Removals
                </MenuItem> */}
        {/* )} */}
        {/* <MenuItem
                  onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/subscription-invoices`)}
                  active={currentUrl === "/subscription-invoices"}
                  className="no-border"
                  onClick={() => resetAndNavigate("/subscription-invoices", ['subscriptionInvoices'])}
                >
                  Subscription
                </MenuItem>
                {isAdmin && (
                  <MenuItem
                    onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/two-step-master-invoices`)}
                    active={currentUrl === "/two-step-master-invoices"}
                    className="no-border"
                    onClick={() => resetAndNavigate("/two-step-master-invoices", ['twoStepInvoices'])}
                  >
                    Two Steps [Master]
                  </MenuItem>
                )} */}
        {/* {isNotSpecialUser && ( */}
        {/* <MenuItem
                  onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/two-step-invoices`)}
                  active={currentUrl === "/two-step-invoices"}
                  className="no-border"
                  onClick={() => resetAndNavigate("/two-step-invoices", ['twoStepInvoices'])}
                >
                  Two Steps
                </MenuItem> */}
        {/* )} */}
        {/* {user?.is_warehouse_owner_admin && (
                  <MenuItem
                    onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/master-storage-invoices`)}
                    active={currentUrl === "/master-storage-invoices"}
                    className="no-border"
                    onClick={() => resetAndNavigate("/master-storage-invoices", ['masterStorageInvoices'])}
                  >
                    Storage [Master]
                  </MenuItem>
                )} */}
        {/* {isNotSpecialUser && ( */}
        {/* <MenuItem
                  onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/storage-invoices`)}
                  active={currentUrl === "/storage-invoices"}
                  className="no-border"
                  onClick={() => resetAndNavigate("/storage-invoices", ['userStorageInvoices'])}
                >
                  Storage
                </MenuItem> */}
        {/* )} */}
        {/* {isAdmin && (
                  <MenuItem
                    onContextMenu={(event) => handleContextMenu(event, `${window.location.origin}/invoices/settings`)}
                    active={currentUrl === "/invoices/settings"}
                    className="no-border"
                    onClick={() => resetAndNavigate("/invoices/settings", [])}
                  >
                    Settings
                  </MenuItem>
                )}
              </SubMenu>
            )}
          </Menu>
        </ProSidebar> */}

        <Content
          //  style={{ height: "calc(100vh)" }} 
          // onMouseOver={() => {
          //   setCollapsed(true);
          //   setShow(false);
          // }}
          onClick={() => headerRef.current.closeMenu()}
          makeBlur={makeBlur}
        >

          {isFBMPages ?
            (
              <div
                style={{ height: `calc(100vh - ${path.includes("fba-inbound") ? "108px" : "85px"})` }}
                className={collapsed ? "collapsed" : "collapsed-none"}
              >
                {props.currentComponent}
              </div>
            ) :
            (
              <div
                style={{ height: "calc(100vh - 85px)", marginTop: '-25px' }}
                className={collapsed ? "collapsed" : "collapsed-none"}
              >
                {props.currentComponent}
              </div>
            )
          }
        </Content>


      </div>
      {
        showTrialPopup ? <Box position="absolute" top="2px" left="35%">
          <TrailPopover
            trialEndDate={user?.trial_end_date}
            setShowTrialPopup={setShowTrialPopup}
            onClick={() => {
              resetAndNavigate("/payments", ['payment', 'affiliateAccountSettings'])
              setShowTrialPopup(false);
            }}
          />
        </Box> : ""
      }
    </Div>
  );
}

export default Layout;
