import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Select, Button, Input, Spin, DatePicker, notification } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { FaMinus, FaPlus } from "react-icons/fa";
import { BsTrash } from "react-icons/bs";

import SelectCarrier from "../../../components/uiComponent/select/index";

import BoxPack from "../../../assets/images/box-pack-icon.svg";
import {
  GetVendors,
  GeneratePoCode,
  setFbaShipmentState,
  CreateNewVendor,
  ValidatePoCode
} from "../../../slices/fba-shipment";

import { AddPurchaseOrderStyle } from "../style";

import { nonPartneredCarriers } from "../../fbaInbound/constants";

const AddPurchaseOrder = ({
  draft_po,
  setDraftPo,
  visible,
  setNewDate,
  newDate,
  setCarrier,
  carrier,
  setTrackingError,
  trackingError
}) => {
  const selectRef = useRef()
  const dispatch = useDispatch();
  const {
    loading,
    vendorsList,
    poName,
    generatePoCodeLoading,
    newVendorLoading
  } = useSelector((store) => store.fbaShipment);

  const [searchedVendor, setSearchedVendor] = useState('');
  const [open, setOpen] = useState(false);
  const [poCode, setPoCode] = useState('');

  useEffect(() => {
    if (visible) {
      setNewDate({
        carrier_name: "",
        delivery_date: "",
        tracking_info: ""
      });
      setCarrier('');
      setTrackingError({});
    }
  }, [visible]);

  const handleIncrement = () => {
    setDraftPo({
      ...draft_po,
      delivery_discrepancy_window: (draft_po?.delivery_discrepancy_window || 0) + 1
    })
  };

  useEffect(() => {
    dispatch(GetVendors());
    setDraftPo({
      ...draft_po,
      delivery_discrepancy_window: draft_po?.delivery_discrepancy_window || 10
    })
  }, []);

  useEffect(() => {
    if (poName) {
      setDraftPo({
        ...draft_po,
        po_name: poName
      });
      dispatch(setFbaShipmentState({ field: 'poName', value: '' }));
    }
  }, [poName]);

  const handleDecrement = () => {
    if ((draft_po?.delivery_discrepancy_window || 0) - 1 <= 0) return
    setDraftPo({
      ...draft_po,
      delivery_discrepancy_window: (draft_po?.delivery_discrepancy_window || 0) - 1
    })
  };

  const handleCreateVendorClick = (e) => {
    e.stopPropagation();
    if (searchedVendor) {
      dispatch(CreateNewVendor(searchedVendor)).then(({
        payload
      }) => {
        if (payload?.vendor) {
          dispatch(GetVendors());
          setDraftPo({
            ...draft_po,
            vendor_id: payload?.vendor?.id
          });
          setOpen(false);
        }
      });
    }
  };

  const handleAddButtonClick = () => {
    let error = '';
    const ifExists = draft_po?.delivery_informations_attributes?.findIndex(({ tracking_info, _destroy }) => {
      return !_destroy && newDate?.tracking_info.trim() === tracking_info.trim();
    });

    if (ifExists >= 0) error = `Tracking id "${newDate?.tracking_info}" already exists`;
    else if (!newDate?.tracking_info || !newDate?.delivery_date) error = 'Please Add Tracking Info and Delivery Date';

    if (error) {
      notification.error({
        message: "Add New Delivery Date",
        description: error,
        top: 65
      });
      return;
    }

    setDraftPo({
      ...draft_po,
      delivery_informations_attributes: [
        ...(draft_po?.delivery_informations_attributes || []),
        {
          carrier_name: newDate?.carrier_name.trim(),
          delivery_date: newDate?.delivery_date.trim(),
          tracking_info: newDate?.tracking_info.trim()
        }
      ]
    });
    setNewDate({
      carrier_name: "",
      delivery_date: "",
      tracking_info: ""
    });
    setTrackingError({});
    setCarrier('');
  };

  const handleDeleteEntry = (trackingInfo) => {
    const newData = [...draft_po?.delivery_informations_attributes];
    const index = newData?.findIndex(({ tracking_info, _destroy }) => !_destroy && tracking_info === trackingInfo);

    newData[index] = {
      ...newData[index],
      _destroy: true
    };
    setDraftPo({
      ...draft_po,
      delivery_informations_attributes: [...newData]
    });
  };

  useEffect(() => {
    const handleChangePo = setTimeout(() => {
      if (poCode) {
        dispatch(ValidatePoCode({
          po_name: poCode.trim()
        })).then(({ payload }) => {
          if (!payload?.po_name) {
            setDraftPo({
              ...draft_po,
              po_name: ''
            });
            setPoCode("");
          }
        });
      }
    }, 1300);

    return () => clearTimeout(handleChangePo)
  }, [poCode]);

  const Option = Select.Option;
  return (
    <Spin tip='Loading...' spinning={loading}>
      <AddPurchaseOrderStyle>
        <div className="create-item-wrapper">
          <div className="create-item">
            <Select
              allowClear
              showSearch
              ref={selectRef}
              open={open}
              getPopupContainer={trigger => trigger.parentNode}
              className="select-wrapper"
              placeholder="Search a Vendor by Name"
              optionFilterProp="children"
              value={draft_po?.vendor_id ? vendorsList?.find((v) => v.id === draft_po?.vendor_id)?.id || undefined : undefined}
              onSearch={(value) => {
                setSearchedVendor(value)
              }}
              onChange={(vendor_id) => {
                setDraftPo({
                  ...draft_po,
                  vendor_id: vendor_id || '',
                  ship_from_address_id: ''
                });
                dispatch(setFbaShipmentState({ field: 'vendorAddresses', value: [] }));
                selectRef.current.blur();
              }}
              filterOption={(input, option) =>
                option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              }
              onFocus={() => setOpen(true)}
              onBlur={() => setOpen(false)}
            >
              {vendorsList.map(({ id, name }) => (
                <Option value={id} key={id}>
                  {name}
                </Option>
              ))}
            </Select>
            <Spin tip="Loading..." spinning={newVendorLoading}>
              <Button
                disabled={vendorsList?.find(({ name }) => name?.toLocaleLowerCase()?.includes(searchedVendor?.toLocaleLowerCase()))}
                onClick={handleCreateVendorClick}
                onMouseDown={(e) => e.preventDefault()}
              >Create New Vendor</Button>
            </Spin>
          </div>
          <div className="create-item">
            <Input
              placeholder="Add PO name"
              value={draft_po?.po_name}
              onChange={(e) => {
                setDraftPo({
                  ...draft_po,
                  po_name: e.target.value?.trimStart()
                });
                setPoCode(e.target.value?.trimStart());
              }} />
            <Spin tip='Loading...' spinning={generatePoCodeLoading}>
              <Button onClick={() => dispatch(GeneratePoCode())}>Generate PO Name</Button>
            </Spin>
          </div>
          <div className="discrepency-wrapper">
            <p>Discrepancy Window from Delivery</p>
            <div className="button-increase-wrapper">
              <Button onClick={handleDecrement}>
                <FaMinus />
              </Button>
              <span>{draft_po?.delivery_discrepancy_window} Hours</span>
              <Button onClick={handleIncrement}>
                <FaPlus />
              </Button>
            </div>
          </div>
        </div>
        <div className="text-container">
          <div className="text-item">
            <p>
              You can fill the fields below at the end of Creating a New Order
            </p>
            <p>
              If you dont add this info at the end, the warehouse will be confused
              and your package may get lost
            </p>
          </div>
          <img src={BoxPack} />
        </div>
        <div className="delivery-row-wrapper">
          <div className="track-info-wrapper position-relative">
            <p>Tracking info</p>
            <Input
              style={trackingError?.tracking_info ? { borderColor: 'red' } : {}}
              value={newDate?.tracking_info}
              onChange={(e) => {
                setNewDate({
                  ...newDate,
                  tracking_info: e.target.value?.trimStart()
                });
                setTrackingError({ ...trackingError, tracking_info: '' });
              }}
              placeholder="Add or edit tracking info"
            />
            {trackingError?.tracking_info ? <span
              style={{ color: 'red', position: 'absolute', bottom: '-24px' }}
            >{trackingError?.tracking_info}</span> : ''}
          </div>
          <div className="track-info-wrapper">
            <p>Carrier</p>
            <SelectCarrier
              placeholder="Select Carrier"
              width="165px"
              isClearable
              marginBottom="0px"
              value={carrier}
              options={nonPartneredCarriers}
              onChange={(event) => {
                setCarrier(event);
                setNewDate({
                  ...newDate,
                  carrier_name: event?.value || ''
                });
              }}
            />
          </div>
          <div className="estimate-ui w-100 position-relative">
            <p>Estimated Delivery Date</p>
            <DatePicker
              value={newDate?.delivery_date ? moment(newDate?.delivery_date) : ''}
              disabledDate={(current) => {
                return moment().add(-1, 'days') >= current
              }}
              format="DD MMMM YYYY"
              className={`custom-date-picker ${trackingError?.delivery_date ? 'error-tracking' : ''}`}
              onChange={(date, dateString) => {
                setNewDate({
                  ...newDate,
                  delivery_date: dateString
                });
                setTrackingError({ ...trackingError, delivery_date: '' });
              }}
            />
            {trackingError?.delivery_date ? <span
              style={{ color: 'red', position: 'absolute', bottom: '-21px' }}
            >{trackingError?.delivery_date}</span> : ''}
          </div>
          <Button className="add-button" onClick={handleAddButtonClick}>
            <FaPlus />
            <span className="add-button-text">Add More</span>
          </Button>
        </div>
        <div className="fix-height-delivery-date">
          {draft_po?.delivery_informations_attributes?.length > 0 && (
            <div>
              {draft_po?.delivery_informations_attributes?.filter(({ _destroy }) => !_destroy)
                ?.map((data, index) => (
                  <div className="tracking-info-inner-wrapper" key={index}>
                    <p className="tracking-info-line">{data.tracking_info}</p>
                    <p className="tracking-info-line">{data.carrier_name || 'N/A'}</p>
                    <p className="tracking-info-date">{moment(data.delivery_date).format('DD MMMM YYYY')}</p>
                    <button className="delete-button" onClick={() => handleDeleteEntry(data.tracking_info)}>
                      <BsTrash color="#CF0909" />
                    </button>
                  </div>
                ))}
            </div>
          )}
        </div>
      </AddPurchaseOrderStyle>
    </Spin>
  );
};

export default AddPurchaseOrder;
