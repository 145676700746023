import Styled from 'styled-components';

const HomePageWrapper = Styled.div`

.blue-back, .blue-back .blue-back-color {
    border-radius: 0px;
    height: 114vh;
    position: relative;
}
.pro-sold-section {
  /* float: left; */
  width: 100%;
  background: linear-gradient(119.68deg, #39CAF9 42.88%, #8BE3FF 76.39%);
  padding: 2.865vw 0vw 3.438vw 0vw;
  margin-top: 12vw;
  clear:both;
  &.float-none{
    float: none
  }

}
.pro-sold-section .sold-btn {
  border-radius: 444px;
  font-weight: 600;
  font-size: 0.833vw;
  line-height: 22px;
  text-transform: uppercase;
  color: #FFFFFF;
  padding: 0.365vw 1.2vw;
  display: inline-block;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
}
.pro-sold-section .sold-btn:hover {
  opacity: 0.9;
}
.pro-sold-section .amazon-section {
  display: grid;
  grid-template-columns: 1fr 16.563vw;
  grid-gap: 7.813vw;
  padding-top: 0.26vw;
  &.layout-change{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 20px;
  }
}
.pro-sold-section .amazon-section .kick-amazon {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 44px;
  color: #FFFFFF;
}
.pro-sold-section .amazon-section .try a {
  background: #4E70E8;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(78, 112, 232, 0.12), 0px 16px 24px rgba(78, 112, 232, 0.2);
  border-radius: 4px;
  font-weight: 600;
  padding: 0.885vw 3.542vw;
  min-width: 15.625vw;
  font-size: 0.938vw;
  line-height: 1.302vw;
  text-transform: uppercase;
  color: white;
  border: 0;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  display: block;
  text-align: center;
}
.pro-sold-section .amazon-section .threepl button{
  background: #4E70E8;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(78, 112, 232, 0.12), 0px 16px 24px rgba(78, 112, 232, 0.2);
  border-radius: 4px;
  font-weight: 600;
  padding: 15px;
  margin-bottom: 5px;
  min-width: 230px;
  font-size: 14px;
  line-height: 1.302vw;
  text-transform: uppercase;
  color: white;
  border: 0;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  display: block;
  text-align: center;
}
.pro-sold-section .amazon-section .try a:hover, .pro-sold-section .amazon-section .try a:focus {
  opacity: 0.9;
}
.esential-tools .essential-text {
  width: 100%;
  max-width: 410px;
  float: left;
  padding-top: 25px;
}
.esential-tools .essential-text {
    padding-right: 15px;
  }
  .esential-tools .essential-text {
    max-width: 100%;
    padding-right: 0px;
  }

}
.esential-tools .essential-text {
  width: calc(50% - 3%);
  float: left;
  padding-top: 25px;
}
.Homepage-innerVideo {
    width: 700px;
    height: 500px;
}
.blue-back {
  border-radius: 0px;
  height: 120vh;
  position: relative;
  &:after {
    background: linear-gradient(100.98deg, #39CAF9 45.46%, #8BE3FF 77.46%);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 69%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 69%, 0% 100%);
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    z-index: -1;
  }
  .blue-back-color{
    height: 80vh;
  }
  .custom-container {
    height: calc(100% - 150px);
  }
}

.btn-wrapper{
    background: #4E70E8;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(78, 112, 232, 0.12), 0px 16px 24px rgba(78, 112, 232, 0.2);
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: uppercase;
  padding: 17px 68px;
  color: white;
  border: 0;
  min-width: 347px;
  -webkit-transition: background-color .3s ease-out;
  -moz-transition: background-color .3s ease-out;
  -o-transition: background-color .3s ease-out;
  transition: background-color .3s ease-out;
  &:hover, &:focus {
    opacity: .9;
  }
}
.business-amazon {
  display: grid;
  min-height: 100%;
  align-items: center;
  .right-content {
    width: 46%;
    float: left;
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr;
    place-content: center;
    .title {
      font-size: 28px;
      line-height: 38px;
      color: white;
      padding: 20px 0px;
    }
    .info {
      font-size: 18px;
      color: white;
      line-height: 25px;
      padding-bottom: 20px;
    }
    .btn-wrapper {
      button {
      }
    }
    .btn-three{
      button{
        padding: 17px;
        margin-bottom: 5px;
      }
    }
  }
}
.homepage-video {
    margin-top: 37px;
}
  .amazon-screens {
    position: absolute;
    right: 0;
    bottom:218px;
    .screen1 {
      float: left;
      position: relative;
      top: 130px;
    }
    .screen2 {
      float: left;
    }
  }
  .custom-container {
  max-width: 1095px;
  width: 100%;
  padding: 0px 15px;
  margin: 0 auto;
  box-sizing: border-box;
}
.right-section .text-section, .left-section .text-section {
  display: flex;
  flex-direction: column;
  min-height: 32vw;
  justify-content: center;
}
.right-section .text-section .support-text, .left-section .text-section .support-text {
  width: calc(65% - 2%);
}
.right-section .heading-icon, .left-section .heading-icon {
  position: relative;
  left: -5px;
  bottom: -5px;
}
.right-section .section-outer, .left-section .section-outer {
  position: relative;
  padding-top: 0%;
}
.right-section .section-outer .support-screen, .left-section .section-outer .support-screen {
  width: 38vw;
  position: relative;
}
.right-section .section-outer .support-screen img, .left-section .section-outer .support-screen img {
  width: 100%;
}
.right-section .section-outer .support-screen.f-right, .left-section .section-outer .support-screen.f-right {
  float: right;
}
.right-section .section-outer .support-screen.f-left, .left-section .section-outer .support-screen.f-left {
  float: left;
}
.left-section .section-outer .support-screen {
  left: 0px;
}
.right-section, .left-section {
  float: left;
  width: 100%;
}
.left-section .text-section {
    justify-content: center;
    align-items: flex-end;
}
.free-call-link{
    margin-top: 10px;
    text-decoration: underline;
    font-size: 24px;
}
.esential-tools {
  position: relative;
  padding-bottom: 6.5%;
    float: left;
    width: 100%;
  .esential-amazon {
    position: absolute;
    right: 0;
    top: -210px;
    .screen1 {
      float: left;
      position: relative;
      top: 92px;
    }
    .screen2 {
      float: left;
    }
  }
  .essential-text {
    width: 100%;
    max-width: 410px;
    float: left;
    padding-top: 25px;
  }
}

.how-it-works {
  float: left;
  width: 100%;
  padding: 30px 0px 40px 0px;
  h2 {
    text-align: center;
  }
  ol {
    margin: 0px;
    padding: 0px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    li {
      list-style: none;
      padding: 33px 25px 33px 40px;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04);
      border-radius: 4px;
      display: grid;
      grid-template-columns: 60px 1fr 140px;
      counter-increment: my-awesome-counter;
      position: relative;
      align-items: center;
      &::before {
        content: counter(my-awesome-counter);
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
        position: absolute;
        background: black;
        border-radius: 50%;
        text-align: center;
        box-shadow: 1px 1px 0 #999;
        width: 45px;
        height: 45px;
        left: 40px;
        top: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
      }
      &:nth-child(1) {
        &:before {
          background: #FC9739;
          box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(252, 151, 57, 0.12), 0px 10px 20px rgba(252, 151, 57, 0.18);
        }
      }
      &:nth-child(2) {
        &:before {
          background: #DA66DC;
          box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(218, 102, 220, 0.12), 0px 10px 20px rgba(218, 102, 220, 0.18);
        }
      }
      &:nth-child(3) {
        &:before {
          background: #51C733;
          box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(81, 199, 51, 0.12), 0px 10px 20px rgba(81, 199, 51, 0.18);
        }
      }
      &:nth-child(4) {
        &:before {
          background: #F7BE2D;
          box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(247, 190, 45, 0.12), 0px 10px 20px rgba(247, 190, 45, 0.18);
        }
      }
      &:nth-child(5) {
        &:before {
          background: #39CCFC;
          box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(57, 204, 252, 0.12), 0px 10px 20px rgba(57, 204, 252, 0.18);
        }
      }
      .count {

      }
      .works-info {
        .title {
          font-size: 16px;
          line-height: 22px;
        }
        p {
          margin-bottom: 0;
        }
      }
      .learn-more {
        button {
          width: 100%;
        }
      }
    }
  }
  .try-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 42px;
  }
}
.header-button{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media(max-width:768px){
        justify-content:center;
        flex-direction: column;
      }
      .threepl-video{
  height: 321px;
  width: 570px;
  @media(max-width:991px){
    height: 266px;
    width: 461px;
  }
  @media(max-width:575px){
    height: 247px;
    width: 440px;
  }
  @media(max-width:475px){
    height: 196px;
    width: 350px;
  }
  @media(max-width:375px){
    height: 177px;
    width: 300px;
  }
}
}
.threepl-sections{
  .section-outer{
    display:flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    flex-wrap:wrap;
    @media(max-width:1226px){
        flex-direction: column;
    }
    @media(max-width:1226px){
    &.col-rev{
      flex-direction: column-reverse;
    }
  }
  }
  .custom-container{
    max-width: 500px;
  }
}
.lowest-pricing {
  background: #F1F4F6;
  clip-path: polygon(0 0%, 100% 30%, 100% 100%, 0% 100%);
  .mw-1300{
    max-width:1300px;
    margin:0 auto;
  }
  .py-80{
    padding: 122px 0;
    @media (max-width:991px){
      padding: 180px 0 0 0;
    }
    @media (max-width:475px){
      padding: 210px 0 0 0;
    }
  }
  .py-120{
    padding: 150px 100px 0 9px;
    margin-bottom: 90px;
    @media (max-width:991px){
      padding: 150px 0 0 9px;
    }
  }
  .reasonable-price{
    background: linear-gradient(43.4deg, #92E84E 0%, #F8D939 115.68%);
    text-align: center;
    img{
      position: relative;
      top: -50px;
    }
    .p-17{
      padding: 17px 0;
      margin: -49px 0;
    }
    .label-box{
      background:#9CAE2A;
      border-radius:50px;
      height:75px;
      width:75px;
      margin: 0 auto;
    }
    .pricing{
      color:#fff
    }
  }
}
.custom-container-footer{
    max-width: 1095px;
    width: 100%;
    padding: 0px 15px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  /*=====  End of Sales Form  ======*/

footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: #99A0A3;
  grid-template-rows: 60px;
  align-items: center;
  padding:30px 0;
  .all-rights {

  }
  .question {
    text-align: right;
    span {
      padding-left: 20px;
      a {
        text-transform: uppercase;
        color: #99A0A3;
        &:hover {
          color: #4E70E8;
        }
      }
    }
  }
}
footer .footer-link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
footer .footer-link a {
  color: #99A0A3;
}
footer .footer-link a:hover {
  color: #4E70E8;
}
footer .footer-link .contact-link {
  text-decoration: underline;
  margin-left: 20px;
}
.pro-sold-section .sold-btn{
  background: #F6BE2D;
    border-radius: 444px;
    font-weight: 600;
    font-size: 0.833vw;
    line-height: 22px;
    text-transform: uppercase;
    text-decoration:none;
    color: #FFFFFF;
    padding: 0.365vw 1.2vw;
    display: inline-block;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    transition: 0.5s;
}
  `;
export { HomePageWrapper };
