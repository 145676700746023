import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetch as axios } from '../../axios_config';
import { handleRejectedResponse } from './utils';
import { awsAxios } from '../../axios_config';

import { notification } from 'antd';

export const AddPurchaseOrder = createAsyncThunk(
  '/add-purchase-order',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.API_BASE_URL}api/v2/draft_pos`,
        data
      })
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Create Purchase Order', rejectWithValue);
    }
  }
);

export const SaveVendorAddress = createAsyncThunk(
  '/save-vendor-address',
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.API_BASE_URL}/api/v2/addresses/create_vendor_address`, null, { params });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Save Vendor Address', rejectWithValue);
    }
  }
);

export const GetWarehouseAddress = createAsyncThunk(
  '/save-warehouse-address',
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}/api/v2/addresses/${id}/list_all_addresses`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Save Vendor Address', rejectWithValue);
    }
  }
);

export const CreateWarehouseAddress = createAsyncThunk(
  '/create-warehouse-address',
  async (address, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.API_BASE_URL}/api/v2/addresses/create_company_address`, address);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Save Vendor Address', rejectWithValue);
    }
  }
);

export const ConfirmPo = createAsyncThunk(
  '/confirm-purchase-order',
  async ({ poId }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.API_BASE_URL}api/v2/draft_pos/${poId}/confirm_draft`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Confirm Po', rejectWithValue);
    }
  }
);

export const UpdatePendingPos = createAsyncThunk(
  '/update-pending-pos',
  async ({ draft_po, poId, stopSuccess }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.patch(`${process.env.API_BASE_URL}api/v2/draft_pos/${poId}`, { draft_po });
      return { ...response.data, stopSuccess };
    } catch (err) {
      return handleRejectedResponse(err, 'Update Pending POs', rejectWithValue);
    }
  }
);

export const UpdateCompanyAddress = createAsyncThunk(
  '/update-company-address',
  async ({ address, id }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `/api/v2/addresses/${id}`,
        null,
        {
          params: {
            address
          }
        }
      );
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Company Addresses', rejectWithValue);
    }
  }
);


export const UpdateQuantity = createAsyncThunk(
  '/update-quantity',
  async ({ params, id }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.patch(`${process.env.API_BASE_URL}/api/v2/purchase_orders/${id}`,
        null,
        { params }
      );

      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Quantity', rejectWithValue);
    }
  }
);

export const UpdateCost = createAsyncThunk(
  '/update-cost',
  async ({ params, id }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.API_BASE_URL}api/v2/purchase_orders/${id}/edit_item`,
        null,
        { params }
      );
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Cost', rejectWithValue);
    }
  }
);

export const CreateInventoryListing = createAsyncThunk(
  '/create-inventory-listing',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.API_BASE_URL}api/v2/draft_pos/create_individual_listings`,
        data
      })
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Create Listing', rejectWithValue);
    }
  }
);

export const CreateListing = createAsyncThunk(
  '/create-listing',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.API_BASE_URL}api/v2/feeds`,
        data
      })
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Create Listing', rejectWithValue);
    }
  }
);

export const AddGatedDetail = createAsyncThunk(
  '/add-gated-detail',
  async (data, { getState, rejectWithValue }) => {
    try {
      const newData = {
        pos: data
      }
      const response = await axios({
        method: 'POST',
        url: `${process.env.API_BASE_URL}api/v2/pos/add_gated_details`,
        data: newData
      })
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Add Gated Detail', rejectWithValue);
    }
  }
);

export const CreateNewVendor = createAsyncThunk(
  '/create-new-vendor',
  async (name, { getState, rejectWithValue }) => {
    try {
      const newVendor = {
        vendor: { name }
      }
      const response = await axios({
        method: 'POST',
        url: `${process.env.API_BASE_URL}api/v2/vendors`,
        data: newVendor
      })
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Create New Vendor', rejectWithValue);
    }
  }
);

export const GetVendors = createAsyncThunk(
  '/get-vendors',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.API_BASE_URL}api/v2/draft_pos/vendor_dropdown`,
        data
      })
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Vendors', rejectWithValue);
    }
  }
);

export const GeneratePoCode = createAsyncThunk(
  '/generate-po-code',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}api/v2/draft_pos/generate_po_name`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Generate PO', rejectWithValue);
    }
  }
);

export const ValidatePoCode = createAsyncThunk(
  '/validate-po-code',
  async ({ po_name }, { getState, rejectWithValue }) => {
    try {
      const encodedPoName = encodeURIComponent(po_name);
      const response = await axios.get(`${process.env.API_BASE_URL}api/v2/draft_pos/validate_po_name?po_name=${encodedPoName}`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Generate PO', rejectWithValue);
    }
  }
);

export const ProductsList = createAsyncThunk(
  '/products-list',
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}api/v2/seller_skus/inventory_dropdown`, { params });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Product Listing', rejectWithValue);
    }
  }
);

export const IsValidateAsin = createAsyncThunk(
  '/validate-asin',
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}api/v2/vendor_asins/validate_asin`, { params })
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Validate Asin', rejectWithValue);
    }
  }
);

export const GetVendorsAddresses = createAsyncThunk(
  '/get-vendor-addresses',
  async (vendorId, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}api/v2/addresses/${vendorId}/vendor_addresses`)
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Vendor Address', rejectWithValue);
    }
  }
);

export const GetGraftPo = createAsyncThunk(
  '/get-draft-po',
  async ({ poId, per_page, page, filters }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}api/v2/draft_pos/${poId}/with_pos`, {
        params: { per_page, page, filters }
      })
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Draft PO', rejectWithValue);
    }
  }
);

export const GetGatedPos = createAsyncThunk(
  '/get-gated-po',
  async ({ poId, filters }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}api/v2/draft_pos/${poId}/with_pos`, {
        params: { filters }
      })
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Draft PO', rejectWithValue);
    }
  }
);

export const GetTopStats = createAsyncThunk(
  '/get-top-stats',
  async ({ poId }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}api/v2/draft_pos/${poId}/stats`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Top Stats', rejectWithValue);
    }
  }
);

export const ResetListing = createAsyncThunk(
  '/reset-listing',
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}api/v2/feeds/${id}/restart_listing`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Restart Listing', rejectWithValue);
    }
  }
);

export const GetCompanyAddress = createAsyncThunk(
  '/get-company-address',
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}api/v2/addresses/${id}/company_addresses`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Company Addresses', rejectWithValue);
    }
  }
);

export const GetShipToAddress = createAsyncThunk(
  '/get-Ship-to-address',
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}/api/v2/addresses/${id}`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Ship To Address', rejectWithValue);
    }
  }
);

export const UploadFileOnS3 = createAsyncThunk(
  '/upload-file-on-s3',
  async ({ fileData, fileName }, { getState, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`${process.env.API_BASE_URL}/api/v2/pos/presiged_url`, {
        params: {
          file_name: fileName
        }
      });

      const options = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      const { url } = data;
      const signedRequest = url;

      await awsAxios.put(signedRequest, JSON.stringify(fileData), options);
      return { url };
    } catch (err) {
      return handleRejectedResponse(err, 'Upload File On S3', rejectWithValue);
    }
  }
);

export const SaveFileName = createAsyncThunk(
  '/save-file-name',
  async ({ fileName, po_name, presignedUrl, draft_po_id }, { getState, rejectWithValue }) => {
    try {
      const splittedPreSignedUrl = presignedUrl.split("/")
      const slicedPreSignedUrl = splittedPreSignedUrl.slice(0, splittedPreSignedUrl.length - 1);
      const finalPreSignedUrl = slicedPreSignedUrl.join('/')
      const params = {
        file_path: `${finalPreSignedUrl}/${fileName}`,
        po_name,
        draft_po_id
      };

      const { data } = await axios({
        method: 'post',
        url: '/api/v2/pos',
        params
      });

      return data;
    } catch (err) {
      return handleRejectedResponse(err, 'File Upload', rejectWithValue);
    }
  }
);

export const DeleteListing = createAsyncThunk(
  '/delete-listing',
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(`${process.env.API_BASE_URL}api/v2/feeds/${id}`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Delete Listing', rejectWithValue);
    }
  }
);

export const DeleteListingItems = createAsyncThunk(
  '/delete-listing-Items',
  async ({ draft_po_id, po_ids }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.API_BASE_URL}api/v2/pos/bulk_delete`, {
        draft_po_id,
        po_ids
      });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Delete Listing Items', rejectWithValue);
    }
  }
);

const fbaShipment = createSlice({
  name: "fbaShipment",
  initialState: {
    loading: false,
    success: false,
    err: '',
    vendorsList: [],
    productsList: [],
    isValidAsin: false,
    isInventoryListing: false,
    isPoCreated: false,
    draftPoInfo: {},
    poData: {},
    listingSuccess: false,
    vendorAddresses: [],
    isValidateAsin: '',
    poName: '',
    confirmSuccess: false,
    generatePoCodeLoading: false,
    topStats: {},
    companyAddresses: [],
    shipToAddress: {},
    newVendorLoading: false,
    gatedPos: [],
    warehouseAddresses: [],
    fileUploadLoading: false,
    isPoCount: ''
  },
  reducers: {
    setFbaShipmentState(state, { payload: { field, value } }) {
      state[field] = value;
    },
  },
  extraReducers: {
    [AddPurchaseOrder.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
        isPoCreated: false
      }
    },
    [AddPurchaseOrder.fulfilled]: (state, action) => {
      notification.success({
        message: "Create Purchase Order",
        description: 'Purchase Order created Successfully' || '',
        top: 65
      });
      return {
        ...state,
        loading: false,
        isPoCreated: true,
        draftPoInfo: action.payload?.draft_po
      }
    },
    [AddPurchaseOrder.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        isPoCreated: false
      }
    },
    [GetVendors.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [GetVendors.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        vendorsList: action.payload.vendors
      }
    },
    [GetVendors.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [ProductsList.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [ProductsList.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        productsList: action.payload.seller_skus
      }
    },
    [ProductsList.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [IsValidateAsin.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
        isValidateAsin: ''
      }
    },
    [IsValidateAsin.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        isValidateAsin: action?.payload?.vendorasin
      }
    },
    [IsValidateAsin.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [CreateInventoryListing.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
        isInventoryListing: false
      }
    },
    [CreateInventoryListing.fulfilled]: (state, action) => {
      notification.success({
        message: "Inventory Listing",
        description: action?.payload?.message || '',
        top: 65
      });
      return {
        ...state,
        loading: false,
        isInventoryListing: true
      }
    },
    [CreateInventoryListing.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetGraftPo.pending]: (state, action) => {
      return {
        ...state,
        loading: true
      }
    },
    [GetGraftPo.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        poData: action.payload.draft_po,
      }
    },
    [GetGraftPo.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetGatedPos.pending]: (state, action) => {
      return {
        ...state,
        loading: true
      }
    },
    [GetGatedPos.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        gatedPos: action.payload.draft_po?.pos,
      }
    },
    [GetGatedPos.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetTopStats.pending]: (state, action) => {
      return {
        ...state,
        loading: true
      }
    },
    [GetTopStats.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        topStats: action.payload,
      }
    },
    [GetTopStats.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetShipToAddress.pending]: (state, action) => {
      return {
        ...state,
        loading: true
      }
    },
    [GetShipToAddress.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        shipToAddress: action.payload?.address,
      }
    },
    [GetShipToAddress.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetCompanyAddress.pending]: (state, action) => {
      return {
        ...state,
        loading: true
      }
    },
    [GetCompanyAddress.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        companyAddresses: action.payload.addresses,
      }
    },
    [GetCompanyAddress.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetWarehouseAddress.pending]: (state, action) => {
      return {
        ...state,
        loading: true
      }
    },
    [GetWarehouseAddress.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        warehouseAddresses: action.payload.addresses,
      }
    },
    [GetWarehouseAddress.rejected]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [CreateWarehouseAddress.pending]: (state, action) => {
      return {
        ...state,
        loading: true
      }
    },
    [CreateWarehouseAddress.fulfilled]: (state, action) => {
      notification.success({
        message: "Address Created",
        description: 'Address created successfully.',
        top: 65
      });
      return {
        ...state,
        loading: false,
      }
    },
    [CreateWarehouseAddress.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetVendorsAddresses.pending]: (state, action) => {
      return {
        ...state,
        loading: true
      }
    },
    [GetVendorsAddresses.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        vendorAddresses: action.payload.addresses,
      }
    },
    [GetVendorsAddresses.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [CreateListing.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
        listingSuccess: false,
        success: false
      }
    },
    [CreateListing.fulfilled]: (state, action) => {
      notification.success({
        message: "Create Listing",
        description: 'Listing successfully started.',
        top: 65
      });
      return {
        ...state,
        loading: false,
        listingSuccess: true,
        success: true
      }
    },
    [CreateListing.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdatePendingPos.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
        success: false
      }
    },
    [UpdatePendingPos.fulfilled]: (state, action) => {
      notification.success({
        message: "Purchase Order",
        description: 'Purchase order updated successfully.',
        top: 65
      });
      return {
        ...state,
        loading: false,
        success: action?.payload?.stopSuccess ? false : true,
        isPoCount: action?.payload?.draft_po?.po_count
      }
    },
    [UpdatePendingPos.rejected]: (state, action) => {
      notification.error({
        message: "Error In Updating Purchase Order",
        description: action?.payload?.err?.data?.errors || '',
        top: 65
      });
      return {
        ...state,
        loading: false,
        success: false
      }
    },
    [CreateNewVendor.pending]: (state, action) => {
      return {
        ...state,
        newVendorLoading: true
      }
    },
    [CreateNewVendor.fulfilled]: (state, action) => {
      notification.success({
        message: "Create New Vendor",
        description: 'New Vendor Created Successfully.',
        top: 65
      });
      return {
        ...state,
        newVendorLoading: false,
      }
    },
    [CreateNewVendor.rejected]: (state, action) => {
      notification.error({
        message: "Error In Creating new Vendor",
        description: action?.payload?.err?.data?.errors || '',
        top: 65
      });
      return {
        ...state,
        newVendorLoading: false
      }
    },
    [UpdateQuantity.pending]: (state, action) => {
      return {
        ...state,
        loading: true
      }
    },
    [UpdateQuantity.fulfilled]: (state, action) => {
      notification.success({
        message: "Quantity Update",
        description: 'Quantity updated successfully.',
        top: 65
      });
      return {
        ...state,
        loading: false
      }
    },
    [UpdateQuantity.rejected]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [ResetListing.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
        success: false
      }
    },
    [ResetListing.fulfilled]: (state, action) => {
      notification.success({
        message: "Listing Update",
        description: 'Listing Reset successfully.',
        top: 65
      });
      return {
        ...state,
        loading: false,
        success: true,
      }
    },
    [ResetListing.rejected]: (state, action) => {
      notification.error({
        message: "Error In Reset Listing",
        description: action?.payload?.err?.data?.errors || '',
        top: 65
      });
      return {
        ...state,
        loading: false,
        success: false
      }
    },
    [DeleteListing.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
        success: false
      }
    },
    [DeleteListing.fulfilled]: (state, action) => {
      notification.success({
        message: "Delete Listing",
        description: 'Listing Deleted successfully.',
        top: 65
      });
      return {
        ...state,
        loading: false,
        success: true,
      }
    },
    [DeleteListing.rejected]: (state, action) => {
      notification.error({
        message: "Error In Reset Delete",
        description: action?.payload?.err?.data?.errors || '',
        top: 65
      });
      return {
        ...state,
        loading: false,
        success: false
      }
    },
    [DeleteListingItems.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
        success: false
      }
    },
    [DeleteListingItems.fulfilled]: (state, action) => {
      notification.success({
        message: "Delete PO Items",
        description: 'Items deleted successfully.',
        top: 65
      });
      return {
        ...state,
        loading: false,
        success: true,
      }
    },
    [DeleteListingItems.rejected]: (state, action) => {
      notification.error({
        message: "Error In Reset Delete",
        description: action?.payload?.err?.data?.errors || '',
        top: 65
      });
      return {
        ...state,
        loading: false,
        success: false
      }
    },
    [UpdateCost.pending]: (state, action) => {
      return {
        ...state,
        loading: true
      }
    },
    [UpdateCost.fulfilled]: (state, action) => {
      notification.success({
        message: "Cost Update",
        description: 'Cost updated successfully.',
        top: 65
      });
      return {
        ...state,
        loading: false
      }
    },
    [UpdateCost.rejected]: (state, action) => {
      notification.error({
        message: "Error In Updating Cost",
        description: action?.payload?.err?.data?.errors || '',
        top: 65
      });
      return {
        ...state,
        loading: false
      }
    },
    [UpdateCompanyAddress.pending]: (state, action) => {
      return {
        ...state,
        loading: true
      }
    },
    [UpdateCompanyAddress.fulfilled]: (state, action) => {
      notification.success({
        message: "Address Update",
        description: 'Address updated successfully.',
        top: 65
      });
      return {
        ...state,
        loading: false
      }
    },
    [UpdateCompanyAddress.rejected]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [SaveVendorAddress.pending]: (state, action) => {
      return {
        ...state,
        loading: true
      }
    },
    [SaveVendorAddress.fulfilled]: (state, action) => {
      notification.success({
        message: "Vendor Address",
        description: 'Address Added successfully.',
        top: 65
      });
      return {
        ...state,
        loading: false
      }
    },
    [SaveVendorAddress.rejected]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [AddGatedDetail.pending]: (state, action) => {
      return {
        ...state,
        loading: true
      }
    },
    [AddGatedDetail.fulfilled]: (state, action) => {
      notification.success({
        message: "Gated Detail",
        description: 'Gated Detail Added successfully.',
        top: 65
      });
      return {
        ...state,
        loading: false
      }
    },
    [AddGatedDetail.rejected]: (state, action) => {
      notification.error({
        message: "Error Adding Gated Detail",
        description: action?.payload?.err?.data?.errors || '',
        top: 65
      });
      return {
        ...state,
        loading: false
      }
    },
    [GeneratePoCode.pending]: (state, action) => {
      return {
        ...state,
        generatePoCodeLoading: true
      }
    },
    [GeneratePoCode.fulfilled]: (state, action) => {
      return {
        ...state,
        generatePoCodeLoading: false,
        poName: action?.payload?.po_name
      }
    },
    [GeneratePoCode.rejected]: (state, action) => {
      return {
        ...state,
        generatePoCodeLoading: false
      }
    },
    [ValidatePoCode.pending]: (state, action) => {
      return {
        ...state,
        generatePoCodeLoading: true
      }
    },
    [ValidatePoCode.fulfilled]: (state, action) => {
      return {
        ...state,
        generatePoCodeLoading: false,
        poName: action?.payload?.po_name
      }
    },
    [ValidatePoCode.rejected]: (state, action) => {
      return {
        ...state,
        generatePoCodeLoading: false
      }
    },
    [ConfirmPo.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
        confirmSuccess: false
      }
    },
    [ConfirmPo.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        confirmSuccess: true
      }
    },
    [ConfirmPo.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        confirmSuccess: false
      }
    },
    [UploadFileOnS3.pending]: (state, action) => {
      return {
        ...state,
        fileUploadLoading: true
      }
    },
    [UploadFileOnS3.fulfilled]: (state, action) => {
      return {
        ...state,
        fileUploadLoading: false
      }
    },
    [UploadFileOnS3.rejected]: (state, action) => {
      return {
        ...state,
        fileUploadLoading: false
      }
    },
    [SaveFileName.pending]: (state, action) => {
      return {
        ...state,
        fileUploadLoading: true,
        success: false
      }
    },
    [SaveFileName.fulfilled]: (state, action) => {
      notification.success({
        message: "PO File Upload",
        description: 'PO File Uploaded Successfully.',
        top: 65
      });
      return {
        ...state,
        fileUploadLoading: false,
        success: true
      }
    },
    [SaveFileName.rejected]: (state, action) => {
      return {
        ...state,
        fileUploadLoading: false,
        success: false
      }
    }
  }
})

const { reducer, actions } = fbaShipment;

export const {
  setFbaShipmentState,
  clearState,
  setPageNum,
  setPerPage,
  clearFilters
} = actions;

export default reducer;
