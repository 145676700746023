import React, { useState, useEffect, useRef } from "react";
import { awsAxios } from '../../../axios_config';
import {
  ceil,
  debounce,
  findIndex,
  cloneDeep,
  capitalize,
  round,
  isInteger,
  toLower,
  filter,
  isEmpty,
  uniq,
  pull,
  isFinite,
  isObject
} from "lodash";
import {
  Spin,
  Tooltip,
  Popconfirm,
  Popover,
  Divider,
  Icon,
  Checkbox,
  notification,
  Select as LocationSelect,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { WarehouseWrapper } from "./style";
import AgGrid from "../../components/ag-grid-table/index";
import Pagination from "../../components/pagination/index";
import SearchInput from "../../components/uiComponent/inputs/searchinput/index";
import Button from "../../components/uiComponent/button/index";
import Drawer from "../../components/uiComponent/drawer/index";
import AddtoStorageModal from "../../components/addToStorageModal";
import { Modal } from "react-bootstrap";
import {
  GetStagingItems,
  ListToShopify,
  GetWarehouses,
  GetStagingAsins,
  DisposeLocationItem,
  updateField,
  clearField,
  UpdateUnitPrice,
  UpdateUpc,
  GetAffiliateUsers,
  ListToShopifyAffiliates,
  RemoveListing,
  GetAffiliatesShopifyListing,
  removeDataEntry,
  RemoveAffiliateListing,
} from "../../slices/internalStorage";
import Select from "../../components/uiComponent/select/index";
import { Stack } from "@mui/material";
import warehouse from "../../assets/images/newImages/warehouseIcon.svg";
import eyeIcon from "../../assets/images/newImages/eye-icon.svg";
import Badge from "react-bootstrap/Badge";
import { generateStorageBarcode } from '../../utils/locationLabels';
import moment from 'moment';
import { BsTrash, BsPencilSquare } from "react-icons/bs";
import warningIcon from "../../assets/images/newImages/warning-red.svg";
import CustomModal from "../../components/uiComponent/modal/index";
import QuestionIcon from "../../assets/images/newImages/question-circle.svg";
import AmazonIcon from "../../assets/images/newImages/amazon-collapse.svg";
import WalmartIcon from "../../assets/images/newImages/walmart-collapse.svg";
import ShopifyLogo from "../../assets/images/newImages/shopify_collapse.svg";
import userIcon from "../../assets/images/newImages/user-blue-icon.svg";

import {
  GetAllLocations
} from "../../slices/fba-inbound/to-receive";
import {
  GetStorageUsersList
} from "../../slices/fba-inbound/to-check-in";

let gridApi, columnApi;
let affiliatesGridApi, affiliatesColumnApi;

const initialListingPlatforms = { amazon: false, walmart: false, shopify: false };
const initialPlatformDeleteModalData = { platform: '', rowIndex: -1 };

const { Option } = LocationSelect;
const Index = (props) => {
  const affiliatesSearchRef = useRef();
  const listingsSearchRef = useRef();
  const dispatch = useDispatch();

  const [stagingItemsDrawer, setStagingItemsDrawer] = useState(false);
  const [usersDrawer, setUsersDrawer] = useState(false);
  const [platformDeleteModalData, setPlatformDeleteModalData] = useState(initialPlatformDeleteModalData);
  const [listingPlatforms, setListingPlatforms] = useState(initialListingPlatforms);
  const [listPlatformModal, setListPlatformModal] = useState(false);
  const [platformDrawer, setPlatformDrawer] = useState(false);
  const [dataToBeUsed, setDataToBeUsed] = useState({});
  const [disposeModal, setDisposeModal] = useState(false);

  const [addtoStorage, setAddtoStorage] = useState(false);
  const [locationsList, setLocationsList] = useState([]);
  const [userList, setUsersList] = useState([]);
  const [pagination, setPagination] = useState({ page_num: 1, per_page: 25 });
  const [filters, setFilters] = useState([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState('');
  const [currentLocationItemIndex, setCurrentLocationItemIndex] = useState(-1);
  const [filteredAffiliateUsers, setFilteredAffiliateUsers] = useState([]);
  const [filteredAffiliatesShopifyListings, setFilteredAffiliatesShopifyListings] = useState([]);
  const [selectedItemsId, setSelectedItemsId] = useState([]);
  const [selectedAffiliatesId, setSelectedAffiliatesId] = useState([]);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const { page_num, per_page } = pagination;

  const {
    userItemLoading,
    fetchingStagingItems,
    stagingItems,
    listingToShopify,
    fetchingWarehouses,
    warehouses,
    stagingAsins,
    fetchingStagingAsins,
    totalStagingAsinsCount,
    disposeItemLoading,
    updatingUnitPrice,
    fetchingAffiliateUsers,
    affiliateUsers,
    listingToShopifyAffiliates,
    removingListing,
    fetchingAffiliatesShopifyListing,
    affiliatesShopifyListings,
    removingAffiliateListing,
    updatingUpc,
  } = useSelector((state) => state?.internalStorage || {});

  const {
    toCheckIn: {
      checkInLoading
    }
  } = useSelector((state) => state.fbaInbound || {});

  const { currentUser: user } = useSelector((state) => state.auth || {});
  const { is_affiliate_manager } = user || {};

  const {
    asin,
    available_quantity,
    on_hold_quantity,
    vendor_sku,
    image_url,
    title,
    users_count
  } = stagingAsins?.[currentLocationItemIndex] || {};

  useEffect(() => {
    dispatch(GetWarehouses());
    dispatch(GetStagingAsins({ filters, pagination }));
    dispatch(GetAffiliateUsers()).then(({ payload }) => {
      const { data } = payload || {};
      const { success, users } = data || {};
      if (success) setFilteredAffiliateUsers(users);
    });

    return () => {
      dispatch(clearField({ field: 'stagingAsins' }));
      gridApi?.deselectAll();
      affiliatesGridApi?.deselectAll();
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(stagingAsins)) onItemFilterChange();
  }, [stagingAsins]);

  useEffect(() => {
    if (!isEmpty(filteredAffiliateUsers)) onAffiliateFilterChange();
  }, [filteredAffiliateUsers]);

  const gridReady = (params) => {
    window.grid = params;
    if (is_affiliate_manager) {
      // params.columnApi?.setColumnVisible('fbm_platform', false);
      params.columnApi?.setColumnVisible('amazon_platform', false);
      params.columnApi?.setColumnVisible('walmart_platform', false);
      params.columnApi?.setColumnVisible('shopify_platform', false);
    }
    !is_affiliate_manager && params.columnApi?.setColumnVisible('users_count', false);
    gridApi = params.api;
    columnApi = params.columnApi;
  };

  const affiliatesGridReady = (params) => {
    window.grid = params;
    affiliatesGridApi = params.api;
    affiliatesColumnApi = params.columnApi;
  };

  const updateUnitPriceHandler = (params, field) => {
    const { data, newValue, oldValue, node } = params || {};
    if (newValue !== oldValue) {
      dispatch(UpdateUnitPrice({
        staging_asin_id: data.id,
        field,
        value: newValue
      }))
        .then(({ payload }) => {
          const { data } = payload || {};
          const { success } = data || {};
          if (success) {
            dispatch(updateField({
              field: 'stagingAsins',
              index: node.rowIndex,
              key: field,
              value: newValue
            }));
            return true;
          }
        });
    }
    return false;
  };

  const downloadImages = async (images) => {
    images.forEach(async (imageUrl) => {
      try {
        const response = await fetch(imageUrl, { method: 'GET' });
        if (!response.ok) {
          throw new Error(`Failed to fetch Image`);
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const fileName = imageUrl.substring(imageUrl.lastIndexOf('/') + 1)
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          a.remove();
        }, 60000);
      } catch (error) {
        notification.error({
          top: 65,
          message: 'Error',
          description: `Failed to download images`
        });
      }
    });
  };
  const downloadSingleImage = async (imageUrl) => {
    setDownloadLoading(true);
    try {
      const response = await fetch(imageUrl, { method: 'GET' });
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
      document.body.appendChild(a);
      a.click();
      setDownloadLoading(false);
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        a.remove();
      }, 60000);
    } catch (error) {
      setDownloadLoading(false);
      notification.error({
        top: 65,
        message: 'Error',
        description: 'Failed to download image'
      });
    }
  };

  const ImagePopoverContent = ({ images }) => (
    <WarehouseWrapper>
      <div style={{width:'248px'}}>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="fw-bold mb-0 fs-14">Images</h6>
          <Button text='Download Images' onClick={() => downloadImages(images)} className='download-btn' />
        </div>
        <Divider className="my-2" />
        <div className="image-overlay-wrapper">
          {images.map((url, index) => (
            <img key={index} src={url} alt={`Image ${index}`} className="product-image" onClick={()=> downloadSingleImage(url)}/>
          ))}
        </div>
      </div>
    </WarehouseWrapper>
  );
  
  const ImagesCellRenderer = ({ value }) => {
    if (!value?.length) return 'N/A';
  
    return (
      <Popover
        placement="bottom"
        content={<ImagePopoverContent images={value} />}
        trigger='click'
      >
        <WarehouseWrapper>
          <div className="image-count-wrapper">
            <img src={value?.[0]} alt="product" width="45px" height="47px" style={{ minWidth: "40px" }} />
            {value?.length > 1 && (<span className="product-count-label">+{value?.length - 1}</span>)}
          </div>
        </WarehouseWrapper>
      </Popover>
    );
  };
  
  const updateUpcHandler = (params, field) => {
    const { data, newValue, oldValue, node } = params || {};
    if (newValue !== oldValue && newValue) {
      if (newValue.length !== 12 || !isFinite(Number(newValue))) {
        return notification.error({
          top: 65,
          message: 'Update Upc',
          description: 'Upc must be of 12 (Numeric) digits'
        });
      }

      dispatch(UpdateUpc({
        staging_asin_id: data.id,
        upc: newValue
      }))
        .then(({ payload }) => {
          const { data } = payload || {};
          const { success } = data || {};
          if (success) {
            dispatch(updateField({
              field: 'stagingAsins',
              index: node.rowIndex,
              key: field,
              value: newValue
            }));
            return true;
          }
        });
    }
    return false;
  };

  const locationItemsColDef = [
    {
      headerName: "Title",
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: (params) => {
        const { shopify_listing_status, amazon_listing_status, walmart_listing_status, listable_quantity } = params?.data || {};
        if (!listable_quantity || !Number(listable_quantity)) return false;
        if (!shopify_listing_status || (shopify_listing_status === 'ERROR')) return true;
        if (!amazon_listing_status || (amazon_listing_status === 'ERROR')) return true;
        if (!walmart_listing_status || (walmart_listing_status === 'ERROR')) return true;
        return false;
      },
      minWidth: 300,
      cellRendererFramework: ({ data }) => {
        const { title, asin, vendor_sku, image_url } = data || {};

        return (
          <Stack direction="row" spacing={1}>
            <img src={image_url || "/images/no-image.png"} alt="product" width="45px" height="47px"
              style={{ minWidth: "40px" }}
            />
            <Stack>
              <span className="fs-14 color-secondary">
                {title}
              </span>
              <span className="fs-10 color-gray weight-bold">
                Identifier{" "}
                <span className="color-secondary fw-normal">{asin || 'N/A'}</span>
              </span>
              <span className="fs-10 color-gray weight-bold">
                Vendor SKU{" "}
                <span className="color-secondary fw-normal">{vendor_sku || 'N/A'}</span>
              </span>
            </Stack>
          </Stack>
        );
      },
    },
    {
      headerName: "UPC",
      field: 'upc',
      width: 140,
      sortable: false,
      editable: true,
      valueSetter: (params) => updateUpcHandler(params, 'upc'),
      cellRendererFramework: ({ value }) => {
        return (
          <Stack direction="row" alignItems="center" spacing={0.3}>
            <span>{value || 'N/A'}</span>
            <BsPencilSquare className="color-primary cursor-pointer" />
          </Stack>
        )
      }
    },
    {
      headerName: "User’s",
      field: 'users_count',
      maxWidth: 110,
      sortable: false,
      cellRendererFramework: ({ value, data, node }) => {
        return (
          <Stack fontWeight={700} direction="row" className="cursor-pointer" spacing={0.3} onClick={() => {
            if (value) {
              setUsersDrawer(true);
              dispatch(GetAffiliatesShopifyListing(data.id)).then(({ payload }) => {
                const { data } = payload || {};
                const { success, affiliate_listings } = data || {};
                if (success) {
                  setCurrentLocationItemIndex(node.rowIndex);
                  setFilteredAffiliatesShopifyListings(affiliate_listings);
                }
              });
            }
          }}>
            <img src={userIcon} alt="icon" />
            <span className="color-primary fs-14">{value || 0}</span>
          </Stack>
        )
      }
    },
    {
      headerName: "Qty",
      minWidth: 120,
      sortable: false,
      cellRendererFramework: ({ data }) => {
        const { available_quantity, on_hold_quantity, listable_quantity } = data || {};

        return (
          <Stack direction="row" spacing={1}>
            <Stack>
              <span className="fs-14 color-secondary">{available_quantity || 0}</span>
              <span className="fs-10 color-gray">In Storage</span>
            </Stack>
            <Stack>
              <span className="fs-14 color-secondary">{on_hold_quantity || 0}</span>
              <span className="fs-10 color-gray">On Hold</span>
            </Stack>
            <Stack>
              <span className="fs-14 color-secondary">{listable_quantity || 0}</span>
              <span className="fs-10 color-gray">Available For FBM</span>
            </Stack>
          </Stack>
        );
      },
    },
    {
      headerName: "Unit Price",
      field: 'unit_price',
      width: 120,
      sortable: false,
      editable: true,
      valueSetter: (params) => updateUnitPriceHandler(params, 'unit_price'),
      cellRendererFramework: ({ value }) => {
        return (
          <div>
            {value ? isInteger(value) ? `$${value}.00` : `$${round(value, 2)}` : '$0.00'}
            <Icon type="edit" style={{ float: 'right', fontSize: 12, marginTop: 5 }} />
          </div>
        )
      }
    },
    {
      headerName: "Amazon",
      field: 'amazon_platform',
      width: 190,
      cellRendererFramework: ({ data, node }) => {
        // const { shopify_listing_reason, shopify_listing_status, listable_quantity } = data || {};
        const { amazon_listing_sku, amazon_listing_status, amazon_listing_reason, listable_quantity } = data || {};
        const { rowIndex } = node || {};
        const isDeletable = amazon_listing_status === 'SUCCESS';

        return (
          <Stack spacing={0.4}>
            <Stack direction="row" spacing={0.5}>
              <span className="color-gray weight-bold fs-12">SKU:</span>
              <span className="fs-12 color-secondary">{amazon_listing_sku || 'N/A'}</span>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <span className="color-gray weight-bold fs-10">Status:</span>
              <Tooltip title={(amazon_listing_status === 'ERROR') ? amazon_listing_reason : ''}>
                <Badge className={
                  (amazon_listing_status === 'SUCCESS') ? 'success-badge' : (amazon_listing_status === 'PENDING') ?
                    'warning-badge' : (amazon_listing_status === 'ERROR') ? 'error-badge' : 'disabled-badge'
                }>
                  {amazon_listing_status ? capitalize(amazon_listing_status) :
                    (!listable_quantity || !Number(listable_quantity)) ? 'N/A' : 'Listable'
                  }
                </Badge>
              </Tooltip>
              {
                (amazon_listing_status === 'ERROR') &&
                <Tooltip title={amazon_listing_reason}>
                  <img src={QuestionIcon} alt="info" className="cursor-pointer" />
                </Tooltip>
              }
              <BsTrash color="#CF0909" className={`${isDeletable ? 'cursor-pointer' : ''}`}
                style={isDeletable ? {} : { opacity: 0.5, pointerEvents: 'none' }}
                onClick={() => setPlatformDeleteModalData({ platform: 'Amazon', rowIndex })}
              />
            </Stack>
            {/* <Tooltip title={(shopify_listing_status === 'ERROR') ? shopify_listing_reason : ''}>
                <Badge
                  className={
                    (shopify_listing_status === 'SUCCESS') ? 'success-badge' : (shopify_listing_status === 'PENDING') ?
                      'warning-badge' : (shopify_listing_status === 'ERROR') ? 'error-badge' : 'disabled-badge'
                  }
                >
                  {shopify_listing_status ? capitalize(shopify_listing_status) :
                    (!listable_quantity || !Number(listable_quantity)) ? 'N/A' : 'Listable'
                  }
                </Badge>
              </Tooltip> */}
          </Stack>
        );
      },
    },
    {
      headerName: "Walmart",
      field: 'walmart_platform',
      width: 190,
      cellRendererFramework: ({ data, node }) => {
        const { walmart_listing_sku, walmart_listing_status, walmart_listing_reason, listable_quantity } = data || {};
        const { rowIndex } = node || {};
        const isDeletable = walmart_listing_status === 'SUCCESS';

        return (
          <Stack spacing={0.4}>
            <Stack direction="row" spacing={0.5}>
              <span className="color-gray weight-bold fs-12">SKU:</span>
              <span className="fs-12 color-secondary">{walmart_listing_sku || 'N/A'}</span>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <span className="color-gray weight-bold fs-10">Status:</span>
              <Tooltip title={(walmart_listing_status === 'ERROR') ? walmart_listing_reason : ''}>
                <Badge className={
                  (walmart_listing_status === 'SUCCESS') ? 'success-badge' : (walmart_listing_status === 'PENDING') ?
                    'warning-badge' : (walmart_listing_status === 'ERROR') ? 'error-badge' : 'disabled-badge'
                }>
                  {walmart_listing_status ? capitalize(walmart_listing_status) :
                    (!listable_quantity || !Number(listable_quantity)) ? 'N/A' : 'Listable'
                  }
                </Badge>
              </Tooltip>
              {
                (walmart_listing_status === 'ERROR') &&
                <Tooltip title={walmart_listing_reason}>
                  <img src={QuestionIcon} alt="info" className="cursor-pointer" />
                </Tooltip>
              }
              <BsTrash color="#CF0909" className={`${isDeletable ? 'cursor-pointer' : ''}`}
                style={isDeletable ? {} : { opacity: 0.5, pointerEvents: 'none' }} onClick={() => setPlatformDeleteModalData({ platform: 'Walmart', rowIndex })}
              />
            </Stack>
          </Stack>
        );
      },
    },
    {
      headerName: "Shopify",
      field: 'shopify_platform',
      width: 190,
      cellRendererFramework: ({ data, node }) => {
        const { shopify_listing_sku, shopify_listing_reason, shopify_listing_status, listable_quantity } = data || {};
        const { rowIndex } = node || {};
        const isDeletable = shopify_listing_status === 'SUCCESS';

        return (
          <Stack spacing={0.4}>
            <Stack direction="row" spacing={0.5}>
              <span className="color-gray weight-bold fs-12">SKU:</span>
              <span className="fs-12 color-secondary">{shopify_listing_sku || 'N/A'}</span>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <span className="color-gray weight-bold fs-10">Status:</span>
              <Tooltip title={(shopify_listing_status === 'ERROR') ? shopify_listing_reason : ''}>
                <Badge className={
                  (shopify_listing_status === 'SUCCESS') ? 'success-badge' : (shopify_listing_status === 'PENDING') ?
                    'warning-badge' : (shopify_listing_status === 'ERROR') ? 'error-badge' : 'disabled-badge'
                }>
                  {shopify_listing_status ? capitalize(shopify_listing_status) :
                    (!listable_quantity || !Number(listable_quantity)) ? 'N/A' : 'Listable'
                  }
                </Badge>
              </Tooltip>
              {
                (shopify_listing_status === 'ERROR') &&
                <Tooltip title={shopify_listing_reason}>
                  <img src={QuestionIcon} alt="info" className="cursor-pointer" />
                </Tooltip>
              }
              <BsTrash color="#CF0909" className={`${isDeletable ? 'cursor-pointer' : ''}`}
                style={isDeletable ? {} : { opacity: 0.5, pointerEvents: 'none' }} onClick={() => setPlatformDeleteModalData({ platform: 'Shopify', rowIndex })}
              />
            </Stack>
          </Stack>
        );
      },
    },
    {
      headerName: "Warehouse",
      field: "warehouse",
      minWidth: 120,
      sortable: false,
      cellRenderer: ({ value }) => (value || 'N/A'),
    },
    // {
    //   headerName: "ReLabel",
    //   minWidth: 160,
    //   sortable: false,
    //   cellRendererFramework: () => {
    //     return (
    //       <Stack>
    //         <span className="fs-10 color-gray weight-bold">
    //           Identifier
    //           <span className="color-secondary fw-normal">SKU-WM-2667</span>
    //         </span>
    //         <span className="fs-10 color-gray weight-bold">
    //           Vendor SKU
    //           <span className="color-secondary fw-normal">SKU-WM-456</span>
    //         </span>
    //         <Stack direction="row" alignItems="center" spacing={1}>
    //           <span className="fs-10 color-gray weight-bold">Status</span>
    //           <Badge className="error-badge small-badge">Error</Badge>
    //           <Badge className="error-badge round-badge">?</Badge>
    //         </Stack>
    //       </Stack>
    //     );
    //   },
    // },
    {
      headerName: "Created At",
      field: "created_at",
      width: 120,
      sortable: false,
      cellRendererFramework: ({ value }) => {
        if (!value) return <div>N/A</div>

        return (
          <Stack>
            <span>{moment(value).format('MMM DD, YYYY')}</span>
            <span className="fs-10 bold-weight color-gray">{moment(value).format('HH:mm:ss')}</span>
          </Stack>
        );
      },
    },
    {
      headerName: "",
      maxWidth: 65,
      sortable: false,
      cellRendererFramework: ({ data, node }) => {
        const { rowIndex } = node || {};
        const { id, shopify_listing_status } = data || {};
        const isDeletable = shopify_listing_status === 'SUCCESS';

        return (
          <div className="d-flex align-items-center gap-3">
            <img
              src={eyeIcon}
              alt="watch"
              className="cursor-pointer"
              onClick={() => {
                setStagingItemsDrawer(true);
                setCurrentLocationItemIndex(rowIndex);
                dispatch(GetStagingItems(id));
              }}
            />
            {/* <Popconfirm
              key='3'
              placement='left'
              title='Are you sure, you want to delete listing on Shopify ?'
              onConfirm={() => handleRemoveListing({ rowIndex, id })}
              okText='Yes'
              cancelText='No'
              disabled={!isDeletable}
            >
              <BsTrash color="#CF0909" className={`${isDeletable ? 'cursor-pointer' : ''}`}
                style={isDeletable ? {} : { opacity: 0.5 }}
              />
            </Popconfirm> */}
          </div>
        );
      },
    },
  ];

  const handleDownloadLocationLabel = (data) => {
    const { title, uuid, stored_quantity, identifier, warehouse_user } = data || {};
    generateStorageBarcode({
      warehouseName: warehouse_user,
      uuid,
      title,
      stored_quantity,
      identifier,
      labelType: 'product'
    });
  };

  const handleDisposeItem = () => {
    const { id, staging_asin_id } = dataToBeUsed || {};
    dispatch(DisposeLocationItem({ id }))
      .then(({ payload }) => {
        const { data } = payload || {};
        const { success } = data || {};
        if (success) {
          setDisposeModal(false);
          dispatch(GetStagingItems(staging_asin_id));
        }
      });
  };

  const disposalTooltip = (data) => {
    const { source_type, disposed, has_work_order } = data || {};
    const allowAction = ['FbaRemovalShipment'].includes(source_type);
    if (disposed) return 'Added to disposed';
    else if (!allowAction) return 'Dispose not allowed for this source';
    else if (has_work_order) return "You can't dispose as it exist over Work Order"
    else return 'Dispose Item';
  };

  const openDisposeModal = (data) => {
    setDataToBeUsed(data);
    setDisposeModal(true);
  };

  const drawerColumnDefinitions = [
    {
      headerName: "Date",
      width: 125,
      field: 'created_at',
      cellRenderer: ({ value }) => (value ? moment(value).format('DD-MM-YYYY') : 'N/A'),
    },
    {
      headerName: "Quantity",
      width: 100,
      sortable: false,
      field: 'stored_quantity',
      cellRenderer: ({ value }) => (value || '0'),
    },
    {
      headerName: "Unit Price",
      width: 120,
      sortable: false,
      field: 'unit_price',
      cellRenderer: ({ value }) => (`$${value || 0}`),
    },
    {
      headerName: "Location",
      width: 160,
      sortable: false,
      cellRendererFramework: ({ data }) => {
        const { storages } = data || {};
        if (!(storages?.length)) return 'N/A';
        else if (storages?.length === 1) return <span className="color-primary">{storages?.[0]?.lcoation_name}</span>;

        return (
          <Popover
            placement="top"
            content={
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="fw-bold mb-0 fs-14">Locations</h6>
                </div>
                <Divider className="my-2" />
                <div className="overflow-auto" style={{ maxHeight: 400 }}>
                  {storages?.map(({ lcoation_name, quantity_stored } = {}, index) => {

                    return (
                      <div key={`location_${lcoation_name}_${index}`} className="d-flex justify-content-between align-items-center mb-2">
                        <span className="pe-4">{lcoation_name}</span>
                        <span className="color-secondary">{quantity_stored}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            }
            trigger='hover'
          >
            <span className="color-primary cursor-pointer">{storages?.[0]?.lcoation_name} ...</span>
          </Popover>
        );
      },
    },
    {
      headerName: "Source",
      width: 130,
      field: 'source_type',
      cellRenderer: ({ value }) => {
        let sourceType = '';
        if (value === 'ArrivalItem') sourceType = 'Storage Po';
        else if (value === 'FbaRemovalShipment') sourceType = 'Removals';
        else if (value === 'OutboundShipment') sourceType = 'Work Order';
        else if (value === 'supplierOrders') sourceType = 'FBM';
        else if (value === 'Vendoritem') sourceType = 'Manual Addition';
        else if (value === 'refundedSupplierOrders') sourceType = 'FBM Refunded';
        else sourceType = 'N/A';

        return (sourceType);
      },
    },
    {
      headerName: "Images",
      width: 200,
      field: 'image_urls',
      cellRendererFramework: ImagesCellRenderer,
    },
    {
      headerName: "Actions",
      width: 150,
      pinned: "right",
      cellRendererFramework: ({ data }) => {
        const { source_type, has_work_order, disposed, relist_status } = data || {};
        const allowAction = ['Vendoritem', 'FbaRemovalShipment'].includes(source_type);
        const disableRelabel = ['Pending', 'Completed'].includes(relist_status) || has_work_order || !allowAction || disposed;
        const disableDisposal = disposed || has_work_order || !(['FbaRemovalShipment'].includes(source_type));

        return (
          <div className="d-flex align-items-center table-icon table-icon-centered gap-3">
            <Tooltip title='Download Label'>
              <span className="icon-print cursor-pointer" onClick={() => handleDownloadLocationLabel(data)}></span>
            </Tooltip>

            {/* <Tooltip title={() => relabelTooltip(data)}>
              <div>
                <span
                  className={`icon-refresh cursor-pointer ${disableRelabel ? 'disabled-span' : ''}`}
                  onClick={() => handleDrawerOpen(data, 'relabel')}
                ></span>
              </div>
            </Tooltip> */}

            <Tooltip title={() => disposalTooltip(data)}>
              <div>
                <span
                  className={`icon-dispose cursor-pointer ${disableDisposal ? 'disabled-span' : ''}`}
                  onClick={() => openDisposeModal(data)}
                ></span>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const usersColumnDefinitions = [
    {
      headerName: "Username",
      width: 160,
      flex: 1,
      cellRendererFramework: ({ data }) => {
        const { user, email } = data || {};

        return (
          <Stack spacing={0.5}>
            <span>{user || 'N/A'}</span>
            <span className="color-secondary fs-12 weight-bold">{email || 'N/A'}</span>
          </Stack>
        )
      }
    },
    {
      headerName: "Amazon",
      width: 140,
      flex: 1,
      cellRendererFramework: ({ data }) => {
        const { amazon_listing_sku, amazon_listing_status, amazon_listing_reason, listable_quantity } = data || {};
        const isDeletable = amazon_listing_status === 'SUCCESS';

        return (
          <Stack spacing={0.4}>
            <Stack direction="row" spacing={0.5}>
              <span className="color-gray weight-bold fs-12">SKU:</span>
              <span className="fs-12 color-secondary">{amazon_listing_sku || 'N/A'}</span>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <span className="color-gray weight-bold fs-10">Status:</span>
              <Tooltip title={(amazon_listing_status === 'ERROR') ? amazon_listing_reason : ''}>
                <Badge className={
                  (amazon_listing_status === 'SUCCESS') ? 'success-badge' : (amazon_listing_status === 'PENDING') ?
                    'warning-badge' : (amazon_listing_status === 'ERROR') ? 'error-badge' : 'disabled-badge'
                }>
                  {amazon_listing_status ? capitalize(amazon_listing_status) :
                    (!listable_quantity || !Number(listable_quantity)) ? 'N/A' : 'Listable'
                  }
                </Badge>
              </Tooltip>
              {
                (amazon_listing_status === 'ERROR') &&
                <Tooltip title={amazon_listing_reason}>
                  <img src={QuestionIcon} alt="info" className="cursor-pointer" />
                </Tooltip>
              }
              <BsTrash color="#CF0909" className={`${isDeletable ? 'cursor-pointer' : ''}`}
                style={isDeletable ? {} : { opacity: 0.5, pointerEvents: 'none' }}
                onClick={() => setPlatformDeleteModalData({ platform: 'Amazon', rowIndex: affiliatesShopifyListings.findIndex(obj => obj.id === data.id) })}
              />
            </Stack>
          </Stack>
        );
      },
    },
    {
      headerName: "Walmart",
      width: 140,
      flex: 1,
      cellRendererFramework: ({ data }) => {
        const { walmart_listing_sku, walmart_listing_status, walmart_listing_reason, listable_quantity } = data || {};
        const isDeletable = walmart_listing_status === 'SUCCESS';

        return (
          <Stack spacing={0.4}>
            <Stack direction="row" spacing={0.5}>
              <span className="color-gray weight-bold fs-12">SKU:</span>
              <span className="fs-12 color-secondary">{walmart_listing_sku || 'N/A'}</span>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <span className="color-gray weight-bold fs-10">Status:</span>
              <Tooltip title={(walmart_listing_status === 'ERROR') ? walmart_listing_reason : ''}>
                <Badge className={
                  (walmart_listing_status === 'SUCCESS') ? 'success-badge' : (walmart_listing_status === 'PENDING') ?
                    'warning-badge' : (walmart_listing_status === 'ERROR') ? 'error-badge' : 'disabled-badge'
                }>
                  {walmart_listing_status ? capitalize(walmart_listing_status) :
                    (!listable_quantity || !Number(listable_quantity)) ? 'N/A' : 'Listable'
                  }
                </Badge>
              </Tooltip>
              {
                (walmart_listing_status === 'ERROR') &&
                <Tooltip title={walmart_listing_reason}>
                  <img src={QuestionIcon} alt="info" className="cursor-pointer" />
                </Tooltip>
              }
              <BsTrash color="#CF0909" className={`${isDeletable ? 'cursor-pointer' : ''}`}
                style={isDeletable ? {} : { opacity: 0.5, pointerEvents: 'none' }}
                onClick={() => setPlatformDeleteModalData({ platform: 'Walmart', rowIndex: affiliatesShopifyListings.findIndex(obj => obj.id === data.id) })}
              />
            </Stack>
          </Stack>
        );
      },
    },
    {
      headerName: "Shopify",
      width: 140,
      flex: 1,
      cellRendererFramework: ({ data }) => {
        const { shopify_listing_sku, shopify_listing_reason, shopify_listing_status, listable_quantity } = data || {};
        const isDeletable = shopify_listing_status === 'SUCCESS';

        return (
          <Stack spacing={0.4}>
            <Stack direction="row" spacing={0.5}>
              <span className="color-gray weight-bold fs-12">SKU:</span>
              <span className="fs-12 color-secondary">{shopify_listing_sku || 'N/A'}</span>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <span className="color-gray weight-bold fs-10">Status:</span>
              <Tooltip title={(shopify_listing_status === 'ERROR') ? shopify_listing_reason : ''}>
                <Badge className={
                  (shopify_listing_status === 'SUCCESS') ? 'success-badge' : (shopify_listing_status === 'PENDING') ?
                    'warning-badge' : (shopify_listing_status === 'ERROR') ? 'error-badge' : 'disabled-badge'
                }>
                  {shopify_listing_status ? capitalize(shopify_listing_status) :
                    (!listable_quantity || !Number(listable_quantity)) ? 'N/A' : 'Listable'
                  }
                </Badge>
              </Tooltip>
              {
                (shopify_listing_status === 'ERROR') &&
                <Tooltip title={shopify_listing_reason}>
                  <img src={QuestionIcon} alt="info" className="cursor-pointer" />
                </Tooltip>
              }
              <BsTrash color="#CF0909" className={`${isDeletable ? 'cursor-pointer' : ''}`}
                style={isDeletable ? {} : { opacity: 0.5, pointerEvents: 'none' }}
                onClick={() => setPlatformDeleteModalData({ platform: 'Shopify', rowIndex: affiliatesShopifyListings.findIndex(obj => obj.id === data.id) })}
              />
            </Stack>
          </Stack>
        );
      },
    },
    {
      headerName: "",
      field: 'id',
      width: 50,
      sortable: false,
      cellRendererFramework: ({ data }) => {
        const { shopify_listing_status, amazon_listing_status, walmart_listing_status } = data || {};
        const isDeletable = (shopify_listing_status === 'SUCCESS') || (amazon_listing_status === 'SUCCESS') || (walmart_listing_status === 'SUCCESS');

        return (
          <div onClick={() => {
            if (isDeletable) setPlatformDeleteModalData({ platform: 'All', rowIndex: affiliatesShopifyListings.findIndex(obj => obj.id === data.id) });
          }}>
            <BsTrash color="#CF0909" className={`${isDeletable ? 'cursor-pointer' : ''}`}
              style={isDeletable ? {} : { opacity: 0.5, pointerEvents: 'none' }}
            />
          </div>
        )
      },
    },
  ];

  const platformColumnDefinitions = [
    {
      headerName: "Username",
      field: 'full_name',
      minWidth: 140,
      flex: 1,
      headerCheckboxSelection: true,
      checkboxSelection: ({ data }) => data.amazon_store || data.walmart_store || data.shopify_store,
      cellRenderer: ({ value }) => value || 'N/A',
    },
    {
      headerName: "Email",
      field: 'email',
      minWidth: 180,
      flex: 1,
      sortable: false,
      cellRenderer: ({ value }) => value || 'N/A'
    },
    {
      headerName: "Amazon",
      field: 'amazon_store',
      width: 90,
      sortable: false,
      cellRendererFramework: ({ value }) => {
        return (
          <Badge className={value ? "success-badge" : "error-badge"}>{value ? 'Exist' : 'Not Exist'}</Badge>
        )
      }
    },
    {
      headerName: "Walmart",
      field: 'walmart_store',
      width: 90,
      sortable: false,
      cellRendererFramework: ({ value }) => {
        return (
          <Badge className={value ? "success-badge" : "error-badge"}>{value ? 'Exist' : 'Not Exist'}</Badge>
        )
      }
    },
    {
      headerName: "Shopify",
      field: 'shopify_store',
      width: 90,
      sortable: false,
      cellRendererFramework: ({ value }) => {
        return (
          <Badge className={value ? "success-badge" : "error-badge"}>{value ? 'Exist' : 'Not Exist'}</Badge>
        )
      }
    },
  ];

  const handlePagination = (number, key) => {
    const value = Number(number);
    let paginationClone = { ...pagination };
    if (key === "pageLimit")
      paginationClone = { page_num: 1, per_page: value };
    else
      paginationClone = { ...pagination, page_num: value };
    setPagination(paginationClone);
    dispatch(GetStagingAsins({ filters, pagination: paginationClone }));
  };

  const handleFilter = debounce(({ isDropDown, field, value }) => {
    const filtersArray = cloneDeep(filters);
    const filterIndex = findIndex(filtersArray, { field });
    if (filterIndex === -1) {
      if (value) {
        filtersArray.push({
          field,
          type: isDropDown ? "drop-down" : "search",
          value
        });
      }
    } else if (value) {
      filtersArray[filterIndex].value = value;
    } else {
      filtersArray.splice(filterIndex, 1);
    }
    setFilters(filtersArray);
    const newPaginationHash = { ...pagination, page_num: 1 };
    setPagination(newPaginationHash);

    dispatch(GetStagingAsins({ filters: filtersArray, pagination: newPaginationHash }));
  }, 1000);

  const refreshGridClick = () => {
    dispatch(GetStagingAsins({ filters, pagination }));
  };

  const defaultColDef = {
    resizable: true,
    sortable: true,
    suppressMenu: true,
    suppressMovable: true,
  };

  const onSelectionChange = () => {
    const itemsId = [];
    gridApi.forEachNode((node) => {
      if (node.selected) {
        const { shopify_listing_status, amazon_listing_status, walmart_listing_status, listable_quantity, id } = node?.data;
        if (
          (
            ((shopify_listing_status === 'SUCCESS') || (shopify_listing_status === 'PENDING')) &&
            ((amazon_listing_status === 'SUCCESS') || (amazon_listing_status === 'PENDING')) &&
            ((walmart_listing_status === 'SUCCESS') || (walmart_listing_status === 'PENDING'))
          ) ||
          (!listable_quantity || !Number(listable_quantity))
        ) node.setSelected(false);
        else itemsId.push(id);
      }
    });
    setSelectedItemsId(itemsId);
  };

  const onItemFilterChange = () => {
    gridApi.forEachNode((node) => {
      node.setSelected(selectedItemsId.includes(node.data.id));
    });
  };

  const onAffiliateFilterChange = () => {
    const nodes = [];
    affiliatesGridApi.forEachNode((node) => {
      if (selectedAffiliatesId.includes(node.data.id) && !(node.isSelected())) {
        nodes.push(node);
      }
    });

    if (nodes.length) {
      for (let i = 0; i < nodes.length - 1; i++) {
        nodes[i].setSelected(true, false, true);   // setSelected(newValue, clearSelection, suppressFinishActions)
      }
      nodes[nodes.length - 1].setSelected(true);
    }
  };

  const handleListToShopify = () => {
    const { amazon, walmart, shopify } = listingPlatforms || {};
    const data = { ids: selectedItemsId, amazon, walmart, shopify };
    let updateKeys = [];
    if (amazon) updateKeys.push('amazon_listing_status');
    if (walmart) updateKeys.push('walmart_listing_status');
    if (shopify) updateKeys.push('shopify_listing_status');

    dispatch(ListToShopify(data))
      .then(({ payload }) => {
        const { data } = payload || {};
        const { success, amazon, walmart, shopify } = data || {};
        if (success) {
          dispatch(GetStagingAsins({ filters, pagination }));
          // const selectedNodes = gridApi?.getSelectedNodes() || [];
          // selectedNodes.forEach((node) => {
          //   updateKeys.forEach(key => {
          //     if (
          //       (key === 'amazon_listing_status' && amazon) ||
          //       (key === 'walmart_listing_status' && walmart) ||
          //       (key === 'shopify_listing_status' && shopify)
          //     ) {
          //       dispatch(updateField({
          //         field: 'stagingAsins',
          //         index: node.rowIndex,
          //         key,
          //         value: 'PENDING'
          //       }));
          //     }
          //   });
          // });
          gridApi?.deselectAll();
          setListPlatformModal(false);
          setListingPlatforms(initialListingPlatforms);
        }
      });
  };

  const handleCloseStagingItemsDrawer = () => {
    setStagingItemsDrawer(false);
    setCurrentLocationItemIndex(-1);
    dispatch(clearField({ field: 'stagingItems' }));
  };

  const mappedWarehousesList = warehouses?.map(({ user_id, warehouse_name, address } = {}) => {
    const { name, address_line1, address_line2 = '', city, postal_code, state_or_province_code, country_code } = address || {};

    return {
      value: user_id,
      label: (
        <>
          <h3 className="fs-14 mb-0">{warehouse_name}</h3>
          <span className="fs-12 color-grey">{`${name} - ${address_line1}${address_line2} ${city} ${state_or_province_code} ${postal_code} ${country_code} `}</span>
        </>
      ),
    };
  }) || [];

  const handleSearchAffiliateUsers = debounce((value) => {
    if (value) {
      setFilteredAffiliateUsers((filteredAffiliateUsers) =>
        filter(filteredAffiliateUsers, (obj) => {
          const { email, full_name } = obj || {};

          return (
            toLower(email)?.includes(toLower(value)) ||
            toLower(full_name)?.includes(toLower(value))
          );
        })
      );
    } else {
      setFilteredAffiliateUsers(affiliateUsers);
    }
  }, 800);

  const handleSearchAffiliatesListings = debounce((value, newData) => {
    if (value) {
      setFilteredAffiliatesShopifyListings((filteredAffiliatesShopifyListings) =>
        filter(isEmpty(newData) ? filteredAffiliatesShopifyListings : newData, (obj) => {
          const { email, user } = obj || {};

          return (
            toLower(email)?.includes(toLower(value)) ||
            toLower(user)?.includes(toLower(value))
          );
        })
      );
    } else {
      setFilteredAffiliatesShopifyListings(isEmpty(newData) ? affiliatesShopifyListings : newData);
    }
  }, 800);

  const onUserSelectionChange = () => {
    const usersId = cloneDeep(selectedAffiliatesId);
    affiliatesGridApi.forEachNode((node) => {
      const { shopify_store, amazon_store, walmart_store, id } = node?.data || {};

      if (node.isSelected()) {
        if (!shopify_store && !amazon_store && !walmart_store) node.setSelected(false);
        else if (!usersId.includes(id)) usersId.push(id);
      }
      else pull(usersId, id);
    });
    setSelectedAffiliatesId(uniq(usersId));
  };

  const handleListToShopifyAffiliates = () => {
    const { amazon, walmart, shopify } = listingPlatforms || {};
    const data = { ids: selectedItemsId, user_ids: selectedAffiliatesId, amazon, walmart, shopify };

    dispatch(ListToShopifyAffiliates(data))
      .then(({ payload }) => {
        const { data } = payload || {};
        const { success } = data || {};
        if (success) {
          dispatch(GetStagingAsins({ filters, pagination }));
          gridApi?.deselectAll();
          handleClosePlatformDrawer();
        }
      });
  };

  const handleClosePlatformDrawer = () => {
    setPlatformDrawer(false);
    affiliatesGridApi?.deselectAll();
    affiliatesSearchRef?.current?.value && (affiliatesSearchRef.current.value = "");
    setFilteredAffiliateUsers(affiliateUsers);
    setSelectedAffiliatesId([]);
    setListingPlatforms(initialListingPlatforms);
  };

  const handleRemoveAffiliateListing = () => {
    const { platform, rowIndex } = platformDeleteModalData || {};
    const { id, email, shopify_listing_status, amazon_listing_status, walmart_listing_status, staging_asin_id } = affiliatesShopifyListings[rowIndex] || {};
    const data = { id, amazon: false, walmart: false, shopify: false };

    let deleteUser = false;
    if (platform === 'All') deleteUser = true;
    else if (amazon_listing_status && !walmart_listing_status && !shopify_listing_status) deleteUser = true;
    else if (!amazon_listing_status && walmart_listing_status && !shopify_listing_status) deleteUser = true;
    else if (!amazon_listing_status && !walmart_listing_status && shopify_listing_status) deleteUser = true;

    let updateKey = '';
    if (platform === 'Amazon') {
      data.amazon = true;
      updateKey = 'amazon_listing_status';
    }
    else if (platform === 'Walmart') {
      data.walmart = true;
      updateKey = 'walmart_listing_status';
    }
    else if (platform === 'Shopify') {
      data.shopify = true;
      updateKey = 'shopify_listing_status';
    }

    dispatch(RemoveAffiliateListing(data))
      .then(({ payload }) => {
        const { data } = payload || {};
        const { success } = data || {};
        if (success) {
          if (deleteUser) {
            dispatch(removeDataEntry({
              field: 'affiliatesShopifyListings',
              key: 'email',
              value: email
            }));
            setFilteredAffiliatesShopifyListings(
              filteredAffiliatesShopifyListings => filter(filteredAffiliatesShopifyListings, obj => (obj.email !== email))
            );
            dispatch(updateField({
              field: 'stagingAsins',
              index: currentLocationItemIndex,
              key: 'users_count',
              value: users_count - 1
            }));
          }
          else {
            dispatch(GetAffiliatesShopifyListing(staging_asin_id)).then(({ payload }) => {
              const { data } = payload || {};
              const { success, affiliate_listings } = data || {};
              if (success) {
                handleSearchAffiliatesListings(listingsSearchRef?.current?.value, affiliate_listings);
              }
            });
            // dispatch(updateField({
            //   field: 'affiliatesShopifyListings',
            //   index: rowIndex,
            //   key: updateKey,
            //   value: null
            // }));
          }
          setPlatformDeleteModalData(initialPlatformDeleteModalData);
        }
      });
  };

  const handleRemovePlatformListing = () => {
    const { platform, rowIndex } = platformDeleteModalData || {};
    const { id } = stagingAsins[rowIndex] || {};
    const data = { id, amazon: false, walmart: false, shopify: false };
    let statusUpdateKey = '', skuUpdateKey = '';
    if (platform === 'Amazon') {
      data.amazon = true;
      statusUpdateKey = 'amazon_listing_status';
      skuUpdateKey = 'amazon_listing_sku';
    }
    else if (platform === 'Walmart') {
      data.walmart = true;
      statusUpdateKey = 'walmart_listing_status';
      skuUpdateKey = 'walmart_listing_sku';
    }
    else if (platform === 'Shopify') {
      data.shopify = true;
      statusUpdateKey = 'shopify_listing_status';
      skuUpdateKey = 'shopify_listing_sku';
    }

    dispatch(RemoveListing(data))
      .then(({ payload }) => {
        const { data } = payload || {};
        const { success } = data || {};
        if (success) {
          setPlatformDeleteModalData(initialPlatformDeleteModalData);
          dispatch(updateField({
            field: 'stagingAsins',
            index: rowIndex,
            key: statusUpdateKey,
            value: null
          }));
          dispatch(updateField({
            field: 'stagingAsins',
            index: rowIndex,
            key: skuUpdateKey,
            value: null
          }));
        }
      });
  };

  const handleGenerateStorageLabels = ({ staging_item, labelQty, expiryDate }) => {
    if (!isEmpty(staging_item)) {
      const { title, uuid, quantity, identifier, warehouse_user } = staging_item || {};
      const warehouseName = isObject(warehouse_user)
        ? !isEmpty(warehouse_user)
          ? `${warehouse_user?.first_name || ""} ${warehouse_user?.last_name || ""}`
          : "N/A"
        : warehouse_user || "N/A";

      generateStorageBarcode({
        warehouseName,
        uuid,
        title,
        stored_quantity: labelQty || quantity,
        identifier,
        labelType: "product",
      });
    }
  };

  const { amazon, walmart, shopify } = listingPlatforms || {};

  return (
    <WarehouseWrapper style={{ height: "100%" }}>
      <Spin size="large" tip="Processing..." spinning={
        userItemLoading ||
        listingToShopify ||
        fetchingStagingAsins ||
        updatingUnitPrice ||
        fetchingAffiliateUsers ||
        listingToShopifyAffiliates ||
        removingListing ||
        updatingUpc ||
        checkInLoading
      }>
        <div className="d-flex justify-content-between">
          <h2 className="color-secondary mb-0">Location Items</h2>
          <div className="d-flex gap-1 justify-content-end">
            <Button text="Add to Storage" className="outlined location-btn mb-0" onClick={() => {
              dispatch(GetAllLocations())
                .then(({ payload }) => {
                  const { success, locations } = payload?.data || {};
                  if (success) {
                    setLocationsList(locations);
                  }
                });
              dispatch(GetStorageUsersList())
                .then(({ payload }) => {
                  const { result } = payload?.data || {};
                  if (payload?.data?.length) {
                    setUsersList(payload?.data);
                  }
                });
              setAddtoStorage(true);
            }} />
            <Select
              width="330px"
              placeholder="Warehouse"
              loading={fetchingWarehouses}
              disabled={fetchingWarehouses}
              value={selectedWarehouse}
              onChange={(event) => {
                const value = event?.value || "";
                const label = event?.label?.props?.children?.[0]?.props?.children || "";
                setSelectedWarehouse(value ? { value, label } : "");
                handleFilter({ isDropDown: true, field: "warehouse_id", value });
              }}
              options={mappedWarehousesList}
              isClearable={true}
            />
            <SearchInput
              width="300px"
              placeholder="Search for Title / Identifier"
              onChange={({ target: { value } }) => handleFilter({ isDropDown: false, field: '', value })}
              className="location-search"
            />
            {
              is_affiliate_manager ?
                <Button
                  text="List to Platform"
                  className="outlined location-btn mb-0"
                  startIcon={<img src={warehouse} alt="warehouse" className="pr-8" />}
                  disabled={!selectedItemsId.length}
                  onClick={() => setPlatformDrawer(true)}
                /> :
                <Button
                  text="List to Platform"
                  className="outlined location-btn mb-0"
                  startIcon={<img src={warehouse} alt="warehouse" className="pr-8" />}
                  disabled={!selectedItemsId.length}
                  onClick={() => setListPlatformModal(true)}
                />
            }
          </div>
        </div>
        <div id="locationItems" style={{ height: "calc(100% - 102px)" }}>
          <AgGrid
            user_referrals={stagingAsins || []}
            defaultColDef={defaultColDef}
            columnDefinitions={locationItemsColDef}
            onSelectionChanged={onSelectionChange}
            rowHeight={70}
            gridReady={gridReady}
            className="locationGrid"
            rowSelection='multiple'
          />
        </div>
        <Pagination
          total={totalStagingAsinsCount}
          totalPages={ceil(totalStagingAsinsCount / per_page + 1)}
          pageNumber={page_num}
          pageLimit={per_page}
          handleLimitChange={(e) => handlePagination(e, "pageLimit")}
          handlePageNumberChange={(e) => handlePagination(e, "pageNumber")}
          handlePageChangeLeft={(e) => handlePagination(e, "pageNumber")}
          handlePageChangeRight={(e) => handlePagination(e, "pageNumber")}
          refreshGridClick={refreshGridClick}
        />
      </Spin>

      <Drawer show={stagingItemsDrawer} size="950px">
        <Spin tip="Loading..." spinning={fetchingStagingItems || disposeItemLoading || downloadLoading}>

          <div className="drawer-padding">
            <div className="d-flex gap-3 align-items-center">
              <span
                class="icon-arrow-left cursor-pointer"
                onClick={handleCloseStagingItemsDrawer}
              />
              <h2 className="mb-0 fs-18" style={{ color: '#4E5969' }}>Location Item</h2>
            </div>

            <div className="marketplace-information mb-4 mt-3">
              <div className="details d-flex gap-3">
                <div>
                  <img
                    src={image_url || "/images/no-image.png"}
                    alt="product"
                    width="90px"
                    height="94px"
                    className="drawer-img"
                  />
                </div>
                <div>
                  <h3 className="color-primary fs-16 mb-0">
                    {title}
                  </h3>
                  <div className="d-flex justify-content-between w-100">
                    <div className="mb-0 d-flex flex-column">
                      <span className="fs-12 color-gray weight-bold">
                        Identifier
                      </span>
                      <span>{asin}</span>
                    </div>
                    <div className="mb-0 d-flex flex-column">
                      <span className="fs-12 color-gray weight-bold">
                        Vendor SKU
                      </span>
                      <span>{vendor_sku || 'N/A'}</span>
                    </div>
                    <div className="mb-0 d-flex flex-column">
                      <span className="fs-12 color-gray weight-bold">
                        Available Qty
                      </span>
                      <span>{available_quantity || 0}</span>
                    </div>
                    <div className="mb-0 d-flex flex-column">
                      <span className="fs-12 color-gray weight-bold">
                        On Hold Qty
                      </span>
                      <span>{on_hold_quantity || 0}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <h3 className="fs-16" style={{ color: '#4E5969' }}>Staging Items</h3>
            </div>
            <div id="drawerItems" style={{ height: "calc(100% - 250px)" }}>
              <AgGrid
                user_referrals={stagingItems}
                defaultColDef={defaultColDef}
                columnDefinitions={drawerColumnDefinitions}
                rowHeight={50}
                className="locationGrid drawer-grid"
              />
            </div>
          </div>
        </Spin>
      </Drawer>

      <Drawer show={usersDrawer} size="752px">
        <Spin tip="Loading..." spinning={fetchingAffiliatesShopifyListing || removingAffiliateListing}>
          <div className="drawer-padding">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
              <div className="d-flex gap-3 align-items-center">
                <span
                  class="icon-arrow-left cursor-pointer"
                  onClick={() => {
                    setUsersDrawer(false);
                    listingsSearchRef?.current?.value && (listingsSearchRef.current.value = "");
                    setFilteredAffiliatesShopifyListings([]);
                  }}
                />
                <h2 className="mb-0 fs-18">User’s <span className="color-secondary">({affiliatesShopifyListings.length || 0})</span></h2>
              </div>
              <SearchInput
                placeholder="Search User’s"
                className="location-search"
                onChange={({ target }) => handleSearchAffiliatesListings(target.value)}
                inputRef={listingsSearchRef}
              />
            </Stack>
            <div>
              <AgGrid
                user_referrals={filteredAffiliatesShopifyListings}
                defaultColDef={defaultColDef}
                columnDefinitions={usersColumnDefinitions}
                rowHeight={50}
                className="locationGrid drawer-grid"
                height="110px"
              />
            </div>
          </div>
        </Spin>
      </Drawer>

      <Drawer show={platformDrawer} size="748px">
        <Spin tip="Loading..." spinning={fetchingStagingItems || disposeItemLoading || listingToShopifyAffiliates}>
          <div className="drawer-padding">
            <div className="d-flex gap-2 align-items-center">
              <span
                class="icon-arrow-left cursor-pointer"
                onClick={handleClosePlatformDrawer}
              />
              <h2 className="mb-0 fs-18">Choose Platform</h2>
            </div>
            <div className="platform-list d-flex my-4">
              <div className={`platform-check w-100 ${listingPlatforms.amazon ? "active-platform" : ''}`}
                onClick={() => setListingPlatforms(listingPlatforms => ({ ...listingPlatforms, amazon: !listingPlatforms.amazon }))}
              >
                <div>
                  <Checkbox checked={amazon} />
                </div>
                <img src={AmazonIcon} alt="Amazon" />
              </div>
              <div className={`platform-check w-100 ${listingPlatforms.walmart ? "active-platform" : ''}`}
                onClick={() => setListingPlatforms(listingPlatforms => ({ ...listingPlatforms, walmart: !listingPlatforms.walmart }))}>
                <div>
                  <Checkbox checked={walmart} />
                </div>
                <img src={WalmartIcon} alt="Walmart" />
              </div>
              <div className={`platform-check w-100 ${listingPlatforms.shopify ? "active-platform" : ''}`}
                onClick={() => setListingPlatforms(listingPlatforms => ({ ...listingPlatforms, shopify: !listingPlatforms.shopify }))}
              >
                <div>
                  <Checkbox checked={shopify} />
                </div>
                <img src={ShopifyLogo} alt="Shopify" />
              </div>
            </div>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2} mt={3}>
              <h2 className="mb-0 fs-18">Select User’s <span className="color-secondary">({affiliateUsers.length || 0})</span></h2>
              <SearchInput
                placeholder="Search User’s"
                className="location-search"
                onChange={({ target }) => handleSearchAffiliateUsers(target.value)}
                inputRef={affiliatesSearchRef}
              />
            </Stack>
            <div>
              {/* <div className="empty-data-container mb-2">
                <div className="no-active-store-notification drawer-no-active-store-notification">
                  <img className="notification-icon" src={IconInfo} />
                  You can not select user’s that don’t have active shopify store.
                </div>
              </div> */}
              <AgGrid
                user_referrals={filteredAffiliateUsers}
                defaultColDef={defaultColDef}
                columnDefinitions={platformColumnDefinitions}
                rowHeight={50}
                className="locationGrid"
                height="290px"
                onSelectionChanged={onUserSelectionChange}
                gridReady={affiliatesGridReady}
                rowSelection='multiple'
              />
            </div>
            <div className="d-flex align-items-center justify-content-end gap-4 mt-3">
              <Button
                text="Cancel"
                className="text-button mb-0"
                onClick={handleClosePlatformDrawer}
              />
              <Button
                text="Confirm"
                className="outlined mb-0 cursor-pointer"
                onClick={handleListToShopifyAffiliates}
                disabled={!selectedAffiliatesId.length || listingToShopifyAffiliates || (!amazon && !walmart && !shopify)}
              />
            </div>
          </div>
        </Spin>
      </Drawer>

      <Modal size="sm" show={disposeModal} centered={true}>
        <div className="text-center px-5 py-4">
          <span class="icon-dispose fs-2"></span>
          <h3 className="mt-4">
            Are you sure you want to dispose item ?
          </h3>
        </div>
        <div className="d-flex px-3 justify-content-between">
          <Button
            className="outlined"
            text="Cancel"
            onClick={() => setDisposeModal(false)}
          />
          <Button className="outlined" text="yes" onClick={handleDisposeItem} />
        </div>
      </Modal>

      <CustomModal
        open={platformDeleteModalData?.platform === 'All'}
        onHide={(e) => {
          e?.preventDefault();
          setPlatformDeleteModalData(initialPlatformDeleteModalData);
        }}
        closeText="No"
        saveText="Yes"
        centerAlign={true}
        className="delete-modal"
        onClose={(e) => {
          e?.preventDefault();
          setPlatformDeleteModalData(initialPlatformDeleteModalData);
        }}
        onSave={handleRemoveAffiliateListing}
      >
        <div className="d-flex flex-column align-items-center text-center">
          <img src={warningIcon} alt="warning" />
          <span className="fw-bold fs-18 text-color mt-3">
            Are you sure you want to Remove <br />
            this User?
          </span>
        </div>
      </CustomModal>

      <CustomModal
        open={['Amazon', 'Walmart', 'Shopify'].includes(platformDeleteModalData?.platform)}
        onHide={(e) => {
          e?.preventDefault();
          setPlatformDeleteModalData(initialPlatformDeleteModalData);
        }}
        closeText="No"
        saveText="Yes"
        centerAlign={true}
        className="delete-modal"
        onClose={(e) => {
          e?.preventDefault();
          setPlatformDeleteModalData(initialPlatformDeleteModalData);
        }}
        onSave={() => is_affiliate_manager ? handleRemoveAffiliateListing() : handleRemovePlatformListing()}
      >
        <div className="d-flex flex-column align-items-center text-center">
          <img src={warningIcon} alt="warning" />
          <span className="fw-bold fs-18 text-color mt-3">
            Are you sure you want to Delete <br />
            the {platformDeleteModalData?.platform} platform
          </span>
        </div>
      </CustomModal>

      <Modal size="auto" show={listPlatformModal} onHide={() => {
        setListPlatformModal(false);
        setListingPlatforms(initialListingPlatforms);
      }} centered={true}>
        <WarehouseWrapper>
          <div className="px-4 py-3">
            <span className="color-secondary fw-bold">List to Multiple Platform</span>
            <div className="platform-list d-flex my-4">
              <div className={`platform-check ${listingPlatforms.amazon ? "active-platform" : ''}`}
                onClick={() => setListingPlatforms(listingPlatforms => ({ ...listingPlatforms, amazon: !listingPlatforms.amazon }))}
              >
                <div>
                  <Checkbox checked={amazon} />
                </div>
                <img src={AmazonIcon} alt="Amazon" />
              </div>
              <div className={`platform-check ${listingPlatforms.walmart ? "active-platform" : ''}`}
                onClick={() => setListingPlatforms(listingPlatforms => ({ ...listingPlatforms, walmart: !listingPlatforms.walmart }))}
              >
                <div>
                  <Checkbox checked={walmart} />
                </div>
                <img src={WalmartIcon} alt="Walmart" />
              </div>
              <div className={`platform-check ${listingPlatforms.shopify ? "active-platform" : ''}`}
                onClick={() => setListingPlatforms(listingPlatforms => ({ ...listingPlatforms, shopify: !listingPlatforms.shopify }))}
              >
                <div>
                  <Checkbox checked={shopify} />
                </div>
                <img src={ShopifyLogo} alt="Shopify" />
              </div>
            </div>
            <div className="d-flex gap-3 justify-content-end">
              <Button
                className="text-button"
                text="Cancel"
                disabled={listingToShopify}
                onClick={() => {
                  setListPlatformModal(false);
                  setListingPlatforms(initialListingPlatforms);
                }}
              />
              <Button className="primary" text="Submit"
                onClick={handleListToShopify}
                disabled={(!amazon && !walmart && !shopify) || listingToShopify}
              />
            </div>
          </div>
        </WarehouseWrapper>
      </Modal>

      <AddtoStorageModal
        setAddtoStorage={setAddtoStorage}
        addtoStorage={addtoStorage}
        userList={userList}
        setLocationsList={setLocationsList}
        locationsList={locationsList}
      />
    </WarehouseWrapper>
  );
};

export default Index;
