// eslint-disable-next-line import/no-unresolved
import generateBarcode from 'pdf417';

// eslint-disable-next-line import/prefer-default-export
export const mix2DBarcode = ({ shipmentId = '', items = [] }) => {
  let stringToEncode = `AMZN,PO:${shipmentId}`;
  items.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (item[key]) {
        stringToEncode += `,${key.toUpperCase()}:${item[key].toString().toUpperCase()}`;
      }
    });
  });

  return generateBarcode(stringToEncode, 15, 15);
};
