import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  pendingPos: [],
  pending_PoIndex: {},
  total_records: 0,
  filters: [],
  sortOptions: {},
  stats: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  }
};

const pendingPos = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case "SET_FILTERS_PENDING_PO_DETAILS":
    case "SET_SORT_OPTIONS_PENDING_PO_DETAILS": {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_PENDING_PO_DETAILS_REQUEST':
    case 'GET_TOTAL_QUANTITIES_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case "GET_PENDING_PO_DETAILS_SUCCESS": {
      const { pending_pos, pending_po_index, total_records } = action.payload;
      return {
        ...state,
        fetching: false,
        pendingPos: pending_pos,
        pending_PoIndex: pending_po_index,
        total_records
      };
    }
    case 'GET_TOTAL_QUANTITIES_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      };
    }
    case 'SET_PAGINATION_PENDING_PO_DETAILS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    case 'GET_PENDING_PO_DETAILS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_TOTAL_QUANTITIES_FAILED': {
      return {
        ...state,
        fetching: false
      }
    }
    default:
      return state;
  }
};

export default pendingPos;
