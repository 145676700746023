import { cloneDeep } from 'lodash';
const inittialState = {
  fetching: false,
  uploadingToS3: false,
  pagination: {
    page_num: 1,
    per_page: 25
  },
  total: 0,
  gridData: null,
  suggestions: [],
  search_term: ''
}

const reducer = (state = cloneDeep(inittialState), action) => {
  switch (action.type) {
    case 'FETCHING_PROFITABLE_MATCHES_REQUEST':
    case 'APPROVE_REQUEST':
    case 'DIS-APPROVE_REQUEST': {
      return {
        ...state,
        fetching: true
      }
    }
    case 'FETCHING_PROFITABLE_MATCHES_SUCCESS': {

      return {
        ...state,
        fetching: false,
        ...action.payload
      }
    }
    case 'APPROVE_REQUEST_SUCCESS':
    case 'DIS-APPROVE_REQUEST_SUCCESS':
    case 'SET_FILTER_FOR_PROFITABLE_MATCHES': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      }
    }
    case 'RESET_FILTER_FOR_PROFITABLE_MATCHES': {
      return {
        ...state,
        search_term: ''
      }
    }
    case 'FETCHING_PROFITABLE_MATCHES_FALSE':
    case 'APPROVE_REQUEST_FAILED':
    case 'DIS-APPROVE_REQUEST_FAILED': {
      return {
        ...state,
        fetching: false
      }
    }
    case 'CHANGE_PER_PAGE_FOR_PROFITABLE_MATCHES': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    case 'CHANGE_PAGE_NUM_FOR_PROFITABLE_MATCHES': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    case 'GET_PRESIGNED_URL': {
      return {
        ...state,
        uploadingToS3: true
      }
    }
    case 'GET_PRESIGNED_URL_FAILED':
    case 'GET_PRESIGNED_URL_SUCCESS': {
      return {
        ...state,
        uploadingToS3: false
      }
    }

    case 'SET_SUGGESTION_MATCHES': {
      return {
        ...state,
        suggestions: action.payload
      }
    }
    case 'CLEAR_SUGGESTION_MATCHES': {
      return {
        ...state,
        suggestions: []
      }
    }

    default: {
      return state
    }
  }
}

export default reducer;
