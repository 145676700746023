import Styled from "styled-components";
const TilesWrapper = Styled.div`
    /* overflow:auto; */
    margin-top: 8px;
    height: ${(props) => `calc(100vh - ${props.height || ""})`};
    .receive-box{
        width: 1620px;
    }
    .ant-spin-blur{
      z-index:0;
    }
.drawer-padding{
    padding:30px;
    height:100vh;
    overflow:auto;
    .header{
        grid-column-gap: 12px;
        margin-top: -2px;
        margin-bottom:21px;
    }
    .input-space{
        grid-column-gap:30px;
    }

    .badge-sucess{
        background-color:${({ theme }) => theme["success-color"]}10;
        border:1px solid ${({ theme }) => theme["success-color"]};
        font-size:${({ theme }) => theme["base-font-size-small"]};
        border-radius:50px;
        padding:0 8px;
        max-width: 75px;
        color:${({ theme }) => theme["success-color"]};
        margin: 6px 0px;
    }
    .badge-danger{
        background-color:${({ theme }) => theme["danger-color"]}10;
        border:1px solid ${({ theme }) => theme["danger-color"]};
        font-size:${({ theme }) => theme["base-font-size-small"]};
        border-radius:50px;
        padding:0 8px;
        color:${({ theme }) => theme["danger-color"]};
        max-width: 75px;
        margin: 6px 0px;
    }
    .badge-info{
        background-color:${({ theme }) => theme["dark-yellow"]}10;
        border:1px solid ${({ theme }) => theme["dark-yellow"]};
        font-size:${({ theme }) => theme["base-font-size-small"]};
        border-radius:50px;
        padding:0 8px;
        color:${({ theme }) => theme["dark-yellow"]};
        max-width: 87px;
        margin: 0px 0px 4px 0px;
    }
    &.drawer-checkin{
        .drawer-header{
            margin-bottom:16px;
            .btn{
                margin-bottom:0;
                font-size:14px;
                line-height:16px;
                padding:8px 24px;
            }
        }
        .drawer-header-buttons{
            gap:20px;
        }
        .header{
            margin-bottom:0;
        }
        .icon-printer{
            font-size:15px;
        }
    }
}
.badge-warning {
    background-color: rgba(255, 153, 0, 0.05);;
    border:1px solid ${({ theme }) => theme["orange_peel"]};
    font-size:${({ theme }) => theme["base-font-size-small"]};
    border-radius:10px;
    font-weight:600;
    padding:0px 9px;
    color:${({ theme }) => theme["orange_peel"]};
    line-height: 20px;
}
.marketplace-information{
    border: 1px solid ${({ theme }) => theme["outline-color"]};
    background-color: ${({ theme }) => theme["outline-color"]}20;
    border-radius:${({ theme }) => theme["border-radius-base"]};
    padding: ${({ theme }) => theme["base-font-size"]};
    margin-right: 6px;
    margin-bottom: 0px;
    padding: 10px 16px;
    .receiving-product{
     width: 338px;
    }
    .details{
        display:flex;
        &.width-0{
            justify-content:space-between;
            div{
                min-width:auto;
            }
        }
        .border-right{
            width: 1px;
            height: 24px;
            background-color: ${({ theme }) => theme["border-color-base"]};
            margin: 30px 10px 0;
        }
        .vendor {
            span {
                &:nth-child(2) {
                    white-space: nowrap; 
                    width: 120px; 
                    overflow: hidden;
                    text-overflow: ellipsis; 
                }
                &:nth-child(3) {
                    max-width: 120px;
                }
            }
        }
        div{
                display: flex;
                flex-direction:column;
                flex-wrap:wrap;
                min-width: 154px;
              
                &.created-date{
                    min-width: 308px;
                }
                span:nth-child(1){
                    font-size: ${({ theme }) => theme["base-font-size-small"]};
                    padding-bottom:0px;
                    color:${({ theme }) => theme["dusty_grey"]} ;
                    text-transform: capitalize;
                    margin-bottom:8px;
                    font-weight: 700;
                }
                span:nth-child(2){
                    font-size: ${({ theme }) => theme["medium-font-size"]};
                    color:${({ theme }) => theme["secondary-color"]} ;
                    line-height:16px;
                }

                span:nth-child(2),
                span:nth-child(3){
                    &.badge-sucess{
                        background-color:${({ theme }) =>
    theme["success-color"]}10;
                        border:1px solid ${({ theme }) =>
    theme["success-color"]};
                        font-size:${({ theme }) => theme["font-size-small"]};
                        border-radius:50px;
                        font-weight:600;
                        padding:0 8px;
                        max-width: 75px;
                        color:${({ theme }) => theme["success-color"]};
                        margin: 6px 0px;
                    }
                    &.badge-primary{
                        background-color:${({ theme }) =>
    theme["primary-color"]}10;
                        border:1px solid ${({ theme }) =>
    theme["primary-color"]};
                        font-size:${({ theme }) =>
    theme["base-font-size-small"]};
                        border-radius:50px;
                        padding:0 8px;
                        color:${({ theme }) => theme["primary-color"]};
                        max-width: 39px;
                        margin: 6px 0px 0;
                        font-weight:600;
                    }
                    &.badge-danger{
                        background-color:${({ theme }) =>
    theme["danger-color"]}10;
                        border:1px solid ${({ theme }) =>
    theme["danger-color"]};
                        font-size:${({ theme }) =>
    theme["base-font-size-small"]};
                        border-radius:50px;
                        padding:0 8px;
                        color:${({ theme }) => theme["danger-color"]};
                        max-width: 75px;
                        margin: 6px 0px 0;
                        font-weight:600;
                    }
                }
                span:nth-child(3){
                    font-size:12px;
                    color: ${({ theme }) => theme["placeholder-color"]};
                    margin-top:4px;
                }
                &.unit-details{
                    flex-direction:row;
                    gap:24px;
                    flex-wrap: nowrap;
                    div{
                        span{
                            &:nth-child(1){
                                font-weight:${({ theme }) => theme["bold-weight"]};
                                font-size:${({ theme }) =>
    theme["font-size-small"]};
                                line-height: 12px;
                                color:${({ theme }) => theme["regent_gray"]};
                                margin-bottom: 4px;
                            }
                            &:nth-child(2){
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                color:${({ theme }) =>
    theme["secondary-color"]};
                            }
                            &:nth-child(3){
                                font-weight:${({ theme }) => theme["bold-weight"]};
                                font-size: 10px;
                                color:${({ theme }) =>
    theme["secondary-color"]};
                            }
                        }
                    }
                }
            }
        }
    }  
 .box-popover{
    width: 242px;
    &.box-number{
        color: #78909C;
    }
 }

 .tiles-box{
    border: 1px solid #CFD8DC;
    border-radius:8px;
    margin-bottom:${({ theme }) => theme["base-font-size"]};
    .tiles-table{
        margin-bottom:0;

        thead{
            tr{
                th{
                    padding:12px 24px;
                    &:first-of-type{
                        border-top-left-radius: 8px;
                    }
                    background-color:${({ theme }) => theme["aqua_haze"]};
                    color:${({ theme }) => theme["regent_gray"]};
                     font-size:${({ theme }) => theme["base-font-size-small"]};
                     width:200px;
                    max-width:200px;    
                     white-space: nowrap;
                     overflow: hidden;
                     text-overflow: ellipsis;
                                        
                }
            }
        }
        tbody{
            tr{
                td{
                    padding:12px 24px;
                    border-bottom-width:0;
                    width:200px;
                    max-width:215px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    span:nth-child(1){
                    font-size: ${({ theme }) => theme["medium-font-size"]};
                    padding-bottom:0px;
                    color:${({ theme }) => theme["regent_gray"]} ;
                    text-transform: capitalize;
                    display: block;
         
                    &.badge-sucess{
                        background-color:${({ theme }) =>
    theme["success-color"]}10;
                        border:1px solid ${({ theme }) =>
    theme["success-color"]};
                        font-size:${({ theme }) =>
    theme["base-font-size-small"]};
                        border-radius:50px;
                        padding:0 0px;
                        color:${({ theme }) => theme["success-color"]};
                        text-align:center;
                    }
                    &.badge-primary{
                        background-color:${({ theme }) =>
    theme["primary-color"]}10;
                        border:1px solid ${({ theme }) =>
    theme["primary-color"]};
                        font-size:${({ theme }) =>
    theme["base-font-size-small"]};
                        border-radius:50px;
                        padding:0 8px;
                        color:${({ theme }) => theme["primary-color"]};
                        text-align:center;

                    }
      
                }
                span:nth-child(2){
                    font-size: ${({ theme }) => theme["base-font-size-small"]};
                    color:${({ theme }) => theme["placeholder-color"]} ;

                    &.badge-sucess{
                        background-color:${({ theme }) =>
    theme["success-color"]}10;
                        border:1px solid ${({ theme }) =>
    theme["success-color"]};
                        font-size:${({ theme }) =>
    theme["base-font-size-small"]};
                        border-radius:50px;
                        padding:0 8px;
                        max-width: 39px;
                        color:${({ theme }) => theme["success-color"]};
                    }
                    &.badge-primary{
                        background-color:${({ theme }) =>
    theme["primary-color"]}10;
                        border:1px solid ${({ theme }) =>
    theme["primary-color"]};
                        font-size:${({ theme }) =>
    theme["base-font-size-small"]};
                        border-radius:50px;
                        padding:0 8px;
                        color:${({ theme }) => theme["primary-color"]};
                        max-width: 39px;
                        margin-top: 6px;

                    }
                } 
                }
            }
        }
 }
 }
 .stepper{
     display:flex ;
    align-items: center;
    padding:0 32px ;
        margin-bottom: 40px;
     .stepper-border{
         height:1px;
         background: linear-gradient(90deg, #1565D8 0%, #C4C4C4 100%);
         margin:0 12px;
         width: 100%;
     }
     .title{
                        font-size:${({ theme }) =>
    theme["base-font-size-small"]}; 
                        margin-top:8px;
                        position:absolute ;
                        white-space:nowrap;
                        top: 11px;
                        &.left{
                            left: -32px;
                        }
                        &.right{
                            right:-30px;
                        }
     }
     .selected{
          color:${({ theme }) => theme["success-color"]}; 
     }
     
 }
 .progress-height{
    height:10px;
    min-width:124px;
 }
 .icon-arrow-right{
     &.purple-icon{
        &::before{
         color:${({ theme }) => theme["purple-color"]}; 
        }
     }
 }
  .icon-printer{
     &.success-color{
        &::before{
         color:${({ theme }) => theme["success-color"]}; 
        }
     }
 }
 .checkin-quantity-column{
    min-width:233px;
}
.storage-po-quantity-column{
    min-width:275px;
}
.action-icons {
    min-width: 130px;
}
  .empty-data-container{
                                display:block;
                                .nodata-notification{
                                    background: ${({ theme }) =>
                                      theme["info-color"]};
                                    border: 1px solid #00A3FF;
                                    border-radius: 4px;
                                    display: flex;
                                    align-items:center;
                                    gap: 8px;
                                    padding: 8px 12px;
                                    padding-right:18px;
                                    font-weight: 600;
                                    font-size: 16px;
                                    line-height: 20px;
                                    color:${({ theme }) =>
                                      theme["black-color"]};
                                    .notification-icon{
                                        width: 20px;
                                    }
                                    .notification-text{
                                        display: flex;
                                        gap:8px;
                                        align-items:center;
                                    }
                                    .notification-toggle{
                                        color:${({ theme }) =>
                                          theme["primary-color"]};
                                        a{
                                            &:hover{
                                                text-decoration:underline;
                                            }
                                        }
                                    }
                                    &.drawer-nodata-notification{
                                        font-size:12px;
                                        line-height:14px;
                                        .notification-icon{
                                            width:16px;
                                        }
                                    }
                                }
                                .checkin-main-nodataimg{
                                    margin-top:42px;
                                }
                            } 
`;
const ShipmentPlanWrapper = Styled.div`
/* .ant-spin-nested-loading{
    height: calc(100vh - 22vh)
} */
.shipping-loading {
  overflow: auto;
  .ant-spin-nested-loading{
 
  }
}
margin-left:4px;
.heading{
    margin-left: 13px;
}
.left-column{
    padding-left:10px;
    padding-right: 0px;
}
.right-column{
    h2{
        margin-bottom:14px;
    }
    .no-data{
        padding-top: 97px;
    padding-right: 22px;
    }
    .caption{
        margin-top: 44px;
     margin-right: 33px;

    }
}
.user-heading{
    font-size:16px;

}
.details{
    margin-bottom:14px;
}
.margin-bottom-29{
    margin-bottom:29px;  
}
.mb-14{
    margin-bottom: 14px; 
}
.variants{
    word-spacing: 4px;
    margin-bottom: 7px;
}

.quantity-info{
    margin-left: 3px;
    grid-column-gap: 65px;
    span{
    font-size:${({ theme }) => theme["base-font-size-small"]};   
    color:${({ theme }) => theme["regent_gray"]}; 
    margin-bottom: 4px;
    display: inline-block;
}
h4{
    font-size:${({ theme }) => theme["medium-font-size"]};    
    color:${({ theme }) => theme["secondary-color"]};
}

}
.input-range{
    margin-top: 35px;
    width:89%;
}
.text-ellipse{
  overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60px;
    display:inline-block;
}
.user-info{
    display:flex;
    gap:32px;
    &.user-info-wrapper{
      grid-gap:4px;
      span{
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

              &::after{
                  display:none ;
              }
            margin-right: 0;
          }
    }
    span{
        font-size:${({ theme }) => theme["medium-font-size"]};  
        color:${({ theme }) => theme["secondary-color"]};
        position: relative;
        &::after{
            content: "";
            display: inline-block;
            width: 1px;
            background-color: #e1e1e1;
            height: 15px;
            margin-left: 8px;
            position: absolute;
            top: 4px;
        }
        &:last-child{
            &::after{
                display:none ;
            }
          margin-right: 0;
        }
    }
    &.user-info-plans{
        padding-left:19px;
        span{
            font-size: 12px;
            font-weight: 600;
            line-height: 14px; 
            letter-spacing: 0.06px;
            color:${({ theme }) => theme["placeholder-color"]};
            margin-right: 32px;
            &::after{
            margin-left: 16px;
        }
            em{
                color: #4E5969;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
            }
        }
    }
}
.type-shipment{
  margin-top: -20px;
  margin-left:210px;
  text-align:center;
  font-weight:600;
  border-radius:4px;
  line-height: 18px;
  height:20px;
  font-size:${({ theme }) => theme["base-font-size-small"]};
        &.standard{
          background-color:#E8F0FB ;
          color:${({ theme }) => theme["primary-color"]} ;
          border:1px solid ${({ theme }) => theme["primary-color"]};
        }
        &.warning{
          background-color:#FFF5E5 ;
          color:${({ theme }) => theme["primary-color"]} ;
          border:1px solid ${({ theme }) => theme["warning-color"]};
        }
        &.danger{
          background-color:#FAE6E6 ;
          border: 1px solid #CF0909;
          color: #CF0909;
        }
      }
.shipment-plans{
    margin-top:16px;
        .plans{
            .plans-inner-content{
                display: flex;
            justify-content: space-between;
     
            div{
                display: flex;
                flex-direction:column;
                justify-content:center;
                span:nth-child(1){
                    font-size: ${({ theme }) => theme["base-font-size-small"]};
                    padding-bottom:0px;
                    color:${({ theme }) => theme["regent_gray"]} ;
                    text-transform: capitalize;
       
                }
                /* span:nth-child(2){
                    font-size: ${({ theme }) => theme["medium-font-size"]};
                    color:${({ theme }) => theme["secondary-color"]};
            } */
            }
        }
        &.active{
            background:rgba(15, 113, 255, 0.02);
        }
}
.ant-collapse {
    margin-bottom:24px;
    .ant-collapse-header{
        padding:8px 35px 8px 8px;
        .ant-collapse-arrow{
          right:9px;
          top:38px;
        }
    }
}
}
.shipment-box{
    border: 1px solid #E6E6E6;
    padding:16px;
    display:flex;
    border-radius:4px;
    .ship-column{
        span:first-child{
           font-size: ${({ theme }) => theme["base-font-size-small"]};
                 color:${({ theme }) => theme["regent_gray"]} ;
        }
    }
}
.product-info{
    background: rgba(238, 241, 245, 0.2);
    border-radius: 4px;
    border: 1px solid #E6E6E6;
}
.height-calc{
    height:calc(100vh - 600px);
    overflow:auto ;
}
.count{
      background-color:${({ theme }) => theme["black-color"]}04; 
      color:${({ theme }) => theme["primary-color"]};
      border-radius: 50px;
      font-size:${({ theme }) => theme["base-font-size-small"]}
      margin-left:8px;
      cursor:pointer ;
}
.review-shipping-label {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    background: #FFF5E5;
    border: 2px solid ${({ theme }) => theme["warning-color"]};
    border-radius: 8px;
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme["secondary-color"]};
    margin-bottom: 16px;
    img {
        margin-top: 4px;
    }
}
.percentage-ratio{
    display: flex;
    align-items:center;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px; 
    color: #009C34;
    font-style:normal;
}
.shipment-error-alert{
    color: #CF0909;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    padding-left: 20px;
    margin:10px 0 0;
}
  .empty-data-container{
                                display:block;
                                .nodata-notification{
                                    background: ${({ theme }) =>
                                      theme["info-color"]};
                                    border: 1px solid #00A3FF;
                                    border-radius: 4px;
                                    display: flex;
                                    align-items:center;
                                    gap: 8px;
                                    padding: 8px 12px;
                                    padding-right:18px;
                                    font-weight: 600;
                                    font-size: 16px;
                                    line-height: 20px;
                                    color:${({ theme }) =>
                                      theme["black-color"]};
                                    .notification-icon{
                                        width: 20px;
                                    }
                                    .notification-text{
                                        display: flex;
                                        gap:8px;
                                        align-items:center;
                                    }
                                    .notification-toggle{
                                        color:${({ theme }) =>
                                          theme["primary-color"]};
                                        a{
                                            &:hover{
                                                text-decoration:underline;
                                            }
                                        }
                                    }
                                    &.drawer-nodata-notification{
                                        font-size:12px;
                                        line-height:14px;
                                        .notification-icon{
                                            width:16px;
                                        }
                                    }
                                }
                                .checkin-main-nodataimg{
                                    margin-top:42px;
                                }
                            } 
    .choose-marketplace {
      margin-bottom: 10px;
        label {
          font-size: 12px;
          line-height: 14px;
          margin-bottom: 4px;
          color: ${({ theme }) => theme['regent_gray']}
        }
        button {
          width: 100%;
          border-radius: 8px;
          color: ${({ theme }) => theme['secondary-color']};
          border-color: ${({ theme }) => theme['information-color']};
          font-size: 14px;
          font-weight: 600;
          &:hover, &:disabled {
            background-color: transparent;
          }
          &.selected-marketplace {
            border-width: 2px;
            background-color: #F1FBFE;
          }
        }
    }
    .shipping-alert {
      border: 2px solid #CF0909;
      background-color: #CF09091A;
      border-radius: 8px;
      padding: 16px 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      &.sku-marketplace{
        div{
          .sku-market{
            color:#4E5969
          }
        }
      
      }
      button {
        margin-bottom: 0;
        background-color: #fff;
        font-weight: 700;
        &:hover {
          color: ${({ theme }) => theme['primary-color']};
        }
      }
      div {
        display: flex;
        align-items: center;
        gap: 16px;
        span {
          font-size: 12px;
          font-weight: 600;
          line-height: 14px;
          color: ${({ theme }) => theme['regent_gray']};
          span {
            font-size: 16px;
            font-weight: 700;
            color: #CF0909;
          }
        }
      }
    }
    .qty-location-btn {
      font-size: 10px;
      width: 160px;
      text-align: left;
      &:hover {
        img {
         filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(327deg) brightness(96%) contrast(104%);
        }
       }
      img {
        filter: invert(42%) sepia(91%) saturate(5555%) hue-rotate(210deg) brightness(102%) contrast(100%);
      }
    }
  .shipment-user-box {
    border: 1px solid ${({ theme }) => theme['information-color']};
    background-color: #F1FBFE;
    padding: 4px 8px;
    border-radius: 4px;
  }
  .shipments-steps {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px;
    div {
      background-color: ${({ theme }) => theme['primary-color']};
      color: ${({ theme }) => theme['white-color']};
      min-width: 24px;
      height: 24px;
      border-radius: 50%;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    span {
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      color: ${({ theme }) => theme['secondary-color']};
    }
  }
  .change-background{
    background-color:#E5F6FF ;
    border:1px solid #00A3FF;
    border-radius:8px;
    padding:8px 16px;
  }
  .ship-po-box {
    border: 2px solid   ${({ theme }) => theme['primary-color']};
    background-color:  #F3F7FD;
    border-radius: 10px;
    padding: 8px 12px;
    margin-bottom: 8px;
    &.selected{
      border: 2px solid   ${({ theme }) => theme['success-color']};
    background-color: #E5F5EB;
    }
    .user-info {
      gap:16px;
      span {
        font-size: 12px;
        font-weight: 600;
        min-width: 16px;
        display:inline-block;
        span {
          font-size:${({ theme }) => theme["medium-font-size"]};
        }
        &::after {
          background-color: ${({ theme }) => theme['information-color']};
          margin-left: 8px;
        }
      }
      &.selected-pos {
        display: flex;
        align-items: center;
        span {
          span {
            color: ${({ theme }) => theme['primary-color']};
            font-weight: 700;
          }
          &.pos {
            font-size:${({ theme }) => theme["base-font-size"]};
            font-weight: 700;
            color: #000;
            span {
              margin-right: 2px;
              margin-left: 2px;
              &::after {
                width: 0;
              }
            }
          }
        }
      }
    }
    button {
      font-size:${({ theme }) => theme["medium-font-size"]};
    }
  }
  .shipment-divider {
    width: 240px;
    height: 3px;
    background-color: ${({ theme }) => theme['information-color']};
    border-radius: 40px;
    margin: 12px auto;
  }
  .progress-bar{
          margin-right: 24px;
          .ant-progress-inner{
            .ant-progress-text{
            font-size:${({ theme }) => theme['font-size-small']};
            color: ${({ theme }) => theme['success-color']};  
            line-height:12.1px;

          }
          }
        
        }
`;
const DrawerWrapper = Styled.div`
.drawer-padding{
    padding:30px;
    height:100vh;
    overflow:auto;
    .product-details{
    background-color: ${({ theme }) => theme['mercury-color']}20; 
    padding:24px;
    border:1px solid ${({ theme }) => theme['outline-color']};
    border-radius:4px;
    .info{
                 div{
                     display:flex;
                     flex-direction:column;
                    span:nth-child(1){
                    font-size: ${({ theme }) => theme['base-font-size-small']};
                    padding-bottom:0px;
                    color:${({ theme }) => theme['regent_gray']} ;
                    text-transform: capitalize;
                }
                    span:nth-child(2){
                        font-size: ${({ theme }) => theme['medium-font-size']};
                        color:${({ theme }) => theme['secondary-color']} ;
                    }
                 }
                 .ellipse {
                    white-space: nowrap; 
                    overflow: hidden;
                    text-overflow: ellipsis; 
                 }
    }
    .units {
        div {
            span:first-child{
                font-size: ${({ theme }) => theme['medium-font-size']};
                color:${({ theme }) => theme['secondary-color']} ;
            }
            span:nth-child(2) {
                font-size: ${({ theme }) => theme['font-size-small']};
                color:${({ theme }) => theme['regent_gray']} ;
            }
        }
    }
    .note-text {
        width: 300px;
    }
    .product-status {
        width: 175px;
    }
}

}
.add-weight{
            grid-column-gap:18px;
            label{
                margin-bottom:3px;
                font-size:${({ theme }) => theme['base-font-size-small']};
                color:${({ theme }) => theme['regent_gray']}
            }
            .input-group{
                flex-wrap:nowrap;
            }
            .form-control{
                border-right:0;
                height:32px;
                width: 100px;
                font-size:${({ theme }) => theme['medium-font-size']};
                    border-top-right-radius: 0px;
                       border-bottom-right-radius: 0px;
                &::placeholder{
                color:${({ theme }) => theme['disabled-color']};
                font-size:${({ theme }) => theme['medium-font-size']};   
                }
            }
            .input-group-text{
                background:transparent;
                font-size:${({ theme }) => theme['medium-font-size']};
                border-left:0;
                height:32px;
            }
            .count-input {
              .form-control{
                border-right:1px solid #ced4da;
                width: 212px;
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
            }
            }
        }

.create-shipment{
    h4{
        font-size:${({ theme }) => theme['base-font-size']}; 
        margin-bottom:12px;
    }
    .shipment-plan{
        min-width:205px;
        padding:12px;
        border: 1px solid ${({ theme }) => theme['outline-color']};
        border-radius: ${({ theme }) => theme['border-radius-base']};;
        cursor:pointer;
        span{
            color:${({ theme }) => theme['secondary-color']}; 
            font-size:${({ theme }) => theme['medium-font-size']};
        }
        &.active{
            background-color:${({ theme }) => theme['primary-color']}; 
            span{
                color:${({ theme }) => theme['white-color']};  
            }
        }
    }
}
.sub-heading{
    margin-top:24px;
    h4{
        font-size:${({ theme }) => theme['base-font-size']}; 
        margin-bottom:12px;
    } 
    .input-details {
        width: 100%;
        height: calc(100vh - 400px);
        border: 1px solid ${({ theme }) => theme['outline-color']};
        border-radius: 4px;
        padding: 16px;
        position: relative;

        .stepper-btn {
            position: absolute;
            bottom: 8px;
            right: 16px;
        }
    }
}
.box-category{
    h4{
        font-size:${({ theme }) => theme['base-font-size']}; 
        margin-bottom:12px;
    }  
}
.info-status {
    background: rgba(0, 163, 255, 0.05);
    border: 1px solid ${({ theme }) => theme['information-color']};
    border-radius: 4px;
    padding: 8px 12px;
}
.badge-success {
    background-color:${({ theme }) => theme['success-color']}10;
    border:1px solid ${({ theme }) => theme['success-color']};
    font-size:${({ theme }) => theme['base-font-size-small']};
    border-radius:50px;
    padding:0 8px;
    color:${({ theme }) => theme['success-color']};
}
.badge-danger {
    background-color:${({ theme }) => theme['danger-color']}10;
    border:1px solid ${({ theme }) => theme['danger-color']};
    font-size:${({ theme }) => theme['base-font-size-small']};
    border-radius:50px;
    padding:0 8px;
    color:${({ theme }) => theme['danger-color']};
}
.box-height {
    height: 180px;
    overflow: auto;
}
.add-box {
    border: 1px solid ${({ theme }) => theme['primary-color']};
    border-radius: 4px;
    width: 32px;
    height: 32px;
    color: ${({ theme }) => theme['primary-color']};
    cursor: pointer;
}
`
const InboundWrapper = Styled.div`
margin-bottom:24px;
    .warehouse{
        display: flex;
        grid-column-gap: 30px;
        &.fbm-inbound{
            grid-column-gap: 30px;
            margin-bottom: 23px;
        }
        .orders{
            border-radius:${({ theme }) => theme['border-radius-base']};
            width:30%;
            padding: 13px 21px 11px 24px;
            .white-bg{
            width: 36px;
            height: 36px;
            background-color:${({ theme }) => theme['white-color']};
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            }
            &.fbm-order{
                width:26%;  
                padding: 11px 20px 14px 24px;
                border-radius:8px;
                h1{
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 73px;
                }
            }
            &.total{
            background-color: ${({ theme }) => theme['primary-color']}03; 
            border:1px solid  ${({ theme }) => theme['primary-color']}; 
            .icon-total::before{
                color:${({ theme }) => theme['black-color']} ; 
            } 
            }
            &.shipped{
                background-color: #F1FBFE; 
                border:1px solid  #00A3FF;  
                max-width:113px;
               min-width:113px; 
            }
            &.storage-orders{
                background-color: ${({ theme }) => theme['success-color']}03; 
                border:1px solid  ${({ theme }) => theme['success-color']};   
            }
            &.missing{
                background-color: ${({ theme }) => theme['warning-color']}05; 
                border:1px solid  ${({ theme }) => theme['warning-color']};  
                max-width:156px;
               min-width:156px; 
            }
            &.due-date{
                background-color: ${({ theme }) => theme['pink-color']}03; 
                border:1px solid  ${({ theme }) => theme['pink-color']}; 
                max-width:121px;
                min-width:121px; 
            }
            &.standard{
                background-color: ${({ theme }) => theme['dark-yellow']}05; 
                border:1px solid  ${({ theme }) => theme['dark-yellow']}; 
            }
        }
      
    }  
    .inbound-bar{
        display: flex;
        gap:0px;
        justify-content:space-between;
        align-items:center;
        background-color:${({ theme }) => theme['aqua_haze']};
        padding:15px 15px;
        border: 1px solid ${({ theme }) => theme['outline-color']};
        border-radius:8px;
    }
    .inbound-buttons{
        display: flex;
        align-items:center;
        gap:16px;
        .btn{
            font-size: 14px;
            line-height: 16px; 
            padding: 15px 16px;
            margin-bottom:0;
            &.w-180{
              min-width:180px;
            }
            &.w-160{
              min-width:170px;
              padding: 14px 11px;
              background-color:${({ theme }) => theme['layout-sider-background-light']};
              &:hover{
                color:${({ theme }) => theme['primary-color']}
              }
            }
            &.w-120{
              min-width:120px;
              background-color:#fff;
              background-color:${({ theme }) => theme['layout-sider-background-light']};
              &:hover{
                color:${({ theme }) => theme['primary-color']}
              }
            }
      
        }
    }
    .inbound-stats{
        .warehouse{
            gap:8px;
            margin-bottom:0;
            .orders{
                gap:8px;
                padding:8px 6px;
                justify-content: center;
                align-items: center;
                min-width:168px;
                max-width:168px;
                h1{
                  font-size:30px;
                }
            }
        }
    }
    .index{
        width:24px;
        height:24px;
        background-color:${({ theme }) => theme['primary-color']};
        border-radius:50px;
    }
    &.period-wrapper {
      margin-bottom: 0;
      .time-remaining {
        font-size: 12px;
        font-weight: 600;
        line-height: 18px;
        color: ${({ theme }) => theme['regent_gray']};
        background-color:${({ theme }) => theme['aqua_haze']};
        border-radius: 6px;
        padding: 2px 10px;
      }
      .remaining-hours {
       border-radius: 20px;
        padding: 8px;
        background-color:${({ theme }) => theme['aqua_haze']};
        span {
          color: ${({ theme }) => theme['warning-color']};
          line-height: 18px;
          display: block;
          text-align: center;
          &:first-child {
            font-weight: 600;
          }
          &:nth-child(2) {
            font-size: 14px;
          }
        }
     
      }
    }
    &.skip-modal {
      margin-bottom: 0;
      .skip-modal-desc {
        font-size: 14px;
        line-height: 21px; 
        color: ${({ theme }) => theme['secondary-color']};
        margin: 40px 0 24px;
        display: block;
      }
    .use-qty-box {
      border: 1px solid ${({ theme }) => theme['primary-color']};
      border-radius: 4px;
      padding: 16px 16px 2px;
      &.selected-qty-box {
        background-color: #E5F6FF;
      }
      .MuiRadio-root {
        padding: 0;
      }
      .use-qty-content {
        p {
          font-size: 12px;
          font-weight: 700;
          line-height: 14px;
          letter-spacing: 0.5%;
          color: ${({ theme }) => theme['primary-color']};
          margin-bottom: 4px;
        }
        &:nth-child(2) {
          margin-bottom: 2px;
        }
        span {
          font-size: 9px;
          font-weight: 600;
          line-height: 9px;
          color: ${({ theme }) => theme['secondary-color']};
          display: flex;
          gap: 4px;
          margin-bottom: 2px;
          span {
            font-weight: 700;
            color: ${({ theme }) => theme['success-color']};
          }
        }
      }
    }
    .skip-modal-btn {
      button {
        padding-top: 17px;
        padding-bottom: 17px;
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
      }
    }
    .received-tracking-btn {
      width: 170px;
    }
  }
  .delivery-today-status {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    max-width: 88px;
    white-space: pre-wrap;
    text-align: center;
        padding: 5px 10px;
  }
  .delivery-recived-status {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    border: 1px solid ${({ theme }) => theme['success-color']};
    color: ${({ theme }) => theme['secondary-color']};
    background-color: #ADFF0080;
    border-radius: 48px;
    padding: 4px 8px;
    max-width: max-content;
  }
`
const WaitingForArrivalWrapper = Styled.div `
    .empty-data-container {
        display:block;
        margin-top: 20px;
        margin-bottom: 20px;
        .nodata-notification{
        background: ${({ theme }) =>
            theme["info-color"]};
        border: 1px solid #00A3FF;
        border-radius: 4px;
        display: flex;
        align-items:center;
        gap: 8px;
        padding: 8px 12px;
        padding-right:18px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color:${({ theme }) =>
            theme["black-color"]};
        .notification-icon{
            width: 20px;
        }
        .notification-text{
            display: flex;
            gap:8px;
            align-items:center;
        }
        .notification-toggle{
            color:${({ theme }) =>
                theme["primary-color"]};
            a{
                &:hover{
                    text-decoration:underline;
                }
            }
        }
        &.drawer-nodata-notification{
            font-size:12px;
            line-height:14px;
            .notification-icon{
                width:16px;
            }
        }
    }
        .checkin-main-nodataimg{
            margin-top:42px;
        }
    } 
    // .no-data-grid-height {
    //     height: calc(100vh - 545px);
    // }
    .po-status-ellipse {
      white-space: nowrap; 
      width: 80px; 
      overflow: hidden;
      text-overflow: ellipsis; 
    }
    .date-show-more {
      font-size: 10px;
      font-weight: 600;
      color:${({ theme }) => theme["primary-color"]};
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
    }
`
const CounterBox = Styled.div`
    display:flex;
    justify-content:space-between;
    margin-bottom:8px;
    .counter-box{
        display: flex;
        border-radius:8px;
        background-color: ${(props) =>
    props.backgroundColor === 'warning'
      ? '#FFFAF2'
      : props.backgroundColor === 'success'
        ? '#F7FCF9'
        : '#FCFAFF'};
        padding:10px;
        gap:16px;
        
        border:1px solid ${(props) =>
    props.border === 'warning' ? '#FF9900' : props.border === 'success' ? '#009C34' : '#8F5AFF'};
    }
    `
const SmallChips = Styled.div`
        border-radius:8px;
        background-color: ${(props) =>
    props.backgroundColor === 'warning'
      ? '#FFFAF2'
      : props.backgroundColor === 'success'
        ? '#F7FCF9'
        : '#FCFAFF'};
        padding:16px 10px;
        border:1px solid ${(props) =>
    props.border === 'warning' ? '#FF9900' : props.border === 'success' ? '#009C34' : '#8F5AFF'};
        .ship{
            background-color:${({ theme }) => theme['layout-trigger-color']} ;
            border: 1px solid #E6E6E6;
            box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.12);
            padding:4px 8px;
            border-radius:8px;
            margin-bottom:8px;
            .ship-name {
              white-space: nowrap; 
              width: 70px; 
              overflow: hidden;
              text-overflow: ellipsis;
            }
        }

    `

const StorageWrapper = Styled.div`
.inputs-wrapper{
  display: flex;
  align-items: end;
  gap:  16px;
  .plus-icon{
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #1565D8;
    color: #1565D8;
    font-size: 20px;
    background: #fff;
    box-shadow:none;
  }
}
  .storage-input{
    border: 1px solid #E6E6E6;
    max-width: 74px;
    padding: 8px;
    border-radius: 4px;
    &:placeholder{
      color: #C4C4C4;
    }
  }
  .storage-select-wrapper{
    width: 100%;
    .storage-select{
      width: 100%;
    border-radius: 4px;
    border: 1px solid #E6E6E6;
    }
  .MuiSelect-select {
    max-width: 132px;
    width: 100%;
    padding: 8px;
    border: 1px solid #E6E6E6;
  }
  }
  .table-data-wrapper{
    margin-top:8px;
    max-height: 128px;
    overflow: scroll;
  }
  .table-data{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    p {
      font-size: 12px;
      font-weight: 700;
      color: ${({ theme }) => theme['regent_gray']} ;
      line-height: 12px;
      padding-left: 10px;
      min-width: 58px;
      margin-bottom: 0;
      &.note-text {
        white-space: nowrap; 
        width: 160px; 
        overflow: hidden;
        text-overflow: ellipsis; 
      }
      &.location-text {
        width: 159px;
      }
    }
  }
  .delete-icon-btn {
    &:hover {
      background-color: transparent;
    }
    img {
      max-width: max-content;
    }
  }
  .product-slider-img {
    border: 1px solid ${({ theme }) => theme['information-color']};
    border-radius: 8px;
    padding: 6px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    img{
      width: 100%;
    }
  }
  .pagination{
    .pagination-select{
      margin-bottom: 0;
      max-width: 152px;
      width: 100%;
      padding-right: 8px;
      .select2-selection__control{
        border: none;
      }
    }
    .pagination-btn{
      border: none;
      border-left: 1px solid #C4C4C4;
      border-radius: 0;
      background: transparent;
      margin-bottom: 0;
    }
  }
  .QB-dataTable.drawer-table{
    padding-top: 0;
    height: calc(100vh - 550px);
    .ag-header {
      border-bottom: none;
    }
    .ag-cell-value {
      padding: 0;
    }
  }
  .dimensions-input {
    width: 310px;
    .form-control {
      padding-right: 52px;
      width: 245px;
    }
    .inches-text {
      position: absolute;
      right: 8px;
      top: 30px;
      font-size: 14px;
      line-height: 16px;
      color: ${({ theme }) => theme['disabled-color']};
    }
  }
`
const StepGatedSkuWrapper = Styled.div`
  label{
    padding-right: 8px;
  }
  .top-heading{
    color: #4E5969;
  }
 .marketplace-logo{
  img{
    height: 20px;
    width: 100%;
  }
 }
 .batch-shipment-name {
    background-color: #FF9900B2;
    border-radius: 12px;
    padding: 12px;
    h2 {
      margin-bottom: 0;
    }
 }
  .badge-warning{
    border: 1px solid #FF9900;
    background: #FF99001A;
    border-radius: 48px;
    padding: 4px 8px;
    color: #4E5969;
    font-family: inherit;
  }
  .arrow-btn{
    .MuiSvgIcon-root{
      height: 14px;
    }
  }
  .product-img{
    width: 45px;
    height: 48px;
    object-fit: cover;
    object-position: center;
  }
  .ellipse-text{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

  }
  .open-shipment-box {
    border: 1px solid ${({ theme }) => theme['information-color']};
    border-radius: 4px;
    background-color: #F9F0E2;
    padding: 4px 10px;
    &.collapse-shipment-box {
      background-color: #F1FBFE;
    }
    .open-shipment-text {
      span {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: ${({ theme }) => theme['primary-color']};
      }
      h2 {
        margin-bottom: 0;
        color: ${({ theme }) => theme['primary-color']};
      }
    }
    .completed-units {
      text-align: center;
      p {
        color: ${({ theme }) => theme['success-color']};
        font-weight: 600;
        line-height: 16px;
        margin-bottom: 0;
      }
      span {
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: ${({ theme }) => theme['secondary-color']};
      }
      &.remaining {
        p {
          color: ${({ theme }) => theme['warning-color']};
        }
      }
    }
    .ant-progress-show-info .ant-progress-outer {
      padding-right: 0;
    }
    .ant-progress-line {
      width: 80px;
      .ant-progress-text {
        position: absolute;
        left: 0;
        top: 4px;
        margin-left: 5px;
        color: ${({ theme }) => theme['white-color']};
        font-weight: 600;
        svg {
          margin-top: -4px;
        }
      }
      .ant-progress-inner {
        background-color: ${({ theme }) => theme['outline-color']};
      }
      .ant-progress-bg {
        height: 20px !important;
        background-color: ${({ theme }) => theme['warning-color']};
      }
    }
  }
  .case-pack-switch {
    border: 1px solid ${({ theme }) => theme['outline-color']};
    border-radius: 54px;
    padding: 4px 10px;
    max-width: 240px;
    min-width: 240px;
    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      color: ${({ theme }) => theme['secondary-color']};
      margin-bottom: 0;
      max-width: 140px;
    }
    .ant-switch-inner {
      font-size: 10px;
      font-weight: 700;
    }
    .ant-switch-checked {
      background-color: ${({ theme }) => theme['primary-color']};
    }
  }
  .batch-collapses {
    .ant-collapse {
      border-color: ${({ theme }) => theme['outline-color']};
      border-radius: 20px;
      .ant-collapse-item {
        border-color: ${({ theme }) => theme['outline-color']};
        &:last-child {
          border-radius: 20px;
        }
        &:last-child > .ant-collapse-header {
          border-radius: 20px;
        }
      }
      .ant-collapse-content-box {
        padding-top: 14px !important;
        background-color: ${({ theme }) => theme['aqua_haze']};
      }
    }
    .ant-collapse-item-active {
      .ant-collapse-content-active {
        border-top: none;
      }
    }
    .ant-collapse-item > .ant-collapse-header {
      cursor: default;
    }
    .ant-collapse-header {
      background-color: ${({ theme }) => theme['aqua_haze']};
      .ant-collapse-arrow {
        cursor: pointer;
      }
    }
    .ant-collapse-item:last-child > .ant-collapse-content {
      border-radius: 0 0 20px 20px;
    }
    &.active-batch-collapse {
      .ant-collapse {
        border: 2px solid ${({ theme }) => theme['success-color']};
        .ant-collapse-item {
          border: 2px solid ${({ theme }) => theme['success-color']};
        }
        .ant-collapse-content-box {
          background-color: #E5F5EB;
        }
      }
      .ant-collapse-header {
        background-color: #E5F5EB;
      }
      .arrow-btn {
        background-color: ${({ theme }) => theme['success-color']};
        border-color: ${({ theme }) => theme['success-color']};
      }
    }
    &:last-child {
      .collapsed-table {
        margin-bottom: 62px;
      }
    }
  }
`

const CompleteBoxWrapper = Styled.div`
  .complete-box-info {
    background: #EEF1F533;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme['outline-color']};
    img {
      object-fit: cover;
    }
  }
  .height-calc{
    max-height:calc(100vh - 620px);
    overflow: auto;
  }
  .received-box-table {
    .ag-cell {
      padding-left: 0;
    }
  }
  .estimate-box {
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme['outline-color']};
    padding: 16px;
    label {
      font-weight: 600;
    }
    .ship-column{
      span:first-child{
        font-size: ${({ theme }) => theme["base-font-size-small"]};
        color:${({ theme }) => theme["regent_gray"]};
        display: block;
        margin-bottom: 4px;
        font-weight: 600;
        line-height: 14px;
      }
      .sales-badge {
        padding: 6px 16px;
        font-weight: 700;
        display: block;
      }
    }
  }
  .estimate-info {
    border: 1px solid ${({ theme }) => theme["information-color"]};
    background-color: #00A3FF0D;
    border-radius: 4px;
    padding: 8px 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 24px;
    p {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }
  .disabled-div {
    opacity: 0.4;
    pointer-event: none;
  }
`

export { TilesWrapper, StorageWrapper, StepGatedSkuWrapper, ShipmentPlanWrapper, DrawerWrapper, InboundWrapper, CounterBox, SmallChips, WaitingForArrivalWrapper, CompleteBoxWrapper }
