import Styled from "styled-components";
const DashboardWrapper = Styled.div`
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');
font-family: 'Open Sans', sans-serif;
.heading{
  color:#515253
}
.affilateDashboard{
  margin: 24px;
  .ant-page-header{
    padding: 21px 24px;
    .ant-page-header-heading-title{
      font-size: 18px;
      color: #515253;
    }
  }
  .selected{
    display: none
  }
  .add-scroll{
  .summary{
    background-color: ${({ theme }) => theme["white-color"]};
    padding: 24px 0;
    margin-bottom:24px;
    border: 1px solid #DDE3EC;
    border-radius:3px;
    h4{
      font-size: 24px;
      color: ${({ theme }) => theme["black-color"]};
      margin-bottom: 32px;
    }
    .inner-content{
      margin: 0 46px;
      .icons-portion{
        display: flex;
        justify-content: space-between;
        padding:0 67px;
        .icons{
            font-size:42px;
        }
      }
      .info-portion{
        display: flex;
        justify-content: space-between;
        padding:0 19px;
        div{
            text-align:center;
            .title{
                color: ${({ theme }) => theme["abbey-color"]};
                font-size: ${({ theme }) => theme["medium-font-size"]};
                font-weight: ${({ theme }) => theme["bold-weight"]};
                margin-bottom:12px;
                margin-top:5px;
            }
            div{
                display: flex;
                grid-column-gap:44px;
                div{
                    display: flex;
                    flex-direction:column;
                    span:nth-child(1){
                        color: ${({ theme }) => theme["science-blue"]};
                        font-weight: ${({ theme }) => theme["bold-weight"]};
                        font-size:${({ theme }) => theme["title-font-size"]};
                        &.color-green{
                            color: ${({ theme }) => theme["japanese-aurel"]}; 
                        }
                    }
                    span:nth-child(2){
                        color: ${({ theme }) => theme["dusty_grey"]};
                        font-weight: ${({ theme }) => theme["normal-weight"]};
                        font-size: ${({ theme }) => theme["medium-font-size"]};
                    }
                }
            }
        }
      }
      .progress{
          height:2px;
          margin-bottom:17px;
          margin-top:25px;
      }
    }
  }
  .date-picker{
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    h4{
      font-weight: 600;
       font-family: 'Open Sans', sans-serif;
    }
    input{
      height:23px;
    }
  }

.shipped_today{
    background-color: ${({ theme }) => theme["white-color"]};
    border-radius: 3px;
    text-align: center;
    padding: 24px 67px;
     margin-bottom: 24px;
     border: 1px solid #DDE3EC;
     
    h6{
      font-size: 18px;
      font-weight: ${({ theme }) => theme["bold-weight"]};
      margin-bottom:26px;
      margin-left:13px;
    }
    .shipped-detail{
      display: flex;
      justify-content:space-between;
      .shipment{
          display: flex;
          flex-direction:column;
          .icons{
            font-size:40px;
      }
      span:nth-child(2){
        color: ${({ theme }) => theme["abbey-color"]};
          font-size:${({ theme }) => theme["title-font-size"]};
          margin-top:16px;
    }
      }
      span:nth-child(3){
                        color: ${({ theme }) => theme["dusty_grey"]};
                        font-weight: ${({ theme }) => theme["normal-weight"]};
                        font-size: ${({ theme }) => theme["medium-font-size"]};
                    }
      }
   
  }
}
  }
}
  `;
export { DashboardWrapper };
