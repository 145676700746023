import React, { useState, useMemo, useEffect } from 'react'
import { object, string, ref, bool } from 'yup';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Logo from "../../assets/images/newImages/new_log.png";
import ProdImg from "../../assets/images/newImages/prod-img.svg";
import { AuthWrapper, PhoneNumberWrapper } from '../profile/style'
import { Form, Row, Col, Container } from "react-bootstrap"
import InfoVideo from '../profile/component/infoVideo'
import LabelInput from '../uiComponent/inputs/labelInput'
import PhoneInput from '../uiComponent/inputs/phoneNumberInput'
import CheckBox from '../uiComponent/checkBox'
import Button from '../uiComponent/button/index'
import { BiLeftArrowAlt } from "react-icons/bi";
import { AiFillEyeInvisible, AiFillEye, AiOutlineMenu } from "react-icons/ai";
import { ListLocationItems } from "../../slices/internalStorage";
import { isEmpty } from 'lodash';
import { MobileWrapper, Header, Content, Footer, Title, InputWrapper, CardDetail, ProductDetail, ScanFloating } from './mobile.styled';

const LocationDetailsAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedNewLocation, locationItemList } = useSelector(state => state?.internalStorage || {});
  const { uuid, items_count, units_count, name, location_type, staging_items } = locationItemList || {};

  useEffect(() => {
    const { id } = selectedNewLocation || {};
      if(isEmpty(id)) dispatch(ListLocationItems({ locationId: id }));
      else navigate('/home');
  }, [])

    return (
        <MobileWrapper className=''>
            <div className='mobile-page'>
                <div className='container-fluid px-0'>
                    <Content className=''>
                        <Title>
                            <h2>
                                {/* <span className="icon-arrow-left cursor-pointer" onClick={() => navigate('/scan-new-location')}></span> */}
                                Location Details</h2>
                        </Title>
                        <div className='content-inner new-product-added'>
                          {
                            locationItemList && 
                            <>
                            <CardDetail>
                            <div className='card-details-head d-flex justify-content-between align-items-center'>
                                <h2>{name}</h2>
                                <span
                                    className=""></span>
                            </div>
                            <div className='card-details-content'>
                                {/* <p>{name}</p> */}
                                <ul className='d-flex'>
                                    {/* <li><span className='icon-carton'></span>{location_type}</li> */}
                                    <li><span className='icon-inventory'></span>Products<strong>{items_count}</strong></li>
                                    <li><span className='icon-total'></span>Units <strong>{units_count}</strong></li>
                                </ul>
                            </div>
                        </CardDetail>
                        { !isEmpty(staging_items)
                            ? 
                            staging_items.map((item ) => {
                            const { image_url, title, identifier, quantity, stored_quantity } = item;
                            return (
                                <ProductDetail>
                                <div class="d-flex align-items-center">
                                    <div>
                                        <img width="42" height="42" src={image_url} alt="no-product" />
                                    </div>
                                    <div>
                                        <span class="fs-14 color-secondary">{title}</span>
                                    </div>
                                </div>
                                <ul class="d-flex">
                                    <li class="">
                                        <span class="fs-12 d-block">Identifier</span>
                                        <span class="color-primary fs-14 fw-bold">{identifier}</span>
                                    </li>
                                    <li class="">
                                        <span class="fs-12 recent-grey d-block">Quantity</span>
                                        <span class="fs-14">{stored_quantity}</span>
                                    </li>
                                </ul>

                            </ProductDetail>
                            )                  
                            })
                            
                            : <span className='empty-product'>No Product Found</span>
                        }      
                        </>
                      }
                            
                        </div>
                    </Content>
                    <Footer>
                        <Button className="outlined btn-block" text="Go to Home Page" onClick={() => navigate('/home')} />
                    </Footer>
                    {/* <ScanFloating>
                        <span className='icon-barcode'></span>
                    </ScanFloating> */}
                </div>
            </div>
        </MobileWrapper>
    )
}

export default LocationDetailsAdd;
