import React from "react";
import { UploadWrapper } from "./style";
import Button from "../../../components/uiComponent/button";
const Index = ({ uploadFile, returnLableFile }) => {
  return (
    <UploadWrapper>
      <span className="color-secondary fs-14 d-none">Upload Location
      </span>
      <div className="input-type">
        <input onChange={uploadFile} type="file" accept=".csv,.xlsx"/>
        <div className="upload-file">
          <div className="d-flex align-items-center flex-column upload">
            <span className="icon-upload"></span>
            <span>Select a file or drag and drop here</span>
            <span>CSV or XLSX file</span>
            <div className="divider">
              <span className="inner-text">OR</span>
            </div>
            <div className="text-center mt-2">
              <Button text="Upload File" className="outlined" />
            </div>
          </div>
        </div>
      { returnLableFile && <span className="uploaded-image-desc">{returnLableFile}</span> }

      </div>
    </UploadWrapper>
  );
};
export default Index;
