import styled from "styled-components";

const MobileWrapper = styled.div`
  /* background-color:#1565D8; */
  max-width: 375px;
  margin: 0 auto;
  overflow-x: hidden;
  position: relative;
  padding-bottom: 96px;
  /* .mobile-page{
        position:relative;
    } */
  &.fullContent {
    padding-bottom: 0;
    .content-inner {
      height: auto;
    }
  }
  .btn {
    margin-bottom: 0;
    &.btn-block {
      display: block;
      width: 100%;
    }
    &.btn-lg {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      padding: 12px;
      border-radius: 8px;
    }
  }
  .home-buttons {
    margin-bottom: 8px;
    height: calc(100vh - 386px);
    .btn {
      margin-bottom: 16px;
    }
  }
  .empty-location {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 200px);
  }
`;
const Header = styled.div`
  background-color: #1565d8;
  padding: 15px 24px;
  .logo-box {
    a {
      color: #ffffff;
      margin-right: 63px;
      line-height: 13px;
      font-size: 19px;
      margin-left: -3px;
    }
  }
`;
const Content = styled.div`
  padding: 24px;
  height: calc(100vh - 157px);
  .content-inner {
    height: 100%;
    overflow: auto;
    .mb-card-single {
      margin-bottom: 11px;
    }
    .modify-margin {
      .mb-4 {
        /* margin-bottom:13px!important;    */
        margin-bottom: 18px !important;
      }
    }
    &.new-product-added {
      margin-left: -24px;
      margin-right: -24px;
      padding-left: 24px;
      padding-right: 24px;
    }
    .empty-product {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(100% - 200px);
    }
  }
  &.fullContent {
    padding: 24px;
    height: calc(100vh - 61px);
  }
`;
const Title = styled.div`
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 37px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4e5969;
    span {
      font-size: 12px;
      color: #1565d8;
      margin-right: 15px;
      margin-left: 2px;
    }
  }
`;
const InputWrapper = styled.div`
  margin-top: 24px;
  .form-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 8px;
  }
  input {
    padding-top: 11px;
    padding-bottom: 12px;
    padding-left: 15px;
    font-weight: 400;
    font-size: 24px;
    line-height: 37px;
    border-radius: 8px;
    &::-webkit-input-placeholder {
      font-size: 24px;
    }
  }
  ::-webkit-input-placeholder {
    /* Edge */
    color: red;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red;
  }

  ::placeholder {
    color: red;
  }
  .input-with-icon {
    position: relative;
    span {
      position: absolute;
      right: 12px;
      top: 23px;
      cursor: pointer;
      &::before{
        font-size:24px ;
      }
    }
  }
`;
const CardDetail = styled.div`
  margin-top: 24px;
  border-radius: 12px;
  margin-bottom: 24px;
  border: 1px solid #e6e6e6;
  padding: 24px;
  background: url("https://sale-support-logos.s3.us-west-1.amazonaws.com/light-bg.svg"),
    #f8f8f8;
  background-size: 100%;
  background-repeat: no-repeat;
  /* box-shadow: 14px 17px 30px rgba(0, 0, 0, 0.05); */
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 12px;
  .card-details-head {
    margin-bottom: 8px;
    h2 {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 24px;
      line-height: 37px;
      color: #000000;
    }
  }
  .card-details-content {
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      color: #4e5969;
      margin-bottom: 8px;
    }
    /* margin-left: 6px; */
    ul {
      padding-left: 0;
      margin-bottom: 0;
      li {
        margin-right: 38px;
        list-style: none;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #4e5969;
        span {
          margin-right: 4px;
          font-size: 11px;
          color: #1565d8;
          &::before {
            color: #1565d8;
          }
        }
        strong {
          color: #000000;
          font-weight: 600;
          padding-left: 2px;
        }
        &:nth-of-type(2n) {
          margin-right: 32px;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
`;
const ProductDetail = styled.div`
  padding-top: 16px;
  padding-bottom: 14px;
  /* padding-top:14px;
    padding-bottom: 14px; */
  border-bottom: 1px solid #c4c4c4;
  &.product-old-location {
    background: #f8fafb;
    /* Light Mid Grey */

    border: 1px solid #e6e6e6;
    border-radius: 8px;
    padding: 19px 24px 18px;
    margin-top: 24px;
    ul {
      padding-top: 7px;
    }
  }
  &.new-product-blink {
    background: rgba(255, 153, 0, 0.05);
    margin-left: -24px;
    margin-right: -24px;
    padding: 18px 24px 14px;
    ul {
      padding-top: 18px;
    }
  }
  img {
    max-width: none;
    margin-right: 8px;
  }
  .d-flex {
    div {
      span.color-secondary {
        line-height: 21px;
        display: block;
      }
    }
  }
  ul {
    padding-top: 11px;
    /* padding-top:13px; */
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    li {
      margin-right: 30px;
      span {
        &:first-of-type {
          margin-bottom: 9px;
          font-weight: 700;
          letter-spacing: 0.5px;
        }
      }
      &:last-of-type {
        margin-right: 0;
      }
      &:last-of-type {
        .mb-4 {
          margin-bottom: 0 !important;
        }
        .form-control {
          width: 72px;
          font-size: 14px;
          margin-top: -9px;
          margin-left: -12px;
          margin-bottom: -2px;
          height: 34px;
        }
      }
    }
  }
`;
const ScanFloating = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 55%;
  background-color: #1565d8;
  padding: 16px;
  bottom: 120px;
  /* right: 16px; */
  bottom: 16%;
  right: 39.5%;
  position: fixed;
  line-height: 16px;
  @media screen and (max-width: 1920px) {
    right: 39.5%;
    bottom: 16%;
    position: fixed;
  }
  @media screen and (max-width: 1440px) {
    right: 36.5%;
  }
  @media screen and (max-width: 1300px) {
    right: 35%;
    bottom: 16%;
  }
  @media screen and (max-width: 1100px) {
    right: 25%;
    bottom: 16%;
  }
  @media screen and (max-width: 1024px) {
    right: 31%;
  }
  @media screen and (max-width: 912px) {
    right: 27%;
  }
  @media screen and (max-width: 768px) {
    right: 24%;
  }
  @media screen and (max-width: 540px) {
    right: 14%;
  }
  @media screen and (max-width: 414px) {
    right: 9%;
  }
  @media screen and (max-width: 375px) {
    right: 13%;
  }
  span {
    font-size: 16px;
    &.icon-barcode {
      &:before {
        color: #ffffff;
      }
    }
  }
`;
const Footer = styled.div`
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
  padding: 24px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  .btn {
    padding: 15px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    width: 100%;
    &.no-border {
      border-color: transparent;
    }
  }
  .col-6 {
    padding-left: 14px;
    padding-right: 13px;
  }
`;
export {
  MobileWrapper,
  Header,
  Content,
  Footer,
  Title,
  InputWrapper,
  CardDetail,
  ProductDetail,
  ScanFloating,
};
