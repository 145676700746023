import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleRejectedResponse } from './utils';
import axios, { awsAxios } from '../../axios_config';
import { notification } from 'antd';

export const CalculateMinMaxPrices = createAsyncThunk(
  '/calculate_min_max_prices',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/api/v2/repricer/calculate_min_max_prices`,
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Calculate Min Max Prices', rejectWithValue);
    }
  }
);

export const GetPresignedUrl = createAsyncThunk(
  '/presigned_url',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `/api/v2/repricer/presigned_url`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          params: data
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Presigned Url', rejectWithValue);
    }
  }
);

export const GetProducts = createAsyncThunk(
  '/get_products',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v2/repricer/get_products`,
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Products', rejectWithValue);
    }
  }
);

export const GetPriceHistory = createAsyncThunk(
  '/GetPriceHistory',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v2/repricer/price_history`,
        {
          params: data,
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Products', rejectWithValue);
    }
  }
);

export const GetRepricerStrategyGraph = createAsyncThunk(
  '/GetRepricerStrategyGraph',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v2/reports/repricer_strategy_graph`,
        {
          params: data
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Products', rejectWithValue);
    }
  }
);

export const GetMissedEvents = createAsyncThunk(
  '/GetMissedEvents',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/v2/repricer/missed_events_stats`);
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Products', rejectWithValue);
    }
  }
);

export const GetRepricerSalesGraph = createAsyncThunk(
  '/GetRepricerSalesGraph',
  async (info, { getState, rejectWithValue }) => {
    try {
      const {
        start_date,
        end_date,
        compare
      } = info || {};

      const { data } = await axios.get(`/api/v2/reports/repricer_dashboard_graph`, {
        params: {
          start_date,
          end_date
        }
      });
      return {
        data,
        compare
      };
    } catch (err) {
      return handleRejectedResponse(err, 'Get Products', rejectWithValue);
    }
  }
);

export const GetRepricerGraphData = createAsyncThunk(
  '/GetRepricerGraphData',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/v2/reports/repricer_dashboard_graph`, {
        params: data
      });
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Products', rejectWithValue);
    }
  }
);

export const GetRepricerTopStats = createAsyncThunk(
  '/GetRepricerTopStats',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/v2/reports/repricer_dashboard_stats`, {
        params: data
      });
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Products', rejectWithValue);
    }
  }
);

export const GetProfitAndLoseStates = createAsyncThunk(
  '/GetProfitAndLoseStates',
  async ({ dataBy, startDate, endDate, search_ids }, { getState, rejectWithValue }) => {
    try {
      const { data } = await axios.get(`/api/v2/reports/repricer_pnl_data`,{
        params: {
          dataBy,
          startDate,
          endDate,
          search_ids
        }
      });
      return data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Products', rejectWithValue);
    }
  }
);

export const GetRepricerProduct = createAsyncThunk(
  '/GetRepricerProduct',
  async (search, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/v2/reports/search_skus?search=${search}`);
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Products', rejectWithValue);
    }
  }
);


export const GetRandomProduct = createAsyncThunk(
  '/random_product',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v2/repricer/random_product`,
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Random Product', rejectWithValue);
    }
  }
);

export const GetAllProducts = createAsyncThunk(
  '/list_all_products',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `/api/v2/repricer/list_all_products`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          params: data
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get All Products', rejectWithValue);
    }
  }
);

export const GetProductTypeBasedStats = createAsyncThunk(
  '/product_type_based_stats',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v2/repricer/product_type_based_stats`,
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Product Type Based Stats', rejectWithValue);
    }
  }
);

export const UpdateProduct = createAsyncThunk(
  '/update_product_new/:id',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { id } = data || {};
      delete data.id;
      const response = await axios.patch(
        `/api/v2/repricer/update_product_new/${id}`,
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Product', rejectWithValue);
    }
  }
);

export const CreateStrategy = createAsyncThunk(
  '/create_new_strategy',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/api/v2/repricer/create_new_strategy`,
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Create Strategy', rejectWithValue);
    }
  }
);

export const SaveAndProcessFile = createAsyncThunk(
  '/save_and_process_file',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/api/v2/repricer/save_and_process_file`,
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Save And Process File', rejectWithValue);
    }
  }
);

export const GetFileProcessingStatus = createAsyncThunk(
  '/file_processing_status',
  async (fileType, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v2/repricer/file_processing_status?fileType=${fileType}`,
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get File Processing Status', rejectWithValue);
    }
  }
);

export const GetListingSyncStatus = createAsyncThunk(
  '/listing_sync_status',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v2/repricer/listing_sync_status`,
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Listing Sync Status', rejectWithValue);
    }
  }
);

export const UploadFileToS3 = createAsyncThunk(
  '/upload_to_s3',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { url, buffer, type } = data || {};
      const response = await awsAxios.put(
        url, buffer, { headers: { 'Content-Type': type } }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Upload File to S3', rejectWithValue);
    }
  }
);

export const SubscribeRepricer = createAsyncThunk(
  '/subscribe_repricer',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/api/v2/repricer/subscribe_repricer',
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Subscribe Repricer', rejectWithValue)
    }
  }
);

export const UnSubscribeRepricer = createAsyncThunk(
  '/unsubscribe_repricer',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { id } = state.auth.currentUser || {};

      const response = await axios.delete(
        `/api/v2/repricer/${id}/unsubscribe_repricer`
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Un-Subscribe Repricer', rejectWithValue)
    }
  }
);

export const GetRepricerStatus = createAsyncThunk(
  '/get_repricer_status',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { id } = state.auth.currentUser || {};

      const response = await axios.get(
        `/api/v2/repricer/get_repricer_status?_id=${id}`,
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Repricer Status', rejectWithValue)
    }
  }
);

export const RemoveFile = createAsyncThunk(
  '/delete_file',
  async (fileType, { getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `/api/v2/repricer/delete_file?fileType=${fileType}`,
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Remove File', rejectWithValue)
    }
  }
);

export const ChangeRepriceMode = createAsyncThunk(
  '/change-repricer-mode',
  async ({ id, mode }, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'post',
        url: '/api/v2/repricer/set_repricer_mode',
        params: {
          repricer: {
            id,
            mode
          }
        }
      });
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Remove File', rejectWithValue)
    }
  }
);

export const EnableRepricer = createAsyncThunk(
  '/Enable-repricer',
  async (user, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/api/v2/repricer/subscribe_repricer`,
        {
          user
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Remove File', rejectWithValue)
    }
  }
);

export const DisableRepricer = createAsyncThunk(
  '/disable-repricer',
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `/api/v2/repricer/${id}/unsubscribe_repricer`,
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Remove File', rejectWithValue)
    }
  }
);

const repricerDashboard = createSlice({
  name: "repricerDashboard",
  initialState: {
    calculatingMinMaxPrices: false,
    minMaxPrices: {},
    fetchingProducts: false,
    products: [],
    fetchingRandomProduct: false,
    randomProduct: {},
    fetchingAllProducts: false,
    allProducts: [],
    allProductsTotalCount: 0,
    fetchingProductTypeBasedStats: false,
    productTypeBasedStats: {},
    updatingProduct: false,
    creatingStrategy: false,
    saveAndStartFileProcessing: false,
    fetchingFileProcessingStatus: false,
    fileData: {},
    fetchingListingSyncStatus: false,
    listingStatusCompleted: false,
    fetchingPresignedUrl: false,
    uploadingFileToS3: false,
    subscribingToRepricer: false,
    fetchingRepricerStatus: false,
    unSubscribingToRepricer: false,
    removingFile: false,
    loading: false,
    priceHistoryList: [],
    priceHistoryCount: 0,
    error: '',
    missedEvents: {},
    salesComparisonData: {},
    salesComparisonCompareData: {},
    repricerStats: {},
    strategiesGraphData: {},
    priceHistoryLoading: true,
    strategyGraphLoading: false,
    salesGraphLoading: false,
    graphLoading: false,
    profitLoseLoading: false,
    profitLoseData: {},
    fetchingRepricerProducts: false,
    repricerProducts: [],
  },
  reducers: {
    setField(state, { payload: { field, index, key, value } }) {
      state[field][index][key] = value;
    },
    setRepricerStates(state, { payload: { field, value } }) {
      state[field] = value;
    },
  },
  extraReducers: {
    [CalculateMinMaxPrices.pending]: (state, action) => ({
      ...state,
      calculatingMinMaxPrices: true
    }),
    [CalculateMinMaxPrices.fulfilled]: (state, action) => {
      const { minimumSellPrice, maximumSellPrice } = action?.payload?.data || {};

      return {
        ...state,
        calculatingMinMaxPrices: false,
        minMaxPrices: { minimumSellPrice, maximumSellPrice }
      }
    },
    [CalculateMinMaxPrices.rejected]: (state, action) => {
      return {
        ...state,
        calculatingMinMaxPrices: false
      }
    },
    [GetRepricerGraphData.pending]: (state, action) => ({
      ...state,
      graphLoading: true
    }),
    [GetRepricerGraphData.fulfilled]: (state, action) => {
      const { data } = action?.payload?.data || {};
      return {
        ...state,
        graphLoading: false
      }
    },
    [GetRepricerGraphData.rejected]: (state, action) => {
      return {
        ...state,
        graphLoading: false
      }
    },
    [GetRepricerTopStats.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetRepricerTopStats.fulfilled]: (state, action) => {
      const { data } = action?.payload?.data || {};
      return {
        ...state,
        repricerStats: data,
        loading: false
      }
    },
    [GetRepricerTopStats.rejected]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [GetProfitAndLoseStates.pending]: (state, action) => ({
      ...state,
      profitLoseLoading: true
    }),
    [GetProfitAndLoseStates.fulfilled]: (state, action) => {
      const { data } = action?.payload || {};
      return {
        ...state,
        profitLoseData: data,
        profitLoseLoading: false
      }
    },
    [GetProfitAndLoseStates.rejected]: (state, action) => {
      return {
        ...state,
        profitLoseLoading: false
      }
    },
    [GetRepricerProduct.pending]: (state, action) => ({
      ...state,
      fetchingRepricerProducts: true
    }),
    [GetRepricerProduct.fulfilled]: (state, action) => {
      const { data } = action?.payload || {};
      const { skus } = data || {};
      return {
        ...state,
        fetchingRepricerProducts: false,
        repricerProducts: skus
      }
    },
    [GetRepricerProduct.rejected]: (state, action) => {
      return {
        ...state,
        fetchingRepricerProducts: false
      }
    },
    [GetRepricerSalesGraph.pending]: (state, action) => ({
      ...state,
      salesGraphLoading: true
    }),
    [GetRepricerSalesGraph.fulfilled]: (state, action) => {
      const { data } = action?.payload.data || {};
      const { compare } = action?.payload || {};
      return {
        ...state,
        ...(compare ? { salesComparisonCompareData: data } : { salesComparisonData: data }),
        salesGraphLoading: false
      }
    },
    [GetRepricerSalesGraph.rejected]: (state, action) => {
      return {
        ...state,
        salesGraphLoading: false
      }
    },
    [GetPriceHistory.pending]: (state, action) => ({
      ...state,
      priceHistoryLoading: true
    }),
    [GetPriceHistory.fulfilled]: (state, action) => {
      const { priceHistoryList, priceHistoryCount } = action?.payload?.data || {};
      return {
        ...state,
        priceHistoryList,
        priceHistoryCount,
        priceHistoryLoading: false,
      }
    },
    [GetPriceHistory.rejected]: (state, action) => {
      return {
        ...state,
        priceHistoryLoading: false,
      }
    },
    [GetRepricerStrategyGraph.pending]: (state, action) => ({
      ...state,
      strategyGraphLoading: true
    }),
    [GetRepricerStrategyGraph.fulfilled]: (state, action) => {
      const { data } = action?.payload?.data || {};
      return {
        ...state,
        strategiesGraphData: data,
        strategyGraphLoading: false
      }
    },
    [GetRepricerStrategyGraph.rejected]: (state, action) => {
      return {
        ...state,
        strategyGraphLoading: false
      }
    },
    [GetMissedEvents.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetMissedEvents.fulfilled]: (state, action) => {
      const { stats } = action?.payload?.data || {};
      return {
        ...state,
        missedEvents: stats,
        loading: false
      }
    },
    [GetMissedEvents.rejected]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [GetPresignedUrl.pending]: (state, action) => ({
      ...state,
      fetchingPresignedUrl: true
    }),
    [GetPresignedUrl.fulfilled]: (state, action) => {
      return {
        ...state,
        fetchingPresignedUrl: false
      }
    },
    [GetPresignedUrl.rejected]: (state, action) => {
      return {
        ...state,
        fetchingPresignedUrl: false
      }
    },
    [GetProducts.pending]: (state, action) => ({
      ...state,
      fetchingProducts: true
    }),
    [GetProducts.fulfilled]: (state, action) => {
      const { products } = action?.payload?.data || {};

      return {
        ...state,
        fetchingProducts: false,
        products
      }
    },
    [GetProducts.rejected]: (state, action) => {
      return {
        ...state,
        fetchingProducts: false
      }
    },
    [GetRandomProduct.pending]: (state, action) => ({
      ...state,
      fetchingRandomProduct: true
    }),
    [GetRandomProduct.fulfilled]: (state, action) => {
      const { product } = action?.payload?.data || {};

      return {
        ...state,
        fetchingRandomProduct: false,
        randomProduct: product?.[0]
      }
    },
    [GetRandomProduct.rejected]: (state, action) => {
      return {
        ...state,
        fetchingRandomProduct: false
      }
    },
    [GetAllProducts.pending]: (state, action) => ({
      ...state,
      fetchingAllProducts: true
    }),
    [GetAllProducts.fulfilled]: (state, action) => {
      const { total_products, products } = action?.payload?.data || {};

      return {
        ...state,
        fetchingAllProducts: false,
        allProducts: products,
        allProductsTotalCount: total_products
      }
    },
    [GetAllProducts.rejected]: (state, action) => {
      return {
        ...state,
        fetchingAllProducts: false
      }
    },
    [GetProductTypeBasedStats.pending]: (state, action) => ({
      ...state,
      fetchingProductTypeBasedStats: true
    }),
    [GetProductTypeBasedStats.fulfilled]: (state, action) => {
      const { result } = action?.payload?.data || {};

      return {
        ...state,
        fetchingProductTypeBasedStats: false,
        productTypeBasedStats: result
      }
    },
    [GetProductTypeBasedStats.rejected]: (state, action) => {
      return {
        ...state,
        fetchingProductTypeBasedStats: false
      }
    },
    [UpdateProduct.pending]: (state, action) => ({
      ...state,
      updatingProduct: true
    }),
    [UpdateProduct.fulfilled]: (state, action) => {
      const { message, success } = action?.payload?.data || {};
      const notificationObj = {
        top: 65,
        message: 'Update Product',
        description: message
      };
      success ? notification.success(notificationObj)
        : notification.error(notificationObj);

      return {
        ...state,
        updatingProduct: false
      }
    },
    [UpdateProduct.rejected]: (state, action) => {
      return {
        ...state,
        updatingProduct: false
      }
    },
    [CreateStrategy.pending]: (state, action) => ({
      ...state,
      creatingStrategy: true
    }),
    [CreateStrategy.fulfilled]: (state, action) => {
      const { message, success } = action?.payload?.data || {};
      const notificationObj = {
        top: 65,
        message: 'Create Strategy',
        description: message
      };
      success ? notification.success(notificationObj)
        : notification.error(notificationObj);

      return {
        ...state,
        creatingStrategy: false
      }
    },
    [CreateStrategy.rejected]: (state, action) => {
      return {
        ...state,
        creatingStrategy: false
      }
    },
    [SaveAndProcessFile.pending]: (state, action) => ({
      ...state,
      saveAndStartFileProcessing: true
    }),
    [SaveAndProcessFile.fulfilled]: (state, action) => {
      const { message, success } = action?.payload?.data || {};
      const notificationObj = {
        top: 65,
        message: 'Save And Process File',
        description: message
      };
      success ? notification.success(notificationObj)
        : notification.error(notificationObj);

      return {
        ...state,
        saveAndStartFileProcessing: false
      }
    },
    [SaveAndProcessFile.rejected]: (state, action) => {
      return {
        ...state,
        saveAndStartFileProcessing: false
      }
    },
    [GetFileProcessingStatus.pending]: (state, action) => ({
      ...state,
      fetchingFileProcessingStatus: true
    }),
    [GetFileProcessingStatus.fulfilled]: (state, action) => {
      const { outputFileUrl, processingStatus, inputFileUrl } = action?.payload?.data || {};

      return {
        ...state,
        fetchingFileProcessingStatus: false,
        fileData: { outputFileUrl, processingStatus, inputFileUrl }
      }
    },
    [GetFileProcessingStatus.rejected]: (state, action) => {
      return {
        ...state,
        fetchingFileProcessingStatus: false
      }
    },
    [GetListingSyncStatus.pending]: (state, action) => ({
      ...state,
      fetchingListingSyncStatus: true
    }),
    [GetListingSyncStatus.fulfilled]: (state, action) => {
      const { listingStatusCompleted } = action?.payload?.data || {};

      return {
        ...state,
        fetchingListingSyncStatus: false,
        listingStatusCompleted
      }
    },
    [GetListingSyncStatus.rejected]: (state, action) => {
      return {
        ...state,
        fetchingListingSyncStatus: false
      }
    },
    [UploadFileToS3.pending]: (state, action) => ({
      ...state,
      uploadingFileToS3: true
    }),
    [UploadFileToS3.fulfilled]: (state, action) => {
      return {
        ...state,
        uploadingFileToS3: false
      }
    },
    [UploadFileToS3.rejected]: (state, action) => {
      return {
        ...state,
        uploadingFileToS3: false
      }
    },
    [GetRepricerStatus.pending]: (state, action) => ({
      ...state,
      fetchingRepricerStatus: true
    }),
    [GetRepricerStatus.fulfilled]: (state, action) => {
      return {
        ...state,
        fetchingRepricerStatus: false
      }
    },
    [GetRepricerStatus.rejected]: (state, action) => {
      return {
        ...state,
        fetchingRepricerStatus: false
      }
    },
    [SubscribeRepricer.pending]: (state, action) => ({
      ...state,
      subscribingToRepricer: true
    }),
    [SubscribeRepricer.fulfilled]: (state, action) => {
      const { success, user, error } = action?.payload?.data || {};
      const notificationObj = {
        top: 65,
        message: 'Enable Repricer',
        description: success ? 'Repricer has been Enabled Successfully.' : error
      };
      success ? notification.success(notificationObj)
        : notification.error(notificationObj);

      return {
        ...state,
        subscribingToRepricer: false
      }
    },
    [SubscribeRepricer.rejected]: (state, action) => {
      return {
        ...state,
        subscribingToRepricer: false
      }
    },
    [UnSubscribeRepricer.pending]: (state, action) => ({
      ...state,
      unSubscribingToRepricer: true
    }),
    [UnSubscribeRepricer.fulfilled]: (state, action) => {
      const { success, user, error } = action?.payload?.data || {};
      const notificationObj = {
        top: 65,
        message: 'Disable Repricer',
        description: success ? 'Repricer has been Disabled Successfully.' : error
      };
      success ? notification.success(notificationObj)
        : notification.error(notificationObj);

      return {
        ...state,
        unSubscribingToRepricer: false
      }
    },
    [UnSubscribeRepricer.rejected]: (state, action) => {
      return {
        ...state,
        unSubscribingToRepricer: false
      }
    },
    [RemoveFile.pending]: (state, action) => ({
      ...state,
      removingFile: true
    }),
    [RemoveFile.fulfilled]: (state, action) => {
      const { success, message } = action?.payload?.data || {};
      const notificationObj = {
        top: 65,
        message: 'Remove File',
        description: message
      };
      // success ? notification.success(notificationObj)
      //   : notification.error(notificationObj);

      return {
        ...state,
        removingFile: false
      }
    },
    [RemoveFile.rejected]: (state, action) => {
      return {
        ...state,
        removingFile: false
      }
    },
    [ChangeRepriceMode.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [ChangeRepriceMode.fulfilled]: (state, action) => {
      const notificationObj = {
        top: 65,
        message: 'Repricer Mode',
        description: 'Repricer Mode Changed Successfully.'
      };
      notification.success(notificationObj)

      return {
        ...state,
        loading: false
      }
    },
    [ChangeRepriceMode.rejected]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [EnableRepricer.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [EnableRepricer.fulfilled]: (state, action) => {
      const {success}   = action?.payload?.data || {};
      let notificationObj;
      if(success) {
        notificationObj = {
          top: 65,
          message: 'Enable Repricer',
          description: 'Repricer Enabled Successfully.'
        };
      } else {
        notificationObj = {
          top: 65,
          message: 'Enable Repricer',
          description: 'Repricer Not Enabled' 
        };
      }
     
      notification.success(notificationObj)

      return {
        ...state,
        loading: false
      }
    },
    [EnableRepricer.rejected]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [DisableRepricer.pending]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [DisableRepricer.fulfilled]: (state, action) => {
      const notificationObj = {
        top: 65,
        message: 'Disable Repricer',
        description: 'Repricer Disabled Successfully.'
      };
      notification.success(notificationObj)

      return {
        ...state,
        loading: false
      }
    },
    [DisableRepricer.rejected]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
  }
});

const { reducer, actions } = repricerDashboard;
export const { setField, setRepricerStates } = actions;
export default reducer;