import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleRejectedResponse } from '../utils';
import { fetch as axios } from '../../../axios_config';
import { isArray, filter, isUndefined } from 'lodash';

import { checkInInitialState, toCheckInReducers } from './to-check-in';
import { toShipInitialState, toShipReducers } from './to-ship'
import { createShipmentInitialState, createShipmentReducers } from './create-shipment';
import { toReceiveInitialState, toReceiveReducers } from './to-receive';
import { postShipmentInitialState, postShipmentReducers } from './post-shipment';
import { storagePoInitialState, storagePosReducers } from './storage-pos';
import { PosReducer } from './purchase-order';

export const GetStats = createAsyncThunk(
  '/po-stats',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/po-stats`,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Po Stats', rejectWithValue);
    }
  }
);


const fbaInbound = createSlice({
  name: "fbaInbound",
  initialState: {
    toCheckIn: checkInInitialState,
    toShip: toShipInitialState,
    createShipment: createShipmentInitialState,
    toReceive: toReceiveInitialState,
    postShipment: postShipmentInitialState,
    storagePo: storagePoInitialState,
    checkedState: [],
    searchedText: '',
    mainPage: {
      stats: {},
      fetchingStats: false
    }
  },
  reducers: {
    setField(state, { payload: { module, field, index, key, value } }) {
      isUndefined(index) ? (state[module][field][key] = value) : (state[module][field][index][key] = value);
    },
    setSelectedSates(state, { payload: { value } }) {
      state['checkedState'] = value;
    },
    setSearchingText(state, { payload: { value } }) {
      state['searchedText'] = value;
    },
    updateSkuStatus(state, { payload: { module, field, index, value } }) {
      state[module][field][index]['vendoritem']['seller_sku']['sku_status'] = value;
    },
    updateItemDimensions(state, { payload: { module, field, index, value } }) {
      state[module][field][index]['vendoritem']['vendorasin']['height'] = value?.height || 0;
      state[module][field][index]['vendoritem']['vendorasin']['length'] = value?.length || 0;
      state[module][field][index]['vendoritem']['vendorasin']['weight'] = value?.weight || 0;
      state[module][field][index]['vendoritem']['vendorasin']['width'] = value?.width || 0;
    },
    clearField(state, { payload: { module, field } }) {
      state[module][field] = isArray(state[module][field]) ? [] : {};
    },
    updateAddressState(state, { payload: { module, field, key, value } }) {
      state[module][field][key]['company']['addresses'] = value;
    },
    removeDataEntry(state, { payload: { module, field, key, value } }) {
      state[module][field] = filter(state[module][field], obj => (obj[key] !== value));
    },
  },
  extraReducers: {
    ...toCheckInReducers,
    ...toShipReducers,
    ...createShipmentReducers,
    ...toReceiveReducers,
    ...postShipmentReducers,
    ...storagePosReducers,
    ...PosReducer,
    [GetStats.pending]: (state, action) => ({
      ...state,
      mainPage: {
        ...state.mainPage,
        fetchingStats: true
      }
    }),
    [GetStats.fulfilled]: (state, action) => {
      const {
        arrivedNotReceivedPos, arrivedNotReceivedUnits,
        dailyArrivedUnits, dailyReceivedBundles
      } = action?.payload?.data || {};

      return {
        ...state,
        mainPage: {
          ...state.mainPage,
          fetchingStats: false,
          stats: { arrivedNotReceivedPos, arrivedNotReceivedUnits, dailyArrivedUnits, dailyReceivedBundles }
        }
      }
    },
    [GetStats.rejected]: (state, action) => {
      return {
        ...state,
        mainPage: {
          ...state.mainPage,
          fetchingStats: false,
        }
      }
    },
  }
});

const { reducer, actions } = fbaInbound;
export const { setField, updateSkuStatus, clearField, updateItemDimensions, updateAddressState, removeDataEntry, setSelectedSates, setSearchingText } = actions;
export default reducer;
