/* eslint-disable import/prefer-default-export */

export const HELLO_WORLD_NAME_UPDATE = 'HELLO_WORLD_NAME_UPDATE';

export const CONDITION_TYPE = {
  NEW: {
    label: 'New',
    value: 11,
    amazon: 'NewItem'
  },
  USED_LIKE_NEW: {
    label: 'Used Like New',
    value: 1,
    amazon: 'UsedLikeNew'
  },
  USED_VERY_GOOD: {
    label: 'Used Very Good',
    value: 2,
    amazon: 'UsedVeryGood'
  },
  USED_GOOD: {
    label: 'Used Good',
    value: 3,
    amazon: 'UsedGood'
  },
  USED_ACCEPTABLE: {
    label: 'Used Acceptable',
    value: 4,
    amazon: 'UsedAcceptable'
  },
  COLLECTIBLE_LIKE_NEW: {
    label: 'Collectible Like New',
    value: 5,
    amazon: 'CollectibleLikeNew'
  },
  COLLECTIBLE_VERY_GOOD: {
    label: 'Collectible Very Good',
    value: 6,
    amazon: 'CollectibleVeryGood'
  },
  COLLECTIBLE_GOOD: {
    label: 'Collectible Good',
    value: 7,
    amazon: 'CollectibleGood'
  },
  COLLECTIBLE_ACCEPTABLE: {
    label: 'Collectible Acceptible',
    value: 8,
    amazon: 'CollectibleAcceptible'
  },
  UNACCEPTABLE: {
    label: 'Unacceptable',
    value: 9,
    amazon: 'Unacceptable'
  },
  REFURBISHED: {
    label: 'Refurbished',
    value: 10,
    amazon: 'Refurbished'
  }
};
