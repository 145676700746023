import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Spin, notification } from 'antd';
import { 
  CreateStore,
  clearStore,
  setState,
  GetValidMarketplaces,
  SaveMarketPlaces,
  ReAuthorizeAmazonStore
} from '../../../slices/store';
import Header from '../../header/header'
import Checkbox from '../../uiComponent/checkBox/index'
import { StoreWrapper } from '../style'
import { cloneDeep, isEmpty, upperCase } from "lodash";
import { BiLeftArrowAlt } from "react-icons/bi";
import Button from '../../uiComponent/button/index';
import SearchInput from '../../uiComponent/inputs/input'
import MapGrey from '../../../assets/images/newImages/map_gray.svg'
import MapEuorpe from '../../../assets/images/newImages/map_europe.svg'
import MapEast from '../../../assets/images/newImages/map_east.svg'
import MapNorth from '../../../assets/images/newImages/map_north.svg'
import { NA_MARKETPLACES, EU_MARKETPLACES, FE_MARKETPLACES } from '../../../constants/onBoarding';
import clearRequestArray from '../../../utils/interseptors/pendingRequest';
import axios from '../../../../axios_config';
let controller = null;

const SelectRegion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
		spApiRegion,
		spApiState,
		storeName,
    storeId,
    loading,
    marketPlaces,
    marketPlaceLoading,
    createStoreLoading,
    authorizingAmazonStore,
    storeData
	} = useSelector((state) => state?.store || {});
  const { currentUser: user } = useSelector((state) => state?.auth || {});

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [authorized, setAuthorized] = useState([]);
  const [value, setValue] = useState({
    marketPlace: [],
    map: MapGrey
  });
  const [isReAuthorize, setIsReAuthorize] = useState(false);

  const { marketPlace, map } = value;

  const showNotificationError = (message, description) => {
    notification.error({
      message,
      description,
      placement: "topRight",
      top: 65,
    });
  }

  const addStore = async () => {
    if(!storeId){
      const params = new URLSearchParams(window.location.search);
      const authCode = params.get('spapi_oauth_code');
      const authToken = params.get('mws_auth_token');
      const sellerId = params.get('selling_partner_id');
      const data = {
        store: {
          region: spApiRegion,
          name: storeName,
          seller_id: sellerId,
          auth_token: authToken,
          platform_type: 'Amazon'
        },
        authorization_code: authCode
      };
      if(sellerId){
        if (localStorage.getItem('re-authorize')) {
          const data = {
            store: {
              seller_id: sellerId,
              auth_token: authToken,
            },
            authorization_code: authCode
          };
          const response = await dispatch(ReAuthorizeAmazonStore(data));
          if (!response.error) {
            const { id } = response?.payload?.data?.store || {};
            return id;
          }
        } else {
          const response = await dispatch(CreateStore(data));
          if (!response.error) {
            const { id } = response?.payload?.data?.store || {};
            return id;
          } else {
            const errorMessage = response.payload.message;
            if (errorMessage.includes('reauthorize')) navigate('/account?section=stores')
            else navigate('/onboarding/store-add')
          };
        }
      } else showNotificationError('Store Connect', 'Authorization failed');
    }
    return null;
  } 

  const handleValidMarketplaces = async(selectedMarketplace) => {
    let id = await addStore();
    id = id || storeId;
    if (id) await dispatch(GetValidMarketplaces({ id }));
  }

  useEffect(() => {
    if (user.platform === 'Repricer') return navigate(`/reprice/guide?panel=authorization&${window.location.search.split('?')?.[1]}`, { replace: true });

    controller = new AbortController();
    axios.defaults.signal = controller?.signal;
    let selectedMarketplace = null;
    let selectedMap = MapGrey;
    if (spApiRegion === "eu") {
      selectedMarketplace = EU_MARKETPLACES;
      selectedMap = MapEuorpe;
    } else if (spApiRegion === "fe") {
      selectedMarketplace = FE_MARKETPLACES;
      selectedMap = MapEast;
    } else {
      selectedMarketplace = NA_MARKETPLACES;
      selectedMap = MapNorth;
    }
    setValue({
      ...value,
      marketPlace: selectedMarketplace,
      map: selectedMap
    });
    handleValidMarketplaces(selectedMarketplace);
    setIsReAuthorize(localStorage.getItem('re-authorize'));
    
    return () => {
      clearRequestArray();
      controller.abort();
      controller = null;
      axios.defaults.signal = null;
    };
	}, []);

  const saveMarketplacesList = async () => {
    const store = {
      marketplace_ids: marketplaceList
    }
    const response = await dispatch(SaveMarketPlaces({store, storeId}));
    if (!response.error) {
      dispatch(setState({ field: 'selectedMarketplaces', value: marketplaceList }));
      navigate(localStorage.getItem('re-authorize') ? '/onboarding/amazon-success' : '/onboarding/amazon-prep-video');
    }
  }


  const handleMarketPlaces = (value) => {
    const selectedIndex = marketplaceList.indexOf(value);
    let selected = [];
    if (selectedIndex === -1) {
      selected = selected.concat(marketplaceList, value);
    } else {
      selected = cloneDeep(marketplaceList);
      selected.splice(selectedIndex, 1);
    }
    setMarketplaceList(selected);
  }

  const isSelected = (name) => marketplaceList.indexOf(name) !== -1;

  const isValid = (id) => {
   const value = marketPlaces?.find(item => item.id === id && item.is_participating === true );
   if(!isEmpty(value)) return false;
   else return true;
  }

  const isDisable = isEmpty(marketplaceList) || loading;
  const { name } = storeData;

  return (
    <StoreWrapper>
      <Header login={true} />
      <div  className='change-background'>
      <Spin size = 'large' tip={`${createStoreLoading ? 'Creating' : 'Authorizing'} Store...`} spinning={createStoreLoading || authorizingAmazonStore}>
      <div className="select-region">
        <div className="arrow-box grid-column-gap-10">
          <BiLeftArrowAlt className="cursor-pointer" onClick={() => navigate(-1)} />
          <h1>Add Marketplace</h1>
        </div>
        <div className="search-box text-center mt-20 mb-20">
          <Row className="align-items-center justify-content-center">
            <Col lg={6} md={8} sm={12}>
              <SearchInput placeholder="Type Store Name Here" value={isReAuthorize ? name : storeName} disabled/>
            </Col>
          </Row>
        </div>
        <Row className="justify-content-center">
          <Col lg={3} md={5}>
          <Spin size = 'large' tip='Fetching marketplaces...' spinning={marketPlaceLoading}>
            <div className="pl-39">
              <h3 className="color-primary fw-bold mb-4">Your {upperCase(spApiRegion)} Region</h3>
              {/* <div className="caption mb-2">Select Region from</div> */}
              {/* <span className="select_region">{startCase(params.id)} Region</span> */}
              <div className="region_marketplace">
                <div className="add_scrolll">
                  {marketPlace?.map((countries, index) => <Checkbox
                    disabled = {isValid(countries.id)}
                    type="checkbox" 
                    label={<><img src={countries.country} key={index} alt="no-marketplace" /><span className="flag-name">{countries.name}</span></>} 
                    onChange={() => handleMarketPlaces(countries.id)} 
                    checked={isSelected(countries.id)}
                    />)}
                </div>
                <Button className="w-100" text={loading? "Saving...": "Save"} onClick={saveMarketplacesList} disabled={isDisable} />
              </div>
            </div>
            </Spin>
          </Col>
          <Col lg={9} md={7}>
            <div className="map_region">
              <img src={map} alt="no-region" />
            </div>
          </Col>
        </Row>
      </div>
      </Spin>
      </div>
    </StoreWrapper>
  )
}

export default SelectRegion
