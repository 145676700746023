import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  fetchingAggregateStats: false,
  invoices: [],
  cities: [],
  states: [],
  amazonLocations: [],
  total_records: 0,
  filters: [],
  totalBalanceToCharge: -1,
  current_default_source_bank: null,
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  updatedInvoice: {},
  users: [],
  invoiceStats: {
    fetching: false,
    stats: {}
  },
  aggregated_record: {
    credit_card_surcharge_records: 0.00,
    credit_records: 0.00,
    pallet_cost_records: 0.00,
    prep_total_records: 0.00,
    product_total_records: 0.00,
    shipped_cost_records: 0.00,
    tax_records: 0.00,
    total_invoices_records: 0.00
  },
  archivingInvoices: false,
  uploadLinkLoading: false
};

const customerInvoices = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'CALCULATE_TOTAL_BALANCE_REQUEST':
    case 'CUSTOMER_INVOICES_APPROVE_INVOICE_REQUEST':
    case 'CUSTOMER_INVOICES_UNAPPROVE_INVOICE_REQUEST':
    case 'GET_USERS_FOR_CUSTOMER_INVOICES_REQUEST':
    case 'SET_UPDATE_DATE_REQUEST':
    case 'GET_EXPORT_CUSTOMER_INVOICES_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_AGGREGATE_RECORD_REQUEST': {
      return {
        ...state,
        fetchingAggregateStats: true
      }
    }
    case 'GET_AGGREGATE_RECORD_SUCCESS':
    case 'GET_AGGREGATE_RECORD_FAILED': {
      return {
        ...state,
        ...action.payload,
        fetchingAggregateStats: false
      }
    }
    case 'CALCULATE_TOTAL_BALANCE_SUCCESS':
    case 'GET_USERS_FOR_CUSTOMER_INVOICES_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        fetching: false
      };
    }
    case 'GET_USERS_FOR_CUSTOMER_INVOICES_FAILED': {
      return {
        ...state
      };
    }
    case 'GET_CITY_NAMES_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_CITY_NAMES_SUCCESS': {
      const { cities, states, amazon_locations } = action?.payload || {};
      return {
        ...state,
        fetching: false,
        cities,
        states,
        amazonLocations: amazon_locations,
      };
    }
    case 'GET_CITY_NAMES_FAILED':
    case 'SET_UPDATE_DATE_SUCCESS':
    case 'GET_EXPORT_CUSTOMER_INVOICES_REQUEST_SUCCESS':
    case 'GET_EXPORT_CUSTOMER_INVOICES_REQUEST_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_FILTERS_CUSTOMER_INVOICES': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_SORT_OPTIONS_CUSTOMER_INVOICES': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'CONFIRM_PAYMENT_REQUEST':
    case 'CUSTOMER_INVOICES_UPDATE_INVOICE_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'CONFIRM_PAYMENT_SUCCESS':
    case 'CONFIRM_PAYMENT_FAILED':
    case 'CUSTOMER_INVOICES_UPDATE_INVOICE_SUCCESS': {
      return {
        ...state,
        fetching: false,
        updatedInvoice: action.payload
      };
    }
    case 'CALCULATE_TOTAL_BALANCE_FAILED':
    case 'CUSTOMER_INVOICES_UPDATE_INVOICE_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'CLEAR_UPDATED_ROW_FOR_INVOICE': {
      return {
        ...state,
        updatedInvoice: {}
      }
    }
    case 'GET_CUSTOMER_INVOICES_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_CUSTOMER_INVOICES_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      };
    }
    case 'CUSTOMER_INVOICES_APPROVE_INVOICE_SUCCESS':
    case 'CUSTOMER_INVOICES_APPROVE_INVOICE_FAILED':
    case 'CUSTOMER_INVOICES_UNAPPROVE_INVOICE_FAILED':
    case 'CUSTOMER_INVOICES_UNAPPROVE_INVOICE_SUCCESS':
    case 'GET_CUSTOMER_INVOICES_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_PAGINATION_CUSTOMER_INVOICES': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    case 'CUSTOMER_INVOICES_DOWNLOAD_INVOICE_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'CUSTOMER_INVOICES_DOWNLOAD_INVOICE_SUCCESS':
    case 'CUSTOMER_INVOICES_DOWNLOAD_INVOICE_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'CLEAR_CHARGING_AMOUNT': {
      return {
        ...state,
        totalBalanceToCharge: -1,
        current_default_source_bank: null
      };
    }
    case 'GET_SHIPMENT_INVOICES_STATS_REQUEST': {
      return {
        ...state,
        invoiceStats: {
          ...state.invoiceStats,
          fetching: true
        }
      };
    }
    case 'GET_SHIPMENT_INVOICES_STATS_FAILED':
    case 'GET_SHIPMENT_INVOICES_STATS_SUCCESS': {
      return {
        ...state,
        invoiceStats: {
          ...state.invoiceStats,
          ...action.payload,
          fetching: false
        }
      };
    }

    case 'ARCHIVE_SHIPMENT_INVOICES_REQUEST': {
      return {
        ...state,
        archivingInvoices: true
      }
    }
    case 'ARCHIVE_SHIPMENT_INVOICES_SUCCESS':
    case 'ARCHIVE_SHIPMENT_INVOICES_FAILED': {
      return {
        ...state,
        archivingInvoices: false
      }
    }
    case 'UPDATE_SHIPMENT_INVOICE_UPLOAD_LINK_REQUEST' :
    case 'GET_PRE_SIGNED_URL_REQUEST':
    case 'DELETE_SHIPMENT_INVOICE_UPLOAD_LINK_REQUEST':
    case 'UPLOAD_SHIPMENT_INVOICE_TO_S3_REQUEST': {
      return {
        ...state,
        uploadLinkLoading: true
      }
    }
    case 'GET_PRE_SIGNED_URL_FAILED':
    case 'UPDATE_SHIPMENT_INVOICE_UPLOAD_LINK_FAILED' :
    case 'UPLOAD_SHIPMENT_INVOICE_TO_S3_SUCCESS':
    case 'GET_PRE_SIGNED_URL_SUCCESS' :
    case 'DELETE_SHIPMENT_INVOICE_UPLOAD_LINK_SUCCES':
    case 'UPDATE_SHIPMENT_INVOICE_UPLOAD_LINK_SUCCES' :
    case 'DELETE_SHIPMENT_INVOICE_UPLOAD_LINK_FAILED':
    case 'UPLOAD_SHIPMENT_INVOICE_TO_S3_FAILED': {
      return {
        ...state,
        uploadLinkLoading: false
      }
    }

    default:
      return state;
  }
};

export default customerInvoices;
