import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleRejectedResponse } from '../utils';
import axiosRails, { fetch as axios } from '../../../axios_config';
import { notification } from 'antd';
import { isEmpty } from 'lodash';

export const GetShipmentDetails = createAsyncThunk(
  '/get-shipment-details/:shipment_id',
  async (shipment_id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/get-shipment-details/${shipment_id}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Shipment Details', rejectWithValue);
    }
  }
);

export const VoidTransportPlan = createAsyncThunk(
  '/void-transport-plan/:shipment_id',
  async (shipment_id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.delete(
        `${process.env.FBA_INBOUND_API_URL}/void-transport-plan/${shipment_id}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Void Transport Plan', rejectWithValue);
    }
  }
);

export const ResetTransportPlan = createAsyncThunk(
  '/reset-transport-plan/:shipment_id',
  async (shipment_id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.delete(
        `${process.env.FBA_INBOUND_API_URL}/reset-transport-plan/${shipment_id}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Reset Transport Plan', rejectWithValue);
    }
  }
);

export const ReceiveCompleteBox = createAsyncThunk(
  '/receive-complete-box',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/receive-complete-box`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Receive Complete Box', rejectWithValue);
    }
  }
);

export const ReceiveLooseBox = createAsyncThunk(
  '/receive-loose-box',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/receive-loose-box`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Receive Loose Box', rejectWithValue);
    }
  }
);

export const EstimateTransportPlan = createAsyncThunk(
  '/estimate-transport-plan/:shipment_id',
  async ({ transportDetails, shipmentType, isPartnered, shipmentID }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/estimate-transport-plan/${shipmentID}`,
        {
          transportDetails, shipmentType, isPartnered
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Estimate Transport Plan', rejectWithValue);
    }
  }
);

export const UpdateQuantityWith3PL = createAsyncThunk(
  '/update_quantity_with_3pl/:shipment_id',
  async (shipment_id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/update_quantity_with_3pl/${shipment_id}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Quantity With 3PL', rejectWithValue);
    }
  }
);

export const ConfirmTransportPlan = createAsyncThunk(
  '/confirm-transport-plan/:shipment_id',
  async ({ transportDetails, shipmentType, isPartnered, shipmentID }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/confirm-transport-plan/${shipmentID}`,
        {
          transportDetails, shipmentType, isPartnered
        },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Confirm Transport Plan', rejectWithValue);
    }
  }
);

export const GetAllBoxIndexes = createAsyncThunk(
  '/all-box-indexes/:shipment_id',
  async (shipmentID, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/all-box-indexes/${shipmentID}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'All Box Indexes', rejectWithValue);
    }
  }
);

export const GetAllShipmentBoxes = createAsyncThunk(
  '/show-boxes/:shipment_id',
  async (shipmentID, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/show-boxes/${shipmentID}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'All Shipment Boxes', rejectWithValue);
    }
  }
);

export const GetLabels = createAsyncThunk(
  '/get-labels/:shipment_id',
  async ({ NumberOfPallets, labelType, PageSize, PageStartIndex, shipmentID, pageType }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/get-labels/${shipmentID}`,
        pageType ? {
          NumberOfPallets, labelType, PageSize, PageStartIndex, pageType
        } : { NumberOfPallets, labelType, PageSize, PageStartIndex },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Labels', rejectWithValue);
    }
  }
);

export const GetBolFile = createAsyncThunk(
  '/get-bill-landing/:shipment_id',
  async (shipmentID, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/get-bill-landing/${shipmentID}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get BOL', rejectWithValue);
    }
  }
);

export const UpdatePlanType = createAsyncThunk(
  '/update-plan-type/:shipment_id',
  async ({ shipmentID, data }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/update-plan-type/${shipmentID}`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Plan Type', rejectWithValue);
    }
  }
);

export const UpdateBoxDimensions = createAsyncThunk(
  '/update-box-dimensions',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/update-box-dimensions`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Box Dimensions', rejectWithValue);
    }
  }
);

export const MarkAsShipped = createAsyncThunk(
  '/mark-as-shipped/:shipment_id',
  async (shipment_id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/mark-as-shipped/${shipment_id}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Mark As Shipped', rejectWithValue);
    }
  }
);

export const RegenerateShipmentInvoice = createAsyncThunk(
  '/inbound_shipments/:shipment_id/generate_shipment_invoice',
  async (shipment_id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axiosRails.post(
        `/api/v2/inbound_shipments/${shipment_id}/generate_shipment_invoice`,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Regenerate Shipment Invoice', rejectWithValue);
    }
  }
);

export const postShipmentInitialState = {
  fetchingShipmentDetails: false,
  shipmentDetails: {},
  deletingTransportPlan: false,
  estimatingTransportPlan: false,
  receivingCompleteBox: false,
  receivingLooseBox: false,
  updatingQuantityWith3pl: false,
  transportContent: {},
  confirmingTransportPlan: false,
  fetchingBoxIndexes: false,
  boxIndexes: [],
  fetchingShipmentBoxes: false,
  shipmentBoxes: [],
  fetchingLabels: false,
  fetchingBolFile: false,
  updatingPlanType: false,
  updatingBoxDimensions: false,
  markingAsShipped: false,
  regeneratingShipmentInvoice: false
};

export const postShipmentReducers = {
  [GetShipmentDetails.pending]: (state, action) => ({
    ...state,
    postShipment: {
      ...state.postShipment,
      fetchingShipmentDetails: true,
      shipmentDetails: {}
    }
  }),
  [GetShipmentDetails.fulfilled]: (state, action) => {
    const { inboundShipment, totalWeight } = action?.payload?.data || {};

    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        fetchingShipmentDetails: false,
        shipmentDetails: { ...inboundShipment, totalWeight },
        transportContent: inboundShipment?.transport_contents?.[0]
      }
    }
  },
  [GetShipmentDetails.rejected]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        fetchingShipmentDetails: false,
      }
    }
  },
  [VoidTransportPlan.pending]: (state, action) => ({
    ...state,
    postShipment: {
      ...state.postShipment,
      deletingTransportPlan: true,
    }
  }),
  [VoidTransportPlan.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Void Transport Plan',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        deletingTransportPlan: false,
      }
    }
  },
  [VoidTransportPlan.rejected]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        deletingTransportPlan: false,
      }
    }
  },
  [ResetTransportPlan.pending]: (state, action) => ({
    ...state,
    postShipment: {
      ...state.postShipment,
      deletingTransportPlan: true,
    }
  }),
  [ResetTransportPlan.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Reset Transport Plan',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        deletingTransportPlan: false,
      }
    }
  },
  [ResetTransportPlan.rejected]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        deletingTransportPlan: false,
      }
    }
  },
  [EstimateTransportPlan.pending]: (state, action) => ({
    ...state,
    postShipment: {
      ...state.postShipment,
      estimatingTransportPlan: true,
      transportContent: {}
    }
  }),
  [EstimateTransportPlan.fulfilled]: (state, action) => {
    const { result, transportContent } = action?.payload?.data || {};
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        estimatingTransportPlan: false,
        transportContent
      }
    }
  },
  [EstimateTransportPlan.rejected]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        estimatingTransportPlan: false,
      }
    }
  },
  [ReceiveCompleteBox.pending]: (state, action) => ({
    ...state,
    postShipment: {
      ...state.postShipment,
      receivingCompleteBox: true,
    }
  }),
  [ReceiveCompleteBox.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Receive Complete Box(es)',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        receivingCompleteBox: false,
      }
    }
  },
  [ReceiveCompleteBox.rejected]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        receivingCompleteBox: false,
      }
    }
  },
  [ReceiveLooseBox.pending]: (state, action) => ({
    ...state,
    postShipment: {
      ...state.postShipment,
      receivingLooseBox: true,
    }
  }),
  [ReceiveLooseBox.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Receive Loose Box',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        receivingLooseBox: false,
      }
    }
  },
  [ReceiveLooseBox.rejected]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        receivingLooseBox: false,
      }
    }
  },
  [UpdateQuantityWith3PL.pending]: (state, action) => ({
    ...state,
    postShipment: {
      ...state.postShipment,
      updatingQuantityWith3pl: true,
    }
  }),
  [UpdateQuantityWith3PL.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Update Quantity With 3PL',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        updatingQuantityWith3pl: false,
      }
    }
  },
  [UpdateQuantityWith3PL.rejected]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        updatingQuantityWith3pl: false,
      }
    }
  },
  [ConfirmTransportPlan.pending]: (state, action) => ({
    ...state,
    postShipment: {
      ...state.postShipment,
      confirmingTransportPlan: true,
    }
  }),
  [ConfirmTransportPlan.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Confirm Transport Plan',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        confirmingTransportPlan: false,
      }
    }
  },
  [ConfirmTransportPlan.rejected]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        confirmingTransportPlan: false,
      }
    }
  },
  [GetAllBoxIndexes.pending]: (state, action) => ({
    ...state,
    postShipment: {
      ...state.postShipment,
      fetchingBoxIndexes: true,
      boxIndexes: []
    }
  }),
  [GetAllBoxIndexes.fulfilled]: (state, action) => {
    const { result } = action?.payload?.data || {};

    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        fetchingBoxIndexes: false,
        boxIndexes: result
      }
    }
  },
  [GetAllBoxIndexes.rejected]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        fetchingBoxIndexes: false,
      }
    }
  },
  [GetAllShipmentBoxes.pending]: (state, action) => ({
    ...state,
    postShipment: {
      ...state.postShipment,
      fetchingShipmentBoxes: true,
      shipmentBoxes: []
    }
  }),
  [GetAllShipmentBoxes.fulfilled]: (state, action) => {
    const { result, count } = action?.payload?.data || {};

    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        fetchingShipmentBoxes: false,
        shipmentBoxes: result
      }
    }
  },
  [GetAllShipmentBoxes.rejected]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        fetchingShipmentBoxes: false,
      }
    }
  },
  [GetLabels.pending]: (state, action) => ({
    ...state,
    postShipment: {
      ...state.postShipment,
      fetchingLabels: true,
    }
  }),
  [GetLabels.fulfilled]: (state, action) => {
    const { result } = action?.payload?.data || {};
    const { DownloadURL } = result || {};
    window.open(DownloadURL, '_blank');

    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        fetchingLabels: false,
      }
    }
  },
  [GetLabels.rejected]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        fetchingLabels: false,
      }
    }
  },
  [GetBolFile.pending]: (state, action) => ({
    ...state,
    postShipment: {
      ...state.postShipment,
      fetchingBolFile: true,
    }
  }),
  [GetBolFile.fulfilled]: (state, action) => {
    const { result } = action?.payload?.data || {};

    if (isEmpty(result)) {
      notification.error({
        top: 65,
        message: 'BOL is not ready yet, please try again later'
      })
    }
    else {
      const { DownloadURL } = result || {};
      window.open(DownloadURL, '_blank');
    }

    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        fetchingBolFile: false,
      }
    }
  },
  [GetBolFile.rejected]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        fetchingBolFile: false,
      }
    }
  },
  [UpdatePlanType.pending]: (state, action) => ({
    ...state,
    postShipment: {
      ...state.postShipment,
      updatingPlanType: true,
    }
  }),
  [UpdatePlanType.fulfilled]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        updatingPlanType: false,
      }
    }
  },
  [UpdatePlanType.rejected]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        updatingPlanType: false,
      }
    }
  },
  [UpdateBoxDimensions.pending]: (state, action) => ({
    ...state,
    postShipment: {
      ...state.postShipment,
      updatingBoxDimensions: true,
    }
  }),
  [UpdateBoxDimensions.fulfilled]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        updatingBoxDimensions: false,
      }
    }
  },
  [UpdateBoxDimensions.rejected]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        updatingBoxDimensions: false,
      }
    }
  },
  [MarkAsShipped.pending]: (state, action) => ({
    ...state,
    postShipment: {
      ...state.postShipment,
      markingAsShipped: true,
    }
  }),
  [MarkAsShipped.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Mark As Shipped',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        markingAsShipped: false,
      }
    }
  },
  [MarkAsShipped.rejected]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        markingAsShipped: false,
      }
    }
  },
  [RegenerateShipmentInvoice.pending]: (state, action) => ({
    ...state,
    postShipment: {
      ...state.postShipment,
      regeneratingShipmentInvoice: true,
    }
  }),
  [RegenerateShipmentInvoice.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Regenerate Shipment Invoice',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        regeneratingShipmentInvoice: false,
      }
    }
  },
  [RegenerateShipmentInvoice.rejected]: (state, action) => {
    return {
      ...state,
      postShipment: {
        ...state.postShipment,
        regeneratingShipmentInvoice: false,
      }
    }
  },
};
