import JsPDF from 'jspdf';
import JsBarcode from 'jsbarcode';

const genrateFNSkuBarCode = (sku) => {
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, sku, {
    format: 'CODE128A',
    text: sku,
    font: 'sans-serif',
    fontSize: 40,
    textAlign: 'center',
    width: 5,
    // height: 85,
    margin: 1,
    displayValue: false
  });
  return canvas.toDataURL('image/png');
};

const formatpdf = (pdfDoc, barcode, fnsku, packageQuantity, condition, expiryDate, title, companyName) => {
  pdfDoc.setFontSize(8);
  pdfDoc.addImage(barcode, 'PNG', 9, 10, 142, 40);
  pdfDoc.setFontSize(6.8);
  pdfDoc.text(`${fnsku}, Pack of ${packageQuantity}, ${condition ? condition : 'New'}, EXP. ${expiryDate}`, 8, 62);
  const firstLineForTitles = title.substring(0, 42);
  const secondLineForTitles = title.substring(42, 84);
  pdfDoc.text(firstLineForTitles, 8, 73);
  if (secondLineForTitles) {
    pdfDoc.text(`${secondLineForTitles} ${(firstLineForTitles.length + secondLineForTitles.length < title.length) ? '...' : ''}`, 8, 82);
  }
};

const instructionLabel = (pdfDoc, username, prapInstructions, packageQuantity, title, bundleQty) => {
  let y = 0;
  const centerX = 81;
  const x = 8;


  let titleLines = pdfDoc.splitTextToSize(title, 250);

  titleLines = titleLines.slice(0, 2);

  pdfDoc.setFont('helvetica', 'normal');

  y += 15;
  pdfDoc.setFontSize(12);
  pdfDoc.setFontStyle('bold');
  pdfDoc.text(username, centerX, y, 'center');

  const instructions = pdfDoc.splitTextToSize(`Prep. Instructions: ${prapInstructions ? prapInstructions.toString() : 'N/A'}`, 170);
  y += 10;
  pdfDoc.setFontSize(8);
  pdfDoc.setFontStyle('bold');
  pdfDoc.text(instructions, x, y);
  y += 5;

  const product = pdfDoc.splitTextToSize(`Pack of ${packageQuantity}, ${titleLines}`, 170);
  prapInstructions.toString()?.length > 40 ? y += 22: y += 15;
  pdfDoc.setFontSize(7);
  pdfDoc.setFontStyle('normal');
  pdfDoc.text(product, x, y);
  y += 20;
  pdfDoc.setFontSize(8);
  pdfDoc.setFontStyle('bold');
  pdfDoc.text(`Total Bundles: ${bundleQty}`, x, y)

  pdfDoc.setFontSize(7);
  pdfDoc.setFontStyle('normal');


  return pdfDoc;
};

const zebraLabels = ({
  fnsku,
  title,
  condition,
  packageQuantity,
  expiryDate,
  numberOfLabels,
  bundleQty,
  username,
  companyName,
  prepInstruction = []
}) => {
  let totalNumberOfLabels = Math.ceil(numberOfLabels);
  let pdfDoc = new JsPDF('landscape', 'pt', [162, 90]);
  pdfDoc = instructionLabel(pdfDoc, username, prepInstruction, packageQuantity, title, bundleQty);

  const barcode = genrateFNSkuBarCode(fnsku);
  // pdfDoc.setFont('times', 'normal');

  for (let index = 0; index < numberOfLabels; index += 1) {
    pdfDoc.addPage([162, 90], 'landscape');
    formatpdf(pdfDoc, barcode, fnsku, packageQuantity, condition, expiryDate, title, companyName);
  }
  pdfDoc.save(`${fnsku}.pdf`);
};

export default zebraLabels;