import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  fetchingWarehouses: false,
  invoices: [],
  warehouses: [],
  total_records: 0,
  filters: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  updatedInvoice: {}
};

const userStorageInvoices = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'SET_FILTERS_USER_STORAGE_INVOICES': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_SORT_OPTIONS_USER_STORAGE_INVOICES': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_STORAGE_INVOICE_REQUEST':
    case 'GET_USER_STORAGE_INVOICES_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_WAREHOUSES_FOR_USER_STORAGE_INVOICES_REQUEST': {
      return {
        ...state,
        fetchingWarehouses: true
      };
    }
    case 'GET_WAREHOUSES_FOR_USER_STORAGE_INVOICES_SUCCESS': {
      return {
        ...state,
        fetchingWarehouses: false,
        ...action.payload
      };
    }
    case 'GET_WAREHOUSES_FOR_USER_STORAGE_INVOICES_FAILED': {
      return {
        ...state,
        fetchingWarehouses: false
      };
    }
    case 'GET_USER_STORAGE_INVOICES_SUCCESS': {
      const { total_records, storage_invoices } = action.payload;
      return {
        ...state,
        fetching: false,
        total_records,
        invoices: storage_invoices
      };
    }
    case 'GET_STORAGE_INVOICE_SUCCESS':
    case 'GET_STORAGE_INVOICE_FAILED':
    case 'GET_USER_STORAGE_INVOICES_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_PAGINATION_USER_STORAGE_INVOICES': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    default:
      return state;
  }
};

export default userStorageInvoices;
