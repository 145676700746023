import Styled from 'styled-components';

const UploadWrapper = Styled.div`
   /* padding-left: 42px;
    padding-right: 33px; */
.input-type{
    position: relative;
    input{
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
    }
    input[type="file"] {
         cursor: pointer;
    }
    .uploaded-image-desc{
        font-size: ${({ theme }) => theme['base-font-size-small']};
        color: ${({ theme }) => theme['disabled-color']};
    }
}
.upload-file{
    border: 1px dashed #C4C4C4;
    border-radius:12px;
    padding:28px 20px;
    margin-top:19px;
    cursor: pointer;
    .upload{
        .icon-upload{
            font-size:33px;
            font-size: 33px;
        }
        span:nth-child(2){
            font-size: ${({ theme }) => theme['medium-font-size']};
            margin-top:8px;
        }
        span:nth-child(3){
            font-size: ${({ theme }) => theme['base-font-size-small']};
            color: ${({ theme }) => theme['disabled-color']};
        }
    }

    .divider{
          display: flex;
          color: #000000d9;
          font-weight: 500;
          font-size: 16px;
          white-space: nowrap;
          text-align: center;
          border-top: 0;
          border-top-color: #0000000f;
          clear: both;
          width: 100%;
          min-width: 100%;
          margin-top:11px;
          margin-bottom:5px;
            &::before{
              position: relative;
            top: 50%;
            width: 50%;
            right:8px; p
            border-top: 1px solid transparent;
            border-top-color: inherit;
            border-bottom: 0;
            transform: translateY(50%);
            content: "";
            }
              &::after{
              position: relative;
              top: 50%;
              width: 50%;
              left:8px;
              border-top: 1px solid transparent;
              border-top-color: inherit;
              border-bottom: 0;
              transform: translateY(50%);
              content: "";
                  }
                  .inner-text{
                      font-size: ${({ theme }) => theme['base-font-size-small']};
                  }
              }
              button.btn{
                padding:7px 24px;
                font-size: 14px;
                line-height: 16px;
              }
              &.upload-progress{
                margin-top:48px;
                .progressbar{
                    width: 96px;
                    height: 96px;
                    margin: 0 auto;
                    margin-top:0px;
             
                    .CircularProgressbar{
                        .CircularProgressbar-text{
                            font-size: 24px;
                            font-weight:700;
                            line-height:29.05px;
                            transform:translate(4px, 3px);
                            letter-spacing:-1px;
                        }
                    }
                }
                .progress-status{
                        font-size: ${({ theme }) => theme['medium-font-size']};
                        line-height:16px;
                        color:${({ theme }) => theme['primary-text-color']};
                        margin-top:24px;
                        margin-bottom:-2px;
                        text-align:center;
                        margin-left:-5px;
                    }
              }

}
`
export { UploadWrapper }
