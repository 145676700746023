import React, { useState } from "react";
import { ModalWrapper } from "./style";
import { Modal } from "react-bootstrap";
import Button from "../../uiComponent/button/index";
import { Link } from "react-router-dom"
import "../../../assets/modal.css"
const Index = (props) => {
  const { open, onHide, onClose, onSave, header, heading, className, closeText, saveText, size, closeButton, centerAlign, buttonDisabled } = props;
  return (
    <ModalWrapper size={size} show={open} onHide={onHide} className={className} centered={true}>
      {header && (
        <Modal.Header closeButton={closeButton}>
          <Modal.Title className="fw-bold">{heading}</Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body className="p-4" >{props.children}</Modal.Body>
      {saveText &&
        <Modal.Footer className={`grid-column-gap-36 ${centerAlign && "justify-content-center"}`}>
          <Link to="#" variant="secondary" className="text-decoration-none fw-bold" onClick={onClose}>
            {closeText}
          </Link>
          <Button className="outlined" disabled={buttonDisabled} onClick={onSave} text={saveText} />
        </Modal.Footer>
      }
    </ModalWrapper>
  );
};
export default Index;
