import { cloneDeep } from "lodash";
const initialState = {
  loading: false,
  cardsLoading: false,
  cards: [],
  plans: [],
  default_card: '',
  cancelLoading: false,
  subscription: {
    loading: false,
    active_subscription: {},
    cancelled_subscription: {},
    stand_by_subscription: {},
    subLoading: false,
    status: ''
  },
  invoice: {
    loading: false,
    invoices: [],
    total: 0,
    pageNo: 1,
    pageSize: 25,
    filters: [],
    sortOptions: {},
    invoicesType: []
  },
  coupon_code: null,
  bankAccount: {
    loading: false
  },
  subscriptionLoading: false,
  fetchingPrivacyPolicyStatus: false,
  updatingPrivacyPolicyStatus: false,
  retryShipmentInvoiceLoading: false
};

const payment = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'CANCEL_SUBSCRIPTION_REQUEST': {
      return {
        ...state,
        cancelLoading: true
      }
    }
    case 'SUBDCRIBE_TO_PLAN_REQUEST':
    case 'GET_SUBSCRIPTION_PLANS_REQUEST':
    case 'ADD_COUPON_REQUEST':
    case 'SAVE_MISC_REQUEST':
    case 'ACCESS_TOKEN_REQUEST':
    case 'INITIALIZE_LOADING_FOR_TOKEN_GENERATION': {
      return {
        ...state,
        loading: true
      };
    }
    case 'SAVE_MISC_SUCCESS':
    case 'SUBDCRIBE_TO_PLAN_FAILED':
    case 'SUBDCRIBE_TO_PLAN_SUCCESS':
    case 'GET_SUBSCRIPTION_PLANS_FAILED':
    case 'GET_SUBSCRIPTION_PLANS_SUCCESS':
    case 'ADD_COUPON_SUCCESS':
    case 'ADD_COUPON_FAILED':
    case 'ACCESS_TOKEN_SUCCESSFUL':
    case 'ACCESS_TOKEN_FAILED':
    case 'TERMINATE_LOADING_FOR_TOKEN_GENERATION': {
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    }
    case 'RETRY_INVOICE_REQUEST': {
      return {
        ...state,
        subscriptionLoading: true
      }
    }
    case 'RETRY_INVOICE_SUCCESS':
    case 'RETRY_INVOICE_FAILED': {
      return {
        ...state,
        subscriptionLoading: false,
        ...action.payload
      }
    }
    case 'DELETE_CARD_SUCCESS':
    case 'DELETE_CARD_FAILED': {
      return {
        ...state,
        cardsLoading: false
      };
    }
    case 'UPDATE_CUSTOMER_DEFAULT_CARD_REQUEST': {
      return {
        ...state,
        cardsLoading: true,
      };
    }
    case 'UPDATE_CUSTOMER_DEFAULT_CARD_SUCCESS': {
      const { default_source } = action.payload;

      return {
        ...state,
        cardsLoading: false,
        default_card: default_source
      };
    }
    case 'UPDATE_CUSTOMER_DEFAULT_CARD_FAILED': {
      return {
        ...state,
        cardsLoading: false
      };
    }
    case 'GET_CUSTOMER_CARDS_REQUEST':
    case 'DELETE_CARD_REQUEST': {
      return {
        ...state,
        cardsLoading: true
      };
    }
    case 'GET_CUSTOMER_CARDS_SUCCESS': {
      const { cards, default_card } = action.payload;
      return {
        ...state,
        cardsLoading: false,
        cards,
        default_card
      };
    }
    case 'SAVE_MISC_FAILED':
    case 'CANCEL_SUBSCRIPTION_SUCCESS':
    case 'CANCEL_SUBSCRIPTION_FAILED': {
      return {
        ...state,
        loading: false,
        cancelLoading: false,
      };
    }
    case 'GET_CUSTOMER_CARDS_FAILED': {
      return {
        ...state,
        cardsLoading: false
      };
    }
    case 'UPDATE_USER_CARD_FOR_PAYMENT_REQUEST':
    case 'CREATE_USER_CARD_FOR_PAYMENT_REQUEST': {
      return {
        ...state,
        loading: true
      };
    }
    case 'UPDATE_USER_CARD_FOR_PAYMENT_SUCCESS':
    case 'CREATE_USER_CARD_FOR_PAYMENT_SUCCESS': {
      return {
        ...state,
        loading: false
      };
    }
    case 'UPDATE_USER_CARD_FOR_PAYMENT_FAILED':
    case 'CREATE_USER_CARD_FOR_PAYMENT_FAILED': {
      return {
        ...state,
        loading: false
      };
    }
    case 'GET_SUBSCRIPTION_REQUEST': {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          subLoading: true
        }
      };
    }
    case 'GET_SUBSCRIPTION_SUCCESS': {
      return {
        ...state,
        subscription: {
          subLoading: false,
          ...action.payload
        }
      };
    }
    case 'GET_SUBSCRIPTION_FAILED': {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          subLoading: false
        }
      };
    }
    case 'GET_SUBSCRIPTION_FAILED_WITH_401': {
      return {
        ...state,
        subscription: {
          ...state.subscription,
          loading: false,
          status: 401
        }
      };
    }
    case 'GET_PAYMENT_INVOICE_REQUEST': {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          loading: true
        }
      };
    }
    case 'GET_PAYMENT_INVOICE_SUCCESS': {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          ...action.payload,
          loading: false
        }
      };
    }
    case 'DOWNLOAD_SHIPMENT_FAILED':
    case 'GET_PAYMENT_INVOICE_FAILED': {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          loading: false
        }
      };
    }
    case 'SET_INVOICE_PAGE_NUMBER': {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          ...action.payload
        }
      };
    }
    case 'SET_INVOICE_PAGE_SIZE': {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          ...action.payload
        }
      };
    }
    case 'SET_INVOICE_TYPE': {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          ...action.payload
        }
      };
    }
    case 'SET_FILTERS_PAYMENT_INVOICES': {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          ...action.payload
        }
      };
    }
    case 'SET_SORT_OPTIONS_PAYMENT_INVOICES': {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          ...action.payload
        }
      };
    }
    case 'DOWNLOAD_SHIPMENT_REQUEST': {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          loading: true
        }
      };
    }
    case 'DOWNLOAD_SHIPMENT_SUCCESS': {
      return {
        ...state,
        invoice: {
          ...state.invoice,
          loading: false
        }
      };
    }
    case 'DELETE_ACCOUNT_REQUEST': {
      return {
        ...state,
        loading: true
      };
    }
    case 'DELETE_ACCOUNT_SUCCESS': {
      return {
        ...state,
        loading: false
      };
    }
    case 'DELETE_ACCOUNT_FAILED': {
      return {
        ...state,
        loading: false
      };
    }
    case 'ADD_BANK_ACCOUNT_WITH_INTUIT_REQUEST': {
      return {
        ...state,
        bankAccount: {
          ...state.bankAccount,
          loading: true
        }
      };
    }
    case 'ADD_BANK_ACCOUNT_WITH_INTUIT_FAILED':
    case 'ADD_BANK_ACCOUNT_WITH_INTUIT_SUCCESS': {
      return {
        ...state,
        bankAccount: {
          ...state.bankAccount,
          loading: false
        }
      };
    }
    case 'GET_PRIVACY_POLICY_STATUS_REQUEST': {
      return {
        ...state,
        fetchingPrivacyPolicyStatus: true
      }
    }
    case 'GET_PRIVACY_POLICY_STATUS_SUCCESS':
    case 'GET_PRIVACY_POLICY_STATUS_FAILED': {
      return {
        ...state,
        fetchingPrivacyPolicyStatus: false
      }
    }
    case 'UPDATE_PRIVACY_POLICY_STATUS_REQUEST': {
      return {
        ...state,
        updatingPrivacyPolicyStatus: true
      }
    }
    case 'UPDATE_PRIVACY_POLICY_STATUS_SUCCESS':
    case 'UPDATE_PRIVACY_POLICY_STATUS_FAILED': {
      return {
        ...state,
        updatingPrivacyPolicyStatus: false
      }
    }
    case 'RETRY_SHIPMENT_INVOICES_REQUEST': {
      return {
        ...state,
        retryShipmentInvoiceLoading: true
      }
    }
    case 'RETRY_SHIPMENT_INVOICES_SUCCESS':
    case 'RETRY_SHIPMENT_INVOICES_FAILED': {
      return {
        ...state,
        retryShipmentInvoiceLoading: false
      }
    }

    default:
      return state;
  }
};

export default payment;
