import React from 'react'
import { SearchInputWrapper } from '../style'
import { Form } from "react-bootstrap"
import {BsSearch} from "react-icons/bs"
const Index = (props) => {
  const { type, placeholder, onChange, value, disabled, defaultValue, inputRef, iconLeft, width, style } = props
  return (
    <SearchInputWrapper iconLeft={iconLeft} width={width} style={style} >
      <Form.Group className={`postion-relative ${props.className}`} width={width}>
        <Form.Control
          defaultValue={defaultValue}
          disabled={disabled}
          value={value}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          ref={inputRef}
        />
        {props.className !== 'large-search' ? (
          <BsSearch className="color-primary cursor-pointer" />
        ) : (
          <span className="icon-arrow-return return-arrow"></span>
        )}
      </Form.Group>
    </SearchInputWrapper>
  )
}

export default Index
