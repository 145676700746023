import Styled from 'styled-components';

const TermsWrapper = Styled.div`
    .add-margin{
        margin-top:107px;
            strong{
                color:#444D50;
                font-weight:600
            }
    }
`
export {TermsWrapper};