import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { Dropdown } from "react-bootstrap";
import { HeaderWrapper } from "./style";
import Logo from "../../assets/images/newImages/new_log.png";
import { BsBell, BsCheckCircleFill } from "react-icons/bs";
import { BiChevronDown } from "react-icons/bi";
import { FiLogOut } from "react-icons/fi";
import { AiOutlineMenu } from "react-icons/ai";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { stopImpersonatingUser, SignOut } from "../../slices/auth";
import axios from '../../../axios_config';
import { isEmpty } from 'lodash';
import HamburgerIcon from "../menu-button/menu-button.component.jsx";
import MenuDropDown from "../menu-dropdown/menu-dropdown.component.jsx";
import { AnimatePresence } from "framer-motion";
import { Icon } from 'antd';
import { TabName } from '../menu-dropdown/menu-dropdown.styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

const Header = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser, parentUser, isImpersonatedCurrentUser } = useSelector(state => state?.auth || {});

  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const [navbarClicked, setNavbarClicked] = useState('');
  const [zIndex, setZIndex] = useState(0);

  const { login, onClick, setMakeBlur,disabled } = props || {};

  useEffect(() => {
    if (!isDropdownShown && !navbarClicked) {
      setTimeout(() => {
        setZIndex?.(0);
      }, 500);
      setMakeBlur?.(false);
    }
    else {
      setZIndex?.(1);
      setMakeBlur?.(true);
    }
  }, [isDropdownShown, navbarClicked]);

  useImperativeHandle(ref, () => ({
    closeMenu() {
      if (isDropdownShown) {
        setIsDropdownShown(false);
        setMakeBlur(false);
      }
      navbarClicked && setNavbarClicked('');
    }
  }));

  let user = {};
  if (!isEmpty(parentUser)) user = parentUser;
  else user = currentUser;
  let splitedName = user?.full_name?.toUpperCase()?.split(' ') || [];
  let abbreviatedName = `${splitedName?.[0]?.[0] || ''}${splitedName?.[1]?.[0] || ''}`;
  const isOwner = user?.email == 'steven@wraithco.com';

  const resetAndNavigate = async () => {
    const reducers = isOwner ? ['adminUsers'] : ['affiliate'];
    await dispatch({ type: 'RESET_APP', reducers });
    isOwner ? navigate('/admin/user') : navigate('/affiliate');
  }

  return (
    <HeaderWrapper drawerOpened={zIndex} disabled={disabled}>
      <div className="d-flex justify-content-between align-items-center h-100 header">
        {!login ? <img src={Logo} alt="no-log" onClick={() => navigate('/')} style={{ cursor: 'pointer' }} /> : ""}
        {login && (
          <>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "16px" }}>
              {/* <AiOutlineMenu onClick={onClick} /> */}
              <HamburgerIcon setIsDropdownShown={setIsDropdownShown} rotated={isDropdownShown} />
              <img src={Logo} alt="no-log" onClick={() => navigate('/')} style={{ cursor: 'pointer' }} />
              <TabName>|</TabName>
              <TabName style={{ cursor: 'pointer' }} onClick={() => setIsDropdownShown(!isDropdownShown)}>Menu</TabName>
              {/* <div className="white-divider h-30" /> */}
              {/* <h1 className="mb-0 text-white">Inventory</h1> */}
            </div>
            <div className="logn-session">
              {isImpersonatedCurrentUser &&
                <Dropdown className="switch-user" onToggle={(event) => setNavbarClicked(event ? 'impersonate' : '')}>
                  <Dropdown.Toggle>
                    <div className="switch-user align-items-center">
                      <span className="icon-user fs-25 mr-4"></span>
                      <div className="user-info d-flex flex-column align-items-start grid-row-gap-4">
                        <span className="userName">Impersonated By</span>
                        <span className="email">
                          {currentUser?.email}
                        </span>
                      </div>
                      <span className="icon-switch margin-right-4 fs-14" onClick={() => {
                        dispatch(stopImpersonatingUser());
                        localStorage.removeItem('shopifyStoreName');
                        resetAndNavigate();
                      }}></span>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu show={navbarClicked === 'impersonate'}>
                    <div className="userSwitchinfo">
                      <div className="d-flex align-items-center">
                        <div className="d-flex align-items-center grid-column-gap-16">
                          <span className="icon-user fs-16"></span>
                          <span className="username">{currentUser?.full_name}</span>
                        </div>
                        {/* <div>
                          <div className="status inactive">Inactive</div>
                        </div> */}
                      </div>
                      <div className="login-info d-flex flex-column">
                        <span className="email">{currentUser?.email}</span>
                        <span className="role color-primary fs-14 ">{currentUser?.role?.name}</span>
                        <span className="role color-primary fs-14">{currentUser?.company?.name}</span>

                        {/* <span className="tile color-secondary fs-14">Oliver Oil Box</span>
                        <span className="users color-secondary fs-14">Affiliate Manager<BsCheckCircleFill /><span>373 Users</span></span>
                        <span className="affilate color-secondary fs-14"> <span className="title">Affiliated to</span>  <b className="ml-4">N/A</b></span>
                        <span className="affilate color-secondary fs-14"><span className="title">Signup at</span><b>30 Apr, 2018 - 01:58:00 AM</b></span>
                        <span className="affilate color-secondary fs-14"><span className="title">Last Login</span> <b>28 Jan, 2021 - 07:34:00 PM</b></span> */}
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              }
              <div className="white-divider" />
              {currentUser?.email == 'steven@wraithco.com' && <FontAwesomeIcon icon={faUsers} className="add-margin" onClick={() => navigate('/admin/user')} />}
              {currentUser?.role?.name !== 'Worker' && <span className="icon-graph add-margin cursor-pointer" onClick={() => navigate('/referal-users')}></span>}
              <span className="icon-mointer fs-28 mr-31 cursor-pointer" onClick={() => window.open('https://thefbamachine-com.mykajabi.com/')}></span>
              {/* <div className="bell-badge cursor-pointer">
                <BsBell  />
                <div></div>
              </div> */}
              <Dropdown className="avatar" onToggle={(event) => setNavbarClicked(event ? 'logged-in-user' : '')}>
                <Dropdown.Toggle>
                  <span className="fs-12">{abbreviatedName}</span>
                  <span className="arrow-box">
                    <BiChevronDown />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu show={navbarClicked === 'logged-in-user'}>
                  <div className="user-dropdown">
                    <div className="d-flex grid-column-gap-10 marginy-8">
                      <div>
                        <span className="icon-user fs-16"></span>
                      </div>
                      <div className="d-flex flex-column grid-column-2">
                        <span className="username fs-14">{user?.full_name}</span>
                        <span className="email">{user?.email}</span>
                        <span className="role color-secondary fs-14">{user?.role?.name}</span>
                        <span className="role color-secondary fs-14">{user?.company?.name}</span>
                      </div>
                    </div>
                    <hr />
                    <div className="logout grid-column-gap-10 d-flex">
                      <div onClick={() => {
                        dispatch(SignOut());
                      }}>
                        <FiLogOut className="color-primary" />
                      </div>
                      <span >Logout</span>
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </>
        )}
      </div>

      <AnimatePresence>
        {isDropdownShown && <MenuDropDown isDropdownShown={isDropdownShown} setIsDropdownShown={setIsDropdownShown} />}
      </AnimatePresence>
    </HeaderWrapper>
  );
});

export default Header;
