import React, { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Tabs, Tab, Tooltip } from "react-bootstrap";
import { Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { GetStats } from '../../slices/fba-inbound';
//style
import { WarehouseWrapper } from "../warehouse/style";
//compoennt
import { TabsWrapper } from "../../components/uiComponent/tabs/style";
import CheckIn from "./component/checkIn";
import ToReceive from "./component/toReceive";
import ToShip from "./component/toShip";
//assets
import NoInbound from "../../assets/images/newImages/no-inbound.svg"; //in th case of no data use this image

const Index = () => {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState("check-in");

  const dispatch = useDispatch();
  const { mainPage: {
    stats,
    fetchingStats,
  } } = useSelector((state) => state.fbaInbound || {});
  const {
    arrivedNotReceivedPos, arrivedNotReceivedUnits,
    dailyArrivedUnits, dailyReceivedBundles
  } = stats || {};

  useEffect(() => {
    dispatch(GetStats());
  }, []);

  useEffect(() => {
    const param = new URLSearchParams(window.location.search);
    const currentTab = param.get('tab');
    const validTabs = ['check-in', 'to-ship', 'to-receive'];
    if (validTabs.includes(currentTab)) {
      setCurrentTab(currentTab);
    }
    else {
      setCurrentTab('check-in');  // Remove This
      navigate('/fba-inbound?tab=check-in');
    }
  }, [window.location.search]);

  const order = [
    {
      key: "check-in",
      title: "CHECK IN",
      component: <CheckIn />,
      tooltip: "From this section, you can mark Purchase Orders as Checked In and review Check In History for the Purchase Orders.",
    },
    {
      key: "to-ship",
      title: "SHIP",
      component: <ToShip />,
      tooltip: "From this section, you can see users whose Purchase Order are Checked In and are pending to create shipments over amazon.",
    },
    {
      key: "to-receive",
      title: "Print 2D-Labels",
      component: <ToReceive />,
      tooltip: "From this section you can receive items to shipment or storage and get relative labels (Storage, Product and Shipping Labels (2D))",
    },
  ];
  const Orders = [
    {
      title: "Unit Arrived in (Not Added To Shipment)",
      value: arrivedNotReceivedUnits || 0,
      key: "total",
    },
    {
      title: "PO Arrived in (Not Added To Shipment)",
      value: arrivedNotReceivedPos || 0,
      key: "shipped",
    },
    {
      title: "Daily Received in Bundles",
      value: dailyReceivedBundles || 0,
      key: "missing",
    },
    {
      title: "Daily Arrived in Units",
      value: dailyArrivedUnits || 0,
      key: "due-date",
    },
  ];

  return (
    <>
      <WarehouseWrapper>
        <Spin tip="Loading..." spinning={fetchingStats}>
          <div className="warehouse fbm-inbound">
            {Orders.map((tiles) => (
              <div className={`orders fbm-order ${tiles.key}`}>
                <span className="fs-14 color-secondary">{tiles.title}</span>
                <div className="d-flex justify-content-between align-items-center mt-1">
                  <h1 className="mb-0">{tiles.value}</h1>
                </div>
              </div>
            ))}
          </div>
        </Spin>
        <h2 className="color-secondary">Inbound</h2>
      </WarehouseWrapper>
      <TabsWrapper height="0">
        <div>
          {currentTab && (
            <Tabs activeKey={currentTab} className="order-tabs fbm-inbound"
              onSelect={(tab) => navigate(`/fba-inbound?tab=${tab}`)}
            >
              {order.map((tab, index) => {
                return (
                  <Tab
                    key={index}
                    eventKey={tab.key}
                    title={
                      <div className="d-flex tabs">
                        <span className="title fw-bold d-flex align-items-center">
                          <span className="index">{index + 1}</span>
                          {tab.title}
                          <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip">{tab.tooltip}</Tooltip>}>
                            <span class="icon-question"></span>
                          </OverlayTrigger>{" "}
                        </span>
                      </div>
                    }
                  >
                    {currentTab === tab.key && tab.component}
                  </Tab>
                );
              })}
            </Tabs>
          )}
        </div>
      </TabsWrapper>
    </>
  );
};
export default Index;
