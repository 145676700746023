import SelectStore from '../components/store/component/selectStore'
import SelectRegion from '../components/store/component/selectRegion';
import AddShopifyStore from '../components/store/component/addShopifyStore';
import SelectMarketPlace from '../components/store/component/addMarketPlace';
import AmazonSuccess from '../components/store/component/amazonSuccess';
import AutomatedPrep from '../components/store/component/automatedPrep';
import SelectPlans from '../components/plans/selectPlans';
import AddPayment from '../components/plans/addPayment.js';
import WalmartMarketPlace from '../components/store/component/walmartMarketPlace.jsx';
import WalmartSuccess from '../components/store/component/walmartSuccess.jsx';
import shopifySuccess from '../components/store/component/shopifySuccess.jsx';

export const ONBOARDING_ROUTES = [
  {
    path: '/onboarding/store-add',
    component: SelectStore,
    group: 'OnBoarding'
  },
  {
    path: '/onboarding/region-add',
    component: SelectRegion,
    group: 'OnBoarding'
  },
  {
    path: '/onboarding/add-shopify-store',
    component: AddShopifyStore,
    group: 'OnBoarding'
  },
  {
    path: '/auth/selling-partner',
    component: SelectMarketPlace,
    group: 'OnBoarding'
  },
  {
    path: '/onboarding/amazon-success',
    component: AmazonSuccess,
    group: 'OnBoarding'
  },
  {
    path: '/onboarding/amazon-prep-video',
    component: AutomatedPrep,
    group: 'OnBoarding'
  },
  {
    path: '/onboarding/select-plan',
    component: SelectPlans,
    group: 'OnBoarding'
  },
  {
    path: '/onboarding/add-payment',
    component: AddPayment,
    group: 'OnBoarding'
  },
  {
    path: '/onboarding/walmart-market-place',
    component: WalmartMarketPlace,
    group: 'OnBoarding'
  },
  {
    path: '/onboarding/walmart-success',
    component: WalmartSuccess,
    group: 'OnBoarding'
  },
  {
    path: '/onboarding/shopify-success',
    component: shopifySuccess,
    group: 'OnBoarding'
  },
];
