import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleRejectedResponse } from './utils';

import axios from '../../axios_config';

export const GetUserList = createAsyncThunk(
  '/dashboard/get-user-list',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        '/api/v2/dashboard/users_dropdown_lists_for_dashbaord',
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Users', rejectWithValue);
    }
  }
);

export const GetWarehouseList = createAsyncThunk(
  '/dashboard/get-warehouse-list',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        '/api/v2/dashboard/warehouses_dropdown_lists_for_dashbaord',
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Warehouses', rejectWithValue);
    }
  }
);

export const GetSummaryData = createAsyncThunk(
  '/dashboard/get-summary-data',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/api/v2/dashboard/summary_for_dashboard',
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Summary data', rejectWithValue);
    }
  }
);

export const GetShipmentData = createAsyncThunk(
  '/dashboard/get-shipment-data',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/api/v2/dashboard/panels_and_graphs_of_dashboard',
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Shipment data', rejectWithValue);
    }
  }
);

const auth = createSlice({
  name: "dashboard",
  initialState: {
    userList: [],
    warehouseList: [],
    summaryData: '',
    shipmentData: '',
    userLoading: false,
    warehouseLoading: false,
    summaryLoading: false,
    shipmentLoading: false
  },
  reducers: {
    setField(state, { payload: { module, field, value } }) {
      state[module][field] = value;
    },
    setItem(state, { payload: { field, value } }) {
      state[field] = value;
    }
  },
  extraReducers: {
    [GetUserList.pending]: (state, action) => ({
      ...state,
      userLoading: true
    }),
    [GetUserList.fulfilled]: (state, action) => {
      const { users } = action?.payload?.data || {};
      return {
        ...state,
        userList: users,
        userLoading: false
      }
    },
    [GetUserList.rejected]: (state, action) => {
      return {
        ...state,
        userLoading: false
      }
    },
    [GetWarehouseList.pending]: (state, action) => ({
      ...state,
      warehouseLoading: true
    }),
    [GetWarehouseList.fulfilled]: (state, action) => {
      const { warehouses } = action?.payload?.data || {}; 
      return {
        ...state,
        warehouseList: warehouses,
        warehouseLoading: false
      }
    },
    [GetWarehouseList.rejected]: (state, action) => {
      return {
        ...state,
        warehouseLoading: false
      }
    },
    [GetSummaryData.pending]: (state, action) => ({
      ...state,
      summaryLoading: true
    }),
    [GetSummaryData.fulfilled]: (state, action) => {
      const { summary } = action?.payload?.data?.dashboard_summary || {};
      return {
        ...state,
        summaryData: summary,
        summaryLoading: false
      }
    },
    [GetSummaryData.rejected]: (state, action) => {
      return {
        ...state,
        summaryLoading: false
      }
    },
    [GetShipmentData.pending]: (state, action) => ({
      ...state,
      shipmentLoading: true
    }),
    [GetShipmentData.fulfilled]: (state, action) => {
      const { complete_data_of_dashboard } = action?.payload?.data || {};
      return {
        ...state,
        shipmentData: complete_data_of_dashboard,
        shipmentLoading: false
      }
    },
    [GetShipmentData.rejected]: (state, action) => {
      return {
        ...state,
        shipmentLoading: false
      }
    },
  }
});

const { reducer, actions } = auth;
export const { setField, setItem } = actions;
export default reducer;
  