import { notification } from "antd";
import { isArray } from "lodash";
import axios from '../../axios_config';

export const handleRejectedResponse = (err, errorType, rejectWithValue) => {
  const { response } = err || {};
  const { data } = response || {};
  const { error, errors, card, message: cardMessage } = data || {};
  const errMessage = isArray(error)? error[0]: error;
  const { message } = errors?.[0] || {};
  const { errors: cardError } = card || {}
  const { message: cardErr } = cardError?.[0] || {}

  const errorMessage = cardErr || message || cardMessage || errors || errMessage || err.message  || 'Unknown Error';

  !(axios.isCancel(err)) && notification.error({
    message: errorType,
    description: errorMessage,
    placement: "topRight",
    top: 65,
  });

  return rejectWithValue({ statusCode: err.response.status, message: (errorMessage || err.message) });
}
