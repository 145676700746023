import Styled from "styled-components";

const HeaderWrapper = Styled.div`
.navbar-toggle {
  position: relative;
  float: right;
  margin-right: 15px;
  padding: 9px 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.navbar-toggle:focus {
  outline: 0;
}
.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}
.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px;
}
.white-sticky {
    .navbar-default.scrolled {
      .navbar-brand {
        .logo {
          width: 155px;
          background-size: 155px;
        }
      }
    }
  }
@media (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }
}
.white-sticky {
  .header-container {
    max-width: 1065px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items:center;
    .logo{
        width: 175px; 
    }
  }
  .navbar-brand {
    padding: 0px;
    height: auto;
    font-weight: normal;
    display: grid;
    grid-auto-flow: column;
    height: 70px;
    align-items: center;
    .logo {
      width: 175px;
      float: left;
      height: 35px;
    }
  }
  .navbar-default {
    border: 0px;
    background: #FFFFFF;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 10px 20px rgba(0, 0, 0, 0.04);
    position: fixed;
    width:100%;
    z-index:999;
    .navbar-collapse {
      padding: 0px;
      display: block;
    }
    ul.navbar-nav {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 25px;
      justify-content: center;
      align-items: center;
      height: 70px;
      @media (max-width:991px){
        grid-gap: 13px;
        font-size: 12px;
      }
      li {
        a {
          /* color: #444D50; */
          font-weight: 600;
          padding: 0;
          &:hover {
            color: #000;
          }
        }
        &.signin-btn {
          .outline-btn {
            @include outline-btn;
            min-width: 137px;
            justify-content: center;
            background: transparent;
            @media (max-width:991px) {
              min-width: 88px;
            }
          }
        }
      }
    }
  }
  .navbar.navbar-fixed-top {
    transition: all .5s .1s;
  }
}
.dashboard-header{
    &.white-header{
        .navbar-default{
            background: #fff;
            box-shadow: 0px 0px 1px rgb(0 0 0 / 4%), 0px 2px 6px rgb(0 0 0 / 4%), 0px 10px 20px rgb(0 0 0 / 4%);
            .navbar-header{
            .logo{
              background-image:url("https://sale-support-logos.s3.us-west-1.amazonaws.com/blue_logo.svg");
              background-repeat: no-repeat;
              height: 34px;
              width: 175px;
            }
          }
            ul{
                li{
                    a{
                       color:#444D50
                    }
                }
            }
        }
    }
    .navbar-default{
        background: transparent;
        box-shadow: none;
        .navbar-header{
            .logo{
              background-image:url("https://sale-support-logos.s3.us-west-1.amazonaws.com/white_logo.svg");
              background-repeat: no-repeat;
              height: 34px;
              width: 175px;
            }
          }
        ul{
            li{
                a{
                    color: #fff;
                    text-decoration:none;
                }
            }
        }
        &.headerWhite{
        background: #fff;
        box-shadow: 0px 0px 1px rgb(0 0 0 / 4%), 0px 2px 6px rgb(0 0 0 / 4%), 0px 10px 20px rgb(0 0 0 / 4%);
          .navbar-header{
            .logo{
              background-image:url("https://sale-support-logos.s3.us-west-1.amazonaws.com/blue_logo.svg");
              background-repeat: no-repeat;
              height: 34px;
              width: 175px;
            }
          }
            ul{
                li{
                    a{
                        color: #000;
                        text-decoration:none;
                    }
                }
            }
        }
    }
}

      .navbar-brand {
        .logo {
          display: block;
        }
      }
      }
        .navbar-brand {
          .logo {
            display: block;
          }
        }
      }
    }
}
.outline-button{
    border: 1px solid #4E70E8;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  background: transparent;
  color: #4E70E8;
  padding: 9px 24px;
  text-align: center;
  -webkit-transition: background-color .3s ease-out;
  -moz-transition: background-color .3s ease-out;
  -o-transition: background-color .3s ease-out;
  transition: background-color .3s ease-out;
  &:hover {
    background: #4E70E8;
    color: white;
  }
}
.blue-image{
  background-image:url("https://sale-support-logos.s3.us-west-1.amazonaws.com/blue_logo.svg");
  background-repeat: no-repeat;
  height: 34px;
  width: 175px;
}
`
export { HeaderWrapper }