import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Logo from "../../assets/images/newImages/new_log.png";
import { AiOutlineMenu } from "react-icons/ai";
import { setField } from "../../slices/internalStorage";
import { isEmpty } from 'lodash';
import { MobileWrapper, Header, Content, Title, CardDetail, ProductDetail } from './mobile.styled';

const ChooseOldLocation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { scannedProductToTransfer } = useSelector(state => state?.internalStorage || {});

    const { identifier, image_url, quantity, stored_quantity, title, locations } = scannedProductToTransfer || {};

    const navigateToChooseNewLocation = (item) => {
      dispatch(setField({ field: 'selectedOldLocation', value: item }))
      dispatch(setField({ field: 'selectedNewLocation', value: {} }))
      navigate('/scan-new-location');
    }

    useEffect(() => {
      if (isEmpty(scannedProductToTransfer)) navigate('/scan-transfer-products');
    }, [])

    return (
        <MobileWrapper className='fullContent'>
            <div className='mobile-page'>
                <div className='container-fluid px-0'>
                    <Content className='fullContent'>
                        <Title>
                            <h2>
                                <span
                                    className="icon-arrow-left cursor-pointer" onClick={() => navigate('/scan-transfer-products')}></span>
                                Choose Old Location</h2>
                        </Title>
                        <div className='content-inner'>
                          {
                            !isEmpty(scannedProductToTransfer) &&
                            <>
                            <ProductDetail className='product-old-location'>
                              <div class="d-flex align-items-center">
                                  <div>
                                      <img width="42" height="42" src={image_url} alt="no-product" />
                                  </div>
                                  <div>
                                      <span class="fs-14 color-secondary">{title}</span>
                                  </div>
                              </div>
                              <ul class="d-flex">
                                  <li class="">
                                      <span class="fs-12 d-block">Identifier</span>
                                      <span class="color-primary fs-14 fw-bold">{identifier}</span>
                                  </li>
                                  <li class="">
                                      <span class="fs-12 recent-grey d-block">Quantity</span>
                                      <span class="fs-14">{stored_quantity}</span>
                                  </li>
                              </ul>
                          </ProductDetail>
                          { !isEmpty(locations) &&
                            locations.map((item, index) => {
                              const { name, uuid, location_type, items_count, units_count } = item;
                              return (
                                <CardDetail className='mb-card-single'>
                                    <div key={index} className='card-details-head d-flex justify-content-between align-items-center'>
                                        <h2>{name}</h2>
                                        <span
                                            className="icon-arrow-right cursor-pointer" 
                                            onClick={() => navigateToChooseNewLocation(item)}
                                        ></span>
                                    </div>
                                    <div key={index} className='card-details-content'>
                                        {/* <p>{name}</p> */}
                                        <ul key={index} className='d-flex'>
                                            {/* <li key='type' ><span className='icon-carton'></span>{location_type}</li> */}
                                            <li key='product' ><span className='icon-inventory'></span>Products<strong>{items_count}</strong></li>
                                            <li key='units' ><span className='icon-total'></span>Units <strong>{units_count}</strong></li>
                                        </ul>
                                    </div>
                                </CardDetail>
                              )
                            })
                          }
                          
                        </>
                          }
                        </div>
                    </Content>
                   
                </div>
            </div>
        </MobileWrapper>
    )
}

export default ChooseOldLocation;