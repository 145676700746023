import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { notification, Spin } from "antd";
import { isEmpty } from 'lodash';
import { storeToken } from './utils';
import Header from '../../header/header'
import { AuthWrapper } from '../style'
import { Row, Col,  } from "react-bootstrap"
import InfoVideo from './infoVideo'
import {Link} from "react-router-dom"
import EmailVerifactionImage from '../../../assets/images/newImages/emailVerifaction.png'
import { ConfirmTokenVerification, ResendEmailVerification, GetCurrentUser } from '../../../slices/auth';
import clearRequestArray from '../../../utils/interseptors/pendingRequest';
import axios from '../../../../axios_config';
let controller = null;

const EmailVerifaction = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, resendEmailLoading, confirmEmailLoading } = useSelector(state => state?.auth || {})
  const { email } = user || {};
  
  const confirmToken = async() => {
    const query = new URLSearchParams(window.location.search);
    const confirmationToken = query.get('confirmation_token');
    if (confirmationToken) {
      const body = {
        confirmationToken
      }
      const response = await dispatch(ConfirmTokenVerification(body));
      if(!response.error) {
        const { jwt } = response?.payload?.data || {};
        if(jwt) {
          storeToken(jwt, navigate, dispatch);
          // await dispatch(GetCurrentUser());
        }
      }
    }
  }

  useEffect(() => {
    controller = new AbortController();
    axios.defaults.signal = controller?.signal;
    confirmToken();

    return () => {
      clearRequestArray();
      controller.abort();
      controller = null;
      axios.defaults.signal = null;
    };
  }, [])

  const handleResendEmail = async() => {
    if(isEmpty(email)) {
      notification.error({
        message: "Verification Email",
        description: 'Email not found',
        top: 65,
      });
      return;
    }
    const user = { email }
    await dispatch(ResendEmailVerification({user}));
  }

  return (
    <AuthWrapper>
      <Header login={false} />
      <div className="conatiner">
        <Spin tip='Confirming Email...' spinning={confirmEmailLoading? true: false} size='large'>
        <Row className="m-0">
          <Col lg={8} sm={12}>
            <InfoVideo />
          </Col>
          <Col lg={4} sm={12}>
            <div className="bg-albaster py-40">
              <div>
                <div className="mb-4">
                  <h2>Verification Email Sent</h2>
                </div>
                <div className="pt-120 pb-90 text-center">
                  <img src={EmailVerifactionImage} alt="no-image-email" />
                </div>
                <div className="text-center">
                  <span disabled={true}>If you didn’t receive the email please 
                    { resendEmailLoading? <a > Sending Email...</a>
                    : <a href='#' onClick={handleResendEmail}> Click Here</a>}
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        </Spin>
      </div>
    </AuthWrapper>
  )
}

export default EmailVerifaction
