import React, { useState, useEffect } from "react";
import { MapContainer, Marker, Tooltip, GeoJSON } from 'react-leaflet';
import { Button, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import L from 'leaflet'

import HouseIcon from "../../../assets/images/house-icon.svg";
import Start from "../../../assets/images/Star-1.svg";

import RadioCard from "../../../components/radioCard";
import "../../../components/radioCard/style.css";

import { GetCompanyAddress } from "../../../slices/fba-shipment";

import { ChooseShippingWarehouseWrapper } from "../style";

import USA_MAP_JSON from './usaMap';

import pin_blue from './images/pin_blue.svg'
import pin_green from './images/pin_green.svg'
import pin_red from './images/pin_red.svg'

const MapComponent = ({
  setDraftPo,
  draft_po,
  company_id,
  setWarehouseDescription,
  setAccordianKey
}) => {
  const { loading, companyAddresses } = useSelector((store) => store.fbaShipment);

  const dispatch = useDispatch();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [warehouseData, setWarehouseData] = useState([]);
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (!map) return;

    const fetchGeoJSON = async () => {
      const osm = L.TileLayer.boundaryCanvas(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          boundary: USA_MAP_JSON,
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors, USA shape <a href="https://example.com/usa-geo-source">Your GeoJSON source</a>'
        }
      );
      map.addLayer(osm);
      const usaLayer = L.geoJSON(USA_MAP_JSON);
      map.fitBounds(usaLayer.getBounds());
    };

    fetchGeoJSON();
  }, [map]);

  const handleMarkerClick = (warehouse) => {
    setSelectedMarker(warehouse);
    setShowInfo(true); // Show the info div when a marker is clicked
  };

  useEffect(() => {
    dispatch(GetCompanyAddress(company_id));
  }, []);

  const handleSelectWarehouse = () => {
    const ownerAddress = companyAddresses?.filter(({ owner_address }) => owner_address);
    const filterAddress = ownerAddress?.filter(({ longitude, latitude }) => longitude && latitude && Number(longitude) < 0);
    const address = filterAddress?.map(({
      id,
      title,
      longitude,
      latitude,
      owner_address,
      address_line_1,
      city
    }) => {
      return {
        id,
        name: title,
        coordinates: [Number(latitude), Number(longitude)],
        isGoodForFBA: owner_address,
        content: address_line_1,
        city
      }
    });

    setWarehouseData(address);

    if (draft_po?.ship_to_address_id) {
      const selectedAddress = address?.find(a => a.id === draft_po?.ship_to_address_id) || {};
      setSelectedMarker(selectedAddress);
      setShowInfo(true);
      setWarehouseDescription(selectedAddress?.name);
    } else {
      const defaultAddress = companyAddresses?.find(a => a.default_address);
      if (defaultAddress && !draft_po?.ship_to_address_id) {
        setDraftPo({
          ...draft_po,
          ship_to_address_id: defaultAddress?.id
        });
        setWarehouseDescription(defaultAddress?.title);
        setSelectedMarker(address?.find(a => a.id === draft_po?.ship_to_address_id) || {});
        setShowInfo(true);
      }
    }
  }

  useEffect(() => {
    if (!draft_po?.ship_to_address_id) {
      setShowInfo(false);
      setSelectedMarker(null);
    }
    else if (draft_po?.ship_to_address_id) {
      handleSelectWarehouse();
    }
  }, [draft_po]);

  useEffect(() => {
    if (companyAddresses?.length) handleSelectWarehouse();
  }, [companyAddresses]);

  const maxBounds = [
    [50.82, -130.01],
    [20.38, -30.94]
  ];
  const position = [37.0902, -82.712891];

  return (
    <ChooseShippingWarehouseWrapper>
      <div className="parent-wrapper">
        <div className="map-container">
          <Spin tip='Loading...' spinning={loading}>
            <MapContainer
              center={position}
              scrollWheelZoom={true}
              style={{ height: '280px', backgroundColor: 'white' }}
              attributionControl={false}
              minZoom={3.5}
              maxZoom={13}
              maxBounds={maxBounds}
              whenCreated={setMap}
              maxBoundsViscosity={0.5}
              zoomControl={false}
              zoom={3.5}
              zoomSnap={0.8}
            >
              <GeoJSON
                data={USA_MAP_JSON}
                style={() => ({
                  fillColor: "none",
                  weight: 0.5,
                  opacity: 1,
                  color: '#1565d8',
                  fillOpacity: 0,
                })}
              />

              {warehouseData.map((warehouse, index) => {
                let iconUrl;
                let iconSize = [30, 30];
                if (selectedMarker === warehouse) {
                  iconUrl = pin_blue;
                  iconSize = [40, 40];
                } else if (warehouse.isGoodForFBA) {
                  iconUrl = pin_green;
                } else {
                  iconUrl = pin_red;
                }

                const icon = L.icon({
                  iconUrl: iconUrl,
                  iconSize: iconSize,
                  iconAnchor: [20, 40],
                  popupAnchor: [0, -40]
                });
                return (
                  <Marker
                    key={index}
                    position={warehouse.coordinates}
                    icon={icon}
                    eventHandlers={{
                      click: () => handleMarkerClick(warehouse),
                    }}
                  >
                    <Tooltip>
                      <span>{warehouse.name}</span>
                    </Tooltip>
                  </Marker>
                );
              })}
            </MapContainer>
          </Spin>
          <div className="legend-main">
            <div className="legend">
              <span style={{ backgroundColor: "#009C34" }}></span>- Good for All
            </div>
            <div className="legend">
              <span style={{ backgroundColor: "#CF0909" }}></span>- Not good for FBA
            </div>
          </div>
        </div>
        <div>
          {showInfo && (
            <RadioCard checked={draft_po?.ship_to_address_id === selectedMarker?.id}>
              <div className="card-inner-wrapper">
                <img src={HouseIcon} />
                <div className="rating-ui-bag">
                  <span>5.0</span>
                  <img src={Start} />
                </div>
                <h4>{selectedMarker.name}</h4>
                <div className="services-tags">
                  <span>{selectedMarker?.name}</span>
                  <span>{selectedMarker?.city} </span>
                  <span>{selectedMarker?.address_line_1}</span>
                  <span>{selectedMarker.content}</span>
                </div>
                <Button
                  onClick={() => {
                    setDraftPo({
                      ...draft_po,
                      ship_to_address_id: selectedMarker?.id
                    });
                    setWarehouseDescription(selectedMarker?.name);
                    setAccordianKey("3");
                  }}>Set Destination Here</Button>
              </div>
            </RadioCard>
          )}
        </div>
      </div>
    </ChooseShippingWarehouseWrapper>
  );
};

export default MapComponent;
