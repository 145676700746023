import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleRejectedResponse } from '../utils';
import axiosRails, { fetch as axios } from '../../../axios_config';
import { notification } from 'antd';

export const GetPendingPoIndices = createAsyncThunk(
  '/get-checked-in-po-indices',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/get-checked-in-po-indices`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          params: data
        }
      );
      return {
        ...response,
        data: { ...response.data, page: {} }
      };
    } catch (err) {
      return handleRejectedResponse(err, 'Get Check-In Pending-Po-Indices', rejectWithValue);
    }
  }
);

export const GetUsersList = createAsyncThunk(
  '/get-to-check-in-users',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/get-users`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Users List', rejectWithValue);
    }
  }
);

export const GetStorageUsersList = createAsyncThunk(
  '/get-to-storage-check-in-users',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axiosRails.get(
        `/api/v2/storages/users`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Users List', rejectWithValue);
    }
  }
);

export const GetInventoryUsersList = createAsyncThunk(
  '/get-inventory-users-list',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axiosRails.get(
        `/api/v2/staging_asins/users_dropdown `,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Users List', rejectWithValue);
    }
  }
);

export const AddManualProductsToStorage = createAsyncThunk(
  '/add-Manual-Products-To-Storage',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axiosRails.post(
        `/api/v2/staging_items/manual_create`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Add to storage', rejectWithValue);
    }
  }
);

export const GetVendors = createAsyncThunk(
  '/get-vendors',
  async (user_id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axiosRails.get(
        `/api/v2/users/${user_id}/vendors`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Users List', rejectWithValue);
    }
  }
);

export const GetVendorsList = createAsyncThunk(
  '/get-vendors-list',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/get-vendors`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Vendors List', rejectWithValue);
    }
  }
);

export const GetTrackingNumbers = createAsyncThunk(
  '/list-all-pending-po-arriveds/:po_index_id',
  async (po_index_id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/list-all-pending-po-arriveds/${po_index_id}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Tracking Numbers', rejectWithValue);
    }
  }
);

export const AddTrackingNumbers = createAsyncThunk(
  '/add-tracking-number',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/add-tracking-number`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'PO Check-In', rejectWithValue);
    }
  }
);

export const GetPendingPos = createAsyncThunk(
  '/get-index-pending-pos',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/get-index-pending-pos`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          params: data
        },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Pending POs', rejectWithValue);
    }
  }
);

export const SaveCheckInQuantity = createAsyncThunk(
  '/save-check-in-quantity',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/save-check-in-quantity`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Save Check-In Quantity', rejectWithValue);
    }
  }
);

export const UpdatePoCompleteStatus = createAsyncThunk(
  '/update-po-complete-status',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/update-po-complete-status`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Po Complete Status', rejectWithValue);
    }
  }
);

export const AddCheckedInQuantities = createAsyncThunk(
  '/add-checked-in-quantities',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/add-checked-in-quantities`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Add Checked-In Quantity', rejectWithValue);
    }
  }
);

export const MarkAsNotReceived = createAsyncThunk(
  '/mark-not-received',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/not-received`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Mark As Not Received', rejectWithValue);
    }
  }
);

export const SyncCheckInQuantity = createAsyncThunk(
  '/sync-check-in-quantity',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/sync-check-in-quantity`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );

      return {
        ...response,
        data: { ...response.data, syncWith: data.syncWith }
      };
    } catch (err) {
      return handleRejectedResponse(err, 'Sync Check-In Quantity', rejectWithValue);
    }
  }
);

export const QuickCheckIn = createAsyncThunk(
  'quick-checkin',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.FBA_INBOUND_API_URL}/quick-checkin`,
        data,
        { headers: { Authorization: `Bearer ${authToken}` } },
      );

      return {
        ...response,
        data: { ...response.data, syncWith: data.syncWith }
      };
    } catch (err) {
      return handleRejectedResponse(err, 'Quick Check In', rejectWithValue);
    }
  }
);

export const PoReceiveHistory = createAsyncThunk(
  'po-receive-history',
  async (poId, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/po-received-history/${poId}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return {
        ...response,
        data: { ...response.data }
      };
    } catch (err) {
      return handleRejectedResponse(err, 'Quick Check In', rejectWithValue);
    }
  }
);

export const DeleteReceivedHistory = createAsyncThunk(
  'delete-receive-history',
  async (receivingId, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.delete(
        `${process.env.FBA_INBOUND_API_URL}/delete-received-history/${receivingId}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      return {
        ...response,
        data: { ...response.data }
      };
    } catch (err) {
      return handleRejectedResponse(err, 'Quick Check In', rejectWithValue);
    }
  }
);

export const GetTrackingIds = createAsyncThunk(
  '/get-tracking-ids',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axiosRails.get(
        '/api/v2/received_trackings',
        {
          params: data
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Tracking Id', rejectWithValue);
    }
  }
);

export const GetScannedLabels = createAsyncThunk(
  '/get-scanned-labels',
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await axiosRails.get(
        '/api/v2/scanned_boxes',
        {
          params
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Scanned Labels', rejectWithValue);
    }
  }
);

export const SaveScannedLabel = createAsyncThunk(
  '/save-scanned-label',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axiosRails.post(
        '/api/v2/scanned_boxes',
        data
      );
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Save Scanned Label', rejectWithValue);
    }
  }
);

export const DeleteLabel = createAsyncThunk(
  '/delete-label',
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await axiosRails.delete(`/api/v2/scanned_boxes/${id}`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Delete Scanned Label', rejectWithValue);
    }
  }
);

export const GetTopStats = createAsyncThunk(
  '/top-stats',
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/po-stats`,
        {
          headers: { Authorization: `Bearer ${authToken}` }
        }
      );
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Top Stats', rejectWithValue);
    }
  }
);

export const AddTrackingId = createAsyncThunk(
  '/create-receive-tracking',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axiosRails.post(
        '/api/v2/received_trackings',
        data
      );
      return response?.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Add Tracking', rejectWithValue);
    }
  }
);

export const UpdateTrackingId = createAsyncThunk(
  '/update-receive-tracking',
  async (body, { getState, rejectWithValue }) => {

    try {
      const { id, images } = body;
      const response = await axiosRails.patch(
        `/api/v2/received_trackings/${id}`,
        { received_trackings: { images } }
      );
      return response?.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Tracking', rejectWithValue);
    }
  }
);

export const DeleteTrackingImage = createAsyncThunk(
  '/delete-receive-tracking-image',
  async (file_links, { getState, rejectWithValue }) => {
    try {
      const response = await axiosRails.delete(
        '/api/v2/received_trackings/delete_image',
        { data: { file_links } }
      );
      return response?.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Delete Tracking Image', rejectWithValue);
    }
  }
);


export const AddToStorageBulk = createAsyncThunk(
  '/add-to-storage-bulk',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axiosRails.post(
        '/api/v2/staging_items/bulk_create',
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Create Shipment', rejectWithValue);
    }
  }
);

export const checkInInitialState = {
  pendingPoIndices: [],
  fetchingPendingPoIndices: false,
  totalCount: 0,
  usersList: [],
  fetchingUsersList: false,
  vendorsAddressList: [],
  fetchingVendorsList: false,
  pendingPoArriveds: [],
  fetchingTrackingNumbers: false,
  addingTrackingNumbers: false,
  fetchingPendingPos: false,
  addingCheckedInQuantities: false,
  savingCheckInQuantity: false,
  updatingPoCompleteStatus: false,
  syncingCheckInQuantity: false,
  markNotReceivedLoading: false,
  completedCount: 0,
  checkInLoading: false,
  getVendorsLoading: false,
  pendingPosIndices: [],
  quickCheckInLoading: false,
  markNotReceived: false,
  trackingIds: [],
  trackingCount: 0,
  unmatchedCount: 0,
  getTrackingIdsLoading: false,
  scannedLabels: [],
  scannedLabelsLoading: false,
  totalScanned: 0,
  fbaWarehouseTopStats: {},
  topStatsLoading: false,
  bulkAddToStorageLoading: false,
  poReceiveHistory: [],
  fetchPoReceiveHistory: false,
  deleteReceivingLoading: false
};

export const toCheckInReducers = {
  [GetPendingPoIndices.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      fetchingPendingPoIndices: true,
    }
  }),
  [GetPendingPoIndices.fulfilled]: (state, action) => {
    const { result, count, page, completedCount } = action?.payload?.data || {};
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        pendingPoIndices: result,
        fetchingPendingPoIndices: false,
        totalCount: count,
        completedCount
      }
    }
  },
  [GetPendingPoIndices.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        fetchingPendingPoIndices: false,
      }
    }
  },
  [GetVendors.pending]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        getVendorsLoading: true,
      }
    }
  },
  [GetVendors.fulfilled]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        getVendorsLoading: false,
      }
    }
  },
  [GetVendors.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        getVendorsLoading: false,
      }
    }
  },
  [AddManualProductsToStorage.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      checkInLoading: true,
    }
  }),
  [AddManualProductsToStorage.fulfilled]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        checkInLoading: false
      }
    }
  },
  [AddManualProductsToStorage.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        checkInLoading: false,
      }
    }
  },
  [GetUsersList.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      usersList: [],
      fetchingUsersList: true
    }
  }),
  [GetUsersList.fulfilled]: (state, action) => {
    const { result } = action?.payload?.data || {};
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        usersList: result,
        fetchingUsersList: false,
      }
    }
  },
  [GetUsersList.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        usersList: [],
        fetchingUsersList: false
      }
    }
  },
  [GetVendorsList.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      vendorsAddressList: [],
      fetchingVendorsList: true
    }
  }),
  [GetVendorsList.fulfilled]: (state, action) => {
    const { result } = action?.payload?.data || {};
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        vendorsAddressList: result,
        fetchingVendorsList: false,
      }
    }
  },
  [GetVendorsList.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        vendorsAddressList: [],
        fetchingVendorsList: false
      }
    }
  },
  [GetTrackingNumbers.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      pendingPoArriveds: [],
      fetchingTrackingNumbers: true,
    }
  }),
  [GetTrackingNumbers.fulfilled]: (state, action) => {
    const { result } = action?.payload?.data || {};
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        pendingPoArriveds: result?.pending_po_index_arriveds || [],
        fetchingTrackingNumbers: false,
      }
    }
  },
  [GetTrackingNumbers.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        fetchingTrackingNumbers: false,
        pendingPoArriveds: [],
      }
    }
  },
  [GetTopStats.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      topStatsLoading: true
    }
  }),
  [GetTopStats.fulfilled]: (state, action) => {
    const { totalInboundPos, totalRemainingInboundPos, closedShipments, dailyReceivedUnits } = action?.payload || {};
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        fbaWarehouseTopStats: {
          totalInboundPos, totalRemainingInboundPos, closedShipments, dailyReceivedUnits
        },
        topStatsLoading: false,
      }
    }
  },
  [GetTopStats.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        topStatsLoading: false
      }
    }
  },
  [AddTrackingNumbers.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      addingTrackingNumbers: true,
    }
  }),
  [AddTrackingNumbers.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: `PO Check-In`,
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        addingTrackingNumbers: false,
      }
    }
  },
  [AddTrackingNumbers.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        addingTrackingNumbers: false,
      }
    }
  },
  [AddTrackingId.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      addingTrackingNumbers: true,
    }
  }),
  [AddTrackingId.fulfilled]: (state, action) => {
    notification.success({
      message: "Add Tracking Id",
      description: 'Tracking Ids Added Successfully.',
      top: 65
    });
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        addingTrackingNumbers: false,
      }
    }
  },
  [AddTrackingId.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        addingTrackingNumbers: false,
      }
    }
  },
  [AddToStorageBulk.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      bulkAddToStorageLoading: true,
    }
  }),
  [AddToStorageBulk.fulfilled]: (state, action) => {
    notification.success({
      message: "Add to storage",
      description: 'Successfully Added Successfully.',
      top: 65
    });
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        bulkAddToStorageLoading: false,
      }
    }
  },
  [AddToStorageBulk.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        bulkAddToStorageLoading: false,
      }
    }
  },
  [GetTrackingIds.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      getTrackingIdsLoading: true,
    }
  }),
  [GetTrackingIds.fulfilled]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        getTrackingIdsLoading: false,
        trackingIds: action.payload?.data.received_trackings,
        trackingCount: action.payload?.data.count,
        unmatchedCount: action.payload?.data.unmatched_po_count
      }
    }
  },
  [GetTrackingIds.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        getTrackingIdsLoading: false,
      }
    }
  },
  [SaveScannedLabel.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      scannedLabelsLoading: true,
    }
  }),
  [SaveScannedLabel.fulfilled]: (state, action) => {
    notification.success({
      message: "Save Scanned Label",
      description: 'Label Scanned Successfully',
      top: 65
    });
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        scannedLabelsLoading: false,
      }
    }
  },
  [SaveScannedLabel.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        scannedLabelsLoading: false,
      }
    }
  },
  [DeleteLabel.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      scannedLabelsLoading: true,
    }
  }),
  [DeleteLabel.fulfilled]: (state, action) => {
    notification.success({
      message: "Delete Label",
      description: 'Label Deleted Successfully',
      top: 65
    });
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        scannedLabelsLoading: false,
      }
    }
  },
  [DeleteLabel.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        scannedLabelsLoading: false,
      }
    }
  },
  [GetScannedLabels.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      scannedLabelsLoading: true,
    }
  }),
  [GetScannedLabels.fulfilled]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        scannedLabelsLoading: false,
        scannedLabels: action.payload?.data?.scanned_boxes,
        totalScanned: action.payload?.data?.count
      }
    }
  },
  [GetScannedLabels.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        scannedLabelsLoading: false,
      }
    }
  },
  [GetPendingPos.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      fetchingPendingPos: true,
    }
  }),
  [GetPendingPos.fulfilled]: (state, action) => {
    const { result } = action?.payload?.data || {};
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        fetchingPendingPos: false,
        pendingPosIndices: result
      }
    }
  },
  [GetPendingPos.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        fetchingPendingPos: false,
      }
    }
  },
  [PoReceiveHistory.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      fetchPoReceiveHistory: true,
    }
  }),
  [PoReceiveHistory.fulfilled]: (state, action) => {
    const { receivedHistories } = action?.payload?.data || {};
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        fetchPoReceiveHistory: false,
        poReceiveHistory: receivedHistories
      }
    }
  },
  [PoReceiveHistory.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        fetchPoReceiveHistory: false,
      }
    }
  },
  [DeleteReceivedHistory.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      deleteReceivingLoading: true,
    }
  }),
  [DeleteReceivedHistory.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Delete Receive History',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        deleteReceivingLoading: false
      }
    }
  },
  [DeleteReceivedHistory.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        deleteReceivingLoading: false,
      }
    }
  },
  [AddCheckedInQuantities.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      addingCheckedInQuantities: true,
    }
  }),
  [AddCheckedInQuantities.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'PO Check-In',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        addingCheckedInQuantities: false,
      }
    }
  },
  [AddCheckedInQuantities.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        addingCheckedInQuantities: false,
      }
    }
  },
  [SaveCheckInQuantity.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      savingCheckInQuantity: true,
    }
  }),
  [SaveCheckInQuantity.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Save PO Check-In',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        savingCheckInQuantity: false,
      }
    }
  },
  [SaveCheckInQuantity.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        savingCheckInQuantity: false,
      }
    }
  },
  [UpdatePoCompleteStatus.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      updatingPoCompleteStatus: true,
    }
  }),
  [UpdatePoCompleteStatus.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: 'Update Po Complete Status',
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        updatingPoCompleteStatus: false,
      }
    }
  },
  [UpdatePoCompleteStatus.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        updatingPoCompleteStatus: false,
      }
    }
  },
  [SyncCheckInQuantity.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      syncingCheckInQuantity: true,
    }
  }),
  [SyncCheckInQuantity.fulfilled]: (state, action) => {
    const { message, success, syncWith } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: `Check-In With ${syncWith} Quantity`,
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        syncingCheckInQuantity: false,
      }
    }
  },
  [SyncCheckInQuantity.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        syncingCheckInQuantity: false,
      }
    }
  },
  [MarkAsNotReceived.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      markNotReceived: true,
    }
  }),
  [MarkAsNotReceived.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: `Mark As Not Received`,
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        markNotReceived: false,
      }
    }
  },
  [MarkAsNotReceived.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        markNotReceived: false,
      }
    }
  },
  [QuickCheckIn.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      quickCheckInLoading: true,
    }
  }),
  [QuickCheckIn.fulfilled]: (state, action) => {
    const { message, success } = action?.payload?.data || {};
    const notificationObj = {
      top: 65,
      message: `Quick Check In`,
      description: message
    };
    success ? notification.success(notificationObj)
      : notification.error(notificationObj);

    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        quickCheckInLoading: false,
      }
    }
  },
  [QuickCheckIn.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        quickCheckInLoading: false,
      }
    }
  },
  [UpdateTrackingId.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      addingTrackingNumbers: true,
    }
  }),
  [UpdateTrackingId.fulfilled]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        addingTrackingNumbers: false,
      }
    }
  },
  [UpdateTrackingId.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        addingTrackingNumbers: false,
      }
    }
  },
  [DeleteTrackingImage.pending]: (state, action) => ({
    ...state,
    toCheckIn: {
      ...state.toCheckIn,
      addingTrackingNumbers: true,
    }
  }),
  [DeleteTrackingImage.fulfilled]: (state, action) => {
    notification.success({
      message: "Delete Tracking Image",
      description: 'Tracking Image Deleted Successfully.',
      top: 65
    });
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        addingTrackingNumbers: false,
      }
    }
  },
  [DeleteTrackingImage.rejected]: (state, action) => {
    return {
      ...state,
      toCheckIn: {
        ...state.toCheckIn,
        addingTrackingNumbers: false,
      }
    }
  }
};
