import React, { useState, useEffect, useRef } from "react";
import { isEmpty, ceil, debounce } from "lodash";
import moment from 'moment'
import { Spin, Tooltip, Avatar, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { WarehouseWrapper } from "./style";
import AgGrid from "../../components/ag-grid-table/index";
import { BsShopWindow } from "react-icons/bs";
import Pagination from "../../components/pagination/index";
import SearchInput from "../../components/uiComponent/inputs/searchinput/index";
import Button from "../../components/uiComponent/button/index";
import Input from "../../components/uiComponent/inputs/labelInput/index"
import Drawer from "../../components/uiComponent/drawer/index";
import {
  ListLocationItems,
  SubmitAddToWorkOrder,
  LookupExistingListing,
  RelabelStorageItem
} from "../../slices/internalStorage";
import { generateStorageBarcode } from '../../utils/locationLabels';
const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [drawer, setDrawer] = useState({
    relabel: false,
    workorder: false
  });
  const [selectedItem, setSelectedItem] = useState({})
  const [isValidAsin, setValidAsin] = useState(false);
  const [relistData, setRelistData] = useState({
    newAsin: null,
    newSku: null
  });
  const [workOrderData, setWorkOrderData] = useState({
    quantity: null,
    date: null
  })

  const params = new URLSearchParams(window.location.search);
  const selectedLocationId = params.get('id');

  const [filters, setFilters] = useState({
    searchValue: null,
    locationId: null,
    pagination: {
      pageNumber: 1,
      pageLimit: 25
    }
  });

  const { pagination, locationId, searchValue } = filters;
  const { pageNumber, pageLimit } = pagination;
  const { relabel, workorder } = drawer;

  const {
    listLocationLoading,
    locationItemList,
    relabelingLoading,
    workorderLoading
  } = useSelector((state) => state?.internalStorage || {});

  const { staging_items, items_count, warehouse_user, name, created_at, updated_at, location_type } = locationItemList || {};

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const locationId = params.get('id');
    if (locationId) {
      dispatch(ListLocationItems({ ...filters, locationId }))
      setFilters({
        ...filters,
        locationId
      });
    }
    else navigate("/warehouse-location")
  }, []);

  const handleDownloadLocationLabel = (data) => {
    const { title, uuid, stored_quantity, identifier, warehouse_user } = data || {};
    generateStorageBarcode({
      warehouseName: warehouse_user, 
      uuid, 
      title,
      stored_quantity,
      identifier,
      labelType: 'product'
    });
  }

  const columnDefinitions = [
    {
      headerName: "User",
      field: "assigned_user",
      width: 200,
      sortable: false,
      cellRendererFramework: ({ data }) => {
        const { assigned_user, assigned_user_email, assigned_user_company } = data || {};
        return (
          <div className="d-flex gap-2 align-items-center">
            <div>
              <span className="fs-14 color-secondary">
                {assigned_user_email || 'N/A'}
              </span>
              <div className="d-flex">
                <div className="d-flex grid-column-gap-2 pe-2">
                  <span className="fs-10">Name:</span>
                  <span className="color-primary fs-10 fw-bold">
                    {assigned_user || 'N/A'}
                  </span>
                </div>
                <div className="d-flex grid-column-gap-2">
                  <span className="fs-10 recent-grey">Company:</span>
                  <span className="color-green fs-10 fw-bold">
                    {assigned_user_company || 'N/A'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      headerName: "Title",
      field: "title",
      width: 250,
      cellRenderer: ({ value }) => (value ? value : "N/A"),
    },
    {
      headerName: "Identifier",
      field: 'identifier',
      width: 150,
      sortable: false,
      cellRenderer: ({ value }) => (value ? value : 0),
    },
    {
      headerName: "Quantity",
      field: "stored_quantity",
      width: 100,
      sortable: false,
      cellRendererFramework: (({ data }) => {
        const { quantity, stored_quantity = 0 } = data || {};
        if (selectedLocationId === 'unassigned') {
          if (quantity) {
            const quantityToShow = quantity - stored_quantity;
            return (
              <div>{quantityToShow}</div>
            )
          } else return (<div>N/A</div>)
        } else if (stored_quantity >= 0) {
          return (
            <div>{stored_quantity}</div>
          )
        } else return (<div>N/A</div>)
      })
    },
    // {
    //   headerName: "UUID",
    //   field: 'uuid',
    //   width: 300,
    //   sortable: false,
    //   cellRenderer: ({ value }) => (value ? value : 0),
    // },
    {
      headerName: "Source Type",
      field: 'source_type',
      width: 150,
      sortable: false,
      cellRenderer: ({ value }) => value ? handleMapSource(value) : 0,
    },
    {
      headerName: "Date Added",
      field: 'created_at',
      width: 200,
      sortable: false,
      cellRenderer: ({ value }) => (value ? moment(value).format('MMM DD, YYYY hh:mm:ss') : 'N/A')
    },
    {
      headerName: "Actions",
      width: 200,
      pinned: "right",
      cellRendererFramework: ({ data }) => {
        return (
          <div className="d-flex align-items-center table-icon table-icon-centered gap-3">
            <Tooltip title='Download Label'>
              <span className="icon-print cursor-pointer" onClick={() => handleDownloadLocationLabel(data)}></span>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleMapSource = (value) => {
    if (value === 'OutboundShipment') return 'Work Order'
    else if (value === 'PendingPo') return 'Distro Work Order'
    else if (value === 'ArrivalItem') return 'Storage PO'
    else if (value === 'FbaRemovalShipment') return 'Removal'
    else if (value === 'supplierOrders') return 'FBM'
    else if (value === 'Vendoritem') return 'Manual Addition'
    else if (value === 'refundedSupplierOrders') return 'FBM Refunded'
    else return 'N/A'
  }

  const handlePagination = (number, key) => {
    const value = Number(number);
    let filter = { ...filters }
    if (key === 'pageLimit') filter = { ...filters, pagination: { pageNumber: 1, pageLimit: value }};
    else filter = { ...filters, pagination: { ...pagination, pageNumber: value }};
    setFilters(filter);
    dispatch(ListLocationItems(filter));
  }

  const handleSearchValue = debounce((event) => {
    const { value } = event?.target || null;
    const filter = { ...filters, pagination: { ...pagination, pageNumber: 1 }, searchValue: value };
    setFilters(filter)
    dispatch(ListLocationItems(filter));
  }, 1000)

  const defaultColDef = {
    resizable: true,
    sortable: true,
  };

  const refreshGridClick = () => {
    dispatch(ListLocationItems({ ...filters }))
  }

  const totalCount = staging_items?.length || 0
  const { newAsin, newSku } = relistData || {};
  const { quantity } = workOrderData || {};
  const { pack_count = 1 } = selectedItem || {};
  const disabledWorkOrder = isEmpty(quantity) || (quantity % pack_count !== 0) || workorderLoading;

  return (
    <WarehouseWrapper className="unassigned-location">
      <Spin size='large' tip="Processing..." spinning={listLocationLoading}>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-3 align-items-center">
          <span
            className="icon-arrow-left cursor-pointer"
            onClick={() => navigate("/warehouse-location")}
          ></span>
          <h2 className="color-secondary mb-0">{locationId === 'unassigned'? 'Unassigned Items': name}</h2>
        </div>
        <SearchInput defaultValue={searchValue} placeholder="Search for Title/ Identifier" onChange={handleSearchValue} />
      </div>
      <div className="name-details">
        <div className="info">
          <span>Products:</span>
          <span>{items_count}</span>
        </div>
        {/* <div className="info">
          <span>Belongs to:</span>
          <span>
            <BsShopWindow className="color-primary" />
            {warehouse_user}
          </span>
        </div> */}
        <div className="info">
          <span>Created:</span>
          <span>{moment(created_at).format('MMM DD, YYYY hh:mm:ss')}</span>
        </div>
        <div className="info">
          <span>Updated:</span>
          <span>{moment(updated_at).format('MMM DD, YYYY hh:mm:ss')}</span>
        </div>
      </div>
      <div>
          <AgGrid
            user_referrals={staging_items || []}
            defaultColDef={defaultColDef}
            columnDefinitions={columnDefinitions}
            rowHeight={55}
            height="228px"
          />
          
      </div>
      <Pagination
        total={items_count}
        totalPages={ceil((items_count / pageLimit) + 1)}
        pageNumber={pageNumber}
        pageLimit={pageLimit}
        handleLimitChange={(e) => handlePagination(e, 'pageLimit')}
        handlePageNumberChange={(e) => handlePagination(e, 'pageNumber')}
        handlePageChangeLeft={(e) => handlePagination(e, 'pageNumber')}
        handlePageChangeRight={(e) => handlePagination(e, 'pageNumber')}
        refreshGridClick={refreshGridClick}
      />
      </Spin>
      {/* <Drawer
        show={relabel}
        size="26%"
      >
        <div className="drawer-padding">
          <div className="d-flex align-items-center grid-column-gap-10">
           <span
            className="icon-arrow-left cursor-pointer"
            onClick={() => handleDrawerClose('relabel')}
          ></span>
          <h3 className="mb-0">SHIPBACK -> RELABEL PRODUCT</h3>

          </div>
          <div className="relabel-description">
          <p className="mt-5">Use this form if you want to relabel the {selectedItem.identifier} with a different ASIN</p>
          </div>
          <div className="mt-3">
            <Input value={newAsin || undefined} label="New ASIN" className="h-32" placeholder="Enter ASIN Here..." onChange={lookupListing}/>
            {
              isValidAsin 
              ? <Input value={newSku || undefined} label="New SKU (Optional)" className="h-32" placeholder="Enter SKU Here..." onChange={handleNewSku} />
              : <p style={{ marginLeft: '10px'}}>Enter valid ASIN!</p>
            }
          </div>
          <div className="d-flex  justify-content-end mt-5">
          <Button disabled={!(isValidAsin) || relabelingLoading} text={relabelingLoading? "RELABELING": "RELABEL"} onClick={handleRelabelAsin} />
          </div>
        </div>
      </Drawer>
      <Drawer
        show={workorder}
        size="26%"
      >
        <div className="drawer-padding">
          <div className="d-flex align-items-center grid-column-gap-10">
           <span
            className="icon-arrow-left cursor-pointer"
            onClick={() => handleDrawerClose('workorder')}
          ></span>
          <h3 className="mb-0">Add to Work Order</h3>

          </div>

          <div 
          className="mt-5"
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginBottom: '24px'
          }}>
          <div>
              <Avatar
                shape='square'
                size={150}
                src={selectedItem?.image_url || 'no image'}
                icon="inbox"
              />
          </div>
          <div
            style={{
              margin: '24px',
              fontSize: 'unset'
            }}>
              <a>{selectedItem?.title || 'N/A'}</a>
              <p>Identifier {selectedItem?.identifier || 'N/A'}</p>
          </div>
        </div>
          <div className="mt-3">
          <Input type='number' value={quantity || undefined} label="Quantity" className="h-32" placeholder="How many quantity?" onChange={(e) => handleWorkOrderData(e, 'quantity')} />
          <Input disabled={true} label="Date Expiry" className="h-32" placeholder="Expire Date" />  
          </div>
          <div className="d-flex  justify-content-end mt-5">
          <Button disabled={disabledWorkOrder} text={workorderLoading? "Processing....": "Add to Work Order"} onClick={handleAddToWorkOrder}/>
          </div>
        </div>
      </Drawer> */}
    </WarehouseWrapper>
  );
};

export default Index;