import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  isViewing: false,
  isEditing: false,
  strategies: [],
  filters: {},
  hashToLoad: {},
  sortOptions: {},
  selectedItems: [],
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  totalStartegies: 0,
  success: false
};

const Strategies = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'SET_IS_VIEWING_FLAG': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_EDIT_STRATEGY': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_PAGINATION_STRATEGIES': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    case 'LOAD_HASH_TO_FORM': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_STRATEGIES_REQUEST': case 'GET_STRATEGY_REQUEST': case 'GET_PRODUCT_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_STRATEGIES_SUCCESS': {
      const { total_records: totalStartegies, strategies } = action.payload;
      return {
        ...state,
        fetching: false,
        totalStartegies,
        strategies
      };
    }
    case 'GET_STRATEGIES_FAILED': case 'GET_STRATEGY_FAILED': case 'GET_PRODUCT_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_STRATEGY_SUCCESS': case 'GET_PRODUCT_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload,
      };
    }
    case 'ADD_STRATEGY_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'ADD_STRATEGY_SUCCESS': {
      const { strategy } = action.payload;
      const { strategies } = state;
      strategies.push(strategy);
      return {
        ...state,
        fetching: false,
        strategies,
        totalStartegies: strategies.length
      };
    }
    case 'ADD_STRATEGY_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'UPDATE_STRATEGY_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'UPDATE_STRATEGY_SUCCESS': {
      const { strategy } = action.payload;
      let { strategies } = state;
      strategies = strategies.filter((strtgy) => {
        return (strtgy._id !== strategy._id);
      });
      strategies.push(strategy);
      return {
        ...state,
        fetching: false,
        strategies,
        totalStartegies: strategies.length
      };
    }
    case 'UPDATE_STRATEGY_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'DELETE_STRATEGY_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'DELETE_STRATEGY_SUCCESS': {
      const { id } = action.payload;
      let { strategies } = state;
      strategies = strategies.filter((strtgy) => (strtgy._id != id));
      return {
        ...state,
        fetching: false,
        strategies,
        totalStartegies: strategies.length
      };
    }
    case 'DELETE_STRATEGY_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_KEYWORD_FILTER_FOR_STRATEGIES': {
      const { key } = action.payload;
      return {
        ...state,
        filters: key ? { keyword: { value: key } } : {}
      };
    }
    case 'ASSIGN_TO_DEFAULT_STRATEGY_REQUEST': {
      return {
        ...state,
        fetching: true
      }
    }
    case 'ASSIGN_TO_DEFAULT_STRATEGY_ERROR':
    case 'ASSIGN_TO_DEFAULT_STRATEGY_SUCCESS': {
      return {
        ...state,
        fetching: false
      }
    }
    case 'CLONE_STRATEGY_ERROR': 
    case 'CLONE_STRATEGY_SUCCESS': {
      return {
        ...state,
        fetching: false
      }
    }
    case 'CLONE_STRATEGY_REQUEST': {
      return {
        ...state,
        fetching: true
      }
    }

    default:
      return state;
  }
};

export default Strategies;
