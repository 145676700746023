import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  fba_removal_shipments: [],
  total_records: 0,
  filters: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  relist_sku: ''
};

const userRemovals = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'ADD_TO_PO_REQUEST':
    case 'SEARCH_RELIST_ASIN':
    case 'SAVE_RELISTING_REQUEST':
    case 'UPDATE_USER_REMOVAL_REQUEST':
    case 'GET_USER_REMOVALS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'ADD_TO_PO_SUCCESS':
    case 'ADD_TO_PO_FAILED':
    case 'SAVE_RELISTING_FAILED':
    case 'SAVE_RELISTING_SUCCESS':
    case 'GET_USER_REMOVALS_FAILED':
    case 'UPDATE_USER_REMOVAL_SUCCESS':
    case 'UPDATE_USER_REMOVAL_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_USER_REMOVALS_SUCCESS': {
      const { items, total } = action.payload;
      return {
        ...state,
        fetching: false,
        fba_removal_shipments: items,
        total_records: total
      };
    }
    case 'SET_PAGINATION_USER_REMOVALS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    case 'SET_FILTERS_USER_REMOVALS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SEARCH_RELIST_ASIN_FAILED':
    case 'SEARCH_RELIST_ASIN_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        fetching: false
      };
    }
    default:
      return state;
  }
};

export default userRemovals;
