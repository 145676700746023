import React from "react";
import { TermsWrapper } from "./style";
import Header from "../../components/fbaHeader/index";
import { Row, Col } from "react-bootstrap";
const Index = () => {
  return (
    <TermsWrapper>
      <Header className="white-header" />
      <Row  className="justify-content-md-center m-0">
          <Col md={8}>
            <div className="add-margin">
            <h1>
              <strong>Legal Details</strong>
            </h1>
            <p>
              <strong>Company Name: </strong> SALES SUPPORT NEW JERSEY INC
            </p>
            <p>
              <strong>Address: </strong> 4211 35th St., Long Island City, New York, 111012301, 2012557995
            </p>
            <h1>
              <strong>Terms of Service</strong>
            </h1>
            <p>
              These Terms of Service represent a legally binding agreement between you and SALES SUPPORT NEW JERSEY INC, the owner of the Sales Support trademark and intellectual property. Salessupport reserves the right to take preventative or corrective
              actions to protect itself and its users at any time. Any user
              violating this policy voids any implied or express warranties and
              violates the Salessupport Terms. Violating this policy may also
              result in temporary or permanent limitation of a user&rsquo;s
              Account, a held Account, termination of an account with 30
              days&rsquo; notice, or immediate termination. This includes the
              inability to ship merchandise, to remove financial information
              from an account, and for users to close their Accounts as a way of
              evading the policy. Additionally, users whose Accounts are
              permanently limited for violating this policy are barred from
              future use of SalesSupport and its services, and such users are
              not permitted to directly or indirectly open new or additional
              SalesSupport Accounts on behalf of themselves or others. Please
              note, additional information on held Accounts and termination is
              provided in the Support section of this site. If you encounter
              violations of this policy, please report them to SalesSupport
              immediately.
            </p>
            <p>
              <strong>WHEREAS</strong>, the Company is in the business of
              selling and/or manufacturing certain Products; and
            </p>
            <p>
              <strong>WHEREAS</strong>, Company desires to retain SalesSupport
              &lsquo;s services, and SalesSupport desires to provide services to
              Company, subject to the terms and conditions of this Agreement;
              and
            </p>
            <p>
              <strong>NOW, THEREFORE</strong>, in consideration of the mutual
              covenants, terms and conditions set out herein, and for other good
              and valuable consideration, the receipt and sufficiency of which
              are hereby acknowledged, the Parties agree as follows:
            </p>
            <h2>
              <strong>1.&nbsp;APPOINTMENT</strong>
            </h2>
            <p>
              The Company hereby engages SalesSupport to provide, and
              SalesSupport hereby agrees, upon the terms and subject to the
              conditions set forth in this Agreement, to provide the Services
              (as defined below) to the Company for the duration of the Term and
              the Renewal Term described in Section 2. During the Term, and any
              Renewal Terms, the conduct of the Parties shall be subject to the
              terms and conditions of this Agreement,&nbsp;including conformance
              with the various SalesSupport policies&nbsp;
            </p>
            <h2>
              <strong>2.&nbsp;TERM</strong>
            </h2>
            <p>
              <strong>2.1 Term.&nbsp;&nbsp;</strong>The term of this Agreement
              commences on the Effective Date and continues for the initial
              period of one (1) year, unless and until earlier terminated as
              provided under this Agreement or applicable law
              (&ldquo;Term&rdquo;). On expiration of the Term, this Agreement
              automatically renews for additional successive one (1) year terms
              unless and until either Party provides written Notice of
              termination at least thirty (30) days before the end of the
              then-current term, or unless and until earlier terminated as
              provided under this Agreement or applicable Law (each a
              &ldquo;Renewal Term&ldquo;). If the Term is renewed for any
              Renewal Term(s) pursuant to this Section, the terms and conditions
              of this Agreement, and any Exhibits attached hereto, during each
              such Renewal Term are the same as the terms in effect immediately
              prior to such renewal.
            </p>
            <h2>
              <strong>3.&nbsp;SALESSUPPORT SERVICES</strong>
            </h2>
            <p>
              <strong>3.1 Services.&nbsp;&nbsp;</strong>SalesSupport shall
              provide the following services to the Company (collectively, the
              &ldquo;Services&rdquo;):
            </p>
            <ol>
              <li>Receive shipments from the Company of the Product</li>
              <li>
                Provide storage facilities for the Inventory in
                SalesSupport&rsquo;s warehousing facilities
                (&ldquo;Warehouse&rdquo;).
              </li>
              <li>
                Upon notification by the Company of a purchase of Products by a
                customer, SalesSupport will pick and package the Products from
                the available Inventory, and ship such Products directly to the
                customer (&ldquo;End-User&rdquo;).
              </li>
              <li>
                SalesSupport will use appropriate packaging material (i.e.
                bubble wrap, boxes with void fill, etc.) at its discretion.
              </li>
              <li>
                Upon request by the Company, SalesSupport will include a Company
                packing slip, and/or other Company marketing materials
                concerning the Product, to be provided by Company.
              </li>
              <li>
                SalesSupport will process, package and ship all Product orders
                in accordance with SalesSupport Policies.
              </li>
              <li>
                SalesSupport will maintain monthly ledger summaries of all
                orders shipped and received, which shall be made available to
                the Company through Salessupport billing.
              </li>
              <li>
                In addition to the above described Services, SalesSupport shall
                perform any additional services, including special projects,
                that the Company desires SalesSupport to perform, as more fully
                described on the attached Exhibit A, to be amended from time to
                time as agreed upon by the Parties.
              </li>
            </ol>
            <h2>4. COMPENSATION AND REIMBURSEMENT FOR SERVICES</h2>
            <p>
              <strong>4.1 Payments &amp; Invoicing.</strong>&nbsp;SalesSupport
              utilizes a &ldquo;Pay As You Go&rdquo; model for the payment of
              Services. Company will load funds into their account and fees are
              deducted as incurred by Company. Invoice for Services are
              generated at the end of each 2-week billing period and shall serve
              as a receipt for the Company.
            </p>
            <p>
              <strong>4.2 Product Invoicing.</strong>&nbsp;Any and all fees due
              for the cost of the Product shall be paid directly by the End-User
              to the Company. Under no circumstances shall SalesSupport accept,
              receive or otherwise be held responsible for payments from an
              End-User made in exchange for the Product.
            </p>
            <p>
              <strong>4.3 Late Payment Fee.</strong>&nbsp;If SalesSupport fails
              to receive payment from Company by the due date of the invoice; a
              late payment fee of 5% (minimum of $30) will be applied to the
              invoice, unless the invoice is currently being disputed.
            </p>
            <p>
              <strong>4.4</strong>&nbsp;
              <strong>Options &amp; Ways to Pay.</strong>&nbsp;SalesSupport
              provides the Company with a convenience to pay for their invoices
              online through the Company&rsquo;s account using the following
              payment methods: Bank Transfer &amp; ACH transfer (Free) and
              Credit Card (3.5% convenience fee) SalesSupport is using a
              3rd&nbsp;party payment processing company Stripe. SalesSupport
              does not store any payment information on its own servers; all of
              Company&rsquo;s payment information is stored with Stripe. If
              Company&rsquo;s invoice remains unpaid for more than 45 days from
              the issue date, Company agrees that SalesSupport shall have the
              right to auto-charge any payment method that has been used in the
              past.
            </p>
            <p>
              <strong>4.5</strong>&nbsp;<strong>Auto-Pay.</strong>&nbsp;Company
              has the option to agree to&nbsp; opt-in for &ldquo;auto-pay&rdquo;
              service, which will automatically charge the saved payment method
              on file in order to replenish the Company Pay as You Go account
              balance.&nbsp; Using the auto-pay service will prevent orders from
              being delayed in processing due to insufficient balances under the
              Pay as You Go payment system.
            </p>
            <h2>5. TITLE, RISK OF LOSS AND INSURANCE</h2>
            <p>
              <strong>5.1 Title.</strong>&nbsp;Company hereby agrees that at no
              time during the period that Products are held by SalesSupport as
              Inventory in the Warehouse will SalesSupport hold title, or any
              other rights of ownership in the Inventory. Title in Inventory
              will continue to be held by Company until such time as the
              Products are delivered to the End-User.
            </p>
            <p>
              <strong>5.2 Risk of Loss</strong>. Company hereby agrees that at
              no time during the period that Products are held by SalesSupport
              as Inventory in the Warehouse will SalesSupport carry the risk of
              loss in the Inventory. Risk of Loss in Inventory will continue to
              be held by Company until such time as the Products are delivered
              to the End-User.&nbsp; SalesSupport shall exercise commercially
              reasonable standards of care for all of Company&rsquo;s Products
              in its possession but specifically disclaims liability of loss
              except for instances of Gross Negligence by
              SalesSupport.&nbsp;&nbsp;
            </p>
            <p>
              <strong>5.3 Insurance.&nbsp;</strong>COMPANY HEREBY AGREES THAT IT
              IS COMPANY&rsquo;S RESPONSIBILITY, AT ALL TIMES, TO MAINTAIN AN
              INSURANCE POLICY THAT COVERS THE COST OF THE PRODUCTS HELD IN
              INVENTORY. Company has the option of adding the Warehouse as a
              designated storage location to Company&rsquo;s general inventory
              policy.&nbsp; If requested by Company, insurance carrier
              SalesSupport shall allow premises inspection in order to assist
              Company in obtaining coverage.&nbsp;
            </p>
            <p>
              <strong>5.4 No Insurance.&nbsp;</strong>Company can choose not to
              insure its inventory. In this event, SalesSupport will, under no
              circumstances, be liable for any loss or damage to the inventory
              stored at SalesSupport facilities. These events include, but are
              not limited to, theft, misuse, fire, natural disaster or any other
              event.
            </p>
            <h2>6. COMPANY&rsquo;S ACCOUNT</h2>
            <p>
              <strong>6.1 Services Available Through the Account.</strong>
              &nbsp;Subject to Company&rsquo;s compliance with this Agreement,
              SalesSupport shall perform the Services described in this
              Agreement as selected and authorized by Company in the Account.
              Company may order Services through the Account
              (&ldquo;SalesSupport Services Orders&rdquo;). All SalesSupport
              Services Orders are deemed incorporated into, and governed by,
              this Agreement. By using the Services, Company acknowledges and
              agrees that Salessupport operates as a third-party warehouse and
              broker of shipping services; accepting shipments from, and making
              shipments to, third parties. SalesSupport is an independent
              contractor for all purposes, and only acts as the agent of Company
              with respect to SalesSupport&rsquo;s custody of the Products in
              its Inventory.&nbsp;
            </p>
            <p>
              <strong>6.2 Account Balances.</strong>&nbsp;&nbsp; Any invoices
              SalesSupport sends to Company in accordance with Section 5 of this
              Agreement shall be based on any balance accrued in the
              Company&rsquo;s Account. &ldquo;3rd&nbsp;Party Fees&rdquo; are the
              fees for postage, shipping and any third-party fees (including,
              but not limited to carrier fees, shipping fees, rates of duty,
              international brokerage charges, freight charges, insurance
              premiums or other charges given during Company&rsquo;s use of
              Services) ordered through the Account and any special or
              additional fees assessed against the Account as permitted in this
              Agreement. The 3rd&nbsp;party fees for Services are billed during
              the month in which the Services are performed, but such
              3rd&nbsp;Party Fees are subject to change due to circumstances
              beyond SalesSupport&rsquo;s control. Company agrees and
              acknowledges that should Company change Account billing plans
              during or at the end of any month, the previous pricing plan may
              no longer be available. All other fees for the Services listed
              on&nbsp;Exhibit A&nbsp;to this Agreement, including Pick &amp;
              Pack, Storage, Special Requests, Hourly Rates, etc., will be
              billed on the 1st&nbsp;and 15th day of the month following the
              conclusion of the month for which such Services were provided, or
              an Account Balance has accrued.
            </p>
            <p>
              <strong>6.4 Pricing Changes &amp; Currency Fluctuations.</strong>
              &nbsp;SalesSupport reserves the right to adjust its pricing for
              its Services, with 30 days&rsquo; notice via publication on the
              SalesSupport k website and/or directly to the Company due to
              market forces and/or in response to currency fluctuations,
              including but not limited to, currency conversion rate changes,
              conversion fee changes, and/or discount rate changes. After the
              first 90-days, SalesSupport reserves the right to automatically
              revise pricing without notice if actual volume does not meet or
              exceed the initial volume estimates provided by the Company at the
              time of contract execution to a rate equal to actual volume. All
              dollar amounts stated in this Agreement will be in US dollars
              unless otherwise specified.
            </p>
            <p>
              <strong>6.5 Usage Fee Disputes.</strong>&nbsp;Should Company
              disagree with any Usage Fees (other than carrier or third-party
              fees) invoiced or charged against the Account, Company must submit
              written notice to SalesSupport within 30 days of the fee being
              charged (&ldquo;Dispute Period&rdquo;). SalesSupport will not
              review customer requests for Usage Fee adjustments that are
              received after the Dispute Period.&nbsp;
            </p>
            <p>
              <strong>6.6 Account Balance Disputes</strong>. If SalesSupport
              becomes aware of, or is notified of, a dispute relating to the
              Account Balance, then SalesSupport will promptly review the
              dispute. Within five business days after the resolution of the
              dispute, SalesSupport will forward a bill for outstanding amounts
              due, or will credit the Account Balance accordingly, if
              appropriate.&nbsp;
            </p>
            <p>
              <strong>6.7 Abandoned Account and Liquidation.</strong>&nbsp;If
              Company&rsquo;s Usage Fees or 3rd&nbsp;Party Fees remain unpaid
              for a period greater than 30 days, then SalesSupport reserves the
              right, at its sole discretion to reclassify Company&rsquo;s
              Account as an &ldquo;Abandoned Account.&rdquo; Additionally, any
              Account that remains unpaid for greater than 60 days will
              automatically be deemed an Abandoned Account. Upon an Account
              becoming an Abandoned Account, Company immediately forfeits its
              rights of ownership of such Company&rsquo;s Inventory up to the
              cumulative amount necessary for payment of all Usage Fees and
              3rd&nbsp;Party Fees as well as any reasonable expenses incurred by
              SalesSupport for the preservation and storage of the Inventory or
              its sale. Inventory will become immediately unavailable to
              Company, and liquidation proceedings would begin. Company agrees
              that all Inventory liquidated shall be sold at the absolute
              discretion of SalesSupport.
            </p>
            <p>
              Company has no rights to any liquidation proceeds arising from an
              Abandoned Account unless the amounts recovered through liquidation
              exceed the cumulative amount necessary for payment as stated
              above.&nbsp; Should the amounts recovered through liquidation be
              insufficient to cover the cumulative amounts above, the Company
              would remain liable for any pending Usage Fees and 3rd&nbsp;Party
              Fees above and beyond the liquidation proceeds.
            </p>
            <p>
              <strong>6.8 Termination of the Account.</strong>&nbsp;Upon
              termination of this Agreement, the Account will be inaccessible to
              Company, and all activities will be suspended. SalesSupport
              reserves the right to cease shipping orders for a number reasons,
              including but not limited to the following:
            </p>
            <ol>
              <li>
                Account Balance remaining unpaid within 30 days of receipt of
                invoice;
              </li>
              <li>
                if anyone using the Account uses abusive language or otherwise
                threatens SalesSupport or its staff;
              </li>
              <li>
                to allow time to resolve or investigate a third party complaint
                of a violation of this Agreement;
              </li>
              <li>
                to allow time for investigation or resolution of an unauthorized
                transaction, customer complaint, dispute or accusation; and
              </li>
              <li>
                to allow time to pick all inventory for the close out order.
              </li>
            </ol>
            <p>
              <strong>6.9 Termination for Breach. &nbsp;&nbsp;</strong>Either
              party may terminate this Agreement upon thirty (30) days&rsquo;
              notice to the other party in the event of a breach by the other
              party of any provision of this Agreement if the breaching party
              fails to cure such breach during the 30 day period;&nbsp;
              <em>provided</em>, that SalesSupport may terminate this Agreement
              immediately upon notice to Client for fraudulent, abusive or
              illegal activity.&nbsp;Upon the expiration of the 30 day cure
              period, a 30 day wind down of the Company Account shall commence
              (&ldquo;Termination Notice Period&rdquo;). Company may have full
              or limited use of the Account during the Termination Notice Period
              for the sole purpose of winding down its use of the account in a
              commercially reasonable manner. Any Product inventory that remains
              in SalesSupport&rsquo;s inventory at the expiration of the
              Termination Notice Period will be shipped to Company at the
              address on file and, if address is not on file, the billing
              address on Company&rsquo;s credit card, at Company&rsquo;s
              expense. If no address is available or no balance is available to
              pay for shipment back to the Company, SalesSupport will liquidate
              the Product inventory in accordance with an Abandoned Account.
            </p>
            <p>
              <strong>6.10 Additional Actions.&nbsp;</strong>If Company is in
              breach of any of the terms of this Agreement, SalesSupport may
              close, put on hold, or limit access to the Account or the
              Services. Without limiting any of the remedies under law or
              equity, SalesSupport may also take any additionally available
              legal actions
            </p>
            <p>
              <strong>6.11 Closing Accounts.</strong>&nbsp;Company may close the
              Account for any reason, upon giving SalesSupport 30 days prior
              written notice. Merchandise will be refunded subject to Company
              paying SalesSupport&rsquo;s standard pick and pack rates outlined
              in Exhibit A.
            </p>
            <h2>7. SALESSUPPORT INTELLECTUAL PROPERTY RIGHTS</h2>
            <p>
              <strong>7.1 SalesSupport Property.</strong>&nbsp;For purposes of
              this Agreement, &ldquo;SalesSupport Property&rdquo; shall mean (a)
              SalesSupport&rsquo;s methodology for the provision of the
              Services; and (b) SalesSupport&rsquo;s Confidential Information.
              SalesSupport hereby retains all worldwide right, title and
              interest in and to the SalesSupport Property. Any rights not
              expressly granted herein to the SalesSupport Property shall be
              retained by SalesSupport. Company acknowledges that all right,
              title and interest to the SalesSupport Property is owned by
              SalesSupport.
            </p>
            <p>
              <strong>7.3 Client Property.</strong>&nbsp;No Confidential
              Information obtained by SalesSupport from Company shall become
              SalesSupport Property. All materials provided by Company shall be
              deemed &ldquo;Client Property&rdquo; for purposes of this
              Agreement. Company grants SalesSupport a non-exclusive license to
              the Client Property solely as needed to provide the Services.
              <strong>&nbsp;</strong>
            </p>
            <p>
              <strong>7.5 Third Party Software</strong>. Any third party
              software application Company uses on the SalesSupport website, to
              connect to the Services, or related to the Services (&ldquo;Third
              Party Software&rdquo;) is solely subject to any third party
              software provider software licenses. SalesSupport does not own,
              control or have any responsibility or liability for any Third
              Party Software.
            </p>
            <h2>8. CONFIDENTIAL INFORMATION</h2>
            <p>
              <strong>8.1 Scope.</strong>&nbsp;From time to time during the
              Term, either Party (&ldquo;Discloser&rdquo;) may disclose or make
              available to the other Party (&ldquo;Recipient&rdquo;)
              Confidential Information, whether orally or in written, electronic
              or other form, whether or not marked, designated or otherwise
              identified as &ldquo;confidential.&rdquo; For purposes of this
              Agreement, &ldquo;Confidential Information&rdquo; includes all
              industrial and other intellectual property rights comprising or
              relating to:
            </p>
            <ol>
              <li>
                all patents (including all reissues, divisionals, provisionals,
                continuations and continuations-in-part, re-examinations,
                renewals, substitutions and extensions thereof), patent
                applications, and other patent rights and any other governmental
                authority-issued indicia of invention ownership (including
                inventor&rsquo;s certificates, petty patents and patent utility
                models);
              </li>
              <li>
                all rights in and to US and foreign trademarks, service marks,
                trade dress, trade names, brand names, logos, trade dress,
                corporate names and domain names and other similar designations
                of source, sponsorship, association or origin, together with the
                goodwill symbolized by any of the foregoing, in each case
                whether registered or unregistered and including all
                registrations and applications for, and renewals or extensions
                of, these rights and all similar or equivalent rights or forms
                of protection in any part of the world;
              </li>
              <li>
                all rights in and to internet domain names, registered by any
                authorized private registrar or another governmental authority,
                web addresses, web pages, website and URLs;
              </li>
              <li>
                all rights in and to works of authorship, expressions, designs
                and design registrations, whether or not copyrightable,
                including copyrights and copyrightable works, software and
                firmware, application programming interfaces, architecture,
                files, records, schematics, data, data files, and databases and
                other specifications and documentation; and
              </li>
              <li>
                all inventions, discoveries, trade secrets, business and
                technical information and know-how, databases, data collections,
                patent disclosures and other confidential and proprietary
                information and all rights therein information about its
                business affairs, goods and services; third-party confidential
                information and other sensitive or proprietary information.
              </li>
            </ol>
            <p>
              <strong>8.2 Exclusion.</strong>&nbsp;Information that is subject
              to one of the following exclusions shall not be Confidential
              Information:&nbsp;
            </p>
            <ol>
              <li>
                information that is or becomes generally available to and known
                by the public;
              </li>
              <li>
                information that is or becomes available to the Recipient on a
                non-confidential basis from a third-party source;
              </li>
              <li>
                information published or otherwise made known to the public by
                Discloser;
              </li>
              <li>
                information that was generated independently without reference
                to the Discloser&rsquo;s Confidential Information; or
              </li>
              <li>
                information that is required to be disclosed under a court order
                or pursuant to any applicable governmental rule, regulation or
                statute, provided that Recipient provide Discloser with prior
                written Notice of such disclosure, (as permitted by law) and the
                timing for response set forth in the request.
              </li>
            </ol>
            <p>
              <strong>8.3 Aggregate Use</strong>. Subject to the terms and
              conditions of this Agreement, Company hereby acknowledges and
              agrees that SalesSupport may compile aggregate results from all
              of, or a selection of Company&rsquo;s use of the Services,
              provided that SalesSupport shall not disclose any information that
              would individually identify Company (&ldquo;Aggregate
              Information&rdquo;). Such Aggregate Information shall be deemed to
              be SalesSupport&rsquo;s Confidential Information. Company also
              hereby agrees that SalesSupport may review and use Company&rsquo;s
              individual use of the Services in order to provide Services to
              Company, to evaluate SalesSupport&rsquo;s provision of the
              Services, and to improve SalesSupport&rsquo;s service offerings.
            </p>
            <h2>9. INDEMNITY</h2>
            <p>
              <strong>9.1 Indemnification</strong>. Subject to the terms and
              conditions of this Agreement, Company shall indemnify, defend and
              hold harmless SalesSupport and its representatives/officers,
              directors, employees, agents, affiliates, successors and permitted
              assigns (collectively, &ldquo;Indemnified Party&rdquo;) against
              any and all losses, damages, liabilities, deficiencies, claims,
              actions, judgments, settlements, interest, awards, penalties,
              fines, costs, or expenses of whatever kind, including
              attorneys&rsquo; fees, fees and the costs of enforcing any right
              to indemnification under this Agreement and the cost of pursuing
              any insurance providers, incurred by Indemnified Party or End-User
              (collectively, &ldquo;Losses&rdquo;), arising out or resulting
              from any claim of a third party alleging:
            </p>
            <ol>
              <li>
                breach or non-fulfillment of any representation, warranty or
                covenant under/representation or warranty set forth in this
                Agreement by Company;
              </li>
              <li>
                any negligent or more culpable act or omission of Company
                (including any recklessness or willful misconduct) in connection
                with the performance of its obligations under this Agreement;
              </li>
              <li>
                any bodily injury, death of any person or damage to real or
                tangible personal property caused by the willful or grossly
                negligent acts or omissions of Company;
              </li>
              <li>
                the acts or omissions (including, without limitation, any
                negligence or willful misconduct) of any third party whether or
                not selected by or retained by SalesSupport;
              </li>
              <li>
                any failure by Company to substantially comply with an
                applicable Food and Drug Administration (FDA) or other
                governmental requirement; or
              </li>
              <li>
                any failure by Company to comply with any applicable state,
                federal or international laws.
              </li>
            </ol>
            <h2>10. DISCLAIMERS</h2>
            <p>
              <strong>10.1 As Is.</strong>&nbsp;SalesSupport&rsquo;s obligations
              under this agreement and the attached Exhibits (including the use
              of SalesSupport&rsquo;s technology) are provided on an &ldquo;as
              is&rdquo; and &ldquo;as available&rdquo; basis. SalesSupport
              expressly disclaims all warranties of any kind, whether expressed
              or implied, including, but not limited to, the implied warranties
              of merchantability, fitness for a particular purpose and
              non-infringement for the services, SalesSupport&rsquo;s website
              and any third-party services. the use of services, SalesSupport
              website, or third party services is at Company&rsquo;s risk.
            </p>
            <p>
              <strong>10.2 No Continuous Access.</strong>&nbsp;SalesSupport does
              not guarantee continuous, uninterrupted or secure access to the
              Service. Operation of the Services may be interfered with by
              numerous factors outside of SalesSupport&rsquo;s control. However,
              SalesSupport will make reasonable efforts to process requests for
              receiving or shipping merchandise in a timely manner but
              SalesSupport makes no representations or warranties regarding the
              amount of time needed to complete processing because the Service
              is dependent upon many factors outside of its control.
            </p>
            <h2>11. LIMITATION ON SALESSUPPORT LIABILITY</h2>
            <p>
              <strong>
                11.1 NO LIABILITY FOR CONSEQUENTIAL OR INDIRECT DAMAGES THIRD
                PARTY LIABILITY.
              </strong>
              &nbsp;EXCEPT FOR LIABILITY FOR INDEMNIFICATION AND LIABILITY FOR
              BREACH OF CONFIDENTIALITY, NEITHER SALESSUPPORT NOR ITS
              REPRESENTATIVES IS LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
              CONSEQUENTIAL, EXEMPLARY, PUNITIVE OR ENHANCED DAMAGES, OR DAMAGES
              FOR LOSS, LOSS OF PROFITS, REVENUE, DATA OR USE, INCURRED BY
              COMPANY OR ANY THIRD PARTY, WHETHER IN AN ACTION IN CONTRACT OR
              TORT, ARISING OUT OF OR RELATING TO ANY BREACH OF THIS AGREEMENT,
              WHETHER OR NOT THE POSSIBILITY OF SUCH DAMAGES HAS BEEN DISCLOSED
              IN ADVANCE BY SALESUPPORT/ OR COULD HAVE BEEN REASONABLY FORESEEN
              BY SALESSUPPORT, REGARDLESS OF THE LEGAL OR EQUITABLE THEORY
              (CONTRACT, TORT OR OTHERWISE) UPON WHICH THE CLAIM IS BASED, AND
              NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS
              ESSENTIAL PURPOSE. OTHER THAN AS SET FORTH BELOW, IN NO EVENT
              SHALL SALESSUPPORT&rsquo;S LIABILITY UNDER THIS AGREEMENT EXCEED
              THE MONIES PAID OR PAYABLE BY COMPANY TO SALESSUPPORT EXCLUDING
              CARRIER FEES OR OTHER THIRD PARTY FEES (&ldquo;DAMAGES
              CAP&rdquo;). SALESSUPPORT MUST BE NOTIFIED WITHIN FIVE (5) DAYS
              AFTER ANY UNAUTHORIZED TRANSACTION OR COMPANY WAIVE ALL DAMAGES
              FROM SALESSUPPORT.
            </p>
            <p>
              <strong>11.2 EXCLUSIVE REMEDY.</strong>&nbsp;THE PROVISIONS OF
              THIS AGREEMENT PROVIDE COMPANY&rsquo;S EXCLUSIVE REMEDY AGAINST
              SALESSUPPORT FOR ANY CLAIM OR CAUSE OF ACTION WHATSOEVER RELATING
              TO LOSS, DAMAGE AND/OR DESTRUCTION OF INVENTORY AND SHALL APPLY TO
              ALL CLAIMS INCLUDING INVENTORY SHORTAGE AND MYSTERIOUS
              DISAPPEARANCE CLAIMS UNLESS PROVEN BY AFFIRMATIVE EVIDENCE THAT
              SALESSUPPORT CONVERTED THE INVENTORY TO ITS OWN USE. COMPANY
              HEREBY WAIVES ANY RIGHTS TO RELY UPON ANY PRESUMPTION OF
              CONVERSION IMPOSED BY LAW.&nbsp;
            </p>
            <p>
              <strong>11.3 INVENTORY COUNT INACCURACIES.</strong>&nbsp;IN THE
              EVENT OF INVENTORY LOSS DUE TO INVENTORY COUNT INACCURACIES,
              INACCURATE INVENTORY COUNTS DURING RECEIVING OR INVENTORY COUNT
              INACCURACIES AT ANY TIME THAT SALESSUPPORT IS IN POSSESSION OF
              INVENTORY FOR WHICH THE CLAUSES ABOVE IS DETERMINED TO BE
              INAPPLICABLE AND SALESSUPPORT IS HELD LEGALLY LIABLE, COMPANY
              AGREES THAT IT WILL BE CONSIDERED AN &ldquo;INVENTORY LOSS&rdquo;
              AND SALESSUPPORT&rsquo;S LIABILITY SHALL BE LIMITED AS STATED
              ABOVE. IN NO EVENT SHALL SALESSUPPORT BE LIABLE FOR ANY LOST SALES
              REVENUE FROM THE INVENTORY LOSS DUE TO INVENTORY COUNT
              INACCURACIES.
            </p>
            <p>
              <strong>11.4 PROJECTS.</strong>&nbsp;IN THE EVENT OF A LOSS DUE TO
              A PROJECT DEFINED AS AN HOURLY OR PIECE WORK DONE ON EXPECTED OR
              HELD INVENTORY (&ldquo;SPECIAL PROJECT&rdquo;), COMPANY AGREES AND
              ACKNOWLEDGES THAT SALESSUPPORT&rsquo;S LIABILITY SHALL BE LIMITED
              TO THE HOURLY CHARGE COMPANY PAID TO SALESSUPPORT FOR THE PROJECT.
            </p>
            <h2>12. GENERAL PROVISIONS.</h2>
            <p>
              <strong>12.1 Entire Agreement.</strong>&nbsp;This Agreement,
              including and together with any related Exhibits, invoices and
              SalesSupport Services Orders constitutes the sole and entire
              agreement of the Parties with respect to the subject matter
              contained herein and therein, and supersedes all prior and
              contemporaneous understandings, agreements, representations and
              warranties, both written and oral, regarding such subject matter.
            </p>
            <p>
              <strong>12.2 Severability.</strong>&nbsp;If any provision of this
              Agreement is held invalid or unenforceable by a court of competent
              jurisdiction, such provision shall be modified to the extent
              necessary to make such provision valid and enforceable and the
              remaining provisions of this Agreement shall remain in effect and
              enforceable in accordance with their terms.
            </p>
            <p>
              <strong>12.3 No Waiver.</strong>&nbsp;Failure or delay of
              SalesSupport to exercise a right or power under this Agreement
              shall not operate as a waiver thereof, nor shall any single or
              partial exercise of a right or power preclude any other future
              exercise thereof.
            </p>
            <p>
              <strong>12.4 Notices.</strong>&nbsp;All notices, requests,
              consents, claims, demands, waivers and other communications under
              this Agreement (each, a &ldquo;Notice&rdquo;) must be in writing
              and addressed to the other Party at its address designated from
              time to time. Unless otherwise agreed herein, all Notices to
              Company must be delivered by email listed in the Account, personal
              delivery, nationally recognized overnight courier or certified or
              registered mail to the address listed in the Account. Except as
              otherwise provided in this Agreement, a Notice is effective only
              (a) on receipt by the receiving Party, and (b) if the Party giving
              the Notice has complied with the requirements of this Section.
            </p>
            <p>
              <strong>12.5 Conflict of Terms.</strong>&nbsp;If there is a
              conflict between this Agreement and the terms of any air waybill,
              bill of lading or other transit documentation set forth by the
              contracted carrier, the carrier&rsquo;s terms will control. If not
              stated within the carrier&rsquo;s terms, this Agreement shall
              control.
            </p>
            <p>
              <strong>12.6 Attorneys&rsquo; Fees and Costs.</strong>&nbsp;In the
              event of a legal dispute, the prevailing Party shall have the
              right to collect from non-prevailing Party any reasonable costs
              and/or attorneys&rsquo; fees incurred in enforcing this Agreement.
            </p>
            <p>
              <strong>12.7 Governing Law and Venue.</strong>&nbsp;This Agreement
              and performance by the Parties hereunder shall be construed in
              accordance with the applicable laws of the State of Florida
              without regard to conflicts of laws provisions thereof, or, as
              appropriate, federal laws of the United States. Any action or
              proceeding arising from or relating to these terms must be brought
              in a federal or state court in Broward County, Florida. The
              Parties consent to the exclusive jurisdiction of, and venue in,
              the state and federal courts within Broward County, Florida.
              Notwithstanding the foregoing, SalesSupport may seek injunctive or
              other equitable relief to protect SalesSupport&rsquo;s
              intellectual property rights in any court of competent
              jurisdiction.
            </p>
            <p>
              <strong>12.8 Impersonation.</strong> SalesSupport may utilize an impersonation 
              function within the App solely for the purpose of identifying and diagnosing 
              issues experienced by users. This impersonation functionality allows SalesSupport 
              to view, but not edit, any data or settings of the impersonated user. SalesSupport 
              agrees that it will not use this functionality for any other purpose and will ensure 
              that any impersonation activities are conducted in accordance with applicable laws and regulations.
            </p>
            <p>
              <strong>12.9 Force Majeure.</strong>&nbsp;Any delay or failure of
              either Party to perform its obligations under this Agreement will
              be excused to the extent that the delay or failure was caused
              directly by an event beyond such Party&rsquo;s control, without
              such Party&rsquo;s fault or negligence and that by its nature
              could not have been foreseen by such Party or, if it could have
              been foreseen, was unavoidable (which events may include natural
              disasters, embargoes, explosions, riots, wars or acts of
              terrorism) (each, a &ldquo;Force Majeure Event&ldquo;).
              Company&rsquo;s financial inability to perform, changes in cost or
              availability of materials, components or services, market
              conditions or Company actions or contract disputes will not excuse
              performance by Company under this Section. Company shall give
              SalesSupport prompt written Notice of any event or circumstance
              that is reasonably likely to result in a Force Majeure Event, and
              the anticipated duration of such Force Majeure Event. Company
              shall use all diligent efforts to end the Force Majeure Event,
              ensure that the effects of any Force Majeure Event are minimized
              and resume full performance under this Agreement.&nbsp;
            </p>
            <p>
              If requested by SalesSupport, Company shall, within 30 days of
              such request, provide adequate assurances that a Force Majeure
              Event will not exceed 30 days. The rights granted to Company with
              respect to excused delays under this Section are intended to limit
              Company&rsquo;s rights under theories of force majeure, commercial
              impracticability, impracticability or impossibility of
              performance, or failure of presupposed conditions or otherwise,
              including any rights arising under the Florida Uniform Commercial
              Code or any similar statute or regulation.
            </p>
            <h2>
              <strong>Refund Policy</strong>
            </h2>
            <p>
              Here is the refund policy for SalesSupport if you have any
              questions
            </p>
            <ul>
              <li>
                Email: steven@fba.support (subject line: Refund Order) or
                contact us on our site to obtain a Refund Authorization Number
                within 30 days of the invoice generated date.
              </li>
              <li>
                Once we process your refund, we will email you a refund shipping
                label that you can attach to the package and mail it back to us.
              </li>
              <li>
                Write the Refund Authorization Number clearly and visibly on the
                outside of your refunded package. Refunds that do not have a RMA
                written on the outside of the package will not be opened and
                will be refunded to sender (at sender’s expense).
              </li>
              <li>
                Purchases that meet the above refund policy guidelines will be
                refunded back to the original method of payment.
              </li>
              <li>
                Purchases received outside of the Return Policy Guidelines will
                not be mailed back and will not be refunded.
              </li>
            </ul>

            <h2>
              <strong>Delivery Policy</strong>
            </h2>
            <ul>
              <li>
                Delivery/Shipping policy: We delivery via pallet or UPS courier.
              </li>
              <li>
                SHIPPING CHARGES: Our shipping varies with respect to order’s
                size & weight..
              </li>
              <li>
                DELIVERY TIME: We make sure items to be shipped within 24-72
                hours. Orders may face temporary delay during promotional, sale
                days or due to the heavy flow of orders..
              </li>
            </ul>

            <h2>
              <strong>Privacy Policy</strong>
            </h2>
            <p>
              We know that you care how information about you is used and
              shared, and we appreciate your trust that we will do so carefully
              and sensibly. This notice describes our privacy policy. By
              visiting Sales.support.com, you are accepting the practices
              described in this Privacy Notice.
            </p>
            <p>
              The information we learn from customers helps us personalize and
              continually improve your shipping experience at Sales.support.com.
              Here are the types of information we gather.
            </p>
            <p>
              Information you give us: We receive and store any information you
              enter on our Web site or give us in any other way. You provide
              most such information when you price, ship, participate in a
              contest or questionnaire, or communicate with customer service. As
              a result of those actions, you might supply us with such
              information as your name, address, and phone numbers; credit card
              information; people to whom packages have been shipped, including
              addresses and phone number; people (with addresses and phone
              numbers) listed in your address book; content of reviews and
              e-mails to us; personal description; and financial information,
              including Social Security and driver&rsquo;s license numbers. You
              can choose not to provide certain information, but then you might
              not be able to take advantage of many of our features. We use the
              information that you provide for such purposes as responding to
              your requests, customizing future shipping for you, improving our
              site, and communicating with you.
            </p>
            <p>
              Automatic information: We receive and store certain types of
              information whenever you interact with us. For example, like many
              Web sites, we use &ldquo;cookies,&rdquo; and we obtain certain
              types of information when your Web browser accesses
              Sales.support.com. Examples of the information we collect and
              analyze include the Internet protocol (IP) address used to connect
              your computer to the Internet; login; e-mail address; password;
              computer and connection information such as browser type and
              version, operating system, and platform; shipping history, which
              we sometimes aggregate with similar information from other
              customers to create features such as shipping circles and top
              shippers; the full Uniform Resource Locator (URL) clickstream to,
              though, and from our Web site, including date and time; cookie
              number; routes you searched for; and the phone number you used to
              call our 888 number. During some visits we may use software tools
              such as JavaScript to measure and collect session information,
              including page response times, download errors, length of visits
              to certain pages, page interaction information (such as scrolling,
              clicks, and mouse-overs), and methods used to browse away from the
              page. A number of companies offer utilities designed to help you
              visit Web sites anonymously. Although we will not be able to
              provide you with a personalized experience at Sales.support.com if
              we cannot recognize you, we want you to be aware that these tools
              exist.
            </p>
            <p>
              Information from other sources: We might receive information about
              you from other sources and add it to our account information.
              Examples of information we receive from other sources include
              updated delivery and address information from our carriers or
              other third parties, which we use to correct our records and
              deliver your next shipment or communication more easily; account
              information, purchase or redemption information, and page-view
              information; pricing criteria and pricing result information; and
              credit history information from credit bureaus, which we use to
              help prevent and detect fraud and to offer certain credit services
              to some customers.
            </p>
            <p>
              Information about our customers is an important part of our
              business, and we are not in the business of selling it to others.
              We share customer information only as described below.
            </p>
            <p>
              Affiliated businesses we do not control: We work closely with our
              affiliated businesses. Examples of co-branded and joint offerings
              include carriers which handle the delivery of your packages, and
              brokers which provide your packages with cargo insurance. You can
              tell when a third party is involved in your transactions, and we
              share customer information related to those transactions with that
              third party.
            </p>
            <p>
              Agents: We employ other companies and individuals to perform
              functions on our behalf. Examples include fulfilling orders,
              delivering packages, sending postal mail and e-mail, removing
              repetitive information from customer lists, analyzing data,
              providing marketing assistance, providing search results and links
              (including paid listings and links), processing credit card
              payments, and providing customer service. They have access to
              personal information needed to perform their functions, but may
              not use it for other purposes.
            </p>
            <p>
              Business transfers: As we continue to develop our business, we
              might sell or buy business units. In such transactions, customer
              information generally is one of the transferred business assets
              but remains subject to the promises made in any pre-existing
              Privacy Notice (unless, of course, the customer consents
              otherwise). Also, in the unlikely event that we, or substantially
              all of our assets are acquired, customer information will of
              course be one of the transferred assets.
            </p>
            <p>
              Protection of Sales.support.com and others: We release account and
              other personal information as necessary to enforce or apply our
              Terms of Use and other agreements; or protect the rights,
              property, or safety of us, our users, or others. This includes
              exchanging information with other companies and organizations for
              fraud protection and credit risk reduction. Obviously, however,
              this does not include selling, renting, sharing, or otherwise
              disclosing personally identifiable information from customers for
              commercial purposes in violation of the commitments set forth in
              this Privacy Notice.
            </p>
            <p>
              Law Enforcement: SalesSupport&rsquo;s policy is to notify members
              of requests for their data unless it is prohibited from doing so
              by statute or court order. Law enforcement officials who believe
              that notification would jeopardize an investigation should obtain
              an appropriate court order or other process that specifically
              precludes member notification.
            </p>
            <p>
              With your consent: Other than as set out above, you will receive
              notice when information about you might go to third parties, and
              you will have an opportunity to choose not to share the
              information.
            </p>
            <p>
              We reveal only the last five digits of your credit card numbers
              when confirming a shipment. Of course, we transmit the entire
              credit card number to the appropriate credit card company during
              order processing. It is important for you to protect against
              unauthorized access to your password and to your computer. Be sure
              to sign off when finished using a shared computer.
            </p>
            <p>
              If you choose to visit Sales.support.com, your visit and any
              dispute over privacy is subject to this Notice and our Terms of
              Use, including limitations on damages, arbitration of disputes,
              and application of the law of the state of Florida. If you have
              any concern about privacy at Sales.support.com, please contact us
              with a thorough description, and we will try to resolve it.
            </p>
            <p>
              Our business changes constantly, and our Privacy Notice and the
              Terms of Use will change also. You should check our Web site
              frequently to see recent changes. Unless stated otherwise, our
              current Privacy Notice applies to all information that we have
              about you and your account. We stand behind the promises we make,
              however, and will never materially change our policies and
              practices to make them less protective of customer information
              collected in the past without the consent of affected customers.
            </p>
            <p>
              If you have any questions about this privacy statement, the
              practices of this site or your dealings with Sales.support.com,
              please contact Customer Support. Our Privacy Policy is subject to
              change without notice. Please visit this site often to keep up to
              date with our latest policies.
            </p>
            <ul>
              <li>
                Electronic Frontier Foundation &ndash; a civil liberties
                organization working to protect your rights online.
              </li>
              <li>
                Electronic Privacy Information Center &ndash; established to
                focus public attention on emerging civil liberties issues
                relating to the National Information Infrastructure.
              </li>
              <li>
                Privacy Issues Page &ndash; from the Center for Democracy and
                Technology (CDT).
              </li>
              <li>
                The EPIC Cookies Page &ndash; definitions, links, and news.
              </li>
              <li>
                How to Protect Your Privacy &ndash; information from the Federal
                Trade Commission.
              </li>
            </ul>
            <p>
              The Client must (A) maintain a clear and conspicuous link in its
              privacy policy to Plaid&rsquo;s privacy policy located at&nbsp;
              <a href="https://plaid.com/legal">https://plaid.com/legal</a>
              &nbsp;with a clear and conspicuous statement that each End User
              acknowledges and agrees that End User data and information will be
              treated in accordance with such policy, or (B) include a statement
              in the Client&rsquo;s privacy policy that grants Plaid the same
              rights, power, and authority as specified in Plaid&rsquo;s privacy
              policy. The following language will be deemed to comply with the
              preceding sentence:
              <em>
                {" "}
                (&ldquo;Plaid&rdquo;) to gather End User&rsquo;s data from
                financialinstitutions. By using our service, you grant Client
                and Plaid the right, power, andauthority to act on your behalf
                to access and transmit your personal and financialinformation
                from the relevant financial institution. You agree to your
                personal andfinancial information being transferred, stored, and
                processed by Plaid in accordancewith the&nbsp;
              </em>
              <a href="https://plaid.com/legal">
                <em>Plaid Privacy Policy</em>
              </a>
              <em>.</em>
            </p>

            <h2>
              <strong>Subscription Policy</strong>
            </h2>
            <ul>
              <li>We charge $99.99 for monthly subscription.</li>
              <li>
                First month is free and You won't be charged until after your
                first free month.
              </li>
              <li>We will remind you before your trial ends.</li>
              <li>
                You can cancel at anytime! In order to that, we will cancel your
                subscription at the end of your current subscription cycle
              </li>
              <li>
              To cancel subscription please login to your account and click settings from the left side menu, then payment and then cancel subscription, there is no policy to cancel via Phone or Email.
              </li>
              <li>
                There will be no refund after we have charged you for your
                monthly subscription!
              </li>
            </ul>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          </div>
        </Col>
      </Row>
    </TermsWrapper>
  );
};
export default Index;
