import Styled from "styled-components";
const ContactWrapper = Styled.div`
    /*=============================================
=            Sales Form            =
=============================================*/
.sales-form {
  max-width: 680px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 15px;
  box-sizing: border-box;
  padding-top: 120px;
  text-align: center;
  h2 {
    font-size: 32px;
    line-height: 44px;
    margin: 0;
  }
  .info {
    font-size: 16px;
    line-height: 22px;
    padding: 15px 0px 25px 0px;
  }
  .form-section {
    max-width: 420px;
    width: 100%;
    margin: 0 auto;
    .form-control {
      border: 1px solid #E7E8EC;
      box-sizing: border-box;
      border-radius: 2px;
      font-size: 14px;
      line-height: 19px;
      color: #99A0A3;
      margin-bottom: 15px;
      height: 60px;
      padding: 10px 20px;
    }
    textarea {
      min-height: 135px;
      &.form-control {
        padding: 20px;
      }
    }
    .improve-myself {
      padding: 15px 0px 20px 0px;
      button, input {
        @include purple-btn;
        min-width: 100%;
        font-size: 16px;
      }
    }
  }
  .purple-btn{
    background: #4E70E8;
          box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(78, 112, 232, 0.12), 0px 16px 24px rgba(78, 112, 232, 0.2);
          border-radius: 4px;
          font-weight: 600;
          padding:20px 15px;
          margin-bottom: 5px;
          width:100%;
          font-size: 14px;
          line-height: 1.302vw;
          text-transform: uppercase;
          color: white;
          border: 0;
          -webkit-transition: 0.5s;
          -moz-transition: 0.5s;
          transition: 0.5s;
          display: block;
          text-align: center;
  &:hover {
    background: #4E70E8;
    color: white;
}
  }
  .copyright {
    font-size: 14px;
    line-height: 19px;
    color: #99A0A3;
    padding-top: 10px;
  }
}
`;
export { ContactWrapper };
