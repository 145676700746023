import React, { useEffect, useState, useRef } from "react";
import { InputGroup, Form, Row, Col, ToggleButton, Table } from "react-bootstrap";
import { BsTrash, BsPrinter } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import { buildStyles } from "react-circular-progressbar";
import {
  Popover,
  Spin,
  Tooltip,
  Popconfirm,
  Divider,
  Steps,
  notification,
  Modal,
  Icon,
  Select as LocationSelect
} from "antd";
import moment from "moment";
import {
  debounce, findIndex, cloneDeep, truncate, isEmpty, find, isObject, findKey, isUndefined, isNull, isFinite, padStart,
  trim, ceil, capitalize, replace
} from "lodash";
import { generateStorageBarcode } from "../../../utils/locationLabels";
import {
  GetToReceivePendingPOs,
  GetUsersList,
  GetPoReceivingHistory,
  DeleteOutboundShipment,
  CreateOutboundShipment,
  GetShipmentOldBoxes,
  GetReceivingPoDetails,
  GetSkuStatus,
  ReceiveGatedPo,
  ReceiveStoragePo,
  DeleteStoragePo,
  Get2DLabels,
  GetLooseBoxDetails,
  GetMix2DLabels,
  GetWorkingShipments,
  DeleteShipment,
  GetAllLocations
} from "../../../slices/fba-inbound/to-receive";
import {
  GetStorageUsersList
} from "../../../slices/fba-inbound/to-check-in";
import { UpdatePoCompleteStatus } from "../../../slices/fba-inbound/to-check-in";
import { GetStoragePos } from "../../../slices/fba-inbound/storage-pos";
import newLabelVersion from "../../../actions/utils/mix2DLablePdf";
import { setField, GetStats, updateSkuStatus, clearField, updateItemDimensions } from "../../../slices/fba-inbound";
import { useSelector, useDispatch } from "react-redux";
import { CONDITION_TYPE } from "../../../constants/products";
import thirtyUpLabels from "../../../utils/utils";
import zebraLabels from "../../../utils/zebraLabels";
//style
import { TilesWrapper, DrawerWrapper } from "../style";
//component
import TilesPagination from "../../../components/uiComponent/tilesPagination/index";
import Select from "../../../components/uiComponent/select/index";
import ButtonGroup from "../../../components/uiComponent/buttonGroup/index";
import Input from "../../../components/uiComponent/inputs/labelInput/index";
import DatePicker from "../../../components/uiComponent/datePicker/index";
import Button from "../../../components/uiComponent/button/index";
import CheckBox from "../../../components/uiComponent/checkBox/index";
import SearchInput from "../../../components/uiComponent/inputs/searchinput/index";
import Drawer from "../../../components/uiComponent/drawer/index";
import AgGrid from "../../../components/ag-grid-table/index";
import Switch from "../../../components/uiComponent/switch/index";
import AddtoStorageModal from "../../../components/addToStorageModal";
//assets
import IconInfo from "../../../assets/images/newImages/icon-info.svg";
import HistoryNew from "../../../assets/images/newImages/history-new.svg";
//icons
import { BsArrowRightCircle, BsFillArrowRightCircleFill, BsInfoCircle } from "react-icons/bs";
import NoInbound from "../../../assets/images/newImages/no-inbound.svg";
import FulfillPos from "./fulfillPos";
import PutToStorage from "./putToStorage";
import StoragePOs from "./storagePOs";
import { nonPartneredCarriers } from "../constants";

const PrintLabelsAction = ({
  fnsku,
  isStorageLabel,
  staging_item,
  handlePrintAdditionalLabels,
  setCurrentPoIndex,
  isValidExpiryDate,
  index,
}) => {
  const [labelQty, setLabelQty] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const disablePrintLabelBtn = !labelQty || !isFinite(Number(labelQty)) || labelQty <= 0;

  const clearLabelStates = () => {
    setLabelQty("");
    setExpiryDate("");
  };

  return (
    <Popover
      placement="bottom"
      content={
        <div>
          <h6 className="mb-0 fs-14">Print Label</h6>
          <Divider className="my-2" />
          <Row>
            <Col md={6}>
              <Input
                label="No. of Labels"
                className="h-32"
                value={labelQty}
                onChange={({ target }) => {
                  setLabelQty(target.value);
                }}
              />
            </Col>
            <Col md={6}>
              <DatePicker
                label="Expiry Date"
                type="date"
                min={moment().add(1, 'days').format('YYYY-MM-DD')}
                className="h-32"
                value={expiryDate}
                onChange={({ target }) => setExpiryDate(target.value)}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end gap-3">
            {isStorageLabel ? (
              <Button
                text="Print Storage Labels"
                className="primary"
                disabled={disablePrintLabelBtn}
                onClick={() => {
                  if (!isValidExpiryDate(expiryDate)) return;
                  handlePrintAdditionalLabels({ isStorageLabel, staging_item, labelQty, expiryDate });
                  clearLabelStates();
                }}
              />
            ) : (
              <>
                <Button
                  text="Zebra Labels"
                  className="primary"
                  disabled={disablePrintLabelBtn}
                  onClick={() => {
                    if (!isValidExpiryDate(expiryDate)) return;
                    handlePrintAdditionalLabels({ isZebra: true, labelQty, expiryDate });
                    clearLabelStates();
                  }}
                />
                <Button
                  text="30 UP Labels"
                  className="primary"
                  disabled={disablePrintLabelBtn}
                  onClick={() => {
                    if (!isValidExpiryDate(expiryDate)) return;
                    handlePrintAdditionalLabels({ isZebra: false, labelQty, expiryDate });
                    clearLabelStates();
                  }}
                />
              </>
            )}
          </div>
        </div>
      }
      trigger={isStorageLabel || fnsku ? "click" : ""}
    >
      <Tooltip title={isStorageLabel || fnsku ? `Print ${isStorageLabel ? "Storage" : "Product"} Labels` : "No FNSKU"}>
        <div style={{ display: "inline-block" }}>
          <Button
            className="icon-button"
            text={<span class="icon-printer success-color fs-16" />}
            disabled={isStorageLabel ? false : !fnsku}
            onClick={() => {
              if (!isUndefined(index)) setCurrentPoIndex(index);
              clearLabelStates();
            }}
          />
        </div>
      </Tooltip>
    </Popover>
  );
};
const { Step } = Steps;
const stepTitle = ["Input Quantities", "Fulfill POs", "Put to Storage"]
const ToReceive = (props) => {
  const navigate = useNavigate();
  const poSearchRef = useRef();
  const poSearch1Ref = useRef();
  const storageRef = useRef();
  const { scrollHight } = props;
  const tilesHeader = ["Title", "Units", "Status", "Dimensions", "FNSKU", "PO #", "Client Name", ""];
  const shipmentHeader = [
    "Shipment ID",
    "FC ID",
    "POs",
    "SKUs",
    "Bundles",
    "Progress",
    "User",
    "Shipment From",
    "Shipment Type",
    "Carrier Type",
    "Created",
    ""
  ];
  const [recevingHistory, setReceiveHistory] = useState(false);
  const [toReceive, setToReceive] = useState(false);
  const [radioValue, setRadioValue] = useState("1");
  const [filters, setFilters] = useState([]);
  const [page, setPage] = useState({ pageSize: 25, pageNumber: 1 });
  const [currentPoIndex, setCurrentPoIndex] = useState(-1);
  const [currentBoxCategoryId, setCurrentBoxCategoryId] = useState({});
  const [itemDimensions, setItemDimensions] = useState({ height: "", width: "", length: "", weight: "" });
  const [boxDimensions, setBoxDimensions] = useState({});
  const [currentExpiryDate, setCurrentExpiryDate] = useState("");
  const [currentWorkingQuantity, setCurrentWorkingQuantity] = useState("");
  const [currentCaseQuantity, setCurrentCaseQuantity] = useState("");
  const [currentBoxQuantity, setCurrentBoxQuantity] = useState("");
  const [currentBundleQuantity, setCurrentBundleQuantity] = useState("");
  const [storageQuantities, setStorageQuantities] = useState({ sellable: "", unSellable: "" });
  const [showCreateBoxCategory, setShowCreateBoxCategory] = useState(false);
  const [isCompleteBox, setIsCompleteBox] = useState(false);
  const [currentOldBoxId, setCurrentOldBoxId] = useState({});
  const [currentShipment, setCurrentShipment] = useState({ shipmentId: "", poJoinShipmentItemsId: "", isSpPartnered: false });
  const [currentLabelType, setCurrentLabelType] = useState({ value: "Zebra", label: "Zebra Labels" });
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedVendor, setSelectedVendor] = useState("");
  const [addtoStorage, setAddtoStorage] = useState(false);
  const [currentMode, setCurrentMode] = useState("shipment");
  const [currentStep, setCurrentStep] = useState(0);
  const [userList, setUsersList] = useState([]);
  const [locationsList, setLocationsList] = useState([]);

  const dispatch = useDispatch();
  const {
    toReceive: {
      toReceivePendingPos,
      fetchingToReceivePendingPOs,
      posTotalCount,
      completedPosCount,
      usersList,
      fetchingUsersList,
      poReceivingHistory,
      fetchingPoReceivingHistory,
      deletingOutboundShipment,
      creatingOutboundShipment,
      fetchingShipmentOldBoxes,
      shipmentOldBoxes,
      newBoxIndex,
      fetchingReceivingPoDetails,
      receivingPoDetails,
      fetchingSkuStatus,
      receivingGatedPo,
      receivingStoragePo,
      deletingStoragePo,
      fetching2dLabels,
      fetchingLooseBoxDetails,
      fetchingMix2dLabels,
      fetchingWorkingShipments,
      workingShipments,
      shipmentsTotalCount,
      deletingShipment,
      fetchingAllLocations,
      creatingNewLocation,
      addingToStorage
    },
    storagePo: {
      completedCount,
    },
    toCheckIn: {
      vendorsAddressList,
      fetchingVendorsList,
      checkInLoading,
      getVendorsLoading
    }
  } = useSelector((state) => state.fbaInbound || {});

  useEffect(() => {
    const { pathname, search } = window.location || {};
    const query = new URLSearchParams(search);
    const mode = query.get("mode");
    if (mode) setCurrentMode(mode);
    else {
      query.set("mode", currentMode);
      const updatedSearchQuery = query.toString();
      navigate(`${pathname}?${updatedSearchQuery}`, { replace: true });
    }

    const po = query.get("po");
    if (!isEmpty(po)) {
      setCurrentMode('product');
      query.set("mode", 'product');
      const updatedSearchQuery = query.toString();
      navigate(`${pathname}?${updatedSearchQuery}`, { replace: true });
      handleFilter({ isDropDown: false, field: "", value: po, isFirstSearch: true }, null, 'product');
    }
    else {
      if (mode === 'shipment' || (!mode && currentMode === 'shipment')) {
        const filter = { field: 'working_shipments', type: 'drop-down', value: true };

        const shipments = query.get("shipments");
        if (isEmpty(shipments)) {
          setFilters([filter]);
          dispatch(GetWorkingShipments({ filters: [filter], page }));
        }
        else {
          poSearch1Ref.current.value = shipments;
          handleFilter({ isDropDown: false, field: "", value: shipments, isFirstSearch: false }, filter);
        }
      }
      else if (mode === 'product' || (!mode && currentMode === 'product')) {
        const pos = query.get("pos");
        if (isEmpty(pos)) dispatch(GetToReceivePendingPOs({ filters, page }));
        else {
          poSearchRef.current.value = pos;
          handleFilter({ isDropDown: false, field: "", value: pos, isFirstSearch: true });
        }
      }
      else {
        dispatch(GetStoragePos({ filters, page }));
      }
    }

    dispatch(GetUsersList());
  }, []);

  const {
    bundle_quantity,
    id: pending_po_id,
    order_quantity,
    pending_po_index: {
      // id,
      last_checked_in_at,
      last_shipment_created_at,
      storage_status,
      po_code,
      note,
      user_id,
      user: { first_name, last_name, company: { name: company_name } = {} } = {},
    } = {},
    received_quantity,
    working_quantity,
    working_units,
    vendoritem,
    arrival_items,
    vendoritem_id
  } = toReceivePendingPos?.[currentPoIndex] || {};
  const {
    packcount,
    title,
    seller_sku,
    vendor_upc,
    vendorasin: { asin, height, length, medium_image, name: product_title, small_image, upc, weight, width } = {},
  } = vendoritem || {};
  const missingQuantity = Number(order_quantity) < Number(received_quantity) ? 0 : Number(order_quantity) - Number(received_quantity);
  const { fnsku, item_condition, item_name, prepinstruction, sku, sku_status } = seller_sku || {};
  const user_name = `${first_name ?? ""} ${last_name ?? ""}`;
  const getFormattedDate = (date, removeTime) =>
    date ? moment(date).format(`DD MMM, YYYY ${removeTime ? "" : "HH:mm"}`) : "N/A";

  const handleFilter = debounce(({ isDropDown, field, value, isFirstSearch }, defaultFilter, defaultMode) => {
    const filtersArray = cloneDeep(filters);
    const type = isDropDown ? "drop-down" : isFirstSearch ? "search" : "search1";
    const filterIndex = findIndex(filtersArray, { field, type });

    const query = new URLSearchParams(window.location.search);
    const pos = query.get('pos');
    const po = query.get('po');
    const shipments = query.get('shipments');

    const { pathname } = window.location || {};

    if (filterIndex === -1) {
      if (value) {
        filtersArray.push({ field, type, value });
      }
    } else if (value) {
      filtersArray[filterIndex].value = value;
      if (
        (pos && type === 'search') ||
        (po && type === 'search') ||
        (shipments && type === 'search1')
      ) {
        const query_param = pos ? 'pos' : po ? 'po' : 'shipments';
        query.set(query_param, value);
        const updatedSearchQuery = query.toString();
        window.history.pushState('', '', `${pathname}?${updatedSearchQuery}`);
      }
    } else {
      filtersArray.splice(filterIndex, 1);
      if (
        (pos && type === 'search') ||
        (po && type === 'search') ||
        (shipments && type === 'search1')
      ) {
        const query_param = pos ? 'pos' : po ? 'po' : 'shipments';
        query.set(query_param, '');
        let updatedSearchQuery = query.toString();
        updatedSearchQuery = replace(updatedSearchQuery, `${query_param}=&`, '');
        window.history.pushState('', '', `${pathname}?${updatedSearchQuery}`);
      }
    }

    if (!isEmpty(defaultFilter)) filtersArray.push(defaultFilter);

    setFilters(filtersArray);
    const newPaginationHash = { ...page, pageNumber: 1 };
    setPage(newPaginationHash);

    const mode = defaultMode || currentMode;
    if ((defaultMode === 'product') && po) {
      poSearchRef.current.value = po;
    }

    mode === 'shipment' ? dispatch(GetWorkingShipments({ filters: filtersArray, page: newPaginationHash })) :
      mode === 'product' ? dispatch(GetToReceivePendingPOs({ filters: filtersArray, page: newPaginationHash })) :
        dispatch(GetStoragePos({ filters: filtersArray, page: newPaginationHash }));
  }, 500);

  const defaultColDef = {
    resizable: true,
    sortable: true,
    suppressMenu: true,
    suppressMovable: true,
  };

  const handleUpdateReceivedQuantity = ({ value }) => {
    dispatch(
      setField({
        module: "toReceive",
        field: "toReceivePendingPos",
        key: "received_quantity",
        index: currentPoIndex,
        value,
      })
    );
  };

  const handleUpdateBundleQuantity = () => {
    dispatch(
      setField({
        module: "toReceive",
        field: "toReceivePendingPos",
        key: "bundle_quantity",
        index: currentPoIndex,
        value: currentBundleQuantity,
      })
    );
  };

  const handleUpdateWorkingUnits = ({ value }) => {
    dispatch(
      setField({
        module: "toReceive",
        field: "toReceivePendingPos",
        key: "working_units",
        index: currentPoIndex,
        value,
      })
    );
  };

  const handleUpdateItemDimensions = () => {
    dispatch(
      updateItemDimensions({
        module: "toReceive",
        field: "toReceivePendingPos",
        index: currentPoIndex,
        value: itemDimensions,
      })
    );
  };

  const handleDeleteReceivingHistory = ({ outbound_shipment_id, sellable_quantity, arrival_item_id }) => {
    if (!isUndefined(outbound_shipment_id) && !isNull(outbound_shipment_id)) {
      dispatch(
        DeleteOutboundShipment({
          outbound_shipment_id,
          pending_po_id,
        })
      ).then(({ payload }) => {
        const { data } = payload || {};
        const { success, result } = data || {};
        if (success) {
          const { received_quantity, working_units } = result || {};
          handleUpdateReceivedQuantity({ value: received_quantity });
          handleUpdateWorkingUnits({ value: working_units });
          dispatch(
            GetPoReceivingHistory({
              pending_po_id,
              storage_status,
            })
          );
        }
      });
    } else {
      dispatch(DeleteStoragePo(arrival_item_id)).then(({ payload }) => {
        const { data } = payload || {};
        const { success } = data || {};
        if (success) {
          // handleUpdateReceivedQuantity({ value: received_quantity - sellable_quantity });
          dispatch(
            GetPoReceivingHistory({
              pending_po_id,
              storage_status,
            })
          );
          dispatch(
            setField({
              module: "toReceive",
              field: "toReceivePendingPos",
              index: currentPoIndex,
              key: "working_quantity",
              value: working_quantity - sellable_quantity,
            })
          );
        }
      });
    }
  };

  const isValidExpiryDate = (expiryDate) => {
    if (expiryDate && (moment(expiryDate).format('YYYY-MM-DD') < moment().add(1, 'days').format('YYYY-MM-DD'))) {
      notification.error({
        top: 65,
        message: "Expiry Date Must Be Of Future",
      });
      return false;
    }
    return true;
  };

  const storagePoHistoryColDef = [
    {
      headerName: "Date",
      field: "created_at",
      cellRenderer: ({ value }) => getFormattedDate(value),
      width: 150,
    },
    {
      headerName: "Received By",
      cellRenderer: ({ data }) => {
        const { receiver } = data || {};
        const { first_name, last_name } = receiver || {};
        return !isEmpty(receiver) ? `${first_name || ""} ${last_name || ""}` : "N/A";
      },
      width: 180,
    },
    {
      headerName: "Quantity",
      cellRendererFramework: ({ data }) => {
        const { sellable_quantity, unsellable_quantity, stored_quantity } = data || {};
        return (
          <span className="d-flex gap-3">
            <span className="d-flex flex-column">
              {sellable_quantity || 0}
              <span className="fs-10 color-secondary">Sellable</span>
            </span>
            <span className="d-flex flex-column">
              <span className="">{unsellable_quantity || 0}</span>
              <span className="fs-10 color-secondary">Un Sellable</span>
            </span>
            <span className="d-flex flex-column">
              <span className="">{stored_quantity || 0}</span>
              <span className="fs-10 color-secondary">On Location</span>
            </span>
          </span>
        );
      },
      width: 300,
    },
    {
      headerName: "Fulfilled Pos",
      cellRendererFramework: ({ data }) => {
        const { distro_storage_items } = data || {};

        return (
          <div style={{ color: "#1974D2" }}>
            <Popover
              title={<b>Fulfilled Pos Detail:</b>}
              content={
                <div>
                  {distro_storage_items?.map((item, index) => {
                    const { quantity, distro_po } = item || {};
                    const { po_code } = distro_po?.pending_po_index || {};

                    return (
                      <div key={`fulfilled_po_${index + 1}`}>
                        <p>
                          <b>PO:</b> {po_code}, <b>Fulfilled QTY:</b> {quantity}{" "}
                        </p>
                      </div>
                    );
                  })}
                </div>
              }
            >
              {distro_storage_items?.length || 0}
            </Popover>
          </div>
        );
      },
      width: 130,
    },
    {
      headerName: "Expiry",
      field: "expired_date",
      cellRenderer: ({ value }) => getFormattedDate(value, true),
      width: 180,
    },
    {
      headerName: "Location",
      cellRendererFramework: ({ data }) => {
        const { storages } = data?.staging_item || {};
        if (!isEmpty(storages) && storages?.length > 0) {
          const locationNames = storages?.map((item) => item?.name) || [];
          const warehouseNames =
            storages?.map(
              (item) => `${item?.warehouse_user?.first_name || ""} ${item?.warehouse_user?.last_name || ""}`
            ) || [];
          const splitedLocationNames = locationNames.toString();
          const splitedWarehouseNames = warehouseNames.toString();

          return (
            <div style={{ color: "#1974D2" }}>
              <Popover
                title="Location Details: "
                content={
                  <div>
                    {storages?.map((item, index) => {
                      const {
                        staged_storages: { quantity_stored },
                        name,
                        warehouse_user,
                      } = item || {};
                      return (
                        <div key={index + 1}>
                          <p>
                            <b>Name:</b> {name}, <b>QTY:</b> {quantity_stored}, <b>Warehouse:</b>{" "}
                            {warehouse_user?.first_name || ""} {warehouse_user?.last_name || ""}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                }
              >
                <span className="text-truncate d-flex flex-column">
                  <span>{splitedLocationNames}</span>
                  <span>{splitedWarehouseNames}</span>
                </span>
              </Popover>
            </div>
          );
        } else return <div>N/A</div>;
      },
      width: 300,
    },
    {
      headerName: "",
      cellStyle: { "justify-content": "flex-end" },
      cellRendererFramework: ({ data }) => {
        const { picked_up, id: arrival_item_id, sellable_quantity, staging_item, distro_storage_items } = data || {};
        const { storages = [] } = staging_item || {};
        const isStorageExist = storages?.length;
        const fulfilledPoCount = distro_storage_items?.length;
        const isStagingItemExist = !isEmpty(staging_item);

        return (
          <span className="color-danger text-end d-flex gap-3 align-items-center">
            <PrintLabelsAction
              fnsku={fnsku}
              isStorageLabel={true}
              staging_item={staging_item}
              isValidExpiryDate={isValidExpiryDate}
              handlePrintAdditionalLabels={handlePrintAdditionalLabels}
            />

            <Popconfirm
              key="delete_storage_po_receive_history"
              placement="left"
              title="Are you sure you want to delete receive history ?"
              onConfirm={() => handleDeleteReceivingHistory({ sellable_quantity, arrival_item_id })}
              okText="Yes"
              cancelText="No"
              disabled={picked_up || isStorageExist || fulfilledPoCount || isStagingItemExist}
            >
              <Tooltip
                title={(picked_up || isStorageExist || fulfilledPoCount || isStagingItemExist) ?
                  "Receive History Is Not Deletable" : "Delete Receive History"}
              >
                <BsTrash className="fs-12" style={(picked_up || isStorageExist || fulfilledPoCount || isStagingItemExist) ? { opacity: 0.5 } : {}} />
              </Tooltip>
            </Popconfirm>
          </span>
        );
      },
      width: 100,
    },
  ];

  const getShipmentLabelFromAmazon = ({
    inbound_shipment_id,
    pdfDoc,
    shipment_id,
    isMix2D,
    outbound_shipment_id,
    PageSize,
    PageStartIndex,
  }) => {
    const name = `${(isMix2D ? inbound_shipment_id : shipment_id) || "CONTENT_LABEL"}.pdf`;

    const byteString = atob(pdfDoc.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ia], {
      type: "application/pdf",
    });

    const data = new FormData();
    data.append("file", blob, name);
    data.append("PageSize", PageSize);
    data.append("PageStartIndex", PageStartIndex);

    if (isMix2D) {
      dispatch(
        GetMix2DLabels({
          data,
          inbound_shipment_id,
        })
      );
    } else {
      dispatch(
        Get2DLabels({
          outbound_shipment_id,
          data,
        })
      );
    }
  };

  const handleReceiveModalOpen = (
    pending_po_id, height, width, length, weight, bundle_quantity, working_quantity, storage_status, index
  ) => {

    setToReceive(true);
    setCurrentPoIndex(index);
    working_quantity && setCurrentStep(1);
    !storage_status &&
      dispatch(GetSkuStatus(pending_po_id)).then(({ payload }) => {
        const { data } = payload || {};
        const { success, result } = data || {};
        if (success) {
          if (!isEmpty(result)) {
            dispatch(
              updateSkuStatus({
                module: "toReceive",
                field: "toReceivePendingPos",
                index: index,
                value: result?.vendoritem?.seller_sku?.sku_status,
              })
            );
            if (result?.vendoritem?.seller_sku?.sku_status?.toLowerCase() === "ok") {
              handleGetReceivingPoDetails(pending_po_id);
            }
          }
        }
      });
    setItemDimensions({ height, width, length, weight });
    setCurrentBundleQuantity(bundle_quantity);
  };

  const getShipmentLabelPdf = (outbound_shipment) => {
    const { looseBoxDetails, box_qty, case_qty, inbound_shipment_item, expired_date } = outbound_shipment || {};

    const { seller_sku, vendorasin, vendortitle } = vendoritem || {};
    const { fnsku, item_name } = seller_sku || {};
    const { name } = vendorasin || {};

    const { inbound_shipment } = inbound_shipment_item || {};
    const { shipment_id: shipmentId, shipment_name, created_at } = inbound_shipment || {};

    let exp = null;
    if (expired_date) {
      exp = moment(expired_date, "YYYY-MM-DD").format("YYMMDD");
    }

    const items = [{ fnsku, qty: case_qty, exp }];
    const commonPdfData = {
      shipmentId,
      userName: user_name,
      shipmentName: shipment_name,
      dateCreated: moment(created_at, "MMMM DD, YYYY").format("DD/MM/YY"),
    };

    const pdfDoc = newLabelVersion(
      isEmpty(looseBoxDetails)
        ? {
          ...commonPdfData,
          items,
          numberOfBox: box_qty,
          title: name || item_name || vendortitle,
          is2D: true,
        }
        : {
          ...commonPdfData,
          items: looseBoxDetails,
        }
    );

    return pdfDoc;
  };

  const handlePrint2DLabels = ({
    shipment_id,
    PageSize,
    PageStartIndex,
    isMix2D,
    inbound_shipment_item,
    looseBoxId,
    outbound_shipment_id,
    case_qty,
    box_qty,
    expired_date,
  }) => {
    let looseBoxDetails = [];
    if (isMix2D) {
      dispatch(GetLooseBoxDetails(looseBoxId)).then(({ payload }) => {
        const { data } = payload || {};
        const { success, itemDetails } = data || {};
        if (success) {
          looseBoxDetails =
            itemDetails?.map((item) => {
              const { expired_date, working_quantity, pending_po, ppo_bundle_quantity } = item || {};
              const { vendoritem } = pending_po || {};
              const { seller_sku } = vendoritem || {};
              const { fnsku } = seller_sku || {};

              return {
                fnsku,
                qty: working_quantity,
                exp: expired_date,
              };
            }) || [];

          const pdfDoc = getShipmentLabelPdf({
            inbound_shipment_item,
            looseBoxDetails,
          });

          if (pdfDoc) {
            const { inbound_shipment_id } = inbound_shipment_item || {};
            getShipmentLabelFromAmazon({
              inbound_shipment_id,
              pdfDoc,
              isMix2D: true,
              PageSize,
              PageStartIndex,
            });
          }
        }
      });
    } else {
      const pdfDoc = getShipmentLabelPdf({
        box_qty,
        case_qty,
        inbound_shipment_item,
        expired_date,
      });

      if (pdfDoc) {
        getShipmentLabelFromAmazon({
          shipment_id,
          PageSize,
          PageStartIndex,
          outbound_shipment_id,
          pdfDoc,
        });
      }
    }
  };

  const poHistoryColDef = [
    {
      headerName: "Date",
      field: "created_at",
      cellRenderer: ({ value }) => getFormattedDate(value),
      width: 150,
    },
    {
      headerName: "Received By",
      cellRenderer: ({ data }) => {
        const { receiver } = data || {};
        const { first_name, last_name } = receiver || {};
        return !isEmpty(receiver) ? `${first_name || ""} ${last_name || ""}` : "N/A";
      },
      width: 160,
    },
    {
      headerName: "Quantity",
      cellRendererFramework: ({ data }) => {
        const { working_quantity, box_qty, case_qty, loose_box, ppo_bundle_quantity } = data || {};
        return (
          <span className="d-flex gap-3">
            {loose_box ? (
              <span className="d-flex flex-column">
                <span className="">{(working_quantity * ppo_bundle_quantity) || 0}</span>
                <span className="fs-10 color-secondary">Quantity</span>
              </span>
            ) : (
              <>
                <span className="d-flex flex-column">
                  {box_qty || 0}
                  <span className="fs-10 color-secondary">Boxes</span>
                </span>
                <span className="d-flex flex-column">
                  <span className="">{(case_qty * ppo_bundle_quantity) || 0}</span>
                  <span className="fs-10 color-secondary">In a Box</span>
                </span>
              </>
            )}
          </span>
        );
      },
      width: 120,
    },
    {
      headerName: "Total Units",
      cellRenderer: ({ data }) => {
        const { working_quantity, box_qty, case_qty, loose_box, ppo_bundle_quantity } = data || {};
        if (loose_box) return ((working_quantity * ppo_bundle_quantity) || 0);
        else return (box_qty * case_qty * ppo_bundle_quantity) || 0;
      },
      width: 78,
    },
    {
      headerName: "Total Bundles",
      cellRenderer: ({ data }) => {
        const { working_quantity, box_qty, case_qty, loose_box } = data || {};
        if (loose_box) return (working_quantity || 0);
        else return (box_qty * case_qty) || 0;
      },
      width: 92,
    },
    {
      headerName: "Pack Of",
      field: "ppo_bundle_quantity",
      cellRenderer: ({ value }) => (value ? value : "1"),
      width: 65,
    },
    {
      headerName: "Shipment",
      cellRendererFramework: ({ data }) => {
        const { inbound_shipment_item } = data || {};
        const { inbound_shipment, inbound_shipment_id } = inbound_shipment_item || {};
        const { shipment_id, shipment_name } = inbound_shipment || {};

        if (!shipment_id) return <div>N/A</div>;
        return (
          <span className="d-flex flex-column">
            <a
              className="text-decoration-none fw-bold"
              href={`${window?.location?.origin}/fba-inbound/transportPlan/${shipment_id}`}
              target="_blank"
            >
              <span className="pr-1 color-primary">{shipment_id}</span>
            </a>
            <span className="fs-10 color-secondary">{shipment_name || "N/A"}</span>
          </span>
        );
      },
      width: 160,
    },
    // {
    //   headerName: "Box Category",
    //   cellRenderer: ({ data }) => {
    //     const { boxes } = data || {};
    //     const { box_category } = boxes?.[0] || {};
    //     const { name } = box_category || {};

    //     return name ? name : "N/A";
    //   },
    //   width: 150,
    // },
    {
      headerName: "Box Dimensions",
      cellRenderer: ({ data }) => {
        const { boxes } = data || {};
        const { box_length = '', box_width = '', box_height = '' } = boxes?.[0] || {};
        return (`( ${box_length} x ${box_width} x ${box_height} ) in`);
      },
      width: 150,
    },
    {
      headerName: "Box Weight",
      cellRenderer: ({ data }) => {
        const { boxes } = data || {};
        const { box_weight = '' } = boxes?.[0] || {};
        return (`${box_weight} lb`);
      },
      width: 150,
    },
    {
      headerName: "Expiry",
      field: "expired_date",
      cellRenderer: ({ value }) => getFormattedDate(value, true),
      width: 150,
    },
    {
      headerName: "Location",
      cellRendererFramework: ({ data }) => {
        const { storages } = data?.staging_item || {};
        if (!isEmpty(storages) && storages?.length > 0) {
          const locationNames = storages?.map((item) => item?.name) || [];
          const warehouseNames =
            storages?.map(
              (item) => `${item?.warehouse_user?.first_name || ""} ${item?.warehouse_user?.last_name || ""}`
            ) || [];
          const splitedLocationNames = locationNames.toString();
          const splitedWarehouseNames = warehouseNames.toString();

          return (
            <div style={{ color: "#1974D2" }}>
              <Popover
                title="Location Details: "
                content={
                  <div>
                    {storages?.map((item, index) => {
                      const {
                        staged_storages: { quantity_stored },
                        name,
                        warehouse_user,
                      } = item || {};
                      return (
                        <div key={index + 1}>
                          <p>
                            <b>Name:</b> {name}, <b>QTY:</b> {quantity_stored}, <b>Warehouse:</b>{" "}
                            {warehouse_user?.first_name || ""} {warehouse_user?.last_name || ""}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                }
              >
                <span className="text-truncate d-flex flex-column">
                  <span>{splitedLocationNames}</span>
                  <span>{splitedWarehouseNames}</span>
                </span>
              </Popover>
            </div>
          );
        } else return <div>N/A</div>;
      },
      width: 200,
    },
    {
      headerName: "",
      cellStyle: { "justify-content": "flex-end" },
      cellRendererFramework: ({ data }) => {
        const {
          id: outbound_shipment_id,
          inbound_shipment_item,
          boxes,
          staging_item,
          start_index,
          loose_box,
          case_qty,
          box_qty,
          expired_date,
          ppo_bundle_quantity,
          working_quantity,
        } = data || {};
        const { inbound_shipment } = inbound_shipment_item || {};
        const { shipment_status, shipment_id } = inbound_shipment || {};
        const { storages = [] } = staging_item || {};
        const isDeletable = storages.length
          ? false
          : !shipment_status
            ? true
            : ["CREATING", "WORKING", "ESTIMATED", "ESTIMATING"].includes(shipment_status);

        const commonLabelData = {
          shipment_id,
          isMix2D: loose_box,
          inbound_shipment_item,
          looseBoxId: boxes?.[0]?.box_join_outbound_shipment?.box_id,
          outbound_shipment_id,
          case_qty,
          box_qty,
          expired_date,
        };

        return (
          <span className="color-danger text-end d-flex gap-3 align-items-center">
            <Popover
              placement="bottom"
              content={
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h6 className="mb-0 fs-14">Box Number</h6>
                    <a onClick={() => {
                      handlePrint2DLabels({
                        ...commonLabelData,
                        PageSize: boxes?.length || 1,
                        PageStartIndex: (start_index === 0) ? 0 : (start_index - 1),
                      });
                    }}>
                      <span class="icon-print" /> <span className="pr-1 color-primary">Print All</span>
                    </a>
                  </div>
                  <Divider className="my-2" />
                  <div className="overflow-auto" style={{ maxHeight: 400 }}>
                    {boxes?.map((box, index) => {
                      const amazonShipmentBoxOrder = start_index + index;

                      return (
                        <div key={`${index}_amazon_box_order`} className="d-flex justify-content-between align-items-center mb-2">
                          <span className="pe-4">{`${shipment_id}U${padStart(amazonShipmentBoxOrder, 7, '0')}`}</span>
                          <span class="icon-print cursor-pointer" onClick={() => {
                            handlePrint2DLabels({
                              ...commonLabelData,
                              PageSize: 1,
                              PageStartIndex: (amazonShipmentBoxOrder === 0) ? 0 : (amazonShipmentBoxOrder - 1),
                            });
                          }} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              }
              trigger={shipment_id ? "click" : ''}
            >
              <Tooltip title={shipment_id ? 'Print Shipping Labels' : 'No Shipment Attached'}>
                <div style={{ display: 'inline-block' }}>
                  <Button className="icon-button" disabled={!shipment_id} text={<span class="icon-shipping fs-16" />} />
                </div>
              </Tooltip>
            </Popover>

            <PrintLabelsAction
              fnsku={fnsku}
              isStorageLabel={shipment_id ? false : true}
              staging_item={staging_item}
              isValidExpiryDate={isValidExpiryDate}
              handlePrintAdditionalLabels={handlePrintAdditionalLabels}
            />
            <Popconfirm
              key="delete_po_receive_history"
              placement="left"
              title="Are you sure you want to delete receive history ?"
              onConfirm={() => handleDeleteReceivingHistory({ outbound_shipment_id })}
              okText="Yes"
              cancelText="No"
              disabled={!isDeletable}
            >
              <Tooltip title={isDeletable ? "Delete Receive History" : "Receive History Is Not Deletable"}>
                <BsTrash className="fs-16" style={isDeletable ? {} : { opacity: 0.5 }} />
              </Tooltip>
            </Popconfirm>
          </span>
        );
      },
      width: 200,
    },
  ];

  const handlePagination = () => {
    const newPaginationHash = { ...page, pageNumber: page.pageNumber + 1 };
    setPage(newPaginationHash);

    (currentMode === 'shipment') ? dispatch(GetWorkingShipments({ filters, page: newPaginationHash })) :
      (currentMode === 'product') ? dispatch(GetToReceivePendingPOs({ filters, page: newPaginationHash })) :
        dispatch(GetStoragePos({ filters, page: newPaginationHash }));
  };

  const radios = [
    { name: "Complete Box", value: "1" },
    { name: "Loose Box", value: "2" },
  ];

  const mode = [
    { name: "Shipment Mode", value: "shipment" },
    // { name: "Product Mode", value: "product" },
    // { name: "Storage PO", value: "storage" },
  ];

  const users =
    usersList?.map(({ id, first_name, last_name, email, company: { name } }) => {
      return {
        value: id,
        label: (
          <>
            <h3 className="fs-14 mb-0">{first_name + last_name}</h3>
            <span className="fs-12 color-grey d-block">{`${name} `}</span>
            <span className="fs-12 color-grey">{`${email} `}</span>
          </>
        ),
      };
    }) || [];

  const vendors =
    vendorsAddressList?.map(({ name, addresses }) => {
      const {
        id,
        title,
        address_line_1,
        address_line_2,
        city,
        zipcode,
        state_or_province,
        country,
      } = addresses?.[0] || {};
      return {
        value: id,
        label: (
          <>
            <h3 className="fs-14 mb-0">{name}</h3>
            <span className="fs-12 color-grey">{`${address_line_1}${address_line_2} ${city} ${state_or_province} ${zipcode} ${country} `}</span>
          </>
        ),
      };
    }) || [];

  const boxCategoryOptions =
    receivingPoDetails?.boxCategories?.map(({ id, height, length, name, weight, width }) => {
      return {
        value: id,
        label: `${name} ( ${length} x ${width} x ${height} x ${weight} )`,
      };
    }) || [];

  const oldBoxOptions =
    shipmentOldBoxes?.map(({ id, box_index, box_category }) => {
      return {
        value: id,
        label: `Old Box ${box_index} - ${box_category?.name || "N/A"}`,
      };
    }) || [];

  const handleDimensions = (dimension, isBox) => {
    if (isBox) setBoxDimensions((boxDimensions) => ({ ...boxDimensions, ...dimension }));
    else setItemDimensions((itemDimensions) => ({ ...itemDimensions, ...dimension }));
  };

  const handleClearReceiveModalStates = (isClose) => {
    setIsCompleteBox(false);
    setShowCreateBoxCategory(false);
    setCurrentBoxQuantity("");
    setCurrentCaseQuantity("");
    setCurrentWorkingQuantity("");
    setCurrentExpiryDate("");
    setBoxDimensions({});
    // setCurrentBoxCategoryId({});
    setCurrentOldBoxId({});
    setStorageQuantities({ sellable: "", unSellable: "" });
    if (isClose) {
      setCurrentBundleQuantity("");
      setItemDimensions({ height: "", width: "", length: "", weight: "" });
      setRadioValue("1");
      setCurrentShipment({ shipmentId: "", poJoinShipmentItemsId: "", isSpPartnered: false });
      setCurrentLabelType({ value: "Zebra", label: "Zebra Labels" });
      dispatch(
        clearField({
          module: "toReceive",
          field: "receivingPoDetails",
        })
      );
    }
  };

  const handleClearStorageStates = () => {
    setToReceive(false);
    setStorageQuantities({ sellable: '', unSellable: '' });
    setCurrentExpiryDate('');
    setCurrentStep(0);
    dispatch(
      setField({
        module: "toReceive",
        field: "toReceivePendingPos",
        index: currentPoIndex,
        key: "received_quantity",
        value: received_quantity + working_quantity,
      })
    );
    dispatch(
      setField({
        module: "toReceive",
        field: "toReceivePendingPos",
        index: currentPoIndex,
        key: "working_quantity",
        value: 0,
      })
    );
  };

  const validateDimensions = (dimensions, isBox) => {
    if (sku_status?.toLowerCase() === "ok" && !isBox) {
      if (isFinite(Number(dimensions?.weight)) && !Number(dimensions?.weight)) {
        if (!dimensions?.length) return `Please Enter Item Length`;
        if (!dimensions?.width) return `Please Enter Item Width`;
        if (!dimensions?.height) return `Please Enter Item Height`;

        if (!isFinite(Number(dimensions?.length)) || dimensions?.length <= 0) return `Invalid Item Length`;
        if (!isFinite(Number(dimensions?.width)) || dimensions?.width <= 0) return `Invalid Item Width`;
        if (!isFinite(Number(dimensions?.height)) || dimensions?.height <= 0) return `Invalid Item Height`;
      } else {
        if (!isFinite(Number(dimensions?.weight)) || dimensions?.weight <= 0) return `Invalid Item Weight`;
        if (!isFinite(Number(dimensions?.length)) || dimensions?.length < 0) return `Invalid Item Length`;
        if (!isFinite(Number(dimensions?.width)) || dimensions?.width < 0) return `Invalid Item Width`;
        if (!isFinite(Number(dimensions?.height)) || dimensions?.height < 0) return `Invalid Item Height`;
      }
    } else {
      // if (isBox && !dimensions?.name?.trim()) return "Invalid Box Name";

      if (!dimensions?.length) return `Please Enter ${isBox ? "Box" : "Item"} Length`;
      if (!dimensions?.width) return `Please Enter ${isBox ? "Box" : "Item"} Width`;
      if (!dimensions?.height) return `Please Enter ${isBox ? "Box" : "Item"} Height`;
      if (!dimensions?.weight) return `Please Enter ${isBox ? "Box" : "Item"} Weight`;

      if (!isFinite(Number(dimensions?.length)) || dimensions?.length <= 0)
        return `Invalid ${isBox ? "Box" : "Item"} Length`;
      if (!isFinite(Number(dimensions?.width)) || dimensions?.width <= 0)
        return `Invalid ${isBox ? "Box" : "Item"} Width`;
      if (!isFinite(Number(dimensions?.height)) || dimensions?.height <= 0)
        return `Invalid ${isBox ? "Box" : "Item"} Height`;
      if (!isFinite(Number(dimensions?.weight)) || dimensions?.weight <= 0)
        return `Invalid ${isBox ? "Box" : "Item"} Weight`;
    }
    return "";
  };

  const handleUpdatePoCompleteStatus = ({
    pending_po_index_id,
    marked_as_hide,
    pending_po_id,
    po_code, asin,
    height, width, length, weight, bundle_quantity,
    working_quantity, storage_status
  }) => {
    dispatch(
      UpdatePoCompleteStatus({
        pending_po_index_id,
        marked_as_hide,
      })
    ).then(({ payload }) => {
      const { data } = payload || {};
      const { success } = data || {};
      if (success) {
        const filterNew = [
          {
            field: '',
            type: "search1",
            value: po_code
          }, {
            field: '',
            type: "search",
            value: asin
          }]
        setFilters(filterNew);
        poSearch1Ref.current.value = po_code;
        poSearchRef.current.value = asin;
        dispatch(GetToReceivePendingPOs({ filters: filterNew, page })).then(({ payload }) => {
          const { data } = payload || {};
          const { success, result } = data || {};
          if (success) {
            handleReceiveModalOpen(pending_po_id, height, width, length, weight, bundle_quantity, working_quantity, storage_status, 0)
          }
        });
      }
    });
  };

  const handleGenerateStorageLabels = ({ staging_item, labelQty, expiryDate }) => {
    if (!isEmpty(staging_item)) {
      const { title, uuid, quantity, identifier, warehouse_user } = staging_item || {};
      const warehouseName = isObject(warehouse_user)
        ? !isEmpty(warehouse_user)
          ? `${warehouse_user?.first_name || ""} ${warehouse_user?.last_name || ""}`
          : "N/A"
        : warehouse_user || "N/A";

      generateStorageBarcode({
        warehouseName,
        uuid,
        title,
        stored_quantity: labelQty || quantity,
        identifier,
        labelType: "product",
      });
    }
  };

  const handleGenerateProductLabels = ({ labelQty, expiryDate, isZebra }) => {
    const conditationKey = findKey(CONDITION_TYPE, ({ value }) => value == item_condition);
    const { label } = CONDITION_TYPE[conditationKey] || {};

    let numberOfLabels = 0;
    if (labelQty) {
      numberOfLabels = labelQty;
    } else {
      const receivedQuantity =
        radioValue === "1" ? Number(currentCaseQuantity) * Number(currentBoxQuantity) : Number(currentWorkingQuantity);
      numberOfLabels = receivedQuantity / currentBundleQuantity;
    }

    const labelsData = {
      fnsku,
      title: item_name || product_title,
      condition: label,
      expiryDate: expiryDate ? (moment(expiryDate).format('MM-DD-YYYY') || 'N/A') : (moment(currentExpiryDate).format('MM-DD-YYYY') || 'N/A'),
      packageQuantity: currentBundleQuantity || bundle_quantity,
      numberOfLabels,
      username: user_name,
      companyName: company_name,
      prepInstruction: prepinstruction,
    };

    if (isZebra || (isUndefined(isZebra) && currentLabelType?.value?.includes("Zebra"))) zebraLabels(labelsData);
    else thirtyUpLabels(labelsData);
  };

  const handlePrintAdditionalLabels = ({ isZebra, isStorageLabel, staging_item, labelQty, expiryDate }) => {
    if (isStorageLabel) handleGenerateStorageLabels({ staging_item, labelQty, expiryDate });
    else handleGenerateProductLabels({ labelQty, expiryDate, isZebra });
  };

  const handleSaveStoragePo = () => {
    if (currentStep === 0) {
      dispatch(
        ReceiveStoragePo({
          pending_po_id,
          sellable_quantity: Number(storageQuantities.sellable),
          unsellable_quantity: Number(storageQuantities.unSellable),
          item_dimensions: itemDimensions,
          bundle_quantity: currentBundleQuantity,
          asin,
          expired_date: currentExpiryDate || null
        })
      ).then(({ payload }) => {
        const { data } = payload || {};
        const { success, arrival_item } = data || {};
        if (success) {
          handleUpdateBundleQuantity();
          handleUpdateItemDimensions();
          dispatch(GetStats());
          setCurrentStep(currentStep => currentStep + 1);
          dispatch(
            setField({
              module: "toReceive",
              field: "toReceivePendingPos",
              index: currentPoIndex,
              key: "arrival_items",
              value: [arrival_item],
            })
          );
          dispatch(
            setField({
              module: "toReceive",
              field: "toReceivePendingPos",
              index: currentPoIndex,
              key: "working_quantity",
              value: Number(storageQuantities.sellable),
            })
          );
        }
      });
    }
    else {
      if (working_quantity === 0) {
        setToReceive(false);
        setCurrentStep(0);
        handleClearReceiveModalStates(true);
      }
      else setCurrentStep(currentStep => currentStep + 1);
    }
  };

  const handleReceive = () => {
    if (storage_status) {
      // remove this endpoint from here, its not in use
      dispatch(
        ReceiveStoragePo({
          pending_po_id,
          sellable_quantity: Number(storageQuantities.sellable),
          unsellable_quantity: Number(storageQuantities.unSellable),
          item_dimensions: itemDimensions,
          bundle_quantity: currentBundleQuantity,
          asin,
          expired_date: currentExpiryDate || null
        })
      ).then(({ payload }) => {
        const { data } = payload || {};
        const { success, staging_item } = data || {};
        if (success) {
          handleUpdateReceivedQuantity({ value: Number(received_quantity) + Number(storageQuantities.sellable) });
          handleUpdateBundleQuantity();
          handleUpdateItemDimensions();
          handleGenerateStorageLabels({ staging_item });
          handleClearReceiveModalStates();
          dispatch(GetStats());
        }
      });
    } else {
      if (sku_status?.toLowerCase() === "ok") {
        const commonData = {
          loose_box: radioValue === "2",
          expired_date: currentExpiryDate || null,
          shipment_id: currentShipment?.shipmentId,
          item_dimensions: itemDimensions,
          bundle_quantity: currentBundleQuantity,
          asin,
          po_join_shipment_items_id: currentShipment?.poJoinShipmentItemsId,
          // box_category_id: currentBoxCategoryId?.value === -1 || (currentOldBoxId?.value && currentOldBoxId?.value !== -1)
          //   ? "" : currentBoxCategoryId?.value,
          box_dimensions: boxDimensions,
        };

        dispatch(
          CreateOutboundShipment(
            radioValue === "1"
              ? {
                ...commonData,
                case_qty: Number(currentCaseQuantity),
                box_qty: Number(currentBoxQuantity),
              }
              : {
                ...commonData,
                working_quantity: Number(currentWorkingQuantity),
                is_complete_box: isCompleteBox,
                // old_box_id: (currentOldBoxId?.value === -1) ? '' : currentOldBoxId?.value,
                old_box_id: currentOldBoxId?.value,
              }
          )
        ).then(({ payload }) => {
          const { data } = payload || {};
          const { success, result } = data || {};
          if (success) {
            const {
              received_quantity,
              loose_box_id: looseBoxId,
              outbound_shipment_id,
              page_start_index: PageStartIndex,
              working_units,
            } = result || {};
            const inbound_shipment_item =
              find(receivingPoDetails?.inbound_shipment_items, {
                po_join_shipment_items: { id: currentShipment?.poJoinShipmentItemsId },
              }) || {};

            handleUpdateReceivedQuantity({ value: Number(received_quantity) });
            handleUpdateBundleQuantity();
            handleUpdateItemDimensions();
            handleUpdateWorkingUnits({ value: working_units });

            const commonLabelData = {
              shipment_id: currentShipment?.shipmentId,
              isMix2D: radioValue === "2",
              inbound_shipment_item,
              looseBoxId,
              outbound_shipment_id,
              // case_qty: Number(currentCaseQuantity),
              case_qty: Number(currentCaseQuantity) / Number(currentBundleQuantity),
              box_qty: Number(currentBoxQuantity),
              expired_date: currentExpiryDate || null,
            };

            handlePrint2DLabels({
              ...commonLabelData,
              PageSize: radioValue === "2" ? 1 : Number(currentBoxQuantity) || 1,
              PageStartIndex,
            });

            if (!fnsku) {
              handleClearReceiveModalStates();
              handleGetReceivingPoDetails(pending_po_id);
              return;
            }
            handleGenerateProductLabels({});
            handleClearReceiveModalStates();
            handleGetReceivingPoDetails(pending_po_id);
          }
        });
      } else {
        const commonData = {
          loose_box: radioValue === "2",
          expired_date: currentExpiryDate || null,
          item_dimensions: itemDimensions,
          bundle_quantity: currentBundleQuantity,
          asin,
          title: product_title,
          pending_po_id,
          image_url: medium_image || small_image,
        };

        dispatch(
          ReceiveGatedPo(
            radioValue === "1"
              ? {
                ...commonData,
                case_qty: Number(currentCaseQuantity),
                box_qty: Number(currentBoxQuantity),
              }
              : {
                ...commonData,
                working_quantity: Number(currentWorkingQuantity),
              }
          )
        ).then(({ payload }) => {
          const { data } = payload || {};
          const { success, result, staging_item } = data || {};
          if (success) {
            handleUpdateReceivedQuantity({ value: Number(result.received_quantity) });
            handleUpdateBundleQuantity();
            handleUpdateItemDimensions();
            handleGenerateStorageLabels({ staging_item });
            handleClearReceiveModalStates();
            dispatch(GetStats());
          }
        });
      }
    }
  };

  const handleReceiveButtonStatus = () => {
    if (!currentBundleQuantity) return "Please Enter Bundle Quantity";
    if (currentBundleQuantity <= 0) return "Invalid Bundle Quantity";
    const itemDimensionsRes = validateDimensions(itemDimensions);
    if (itemDimensionsRes) return itemDimensionsRes;

    if (storage_status) {
      if (!storageQuantities.sellable) return "Please Enter Sellable Quantity";
      if (storageQuantities.sellable < 0) return "Invalid Sellable Quantity";

      if (!storageQuantities.unSellable) return "Please Enter Un-Sellable Quantity";
      if (storageQuantities.unSellable < 0) return "Invalid Un-Sellable Quantity";
      if (!(storageQuantities.sellable != 0 || storageQuantities.unSellable != 0))
        return "Both Sellable & Un-Sellable Quantities Cannot Be Zero";
    } else {
      if (radioValue === "1") {
        if (!currentBoxQuantity) return "Please Enter Box Quantity (No Of Boxes)";
        if (currentBoxQuantity <= 0) return "Invalid Box Quantity (No Of Boxes)";

        if (!currentCaseQuantity) return "Please Enter Case Quantity (In A Box)";
        if (currentCaseQuantity <= 0) return "Invalid Case Quantity (In A Box)";
      } else {
        if (!currentWorkingQuantity) return "Please Enter Quantity";
        if (currentWorkingQuantity <= 0) return "Invalid Quantity";
      }

      const receivedQuantity = radioValue === "1" ? currentCaseQuantity * currentBoxQuantity : currentWorkingQuantity;

      if (radioValue === "1") {
        if (currentCaseQuantity % currentBundleQuantity !== 0) return "In a Box Must Be Divisible By Pack Of";
      } else {
        if (currentWorkingQuantity % currentBundleQuantity !== 0) return "Quantity Must Be Divisible By Pack Of";
      }

      if (sku_status?.toLowerCase() === "ok") {
        const obj =
          find(receivingPoDetails?.inbound_shipment_items, {
            po_join_shipment_items: { id: currentShipment?.poJoinShipmentItemsId },
          }) || {};
        const { po_join_shipment_items } = obj || {};
        const { quantity_shippable, received_quantity } = po_join_shipment_items || {};
        const availableQuantity = quantity_shippable - received_quantity || 0;
        const boxDimensionsRes = validateDimensions(boxDimensions, true);

        // if (isEmpty(currentBoxCategoryId)) return 'Please Select Box Category';
        if (radioValue === "2") {
          if (isEmpty(currentOldBoxId)) return "Please Select A Box";
          // if (currentOldBoxId?.value === -1 && isEmpty(currentBoxCategoryId)) return 'Please Select OR Create Box Category';
        }
        // if (!isEmpty(currentBoxCategoryId) && currentBoxCategoryId?.value === -1 && boxDimensionsRes)
        //   return boxDimensionsRes;
        if (currentShipment?.isSpPartnered && boxDimensionsRes) return boxDimensionsRes;
        if (isEmpty(obj)) return "No Shipment Available";
        const receivedQuantityInBundles = receivedQuantity / currentBundleQuantity;
        if (receivedQuantityInBundles > availableQuantity)
          return "Receive Quantity Must Be Smaller Than & Equal to Shippable Quantity";
      }
    }

    if (currentExpiryDate && (moment(currentExpiryDate).format('YYYY-MM-DD') < moment().add(1, 'days').format('YYYY-MM-DD'))) {
      return 'Expiry Date Must Be Of Future';
    }

    return "";
  };

  const handleGetReceivingPoDetails = (pending_po_id) => {
    dispatch(GetReceivingPoDetails(pending_po_id)).then(({ payload }) => {
      const { data } = payload || {};
      const { success, result } = data || {};
      const { inbound_shipment_items } = result || {};

      if (success) {
        if (!isEmpty(inbound_shipment_items)) {
          const { inbound_shipment, po_join_shipment_items: { id } = {} } = inbound_shipment_items?.[0] || {};
          const { shipment_id, transport_contents } = inbound_shipment || {};
          const { delivery_by_amazon, shipment_type } = transport_contents?.[0] || {};
          setCurrentShipment({
            shipmentId: shipment_id,
            poJoinShipmentItemsId: id,
            isSpPartnered: (delivery_by_amazon && shipment_type === 'SP')
          });
          dispatch(GetShipmentOldBoxes(shipment_id));
        }
      }
    });
  };

  const labelTypes = [
    { value: "Zebra", label: "Zebra Labels" },
    { value: "30 Up", label: "30 Up Labels" },
  ];

  const disableText = handleReceiveButtonStatus();

  const handleModeChange = (value) => {
    setCurrentMode(value);

    poSearchRef?.current?.value && (poSearchRef.current.value = '');
    poSearch1Ref?.current?.value && (poSearch1Ref.current.value = '');
    setSelectedUser('');

    const { pathname, search } = window.location || {};
    const query = new URLSearchParams(search);
    const tab = query.get('tab');
    if (tab) navigate(`${pathname}?tab=to-receive&mode=${value}`, { replace: true });
    else navigate(`${pathname}?mode=${value}`, { replace: true });

    setFilters([]);
    const newPaginationHash = { pageSize: 25, pageNumber: 1 };
    setPage(newPaginationHash);

    if (value === 'shipment') {
      const filter = { field: 'working_shipments', type: 'drop-down', value: true };
      setFilters([filter]);
      dispatch(GetWorkingShipments({ filters: [filter], page: newPaginationHash }));
    }
    else if (value === 'product') {
      dispatch(GetToReceivePendingPOs({ filters: [], page: newPaginationHash }));
    }
    else {
      setSelectedVendor('');
      dispatch(GetStoragePos({ filters: [], page: newPaginationHash }));
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between mt-4 align-items-center align-items-baseline">
        <h2 className="mb-0 color-black">{capitalize(currentMode)}s</h2>
        <div className="d-flex gap-3 align-items-center">
          {(currentMode === 'product') && <Button text="Add to Storage" className="outlined" onClick={() => {
            dispatch(GetAllLocations())
              .then(({ payload }) => {
                const { success, locations } = payload?.data || {};
                if (success) {
                  setLocationsList(locations);
                }
              });
            dispatch(GetStorageUsersList())
              .then(({ payload }) => {
                if (payload?.data?.length) {
                  setUsersList(payload?.data);
                }
              });
            setAddtoStorage(true);
          }} />}
          <ButtonGroup>
            {mode?.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`mode-${idx}`}
                type="radio"
                variant={currentMode === radio.value ? "primary" : ""}
                name="radio"
                value={radio.value}
                checked={currentMode === radio.value}
                onChange={(e) => handleModeChange(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>
      </div>
      <Row className="mt-4">
        {(currentMode === 'product') &&
          <Col md={3}>
            <SearchInput
              placeholder="Search by Invoice # / PO # / Order ID / Tracking No"
              inputRef={poSearchRef}
              onChange={({ target }) => handleFilter({ isDropDown: false, field: "", value: target.value, isFirstSearch: true })}
            />
          </Col>
        }
        <Col md={(currentMode === 'shipment') ? 5 : 3}>
          <SearchInput
            placeholder={
              (currentMode === 'shipment') ? 'Search by Shipment ID, Shipment Name, PO #'
                : (currentMode === "storage") ? "Search invoice, PO, Order ID, Tracking ID"
                  : 'Search by UPC / ASIN / SKU / FNSKU / Vendor UPC'
            }
            inputRef={poSearch1Ref}
            onChange={({ target }) => handleFilter({ isDropDown: false, field: "", value: target.value, isFirstSearch: false })}
          />
        </Col>
        <Col md={3}>
          <Select
            placeholder="User"
            isClearable={true}
            value={selectedUser}
            loading={fetchingUsersList}
            disabled={fetchingUsersList}
            onChange={(event) => {
              const value = event?.value || "";
              const field = (currentMode === 'shipment') ? '$inbound_shipments.user_id$' : (currentMode === 'product') ? '$pending_po_index.user_id$' : '$pending_po_indices.user_id$';
              const label =
                trim(event?.label?.props?.children?.[0]?.props?.children) ||
                trim(event?.label?.props?.children?.[2]?.props?.children) ||
                "";
              setSelectedUser(value ? { value, label } : "");
              handleFilter({ isDropDown: true, field, value });
            }}
            className="h-32  w-276"
            options={users}
          />
        </Col>
        {currentMode === "storage" &&
          <Col md={3}>
            <Select
              placeholder="Vendor"
              loading={fetchingVendorsList}
              disabled={fetchingVendorsList}
              value={selectedVendor}
              onChange={(event) => {
                const value = event?.value || "";
                const label =
                  event?.label?.props?.children?.[0]?.props?.children || "";
                setSelectedVendor(value ? { value, label } : "");
                handleFilter({
                  isDropDown: true,
                  field: "vendor_address_id",
                  value,
                });
              }}
              className="h-32"
              options={vendors}
              isClearable={true}
            />
          </Col>}
        {<Col md={3}>
          <Switch
            text={
              currentMode === "shipment" ? "Show Only Working Shipments" :
                ["Show Completed PO’s ", <strong>({(currentMode === "storage") ? completedCount : completedPosCount || 0})</strong>]
            }
            className="h-32"
            onChange={(event) => handleFilter({
              isDropDown: true,
              field: currentMode === 'shipment' ? 'working_shipments' : 'marked_as_hide',
              value: event
            })}
            checked={find(filters, { field: currentMode === 'shipment' ? 'working_shipments' : 'marked_as_hide' })?.value}
          />
        </Col>
        }
      </Row>
      {currentMode === "shipment" ? (
        <Spin tip="Loading..." spinning={fetchingWorkingShipments || deletingShipment}>
          {(workingShipments.length) ?
            <>
              <TilesWrapper height={scrollHight ? scrollHight : "490px"}>
                {workingShipments?.map(
                  (
                    {
                      created_at,
                      destination_fulfillment_center_id,
                      id,
                      inbound_shipment_items,
                      pos,
                      quantity,
                      shipment_id,
                      skus,
                      transport_contents,
                      user: { first_name, last_name, company: { name: company_name } = {} } = {},
                      working_quantity,
                      shipment_status,
                      po_codes
                    },
                    index
                  ) => {
                    const user_name = `${first_name ?? ""} ${last_name ?? ""}`;
                    const isShipmentDeletable = (shipment_status === 'WORKING') ? true : false;
                    const progress = working_quantity / quantity;
                    const shipment_po_codes = po_codes?.split(', ') || [];
                    const { shipment_type, carrier } = transport_contents?.[0] || {};
                    let mappedCarrier = '';
                    if (carrier) mappedCarrier = find(nonPartneredCarriers, { value: carrier })?.label?.split(' - ')?.[0];

                    return (
                      <div className="tiles-box">
                        <Table responsive className="tiles-table">
                          <thead>
                            <tr>
                              {shipmentHeader.map((header) => (
                                <th>{header}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span>{shipment_id}</span>
                              </td>
                              <td>
                                <span>{destination_fulfillment_center_id}</span>
                              </td>
                              <td>
                                <Popover
                                  placement="top"
                                  content={
                                    <div>
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h6 className="fw-bold mb-0 fs-14">POs</h6>
                                      </div>
                                      <Divider className="my-2" />
                                      <div className="overflow-auto" style={{ maxHeight: 400 }}>
                                        {shipment_po_codes?.map((po, index) => {

                                          return (
                                            <div key={`${shipment_id}_po_${index}`} className="d-flex justify-content-between align-items-center mb-2">
                                              <span className="pe-4">{po}</span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  }
                                  trigger='hover'
                                >
                                  <span className="color-primary cursor-pointer">{pos}</span>
                                </Popover>
                              </td>
                              <td>
                                <span>{skus}</span>
                              </td>
                              <td>
                                <span>{quantity}</span>
                              </td>
                              <td>
                                <span className="d-flex align-items-center gap-1">
                                  <ProgressBar now={ceil(progress * 100)} variant="warning" className="progress-height" />
                                  <span>{working_quantity} / {quantity}</span>
                                </span>
                              </td>
                              <td>
                                <span>{user_name}</span>
                              </td>
                              <td>
                                <span>{company_name}</span>
                              </td>
                              <td>
                                <span>{shipment_type || 'N/A'}</span>
                              </td>
                              <td>
                                <span>{mappedCarrier || 'N/A'}</span>
                              </td>
                              <td>
                                <span>{moment(created_at).format('DD MMM, YYYY - hh:mm:ss A')}</span>
                              </td>
                              <td>
                                <span>
                                  <Popconfirm
                                    key="delete_storage_po_receive_history"
                                    placement="left"
                                    title={`Are you sure, you want to delete shipment (${shipment_id}) ?`}
                                    onConfirm={() => {
                                      dispatch(DeleteShipment(shipment_id)).then(({ payload }) => {
                                        const { data } = payload || {};
                                        const { success } = data || {};
                                        if (success) {
                                          dispatch(GetWorkingShipments({ filters, page }));
                                        }
                                      })
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                    disabled={!isShipmentDeletable}
                                  >
                                    <Tooltip
                                      title={isShipmentDeletable ? "Delete Shipment" : "Shipment Is Not Deletable"}
                                    >
                                      <BsTrash color="#CF0909" className={`me-2 ${isShipmentDeletable ? 'cursor-pointer' : ''}`}
                                        style={isShipmentDeletable ? {} : { opacity: 0.5 }}
                                      />
                                    </Tooltip>
                                  </Popconfirm>

                                  <span
                                    class="icon-arrow-right cursor-pointer"
                                    onClick={() => navigate(`/fba-inbound/transportPlan/${shipment_id}`)}
                                  ></span>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    );
                  }
                )}
              </TilesWrapper>
              <TilesPagination total={shipmentsTotalCount} offset={workingShipments.length} onClick={handlePagination} />
            </> :
            <img src={NoInbound} alt="no-data" className="mx-auto d-block" />
          }
        </Spin>
      ) : currentMode === "product" ? (
        <Spin tip="Loading..." spinning={fetchingToReceivePendingPOs || checkInLoading}>
          {(toReceivePendingPos.length) ?
            <>
              <TilesWrapper height={scrollHight ? scrollHight : "490px"}>
                {toReceivePendingPos?.map(
                  (
                    {
                      bundle_quantity,
                      id: pending_po_id,
                      order_quantity,
                      working_units,
                      working_quantity,
                      pending_po_index: {
                        id: pending_po_index_id,
                        last_checked_in_at,
                        last_shipment_created_at,
                        po_code,
                        storage_status,
                        user: { first_name, last_name, company: { name: company_name } = {} } = {},
                      } = {},
                      received_quantity,
                      vendoritem: {
                        vendor_upc,
                        packcount,
                        seller_sku,
                        vendorasin: {
                          asin,
                          height,
                          length,
                          medium_image,
                          name: product_title,
                          small_image,
                          upc,
                          weight,
                          width,
                        } = {},
                      } = {},
                    },
                    index
                  ) => {
                    const { fnsku, sku, sku_status } = seller_sku || {};
                    const user_name = `${first_name ?? ""} ${last_name ?? ""}`;
                    const missingQuantity =
                      Number(order_quantity) < Number(received_quantity)
                        ? 0
                        : Number(order_quantity) - Number(received_quantity);

                    return (
                      <div className="tiles-box">
                        <Table responsive className="tiles-table">
                          <thead>
                            <tr>
                              {tilesHeader.map((header) => (
                                <th>{header}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <span className="d-flex align-items-start flex-row">
                                  <img
                                    width={40}
                                    height={40}
                                    src={small_image || "/images/no-image.png"}
                                    alt="no-product"
                                  />
                                  <span className="text-truncate d-flex flex-column ms-2">
                                    <Tooltip title={product_title || ""}>
                                      <span>{truncate(product_title, { length: 25 }) || "N/A"}</span>
                                    </Tooltip>
                                    <span
                                      className="color-primary"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        window.open(`https://www.amazon.com/gp/product/${asin}`, "_blank");
                                      }}
                                    >
                                      ASIN: {asin || "N/A"}
                                    </span>
                                  </span>
                                </span>
                              </td>
                              <td>
                                <div className="receive-tile d-flex align-items-start flex-row">
                                  <span className="d-flex flex-column">
                                    <span>{order_quantity || 0}</span>
                                    <span>Ordered</span>
                                  </span>
                                  <span className="d-flex flex-column ms-2">
                                    <span>{working_units || working_quantity || 0}</span>
                                    <span>Working</span>
                                  </span>
                                  <span className="d-flex flex-column ms-2">
                                    <span>{received_quantity || 0}</span>
                                    <span>Received</span>
                                  </span>
                                  <span className="d-flex flex-column ms-2">
                                    <span>{missingQuantity}</span>
                                    <span>Missing</span>
                                  </span>
                                </div>
                              </td>
                              <td>
                                <Tooltip title={sku_status || ""}>
                                  <span
                                    className={`${sku_status?.toLowerCase() === "ok" ? "badge-primary" : "badge-sucess"
                                      } text-truncate`}
                                  >
                                    {sku_status?.toLowerCase() === "ok" ? sku_status : "GATED"}
                                  </span>
                                </Tooltip>
                              </td>
                              <td>
                                <span>
                                  {length || 0.0} x {width || 0.0} x {height || 0.0} x {weight || 0.0}
                                </span>
                                <span>L (in) x W (in) x H (in) x W (lb)</span>
                              </td>
                              <td>
                                <span>{fnsku || "N/A"}</span>
                                <Tooltip title={sku || ""}>
                                  <span>SKU: {truncate(sku, { length: 15 }) || "N/A"}</span>
                                </Tooltip>
                              </td>
                              <td>
                                <span>{po_code || "N/A"}</span>
                                <span className={storage_status ? "badge-sucess" : "badge-primary"}>
                                  {storage_status || "FBA"}
                                </span>
                              </td>
                              <td>
                                <span>{user_name || "N/A"}</span>
                                <span>{company_name || "N/A"}</span>
                              </td>
                              <td>
                                <div className="d-flex flex-row align-items-center gap-3">
                                  <PrintLabelsAction
                                    fnsku={fnsku}
                                    handlePrintAdditionalLabels={handlePrintAdditionalLabels}
                                    setCurrentPoIndex={setCurrentPoIndex}
                                    isValidExpiryDate={isValidExpiryDate}
                                    index={index}
                                  />

                                  <Tooltip title="View Receive History">
                                    <img
                                      src={HistoryNew}
                                      alt="no-timer"
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setReceiveHistory(true);
                                        setCurrentPoIndex(index);
                                        dispatch(GetPoReceivingHistory({ pending_po_id, storage_status }));
                                      }}
                                    />
                                  </Tooltip>

                                  {(find(filters, { field: 'marked_as_hide' })?.value) ? (<Popconfirm
                                    key="po_complete_status"
                                    placement="left"
                                    title={`Are you sure, you want to Mark Po In-Complete?`}
                                    disabled={(!storage_status && sku_status?.toLowerCase() === "ok" && !last_shipment_created_at) ||
                                      ((storage_status || sku_status?.toLowerCase() !== "ok") && !last_checked_in_at)}
                                    onConfirm={() =>
                                      handleUpdatePoCompleteStatus({
                                        pending_po_index_id: pending_po_index_id,
                                        marked_as_hide: false,
                                        pending_po_id,
                                        po_code, asin,
                                        height, width, length, weight, bundle_quantity,
                                        working_quantity, storage_status
                                      })
                                    }
                                    okText="Yes"
                                    cancelText="No"
                                  >

                                    <Tooltip
                                      title={
                                        !storage_status && sku_status?.toLowerCase() === "ok" && !last_shipment_created_at
                                          ? "Please Create Shipment First"
                                          : (storage_status || sku_status?.toLowerCase() !== "ok") && !last_checked_in_at
                                            ? "Please Check-In First"
                                            : "Receive"
                                      }
                                    >
                                      <span className="icon-arrow-right fs-4 purple-icon"
                                        style={
                                          (!storage_status && sku_status?.toLowerCase() === "ok" &&
                                            !last_shipment_created_at) ||
                                            ((storage_status || sku_status?.toLowerCase() !== "ok") && !last_checked_in_at)
                                            ? { opacity: 0.5 } : {}
                                        }
                                      ></span>

                                    </Tooltip>
                                  </Popconfirm>) : <Tooltip
                                    title={
                                      !storage_status && sku_status?.toLowerCase() === "ok" && !last_shipment_created_at
                                        ? "Please Create Shipment First"
                                        : (storage_status || sku_status?.toLowerCase() !== "ok") && !last_checked_in_at
                                          ? "Please Check-In First"
                                          : "Receive"
                                    }
                                  >
                                    <span className="icon-arrow-right fs-4 purple-icon"
                                      style={
                                        (!storage_status && sku_status?.toLowerCase() === "ok" &&
                                          !last_shipment_created_at) ||
                                          ((storage_status || sku_status?.toLowerCase() !== "ok") && !last_checked_in_at)
                                          ? { opacity: 0.5 } : {}
                                      }
                                      onClick={() => {
                                        if (
                                          (!storage_status && sku_status?.toLowerCase() === "ok" && !last_shipment_created_at) ||
                                          ((storage_status || sku_status?.toLowerCase() !== "ok") && !last_checked_in_at)
                                        )
                                          return;
                                        handleReceiveModalOpen(pending_po_id, height, width, length, weight, bundle_quantity, working_quantity, storage_status, index)
                                      }}
                                    ></span>

                                  </Tooltip>}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    );
                  }
                )}
              </TilesWrapper>
              <TilesPagination total={posTotalCount} offset={toReceivePendingPos.length} onClick={handlePagination} />
            </> : (
              <div className="empty-data-container">
                {(completedPosCount && !toReceivePendingPos.length) ?
                  <div className="nodata-notification justify-content-between">
                    <div className="notification-text">
                      <img className="notification-icon" src={IconInfo} />
                      There are {completedPosCount} SKUs that are completed.
                    </div>
                    <div className="notification-toggle">
                      <a onClick={() => { handleFilter({ isDropDown: true, field: "marked_as_hide", value: true }); return false; }}>
                        Click Here to View
                      </a>
                    </div>
                  </div> : null
                }
                <img
                  src={NoInbound}
                  alt="no-data"
                  className="mx-auto d-block checkin-main-nodataimg"
                />
              </div>
            )
          }
        </Spin>
      ) :
        <StoragePOs
          scrollHight={scrollHight}
          filters={filters}
          handleFilter={handleFilter}
          handlePagination={handlePagination}
        />
      }

      <Drawer show={recevingHistory} size="96%">
        <TilesWrapper className="mt-0">
          <div className="drawer-padding">
            <div className="d-flex align-items-center mb-3 gap-3">
              <span
                className="icon-arrow-left cursor-pointer"
                onClick={() => {
                  setRadioValue("1");
                  setReceiveHistory(false);
                }}
              ></span>
              <h2 className="mb-0">Receiving History</h2>
            </div>
            <TilesWrapper className="mt-0">
              <div className="marketplace-information">
                <div className="details">
                  <div className="d-flex flex-row gap-3">
                    <img width={40} height={40} src={small_image || "/images/no-image.png"} alt="no-product" />
                    <span className="text-truncate w-75 primary" style={{ cursor: "pointer" }}>
                      {product_title || "N/A"}
                    </span>
                  </div>
                  <div>
                    <span className="text-truncate d-flex flex-column ms-2">
                      <span>{user_name}</span>
                      <span>{company_name || "N/A"}</span>
                    </span>
                  </div>
                  <div>
                    <span>SKU</span>
                    <span>{sku || "N/A"}</span>
                  </div>
                  <div>
                    <span>FNSKU</span>
                    <span>{fnsku || "N/A"}</span>
                  </div>
                  <div>
                    <span>PO#</span>
                    <span>{po_code || "N/A"}</span>
                  </div>
                  <div>
                    <span>Product Status</span>
                    <Tooltip title={sku_status || ""}>
                      <span>{sku_status?.toLowerCase() === "ok" ? sku_status : "GATED"}</span>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </TilesWrapper>
            <Spin
              tip="Loading..."
              spinning={
                fetchingPoReceivingHistory ||
                deletingOutboundShipment ||
                deletingStoragePo ||
                fetching2dLabels ||
                fetchingLooseBoxDetails ||
                fetchingMix2dLabels
              }
            >
              {recevingHistory && (
                <AgGrid
                  user_referrals={
                    storage_status
                      ? poReceivingHistory?.arrival_items || []
                      : poReceivingHistory?.outbound_shipments || []
                  }
                  defaultColDef={defaultColDef}
                  columnDefinitions={storage_status ? storagePoHistoryColDef : poHistoryColDef}
                  rowHeight={60}
                  height={"200px"}
                />
              )}
            </Spin>
          </div>
        </TilesWrapper>
      </Drawer>

      <Drawer show={toReceive} size="48%">
        <DrawerWrapper>
          <Spin
            tip="Loading..."
            spinning={
              fetchingSkuStatus ||
              receivingGatedPo ||
              receivingStoragePo ||
              creatingOutboundShipment ||
              fetching2dLabels ||
              fetchingLooseBoxDetails ||
              fetchingMix2dLabels
            }
          >
            <div className="drawer-padding">
              <div className="d-flex align-items-center mb-3 gap-3">
                <span
                  class="icon-arrow-left cursor-pointer"
                  onClick={() => {
                    handleClearReceiveModalStates(true);
                    setToReceive(false);
                    setCurrentStep(0);
                  }}
                ></span>
                <h3 className="mb-0 color-black">
                  {sku_status?.toLowerCase() !== "ok" || storage_status
                    ? `Receive to Storage: ${po_code}`
                    : radioValue === "1"
                      ? "Receive"
                      : "Receive to Shipment"}{" "}
                </h3>
              </div>
              <div className="product-details">
                <div className="d-flex gap-3">
                  <img
                    src={medium_image || small_image || "/images/no-image.png"}
                    alt="no-product"
                    width="148px"
                    height="140px"
                  />
                  <div className="w-100">
                    <h3
                      className="color-primary"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        window.open(`https://www.amazon.com/gp/product/${asin}`, "_blank");
                      }}
                    >
                      {product_title || "N/A"}
                    </h3>
                    <div className="d-flex justify-content-between info mb-3">
                      <div>
                        <span>ASIN</span>
                        <span>{asin}</span>
                      </div>
                      <div>
                        <span>SKU</span>
                        <span>{sku || "N/A"}</span>
                        <span className="fs-10 d-flex">
                          <div className="fw-bold color-regent">FNSKU:</div>
                          <div className="ps-1 color-secondary">{fnsku || "N/A"}</div>
                        </span>
                      </div>
                      <div>
                        <span>Quantity (Unit’s)</span>
                        <span className="d-flex gap-3 units">
                          <div>
                            <span>{order_quantity || 0}</span>
                            <span>Order</span>
                          </div>
                          <div>
                            <span>{working_units || working_quantity || 0}</span>
                            <span>Working</span>
                          </div>
                          <div>
                            <span>{received_quantity || 0}</span>
                            <span>Received</span>
                          </div>
                          <div>
                            <span>{missingQuantity || 0}</span>
                            <span>Missing</span>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between info">
                      <div className="note-text">
                        <span>Note</span>
                        <Tooltip title={note || ''}>
                          <span className="ellipse">{note || 'N/A'}</span>
                        </Tooltip>
                      </div>
                      <div className="product-status">
                        <span>Product Status</span>
                        <Tooltip title={sku_status}>
                          <span className="ellipse">{sku_status || "N/A"}</span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex add-weight">
                <div className="d-flex flex-column">
                  <Form.Label htmlFor="basic-url">Pack of</Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="bundle_quantity"
                      placeholder="00"
                      value={currentBundleQuantity}
                      disabled={true}
                      onChange={({ target }) => setCurrentBundleQuantity(target.value)}
                    />
                    {/* <InputGroup.Text></InputGroup.Text> */}
                  </InputGroup>
                </div>

                <div className="d-flex flex-column">
                  <Form.Label htmlFor="basic-url">Length</Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="item_length"
                      placeholder="00"
                      value={itemDimensions?.length}
                      disabled={currentStep !== 0}
                      onChange={({ target }) => handleDimensions({ length: target.value })}
                    />
                    <InputGroup.Text>in</InputGroup.Text>
                  </InputGroup>
                </div>
                <div className="d-flex flex-column">
                  <Form.Label htmlFor="basic-url">Width</Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="item_width"
                      placeholder="00"
                      value={itemDimensions?.width}
                      disabled={currentStep !== 0}
                      onChange={({ target }) => handleDimensions({ width: target.value })}
                    />
                    <InputGroup.Text>in</InputGroup.Text>
                  </InputGroup>
                </div>
                <div className="d-flex flex-column">
                  <Form.Label htmlFor="basic-url">Height</Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="item_height"
                      placeholder="00"
                      value={itemDimensions?.height}
                      disabled={currentStep !== 0}
                      onChange={({ target }) => handleDimensions({ height: target.value })}
                    />
                    <InputGroup.Text>in</InputGroup.Text>
                  </InputGroup>
                </div>
                <div className="d-flex flex-column">
                  <Form.Label htmlFor="basic-url">Weight</Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="item_weight"
                      placeholder="00"
                      value={itemDimensions?.weight}
                      disabled={currentStep !== 0}
                      onChange={({ target }) => handleDimensions({ weight: target.value })}
                    />
                    <Select
                      value={{ value: "lb", label: "lb" }}
                      disabled={true}
                      options={[
                        { value: "lb", label: "lb" },
                        { value: "oz", label: "oz" },
                      ]}
                      className="input-select"
                    />
                  </InputGroup>
                </div>
              </div>
              {!storage_status && sku_status?.toLowerCase() === "ok" && (
                <div className="create-shipment">
                  <h4 className="fw-bold color-black">
                    {isEmpty(receivingPoDetails?.inbound_shipment_items) ? "No Shipment Available" : "Choose Shipment"}
                  </h4>
                  <Spin tip="Loading..." spinning={fetchingReceivingPoDetails}>
                    <div className="d-flex gap-12 overflow-auto">
                      {receivingPoDetails?.inbound_shipment_items?.map(
                        ({
                          inbound_shipment: {
                            destination_fulfillment_center_id,
                            shipment_id,
                            shipment_name,
                            created_at,
                            transport_contents
                          } = {},
                          po_join_shipment_items: { id, quantity_shippable, received_quantity } = {},
                          inbound_shipment_id,
                        } = {}) => {
                          const { delivery_by_amazon, shipment_type } = transport_contents?.[0] || {};
                          return (
                            <div
                              className={`shipment-plan ${currentShipment.shipmentId === shipment_id ? "active" : ""}`}
                              onClick={() => {
                                if (currentShipment?.shipmentId !== shipment_id) {
                                  setCurrentShipment({
                                    shipmentId: shipment_id,
                                    poJoinShipmentItemsId: id,
                                    isSpPartnered: (delivery_by_amazon && shipment_type === 'SP')
                                  });
                                  dispatch(GetShipmentOldBoxes(shipment_id));
                                  handleClearReceiveModalStates();
                                }
                              }}
                            >
                              <span className="d-flex justify-content-between">
                                <Tooltip title={shipment_name}>
                                  <span className="fw-bold">{truncate(shipment_name, { length: 18 })}</span>
                                </Tooltip>
                                {currentShipment.shipmentId === shipment_id ? (
                                  <BsFillArrowRightCircleFill
                                    onClick={() => {
                                      window.open(
                                        `${window?.location?.origin}/fba-inbound/transportPlan/${shipment_id}`
                                      );
                                    }}
                                  />
                                ) : (
                                  <BsArrowRightCircle
                                    onClick={() => {
                                      window.open(
                                        `${window?.location?.origin}/fba-inbound/transportPlan/${shipment_id}`
                                      );
                                    }}
                                  />
                                )}
                              </span>

                              <div className="d-flex gap-2">
                                <span>
                                  <span className="fw-bold">ID</span>: {shipment_id || "N/A"}
                                </span>
                                <span>
                                  <span className="fw-bold">FC</span>: {destination_fulfillment_center_id || "N/A"}
                                </span>
                              </div>
                              <div className="d-flex gap-2">
                                <span className="d-block">
                                  <span className="fw-bold">Bundles</span>: {quantity_shippable - received_quantity}
                                </span>
                                <span className="d-block">
                                  <span className="fw-bold">Units</span>:{" "}
                                  {(quantity_shippable - received_quantity) * currentBundleQuantity}
                                </span>
                              </div>
                              <span className="d-block">
                                <span className="fw-bold">Created</span>: {getFormattedDate(created_at)}
                              </span>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </Spin>
                </div>
              )}
              <div className="sub-heading">
                {!storage_status && <h4 className="fw-bold color-black">Input Details</h4>}
                {!storage_status && (
                  <Row>
                    <Col md={6}>
                      <ButtonGroup>
                        {radios?.map((radio, idx) => (
                          <ToggleButton
                            key={idx}
                            id={`radio-${idx}`}
                            type="radio"
                            variant={radioValue === radio.value ? "primary" : ""}
                            name="radio"
                            value={radio.value}
                            checked={radioValue === radio.value}
                            onChange={(e) => {
                              handleClearReceiveModalStates();
                              setRadioValue(e.currentTarget.value);
                            }}
                          >
                            {radio.name}
                          </ToggleButton>
                        ))}
                      </ButtonGroup>
                    </Col>
                  </Row>
                )}
                <Row>
                  {storage_status && (
                    <div className="input-details">
                      <Steps
                        size="small"
                        current={currentStep}
                      >
                        {stepTitle.map((item, ind) => (
                          <Step key={ind} title={item} />
                        ))}

                      </Steps>
                      {currentStep === 0 ?
                        <Row className="mt-3">
                          <Col md={4}>
                            <Input
                              label="Sellable"
                              className="h-32"
                              value={storageQuantities?.sellable}
                              onChange={({ target }) =>
                                setStorageQuantities((storageQuantities) => ({
                                  ...storageQuantities,
                                  sellable: target.value,
                                }))
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <Input
                              label="Un Sellable"
                              className="h-32"
                              value={storageQuantities?.unSellable}
                              onChange={({ target }) =>
                                setStorageQuantities((storageQuantities) => ({
                                  ...storageQuantities,
                                  unSellable: target.value,
                                }))
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <DatePicker
                              label="Expiry Date"
                              type="date"
                              className="h-32"
                              min={moment().add(1, 'days').format('YYYY-MM-DD')}
                              value={currentExpiryDate}
                              onChange={({ target }) => setCurrentExpiryDate(target.value)}
                            />
                          </Col>
                        </Row>
                        : currentStep === 1 ?
                          <div className="mt-3">
                            <FulfillPos
                              totalQty={arrival_items?.[0]?.sellable_quantity || 0}
                              working_quantity={working_quantity}
                              pending_po_id={pending_po_id}
                              setCurrentStep={setCurrentStep}
                              arrival_item_id={arrival_items?.[0]?.id || null}
                              po_index={currentPoIndex}
                              received_quantity={received_quantity}
                            />
                          </div>
                          :
                          <div className="mt-3">
                            <PutToStorage
                              totalQty={arrival_items?.[0]?.sellable_quantity || 0}
                              qtyAssignToPos={(arrival_items?.[0]?.sellable_quantity || 0) - working_quantity}
                              qtyToStorage={working_quantity}
                              ref={storageRef}
                              handleClearStorageStates={handleClearStorageStates}
                              handleGenerateStorageLabels={handleGenerateStorageLabels}
                              staging_item={{
                                title: item_name || product_title,
                                image_url: small_image,
                                identifier: asin,
                                source_type: 'ArrivalItem',
                                quantity: working_quantity,
                                unsellable_quantity: 0,
                                source_id: arrival_items?.[0]?.id,
                                assigned_user_id: user_id,
                                vendoritem_id,
                                height: itemDimensions?.height,
                                width: itemDimensions?.width,
                                length: itemDimensions?.length,
                                weight: itemDimensions?.weight,
                                expiry: currentExpiryDate
                              }}
                            />
                          </div>
                      }
                      <div className="d-flex gap-3 stepper-btn">
                        {currentStep > 0 &&
                          <Button
                            text="Back"
                            variant="text"
                            className="text-button"
                            disabled={currentStep === 1}
                            startIcon={<span className="icon-arrow-left cursor-pointer" />}
                            onClick={() => setCurrentStep(currentStep - 1)}
                          />
                        }
                        {currentStep < stepTitle.length - 1 &&
                          <Tooltip title={currentStep === 0 ? disableText : ''}>
                            <div style={{ display: "inline-block" }}>
                              <Button
                                text={currentStep === 0 ? "Save & Next" : (working_quantity === 0) ? 'Close' : "Next"}
                                className="primary"
                                disabled={currentStep === 0 && disableText}
                                onClick={handleSaveStoragePo}
                              />
                            </div>
                          </Tooltip>
                        }
                        {currentStep === stepTitle.length - 1 &&
                          <Button
                            text="Submit"
                            className="primary"
                            onClick={() => storageRef.current.handleAddToStorage(setCurrentStep)}
                          />}
                      </div>
                    </div>
                  )}
                  {!storage_status && radioValue === "1" && (
                    <>
                      <Col md={4}>
                        <Input
                          label="No. of Box(es)"
                          className="h-32"
                          value={currentBoxQuantity}
                          onChange={({ target }) => setCurrentBoxQuantity(target.value)}
                        />
                      </Col>
                      <Col md={4}>
                        <Input
                          label="In a Box"
                          className="h-32"
                          value={currentCaseQuantity}
                          onChange={({ target }) => setCurrentCaseQuantity(target.value)}
                        />
                      </Col>
                    </>
                  )}
                  {!storage_status && radioValue === "2" && (
                    <Col md={4}>
                      <Input
                        label="Quantity"
                        className="h-32"
                        value={currentWorkingQuantity}
                        onChange={({ target }) => setCurrentWorkingQuantity(target.value)}
                      />
                    </Col>
                  )}
                  {!storage_status && <Col md={4}>
                    <DatePicker
                      label="Expiry Date"
                      type="date"
                      className="h-32"
                      min={moment().add(1, 'days').format('YYYY-MM-DD')}
                      value={currentExpiryDate}
                      onChange={({ target }) => setCurrentExpiryDate(target.value)}
                    />
                  </Col>
                  }
                  {!storage_status && sku_status?.toLowerCase() === "ok" && (
                    <Col md={4}>
                      <Select
                        label="Label Type"
                        value={currentLabelType}
                        onChange={(event) => setCurrentLabelType(event)}
                        options={labelTypes}
                      />
                    </Col>
                  )}

                  {!storage_status && sku_status?.toLowerCase() === "ok" && radioValue === "2" && (
                    <Col md={4}>
                      <Select
                        label="Select Box"
                        placeholder="Select Box"
                        isClearable={true}
                        maxHeight={100}
                        value={isEmpty(currentOldBoxId) ? "" : currentOldBoxId}
                        loading={fetchingShipmentOldBoxes}
                        disabled={fetchingShipmentOldBoxes}
                        onChange={(event) => {
                          setCurrentOldBoxId(event);
                          if (event?.value && event?.value !== -1) {
                            const box = find(shipmentOldBoxes, { id: event?.value }) || {};
                            setIsCompleteBox(box?.is_completed);
                            const { box_category } = box || {};
                            if (!isEmpty(box_category)) {
                              const { id, height, length, name, weight, width } = box_category || {};
                              // setCurrentBoxCategoryId({
                              //   value: id,
                              //   label: `${name} ( ${length} x ${width} x ${height} x ${weight} )`,
                              // });
                            }
                          } else {
                            // setCurrentBoxCategoryId({});
                          }
                          setShowCreateBoxCategory(false);
                          setBoxDimensions({});
                        }}
                        options={[
                          ...oldBoxOptions,
                          {
                            value: -1,
                            label: <span className="text-primary">{`New Box - ${newBoxIndex}`}</span>,
                          },
                        ]}
                      />
                    </Col>
                  )}
                  {/* {!storage_status && sku_status?.toLowerCase() === "ok" && (
                    <Col md={4}>
                      <Select
                        label="Box Category"
                        placeholder="Select Box Category"
                        isClearable={true}
                        maxHeight={100}
                        value={isEmpty(currentBoxCategoryId) ? "" : currentBoxCategoryId}
                        loading={fetchingReceivingPoDetails}
                        disabled={
                          fetchingReceivingPoDetails || (currentOldBoxId?.value && currentOldBoxId?.value !== -1)
                        }
                        onChange={(event) => {
                          event?.value === -1 ? setShowCreateBoxCategory(true) : setShowCreateBoxCategory(false);
                          setCurrentBoxCategoryId(event);
                          !event?.value && setBoxDimensions({});
                        }}
                        options={[
                          ...boxCategoryOptions,
                          {
                            value: -1,
                            label: <span className="text-primary">Create Box Category</span>,
                          },
                        ]}
                      />
                    </Col>
                  )} */}
                  {!storage_status && sku_status?.toLowerCase() === "ok" && radioValue === "2" && (
                    <Col md={4}>
                      <div className="mt-4 d-flex gap-1 align-items-center">
                        <CheckBox
                          label="Box Complete"
                          value={isCompleteBox}
                          onChange={({ target }) => setIsCompleteBox(target.checked)}
                        />
                        <Tooltip title="Once checked, it means box is complete now, no more items can be added to that box and vice versa.">
                          <BsInfoCircle className="fs-12 color-secondary" />
                        </Tooltip>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
              {/* {showCreateBoxCategory && ( */}
              {currentShipment?.isSpPartnered && (
                <div className="box-category">
                  <h4 className="fw-bold color-black">Box Dimensions</h4>
                  <div className="d-flex add-weight">
                    {/* <div className="d-flex flex-column">
                      <Form.Label htmlFor="basic-url">Name</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="box_name"
                          placeholder="00"
                          value={boxDimensions?.name}
                          onChange={({ target }) => handleDimensions({ name: target.value }, true)}
                        />
                        <InputGroup.Text></InputGroup.Text>
                      </InputGroup>
                    </div> */}
                    <div className="d-flex flex-column">
                      <Form.Label htmlFor="basic-url">Length</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="box_length"
                          placeholder="00"
                          value={boxDimensions?.length}
                          onChange={({ target }) => handleDimensions({ length: target.value }, true)}
                        />
                        <InputGroup.Text>in</InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="d-flex flex-column">
                      <Form.Label htmlFor="basic-url">Width</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="box_width"
                          placeholder="00"
                          value={boxDimensions?.width}
                          onChange={({ target }) => handleDimensions({ width: target.value }, true)}
                        />
                        <InputGroup.Text>in</InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="d-flex flex-column">
                      <Form.Label htmlFor="basic-url">Height</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="box_height"
                          placeholder="00"
                          value={boxDimensions?.height}
                          onChange={({ target }) => handleDimensions({ height: target.value }, true)}
                        />
                        <InputGroup.Text>in</InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="d-flex flex-column">
                      <Form.Label htmlFor="basic-url">Weight</Form.Label>
                      <InputGroup>
                        <Form.Control
                          name="box_weight"
                          placeholder="00"
                          value={boxDimensions?.weight}
                          onChange={({ target }) => handleDimensions({ weight: target.value }, true)}
                        />
                        <Select
                          value={{ value: "lb", label: "lb" }}
                          disabled={true}
                          options={[
                            { value: "lb", label: "lb" },
                            { value: "oz", label: "oz" },
                          ]}
                          className="input-select"
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              )}
              {!storage_status &&
                <div className="d-flex justify-content-end">
                  <Popconfirm
                    key="check_loose_box"
                    placement="left"
                    title="Are you sure you want to mark this box as complete ?"
                    onConfirm={handleReceive}
                    okText="Yes"
                    cancelText="No"
                    disabled={!isCompleteBox}
                  >
                    <Tooltip title={disableText}>
                      <div style={{ display: "inline-block" }}>
                        <Button
                          text="Receive"
                          className="primary"
                          disabled={disableText}
                          onClick={() => !isCompleteBox && handleReceive()}
                        />
                      </div>
                    </Tooltip>
                  </Popconfirm>
                </div>
              }
            </div>
          </Spin>
        </DrawerWrapper>
      </Drawer>
      <AddtoStorageModal
        setAddtoStorage={setAddtoStorage}
        addtoStorage={addtoStorage}
        userList={userList}
        setLocationsList={setLocationsList}
        locationsList={locationsList}
      />
    </>
  );
};
export default ToReceive;
