import { cloneDeep } from "lodash";
const initialState = {
  fetchJournals: false,
  filters: [],
  pagination: {
    page_num: 1,
    per_page: 25,
  },
  total: 0,
  user_id: null,
  message: "",
  payment_history: [],
  fetchingPaymentHistory: false,
  addingManualAdjustment: false,
  updatingChargedAmount: false
}

const journal = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'FETCH_JOURNAL_REQUEST':
    case 'FETCH_USERS_LIST_REQUEST': {
      return {
        ...state,
        fetchJournals: true
      }
    }
    case 'GET_PYAMENT_HISTORY_REQUEST': {
      return {
        ...state,
        fetchingPaymentHistory: true
      }
    }
    case 'GET_PYAMENT_HISTORY_SUCCESS':
    case 'GET_PYAMENT_HISTORY_FAILED': {
      return {
        ...state,
        ...action.payload,
        fetchingPaymentHistory: false
      }
    }
    case 'SET_FILTERS_FOR_JOURNALS_PAGE':
    case 'FETCH_JOURNAL_SUCCESS':
    case 'FETCH_USERS_LIST_SUCCESS':
    case 'FETCH_USERS_LIST_FAILED': {
      const { without_pagination } = action.payload || {};
      if (without_pagination) {
        return {
          ...state,
          fetchJournals: false
        }
      }
      return {
        ...state,
        ...action.payload,
        fetchJournals: false
      }
    }
    case 'SET_PAGE_NUMBER_FOR_JOURNAL':
    case 'SET_PER_PAGE_FOR_JOURNAL': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    case 'SET_USER_ID': {
      return {
        ...state,
        ...action.payload
      }
    }
    case 'AMOUNT_ADJUSTMENT_REQUEST': {
      return {
        ...state,
        addingManualAdjustment: true
      }
    }
    case 'AMOUNT_ADJUSTMENT_REQUEST_SUCCESS':
    case 'AMOUNT_ADJUSTMENT_REQUEST_FAILED': {
      return {
        ...state,
        ...action.payload,
        addingManualAdjustment: false
      }
    }
    case 'UPDATE_CHARGED_AMOUNT_REQUEST': {
      return {
        ...state,
        updatingChargedAmount: true
      }
    }
    case 'UPDATE_CHARGED_AMOUNT_SUCCESS':
    case 'UPDATE_CHARGED_AMOUNT_FAILED': {
      return {
        ...state,
        updatingChargedAmount: false
      }
    }
    default: {
      return state
    }
  }
}

export default journal;
