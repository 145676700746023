import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';

import { handleRejectedResponse } from './utils';
import axios, { awsAxios } from '../../axios_config';
import { isArray } from 'lodash';

import { notification } from 'antd';

export const CreateStore = createAsyncThunk(
    '/create-store',
    async (data, { getState, rejectWithValue }) => {
      try {
        const state = getState();
        const authToken = state.auth.token;
        const response = await axios.post(
          '/api/v2/stores',
          data,
          { headers: { Authorization: `Bearer ${authToken}` } }
        );
        return response;
      } catch (err) {
        return handleRejectedResponse(err, 'Store Connect' , rejectWithValue);
      }
    }
);

export const SaveMarketPlaces = createAsyncThunk(
  '/save-marketplaces',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { storeId } = data;
      const response = await axios.post(
        `/api/v2/stores/${storeId}/add_market_places_for_store`,
         data,
         { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Add Marketplace' , rejectWithValue);
    }
  }
);

export const CreateSubscription = createAsyncThunk(
  '/subscribe-to-plan',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        '/api/v2/subscriptions',
         data,
         { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Subscribe to plan' , rejectWithValue);
    }
  }
);

// export const SubscribeToPlan = createAsyncThunk(
//   '/subscribe-to-plan',
//   async (data, { getState, rejectWithValue }) => {
//     try {
//       const state = getState();
//       const authToken = state.auth.token;
//       const response = await axios.post(
//         '/api/v2/subscriptions',
//          data,
//          { headers: { Authorization: `Bearer ${authToken}` } }
//       );
//       return response.data;
//     } catch (err) {
//       return handleRejectedResponse(err, 'Subscribe to plan' , rejectWithValue);
//     }
//   }
// );

// export const QBSubscription = createAsyncThunk(
//   '/qb-subscription',
//   async (data, { getState, rejectWithValue }) => {
//     try {
//       const state = getState();
//       const authToken = state.auth.token;
//       const response = await axios.post(
//         '/api/v2/qb_subscriptions',
//         data,
//         { headers: { Authorization: `Bearer ${authToken}` } }
//       );
//       return response.data;
//     } catch (err) {
//       return handleRejectedResponse(err, 'QB Subscription' , rejectWithValue);
//     }
//   }
// );

export const addCard = createAsyncThunk(
  '/add-card',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        '/api/v2/charges/add_card',
        data,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Add Card' , rejectWithValue);
    }
  }
);

export const ConnectWalmart = createAsyncThunk(
  '/connect-walmart',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        '/api/v2/stores/add_walmart_store',
        data,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Connect Walmart' , rejectWithValue);
    }
  }
);

// export const GetAccessToken = createAsyncThunk(
//   '/get-access-token',
//   async (data, { getState, rejectWithValue }) => {
//     try {
//       const response = await awsAxios.post(
//         `${process.env.INTUIT_URL}/quickbooks/v4/payments/tokens`,
//         data,
//         {
//           'Content-Type': 'application/json'
//         },
//       );
//       return response.data;
//     } catch (err) {
//        return handleRejectedResponse(err, 'Get Access Token' , rejectWithValue);
//     }
//   }
// );

// export const SaveCardMisc = createAsyncThunk(
//   '/save-card-Misc',
//   async (data, { getState, rejectWithValue }) => {
//     try {
//       const state = getState();
//       const authToken = state.auth.token;
//       const { userId } = data;
//       const response = await axios.put(
//         `/api/v2/users/${userId}/cc_info`,
//         data,
//         { headers: { Authorization: `Bearer ${authToken}` } }
//       );
//       return response.data;
//     } catch (err) {
//       return handleRejectedResponse(err, 'Save User Info' , rejectWithValue);
//     }
//   }
// );

export const GetValidMarketplaces = createAsyncThunk(
  '/get-valid-marketplaces',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { id } = data;
      const response = await axios.get(
        `/api/v2/stores/${id}/authorization_for_marketplaces_using_sp_api`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Marketplaces' , rejectWithValue);
    }
  }
);

export const WarehouseStatusUpdate = createAsyncThunk(
  '/warehouse-status-update',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { token: authToken, currentUser } = state?.auth || {};
      const { id } = currentUser || {};
      const response = await axios.post(
        `/api/v2/users/${id}/update_role_id_of_current_user`,
        {},
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Marketplaces' , rejectWithValue);
    }
  }
);

export const GetStores = createAsyncThunk(
  '/get-store',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { platformType } = data;
      const response = await axios.get(
        `/api/v2/stores/stores?platform_type=${platformType}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Stores' , rejectWithValue);
    }
  }
);

export const ReAuthorizeWalmartStore = createAsyncThunk(
  '/stores/:id',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { id, store } = data || {};
      const response = await axios.patch(
        `/api/v2/stores/${id}`,
        store
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Re-Authorize Walmart Store' , rejectWithValue);
    }
  }
);

export const ReAuthorizeAmazonStore = createAsyncThunk(
  '/stores/reauthorize_amazon_store',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        '/api/v2/stores/reauthorize_amazon_store',
        data
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Re-Authorize Amazon Store' , rejectWithValue);
    }
  }
);

export const GetShopifyAuthCode = createAsyncThunk(
  '/api/v2/stores/shopify_authorization_code',
  async ({ shop_name, redirect_url }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v2/stores/shopify_authorization_code?shop_name=${shop_name}&redirect_url=${redirect_url}`,
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Fetch Shopify Auth Code', rejectWithValue);
    }
  }
);

export const CreateShopifyStore = createAsyncThunk(
  '/api/v2/stores/shopify_access_token',
  async ({ shop_name, authorization_code }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `/api/v2/stores/shopify_access_token?authorization_code=${authorization_code}&shop_name=${shop_name}`,
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Create Shopify Store', rejectWithValue);
    }
  }
);

export const IsShopifyStoreExists = createAsyncThunk(
  '/api/v2/staging_asins/validate_shop_name',
  async (shop_name, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `/api/v2/staging_asins/validate_shop_name?shop_name=${shop_name}`,
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Shopify Store Validation', rejectWithValue);
    }
  }
);

export const ReAuthorizeShopifyStore = createAsyncThunk(
  '/api/v2/stores/shopify_credentials',
  async ({ shop_name, authorization_code }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `/api/v2/stores/shopify_credentials?authorization_code=${authorization_code}&shop_name=${shop_name}`,
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Re-Authorize Shopify Store', rejectWithValue);
    }
  }
);

const store = createSlice({
  name: "store",
  initialState: {
    storeName: '',
    storeId: '',
    spApiState: '',
    spApiRegion: '',
    stores: [],
    selectedMarketplaces: [],
    marketPlaces: [],
    planId: '',
    storeData: {},
    loading: false,
    createStoreLoading: false,
    marketPlaceLoading: false,
    paymentLoading: false,
    walmartLoading: false,
    warehouseLoading: false,
    getStoreLoading: false,
    skipBasicLoading: false,
    squareTokenLoading: false,
    authorizingWalmartStore: false,
    authorizingAmazonStore: false,
    fetchingShopifyAuthCode: false,
    creatingShopifyStore: false,
    authorizingShopifyStore: false,
    validatingShopifyStore: false
  },
  reducers: {
    setState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    clearStore(state,action) {
      return {
        ...state,
        selectedMarketplaces: [],
        storeName: '',
        storeData: {},
        spApiState: '',
        spApiRegion: ''
      }
    },
    amazonStoreInfo(state, action) {
      return {
        ...state,
        storeName: action.payload.storeName,
        spApiState: action.payload.state,
        spApiRegion: action.payload.region
      };
    }
  },
  extraReducers: {
    [CreateStore.pending]: (state, action) => ({
      ...state,
      createStoreLoading: true,
    }),
    [CreateStore.fulfilled]: (state, action) => {
      const { store, message } = action?.payload?.data || {};
      const { id } = store || {};
      notification.success({
        message: message || 'Store connected successfully',
        top: 65
      });
      return {
        ...state,
        createStoreLoading: false,
        storeData: store,
        storeId: id
      }
    },
    [CreateStore.rejected]: (state, action) => {
      return {
        ...state,
        createStoreLoading: false,
      }
    },
    [GetStores.pending]: (state, action) => ({
      ...state,
      getStoreLoading: true
    }),
    [GetStores.fulfilled]: (state, action) => {
      const { stores } = action?.payload || {};
      return {
        ...state,
        stores,
        getStoreLoading: false
      }
    },
    [GetStores.rejected]: (state, action) => {
      return {
        ...state,
        getStoreLoading: false
      }
    },
    [SaveMarketPlaces.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [SaveMarketPlaces.fulfilled]: (state, action) => {
      notification.success({
        message: 'Marketplaces updated successfully',
        top: 65
      });
      return {
      ...state,
      loading: false
    }},
    [SaveMarketPlaces.rejected]: (state, action) => {
      return {
      ...state,
      loading: false
    }},
    [ConnectWalmart.pending]: (state, action) => ({
      ...state,
      walmartLoading: true
    }),
    [ConnectWalmart.fulfilled]: (state, action) => {
      notification.success({
        message: 'Store connected successfully',
        top: 65
      });
      return {
      ...state,
      walmartLoading: false
    }},
    [ConnectWalmart.rejected]: (state, action) => {
      return {
      ...state,
      walmartLoading: false
    }},
    // [GetAccessToken.pending]: (state, action) => ({
    //   ...state,
    //   paymentLoading: true,
    // }),
    // [GetAccessToken.fulfilled]: (state, action) => {
    //   return {
    //   ...state
    // }},
    // [GetAccessToken.rejected]: (state, action) => {
    //   return {
    //   ...state,
    //   paymentLoading: false,
    // }},
    [addCard.pending]: (state, action) => ({
      ...state,
      paymentLoading: true,
    }),
    [addCard.fulfilled]: (state, action) => {
      return {
      ...state
    }},
    [addCard.rejected]: (state, action) => {
      return {
      ...state,
      paymentLoading: false,
      squareTokenLoading: false
    }},
    [CreateSubscription.pending]: (state, action) => {
      const initialState = current(state); 
      const { planId, paymentLoading } = initialState || {};
      return {
      ...state,
      skipBasicLoading: planId === 1 && !paymentLoading? true: false
    }},
    [CreateSubscription.fulfilled]: (state, action) => {
      const { success, message } = action.payload || {};
      if (success) {
        const mess = isArray(message) ? message[0] : message;
        notification.success({
          message: 'SUBSCRIBE TO PLAN',
          description: mess || 'Your 30 days trial has started!',
          top: 65
        });
      } else {
        const mess = isArray(message) ? message[0] : message;
        notification.error({
          message: 'SUBSCRIBE TO PLAN',
          description: mess || 'Plan cannot be subscribed',
          top: 65
        })
      }
      return {
      ...state,
      skipBasicLoading: false,
      paymentLoading: false,
      squareTokenLoading: false
    }},
    [CreateSubscription.rejected]: (state, action) => {
      return {
      ...state,
      skipBasicLoading: false,
      paymentLoading: false,
      squareTokenLoading: false
    }},
    // [SaveCardMisc.pending]: (state, action) => ({
    //   ...state,
    // }),
    // [SaveCardMisc.fulfilled]: (state, action) => {
    //   notification.success({
    //     message: 'Saving User Information',
    //     top: 65,
    //   })
    //   return {
    //   ...state
    // }},
    // [SaveCardMisc.rejected]: (state, action) => {
    //   return {
    //   ...state,
    //   paymentLoading: false,
    // }},
    // [SubscribeToPlan.pending]: (state, action) => {
    //   const initialState = current(state); 
    //   const { planId } = initialState || {};
    //   return {
    //   ...state,
    //   skipBasicLoading: planId === 1? true: false,
    //   paymentLoading: planId === 1? false: true,
    // }},
    // [SubscribeToPlan.fulfilled]: (state, action) => {
    //   return {
    //   ...state,
    //   skipBasicLoading: false
    // }},
    // [SubscribeToPlan.rejected]: (state, action) => {
    //   return {
    //   ...state,
    //   skipBasicLoading: false,
    //   paymentLoading: false,
    // }},
    // [QBSubscription.pending]: (state, action) => ({
    //   ...state,
    //   // paymentLoading: true,
    // }),
    // [QBSubscription.fulfilled]: (state, action) => {
    //   notification.success({
    //     message: 'Added Card successfully',
    //     top: 65,
    //   })
    //   return {
    //   ...state,
    //   paymentLoading: false,
    // }},
    // [QBSubscription.rejected]: (state, action) => {
    //   return {
    //   ...state,
    //   paymentLoading: false,
    // }},
    [GetValidMarketplaces.pending]: (state, action) => ({
      ...state,
      marketPlaceLoading: true
    }),
    [GetValidMarketplaces.fulfilled]: (state, action) => {
      const { authorized_market_places } = action?.payload || {};
      return {
      ...state,
      marketPlaces: authorized_market_places,
      marketPlaceLoading: false
    }},
    [GetValidMarketplaces.rejected]: (state, action) => {
      return {
      ...state,
      marketPlaceLoading: false
    }},
    [WarehouseStatusUpdate.pending]: (state, action) => ({
      ...state,
      warehouseLoading: true
    }),
    [WarehouseStatusUpdate.fulfilled]: (state, action) => {
      notification.success({
        message: 'Skipped to warehouse',
        top: 65
      });
      return {
      ...state,
      warehouseLoading: false
    }},
    [WarehouseStatusUpdate.rejected]: (state, action) => {
      return {
      ...state,
      warehouseLoading: false
    }},
    [ReAuthorizeWalmartStore.pending]: (state, action) => ({
      ...state,
      authorizingWalmartStore: true
    }),
    [ReAuthorizeWalmartStore.fulfilled]: (state, action) => {
      const { message } = action.payload.data || {};

      notification.success({
        message: message || 'Walmart Store Authorized Successfully',
        top: 65
      });

      return {
        ...state,
        authorizingWalmartStore: false
      }
    },
    [ReAuthorizeWalmartStore.rejected]: (state, action) => ({
      ...state,
      authorizingWalmartStore: false
    }),
    [ReAuthorizeAmazonStore.pending]: (state, action) => ({
      ...state,
      authorizingAmazonStore: true
    }),
    [ReAuthorizeAmazonStore.fulfilled]: (state, action) => {
      const { message, store } = action.payload.data || {};
      const { id } = store || {};

      notification.success({
        message: message || 'Amazon Store Authorized Successfully',
        top: 65
      });

      return {
        ...state,
        authorizingAmazonStore: false,
        storeData: store,
        storeId: id
      }
    },
    [ReAuthorizeAmazonStore.rejected]: (state, action) => ({
      ...state,
      authorizingAmazonStore: false
    }),
    [GetShopifyAuthCode.pending]: (state, action) => ({
      ...state,
      fetchingShopifyAuthCode: true
    }),
    [GetShopifyAuthCode.fulfilled]: (state, action) => {
      return {
        ...state,
        fetchingShopifyAuthCode: false,
      }
    },
    [GetShopifyAuthCode.rejected]: (state, action) => ({
      ...state,
      fetchingShopifyAuthCode: false
    }),
    [CreateShopifyStore.pending]: (state, action) => ({
      ...state,
      creatingShopifyStore: true
    }),
    [CreateShopifyStore.fulfilled]: (state, action) => {
      const { response, success } = action?.payload?.data || {};
      const notificationObj = {
        top: 65,
        message: 'Create Shopify Store',
        description: response
      };
      success ? notification.success(notificationObj)
        : notification.error(notificationObj);

      return {
        ...state,
        creatingShopifyStore: false,
      }
    },
    [CreateShopifyStore.rejected]: (state, action) => ({
      ...state,
      creatingShopifyStore: false
    }),
    [ReAuthorizeShopifyStore.pending]: (state, action) => ({
      ...state,
      authorizingShopifyStore: true
    }),
    [ReAuthorizeShopifyStore.fulfilled]: (state, action) => {
      const { response, success } = action?.payload?.data || {};
      const notificationObj = {
        top: 65,
        message: 'Authorize Shopify Store',
        description: response
      };
      success ? notification.success(notificationObj)
        : notification.error(notificationObj);

      return {
        ...state,
        authorizingShopifyStore: false,
      }
    },
    [ReAuthorizeShopifyStore.rejected]: (state, action) => ({
      ...state,
      authorizingShopifyStore: false
    }),
    [IsShopifyStoreExists.pending]: (state, action) => ({
      ...state,
      validatingShopifyStore: true
    }),
    [IsShopifyStoreExists.fulfilled]: (state, action) => {
      return {
        ...state,
        validatingShopifyStore: false,
      }
    },
    [IsShopifyStoreExists.rejected]: (state, action) => ({
      ...state,
      validatingShopifyStore: false
    }),
  }
  });
    
    const { reducer, actions } = store;
    
    export const {
      amazonStoreInfo,
      setState,
      clearStore } = actions;
    
    export default reducer;