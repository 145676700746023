import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  fetchingVendors: false,
  vendors: [],
  fetchingAggregateData: false,
  items: [],
  total: 0,
  filters: [],
  sortOptions: {},
  selectedItems: [],
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  net_total: 0,
  margin: 0,
  cogs: 0,
  fetchingDataForPO: false,
  aggregateData: {},
  vendor_items: [],
  details: {
    productDetails: [],
    fetching: false
  }
};

const products = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'SYNC_INVENTORY_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'SYNC_INVENTORY_SUCCESS': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SYNC_INVENTORY_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_EXPORT_PRODUCTS_REQUEST':
    case 'GET_INVENTORY_PRODUCTS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_VENDOR_NAMES_REQUEST': {
      return {
        ...state,
        fetchingVendors: true
      };
    }
    case 'GET_VENDOR_NAMES_SUCCESS': {
      return {
        ...state,
        fetchingVendors: false,
        vendors: action.payload ? [...action.payload] : []
      };
    }
    case 'GET_VENDOR_NAMES_FAILED': {
      return {
        ...state,
        fetchingVendors: false
      };
    }
    case 'GET_DATA_FOR_PO_REQUEST': {
      return {
        ...state,
        fetchingDataForPO: true
      };
    }
    case 'GET_AGGREGATE_DATA_REQUEST': {
      return {
        ...state,
        fetchingAggregateData: true
      };
    } 
    case 'UPDATE_PRODUCT_INFO_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_AGGREGATE_DATA_SUCCESS': {
      return {
        ...state,
        fetchingAggregateData: false,
        aggregateData: action.payload
      };
    } 
    case 'UPDATE_PRODUCT_INFO_SUCCESS': {
      const updatedProductInfo = action.payload
      const { items } = state;
      const updatedItems = items.map((product) => {
        if (product.id === updatedProductInfo.id){
          product = updatedProductInfo;
        }
        return product;
      });
      return {
        ...state,
        fetching: false,
        items: [...updatedItems]
      };
    }
    case 'GET_EXPORT_PRODUCTS_SUCCESS':
    case 'GET_INVENTORY_PRODUCTS_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      };
    }
    case 'GET_DATA_FOR_PO_SUCCESS': {
      return {
        ...state,
        fetchingDataForPO: false,
        ...action.payload
      };
    }
    case 'CLEAR_PO_DATA': {
      return {
        ...state,
        vendor_items: []
      };
    }
    case 'GET_AGGREGATE_DATA_FAILED': {
      return {
        ...state,
        fetchingAggregateData: false
      };
    } 
    case 'CLEAR_PO_DATA_FAILED': {
      return {
        ...state,
        fetchingDataForPO: false
      };
    }
    case 'GET_DATA_FOR_PO_FAILED': {
      return {
        ...state,
        fetchingDataForPO: false
      };
    }
    case 'GET_EXPORT_PRODUCTS_FAILED':
    case 'GET_INVENTORY_PRODUCTS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'UPDATE_PRODUCTS_INFO_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_PAGINATION_INVENTORY_PRODUCTS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    case 'SET_FILTERS_PRODUCTS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_SORT_OPTIONS_PRODUCTS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_INVENTORY_PRODUCTS_DETAILS_REQUEST': {
      return {
        ...state,
        details: {
          ...state.details,
          fetching: true
        }
      };
    }
    case 'GET_INVENTORY_PRODUCTS_DETAILS_FAILED':
    case 'GET_INVENTORY_PRODUCTS_DETAILS_SUCCESS': {
      return {
        ...state,
        details: {
          ...state.details,
          fetching: false,
          ...action.payload
        }
      };
    }

    default:
      return state;
  }
};

export default products;
