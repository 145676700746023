import React, { lazy } from 'react';

const InboundFlow = lazy(() => import('../../containers/inboundFlow/inboundFlowContainer'));
const ShipmentsContainer = lazy(() => import('../../containers/ShipmentsContainer'));
const IncommingPOsContainer = lazy(() => import('../../containers/incommingPOs/incommingPOs'));

export const FBA_INBOUND_ROUTES = [
  {
    path: '/old-inbound-flow',
    component: <InboundFlow />,
    group: 'FBA Inbound',
    reducer: ['inboundFlow', 'shipment', 'stagingInventory']
  },
  {
    path: '/transport-plans',
    component: <ShipmentsContainer />,
    group: 'FBA Inbound',
    reducer: 'shipments'
  },
  {
    path: '/old-incomming-pos',
    component: <IncommingPOsContainer />,
    group: 'FBA Inbound',
    reducer: 'incommingPOs'
  }
];
