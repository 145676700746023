import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  vendor_items: [],
  total_records: 0,
  filters: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  vendors: [],
  items_entries: {}
};

const catalogWithErrors = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GET_FAILURE_CATALOGS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_FAILURE_CATALOGS_SUCCESS': {
      const { errored, file_upload, total_records } = action.payload;
      const { errored_entries, processed_entries, processing_entries } = file_upload;
      return {
        ...state,
        fetching: false,
        total_records,
        vendor_items: errored,
        items_entries: { errored_entries, processed_entries, processing_entries }
      };
    }
    case 'GET_FAILURE_CATALOGS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_VENDOR_NAMES_FOR_FAILURE_CATALOGS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_VENDOR_NAMES_FOR_FAILURE_CATALOGS_SUCCESS': {
      return {
        ...state,
        fetching: false,
        vendors: action.payload ? [...action.payload] : []
      };
    }
    case 'GET_VENDOR_NAMES_FOR_FAILURE_CATALOGS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_PAGINATION_FOR_FAILURE_CATALOGS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    case 'SET_FILTERS_FOR_FAILURE_CATALOGS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_SORT_OPTIONS_FOR_FAILURE_CATALOGS': {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
};

export default catalogWithErrors;
