import Styled from "styled-components";
import Button from "react-bootstrap/Button";
const ButtonWrapper = Styled(Button)`
    margin-bottom:${({ props }) =>
      props?.marginBottom ? `${props?.marginBottom}` : "5px"};
    font-size:${({ theme }) => theme["base-font-size-small"]};

      width: ${(props) => (props.width ? `${props.width}` : "")};
         height: ${(props) => (props.height ? `${props.height}` : "")};
    font-weight:600;
    min-width: ${(props) => (props.minWidth ? `${props.minWidth}` : '77px')};
    padding:6px 12px;
    height: ${(props) => (props.height ? `${props.height}` : '')};
    display:flex;
    align-items:center;
    justify-content:center;
    text-transform:capitalize;
    .d-flex{
       font-weight:600;
    }
    &.btn:disabled {
      background-color: ${({ theme }) => theme['outline-color']};
      border-color: ${({ theme }) => theme['outline-color']};
      color: ${({ theme }) => theme['disabled-color']};
      font-weight: 700;
    }
    &.re-authorized{
      padding:2px 15px;
      margin-bottom:0;
      color:${({ theme }) => theme['danger-color']} !important;
      border-color:${({ theme }) => theme['danger-color']} !important;
    }
    &.add-space-for-button{
      padding:12px 0px;
    }
    &.h-40{
      height:40px;
    }
      &.w-283{
        width:283px;
      }
      &.w-68{
         width:68px;
         margin-bottom:0px;
      }
      &.padding-increase{
        padding:9px;
        margin-bottom: 8px;
      }
      &.w-77{
        width:77px;
        padding:3px 12px;
      }
      &.w-160{
         width:160px;
      }
      &.primary{
        background-color:${({ theme }) => theme['primary-color']};
        span{
          color:#fff;
        }

        &:focus{
          box-shadow:none;
        }
        font-size:${({ theme }) => theme['base-font-size-small']};
        border-color:${({ theme }) => theme['primary-color']};
      }
      &.success{
        background-color:${({ theme }) => theme['success-color']};

        &:focus{
          box-shadow:none;
        }
        font-size:${({ theme }) => theme['base-font-size-small']};
        border-color:${({ theme }) => theme['success-color']};
      }
      &.danger{
        background-color:${({ theme }) => theme['danger-color']};

        &:focus{
          box-shadow:none;
        }
        font-size:${({ theme }) => theme['base-font-size-small']};
        border-color:${({ theme }) => theme['danger-color']};
      }
      &.text-button{
          color:${({ theme }) => theme['primary-color']};
          border-color:transparent;
          background-color:transparent;
          &:disabled{
              color:${({ theme }) => theme['dusty_grey']};
              cursor:not-allowed;
          }
          &.popover-button{
            padding: 0;
          display: inline;
          min-width: 17px;
          }
      }
      &.outlined{
        background-color: transparent;
        color:${({ theme }) => theme['primary-color']};
        border-color:${({ theme }) => theme['primary-color']};
        font-size: ${(props) => (props.fontSize ? `${props.fontSize}` : "12px")};
        &:hover{
          background-color: transparent;
        color:${({ theme }) => theme['primary-color']};
        border-color:${({ theme }) => theme['primary-color']};
        }
        &.filter-button{
          border:1px solid #CCCCCC;
          color:${({ theme }) => theme['regent_gray']};
          min-width: 126px;
          padding: 0 7px;
          margin-right:8px;
        }
        &:disabled{
          color:${({ theme }) => theme['dusty_grey']};
          border-color:${({ theme }) => theme['dusty_grey']};
          background-color: ${({ theme }) => theme['aqua_haze']};
          cursor:not-allowed;
            color:${({ theme }) => theme["primary-color"]};
            border-color:${({ theme }) => theme["primary-color"]};
            width: ${(props) => (props.width ? `${props.width}` : "")};
            height: ${(props) => (props.height ? `${props.height}` : "")};
            font-weight:600;
        }
        &:hover{
                 background-color: transparent;
                 color:${({ theme }) => theme["primary-color"]};
              border-color:${({ theme }) => theme["primary-color"]};
        }
        &.border-added{
          border:2px solid #1565D8;
        }
        &.button-border{
          border:1px solid #CCCCCC;
          color:#78909C;
          padding:3px 0;
          font-size:14px;
          margin-bottom: 0px;
        }
        
      }
      &.bg-grey-ss {
        background: #605f5f;
        border-color: #605f5f;
        &:hover{
        background: #605f5f;
        border-color: #605f5f;
        }
    }
      &.row-btn{
        font-size:14px;
        div{
          flex-direction:row!important;
        }
        svg{
          font-size:13px;
        }
      }
      &.contained {
        background: #1565D8;
        border-radius: 4px;
        padding: 8px 24px;
        color: #fff;
        font-weight: 700;
      }
      &.location-btn {
        height: 32px;
      }
      &.btn-warning{
        border-color:${({ theme }) => theme['warning-color']};
        background-color: transparent;
        color:${({ theme }) => theme['warning-color']};
        &:hover{
          border-color:${({ theme }) => theme['warning-color']};
        background-color: transparent;
        color:${({ theme }) => theme['warning-color']}; 
        }
        &.disabled{
          border-color:#C4C4C4;
        background-color: transparent;
        color:#C4C4C4; 
        }
      }
      &.outlined-danger-small{
        border:2px solid #CF0909;
        background-color: transparent;
        color:#CF0909; 
        padding: 0px 16px;
        min-width: 100px;
        font-size:13px;

      }
      &.not-received-button{
        border-color:#CF0909;
        background-color: transparent;
        color:#CF0909; 
        padding: 2px 12px;
        span{
          max-width: 55px;
          white-space: pre-line;
          line-height: 18px;
          font-weight:400;
          font-size:12px;
        }
        &:hover{
          border-color:#CF0909;
          background-color: transparent;
          color:#CF0909; 
        }
      }
      &.outlined-danger{
          border-color:${({ theme }) => theme['danger-color']};
        background-color: transparent;
        color:${({ theme }) => theme['danger-color']}; 
        padding: 9px 12px;
        margin-top: 12px;
        min-width: 0px;
        &:hover{
          border-color:${({ theme }) => theme['danger-color']};
        background-color: transparent;
        color:${({ theme }) => theme['danger-color']};  
        }
        &.color-change{
          border-color:#CF0909;
          color:#CF0909;
          font-size:14px;
          font-weight:700;
          padding: 1px 8px;
          margin-top: 0px;
        }
      }
      &.shipment-button{
        padding:7px 0px;
      }
      &.outlined-warning{
        border-color:${({ theme }) => theme['danger-color']};
        background-color: transparent;
        color:${({ theme }) => theme['danger-color']}; 
        padding: 9px 12px;
        margin-top: 12px;
        min-width: 0px; 
      }
      &.icon-button{
        min-width:0;
        background-color: transparent;
        border:0;
        padding:0;
        margin-bottom:0;
        &:focus{
          box-shadow:none;
        }
        &:hover{
          background-color:transparent;
        }
        &.qty-added-button{
             border:2px solid  ${({ theme }) => theme['primary-color']};
             padding:8px;
             border-radius:4px;
             display:flex;
             height:15px;
             &:hover{
              background-color:transparent
             }
        }
        &.refresh-button{
          border:1px solid  ${({ theme }) => theme['primary-color']};
          padding:8px;
          height:auto;
        }
        &.border-width-change{
          border:1px solid  ${({ theme }) => theme['primary-color']};
          padding: 12px 4px
        }
      }
      &.add-space{
        margin-top:10px;
      }
      &.pl-btn {
        padding: 4px 12px;
      }
      &.white-button{
        background-color:${({ theme }) => theme['white-color']};
          color:${({ theme }) => theme['primary-color']};
          padding:10px 0;
      }
  `

export { ButtonWrapper };
