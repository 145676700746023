import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  loading: false,
  total_records: 0,
  fetchingForAffiliateFilter: false,
  filters: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  users: [],
  roles: [],
  affiliate_managers: []
};

const adminUsers = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GET_CSV_FILE_REQUEST':
    case 'REMOVE_FROM_AFFILIATE_MANAGER_REQUEST':
    case 'GET_USERS_FOR_ADMIN_DASHBOARD_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'ADD_AFFILIATE_USER': {
      return {
        ...state,
        loading: true
      };
    }
    case 'ADD_AFFILIATE_FAILED':
    case 'ADD_AFFILIATE_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    }
    case 'REMOVE_FROM_AFFILIATE_MANAGER_FAILED':
    case 'REMOVE_FROM_AFFILIATE_MANAGER_SUCCESS':
    case 'GET_CSV_FILE_FAILED':
    case 'GET_CSV_FILE_SUCCESS':
    case 'SET_FILTERS_FOR_ADMIN_USERS':
    case 'GET_USERS_FOR_ADMIN_DASHBOARD_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        fetching: false
      };
    }
    case 'GET_USERS_FOR_ADMIN_DASHBOARD_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_PAGINATION_FOR_ADMIN_USERS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    case 'SET_SORT_OPTIONS_FOR_ADMIN_USERS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'CLEAR_FILTERS_FOR_ADMIN_USERS': {
      return {
        ...state,
        filters: []
      }
    }
    default:
      return state;
  }
};

export default adminUsers;
