import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  users: [],
  total_records: 0,
  filters: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  }
};

const workers = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'SET_FILTERS_WORKERS':
    case 'SET_SORT_OPTIONS_WORKERS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_WORKERS_REQUEST':
    case 'ADD_WORKER_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_WORKERS_SUCCESS':
    case 'ADD_WORKER_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      };
    }
    case 'SET_PAGINATION_WORKERS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    case 'GET_WORKERS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    default:
      return state;
  }
};

export default workers;
