import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Header from '../../header/header'
import { StoreWrapper } from '../style'
import Shopify from '../../../assets/images/newImages/shopify-fba.svg'
import Button from '../../uiComponent/button/index';
import Check from '../../../assets/images/newImages/check.svg';

const ShopifySuccess = () => {
  const navigate = useNavigate()
  const [isBackToStores, setIsBackToStores] = useState(false);

  useEffect(() => {
    setIsBackToStores(localStorage.getItem('back-to-stores'));
  }, []);

  return (
    <StoreWrapper>
      <Header login={true} />
      <div  className='change-background'>
      <div className="select-region">
        <div className="mb-72">
          <h1>Add Store</h1>
        </div>
        <Row className="justify-content-center align-items-center">
          <Col lg={4} md={6} sm={12}>
            <div className="justify-content-center align-items-center d-flex flex-column grid-row-gap-20">
              <div className="mb-40">
                <img src={Check} alt="no-success" />
              </div>
              <h1 className="color-primary mb-28 fs-60">Congratulations</h1>
              <h2>You are succesfully connected with</h2>
              <div className="d-flex grid-column-gap-20 align-items-center">
                <div>
                  <img src={Shopify} alt="no-marketPlace" width="206px" height="54px" />
                </div>
              </div>
              <caption className="fs-14">Your store has been successfully setup, ready to be used.</caption>
              <div className="d-flex grid-column-gap-30">
                {
                  isBackToStores ?
                    <Button text="Back To Stores" className="primary w-160" onClick={() => {
                      localStorage.removeItem('back-to-stores');
                      localStorage.removeItem('re-authorize');
                      navigate("/account?section=stores");
                    }} /> :
                    <>
                      <Button text="Select Price Plan" className="outlined w-160" onClick={() => navigate("/onboarding/select-plan")} />
                      <Button text="Add Another Store" className="primary w-160" onClick={() => navigate("/onboarding/store-add")} />
                    </>
                }
              </div>
            </div>
          </Col>
        </Row>
      </div>
      </div>
    </StoreWrapper>
  )
}

export default ShopifySuccess;
