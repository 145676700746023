import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import crypto from 'crypto';
import { isEmpty } from 'lodash';
import { Tooltip, Spin } from 'antd';
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Header from '../../header/header'
import { APP_ID } from '../../../constants/onBoarding';
import { StoreWrapper } from '../style'
import { amazonStoreInfo, setState, GetStores } from '../../../slices/store';
import { BiLeftArrowAlt } from "react-icons/bi";
import Button from '../../uiComponent/button/index';
import SearchInput from '../../uiComponent/inputs/input'
import MapGrey from '../../../assets/images/newImages/map_gray.svg'
import MapEuorpe from '../../../assets/images/newImages/map_europe.svg'
import MapEast from '../../../assets/images/newImages/map_east.svg'
import MapNorth from '../../../assets/images/newImages/map_north.svg'
import { NA_IMAGES, EU_IMAGES, FE_IMAGES } from '../../../constants/onBoarding';
import clearRequestArray from '../../../utils/interseptors/pendingRequest';
import axios from '../../../../axios_config';
let controller = null;

const selectMap =
{
  america: MapNorth,
  europe: MapEuorpe,
  east: MapEast,
  grey: MapGrey
}

const SelectRegion = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { storeName: savedStoreName, stores, getStoreLoading } = useSelector(state => state?.store || {});

  const [regionSelect, setRegionSelect] = useState(selectMap.grey)
  const [region, setRegion] = useState('');
  const [storeName, setStoreName] = useState(savedStoreName);

  const isAnyStoreExist = !isEmpty(stores);

  useEffect(() => {
    controller = new AbortController();
    axios.defaults.signal = controller?.signal;
    dispatch(GetStores({ platformType: 'Amazon' }));

    return () => {
      clearRequestArray();
      controller.abort();
      controller = null;
      axios.defaults.signal = null;
    };
  }, []);

  const handleAddRegion = async() => {
    const state = crypto.randomBytes(20).toString("hex");
    const payload = {
      storeName: storeName,
      state,
      region,
    };
    dispatch(amazonStoreInfo(payload));
    dispatch(setState({ field: "storeId", value: '' }));
    dispatch(setState({ field: "marketPlaces", value: [] }));
    dispatch(setState({ field: "stores", value: [] }));
    let url;
    if (region === "eu") {
      url = `https://sellercentral-europe.amazon.com/apps/authorize/consent?application_id=${APP_ID}&state=${state}&redirect_url=https://sales.support/auth/selling_partner`
    } else if(region === "na") {
      url = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${APP_ID}&state=${state}&redirect_url=https://sales.support/auth/selling_partner`;
    } else if (region === "fe") {
      url = `https://sellercentral-japan.amazon.com/apps/authorize/consent?application_id=${APP_ID}&state=${state}&redirect_url=https://sales.support/auth/selling_partner`
    }
    window.open(url, "_self");
  }

  const handleStoreName = (e) => {
    const value = e.target.value;
    setStoreName(value);
  };

  const checkForName = () => {
    const index = stores?.findIndex(store => store.name === storeName);
    return index !== -1? true: false;
  };

  const checkForRegion = (region) => {
    const index = stores?.findIndex(store => store.region === region);
    return index !== -1? true: false;
  };

  const disable = isEmpty(storeName) || isEmpty(region) || checkForName();

  const tooltipErrorMessage = () => {
    if(isEmpty(storeName)) return 'Enter store name.';
    if(isEmpty(region)) return 'Select region.';
    if(checkForName()) return 'Store already exist with this name.';
  }


  return (
    <StoreWrapper>
      <Header login={true} />
      <div  className='change-background'>
      <div className="select-region">
        <div className="arrow-box grid-column-gap-10">
          <BiLeftArrowAlt className="cursor-pointer" onClick={() => navigate(-1)} />
          <h1>Add Amazon Region</h1>
        </div>
        <Spin tip='Processing...' spinning={getStoreLoading? true: false}>
        <div className="search-box text-center mt-20 mb-20">
          <Row className="align-items-center justify-content-center">
            <Col lg={6} md={8} sm={12}>
              <SearchInput placeholder="Enter Store Name Here (Required)" value={storeName} onChange={handleStoreName} />
            </Col>
          </Row>
        </div>
        <Row className="justify-content-center">
          <Col lg={3} md={5}>
            <div className="pl-27">
              <h3 className="color-primary fw-bold mb-3">Select MarketPlace Region</h3>
              {/* <div className="caption">Select Region from following</div> */}
              <div className="region">
              <Tooltip title={checkForRegion('na') ? 'You already have connected store against this region!' : ''} placement="right">
                  <div>
                    <div className={`region_block ${checkForRegion('na')? 'disable-true': ''}`} onClick={() => {
                      setRegionSelect(selectMap.america)
                      setRegion('na')
                    }}>
                      <div className="d-flex align-items-center mb-2">
                        <div className="round">
                          <input type="checkbox" checked={region === 'na'} id="checkbox-na" onChange={() => setRegion('na')}/>
                          <label htmlFor="checkbox-na"></label>
                        </div>
                        <span>North America</span>
                      </div>
                      <div className="d-flex grid-column-gap-20 flex-wrap justify-content-center mt-10">
                        {NA_IMAGES.map((country, index) => <img src={country} key={index}/>)}
                      </div>
                    </div>
                  </div>
                </Tooltip>
                <Tooltip title={checkForRegion('eu') ? 'Region already exists' : ''} placement="right">
                  <div>
                    <div className={`region_block disable-true`} onClick={() => {
                      setRegionSelect(selectMap.europe)
                      setRegion('eu')
                    }}>
                      <div className="d-flex align-items-center mb-2">
                        <div className="round">
                          <input type="checkbox" checked={region === 'eu'} id="checkbox-eu" onChange={() => setRegion('eu')}/>
                          <label htmlFor="checkbox-eu"></label>
                        </div>
                        <span>Europe</span>
                      </div>
                      <div className="d-flex grid-column-gap-20 justify-content-center flex-wrap mt-10">

                        {EU_IMAGES.map((country, index) => <img src={country} alt="no-country" key={index}/>)}
                      </div>
                    </div>
                  </div>
                </Tooltip>
                <Tooltip title={checkForRegion('fe') ? 'Region already exists' : ''} placement="right">
                  <div>
                    <div className={`region_block disable-true`} onClick={() => {
                      setRegionSelect(selectMap.east)
                      setRegion('fe')
                    }}>
                      <div className="d-flex align-items-center mb-2">
                        <div className="round">
                          <input type="checkbox" checked={region === 'fe'} id="checkbox-fe" onChange={() => {setRegion('fe'); setRegionSelect(selectMap.east)}}/>
                          <label htmlFor="checkbox-fe"></label>
                        </div>
                        <span>Far East</span>
                      </div>
                      <div className="d-flex grid-column-gap-20 flex-wrap justify-content-center  mt-10">

                        {FE_IMAGES.map((country, index) => <img src={country} alt="no-country" key={index} />)}
                      </div>
                    </div>
                  </div>
                </Tooltip>
                <div className="gap-3" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    className="w-100" 
                    text="Select Price Plan" 
                    disabled={isAnyStoreExist? false: true} 
                    onClick={() => navigate('/onboarding/select-plan')}
                  />
                  <Tooltip  title={disable ? tooltipErrorMessage() : ''} placement="right" >
                    <div className='w-100'>               
                      <Button 
                        className="w-100" 
                        text="Register Account" 
                        onClick={handleAddRegion} 
                        disabled={disable}
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={9} md={7}>
            <div className="map_region">
              <img src={regionSelect} alt="no-region" />
            </div>
          </Col>
        </Row>
        </Spin>
      </div>
      </div>
    </StoreWrapper>
  )
}

export default SelectRegion
