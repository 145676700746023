import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { BsPencilSquare, BsTrash } from "react-icons/bs";
import { Row, Col, Table } from "react-bootstrap";
import { useSelector, useDispatch } from 'react-redux';
import { Spin, Popconfirm, Tooltip, Popover } from 'antd';
import moment from "moment";
import { debounce, findIndex, cloneDeep, isEmpty, trim } from 'lodash';
import {
  GetAllShipmentPOs,
  GetUserPoIndices,
  DeleteArrivedPo,
  GetUsersList
} from '../../../slices/fba-inbound/to-ship';
import { GetStats } from '../../../slices/fba-inbound';
//style
import { TilesWrapper } from "../style";
//compoennt
import SearchInput from "../../../components/uiComponent/inputs/searchinput/index";
import Drawer from "../../../components/uiComponent/drawer/index";
import AgGrid from "../../../components/ag-grid-table/index";
import Select from "../../../components/uiComponent/select/index";
import TilesPagination from "../../../components/uiComponent/tilesPagination/index";
//assets
import NoInbound from "../../../assets/images/newImages/no-inbound.svg";

const ToShip = () => {
  const navigate = useNavigate();
  const modalSearchRef = useRef();
  const editHeader = ["User Name", "Email", "company Name", "Total PO’s", "SKU", "Unit’s", ""];
  const [editChecked, setEditChecked] = useState(false);
  const [filters, setFilters] = useState([]);
  const [modalFilters, setModalFilters] = useState([]);
  const [page, setPage] = useState({ pageSize: 25, pageNumber: 1 });
  const [currentUserIndex, setCurrentUserIndex] = useState(-1);
  const [selectedUser, setSelectedUser] = useState('');

  const dispatch = useDispatch();
  const { toShip: {
    fetchingShipmentPOs,
    totalCount,
    shipmentPOs,
    userPoIndices,
    fetchingUserPoIndices,
    usersList,
    fetchingUsersList,
    deletingArrivedPo
  }, } = useSelector((state) => state.fbaInbound || {});

  useEffect(() => {
    dispatch(GetAllShipmentPOs({ filters, page }));
    dispatch(GetUsersList());
  }, []);

  const {
    id,
    user_id,
    po_count,
    sku_count,
    unit_count,
    created_at,
    updated_at,
    user: {
      first_name,
      last_name,
      email,
      company: {
        name: company_name
      } = {}
    } = {}
  } = shipmentPOs?.[currentUserIndex] || {};
  const user_name = `${first_name ?? ''} ${last_name ?? ''}`;

  const getFormattedDate = (date) => (date ? moment(date).format('DD MMM, YYYY HH:mm') : 'N/A');

  const handleFilter = debounce(({ isDropDown, field, value }, isOfModal) => {
    const filtersArray = cloneDeep(isOfModal ? modalFilters : filters);
    const filterIndex = findIndex(filtersArray, { field });
    if (filterIndex === -1) {
      if (value) {
        filtersArray.push({
          field,
          type: isDropDown ? 'drop-down' : 'search',
          value,
        });
      }
    } else if (value) {
      filtersArray[filterIndex].value = value;
    } else {
      filtersArray.splice(filterIndex, 1);
    }
    if (isOfModal) {
      setModalFilters(filtersArray);
      const { user_id } = shipmentPOs[currentUserIndex] || {};
      dispatch(GetUserPoIndices({ user_id, filters: filtersArray }));
    }
    else {
      setFilters(filtersArray);
      const newPaginationHash = { ...page, pageNumber: 1 };
      setPage(newPaginationHash);
      dispatch(GetAllShipmentPOs({ filters: filtersArray, page: newPaginationHash }));
    }
  }, 500);

  const handlePagination = () => {
    const newPaginationHash = { ...page, pageNumber: (page.pageNumber + 1) };
    setPage(newPaginationHash);
    dispatch(GetAllShipmentPOs({ filters, page: newPaginationHash }));
  };

  const defaultColDef = {
    resizable: true,
    sortable: true,
    suppressMenu: true,
    suppressMovable: true,
  };

  const editCheckedPoColDef = [
    {
      headerName: "Po #",
      field: 'pending_po_index.po_code',
      cellRenderer: ({ value }) => (value ? value : "N/A"),
      minWidth: 190,
      width: 190,
      flex: 1,
    },
    {
      headerName: "Tracking/Pro Number",
      field: 'tracking_number',
      cellRendererFramework: ({ value }) => {
        if (isEmpty(value) || (value && isEmpty(JSON.parse(value)))) return <div>N/A</div>;
        const trackingNumbers = JSON.parse(value).join(',  ');

        return (
          <Popover
            title="Tracking Numbers: "
            content={
              JSON.parse(value)?.map((element) => (<div>{element}</div>))
            }
          >
            <span>{trackingNumbers}</span>
          </Popover >
        );
      },
      minWidth: 420,
      width: 420,
      flex: 1,
    },
    {
      headerName: "Checked-In At",
      field: 'received_at',
      cellRenderer: ({ value }) => getFormattedDate(value),
      minWidth: 150,
      width: 150,
      flex: 1,
    },
    {
      headerName: "",
      minWidth: 50,
      width: 50,
      flex: 1,
      cellRendererFramework: ({ data }) => {
        const { id } = data || {};

        let updatedUserId = null;
        setCurrentUserIndex(currentUserIndex => {
          const { user_id } = shipmentPOs?.[currentUserIndex] || {};
          updatedUserId = user_id;
          return currentUserIndex;
        });

        return (
          <span className="color-danger text-end">
            <Popconfirm
              key='delete_tracking_no'
              placement='left'
              title='Are you sure you want to delete these tracking number(s)?'
              onConfirm={() => {
                dispatch(DeleteArrivedPo(id)).then(({ payload } = {}) => {
                  const { data } = payload || {};
                  const { success } = data || {};
                  if (success) {
                    dispatch(GetUserPoIndices({ user_id: updatedUserId, filters: modalFilters }))
                      .then(({ payload }) => {
                        const { data } = payload || {};
                        const { success, count } = data || {};
                        if (success) {
                          if (count === 0) {
                            setEditChecked(false);
                            setModalFilters([]);
                          }
                          dispatch(GetAllShipmentPOs({ filters, page }));
                          dispatch(GetStats());
                        }
                      });
                  }
                })
              }}
              okText='Yes'
              cancelText='No'
            >
              <BsTrash />
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const users = usersList?.map(({ id, first_name, last_name, email, company: { name } }) => {
    return {
      value: id,
      label: (
        <>
          <h3 className="fs-14 mb-0">{first_name + last_name}</h3>
          <span className="fs-12 color-grey d-block">{`${name} `}</span>
          <span className="fs-12 color-grey">{`${email} `}</span>
        </>
      ),
    };
  }) || [];

  return (
    <>
      <Row className="mt-4">
        <Col md={8}>
          <SearchInput placeholder="Search by Invoice #, PO #, Order ID, Tracking No."
            onChange={({ target }) => handleFilter({ isDropDown: false, field: '', value: target.value })}
          />
        </Col>
        <Col md={4}>
          <Select
            placeholder="User"
            isClearable={true}
            value={selectedUser}
            loading={fetchingUsersList}
            disabled={fetchingUsersList}
            onChange={(event) => {
              const value = event?.value || '';
              const label = trim(event?.label?.props?.children?.[0]?.props?.children) ||
                trim(event?.label?.props?.children?.[2]?.props?.children) || "";
              setSelectedUser(value ? { value, label } : '');
              handleFilter({ isDropDown: true, field: 'user_id', value })
            }}
            className="h-32  w-276"
            options={users}
          />
        </Col>
      </Row>

      <Spin tip="Loading..." spinning={fetchingShipmentPOs || deletingArrivedPo}>
        {(shipmentPOs.length) ?
          <>
            <TilesWrapper height="433px">
              {shipmentPOs?.map(({
                id,
                user_id,
                po_count,
                sku_count,
                unit_count,
                created_at,
                updated_at,
                user: {
                  first_name,
                  last_name,
                  email,
                  company: {
                    name: company_name
                  }
                }
              }, index) => {
                const user_name = `${first_name ?? ''} ${last_name ?? ''}`;

                return (
                  <div className="tiles-box">
                    <Table responsive className="tiles-table">
                      <thead>
                        <tr>
                          {editHeader.map((header) => (
                            <th>{header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>{user_name || 'N/A'}</span>
                          </td>
                          <td>
                            <span>{email || 'N/A'}</span>
                          </td>
                          <td>
                            <span>{company_name || 'N/A'}</span>
                          </td>
                          <td>
                            <span>{po_count || 0}</span>
                          </td>
                          <td>
                            <span>{sku_count || 0}</span>
                          </td>
                          <td>
                            <span>{unit_count || 0}</span>
                          </td>
                          <td>
                            <div className="d-flex flex-row align-items-center gap-3">
                              <Tooltip title='Edit Checked in PO’s'>
                                <BsPencilSquare className="color-primary cursor-pointer" onClick={() => {
                                  setCurrentUserIndex(index);
                                  dispatch(GetUserPoIndices({ user_id, filters: modalFilters }));
                                  setEditChecked(true);
                                }} />
                              </Tooltip>
                              <Tooltip title={!po_count ? 'No Pending Pos For Shipment' : 'Review Shipment Plan'}>
                                <span
                                  className={`icon-arrow-right ${!po_count ? 'disable-cursor' : 'cursor-pointer'}`}
                                  onClick={() => po_count && navigate(`/fba-inbound/shipment-plan/${user_id}`)}
                                ></span>
                              </Tooltip>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                );
              })}
            </TilesWrapper>
            <TilesPagination total={totalCount} offset={shipmentPOs.length} onClick={handlePagination} />
          </> :
          <img src={NoInbound} alt="no-data" className="mx-auto d-block" />
        }
      </Spin>

      <Drawer show={editChecked} size="900px">
        <TilesWrapper className="mt-0">
          <div className="drawer-padding">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex gap-2 align-items-center">
                <span class="icon-arrow-left cursor-pointer" onClick={() => {
                  setModalFilters([]);
                  setEditChecked(false);
                  modalSearchRef.current.value = '';
                }}></span>
                <h2 className="mb-0 color-black">Edit Checked in PO’s</h2>
              </div>
              <SearchInput placeholder="Search by PO #, Tracking/Pro #"
                inputRef={modalSearchRef}
                onChange={({ target }) => handleFilter({ isDropDown: false, field: '', value: target.value }, true)}
              />
            </div>
            <TilesWrapper className="mt-0">
              <div className="marketplace-information mb-3">
                <div className="details width-0">
                  <div className="mb-0">
                    <span>UserName</span>
                    <span>{user_name || 'N/A'}</span>
                  </div>
                  <div>
                    <span>company Name</span>
                    <span>{company_name || 'N/A'}</span>
                  </div>
                  <div>
                    <span>Email</span>
                    <span>{email || 'N/A'}</span>
                  </div>
                  <div>
                    <span>Total PO’s</span>
                    <span>{po_count || 0}</span>
                  </div>
                  <div>
                    <span>SKU</span>
                    <span>{sku_count || 0}</span>
                  </div>
                  <div>
                    <span>Units</span>
                    <span>{unit_count || 0}</span>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <span className="color-danger fw-bold">Checked In History</span>
              </div>
              <Spin tip="Loading..." spinning={fetchingUserPoIndices || deletingArrivedPo}>
                <AgGrid
                  user_referrals={userPoIndices}
                  defaultColDef={defaultColDef}
                  columnDefinitions={editCheckedPoColDef}
                  rowHeight={42}
                  className="checkin-table"
                />
              </Spin>
            </TilesWrapper>
          </div>
        </TilesWrapper>
      </Drawer>
    </>
  );
};
export default ToShip;
