import React, { useState, useMemo } from 'react'
import { object, string, ref, bool } from 'yup';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Logo from "../../assets/images/newImages/new_log.png";
import ProdImg from "../../assets/images/newImages/prod-img.svg";
import { AuthWrapper, PhoneNumberWrapper } from '../profile/style'
import { Form, Row, Col, Container } from "react-bootstrap"
import InfoVideo from '../profile/component/infoVideo'
import LabelInput from '../uiComponent/inputs/labelInput'
import PhoneInput from '../uiComponent/inputs/phoneNumberInput'
import CheckBox from '../uiComponent/checkBox'
import Button from '../uiComponent/button/index'
import { BiLeftArrowAlt } from "react-icons/bi";
import { AiFillEyeInvisible, AiFillEye, AiOutlineMenu } from "react-icons/ai";
import { GetScannedItem, setField } from "../../slices/internalStorage";
import { isEmpty, debounce } from 'lodash';
import { MobileWrapper, Header, Content, Footer, Title, InputWrapper, CardDetail, ProductDetail, ScanFloating } from './mobile.styled';

const ScanTransferProduct = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { scannedProductToTransfer, scannedItemLoading } = useSelector(state => state?.internalStorage || {});

    const { identifier, image_url, quantity, title, stored_quantity } = scannedProductToTransfer || {};

    const navigateToChooseOldLocation = () => {
      navigate('/choose-old-location')
    }

    const handleScannedProduct = debounce(async(event) => {
      const value = event.target.value;
      if(!isEmpty(value)) {
        const response = await dispatch(GetScannedItem({ id: value }));
        if(isEmpty(response.error)) {
          const { staging_item } = response?.payload?.data || {};
          dispatch(setField({ field: 'scannedProductToTransfer', value: staging_item }));
        }
      }
    }, 1000)

    return (
        <MobileWrapper className=''>
            <div className='mobile-page'>
                <div className='container-fluid px-0'>
                    <Spin tip='Processing...' spinning={scannedItemLoading}>
                    <Content className=''>
                        <Title>
                            <h2>
                                <span
                                    className="icon-arrow-left cursor-pointer" onClick={() => navigate('/home')}></span>
                                Scan Product to Transfer</h2>
                        </Title>
                        <div className='content-inner'>
                        <InputWrapper className='modify-margin'>
                            <LabelInput className="input-with-icon" icon="icon-barcode" label="Scan Product Barcode" placeholder="Scan Barcode" onChange={(e) => handleScannedProduct(e)} />
                        </InputWrapper>
                        {
                          !isEmpty(scannedProductToTransfer) && 
                          <ProductDetail>
                            <div class="d-flex align-items-center">
                                <div>
                                    <img width="42" height="42" src={image_url} alt="no-product" />
                                </div>
                                <div>
                                    <span class="fs-14 color-secondary">{title}</span>
                                </div>
                            </div>
                            <ul class="d-flex">
                                <li class="">
                                    <span class="fs-12 d-block">Identifier</span>
                                    <span class="color-primary fs-14 fw-bold">{identifier}</span>
                                </li>
                                <li class="">
                                    <span class="fs-12 recent-grey d-block">Quantity</span>
                                    <span class="fs-14">{stored_quantity}</span>
                                </li>
                            </ul>
                          </ProductDetail>
                        }
                        </div>
                    </Content>
                    </Spin>
                    <Footer>
                        <Button disabled={isEmpty(scannedProductToTransfer)} className="outlined btn-block" text="Go to Locations" onClick={navigateToChooseOldLocation}/>
                    </Footer>
                    {/* <ScanFloating>
                        <span className='icon-barcode cursor-pointer'></span>
                    </ScanFloating> */}
                </div>
            </div>
        </MobileWrapper>
    )
}

export default ScanTransferProduct;
