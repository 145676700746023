import styled from "styled-components";
import dropdownIcon from "../../assets/images/pagination-arrow-down.svg"
import partiallyBg from "../../assets/images/newImages/partially-bg.svg"

const FirstStepWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  align-self: stretch;
  .accordion-item {
    background: #ddd;
    width: 100%auto;
    color: #fff;
    cursor: pointer;
    user-select: none;
    .accordion-data {
      background-color: #fff;
      color: #000;
      height: 0px;
      transition: height all 0.3s ease;

      &.accordion-data.active-colapse {
        height: 400px;
        animation: fadeIn 0.3s ease;
      }
    }
  }
  .accordion-overlay-wrapper {
    width: 100%;
  }
  @keyframes fadeIn {
    from {
      height: 0;
    }
    to {
      height: 400px;
    }
  }
`;
const StepperStyleWrapper = styled.div `
    width: 100%;
.modal-stepper-ui {
    width: 200px;
}
.purchase-order-popup-wrapper .ant-collapse-content {
    overflow: visible;
}
.ant-collapse.ant-collapse-icon-position-left.last-step-accordion .ant-collapse-content {
    overflow: visible;
}
.ant-select-selection__rendered {
    height: 100%;
  }
  .ant-select-selection__clear{
    display: flex;
  }
  /* .ant-select-search--inline{
    top: -8px;
  } */
  .ant-collapse.ant-collapse-icon-position-left {
    border: none;
  }
  .last-step-accordion .ant-collapse-item {
    border: 1px solid var(--Light-Mid-Grey, #e6e6e6);
    border-radius: 3px;
    border-left: none;
    border-bottom: none;
    border-right: none;
    padding-top: 24px;
    &:nth-child(3) {
      border-bottom: 1px solid var(--Light-Mid-Grey, #e6e6e6);
      padding-bottom: 24px;
    }
  }
  .shipment-wrapper {
    .item-heading {
      span {
        width: 20px;
        height: 20px;
        background: rgba(0, 163, 255, 1);
        border-radius: 20px;
      }
      svg {
        font-size: 16px;
        transform: rotate(130deg);
      }
    }
    p.complete-text {
      color: var(--Success-green, #009c34);
      text-align: right;
      font-family: Titillium Web;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      margin: 0px;
    }
    &.completed-accordion {
      .item-heading {
        h2 {
          color: var(--Primary, #1565d8);
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }
        svg {
          font-size: 16px;
          transform: rotate(-45deg);
          color: #1565d8;
        }
        span {
          width: 20px;
          height: 20px;
          background: #009C34;
          border-radius: 20px;
        }
      }
    }
    &.active-accordion {
      .item-heading {
        h2 {
          color: var(--Primary, #1565d8);
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px;
        }
        svg {
          font-size: 16px;
          transform: rotate(-45deg);
          color: #1565d8;
        }
        span {
          width: 20px;
          height: 20px;
          background: #1565d8;
          border-radius: 20px;
        }
      }
    }
    &.complete-accordion {
      .item-heading {
        span {
          width: 20px;
          height: 20px;
          background: #009c34;
          border-radius: 20px;
        }
      }
    }
  }
  .po-in-draft-wrapper {
    background: #fff;
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 32px;
    border-bottom: 1px solid var(--Light-Mid-Grey, #e6e6e6);
    .title-wrapper {
      display: flex;
      align-items: center;
      grid-gap: 16px;
      h1 {
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 49px;
        margin: 0px;
        span {
          color: var(--Primary, #1565d8);
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 49px;
        }
      }
      p {
        border-radius: 16px;
        border: 1px solid var(--Success, #009c34);
        background: var(--green-5-opacity, rgba(0, 156, 52, 0.05));
        margin: 0px;
        padding: 3px 8px;
        color: var(--Success, #009c34);
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px; /* 116.667% */
      }
    }
    .banner-items-wrapper {
      display: flex;
      align-items: baseline;
      grid-gap: 80px;
      .banner-items {
        display: flex;
        flex-direction: column;
        grid-row-gap: 12px;
        h2 {
          color: #515253;

          /* Button */
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px; /* 128.571% */
          margin: 0px;
        }
        p {
          color: #515253;

          /* Body 2 */
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          margin: 0px;
        }
      }
    }
  }
  .ant-steps-icon {
    i {
      svg {
        margin-top: -6px;
      }
    }
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
    grid-row-gap: 8px;
  }
  .link-overlay-wrapper {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    margin-top: 36px;
    a {
      color: var(--Primary, #1565d8);
      text-decoration: none;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .stepper-steps-wrapper {
    margin-top: 40px;
    display: flex;
    justify-content: end;
    flex-direction: row-reverse;
    .button-wrapper {
      display: flex;
      height: 44px;
      padding: 8px 24px;
      border-radius: 4px;
      background: var(--Primary, #1565d8);
      color: var(--White, #fff);
      text-align: right;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 106.667% */
      letter-spacing: 0.3px;
      align-items: center;
    }
    .button {
      display: flex;
      width: 130px;
      height: 44px;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      color: #fff;
      text-align: right;
      border-radius: 4px;
      background: var(--Link, #0057d3);
      font-size: 15px;
      border: none;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.3px;
      grid-gap: 8px;
      svg {
        font-size: 24px;
      }
      &.disable-btn-wrapper{
        border:1px solid #d9d9d9;
        background-color: #f5f5f5;
        color:#b7b7b7;
        &:hover{
        border:1px solid #d9d9d9;
        background-color: #f5f5f5;
        color:#b7b7b7;
        }
      }
    }
    .back-fix {
      color: var(--Primary, #1565d8);
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      display: flex;
      line-height: 16px;
      letter-spacing: 0.3px;
      border: none;
      background: transparent;
      align-self: center;
      align-items: center;
      grid-gap: 8px;
      svg {
        font-size: 24px;
      }
    }
    .button-outline {
      border-radius: 4px;
      border: 1px solid var(--Primary, #1565d8);
      padding: 8px 24px;
      height: 44px;
      margin-right: 16px;
      color: var(--Primary, #1565d8);
      text-align: right;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.3px;
    }
  }
  .ant-collapse-content.ant-collapse-content-active {
    border: none;
  }
  header {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-row-gap: 16px;
    h1 {
      color: #000;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      margin: 0px;
    }
    .header-data {
      position: relative;
      padding-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 88px;
        height: 4px;
        border-radius: 6px;
        background: #00a3ff;
      }
      p {
        color: #4e5969;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 0px;
      }
    }
  }
  .ant-collapse-item {
    border-bottom: none;
  }
  .ant-collapse-header {
    padding: 8px 20px !important;
    .anticon.anticon-right.ant-collapse-arrow {
      display: none;
    }
  }

  .item-wrapper {
    display: flex;
    justify-content: space-between;
    grid-gap: 16px;
    height:49px;
    align-items: center;
    .item-heading {
      display: flex;
      align-items: center;
      grid-gap: 8px;
      h2 {
        color: var(--Heading, #000);
        margin: 0px;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 111.111% */
        user-select: none;
      }
      h5 {
        color: var(--Primary, #1565d8);
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 111.111% */
        margin: 0px;
        user-select: none;
      }
    }
    button {
      border: none;
      background-color: transparent;
      display: flex;
      align-items: center;
      grid-gap: 8px;
      color: var(--Accent-blue, #00a3ff);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 10px; /* 83.333% */
      box-shadow: none;
      outline: none;
    }
  }
  .ant-collapse.ant-collapse-icon-position-left {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    grid-row-gap: 30px;
    .ant-collapse-item{
      border-bottom: none;
      .ant-collapse-header{
        display: flex;
        align-items: center;
        min-height: 49px;
        background-color: #F1FBFE;
      }
    }
  }
`;

const AddPurchaseOrderStyle = styled.div `
  display: flex;
  flex-direction: column;
  grid-row-gap: 16px;
.last-step-accordion .ant-collapse-content {
    overflow: visible;
}
  .fix-height-delivery-date {
    max-height: 180px;
    overflow-y: auto;
  }
  .select-wrapper {
    .ant-select-selection--single {
      position: relative;
      cursor: pointer;
      padding: 8px;
      width: 170px;
    }
    .ant-select-selection-selected-value {
      color: var(--Primary-Text, #4e5969);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.06px;
    }
  }
  .create-item-wrapper {
    grid-gap: 24px;
    display: flex;
    align-items: end;
    .create-item {
      display: flex;
      align-items: center;
      grid-gap: 8px;
      input {
        color: var(--Primary-Text, #4e5969);
        width: 104px;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.06px;
        &.ant-select-search__field{
          position: relative;
          top: -8px;
        }
      }
      button {
        width: 80px;
        text-wrap: wrap;
        height: auto;
        padding: 8px;
        color: var(--Primary, #1565d8);
        text-align: center;
        font-family: Titillium Web;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0.06px;
      }
    }
    .discrepency-wrapper {
      display: flex;
      flex-direction: column;
      grid-row-gap: 8px;
      button {
        border-radius: 4px;
        border: 1px solid var(--Light-Mid-Grey, #e6e6e6);
        background: #fff;
        padding: 8px;
        display: flex;
        svg {
          color: #1565d8;
        }
      }
      p {
        color: var(--Primary-Text, #4e5969);
        font-family: Titillium Web;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 133.333% */
        letter-spacing: 0.06px;
        margin: 0;
      }
    }
    .button-increase-wrapper {
      display: flex;
      align-items: center;
      grid-gap: 8px;
      span {
        display: flex;
        width: 102px;
        height: 32px;
        padding: 8px;
        border-radius: 4px;
        border: 1px solid var(--Primary, #1565d8);
        background: #fff;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: var(--Primary, #1565d8);
        font-family: Titillium Web;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 114.286% */
        letter-spacing: 0.07px;
      }
    }
  }

  .purchase-order-popup-wrapper{
    .create-item-wrapper{
      .create-item{
        input{
          position: relative;
          top: -8px;
        }
      }
    }
  }
  .text-container {
    border-radius: 4px;
    margin-top: 16px;
    grid-gap: 40px;
    border: 2px solid var(--Warning, #f90);
    background: #fffaf2;
    display: flex;
    align-items: center;
    padding: 8px;
    .text-item {
      display: flex;
      flex-direction: column;
      grid-row-gap: 10px;
      p {
        margin: 0px;
        color: var(--Primary-Text, #4e5969);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 114.286% */
      }
    }
  }
  .select-ui-wrapper {
    border: 1px solid rgba(0, 71, 255, 1);
    border-radius: 6px;
    background: #fff;
    min-width: 238px;
    box-shadow: 0px 1px 1px 0px rgba(0, 14, 51, 0.05);
    display: flex;
    grid-gap: 2px;
  }
  .estimate-select-wrapper.ant-select.ant-select-enabled {
    .ant-select-selection__rendered {
      margin: 0 16px;
    }
    .ant-select-selection.ant-select-selection--single {
      border: none;
      background: transparent;
      color: var(--light-mode-typography-header, #141414);
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -0.18px;
    }
  }
  .custom-date-picker .ant-calendar-picker-input {
  color: #000; 
  font-weight: bold; 
  font-size: 18px;
  word-spacing: 18px;
  min-height: 35px;
}

  .delivery-row-wrapper {
    display: flex;
    align-items: end;
    grid-gap: 15px;
    button.add-button{
      padding: 9px 10px;
      display: flex;
      align-items: center;
      height: 35px;
      svg{
        color: #1565d8 !important;
      }
      .add-button-text{
        color: #1565D8;
        font-size: 14px;
        font-weight: 700;
        margin-left: 10px;
      }
    }
    .estimate-ui {
      display: flex;
      flex-direction: column;
      grid-row-gap: 6px;
      display: flex;

      p {
        margin: 0px;
        color: #646464;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0.06px;
      }
    }
    .track-info-wrapper {
      display: flex;
      flex-direction: column;
      grid-row-gap: 6px;
      position: relative;
      min-width: 165px;
      input {
        padding-right: 13px;
      }
      button {
        position: absolute;
        right: 0;
        top: 21px;
        color: var(--Information, #00a3ff);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        text-decoration-line: underline;
        border: none;
        text-decoration: underline;
        background: transparent;
      }
      p {
        margin: 0px;
        color: #646464;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0.06px;
      }
    }
  }
  .tracking-info-inner-wrapper{
      display: flex;
      gap: 24px;
      .tracking-info-line{
       min-width: 170px;
       margin-bottom: 0;
       padding: 10px;
      }
      .tracking-info-date{
        width: 100%;
        margin-bottom: 0;
        padding: 10px;
      }
      .delete-button{
        border: none;
        background-color: transparent;
      }
    }
  .down-arrow-wrapper {
    position: relative;
    svg {
      position: absolute;
      top: 13px;
      right: 0px;
      transform: rotate(333deg);
      color: rgba(0, 71, 255, 1);
    }
  }
  .track-info-wrapper {
    display: flex;
    flex-direction: column;
    grid-row-gap: 6px;
    position: relative;
    min-width: 197px;
    input {
      padding-right: 45px;
    }
    button {
      position: absolute;
      right: 0;
      top: 21px;
      color: var(--Information, #00a3ff);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      text-decoration-line: underline;
      border: none;
      text-decoration: underline;
      background: transparent;
    }
    p {
      margin: 0px;
      color: #646464;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px;
      letter-spacing: 0.06px;
    }
    textarea {
      color: var(--Light-Grey, #c4c4c4);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      padding: 8px;
    }
  }
  .custom-input{
    padding: 6px;
    border-radius: 5px;
    border-color: #d9d9d9 !important;
    height: 35px;
  }
`;
const ShipThirdPartyLogisticsWrapper = styled.div `
  .card-data-overlay {
    grid-row-gap: 10px;
    display: flex;
    flex-direction: column;
    svg {
      margin: auto;
    }
    h5 {
      color: var(--Heading, #000);
      text-align: center;
      padding: 0 45px;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      margin: 0px;
      &.shipment-heading {
        padding: 0px;
      }
    }
    h6 {
      color: var(--Heading, #000);
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 111.111% */
    }
    p {
      color: var(--Primary-Text, #4e5969);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 128.571% */
    }
  }
  .grid {
    gap: 20px;
  }
  .disable {
    opacity: 0.5;
  }
`;

const ChooseShippingWarehouseWrapper = styled.div `
.leaflet-container {
  height: 100vh;
  width: 100%;
}
/* required styles */

.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
	position: absolute;
	left: 0;
	top: 0;
	}
.leaflet-container {
	overflow: hidden;
	}
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
	-webkit-user-select: none;
	   -moz-user-select: none;
	        user-select: none;
	  -webkit-user-drag: none;
	}
/* Prevents IE11 from highlighting tiles in blue */
.leaflet-tile::selection {
	background: transparent;
}
/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
	image-rendering: -webkit-optimize-contrast;
	}
/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
	width: 1600px;
	height: 1600px;
	-webkit-transform-origin: 0 0;
	}
.leaflet-marker-icon,
.leaflet-marker-shadow {
	display: block;
	}
/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg {
	max-width: none !important;
	max-height: none !important;
	}
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
	max-width: none !important;
	max-height: none !important;
	width: auto;
	padding: 0;
	}

.leaflet-container img.leaflet-tile {
	/* See: https://bugs.chromium.org/p/chromium/issues/detail?id=600120 */
	mix-blend-mode: plus-lighter;
}

.leaflet-container.leaflet-touch-zoom {
	-ms-touch-action: pan-x pan-y;
	touch-action: pan-x pan-y;
	}
.leaflet-container.leaflet-touch-drag {
	-ms-touch-action: pinch-zoom;
	/* Fallback for FF which doesn't support pinch-zoom */
	touch-action: none;
	touch-action: pinch-zoom;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
	-ms-touch-action: none;
	touch-action: none;
}
.leaflet-container {
	-webkit-tap-highlight-color: transparent;
}
.leaflet-container a {
	-webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}
.leaflet-tile {
	filter: inherit;
	visibility: hidden;
	}
.leaflet-tile-loaded {
	visibility: inherit;
	}
.leaflet-zoom-box {
	width: 0;
	height: 0;
	-moz-box-sizing: border-box;
	     box-sizing: border-box;
	z-index: 800;
	}
/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
	-moz-user-select: none;
	}

.leaflet-pane         { z-index: 400; }

.leaflet-tile-pane    { z-index: 200; }
.leaflet-overlay-pane { z-index: 400; }
.leaflet-shadow-pane  { z-index: 500; }
.leaflet-marker-pane  { z-index: 600; }
.leaflet-tooltip-pane   { z-index: 650; }
.leaflet-popup-pane   { z-index: 700; }

.leaflet-map-pane canvas { z-index: 100; }
.leaflet-map-pane svg    { z-index: 200; }

.leaflet-vml-shape {
	width: 1px;
	height: 1px;
	}
.lvml {
	behavior: url(#default#VML);
	display: inline-block;
	position: absolute;
	}


/* control positioning */

.leaflet-control {
	position: relative;
	z-index: 800;
	pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
	pointer-events: auto;
	}
.leaflet-top,
.leaflet-bottom {
	position: absolute;
	z-index: 1000;
	pointer-events: none;
	}
.leaflet-top {
	top: 0;
	}
.leaflet-right {
	right: 0;
	}
.leaflet-bottom {
	bottom: 0;
	}
.leaflet-left {
	left: 0;
	}
.leaflet-control {
	float: left;
	clear: both;
	}
.leaflet-right .leaflet-control {
	float: right;
	}
.leaflet-top .leaflet-control {
	margin-top: 10px;
	}
.leaflet-bottom .leaflet-control {
	margin-bottom: 10px;
	}
.leaflet-left .leaflet-control {
	margin-left: 10px;
	}
.leaflet-right .leaflet-control {
	margin-right: 10px;
	}


/* zoom and fade animations */

.leaflet-fade-anim .leaflet-popup {
	opacity: 0;
	-webkit-transition: opacity 0.2s linear;
	   -moz-transition: opacity 0.2s linear;
	        transition: opacity 0.2s linear;
	}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
	opacity: 1;
	}
.leaflet-zoom-animated {
	-webkit-transform-origin: 0 0;
	    -ms-transform-origin: 0 0;
	        transform-origin: 0 0;
	}
svg.leaflet-zoom-animated {
	will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
	-webkit-transition: -webkit-transform 0.25s cubic-bezier(0,0,0.25,1);
	   -moz-transition:    -moz-transform 0.25s cubic-bezier(0,0,0.25,1);
	        transition:         transform 0.25s cubic-bezier(0,0,0.25,1);
	}
.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
	-webkit-transition: none;
	   -moz-transition: none;
	        transition: none;
	}

.leaflet-zoom-anim .leaflet-zoom-hide {
	visibility: hidden;
	}


/* cursors */

.leaflet-interactive {
	cursor: pointer;
	}
.leaflet-grab {
	cursor: -webkit-grab;
	cursor:    -moz-grab;
	cursor:         grab;
	}
.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
	cursor: crosshair;
	}
.leaflet-popup-pane,
.leaflet-control {
	cursor: auto;
	}
.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
	cursor: move;
	cursor: -webkit-grabbing;
	cursor:    -moz-grabbing;
	cursor:         grabbing;
	}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
	pointer-events: none;
	}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
	pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
	pointer-events: auto;
	}

/* visual tweaks */

.leaflet-container {
	background: #ddd;
	outline-offset: 1px;
	}
.leaflet-container a {
	color: #0078A8;
	}
.leaflet-zoom-box {
	border: 2px dotted #38f;
	background: rgba(255,255,255,0.5);
	}


/* general typography */
.leaflet-container {
	font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
	font-size: 12px;
	font-size: 0.75rem;
	line-height: 1.5;
	}


/* general toolbar styles */

.leaflet-bar {
	box-shadow: 0 1px 5px rgba(0,0,0,0.65);
	border-radius: 4px;
	}
.leaflet-bar a {
	background-color: #fff;
	border-bottom: 1px solid #ccc;
	width: 26px;
	height: 26px;
	line-height: 26px;
	display: block;
	text-align: center;
	text-decoration: none;
	color: black;
	}
.leaflet-bar a,
.leaflet-control-layers-toggle {
	background-position: 50% 50%;
	background-repeat: no-repeat;
	display: block;
	}
.leaflet-bar a:hover,
.leaflet-bar a:focus {
	background-color: #f4f4f4;
	}
.leaflet-bar a:first-child {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	}
.leaflet-bar a:last-child {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	border-bottom: none;
	}
.leaflet-bar a.leaflet-disabled {
	cursor: default;
	background-color: #f4f4f4;
	color: #bbb;
	}

.leaflet-touch .leaflet-bar a {
	width: 30px;
	height: 30px;
	line-height: 30px;
	}
.leaflet-touch .leaflet-bar a:first-child {
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	}
.leaflet-touch .leaflet-bar a:last-child {
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
	}

/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
	font: bold 18px 'Lucida Console', Monaco, monospace;
	text-indent: 1px;
	}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out  {
	font-size: 22px;
	}


/* layers control */

.leaflet-control-layers {
	box-shadow: 0 1px 5px rgba(0,0,0,0.4);
	background: #fff;
	border-radius: 5px;
	}
.leaflet-control-layers-toggle {
	background-image: url(images/layers.png);
	width: 36px;
	height: 36px;
	}
.leaflet-retina .leaflet-control-layers-toggle {
	background-image: url(images/layers-2x.png);
	background-size: 26px 26px;
	}
.leaflet-touch .leaflet-control-layers-toggle {
	width: 44px;
	height: 44px;
	}
.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
	display: none;
	}
.leaflet-control-layers-expanded .leaflet-control-layers-list {
	display: block;
	position: relative;
	}
.leaflet-control-layers-expanded {
	padding: 6px 10px 6px 6px;
	color: #333;
	background: #fff;
	}
.leaflet-control-layers-scrollbar {
	overflow-y: scroll;
	overflow-x: hidden;
	padding-right: 5px;
	}
.leaflet-control-layers-selector {
	margin-top: 2px;
	position: relative;
	top: 1px;
	}
.leaflet-control-layers label {
	display: block;
	font-size: 13px;
	font-size: 1.08333em;
	}
.leaflet-control-layers-separator {
	height: 0;
	border-top: 1px solid #ddd;
	margin: 5px -10px 5px -6px;
	}

/* Default icon URLs */
.leaflet-default-icon-path { /* used only in path-guessing heuristic, see L.Icon.Default */
	background-image: url(images/marker-icon.png);
	}


/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
	background: #fff;
	background: rgba(255, 255, 255, 0.8);
	margin: 0;
	}
.leaflet-control-attribution,
.leaflet-control-scale-line {
	padding: 0 5px;
	color: #333;
	line-height: 1.4;
	}
.leaflet-control-attribution a {
	text-decoration: none;
	}
.leaflet-control-attribution a:hover,
.leaflet-control-attribution a:focus {
	text-decoration: underline;
	}
.leaflet-attribution-flag {
	display: inline !important;
	vertical-align: baseline !important;
	width: 1em;
	height: 0.6669em;
	}
.leaflet-left .leaflet-control-scale {
	margin-left: 5px;
	}
.leaflet-bottom .leaflet-control-scale {
	margin-bottom: 5px;
	}
.leaflet-control-scale-line {
	border: 2px solid #777;
	border-top: none;
	line-height: 1.1;
	padding: 2px 5px 1px;
	white-space: nowrap;
	-moz-box-sizing: border-box;
	     box-sizing: border-box;
	background: rgba(255, 255, 255, 0.8);
	text-shadow: 1px 1px #fff;
	}
.leaflet-control-scale-line:not(:first-child) {
	border-top: 2px solid #777;
	border-bottom: none;
	margin-top: -2px;
	}
.leaflet-control-scale-line:not(:first-child):not(:last-child) {
	border-bottom: 2px solid #777;
	}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	box-shadow: none;
	}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	border: 2px solid rgba(0,0,0,0.2);
	background-clip: padding-box;
	}


/* popup */

.leaflet-popup {
	position: absolute;
	text-align: center;
	margin-bottom: 20px;
	}
.leaflet-popup-content-wrapper {
	padding: 1px;
	text-align: left;
	border-radius: 12px;
	}
.leaflet-popup-content {
	margin: 13px 24px 13px 20px;
	line-height: 1.3;
	font-size: 13px;
	font-size: 1.08333em;
	min-height: 1px;
	}
.leaflet-popup-content p {
	margin: 17px 0;
	margin: 1.3em 0;
	}
.leaflet-popup-tip-container {
	width: 40px;
	height: 20px;
	position: absolute;
	left: 50%;
	margin-top: -1px;
	margin-left: -20px;
	overflow: hidden;
	pointer-events: none;
	}
.leaflet-popup-tip {
	width: 17px;
	height: 17px;
	padding: 1px;

	margin: -10px auto 0;
	pointer-events: auto;

	-webkit-transform: rotate(45deg);
	   -moz-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	        transform: rotate(45deg);
	}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
	background: white;
	color: #333;
	box-shadow: 0 3px 14px rgba(0,0,0,0.4);
	}
.leaflet-container a.leaflet-popup-close-button {
	position: absolute;
	top: 0;
	right: 0;
	border: none;
	text-align: center;
	width: 24px;
	height: 24px;
	font: 16px/24px Tahoma, Verdana, sans-serif;
	color: #757575;
	text-decoration: none;
	background: transparent;
	}
.leaflet-container a.leaflet-popup-close-button:hover,
.leaflet-container a.leaflet-popup-close-button:focus {
	color: #585858;
	}
.leaflet-popup-scrolled {
	overflow: auto;
	}

.leaflet-oldie .leaflet-popup-content-wrapper {
	-ms-zoom: 1;
	}
.leaflet-oldie .leaflet-popup-tip {
	width: 24px;
	margin: 0 auto;

	-ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
	filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
	}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
	border: 1px solid #999;
	}


/* div icon */

.leaflet-div-icon {
	background: #fff;
	border: 1px solid #666;
	}


/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
	position: absolute;
	padding: 6px;
	background-color: #fff;
	border: 1px solid #fff;
	border-radius: 3px;
	color: #222;
	white-space: nowrap;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
	box-shadow: 0 1px 3px rgba(0,0,0,0.4);
	}
.leaflet-tooltip.leaflet-interactive {
	cursor: pointer;
	pointer-events: auto;
	}
.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
	position: absolute;
	pointer-events: none;
	border: 6px solid transparent;
	background: transparent;
	content: "";
	}

/* Directions */

.leaflet-tooltip-bottom {
	margin-top: 6px;
}
.leaflet-tooltip-top {
	margin-top: -6px;
}
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
	left: 50%;
	margin-left: -6px;
	}
.leaflet-tooltip-top:before {
	bottom: 0;
	margin-bottom: -12px;
	border-top-color: #fff;
	}
.leaflet-tooltip-bottom:before {
	top: 0;
	margin-top: -12px;
	margin-left: -6px;
	border-bottom-color: #fff;
	}
.leaflet-tooltip-left {
	margin-left: -6px;
}
.leaflet-tooltip-right {
	margin-left: 6px;
}
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
	top: 50%;
	margin-top: -6px;
	}
.leaflet-tooltip-left:before {
	right: 0;
	margin-right: -12px;
	border-left-color: #fff;
	}
.leaflet-tooltip-right:before {
	left: 0;
	margin-left: -12px;
	border-right-color: #fff;
	}

/* Printing */

@media print {
	/* Prevent printers from removing background-images of controls. */
	.leaflet-control {
		-webkit-print-color-adjust: exact;
		print-color-adjust: exact;
		}
	}

  .warehouse-wrapper {
    display: flex;
    grid-gap: 20px;
    align-items: center;
    justify-content: center;
  }
  .parent-wrapper {
    display: flex;
    align-items: center;
    grid-gap:10px ;
  }
  .rating-ui-bag {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 10px;
    background: var(--Background-blue, #f1fbfe);
    display: flex;
    width: 64px;
    padding: 6px 8px;
    gap: 4px;
    justify-content: space-around;
    align-items: center;
    span {
      color: var(--Primary-Text, #4e5969);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 19.6px */
    }
  }
  .card-inner-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 16.5px;
    img {
      width: 32px;
    }
    h4 {
      color: var(--Heading, #000);
      text-align: center;
      margin: 0px;
      padding-top: 10px;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 111.111% */
      padding-bottom: 16px;
    }
    .services-tags {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 20px;
      span {
        color: var(--Primary-Text, #4e5969);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 128.571% */
      }
    }
    button {
      border-radius: 4px;
      border: 1px solid var(--Primary, #1565d8);
      display: flex;
      width: 165px;
      height: 44px;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: var(--Primary, #1565d8);
      text-align: right;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 0.3px;
    }
  }
`;

const AddProductToPurchaseOrderWrapper = styled.div `
  .add-product-wrapper {
    display: flex;
    grid-gap: 24px;
    .add-product-item {
      width: 100%;
      border-radius: 8px;
      border: 1px solid var(--Light-blue, #6fd5f6);
      padding: 20px 16px;
      h5 {
        color: var(--Heading, #000);
        font-size: 16px;
        font-style: normal;
        text-align: center;
        font-weight: 600;
        line-height: 20px; /* 125% */
      }
      .selected-sku-text{
        font-size: 12px;
      }
      .search-bar-wrapper {
        position: relative;
        input {
          padding: 8px 32px 8px 12px;
          border-radius: 4px;
          border-bottom-right-radius: 0px;
          border-bottom-left-radius: 0px;
          border: 1px solid var(--Light-Mid-Grey, #e6e6e6);
          background: var(--White, #fff);
          color: #78909c;
          width: 100%auto;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          height: 40px;
          line-height: 16px; /* 133.333% */
        }
        svg {
          position: absolute;
          right: 10px;
          top: 14px;
          color: #1565d8;
          cursor: pointer;
        }
      }
      .product-data-box-wrapper {
        border-radius: 4px 4px 0px 0px;
        background: #fff;
        padding: 7px;
        border: 1px solid var(--Light-Mid-Grey, #e6e6e6);
        overflow: auto;
        height: 342px;
        margin-top: 16px;
        display: flex;
        flex-direction: column;
        grid-row-gap: 10px;

        .product-data-item {
          display: flex;
          grid-gap: 10px;
          align-items: center;
          .product {
            display: flex;
            align-items: center;
            grid-gap: 8px;
            .product-details {
              display: flex;
              flex-direction: column;
              grid-row-gap: 8px;
              h6 {
                margin: 0px;
                color: var(--Primary, #1565d8);
                font-feature-settings: "clig" off, "liga" off;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                width: 200px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .product-text {
                display: flex;
                grid-gap: 8px;
                p {
                  margin: 0px;
                  color: #78909c;
                  font-feature-settings: "clig" off, "liga" off;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 12px; /* 120% */
                  display: flex;
                  grid-gap: 2px;
                  span {
                    color: #4e5969;
                    font-feature-settings: "clig" off, "liga" off;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400 !important;
                    line-height: 12px; /* 120% */
                  }
                }
              }
            }
          }
        }
        .upload-product-item {
        }
      }
    }
    .upload-product-item {
      width: 100%;
      height: 216px;
      .ant-upload-drag {
        padding: 24px;
      }
      span.ant-upload.ant-upload-btn {
        padding: 0px;
      }
      .ant-upload-drag-container {
        h6 {
          color: var(--Heading, #000);
          font-family: Titillium Web;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          margin: 0px;
        }
        p.ant-upload-drag-icon {
          margin-bottom: 12px;
          svg {
            font-size: 30px;
            color: rgba(0, 163, 255, 1);
            margin-top: 15.5px;
          }
        }
        .ant-upload-text {
          color: var(--Heading, #000);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          margin-bottom: 6px;
        }
        .ant-upload-hint {
          color: var(--Light-Grey, #c4c4c4);
          text-align: center;
          font-family: Titillium Web;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px;
          margin-bottom: 26.5px;
        }
      }
      button {
        display: flex;
        width: 217.991px;
        padding: 8px 119px;
        justify-content: center;
        align-items: center;
        margin: auto;
        border-radius: 4px;
        border: 1px solid var(--Primary, #1565d8);
        color: var(--Primary, #1565d8);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
      }
    }
  }
  .next-button-wrapper {
    display: flex;
    justify-content: end;
    button {
      display: flex;
      height: 44px;
      padding: 8px 24px;
      align-items: center;
      justify-content: center;
      gap: 8px;
      border-radius: 4px;
      background: var(--Link, #0057d3);
      color: #fff;
      text-align: right;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 106.667% */
      letter-spacing: 0.3px;
      &.disabled{
        background: #d9d9d9;
      }
    }
  }
  .drawer-add-product {
    display: flex;
    flex-direction: column;
    width: 100%;
    button {
      border-radius: 4px;
      border: 1px solid var(--Primary, #1565d8);
      padding: 13px 33px;
      height: 40px;
      color: var(--Primary, #1565d8);
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; /* 116.667% */
      letter-spacing: 0.06px;
    }
  }
  .create-listing-ui {
    display: flex;
    flex-direction: column;
    grid-gap: 16px;
    padding: 12px 0px;
    border: 1px solid #e6e6e6;
    border-top: none;
    border-radius: 4px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;

    button {
      color: var(--Primary, #1565d8);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 114.286% */
      display: flex;
      width: 217.991px;
      padding: 8px 119px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      border: 1px solid var(--Primary, #1565d8);
      margin: auto;
      &.disable-btn-wrapper{
        border:1px solid #d9d9d9;
        background-color: #f5f5f5;
        color:#b7b7b7;
        &:hover{
        border:1px solid #d9d9d9;
        background-color: #f5f5f5;
        color:#b7b7b7;
        }
      }
    }
  }
`;
const FileUploadWrapper = styled.div `
 .file-upload-header{
  text-align: center;
  .file-upload-heading{
    font-size: 20px;
    color: var(--Primary, #1565d8);
    font-weight: 600;
  }
  .file-upload-paragraph{
    font-size: 12px;
    font-weight: 400;
    color: #4E5969;
  }
 }
 .upload-title-wrapper{
  display: flex;
  align-items: center;
  margin-bottom: 0;
  gap: 16px;
  .header-text-inner-wrapper{
    width: 18px;
    height: 18px;
    text-align: center;
  }
 }
 .file-upload-main{
  max-height: calc(100vh - 190px);
    overflow: auto;
    overflow-x: hidden;
    position: relative;
  .ant-collapse{
    border: none;
    background-color: transparent;
    grid-row-gap: 0;
    margin-top: 12px;
    .ant-collapse-item {
      border-bottom: none;
      border-radius: 8px;
      margin-bottom: 12px;
      .ant-collapse-header{
        padding: 8px;
        background-color: #F1FBFE;
        border-radius: 8px;
        i.anticon.ant-collapse-arrow{
          display: none !important;
        }
      }
    }
    .ant-collapse-content{
      border-top: 0;
      .ant-collapse-content-box{
        padding: 8px 0 0px !important;
      }
    }
  }
  .row{
    --bs-gutter-y: 8px;
    --bs-gutter-x: 8px;
  }
  .outlined-btn{
    border: 1px solid #1565D8;
    color: #1565D8;
    padding: 8px 24px;
    border-radius: 4px;
    background-color: #fff;
    font-weight: 700;
    margin-top: 12px;
    &:hover{
      background-color: #1565D8;
      color: #fff;
    }
  }
  .checklist-item{
    padding: 12px 8px;
    display: flex;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
    span.item-text{
      font-size: 12px;
      font-weight: 400;
      color: #000;
      padding-left: 6px;
      max-width: 120px;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .step-icon{
      height: 18px;
      width: 18px;
      object-fit: contain;
    }
    .reset-button{
      font-size: 12px;
      font-weight: 600;
      color: var(--Primary, #1565d8);
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: end;
      gap: 6px;
      .reset-icon{
        height: 12px;
        width: 12px;
        object-fit: contain;
      }
    }
  }
  .item-1{
    background-color: #D9F1FF;
  }
  .item-2{
    background-color: #BFE7FE;
  }
  .item-3{
    background-color: #ACE1FE;
  }
  .item-4{
    background-color: #A2DCFE;
  }
  .item-5{
    background-color: #FFF5E6;
  }
  .item-6{
    background-color: #FFF2D8;
  }
  .item-7{
    background-color: #FFEFCA;
  }
  .item-8{
    background-color: #FFECBD;
  }
  .item-9{
    background-color: #FFEAB0;
  }
  .item-10{
    background-color: #FFE7A2;
  }
  .item-11{
    background-color: #FFE494;
  }
  .item-12{
    background-color: #FFE187;
  }
  .ag-root-wrapper{
    border-radius: 8px;
    border: 1px solid #00A3FF;
    font-family:  "Titillium Web", sans-serif;
  }
  .ag-header-row{
    border-bottom: 1px solid #4E5969;
    background-color: #F8FAFB;
    color: #4E5969;
  }
  .ag-header-row-column-group{
    background-color: #D9F1FF;
    border-bottom: 1px solid #4E5969;
    .ag-header-group-text{
      font-size: 16px;
      color: #4E5969;
      font-weight: 600;
    }
  }
  .product-description{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .ag-header-group-cell,
  .ag-header-cell,
  .ag-cell {
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    justify-content: center;
    span{
      font-size: 12px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .ag-header-group-cell{
    border-right: 1px solid #E6E6E6;
    &:last-child{
      border-right: none;
    }
  }
  .ag-header-cell{
    border-right: 1px solid #E6E6E6;
    &:last-child{
      border-right: none;
    }
  }
  .ag-cell{
    color: #4E5969;
    border-right: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;
    &:last-child{
      border-right: none;
    }
    .blue-text{
      color: #1565D8;
      font-weight: 600;
    }
  }
  .main-header-label{
    display: flex;
    align-items: center;
    gap: 4px;
    .main-header-label-img{
      height: 12px;
      width: 12px;
      object-fit: contain;
    }
    span{
      font-size: 14px;
      font-weight: 600;
    }
  }
  .ag-header-cell-label{
    display: block;
    text-align: center;
  }
  .ag-header-group-cell-label,
  .ag-cell {
    justify-content: center;
    text-align: center;
    display: flex;
    align-items: center;
  }
 }
`;
const TableWrapper = styled.div `
  border: 1px solid #cfd8dc;
  border-radius: 8px;
  overflow: hidden;
  .gated-asin-table{
    .ag-header{
      min-height: 32px !important;
      height: 32px !important;
      background-color: #F8FAFB !important;
      border-bottom: 0;
      font-family: 'Titillium Web';
      .ag-header-row{
        min-height: 32px;
        height: 32px !important;
        span{
          color: inherit;
        }
      }
    }
    .ag-row{
        border-bottom: 0 !important;
        &.ag-row-odd{
          background-color: #F8FAFB;
          &.ag-row-hover:not(.ag-full-width-row)::before{
            background-color: #F8FAFB !important
          }
        }
        &.ag-row-even{
          background-color: #fff;
          &.ag-row-hover:not(.ag-full-width-row)::before{
            background-color: #fff !important;
          }
        }
        .ag-cell{
          border: none !important;
          p{
            font-size: 14px;
            &.sku-text{
              max-width: 38px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            &.bages-wrapper{
              font-size: 12px;
              font-family: 'Titillium Web';
            }
          }
          .product{
            img{
              height: 36px !important;
              width: 36px !important;
            }
          }
          .product-details{
            grid-row-gap: 4px;
            h6{
              font-family: 'Titillium Web';
            }
          }
        }
    }
  }
  .ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-value {
    display: flex;
    align-items: center;
  }
  .ag-inner-tooltip-text{
    margin-bottom: 0;
    width: 71px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--Primary-Text,#4e5969);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding-right: 10px;
  }
  .action-column-wrapper {
    display: flex;
    grid-gap: 8px;
  }
  .ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-value {
    padding: 4px;
    padding-right: 0px;
    padding-left:0px;
    &:nth-child(1){
      padding-left:16px;
      padding-right:16px ;
    }
    &:nth-last-child(){
      padding-right:16px;
    }
}
  .ag-header-cell.ag-focus-managed {
    padding: 4px;
    padding-right: 0px;
    padding-left:0px;
    &:nth-child(1){
      padding-left:16px;
    }
    &:nth-last-child(){
      padding-right:16px;
    }
}
  .ag-header-viewport {
    border-radius: 8px 8px 0px 0px;
    background: #f8fafb;
  }
  .ag-header-cell-label {
    color: var(--label-placeholder-text, #979797);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.06px;
  }
  span.ant-checkbox {
    border-radius: 2px;
    border: none;
    &:hover {
      border: none;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1565d8 !important;
    border: none;
    border-color: #1565d8 !important;
  }
  /* .ant-checkbox-wrapper-checked .ant-checkbox-inner {
    border-radius: 2px;
    background: var(--Primary, #1565d8) !important;
    border-color: var(--Primary, #1565d8) !important;
  } */
  button.ant-btn.checked-button {
    display: flex;
    align-items: center;
    padding: 19px 8px;
    grid-gap: 4px;
    color: var(--Primary, #1565d8);
    font-family: Titillium Web;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.06px;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 1px;
      right: -12px;
      border-radius: 2px;
      background: #cfd8dc;
      top: -11px;
      height: 60px;
    }
    &.active {
      border: 1px solid var(--Primary, #1565d8);
      background: #fff;
    }
    &:hover {
      border: 1px solid var(--Primary, #1565d8);
      background: #fff;
    }
  }
  button.ant-btn.checked-button-next {
    display: flex;
    align-items: center;
    padding: 19px 8px;
    grid-gap: 4px;
    color: var(--Primary, #1565d8);
    font-family: Titillium Web;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0.06px;
    position: relative;
    &.active {
      border: 1px solid var(--Primary, #1565d8);
      background: #fff;
    }
    &:hover {
      border: 1px solid var(--Primary, #1565d8);
      background: #fff;
    }
  }
  .bages-wrapper {
    border-radius: 16px;
    border: 1px solid var(--Error, #cf0909);
    background: rgba(207, 9, 9, 0.1);
    display: flex;
    padding: 6px 18px;
    align-items: center;
    color: var(--Error, #cf0909);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    margin: 0px;
    line-height: 14px; /* 116.667% */
  }
  p.sku-text {
    color: var(--Primary-Text, #4e5969);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0px;
  }
  .product-data-item {
    display: flex;
    grid-gap: 10px;
    align-items: center;
    .product {
      display: flex;
      align-items: center;
      grid-gap: 8px;
      .product-details {
        display: flex;
        flex-direction: column;
        grid-row-gap: 8px;
        h6 {
          margin: 0px;
          color: var(--Primary, #1565d8);
          font-feature-settings: "clig" off, "liga" off;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          width: 100px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .product-text {
          display: flex;
          grid-gap: 8px;
          p {
            margin: 0px;
            color: #78909c;
            font-feature-settings: "clig" off, "liga" off;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 12px; /* 120% */
            display: flex;
            grid-gap: 2px;
            span {
              color: #4e5969;
              font-feature-settings: "clig" off, "liga" off;
              font-size: 10px;
              font-style: normal;
              font-weight: 400 !important;
              line-height: 12px; /* 120% */
            }
          }
        }
      }
    }
  }
`;
const VenderShipmentAddressWrapper = styled.div `
  .input-form-wrapper {
    background: #ffff;
    padding: 24px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 24px;
    margin-top: 30px;
    .ant-col.ant-form-item-label {
      color: var(--Primary-Text, #4e5969);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px !important;
      letter-spacing: 0.06px;
    }
    .ant-row.ant-form-item {
      margin: 0px;
      padding: 0px;
    }
    .input-overlay-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: end;
      grid-gap: 24px;
      .ant-row.ant-form-item {
        width: 100%;
        margin-bottom: 0px;
        padding-bottom: 0px;
      }
      .ant-btn.button{
        margin-bottom: 5px;
      }
    }
    .inner-buttons-wrapper{
      display: flex;
      gap: 4px;
      justify-content: end;
    }
  }
  .fix-height-warehouse-address {
    max-height: 300px;
    overflow-y: auto;
  }
  .card-overlay-wrapper {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 20px;
    .card-item {
      display: flex;
      width: 48.5%;
      padding: 10px;
      justify-content: space-between;
      align-items: flex-start;
      border-radius: 10px;
      border: 1px solid var(--Light-Grey, #c4c4c4);
      background: #fff;
      &:hover {
        border: 1px solid var(--Primary, #1565d8);
      }
      &.active {
        border: 2px solid var(--Primary, #1565d8);
      }
      .text-wrapper {
        grid-row-gap: 4px;
        p {
          margin: 0px;
          color: var(--Primary-Text, #4e5969);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
        }
      }
      button {
        color: var(--Primary, #1565d8);
        border: none;
        padding: 0px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px; /* 128.571% */
        text-decoration-line: underline;
      }
    }
  }
  .add-new-address-button {
    color: var(--Primary, #1565d8);
    border: none;
    font-size: 10px;
    margin: 0px;
    background-color: transparent;
    display: flex;
    justify-content: end;
    font-style: normal;
    height: auto;
    font-weight: 600;
    line-height: 18px; /* 180% */
    text-decoration-line: underline;
  }
`;

const InboundShipmentwrapper = styled.div `
  label.card {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .card-data-overlay {
    display: flex;
    flex-direction: column;
    grid-row-gap: 16px;
    h5 {
      color: var(--Heading, #000);
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 111.111% */
    }
  }
`;
const PricingAndFeesWrapper = styled.div `
  display: flex;
  flex-direction: column;
  grid-row-gap: 24px;

  .price-accordion-table {
    border: 1px solid #cfd8dc;
    border-radius: 8px;
    overflow: hidden;
    font-family: inherit;
    .ag-header{
      min-height: 32px !important;
      height: 32px !important;
      background-color: #F8FAFB !important;
      border-bottom: 0;
      font-family: 'Titillium Web';
      .ag-header-row{
        min-height: 32px;
        height: 32px !important;
        span{
          color: inherit;
        }
      }
    }
    .ag-row{
        border-bottom: 0 !important;
        &.ag-row-odd{
          background-color: #F8FAFB;
          &.ag-row-hover:not(.ag-full-width-row)::before{
            background-color: #F8FAFB !important
          }
        }
        &.ag-row-even{
          background-color: #fff;
          &.ag-row-hover:not(.ag-full-width-row)::before{
            background-color: #fff !important;
          }
        }
        .ag-cell{
          border: none !important;
          p{
            font-size: 14px;
            &.bages-wrapper{
              font-size: 12px;
            }
          }
        }
    }
    .total-fee-cost-wrapper{
      border-radius: 0;
    }
  }
  .header-overlay {
    background: var(--Background-blue, #f1fbfe);
    display: flex;
    height: 48px;
    padding: 8px 20px;
    align-items: center;
    gap: 10px;
    p {
      margin: 0px;
      color: var(--Heading, #000);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 111.111% */
    }
  }
  p.sku-text {
    color: var(--Primary-Text, #4e5969);
    font-family: Titillium Web;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin: 0px;
  }
  .switch-overlay {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0px;
      color: var(--Primary-Text, #4e5969);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 133.333% */
      letter-spacing: 0.06px;
    }
    button.ant-switch {
      border: 1px solid #1565d8;
      display: flex;
      align-items: center;
      padding: 15px;
      background: transparent;
      span {
        color: var(--Mid-Grey, #979797);
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
      }
      &::after {
        top: 5px;
        left: 6px;
        background-color: #c4c4c4;
      }
      &.ant-switch-checked {
        background-color: #1565d8;
        span {
          color: #fff;
          text-align: right;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 114.286% */
        }
        &::after {
          background-color: #fff;
          left: 100% !important;
          margin-left: -8px !important ;
        }
      }
    }
  }
  .ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-value {
    display: flex;
    align-items: center;
  }
  .action-column-wrapper {
    display: flex;
    grid-gap: 8px;
  }
  .ag-header-viewport {
    border-radius: 8px 8px 0px 0px;
    background: #f8fafb;
  }
  .ag-header-cell-label {
    color: var(--label-placeholder-text, #979797);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0.06px;
  }
  .total-fee-cost-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    background: #f8fafb;
    border-radius: 8px;
    h6 {
      color: var(--Primary-Text, #4e5969);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px; /* 114.286% */
      margin: 0px;
    }

    .right-section {
      display: flex;
      flex-direction: column;
      grid-row-gap: 32px;
      p {
        color: var(--Primary-Text, #4e5969);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 114.286% */
        margin: 0px;
      }
      button {
        border-radius: 4px;
        border: 1px solid var(--Primary, #1565d8);
        padding: 8px 24px;
        color: var(--Primary, #1565d8);
        text-align: right;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 106.667% */
        letter-spacing: 0.3px;
        height: 44px;
      }
    }
  }
`;
const EstimateDeliveryInfo = styled.div `
  .select-ui-wrapper {
    border: 1px solid rgba(0, 71, 255, 1);
    border-radius: 6px;
    background: #fff;
    min-width: 238px;
    box-shadow: 0px 1px 1px 0px rgba(0, 14, 51, 0.05);
    display: flex;
    grid-gap: 2px;
  }

  .estimate-select-wrapper.ant-select.ant-select-enabled {
    .ant-select-selection__rendered {
      margin: 0 16px;
    }
    .ant-select-selection.ant-select-selection--single {
      border: none;
      background: transparent;
      color: var(--light-mode-typography-header, #141414);
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;
      letter-spacing: -0.18px;
    }
  }
`;
const DeliveryInformationWrapper = styled.div `
  .recieved-text {
    border-radius: 48px;
    border: 1px solid #009C34;
    background: #E5F5EB;
    line-height: 22px;
    width: 63px;
    color: #009C34;
    height: 24px;
    padding-left: 4px;
    size: 12px;
    margin-top: 10px;
    font-weight: 600;
  }
  .text-container-ss {
    border-radius: 4px;
    margin-top: 16px;
    grid-gap: 40px;
    border-radius: 2.519px;
    border: 0.84px solid var(--Light-Mid-Grey, #E6E6E6);
    background: var(--V-Light-Grey, #F8FAFB);
    display: flex;
    align-items: center;
    padding: 8px;
    .box-wrapper {
    display: flex;
    align-items: center;
    .delivery-modal-checkbox{
      padding-left: 0px;
    }
    p.outline-text{
      color: var(--Warning, #F90) !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; 
    }
}
    .text-item {
      display: flex;
      flex-direction: column;
      grid-row-gap: 10px;
      p {
        margin: 0px;
        color: var(--Primary-Text, #4e5969);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 114.286% */
      }
    }
  }
  .header {
    padding-top: 24px;
    padding-bottom: 40px;
    h1 {
      color: var(--Primary-Text, #4e5969);
      text-align: center;
      font-feature-settings: "clig" off, "liga" off;
      font-size: 24px;
      font-weight: 700;
      line-height: 24px;
    }
  }
  .fix-height-delivery-date {
    max-height: 180px;
    overflow-y: auto;
  }
  .text-container {
    border-radius: 4px;
    margin-top: 24px;
    grid-gap: 40px;
    border-radius: 2.519px;
    border: 0.84px solid var(--Light-Mid-Grey, #e6e6e6);
    background: var(--V-Light-Grey, #f8fafb);
    display: flex;
    align-items: center;
    padding: 8px;
    .text-item {
      display: flex;
      flex-direction: column;
      grid-row-gap: 16px;
      p {
        color: var(--Primary-Text, #4e5969);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 114.286% */
        margin: 0px;
      }
      .checkbox-wrapper {
        display: flex;
        align-items: center;
        grid-gap: 8px;
        p {
          color: var(--Warning, #f90) !important;
          font-size: 12px;
          font-style: normal;
          font-weight: 400 !important;
          line-height: 16px; /* 133.333% */
          margin: 0px;
        }
      }
    }
  }
  .sub-header {
    display: flex;
    flex-direction: column;
    grid-row-gap: 4px;
    h2 {
      color: var(--Heading, #000);
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px; /* 125% */
      margin: 0px;
    }
    p {
      color: #646464;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 14px; /* 116.667% */
      letter-spacing: 0.06px;
      margin: 0px;
    }
  }
  .delivery-row-wrapper {
    display: flex;
    align-items: end;
    grid-gap: 15px;
    button.add-button{
      padding: 9px 10px;
      display: flex;
      align-items: center;
      height: 35px;
      svg{
        color: #1565d8 !important;
      }
      .add-button-text{
        color: #1565D8;
        font-size: 14px;
        font-weight: 700;
        margin-left: 10px;
      }
    }
    .estimate-ui-skip-confirm {
      display: flex;
      flex-direction: column;
      grid-row-gap: 6px;
      display: flex;
      input {
        width: 250px !important;
      }

      p {
        margin: 0px;
        color: #646464;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0.06px;
      }
    }
    .estimate-ui {
      display: flex;
      flex-direction: column;
      grid-row-gap: 6px;
      display: flex;

      p {
        margin: 0px;
        color: #646464;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0.06px;
      }
    }
    .track-info-wrapper {
      display: flex;
      flex-direction: column;
      grid-row-gap: 6px;
      position: relative;
      min-width: 165px;
      input {
        padding-right: 13px;
      }
      button {
        position: absolute;
        right: 0;
        top: 21px;
        color: var(--Information, #00a3ff);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        text-decoration-line: underline;
        border: none;
        text-decoration: underline;
        background: transparent;
      }
      p {
        margin: 0px;
        color: #646464;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0.06px;
      }
    }
  }
  .custom-date-picker .ant-calendar-picker-input {
  color: #000; 
  font-weight: bold; 
  font-size: 18px;
  word-spacing: 18px;
  min-height: 35px;
  width: 200px;
}
.tracking-info-inner-wrapper{
      display: flex;
      gap: 24px;
      .tracking-info-line{
       min-width: 170px;
       margin-bottom: 0;
       padding: 10px;
      }
      .tracking-info-date{
        width: 100%;
        margin-bottom: 0;
        padding: 10px;
      }
      .delete-button{
        border: none;
        background-color: transparent;
      }
    }
  /* .tracking-info-overlay {
    display: flex;
    align-items: end;
    margin-top: 16px;
    .track-info-wrapper {
      display: flex;
      flex-direction: column;
      grid-row-gap: 6px;
      position: relative;
      min-width: 197px;
      input {
        padding-right: 45px;
      }
      button {
        position: absolute;
        right: 0;
        top: 21px;
        color: var(--Information, #00a3ff);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        text-decoration-line: underline;
        border: none;
        text-decoration: underline;
        background: transparent;
      }
      p {
        margin: 0px;
        color: #646464;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        letter-spacing: 0.06px;
      }
      textarea {
        color: var(--Light-Grey, #c4c4c4);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        padding: 8px;
      }
    }
  } */
  .button-wrapper {
    margin: 40px 0px 8px;
    display: flex;
    justify-content: end;
    grid-gap: 16px;
    .skip-button {
      display: flex;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      background: transparent;
      border: none;
      color: var(--Primary, #1565d8);
      text-align: center;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 106.667% */
      letter-spacing: 0.3px;
    }
    .confirm-button {
      display: flex;
      width: 180px;
      padding: 8px 89px;
      border-radius: 4px;
      background: var(--Primary, #1565d8);
      justify-content: center;
      align-items: center;
      color: var(--White, #fff);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px; /* 114.286% */
      &.disabled{
        background: #d9d9d9;
      }
    }
  }

  .custom-input{
    padding: 6px;
    border-radius: 5px;
    border-color: #d9d9d9 !important;
    height: 35px;
  }
`;
const CongratulationWrapper = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 32px;
  p {
    color: var(--Primary-Text, #4e5969);
    text-align: center;
    margin: 0px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
  }
`;
const PurchaseOrderWrapper = styled.div `
.card-overlay-wrapper {
  height: calc(100vh - 480px);
  overflow: scroll;
}
.show-received-button-ss{
  background-color:#ddd !important;
  border:none;
}
.select-antd-wrapper{ 
  .ant-select-selection.ant-select-selection--single {
    height: 48px;
    display: flex;
    align-items: center;
}
span.ant-select-selection__clear {
    top: 44%;
}
.ant-select-selection-selected-value {
    max-width: 118px !important;
}
}
.bags-grow-network-wrapper {
    border-radius: 50px;
    border: 1px solid var(--Primary, #1565D8);
    background: rgba(21, 101, 216, 0.05);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    text-align: center;
    padding: 4px 8px;
    grid-gap: 4px;
    height:24px;
    p{
      margin: 0px;
      color: var(--Primary, #1565D8);
      text-align: center;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px; 
      letter-spacing: 0.05px;
    }
}
.invoice-popup{
  .icon-arrow-left{
    font-size: 18px;
  }
}
  .purchase-heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 26px;
    .scanned-pkg {
      border: 1px solid #CF0909;
      background-color: #FEF8F8;
      border-radius: 8px;
      padding: 12px 16px;
      display: flex;
      align-items: center;
      gap: 16px;
      &.po-code {
        border: 1px solid ${({ theme }) => theme["outline-color"]};
        background-color: ${({ theme }) => theme["aqua_haze"]};
      }
      p {
        color: ${({ theme }) => theme["secondary-color"]};
        font-weight: 600;
        line-height: 16px;
        margin-bottom: 0;
      }
      button {
        font-weight: 600;
        margin-bottom: 0;
      }
      .scanned-content {
        padding-right: 8px;
        position: relative;
        line-height: 0;
        &::after {
          content: '';
          position: absolute;
          right: -3px;
          top: 6px;
          width: 1px;
          height: 24px;
          background-color: ${({ theme }) => theme["information-color"]};
        }
      div {
        line-height: 0;
        span {
          font-size: 10px;
          line-height: 18px;
          color: ${({ theme }) => theme["secondary-color"]};
          position: relative;
          &:first-child {
            padding-right: 4px;
            margin-right: 4px;
            &::after {
              content: '';
              position: absolute;
              right: 0;
              top: 2px;
              width: 1px;
              height: 12px;
              background-color: ${({ theme }) => theme["disabled-color"]};
            }
          }
        }
      }
      .scanned-number {
        font-size: 14px;
        font-weight: 600;
        line-height: 18px;
        color: ${({ theme }) => theme["primary-color"]};
      }
      }
    }
  }

  .ag-root{
    .ag-row-odd{
      background-color: #F8FAFB;
    }
    .ag-cell{
    .ag-cell-wrapper{
      height: 100%;
    }
  .sale-price-text{
    font-size: 10px;
    font-weight: 600;
  }
  }
  }
  .po-header-bar {
    border-radius: 8px;
    border: 1px solid #e6e6e6;
    background: #f8fafb;
    padding: 16px;
    display: flex;
    flex-wrap: wrap;
    margin: 24px 0;
    .btn {
        min-width: 120px;
        height: 48px;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
        line-height: 16px;
      }
    .po-header-left {
      display: flex;
      align-items: center;
      gap: 12px;
      width: 100%;
      padding-right: 20px;
      
      .input-search {
        width: 100%;
        input{
          min-width: auto;
          width: 100%;
          height: 48px;
          font-size: 14px;
        }
        .header-input-field{
            input{
              padding-right: 26px;
                &::placeholder{
                font-size: 14px;
                color: #78909C;
                font-weight: 400;
              }
            }
          }
        svg {
          top: 18px;
        }
      }
      .po-select {
        margin-bottom: 0;
        width: 100%;
        .select2-container {
          margin-bottom: 0;
          .select2-selection__control {
            min-height: 48px;
            max-height: 48px;
          }
        }
        .select2-selection__placeholder {
          font-size: 14px;
          color: #78909C;
          font-weight: 400;
        }
      }
    }
    .po-header-right {
      padding-left: 16px;
      border-left: 1px solid #d9d9d9;
      display: flex;
      margin: auto;
      grid-column-gap: 10px;
      width: 40%;
      .btn {
        min-width: 78px;
        height: 56px;
      }
      .orders {
        border-radius: ${({ theme }) => theme["border-radius-base"]};
        width: 100%;
        height: 56px;
        padding: 10px;
        border-radius: 8px;
        gap: 10px;
        span {
          line-height: 18px;
        }
        &.fbm-order {
          padding: 10px;
          .order-inner-value{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .order-inner-text-wrapper{
            max-width: 70%;
          }
        }
        &.total {
          background-color: ${({ theme }) => theme["primary-color"]}03;
          border: 1px solid ${({ theme }) => theme["primary-color"]};
          .icon-total::before {
            color: ${({ theme }) => theme["black-color"]};
          }
        }
        &.shipped {
          background-color: ${({ theme }) => theme["success-color"]}03;
          border: 1px solid ${({ theme }) => theme["success-color"]};
        }
        &.missing {
          background-color: ${({ theme }) => theme["warning-color"]}05;
          border: 1px solid ${({ theme }) => theme["warning-color"]};
        }
      }
    }
    @media(max-width: 1199px){
      .po-header-left{
        width: 100%;
        padding-right: 0;
        .input-search{
          max-width: 100%;
          min-width: 128px;
          input{
            min-width: 100%;
          }
        }
      }
      .po-header-right{
        margin-top: 10px;
        width: 100%;
        padding-left: 0;
        border-left: 0;
      }
    }
  }
  .purchase-order-grid{
    border: 1px solid #CFD8DC;
    padding-top: 0;
    margin-top: 29px;
    border-radius: 8px;
    .ag-header-cell-text{
      font-size: 14px;
      color: #78909C;
      white-space: pre-line;
    }
    .ag-cell-label-container{
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
  .po-table-status-select{
     .ant-select-selection{
      border-radius: 30px;
      border: 1px solid;
      color: #4E5969;
     }
     .ant-select-arrow {
      color: inh
     }
      .ant-select-selection-selected-value{
        font-weight: 600;
      }
      &.submitted{
        .ant-select-selection{
          border-color: #FFF500;
          background-color: #FFF50033;
        }
      }
      &.confirmed{
        .ant-select-selection{
          border-color: #FFF500;
          background-color: #FFF50033;
        }
      }
      &.payment{
        .ant-select-selection{
          border-color: #F0F500;
          background-color: #FAFF00B2;
        }
      }
      &.pending-shipment{
        .ant-select-selection{
          border-color: #E1E10A;
          background-color: #FFF500;
        }
      }
      &.shipped{
        .ant-select-selection{
          border-color: #CCFF00;
          background-color: #CCFF004D;
        }
      }
      &.inbound{
        .ant-select-selection{
          border-color: #9EFF00;
          background-color: #9EFF004D;
        }
      }
      &.cancelled{
        .ant-select-selection{
          border-color: #CF0909;
          background-color: #FFD6D6;
        }
      }
      &.pending-approval{
        .ant-select-selection{
          border-color: #99EB00;
          background-color: #ADFF0080;
        }
      }
      &.completed{
        .ant-select-selection{
          border-color: #009C34;
          background-color: #ADFF00E5;
        }
      }
  }
  .view-drafts {
    cursor: pointer;
    display: flex;
    gap: 8px;
    overflow-x: auto;
    overflow-y: hidden;
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &.drafts-box{
        width: 100%;
        min-width: 70px;
        max-width: 134px;
        height: 60px;
        padding-left: 22px;
        position: relative;
        color: ${({ theme }) => theme["primary-color"]};
        background:${({ theme }) => theme["white-color"]};
        border: 1px solid ${({ theme }) => theme["primary-color"]};
        border-right: none;
        border-radius: 8px 0 0 8px;
        margin-right: 18px;
        font-size: 14px;
        font-weight: 700;
        &::before {
          content: " ";
          position: absolute;
          width: 100%;
          top: -1px;
          left: 0;
          z-index: -1;
          background:${({ theme }) => theme["white-color"]};
          transform-origin: bottom left;
          transform: skew(-30deg, 0deg);
          border: 1px solid ${({ theme }) => theme["primary-color"]};
          height: 61px;
          border-radius: 0 0 0 8px;
        }
      }
    }
    .curve {
      min-width: 100px;
      width: 100%;
      height: 60px;
	    transform: skew(-30deg);
      border: 1px solid red;
      &.all{
        .info-icon{
          gap: 6px;
        }
        .submitted-radio{
          .MuiButtonBase-root{
	          transform: skew(0deg);
          }
        }
      }
      p {
        margin: 0;
	      transform: skew(30deg);
        color: ${({ theme }) => theme["secondary-color"]};
        line-height: 16px;
        font-size: 18px;
        &:nth-child(2) {
          font-size: 12px;
          font-weight: 700;
          white-space: nowrap;
          max-width: 67%;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .info-icon {
        display: flex;
        align-items: flex-end;
        gap: 8px;
        img {
	        transform: skew(30deg);
          cursor: pointer;
        }
      }
      .submitted-radio {
        display: flex;
        align-items: center;
        gap: 8px;
        p {
          font-size: 18px;
          font-weight: 400;
          color: #000000;
        }
        .MuiButtonBase-root {
	        transform: skew(30deg);
          padding: 0;
        }
      }
      &.submitted {
        background: #FFF50033;
        border: 2px solid #FFF500;
      }
      &.confirmed {
        background: #FAFF0866;
        border: 1px solid #FAFF08;
      }
      &.payment {
        background: #FAFF00B2;
        border: 1px solid #F0F500;
      }
      &.pending-shipment {
        background: #FFF500;
        border: 1px solid #E1E10A;
      }
      &.shipped {
        background: #CCFF004D;
        border: 1px solid #CCFF00;
      }
      &.inbound {
        background: #9EFF004D;
        border: 1px solid #9EFF00;
      }
      &.pending-client {
        background: #ADFF0080;
        border: 1px solid #99EB00;
      }
      &.completed {
        background: #ADFF00E5;
        border: 1px solid #009C34;
      }
      &.cancelled {
        background: #FFD6D6;
        border: 1px solid #CF0909;
      }
      &:last-child {
        position: relative;
        transform: skew(0deg);
        margin-left: 20px;
        border-left: none;
        background: #F3F3F3;
        border: 1px solid #C4C4C4;
        border-radius: 0 8px 8px 0;
        width: 100%;
        min-width: 70px;
        max-width: 134px;
        p {
          transform: skew(0deg);
        }
        .MuiButtonBase-root {
	        transform: skew(0deg);
          padding: 0;
        }
        &::after {
          content: " ";
          position: absolute;
          width: 100%;
          height: 59.5px;
          top: 0.5px;
          right: 0;
          z-index: -1;
          background: #F3F3F3;
          border: 1px solid #C4C4C4;
          transform: skew(-30deg, 0deg);
          margin-right: 20px;
          margin-top: -1px;
          border-right: none;
        }
      }
    }
  }
  .vendor-name {
    font-weight: 600;
    color: ${({ theme }) => theme["secondary-color"]};
  }
  .po-no {
    font-weight: 700;
    text-decoration: underline;
    color: ${({ theme }) => theme["primary-color"]};
  }
  .success-badge {
    border-radius: 20px;
    font-weight: 600;
  }
  .total-profit {
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme["success-color"]};
  }
  .total-lose {
    font-size: 18px;
    font-weight: 600;
    color: #CF0909;
  }
  .buttons-wrapper{
    display: flex;
    justify-content: center;
    gap: 8px;
    border-left: 1px solid #CFD8DC;
  }
  .delete-button {
    color: #CF0909;
    padding: 13px 8px;
    border-color: #CF0909;
    background-color: #fff;
    &:hover{
      color: #fff;
      border-color: #CF0909;
      background-color: #CF0909;
    }
  }
  .view-button{
    padding: 13px 8px;
  }
  .view-button:focus,
  .view-button:active,
  .delete-button:focus,
  .delete-button:active {
    box-shadow: none;
    outline: none;
  }
  .user-designation {
    span:nth-child(2) {
      font-size: 12px;
      color: ${({ theme }) => theme["dusty_grey"]};
    }
  }
  .ship-to {
    overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    span {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      &:first-child {
        color: ${({ theme }) => theme["primary-color"]};
      }
      &:nth-child(2) {
        font-size: 12px;
        color: ${({ theme }) => theme["dusty_grey"]};
      }
    }
  }
  .invoice-download {
    text-align: center;
    span:nth-child(2) {
      color: ${({ theme }) => theme["primary-color"]};
      font-size: 10px;
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .error-badge {
    white-space: pre-wrap;
    text-align: center;
    border-radius: 24px;
    font-size: 10px;
    font-weight: 600;
    padding: 4px 14px;
    width: 118px;
    margin: auto;
  }
  .tracking-info {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    span:nth-child(2) {
      color: ${({ theme }) => theme["primary-color"]};
      font-size: 10px;
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
      text-align: center;
    }
    .error-badge {
      padding: 4px 8px;
      width: 78px;
    }
  }
  .delivery-date {
    .error-badge {
      padding: 4px 8px;
      width: 78px;
    }
    .delivery-badge {
      color: ${({ theme }) => theme["secondary-color"]};
      padding: 4px 8px;
      font-size: 12px;
      font-weight: 600;
      border-radius: 24px;
      max-width: 80px;
      min-width: 80px;
      min-height: 32px;
      line-height: 12px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: wrap;
      box-sizing: border-box;
      position: relative;
      &.info-badge {
        background: #1565D81A;
        border: 1px solid ${({ theme }) => theme["primary-color"]};
      }
      &.partially-recieved {
        border: 1px solid ${({ theme }) => theme["warning-color"]};
        background-image: url(${partiallyBg});
      }
      &.scanned-in-badge {
        background: #FFF50033;
        border: 1px solid #FFF500;
      }
      &.received-badge {
        background: #ADFF0080;
        border: 1px solid ${({ theme }) => theme["success-color"]};
      }
      &.shipped-badge {
        background: #CCFF004D;
        border: 1px solid #CCFF00;
      }
      &.overdue-badge {
        padding: 4px 22px;
        border: 1px solid #cf0909;
        border-radius: 48px;
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        background-color: #CF090980;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    
    .show-more {
      color: ${({ theme }) => theme["primary-color"]};
      font-size: 10px;
      font-weight: 600;
      text-decoration: underline;
      line-height: 10px;
      cursor: pointer;
    }
    .checked-in {
      font-size: 12px;
      font-weight: 600;
      color: ${({ theme }) => theme["primary-color"]};
      cursor: pointer;
    }
  }
  .quantity-tabs {
    border: 1px solid ${({ theme }) => theme["primary-color"]};
    color: ${({ theme }) => theme["primary-color"]};
    border-radius: 30px;
    background: ${({ theme }) => theme["outline-color"]};
    font-size: 10px;
    font-weight: 600;
    width: max-content;
    height: 26px;
    .nav-link {
      margin-bottom: 0;
      padding: 2px 8px;
      height: 100%;
      &:hover {
        border-radius: 30px;
      }
    }
    .nav-link.active {
      background: ${({ theme }) => theme["primary-color"]};
      color: #fff;
      border-radius: 30px;
      border-color: ${({ theme }) => theme["primary-color"]};
    }
  }
  .tab-content{
    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis;
    .tabs-inner-data-wrapper{
    overflow: hidden;
    text-overflow: ellipsis;
    }
  }
  .tab-units {
    border: 1px solid ${({ theme }) => theme["primary-color"]};
    margin: 0 10px;
    border-top: 0;
    border-radius: 0 0 12px 12px;
    padding: 6px;
    color: #CF0909;
    text-align: center;
    line-height: 12px;
    font-size: 10px;
    span {
      font-size: 12px;
      font-weight: 700;
    }
  }
  .tooltip-wrapper {
    border: 2px solid #1565D8;
    border-radius: 16px;
    box-shadow: 4px 4px 25px 0px #6FD5F640;
    padding: 16px;
    line-height: 20px;
    width: 240px;
    height: auto;
  }
  .tooltip-wrapper span{
    font-weight: 400;
    font-size: 16px;
    color: #000;
  }
  .tooltip-wrapper .status{
    color: #1565D8;
  }
  .tooltip-wrapper .heading{
    font-weight: 700;
    color: #4E5969;
  }
 
.tooltip-wrapper-2{
  border: 2px solid #1565D8;
  border-radius: 16px;
  padding: 16px;
  width: fit-content;
  box-shadow: 4px 4px 25px 0px #6FD5F640;
}
.tooltip-wrapper-2 .data-wrapper{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
}
.tooltip-wrapper-2 .data-wrapper:last-child{
  margin-bottom: 0;
}
.tooltip-wrapper-2 .data-wrapper .danger,
.tooltip-wrapper-2 .data-wrapper .danger span,
.tooltip-wrapper-2 .data-wrapper .danger span.label{
  color: #CF0909;
}
.tooltip-wrapper-2 .data-wrapper-inner{
  display: flex;
  gap: 20px;
}
.tooltip-wrapper-2 .data-wrapper span{
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 14px;
  color: #4E6069;
}

.tooltip-wrapper-2 .data-wrapper span.label{
  font-size: 10px;
  color: #78909C;
}
.tooltip-wrapper-2 .data-wrapper .heading{
  font-size: 16px;
  line-height: 20px;
  width: 60px;
  font-weight: 700;
  color: #4E5969;
}

`
const MatchingPoWrapper = styled.div `
&.matching-po {
  .ag-row:hover::before {
    background-color: unset !important;
  }
}
.text-ellipse{
  max-width: 130px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
`
const RecievedShipmentWrapper = styled.div `
&.recieved-shipment-wrapper{
  .drawer-pagination{
    margin: 8px 0;
    display: flex;
    justify-content: end;
    align-items: center;
    .drawer-table-navigation{
      display: flex;
      button{
        padding: 0 0 0 16px;
        margin-left: 16px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;
        border-left: 1px solid #C4C4C4;
      }
    }
  }
  .ag-theme-balham{
    padding-top: 0;
  }
  .ag-root-wrapper {
    border-radius: 8px;
  }
  .ag-header {
    border-bottom: 0;
    .ag-header-cell-text{
      color: #979797;
      font-weight: 600;
    }
  }

  .ag-row{
    .vendor-name{
      font-weight: 600;
    }
    .inner-text{
      font-size: 10px;
      font-weight: 400;
    }
    .matching-po {
      font-size: 14px;
      font-weight: 600;
      color: #1565D8;
      text-align: center;
      margin-left: 32px;
    }
  }
  .ag-center-cols-viewport{
    .ag-cell-wrapper{
      height: 34px ;
      min-height: 34px ;
      padding: 0;
    }
}
.ant-calendar-picker{
  width: 152px;
  cursor: pointer;
  /* &:after{
      content: '';
      height: 16px;
      width: 16px;
      display: block;
      background: url(${dropdownIcon});
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 9px;
      right: 8px;
    } */
  .anticon{
    display: none;
  }
  .ant-calendar-picker-input{
    border: none;
    font-weight: 700;
    padding-right: 0;
    &:focus{
      outline: none;
      box-shadow: none;
    }
    &::placeholder{
      color: #000;
    }
  }
}
.ag-header-cell::after{
  display: none;
}
}
  
`
const UploadStyleWrapper = styled.div `
.header-inner-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  button{
    color: #00A3FF;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 600;
  }
}
.table-header-upload-file-wrapper {
    display: flex;
    align-items: center;
    padding: 12px;
    background-color: #F1FBFE;
    border-radius: 8px 8px 0 0;
    .upload-title-wrapper{
      color: var(--Heading, #000);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: 30px;
      background-color: red;
    }
    img{
      margin-right: 8px;
    }
    h5 {
    color: var(--Primary, #1565D8);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 71.429% */
    margin: 0px;
}
button {
    color: var(--Accent-blue, #00A3FF);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 10px; /* 62.5% */
    text-decoration-line: underline;
    background: transparent;
    border: none;
    padding-bottom: 0px;
    padding-right: 0px;
    padding-left: 24px;
    margin-bottom: -2px;
    span {
    border-bottom: 1px solid #00A3FF;
    padding-bottom: 2px;
}
}
}
.checklist-item.btn-cell-renderer {
    background: rgba(0, 156, 52, 0.20);
}
.column-cell-wrapper {
    background: rgba(0, 156, 52, 0.20);
}


`;

const InvoicePopupWrapper = styled.div`
.save-fileButton {
  border: 1px solid #1565D8;
  color: #1565D8;
  border-radius: 4px;
  background-color: #fff;
  font-weight: 700;
  width: 100%;
  &:hover{
    background-color: #1565D8;
    color: #fff;
  }
}
span.invoice-popup-date-picker {
    width: 220px;
    cursor: pointer;
    .anticon-calendar {
    display: none;
}
}
.ant-calendar-picker-input {
  cursor: pointer;
}
.add-invoice-box {
  border: 1px solid #6FD5F6;
  border-radius: 8px;
  padding: 32px 16px;
  .add-invoice-heading {
    color: var(--Heading, #000);
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    margin-bottom: 24px;
  }
  .box-divider {
    padding-right: 28px;
    border-right: 1px solid #6FD5F6;
  }
}
.upload-product-item {
      display: flex;
      justify-content: center;
      .ant-upload-drag {
        padding: 16px;
        width: 236px;
        height: auto;
        border-color: #6FD5F6;
        border-radius: 8px;
        .ant-upload-drag-container {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
      span.ant-upload.ant-upload-btn {
        padding: 0px;
      }
      .ant-upload-list-item-name{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width:200px;
      } 
      .ant-upload-drag-container {
        h6 {
          color: var(--Heading, #000);
          font-family: Titillium Web;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          margin: 0px;
        }
        .drag-icon {
          max-width: 130px;
          margin-bottom: 22px;
          svg {
            font-size: 30px;
            color: rgba(0, 163, 255, 1);
          }
        }
        .ant-upload-text {
          color: var(--Heading, #000);
          text-align: left;
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 0;
        }
        .ant-upload-hint {
          color: var(--Light-Grey, #c4c4c4);
          text-align: center;
          font-family: Titillium Web;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 12px;
          margin-bottom: 26.5px;
        }
      }
      button {
        display: flex;
        width: 100%;
        padding: 8px;
        justify-content: center;
        align-items: center;
        margin: auto;
        border-radius: 4px;
        border: 1px solid var(--Primary, #1565d8);
        color: var(--Primary, #1565d8);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
      }
    }
    .drawer-pagination{
    margin: 8px 0;
    display: flex;
    justify-content: end;
    align-items: center;
    .drawer-table-navigation{
      display: flex;
      button{
        padding: 0 0 0 16px;
        margin-left: 16px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;
        border-left: 1px solid #C4C4C4;
      }
    }
  }
  .ant-calendar-picker{
  width: 152px;
  cursor: pointer;
  /* &:after{
      content: '';
      height: 16px;
      width: 16px;
      display: block;
      background: url(${dropdownIcon});
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 9px;
      right: 8px;
    } */
  .ant-calendar-picker-input{
    border: none;
    background-color: transparent;
    font-weight: 700;
    padding-right: 0;
    &:focus{
      outline: none;
      box-shadow: none;
    }
    &::placeholder{
      color: #000;
    }
  }
}
.divider{
  height: 3px;
  width: 194px;
  background-color: #00A3FF;
  margin: 32px auto;
  display: block;
}
.inner-heading{
  font-size: 20px;
  color: #4E5969;
  margin-bottom: 16px;
}
.search-bar-wrapper {
  position: relative;
  input {
    padding: 8px 32px 8px 12px;
    border-radius: 4px;
    border: 1px solid var(--Light-Mid-Grey, #e6e6e6);
    background: var(--White, #fff);
    color: #78909c;
    width: auto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    height: 40px;
    line-height: 16px; /* 133.333% */
  }
  svg {
    position: absolute;
    right: 10px;
    top: 14px;
    color: #1565d8;
    cursor: pointer;
  }
}
.recent-uploads{
  padding: 12px;
  border: 1px solid #CFD8DC;
  border-radius: 8px;
  margin-top: 8px;
  .recent-upload{
    padding: 16px;
    background-color: #4E5969;
    border-radius: 8px;
    button{
      border: none;
      border-radius: 4px;
      background-color: #FFFFFF;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .invoice-img{
      width: 100%;
      height: auto;
    }
  }
  .upload-inner-wrapper{
    display: flex;
    padding: 12px 0;
    gap: 12px;
    justify-content: space-between;
    align-items: center;
    .upload-id{
      font-size: 14px;
      font-weight: 600;
      color: #FFFFFF;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .upload-date{
      font-size: 10px;
      font-weight: 400;
      color: #FFFFFF;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.user-cell-class {
  .ag-cell-value {
    &::after {
      content: '';
      position: absolute;
      right: 0;
      width: 1px;
      height: 24px;
      background-color: #00A3FF;
      margin-right: 12px;
    }
  }
}
`;
export {
  StepperStyleWrapper,
  AddPurchaseOrderStyle,
  ShipThirdPartyLogisticsWrapper,
  ChooseShippingWarehouseWrapper,
  AddProductToPurchaseOrderWrapper,
  FileUploadWrapper,
  TableWrapper,
  VenderShipmentAddressWrapper,
  InboundShipmentwrapper,
  PricingAndFeesWrapper,
  EstimateDeliveryInfo,
  DeliveryInformationWrapper,
  CongratulationWrapper,
  PurchaseOrderWrapper,
  RecievedShipmentWrapper,
  UploadStyleWrapper,
  InvoicePopupWrapper,
  MatchingPoWrapper
};