import React, { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, Checkbox, Tooltip, Spin } from "antd";
import { useSelector } from "react-redux";

// import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { TableWrapper } from "../style";

const Table = ({
  gatedPos,
  setGatedPos
}) => {
  const { loading } = useSelector((store) => store.fbaShipment);
  const handleRequestImageChange = (props, value, id) => {
    setGatedPos(prevGatedPos => {
      return prevGatedPos.map(product => {
        if (product.id === id) {
          return {
            ...product,
            [props]: value,
            ...(product?.removeSkuFromPo === true ? { removeSkuFromPo: false } : {})
          };
        }
        return product;
      });
    });
  }

  const handleStorageChange = (props, id, value) => {
    setGatedPos(prevGatedPos => {
      return prevGatedPos.map(product => {
        if (product.id === id) {
          return {
            ...product,
            [props]: value,
            ...(props === 'putInStorage' && product?.removeSkuFromPo === true ? { removeSkuFromPo: false } : {}),
            ...(props === 'removeSkuFromPo' ? { putInStorage: false, requestPicture: false } : {})
          };
        }
        return product;
      });
    });
  }

  const columnDefs = [
    {
      headerName: "Title",
      field: "title",
      suppressMenu: true,
      cellRendererFramework: ({ value, data }) => {
        const { title, image, asin } = data || {};
        return <CustomTitleRenderer title={title} image={image} asin={asin} />;
      },
      width: 180,
    },
    {
      headerName: "SKUs",
      field: "sKUs",
      suppressMenu: true,
      cellRendererFramework: ({ value, data }) => {
        const sku = data?.sku || 'N/A';
        return (
          <Tooltip title={sku}>
            <p className="ag-inner-tooltip-text">{sku}</p>
          </Tooltip>
        );
      },
      width: 70,
    },
    {
      headerName: "Qty",
      field: "qty",
      suppressMenu: true,
      cellRendererFramework: ({ value, data }) => {
        return (
          <span>
            {String(data?.quantity || 0).length > 4 ? (
              <Tooltip title={data?.quantity || 0}>
                <p className="sku-text">{data?.quantity || 0}</p>
              </Tooltip>
            ) : (
              <p className="sku-text">{data?.quantity || 0}</p>
            )}
          </span>
        );
      },
      width: 51,
    },
    {
      headerName: "Status",
      field: "status",
      suppressMenu: true,
      cellRendererFramework: ({ value, data }) => {
        return <p className="bages-wrapper">Gated</p>;
      },
      width: 80,
    },
    {
      headerName: "Request Picture",
      field: "requestPicture",
      suppressMenu: true,
      cellRendererFramework: ({ value, data }) => {
        return (
          <Button
            className={`checked-button ${data?.requestPicture ? "active" : null}`}
            onClick={() => handleRequestImageChange('requestPicture', !data?.requestPicture, data?.id)}
          >
            <Checkbox size="small" checked={data?.requestPicture} />
            Request
          </Button>
        );
      },
      width: 112,
    },
    {
      headerName: "Actions",
      field: "actions",
      suppressMenu: true,

      cellRendererFramework: ({ value, data }) => {
        return (
          <div className="action-column-wrapper">
            <Button
              style={{ width: "73px", whiteSpace: 'wrap', textAlign: 'left' }}
              className={`checked-button-next ${data?.putInStorage ? "active" : null}`}
              onClick={() => handleStorageChange('putInStorage', data?.id, !data?.putInStorage)}
            >
              <Checkbox size="small" checked={data?.putInStorage} />
              Put In Storage
            </Button>
            <Button
              style={{ width: "106px", whiteSpace: 'wrap', textAlign: 'left' }}
              className={`checked-button-next ${data?.removeSkuFromPo ? "active" : null}`}
              onClick={() => handleStorageChange('removeSkuFromPo', data?.id, !data?.removeSkuFromPo)}
            >
              <Checkbox size="small" checked={data?.removeSkuFromPo} />
              Remove SKU from PO
            </Button>
          </div>
        );
      },
      width: 196,
    },
    // Add more columns as needed
  ];
  const CustomTitleRenderer = ({ title, image, asin }) => {
    return (
      <div className="product-data-item">
        <div className="product">
          <img width={50} height={50} src={image || '/images/no-image.png'} alt="Product" />
          <div className="product-details">
            <Tooltip title={title}><h6>{title}</h6></Tooltip>
            <div className="product-text">
              <p>
                ASIN: <span>{asin}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const defaultColDef = useMemo(() => ({
    suppressSorting: false,
  }), []);

  return (
    <TableWrapper>
      <div
        className="ag-theme-material gated-asin-table"
      >
        <Spin spinning={loading}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={gatedPos}
            rowHeight={84}
            defaultColDef={defaultColDef}
            domLayout="autoHeight"
          />
        </Spin>
      </div>
    </TableWrapper>
  );
};

export default Table;
