import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  message: "",
  fetchingForVendors: false,
  purchaseOrders: [],
  total_records: 0,
  vendors: [],
  filters: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  }
};

const simplePurchaseOrders = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'SET_FILTERS_SIMPLE_PURCHASE_ORDERS':
    case 'SET_SORT_OPTIONS_SIMPLE_PURCHASE_ORDERS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_VENDORS_FOR_SIMPLE_PURCHASE_ORDERS_REQUEST': {
      return {
        ...state,
        fetchingForVendors: true
      };
    }
    case 'GET_SIMPLE_PURCHASE_ORDERS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_SIMPLE_PURCHASE_ORDERS_SUCCESS': {
      const { total_records, vendors } = action.payload;
      return {
        ...state,
        fetching: false,
        total_records,
        purchaseOrders: vendors
      };
    }
    case 'SET_PAGINATION_SIMPLE_PURCHASE_ORDERS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    case 'GET_SIMPLE_PURCHASE_ORDERS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_VENDORS_FOR_SIMPLE_PURCHASE_ORDERS_FAILED': {
      return {
        ...state,
        fetchingForVendors: false
      };
    }
    case 'GET_VENDORS_FOR_SIMPLE_PURCHASE_ORDERS_SUCCESS': {
      return {
        ...state,
        fetchingForVendors: false,
        vendors: action.payload ? [...action.payload] : []
      };
    }
    case "PROFIT_PERCENTAGE_SUCCESS": {
      return {
        ...state,
        fetching: false,
        message: action.payload,
      };
    }
    case "CLEAR_FILTERS": {
      return {
        ...state,
        filters: [],
        sortOptions: {},
      };
    }
    default:
      return state;
  }
};

export default simplePurchaseOrders;
