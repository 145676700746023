import React, { lazy } from 'react';
import Dashboard from '../../components/purchaseOrders/combinedPoAndDashboard.jsx';

const NewPurchaseOrderContainer = lazy(() => import('../../containers/purchaseOrders/NewPurchaseOrderContainer'));
const PurchaseOrderDetailsContainer = lazy(() => import('../../containers/purchaseOrders/newPurchaseOrderContainers/purchaseOrderDetailsContainer'));
const PendingPosContainer = lazy(() => import('../../containers/purchaseOrders/PendingPosContainer'));
const PendingPoIndicesContainer = lazy(() => import('../../containers/purchaseOrders/PendingPoIndicesContainer'));
const AllOrdersContainer = lazy(() => import('../../containers/allOrders/allOrdersContainer'));
const PoInvoicesContainer = lazy(() => import('../../containers/PoInvoicesContainer'));
const PurchaseInvoicesContainer = lazy(() => import('../../containers/PurchasingInvoicesContainer'));
const PendingItemsContainer = lazy(() => import('../../containers/purchaseOrders/PendingItemsContainer'));

export const PURCHASE_ORDERS_ROUTES = [
  // {
  //   path: '/po/new-po-plan',
  //   component: <Dashboard />,
  //   group: 'PO',
  //   reducer: ['uploadPurchaseOrder', 'simplePurchaseOrders', 'pendingPurchaseOrders']
  // },
  {
    path: '/po/new-po-plan/:id',
    component: <PendingItemsContainer />,
    group: 'PO',
    reducer: 'pendingPurchaseOrders'
  },
  // {
  //   path: '/po/pos/:id',
  //   component: <PurchaseOrderDetailsContainer />,
  //   group: 'PO',
  //   reducer: 'purchaseOrderDetails'
  // },
  {
    path: '/po/bulk-shipment/:id',
    component: <PurchaseOrderDetailsContainer />,
    group: 'PO',
    reducer: 'purchaseOrderDetails'
  },
  {
    path: '/po/my-orders/:id',
    component: <PendingPosContainer />,
    group: 'PO',
    reducer: 'pendingPos'
  },
  // {
  //   path: '/po/my-orders',
  //   component: <Dashboard />,
  //   group: 'PO',
  //   reducer: 'pendingPoIndices'
  // },
  {
    path: '/po/all-orders',
    component: <Dashboard />,
    group: 'PO',
    reducer: 'allOrders'
  },
  {
    path: '/po/reconciliation',
    component: <Dashboard />,
    group: 'PO',
    reducer: 'poInvoices'
  },
  {
    path: '/po/invoices',
    component: <Dashboard />,
    group: 'PO',
    reducer: 'purchasingInvoices'
  }
];
