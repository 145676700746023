import React, { useState } from "react";
import { HomePageWrapper } from "../indexPage/style";
import Header from "../fbaHeader/index";
import Location from "../../assets/images/location-img.png"
import BeyonLocation from "../../assets/images/beyon-location.svg"
import OneClick from "../../assets/images/one-click.svg"
import ClickImage from "../../assets/images/click-img.png"
import OptimizedImage from "../../assets/images/optimize-img.png"
import Help from "../../assets/images/help.svg"
import WeHelp from "../../assets/images/weHelp.png"
import AutomateFba from "../../assets/images/automate-fba.svg"
import InventoryMangement from "../../assets/images/inventry-mangement.svg"
import EcomFulfil from "../../assets/images/ecom-fulfil.svg"
import LowestPrice from "../../assets/images/lowest-price.svg"
import { Form, Button, Modal } from "react-bootstrap";
import { isEmpty } from 'lodash';
import axios from '../../../axios_config';
import { notification } from 'antd';

const Index = () => {
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleFormSubmit = async () => {
    return axios({
      method: 'POST',
      url: `/api/v2/contacts/visitor`,
      data: {
        visitor: {
          user_name: name,
          email
        }
      },
      headers: {
        Authorization: ''
      }
    }).then(() => {
      setName('');
      setEmail('');
      setShowModal(false);
    }).catch((error) => {
      if (axios.isCancel(error)) return;
      notification.error({
        message: 'SUBMIT FORM',
        description: error.message || 'Failed To Submit',
        top: 65
      })
    })
  }

  return (
    <HomePageWrapper>
      <Header />
      <div className="blue-back">
        <div className="custom-container">
          <div className="business-amazon">
            <div className="flex header-button">
              <div className="modal-section">
                <div className="btn-three">
                  <button
                    type="button"
                    className="btn-wrapper mb-1"
                    onClick={() => setShowModal(true)}
                  >
                    Amazon FBA Wholesale
                  </button>
                </div>
                <div className="btn-three">
                  <button
                    type="button"
                    className="btn-wrapper mb-1"
                    onClick={() => setShowModal(true)}
                  >
                    Retail Arbitrage FBA
                  </button>
                </div>
                <div className="btn-three mb-1">
                  <button
                    type="button"
                    className="btn-wrapper"
                    onClick={() => setShowModal(true)}
                  >
                    Direct to Consumer
                  </button>
                </div>
                <div className="btn-three mb-1">
                  <button
                    type="button"
                    className="btn-wrapper"
                    onClick={() => setShowModal(true)}
                  >
                    Freight Forwarding
                  </button>
                </div>
                <div className="btn-three mb-1">
                  <button
                    type="button"
                    className="btn-wrapper"
                    onClick={() => setShowModal(true)}
                  >
                    B2B distribution
                  </button>
                </div>
              </div>
              <iframe
                className="embed-responsive-item threepl-video"
                src="https://fbasupport-videos.s3.us-west-2.amazonaws.com/threepl_new_video.mp4"
                allow="autoplay"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="threepage-layout">
        <div className="esential-amazon hidden-xs">
        </div>
        <div className="custom-container">
          <div className="business-section">
          </div>
        </div>
      </div>
      <div className="threepl-sections">
        <div className="section-outer mt-20">
          <div className="support-screen f-left hidden-xs">
            <img src={Location} alt="no-png" />
          </div>
          <div className="custom-container">
            <div className="text-section">
              <div className="support-text">
                <div className="heading-wrapper margin-0">
                  <div className="heading-icon">
                    <img src={BeyonLocation} alt="no-png" />
                  </div>
                  <h2>Beyond a Location</h2>
                </div>
                <p>
                  East coast or west coast, we’re everywhere. Our warehouses are located in strategic places to give you a competitive edge and make your  fulfillment  hitch-free experiences. We have warehouses in New York, New Jersey, Texas, California, and Ohio. With five different warehouses across the USA, be rest assured your inventories are in safe hands.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="threepl-sections">
        <div className="section-outer mt-20 col-rev ">


          <div className="custom-container">
            <div className="text-section">
              <div className="support-text">
                <div className="heading-wrapper">
                  <div className="heading-icon">
                    <img src={OneClick} alt="no-svg" />
                  </div>
                  <h2>One-click Software Automation and set up.</h2>
                </div>
                <p>
                  No technical skills required and no complex setup. All you have to do is connect your marketplace and upload your orders. Our software automates and consolidates all your shipping plans, and we take it from there.
                </p>
              </div>
            </div>
          </div>
          <div className="support-screen f-right hidden-xs">
            <img src={ClickImage} alt="no-png" />
          </div>
        </div>
      </div>
      <div className="threepl-sections large-img">
        <div className="section-outer ">
          <div className="support-screen f-left hidden-xs">
            <img src={OptimizedImage} alt="no-png" />
          </div>
          <div className="custom-container">
            <div className="text-section">
              <div className="support-text">
                <div className="heading-wrapper">
                  <div className="heading-icon">
                    <img src={InventoryMangement} alt="no-inventry" />
                  </div>
                  <h2>Optimized Inventory Management and Order Fulfillment.</h2>
                </div>
                <p>
                  Once you’ve determined what products you want to sell, you can keep track of all orders here in this
                  section of the dashboard. Manage all of your fulfilled purchases & upcoming orders all in one spot with
                  this 3PL automation. Our 3PL system also connects to the warehouse so that when you upload a purchase
                  order, it is automatically sent to the warehouse so the order can be shipped.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="threepl-sections large-img">
        <div className="section-outer col-rev">


          <div className="custom-container">
            <div className="text-section">
              <div className="support-text">
                <div className="heading-wrapper">
                  <div className="heading-icon">
                    <img src={Help} alt="no-svg" />
                  </div>
                  <h2>How we can help?</h2>
                </div>
                <p>
                  Keep track of all of the inventory that your business is handling. See your products’ inventory stock
                  and sales in real-time right here. If you see a product is selling well & inventory is low, hit the
                  re-order button right inside this section of the dashboard.
                </p>
              </div>
            </div>
          </div>
          <div className="support-screen f-right hidden-xs">
            <img src={WeHelp} alt="no-svg" />
          </div>
        </div>
      </div>
      <div className="threepl-sections  large-img">
        <div className="section-outer ">
          <div className="support-screen f-left hidden-xs">
            <img src={AutomateFba} alt="no-svg" />
          </div>
          <div className="custom-container">
            <div className="text-section">
              <div className="support-text">
                <div className="heading-wrapper">
                  <div className="heading-icon">
                    <img src={EcomFulfil} alt="no-svg" />
                  </div>
                  <h2>ECommerce Fulfillment</h2>
                </div>
                <p>
                  Get a full overview of the inbound shipment of your goods to Amazon. Most importantly you can set
                  your shipping plans to see what the best options there are for you when sending goods, in order to
                  save money on transportation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lowest-pricing">
        <div className="mw-1300">
          <div className="row" style={{ margin: 0 }}>
            <div className="col-md-6">
              <div className="py-80">
                <h2> Lowest pricing with no hidden fees</h2>
                <p>Sales Support offers transparent pricing for all sellers. No add-on charges, no extra fees, and no ambiguous pricing. We offer you reasonable order fulfillment pricing without any extra spending.

                </p>
                <p> No seller deserves to pay hefty fees, all in the name of order fulfillment. Take advantage of our complete order fulfillment solution without breaking the bank.</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="py-120">
                <div className="reasonable-price">
                  <img src={LowestPrice} alt="no-svg" />
                  <div className="p-17">
                    <h2 className="pricing">Reasonable pricing for all Merchants</h2>
                    <h6>What you see is what you get</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pro-sold-section float-none" style={{ marginTop: 0, paddingBottom: 20, paddingTop: 20 }}>
        <div className="custom-container">
          <div className="amazon-section layout-change">
            <div className="kick-amazon">
              <a className="sold-btn">3,240 products sold</a>
              <div>  Ready to scale your business?
                Let's work together.
              </div>
            </div>
            <div className="threepl">
              <div className="btn-three"><button type="button" className="purple-btn" onClick={() => setShowModal(true)}>Amazon FBA Wholesale</button></div>
              <div className="btn-three"><button type="button" className="purple-btn" onClick={() => setShowModal(true)}>Retail Arbitrage FBA</button></div>
              <div className="btn-three"><button type="button" className="purple-btn" onClick={() => setShowModal(true)}>Direct to Consumer</button></div>
              <div className="btn-three"><button type="button" className="purple-btn" onClick={() => setShowModal(true)}>Freight Forwarding</button></div>
              <div className="btn-three"><button type="button" className="purple-btn" onClick={() => setShowModal(true)}>B2B distribution</button></div>
            </div>
          </div>
        </div>
      </div>
      <div className="custom-container-footer">
        <footer>
          <div className="copyright">© 2022 -All Rights reserved. Support.Sales</div>
          <div className="footer-link">
            <a> Still have questions?</a>
            <a href="/contact-us" className="contact-link">CONTACT US</a>
            {/* <%= link_to "CONTACT US", contact_us_pages_path, className: "contact-link" %> */}
          </div>
        </footer>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Watch our onboarding training here</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-section">
            <Form.Group className="mb-3">
              <Form.Control type="text" placeholder="Name" onChange={({ target }) => setName(target.value)} />
            </Form.Group>
            <Form.Group className="mb-3" >
              <Form.Control type="text" placeholder="Email" onChange={({ target }) => setEmail(target.value)} />
            </Form.Group>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className='btn-wrapper w-100'
            disabled={isEmpty(name.trim()) || isEmpty(email.trim())}
            onClick={handleFormSubmit}>
            SUBMIT
          </Button>
        </Modal.Footer>
      </Modal>

    </HomePageWrapper>
  );
};
export default Index;
