import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';

import { handleRejectedResponse } from './utils';
import axios from '../../axios_config';

export const GetProducts = createAsyncThunk(
  '/list_all_products',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/v2/list-products`, { params: data });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get All Products', rejectWithValue);
    }
  }
);
export const CreateStrategy = createAsyncThunk(
  '/create-strategy',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(`api/v2/repricer/create_new_strategy`, data);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get All Products', rejectWithValue);
    }
  }
);

export const GetStrategies = createAsyncThunk(
  '/get-strategies',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`api/v2/strategies`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get All Products', rejectWithValue);
    }
  }
);

export const GetStrategyById = createAsyncThunk(
  '/get-strategy-by-id',
  async (strategyId, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/v2/strategies/${strategyId}`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get All Products', rejectWithValue);
    }
  }
);

export const SwitchStrategy = createAsyncThunk(
  '/switch-strategy',
  async ({ strategyId, productId }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.put(`/api/v2/products/${productId}/switch?strategyId=${strategyId}`);
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get All Products', rejectWithValue);
    }
  }
);

export const UpdateStrategy = createAsyncThunk(
  '/update-strategy',
  async ({ selectedStrategy, strategyData }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.put(`/api/v2/strategies/${selectedStrategy}`, { strategyData });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get All Products', rejectWithValue);
    }
  }
);

export const UpdateUnitPrice = createAsyncThunk(
  '/update-unit-price',
  async ({
    productId,
    newPrice
  }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.patch(`/api/v2/product/${productId}/edit-unit-price`, { newPrice });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get All Products', rejectWithValue);
    }
  }
);
export const UpdateProduct = createAsyncThunk(
  '/update-product',
  async ({
    productId,
    reprice
  }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.put(`/api/v2/product/${productId}`, { reprice });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get All Products', rejectWithValue);
    }
  }
);
const productSlice = createSlice({
  name: "productSlice",
  initialState: {
    loading: false,
    editSuccess: false,
    products: [],
    totalProducts: 0,
    strategies: [],
    strategy: {},
    strategyAppliedCount: 0,
    switchStrategyUpdate: false,
    getStrategyLoading: false
  },
  reducers: {
    setProductsStates(state, { payload: { field, value } }) {
      state[field] = value;
    },
  },
  extraReducers: {
    [GetProducts.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetProducts.fulfilled]: (state, action) => {
      const { total, products } = action?.payload || {};
      return {
        ...state,
        loading: false,
        products: products,
        totalProducts: total
      }
    },
    [GetProducts.rejected]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [GetStrategies.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetStrategies.fulfilled]: (state, action) => {
      const { strategies } = action?.payload || {};
      return {
        ...state,
        loading: false,
        strategies
      }
    },
    [GetStrategies.rejected]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [CreateStrategy.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [CreateStrategy.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [CreateStrategy.rejected]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [GetStrategyById.pending]: (state, action) => ({
      ...state,
      getStrategyLoading: true
    }),
    [GetStrategyById.fulfilled]: (state, action) => {
      const { strategy, strategyAppliedCount } = action?.payload || {};
      return {
        ...state,
        getStrategyLoading: false,
        strategy,
        strategyAppliedCount
      }
    },
    [GetStrategyById.rejected]: (state, action) => {
      return {
        ...state,
        getStrategyLoading: false
      }
    },
    [SwitchStrategy.pending]: (state, action) => ({
      ...state,
      loading: true,
      switchStrategyUpdate: false
    }),
    [SwitchStrategy.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        switchStrategyUpdate: true
      }
    },
    [SwitchStrategy.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        switchStrategyUpdate: false
      }
    },
    [UpdateStrategy.pending]: (state, action) => ({
      ...state,
      loading: true,
      switchStrategyUpdate: false
    }),
    [UpdateStrategy.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
        switchStrategyUpdate: true
      }
    },
    [UpdateStrategy.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        switchStrategyUpdate: false
      }
    },
    [UpdateUnitPrice.pending]: (state, action) => ({
      ...state,
      loading: true,
      editSuccess: false
    }),
    [UpdateUnitPrice.fulfilled]: (state, action) => {
      const { message } = action?.payload || {};
      notification.success({
        message: 'Update Unit Price',
        description: message,
        top: 65
      });
      return {
        ...state,
        loading: false,
        editSuccess: true
      }
    },
    [UpdateUnitPrice.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        editSuccess: false
      }
    },
    [UpdateProduct.pending]: (state, action) => ({
      ...state,
      loading: true,
      editSuccess: false
    }),
    [UpdateProduct.fulfilled]: (state, action) => {
      const { message } = action?.payload || {};
      notification.success({
        message: 'Update Product',
        description: message,
        top: 65
      });
      return {
        ...state,
        loading: false,
        editSuccess: true
      }
    },
    [UpdateProduct.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        editSuccess: false
      }
    }
  }
});
const { reducer, actions } = productSlice;
export const { setProductsStates } = actions;
export default reducer;