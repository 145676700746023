import React from "react";
import Switch from "react-switch";
import { SwitchWrapper } from './style';

const SwitchButton = (props) => {
  const { name, text, checked, onChange, className } = props;

  return (
    <SwitchWrapper className={`d-flex grid-column-gap-15 align-items-center ${className}`}>
      <Switch
        id={name}
        uncheckedIcon={false}
        checkedIcon={false}
        onColor="#E9ECEF"
        offColor="#fff"
        onHandleColor="#fff"
        offHandleColor="#E9ECEF"
        handleDiameter={10}
        height={12}
        width={24} 
        checked={checked}
        onChange={onChange}
        className="react-switch"
      />
      {text && <span>{text}</span>}
    </SwitchWrapper>
  );
};

export default SwitchButton;
