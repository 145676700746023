import SignIn from '../../src/components/profile/component/login.jsx';
import SignUp from '../../src/components/profile/component/signUp';
import EmailVerifaction from '../../src/components/profile/component/emailVerifaction';
import ForgetPassword from '../../src/components/profile/component/forgetPassword.jsx';
import PasswordChange from '../../src/components/profile/component/passwordChange.jsx';
import PasswordEmailVerification from '../../src/components/profile/component/passwordEmailVerification';
// import StoreAdd from "../components/store/component/selectStore"
import Layout from '../components/layout/layout.jsx';

export const AUTH_ROUTES = [
  {
    path: '/auth/sign-in',
    component: SignIn,
    group: 'Auth'
  },
  {
    path: '/auth/dashboard',
    component: Layout,
    group: 'Auth'
  },
  {
    path: '/auth/sign-up',
    component: SignUp,
    group: 'Auth'
  },
  {
    path: '/auth/password-change',
    component: PasswordChange,
    group: 'Auth'
  },
  {
    path: '/auth/forget-password',
    component: ForgetPassword,
    group: 'Auth'
  },
  {
    path: '/auth/email_verification',
    component: EmailVerifaction,
    group: 'Auth'
  },
  {
    path: '/auth/password-email-verification',
    component: PasswordEmailVerification,
    group: 'Auth'
  }
];
