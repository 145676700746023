import React, { useState, useEffect } from 'react'
import { Spin } from 'antd';
import { object, string, ref, bool } from 'yup';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Logo from "../../assets/images/newImages/new_log.png";
import { AiFillEyeInvisible, AiFillEye, AiOutlineMenu } from "react-icons/ai";
import { GetPickListLocations } from "../../slices/internalStorage";
import { isEmpty } from 'lodash';
import { MobileWrapper, Header, Content, Footer, Title, InputWrapper, CardDetail, ScanFloating } from './mobile.styled';

const ListLocations = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pickUpLocationList, pickLocationLoading } = useSelector(state => state?.internalStorage || {});

    useEffect(() => {
        dispatch(GetPickListLocations())
    }, []);

    return (
        <MobileWrapper className='fullContent'>
            <div className='mobile-page'>
                <div className='container-fluid px-0'>
                    <Spin tip='Processing...' spinning={pickLocationLoading} >
                    <Content className='fullContent'>
                        <Title>
                            <h2>
                                <span
                                    className="icon-arrow-left cursor-pointer" onClick={() => navigate('/home')}></span>
                                List of Location</h2>
                        </Title>
                        {
                          !isEmpty(pickUpLocationList) ?
                          pickUpLocationList.map((item) => {
                            const { name, units_count, location_type, items_count } = item || {};
                            return (
                              <CardDetail>
                              <div className='card-details-head d-flex justify-content-between align-items-center'>
                                  <h2>{name}</h2>
                                  {/* <span className="icon-arrow-right cursor-pointer"></span> */}
                              </div>
                              <div className='card-details-content'>
                                  {/* <p></p> */}
                                  <ul className='d-flex'>
                                      {/* <li><span className='icon-carton'></span>{location_type}</li> */}
                                      <li><span className='icon-inventory'></span>Products<strong>{items_count}</strong></li>
                                      <li><span className='icon-total'></span>Units <strong>{units_count  }</strong></li>
                                  </ul>
                              </div>
                             </CardDetail>
                            )
                          })
                          : <div className='empty-location'><span>No Location Found</span></div>
                        }
                        {/* <CardDetail>
                            <div className='card-details-head d-flex justify-content-between align-items-center'>
                                <h2>1234567890000</h2>
                                <span
                                    className="icon-arrow-right cursor-pointer"></span>
                            </div>
                            <div className='card-details-content'>
                                <p>Location Name Here</p>
                                <ul className='d-flex'>
                                    <li><span className='icon-carton'></span>Carton</li>
                                    <li><span className='icon-inventory'></span>Products<strong>00</strong></li>
                                    <li><span className='icon-total'></span>Units <strong>00</strong></li>
                                </ul>
                            </div>
                        </CardDetail>
                        <CardDetail>
                            <div className='card-details-head d-flex justify-content-between align-items-center'>
                                <h2>1234567890000</h2>
                                <span
                                    className="icon-arrow-right cursor-pointer"></span>
                            </div>
                            <div className='card-details-content'>
                                <p>Location Name Here</p>
                                <ul className='d-flex'>
                                    <li><span className='icon-carton'></span>Carton</li>
                                    <li><span className='icon-inventory'></span>Products<strong>00</strong></li>
                                    <li><span className='icon-total'></span>Units <strong>00</strong></li>
                                </ul>
                            </div>
                        </CardDetail>
                        <CardDetail>
                            <div className='card-details-head d-flex justify-content-between align-items-center'>
                                <h2>1234567890000</h2>
                                <span
                                    className="icon-arrow-right cursor-pointer"></span>
                            </div>
                            <div className='card-details-content'>
                                <p>Location Name Here</p>
                                <ul className='d-flex'>
                                    <li><span className='icon-carton'></span>Carton</li>
                                    <li><span className='icon-inventory'></span>Products<strong>00</strong></li>
                                    <li><span className='icon-total'></span>Units <strong>00</strong></li>
                                </ul>
                            </div>
                        </CardDetail> */}
                        
                    </Content>
                    </Spin>
                    <ScanFloating className={isEmpty(pickUpLocationList)? 'disabled-span': ''}>
                        <span className='icon-barcode cursor-pointer' onClick={() => navigate('/scan-pick-location')}
                        ></span>
                    </ScanFloating>
                </div>
            </div>
        </MobileWrapper>
    )
}

export default ListLocations;
