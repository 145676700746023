import React, { useState, useEffect, useRef } from "react";
import { notification, Spin, Popconfirm, Tooltip } from 'antd';
import { Modal } from "react-bootstrap";
import XLSX from 'xlsx';
import downloadCsv from 'download-csv';
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { round, debounce, ceil, isElement, isEmpty, compact } from "lodash";
import { WarehouseWrapper } from "./style";
import Select from "../../components/uiComponent/select/index";
import AgGrid from "../../components/ag-grid-table/index";
import { BsPencilSquare, BsTrash, BsPlus, BsSearch, BsClockHistory } from "react-icons/bs";
import Button from "../../components/uiComponent/button/index";
import Drawer from "../../components/uiComponent/drawer/index";
import Input from "../../components/uiComponent/inputs/labelInput/index"
import Pagination from "../../components/pagination/index";
import SearchInput from "../../components/uiComponent/inputs/searchinput/index";
import { generateStorageBarcode, generateZebraLabels, generate30UpLabels } from '../../utils/locationLabels';
import {
  UploadNewLocation,
  listLocationItems,
  listExistingLocations,
  UpdateExistingLocation,
  setField,
  GetWarehouseUsers,
  GetUserWarehouses,
  UploadBulkLocations,
  DeleteWarehouseLocation
} from "../../slices/internalStorage";
import Stepper from '../../components/uiComponent/stepper/index';

const Index = () => {
  const submitFormRef = useRef(null);
  const dispatch = useDispatch();
  const searchRef = useRef(null);
  const navigate = useNavigate();

  const { 
    locations  = [],
    listLocations,
    createLocationLoading,
    updateLocationLoading,
    warehouseUsersLoading,
    warehouseUsers,
    userWarehouses,
    storageCount,
    uploadBulkLoading,
    deleteLocationLoading
  } = useSelector(state => state?.internalStorage || {});

  const { currentUser } = useSelector(state => state?.auth || {});

  const [activeStep, setActiveStep] = useState(0);
  const [upload, setUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const [location, setLocation] = useState({
    name: '',
    selectedId: ''
  })
  const [filters, setFilters] = useState({
    warehouse: null,
    userName: null,
    searchValue: null,
    pagination: {
      pageNumber: 1,
      pageLimit: 25
    }
  });

  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [dataToBeDelete, setDataToBeDelete] = useState({});
  const [editLocation, setEditLocation] = useState(false);
  // const [openSearch, setOpenSearch] = useState(false);

  const { name } = location;
  const { pagination, warehouse, userName, searchValue } = filters || {};
  const { pageNumber, pageLimit } = pagination || {};

  const openEditModule = (event, data) => {
    const { id, name } = data;
    setLocation({
      name: name,
      selectedId: id
    });
    setEditLocation(true);
  }

  useEffect(() => {
    dispatch(listExistingLocations(filters));
    dispatch(GetUserWarehouses())
  }, []);

  const handleDownloadLocationLabel = (event, data) => {
    const { uuid, name, warehouse_user } = data || {};
    generateStorageBarcode({
      warehouseName: warehouse_user,
      locationName: name,
      uuid,
      labelType: 'location'
    })
  }

  const handleDeleteLocation = async (data) => {
    const { id } = dataToBeDelete;
    const response = await dispatch(DeleteWarehouseLocation({ locationId: id }))
    setDeleteModal(false);
    if (isEmpty(response.error)){
      dispatch(listExistingLocations(filters));
      setDeleteModal(false);
    }
  }

  const openDeleteModal = (data) => {
    setDataToBeDelete(data);
    setDeleteModal(true);
  }

  const columnDefinitions = [
    {
      headerName: "Location Name",
      field: 'name',
      width:257,
      sortable: false,
      cellRenderer: ({ value }) =>
        value ? value : "N/A",
    },
    {
      headerName: "Warehouse",
      field: 'warehouse_user',
      minWidth: 200,
      flex:1,
      sortable: false,
      cellRenderer: ({ value }) => (value ? value : "N/A"),
    },
    {
      headerName: "Products",
      field: 'items_count',
      width: 105,
      flex:1,
      sortable: false,
      cellRenderer: ({ value }) => (value ? value : 0),
    },
    {
      headerName: "Units",
      field: 'units_count',
      width: 85,
      sortable: false,
      cellRenderer: ({ value }) => (value ? value : 0),
    },
    // {
    //   headerName: "UUID",
    //   field: 'uuid',
    //   minWidth: 173,
    //   flex:1,
    //   sortable: false,
    //   cellRenderer: ({ value }) => (value ? value : "N/A"),
    // },
    // {
    //   headerName: "Belongs To",
    //   field: 'assigned_user',
    //   minWidth: 173,
    //   flex:1,
    //   sortable: false,
    //   cellRenderer: ({ value }) => (value ? value : "N/A"),
    // },
    {
      headerName: "Created",
      field: 'created_at',
      minWidth: 128,
      flex:1,
      sortable: false,
      cellRenderer: ({ value }) => {
        if (value) {
          return moment(value).format('MMM, DD YYYY hh:mm:ss')
        }
        return 'N/A';
      }
    },
    {
      headerName: "Updated",
      field: 'updated_at',
      minWidth: 105,
      flex:1,
      sortable: false,
      cellRenderer: ({ value }) => {
        if (value) {
          return moment(value).format('MMM, DD YYYY hh:mm:ss')
        }
        return 'N/A';
      }
    },
    {
      headerName: "Actions",
      field: 'email',
      pinned:"right",
      minWidth:150,
      flex:1,
      cellRendererFramework: ({ data }) => {
        const { items_count } = data || {};
        const deleteConfirm = items_count !== 0
        return (
          <div className="d-flex align-items-center table-icon gap-3">
            <Tooltip title={deleteConfirm? 'You cannot delete location with placed items': 'Delete'}>
                <BsTrash className={`${deleteConfirm? '': 'cursor-pointer'} color-danger`} onClick={() => openDeleteModal(data)} />
            </Tooltip>
            <Tooltip title='Download Label'>
              <span className="icon-print cursor-pointer" onClick={(e) => handleDownloadLocationLabel(e, data)}></span>
            </Tooltip>
            <Tooltip title='Edit Name'>
              <BsPencilSquare className="color-primary cursor-pointer"  onClick={(e) => openEditModule(e, data)} />
            </Tooltip>
            <Tooltip title='Go to histories'>
            <BsClockHistory className="color-primary cursor-pointer" onClick={()=> selectLocationAndRedirect(data, 'locations-history')}/>
            </Tooltip>
            <Tooltip title='Go to Items'>
              <span onClick={()=> selectLocationAndRedirect(data, 'location-names')}
                className="icon-arrow-right cursor-pointer"
              ></span>
             
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const selectLocationAndRedirect = (selectedLocation, url) => {
    const { id, name } = selectedLocation || {};
    if (url === 'locations-history') navigate(`/${url}?id=${name}`);
    else navigate(`/${url}?id=${id}`);
  }

  const isFirstColumn = (params) => {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  };
  const defaultColDef = {
    resizable: true,
    sortable: true,
    // headerCheckboxSelection: isFirstColumn,
    // checkboxSelection: isFirstColumn,
  };

  const updateLocationFields = (key, value) => {
    setLocation({
      ...location,
      [key]: value
    });
  }

  const handleUpdateLocation = async() => {
    const { name, selectedId } = location;
    if (name && selectedId) {
      const response = await dispatch(UpdateExistingLocation({ id: selectedId, storage: { name } }))
      if(isEmpty(response.error)) {
        setEditLocation(false);
        dispatch(listExistingLocations(filters));
      }
    }
  }

  const handleUploadNewLocation = async () => {
    const { name } = location;
    if (name) {
      const response = await dispatch(UploadNewLocation({ storage: { name } }));
      if(isEmpty(response.error)) {
        setOpen(false);
        dispatch(listExistingLocations(filters));
      }
    }
  }

  const handlePagination = (number, key) => {
    const value = Number(number);
    let filter = { ...filters }
    if (key === 'pageLimit') filter = { ...filters, pagination: { pageNumber: 1, pageLimit: value }};
    else filter = { ...filters, pagination: { ...pagination, pageNumber: value }};
    setFilters(filter);
    dispatch(listExistingLocations(filter));
  }

  const handleDropdownValue = (data, key) => {
    const { value = null } = data || {};  
    const filter = { ...filters, pagination: { ...pagination, pageNumber: 1 }, [key]: value };
    setFilters(filter)
    dispatch(listExistingLocations(filter));
  }

  const handleSearchValue = debounce((event) => {
    const { value } = event?.target || null;
    const filter = { ...filters, pagination: { ...pagination, pageNumber: 1 }, searchValue: value };
    setFilters(filter)
    dispatch(listExistingLocations(filter));
  }, 1000)

  const warehouseOptions = warehouseUsers?.map((warehouse) => {
    const { id, warehouse_name } = warehouse || {};
    return {
      value: id,
      label: warehouse_name
    };
  });

  const usersOptions = userWarehouses?.map((user) => {
    const { id, full_name } = user || {};
    return {
      value: id,
      label: full_name
    };
  });

  const handleUploadfile = async(e, isZebraLabel) => {
    const file = e.target.files[0];
    setActiveStep(1);
    e.preventDefault();
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    let fileJson = [];
    reader.onload =  (async({ target }) => {
      const wb = XLSX.read(target.result, { type: 'binary', raw: true });
      wb.SheetNames.forEach((sheetName) => {
        fileJson = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], { header: 2, defval: "" })
      });
    if (!isEmpty(fileJson)) {
      let locationNames = fileJson.map((item) => item.LocationName);
      locationNames = compact(locationNames);
      if(!isEmpty(locationNames)) {
        const response = await dispatch(UploadBulkLocations({ locations: locationNames }));
        if (isEmpty(response.error)) {
          const locations = response?.payload?.data?.locations || [];
          isZebraLabel ? generateZebraLabels({ locations }) : generate30UpLabels({ locations });
          await dispatch(listExistingLocations(filters));
          setActiveStep(2);
        } else setActiveStep(0);
      } else {
        notification.error({
          message: 'File doesnt contain LocationName column. Please use sample file.'
        })
        setActiveStep(0);
      }
    } else {
      notification.error({
        message: 'File contains no data.'
      })
      setActiveStep(0);
    }
    });
  };

  const downloadSampleTemplate = () => {
    const dummyData = [{
      locationName: ''
    }];
    const headers = { locationName: 'LocationName'}
    downloadCsv(dummyData, headers, 'location_sample.csv');
  }

  const handleOpenBulkDrawer = () => {
    setUpload(true);
    setActiveStep(0);
  }

  const refreshGridClick = () => {
    dispatch(listExistingLocations(filters));
  }

  const handleLocationAddDrawer = async() => {
    setLocation({ name: '' });
    setOpen(true);
  }

  const addDisable = isEmpty(name) || createLocationLoading;
  const updateDisable = isEmpty(name) || updateLocationLoading;

  return (
    <WarehouseWrapper>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-3 align-items-center">
          {/* <span
            className="icon-arrow-left cursor-pointer"
            onClick={() => navigate("/fbm")}
          ></span> */}
          <h2 className="color-secondary mb-0">Warehouse Location</h2>
        </div>
        <div className="d-flex align-items-center gap-4">
          <div className="d-flex gap-3">
            <Select
              isClearable={true}
              className="w-188"
              placeholder="Select User"
              defaultValue={userName}
              onChange={(event) => handleDropdownValue(event, 'userName')}
              options={usersOptions}
            />
            {/* {
              true
              ? 
                <Select
                  isClearable={true}
                  className="w-188"
                  placeholder="Select User"
                  defaultValue={userName}
                  onChange={(event) => handleDropdownValue(event, 'userName')}
                  options={usersOptions}
                />
              : <Select
                  isClearable={true}
                  className="w-188"
                  placeholder="Select Warehouse"
                  defaultValue={warehouse}
                  onChange={(event) => handleDropdownValue(event, 'warehouse')}
                  options={warehouseOptions}
                />
              } */}
          </div>
          <div className="d-flex gap-3">
            <Button
              text="Upload Bulk Locations"
              className="outlined mb-0"
              startIcon={<span className="icon-upload-bulk pr-8 "></span>}
              onClick={handleOpenBulkDrawer}
            />
            <Button
              text="Add Location"
              className="outlined mb-0"
              startIcon={<span className="icon-add pr-8"></span>}
              onClick={handleLocationAddDrawer}
            />
            <Tooltip title='Items which was received but never assigned a location'>
              <div>
                <Button
                  text="Unassigned Items"
                  className="outlined mb-0"
                  startIcon={<span className="pr-8"></span>}
                  onClick={() => navigate(`/location-names?id=unassigned`)}
                />
              </div>
            </Tooltip>
            {/* <Button
              text="Go To histories"
              className="outlined mb-0"
              startIcon={<span className="pr-8"></span>}
              onClick={() => navigate('/locations-history')}
            /> */}
            <SearchInput defaultValue={searchValue} placeholder="Search for Location Name" onChange={handleSearchValue}/>
          </div>
        </div>
      </div>
      <div>
        <Spin tip="Processing..." spinning={listLocations}>
          <AgGrid
            user_referrals={locations}
            defaultColDef={defaultColDef}
            columnDefinitions={columnDefinitions}
            rowHeight={40}
            className="warehouse-location"
            height="164px"
          />
        </Spin>
      </div>
      <Pagination
        total={storageCount}
        totalPages={ceil(storageCount / pageLimit + 1)}
        pageNumber={pageNumber}
        pageLimit={pageLimit}
        handleLimitChange={(e) => handlePagination(e, 'pageLimit')}
        handlePageNumberChange={(e) => handlePagination(e, 'pageNumber')}
        handlePageChangeLeft={(e) => handlePagination(e, 'pageNumber')}
        handlePageChangeRight={(e) => handlePagination(e, 'pageNumber')}
        refreshGridClick={refreshGridClick}
      />
      <Drawer
        show={open}
        size="26%"
      >
        <div className="drawer-padding">
          <div className="d-flex align-items-center grid-column-gap-10">
           <span
            className="icon-arrow-left cursor-pointer"
            onClick={() =>setOpen(false)}
          ></span>
          <h2 className="mb-0">Add Location</h2>

          </div>
          <div className="mt-3">
          <Input label="Location Name" value={name} className="h-32" placeholder="Location Name" onChange={(e) => updateLocationFields('name', e.target.value)} />
          </div>
          <div className="mt-3">
          {/* <Input label="Location Type" className="h-32" placeholder="Location Type" onChange={(e) => updateLocationFields('type', e.target.value)} /> */}
          </div>
          <div className="d-flex  justify-content-end mt-5">
          <Button disabled= {addDisable} text={createLocationLoading ? "Adding..." : "Add Location"} onClick={handleUploadNewLocation} />
          </div>
        </div>
      </Drawer>
      <Drawer
        show={editLocation}
        size="26%"
      >
        <div className="drawer-padding">
          <div className="d-flex align-items-center grid-column-gap-10">
           <span
            className="icon-arrow-left cursor-pointer"
            onClick={() =>setEditLocation(false)}
          ></span>
          <h2 className="mb-0">Update Location</h2>

          </div>
          <div className="mt-3">
          <Input required label="Location Name" value={name} className="h-32" placeholder="Location Name" onChange={(e) => updateLocationFields('name', e.target.value)}/>
          {/* <Input label="Location Type" value={type} className="h-32" placeholder="Location Type" onChange={(e) => updateLocationFields('type', e.target.value)}/>   */}
          </div>
          <div className="d-flex  justify-content-end mt-5">
          <Button disabled={updateDisable} text={updateLocationLoading? "Updating....": "Update"} onClick={handleUpdateLocation} />
          </div>
        </div>
      </Drawer>
      {/* Upload Drawer */}
      <Drawer
        show={upload}
        size="446px"
      >
        <div className="drawer-padding drawer-overflow">
          <div className="d-flex align-items-center grid-column-gap-10">
           <span
            className="icon-arrow-left cursor-pointer"
            onClick={() =>setUpload(false)}
          ></span>
          <h2 className="mb-0">Upload File</h2>

          </div>
          <div className="upload-description">
          <p className="">(To add multiple locations, you can upload a CSV file with location names. <a style={{ color: '#1565d8' }} onClick={downloadSampleTemplate}>Download Sample File</a></p>
          </div>
          <Stepper 
            onChange={(e, isZebraLabel) => handleUploadfile(e, isZebraLabel)} 
            activeStep={activeStep}
            onClick={() => setUpload(false)}
            uploadBulkLoading={uploadBulkLoading}
          />     
        </div>
      </Drawer>
      <Modal size="sm" show={deleteModal} centered={true}>
        <div className="text-center px-5 py-4">
          <h1><BsTrash className="color-danger"/></h1>
          <h3 className="mt-4">
            Are you sure you want to delete Location ?
          </h3>
        </div>
        <div className="d-flex px-3 justify-content-between">
          <Button
            className="outlined"
            text="Cancel"
            onClick={() => setDeleteModal(false)}
          />
          <Button className="outlined" text="yes" onClick={handleDeleteLocation} />
        </div>
      </Modal>
    </WarehouseWrapper>
  );
};

export default Index;