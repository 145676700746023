import React from "react";
import CongratulationImage from "../../../assets/images/congratulation.svg";
import { CongratulationWrapper } from "../style";

const Congratulation = () => {
  return (
    <CongratulationWrapper>
      <img src={CongratulationImage} />
      <p>ORDER CONFIRMED!</p>
    </CongratulationWrapper>
  );
};

export default Congratulation;
