import Styled from 'styled-components';

const AuthWrapper = Styled.div`
  background-color: ${({ theme }) => theme['white-color']};
  height:100vh;
  .conatiner{
    max-width:1800px;
    margin: 0 auto;
    height:100vh;
    overFlow:auto;
  }
  span{
    font-size: ${({ theme }) => theme['font-size-base']};
      color: ${({ theme }) => theme['lynch-color']};
  }
  .password-input{
   position: relative;
   svg{
     position: absolute;
     top: 35px;
    right: 11px;
    color: ${({ theme }) => theme['primary-color']};
   }
  }
  .bg-albaster{
    background-color:${({ theme }) => theme['alabaster-color']}80 ;
    height:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    &.py-40{
      padding: 0px 40px;
      padding-top:61px;
    }
  }
  .forget-password{
    font-size: ${({ theme }) => theme['font-size-base']};
      color: ${({ theme }) => theme['primary-color']};
      text-decoration:underline;
  }
  .handle-recaptcha{
    transform:scale(0.88);
    -webkit-transform:scale(1.18);
    transform-origin:0 0;
    -webkit-transform-origin:0 0; 
   } 
  `;
const InfoVideoWrapper = Styled.div`
  padding-left:40px;
  padding-top:97px;
  h2{
      color: ${({ theme }) => theme['secondary-color']};
  }
  .onBorading-innerVideo{
    height: 500px;
    width: 100%;
}
  }

  `;


export { AuthWrapper, InfoVideoWrapper };
