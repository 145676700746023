import React from "react";
import { useNavigate } from 'react-router-dom'
import { StoreWrapper } from "../style";
import Header from "../../header/header";
import Button from "../../uiComponent/button/index"

const Index = () => {
  const navigate = useNavigate();
  return (
    <StoreWrapper>
      <Header login={true} />
      <div className="change-background">
        <div className="bg-white p-4 automated-prep">
          <h1 className="d-flex align-items-center mb-0">
            <span className="icon-arrow-left pe-3 cursor-pointer"></span>Automated
            Prep and Pack services
          </h1>
          <div className="d-flex justify-content-center align-items-center flex-column iframe">
            <p className="paragrapgh-space mb-0">
              Please watch the video and follow the steps to begin utilizing our
              Automated Prep and Pack services!
            </p>
            <div className="ratio ratio-21x9 iframe-width">
              <iframe
                src="https://fbasupport-videos.s3.us-west-2.amazonaws.com/prep_settings.mp4"
                title="Prep Setting for Sales Support"
                allowfullscreen
              ></iframe>
            </div>
            <Button text="Automation Done" className="primary add-spacing" onClick={() => navigate('/onboarding/amazon-success') }/>
          </div>
        </div>
      </div>
    </StoreWrapper>
  );
};
export default Index;
