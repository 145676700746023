import React, { useState, useEffect } from 'react'
import { object, string, ref, bool } from 'yup';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Logo from "../../assets/images/newImages/new_log.png";
import ProdImg from "../../assets/images/newImages/prod-img.svg";
import { Form, Row, Col, Container } from "react-bootstrap"
import Button from '../uiComponent/button/index'
import { BiLeftArrowAlt } from "react-icons/bi";
import { AiFillEyeInvisible, AiFillEye, AiOutlineMenu } from "react-icons/ai";
import { setField } from "../../slices/internalStorage";
import { isEmpty } from 'lodash';
import { MobileWrapper, Header, Content, Footer, Title, InputWrapper, CardDetail, ProductDetail, ScanFloating } from './mobile.styled';

const LocationDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { locationItemList } = useSelector(state => state?.internalStorage || {});

  const { uuid, items_count, units_count, name, location_type, staging_items } = locationItemList || {};

  const handleAddProductToLocation = () => {
    dispatch(setField({ field: 'scannedItem', value: {}}));
    navigate('/scan-add-products');
  }

  useEffect(() => {
    if(isEmpty(locationItemList)) navigate('/scan-location');
  }, [])


  return (
    <MobileWrapper className=''>
      <div className='mobile-page'>
        <div className='container-fluid px-0'>
          <Content className=''>
            <Title>
                <h2>
                    <span
                        className="icon-arrow-left cursor-pointer" onClick = {() => navigate('/scan-location')}></span>
                    Location Details</h2>
            </Title>
            <div className='content-inner'>
              <CardDetail className='mb-card-single'>
                  <div className='card-details-head d-flex justify-content-between align-items-center'>
                      <h2>{name}</h2>
                      <span
                          className=""></span>
                  </div>
                  <div className='card-details-content'>
                      {/* <p>{name}</p> */}
                      <ul className='d-flex'>
                          {/* <li><span className='icon-carton'></span>{location_type}</li> */}
                          <li><span className='icon-inventory'></span>Products<strong>{items_count}</strong></li>
                          <li><span className='icon-total'></span>Units <strong>{units_count}</strong></li>
                      </ul>
                  </div>
              </CardDetail>
              { !isEmpty(staging_items)
                ? 
                staging_items.map((item ) => {
                  const { image_url, title, identifier, quantity, stored_quantity } = item;
                  return (
                    <ProductDetail>
                    <div class="d-flex align-items-center">
                        <div>
                            <img width="42" height="42" src={image_url} alt="no-product" />
                        </div>
                        <div>
                            <span class="fs-14 color-secondary">{title}</span>
                        </div>
                    </div>
                    <ul class="d-flex">
                        <li class="">
                            <span class="fs-12 d-block">Identifier</span>
                            <span class="color-primary fs-14 fw-bold">{identifier}</span>
                        </li>
                        <li class="">
                            <span class="fs-12 recent-grey d-block">Quantity</span>
                            <span class="fs-14">{stored_quantity}</span>
                        </li>
                    </ul>
                </ProductDetail>
                  )
                })   
                : <span className='empty-product'>No Product Found</span>
             }
              
            </div>
          </Content>
          <Footer>
              <Row>
                  <Col xs={6}>
                  <Button className="outlined no-border" text="Cancel" onClick={() => navigate('/scan-location')}/>
                  </Col>
                  <Col xs={6}>
                  <Button className="outlined" text="Complete" onClick={() => navigate('/home')}/>
                  </Col>
              </Row>
                {/* <Footer>
  <Button className="outlined btn-block" text="Go to Warehouse" />
</Footer> */}
          </Footer>
          <ScanFloating>
            <span className='icon-barcode cursor-pointer' onClick={handleAddProductToLocation}></span>
          </ScanFloating>
        </div>
      </div>
    </MobileWrapper>
  )
}

export default LocationDetails;
