import React from 'react'
import { InputWrapper } from '../style'
import { Form, ProgressBar } from "react-bootstrap"

const Index = (props) => {
  const {
    label,
    type,
    placeholder,
    inputStatus,
    strength,
    onChange,
    controlId,
    required,
    value,
    name,
    width,
    onBlur,
    className,
    autoComplete,
    disabled,
    warehouseSelect,
    as,
    rows,
    min,
    icon,
    onWarehouseSelectClick,
    marginBottom,
    defaultValue,
    inputRef,
    background,
    borderless,
    style
  } = props;
  return (
    <InputWrapper marginBottom={marginBottom} width={width} background={background} borderless={borderless}>
      <Form.Group controlId={controlId}>
        <div className="d-flex justify-content-between">
          {label && <Form.Label>{label}</Form.Label>}
          {strength ? (
            <div className="d-flex align-items-center grid-column-gap-10 progress-status">
              <label>Strength</label> <ProgressBar now={60} />
            </div>
          ) : null}
          {warehouseSelect && (
            <span
              className="d-flex align-items-center gap-2 cursor-pointer fs-12"
              onClick={() => onWarehouseSelectClick(true)}
            >
              <span className="icon-location cursor-pointer"></span>
              <span className="fs-12 color-primary">Change Address</span>
            </span>
          )}
        </div>
        <span className={className}>
          <Form.Control
            autoComplete={autoComplete}
            type={type}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            required={required}
            name={name}
            onBlur={onBlur}
            disabled={disabled}
            min={min}
            ref={inputRef}
            style={style}
          />
          {icon && <span className={icon}></span>}
        </span>
        {inputStatus && <Form.Text className="text-danger danger-border">{inputStatus}</Form.Text>}
      </Form.Group>
    </InputWrapper>
  );
}

export default Index
