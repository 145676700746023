import React, { useEffect, useState, useCallback } from "react";
import { Button, Form, Input, notification, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { STATES } from "../../../constants/vendors";
import { countries } from "../../../constants/Countries";

import {
  GetVendorsAddresses,
  UpdateCompanyAddress,
  SaveVendorAddress
} from "../../../slices/fba-shipment";

import { VenderShipmentAddressWrapper } from "../style";

const Option = Select.Option;
const VenderShipmentAddress = ({
  setDraftPo,
  draft_po,
  setDisableNext
}) => {
  const dispatch = useDispatch();
  const { vendorAddresses } = useSelector((store) => store.fbaShipment);
  const [editCard, setEditCard] = useState();
  const [address, setAddress] = useState({ country: 'US' });

  const statesOptions = STATES.map(({ value, name }, index) => {
    return (
      <Option value={name} key={index} label={name}>
        {name}
      </Option>
    );
  });

  const countryList = countries?.map(({ name, code }, index) => {
    return (
      <Option key={code} value={code}>
        {name}
      </Option>
    );
  });

  useEffect(() => {
    if (draft_po?.vendor_id) dispatch(GetVendorsAddresses(draft_po?.vendor_id));
  }, []);


  useEffect(() => {
    if (editCard && editCard !== 'add new address') {
      setAddress(vendorAddresses?.find(x => x.id === editCard));
    }
  }, [editCard]);

  useEffect(() => {
    if (vendorAddresses?.length && !draft_po?.ship_from_address_id) {
      const defaultAddress = vendorAddresses?.find(a => a.default_address);
      if (defaultAddress && !draft_po?.ship_from_address_id) {
        setDraftPo({
          ...draft_po,
          ship_from_address_id: defaultAddress?.id
        });
      }
    }

    if (!vendorAddresses?.length) setEditCard('add new address');
    else setEditCard('');
  }, [vendorAddresses]);

  const selectedAddress = ({ address, setAddress }) => {

    return address?.country === 'US' ? <Select
      placeholder='Select State'
      showSearch
      maxTagCount={1}
      maxTagTextLength={6}
      allowClear
      style={{ width: '100%' }}
      value={address?.state_or_province}
      onChange={(value) => {
        setAddress({
          ...address,
          state_or_province: value
        })
      }}
    >
      {statesOptions}
    </Select> : <Input
      value={address?.state_or_province}
      onChange={(e) => {
        if (e.target.value && !isNaN(e.target.value)) return;
        setAddress({
          ...address,
          state_or_province: e.target.value
        })
      }}
    />
  };

  const AddressForm = ({
    addressData
  }) => {
    const [address, setAddress] = useState(addressData);
    return <div className="input-form-wrapper">
      <div className="input-overlay-wrapper">
        <Form.Item name="name" label="Name">
          <Input
            value={address?.title}
            onChange={(e) => {
              setAddress({
                ...address,
                title: e.target.value?.trimStart()
              })
            }}
          />
        </Form.Item>
      </div>
      <Form.Item name="name" label="Street">
        <Input
          value={address?.address_line_1}
          onChange={(e) => {
            setAddress({
              ...address,
              address_line_1: e.target.value?.trimStart()
            })
          }}
        />
      </Form.Item>
      <Form.Item name="name" label="Street 2">
        <Input
          value={address?.address_line_2}
          onChange={(e) => {
            if (e.target.value && !isNaN(e.target.value)) return;
            setAddress({
              ...address,
              address_line_2: e.target.value?.trimStart()
            })
          }}
        />
      </Form.Item>
      <div className="input-overlay-wrapper">
        <Form.Item name="name" label="City">
          <Input
            value={address?.city}
            onChange={(e) => {
              if (e.target.value && !isNaN(e.target.value)) return;
              setAddress({
                ...address,
                city: e.target.value
              })
            }}
          />
        </Form.Item>
        <Form.Item name="name" label="State">
          {selectedAddress({ address, setAddress })}
        </Form.Item>
      </div>
      <div className="input-overlay-wrapper">
        <Form.Item name="name" label="Country">
          <Select
            showSearch
            maxTagCount={1}
            maxTagTextLength={6}
            allowClear
            style={{ width: '100%' }}
            value={address?.country}
            filterOption={(input, option) => {
              return option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
            }}
            placeholder='Select Country'
            onChange={(value) => {
              setAddress({
                ...address,
                country: value,
                state_or_province: ''
              });
            }}
          >
            {countryList}
          </Select>
        </Form.Item>
        <Form.Item name="name" label="Zip code">
          <Input
            value={address?.zipcode}
            onChange={(e) => {
              if (isNaN(e.target.value) || e.target.value < 0) return;
              setAddress({
                ...address,
                zipcode: e.target.value
              })
            }}
          />
        </Form.Item>
      </div>
      <div className="inner-buttons-wrapper">
        <Button
          className="button"
          type="primary"
          onClick={() => {
            setDisableNext(false);
            setAddress({});
            setEditCard('');
          }}>
          Cancel
        </Button>
        <Button
          className="button"
          type="primary"
          disabled={
            !address?.title || !address?.zipcode || !address?.city || !address?.country || !address?.state_or_province ||
            !address?.address_line_1}
          onClick={() => {
            if (String(address.zipcode)?.length < 4 || String(address.zipcode)?.length > 6) {
              notification.info({
                message: "Address Validation",
                description: 'Postal length must in between 4 to 6.',
                top: 65
              });
              return;
            }
            if (editCard === 'add new address') {
              dispatch(SaveVendorAddress({ address, id: draft_po?.vendor_id })).then(({ payload }) => {
                if (payload?.success) {
                  dispatch(GetVendorsAddresses(draft_po?.vendor_id));
                  setEditCard("");
                  setDisableNext(false);
                }
              });
            } else {
              dispatch(UpdateCompanyAddress({ id: editCard, address })).then(({ payload }) => {
                if (payload?.success) {
                  dispatch(GetVendorsAddresses(draft_po?.vendor_id));
                  setEditCard("");
                  setDisableNext(false);
                }
              });
            }
          }}>
          Confirm
        </Button>
      </div>
    </div>
  }

  return (
    <VenderShipmentAddressWrapper>
      {vendorAddresses && (
        <>
          <Button onClick={() => {
            setEditCard('add new address');
            setDisableNext(true);
            setAddress({
              country: 'US'
            });
          }} className="add-new-address-button">Add New Address</Button>
          <div className="fix-height-warehouse-address ">
            <div className="card-overlay-wrapper">
              {
                vendorAddresses?.map(({
                  id,
                  address_line_1,
                  city,
                  country,
                  zipcode,
                  title
                }) => {
                  return <div
                    onClick={() => setDraftPo({
                      ...draft_po,
                      ship_from_address_id: id
                    })}
                    className={`card-item ${id === draft_po?.ship_from_address_id ? "active" : null}`}
                  >
                    <div className="text-wrapper">
                      <p>{title}</p>
                      <p>{address_line_1}</p>
                      <p>{country} {city}, {zipcode}</p>
                    </div>
                    <Button onClick={() => {
                      setEditCard(id);
                      setDisableNext(true);
                    }}>Edit</Button>
                  </div>
                })
              }
            </div>
          </div>
        </>
      )}
      {editCard && (
        <AddressForm addressData={address} />
      )}
    </VenderShipmentAddressWrapper>
  );
};

export default VenderShipmentAddress;
