import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  fba_removal_shipments: [],
  users: [],
  total_records: 0,
  filters: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  }
};

const masterRemovalOrders = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GET_USERS_FOR_MASTER_REMOVAL_ORDERS_REQUEST': {
      return {
        ...state
      };
    }
    case 'GET_USERS_FOR_MASTER_REMOVAL_ORDERS_SUCCESS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_USERS_FOR_MASTER_REMOVAL_ORDERS_FAILED': {
      return {
        ...state
      };
    }
    case 'PLACE_ORDER_FOR_FBA_REMOVAL_SHIPMENT_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'PLACE_ORDER_FOR_FBA_REMOVAL_SHIPMENT_SUCCESS': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'PLACE_ORDER_FOR_FBA_REMOVAL_SHIPMENT_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_MASTER_REMOVAL_ORDERS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_MASTER_REMOVAL_ORDERS_SUCCESS': {
      const { total, items } = action.payload
      return {
        ...state,
        total_records: total,
        fba_removal_shipments: items,
        fetching: false
      };
    }
    case 'GET_MASTER_REMOVAL_ORDERS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_FILTERS_MASTER_REMOVAL_ORDERS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_PAGINATION_MASTER_REMOVAL_ORDERS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    default:
      return state;
  }
};

export default masterRemovalOrders;
