import React, { useState, useMemo } from 'react'
import { Formik } from 'formik';
import { object, string, ref, bool } from 'yup';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../header/header'
import { AuthWrapper, PhoneNumberWrapper } from '../style'
import { Form, Row, Col, Container } from "react-bootstrap"
import InfoVideo from './infoVideo'
import LabelInput from '../../uiComponent/inputs/labelInput'
import PhoneInput from '../../uiComponent/inputs/phoneNumberInput'
import CheckBox from '../../uiComponent/checkBox'
import Button from '../../uiComponent/button/index'
import { BiLeftArrowAlt } from "react-icons/bi";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { SignUp as UserSignUp, setField } from "../../../slices/auth";
import { isEmpty } from 'lodash';

const SignUp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signUpLoading } = useSelector(state => state?.auth || {});

  const [value, setValue] = useState()
  const [phoneError, setPhoneError] = useState(false);
  const [passwordShow, setPasswordShow] = useState(true);

  const signUp = async (data) => {
    if(isEmpty(value)) return setPhoneError(true);

    const { firstName, lastName, email, companyName, password, confirmPassword,  phoneNumber } = data;
    
    const query = new URLSearchParams(window.location.search);
    const referral_code = query.get('code');

    const body = {
      user: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phoneNumber,
        company: {
          name: companyName
        },
        password,
        password_confirmation: confirmPassword,
        referral_code
      }
    }
    
    const response = await dispatch(UserSignUp(body));
    if (!response.error){
      dispatch(setField({
        module: 'user',
        field: 'email',
        value: email
      }));
      navigate("/auth/email_verification");
    }
    setPhoneError(false);
  }

  const handleSetPassword = () => {
    setPasswordShow(!passwordShow)
  }

  const schema = object().shape({
    firstName: string().required('Enter your first name!'),
    lastName: string().required('Enter your last name!'),
    email: string().email('Invalid Email').required('Enter your email!'),
    companyName: string().required('Enter company name!'),
    password: string()
    .required('Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{12,})/,
      "Must Contain 12 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    confirmPassword: string()
    .required()
    .oneOf([ref("password"), null], "Passwords must match"),
    terms: bool().required().oneOf([true], 'Terms must be accepted'),
  });

  return (
    <AuthWrapper>
      <Formik
      validationSchema={schema}
      onSubmit={(values) => signUp(values)}
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        password: '',
        confirmPassword: '',
        phoneNumber: '',
        terms: false
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched
      }) => (
      <Form onSubmit={handleSubmit}>
      <Header login={false} />
      <div className="conatiner">
        <Row className="m-0">
          <Col lg={8} sm={12}>
            <InfoVideo />
          </Col>
          <Col lg={4} sm={12}>
            <div className="bg-albaster py-40">
              <div>
                <div className="mb-2">
                  <BiLeftArrowAlt className="cursor-pointer color-primary" onClick={() => navigate(-1)} />
                  <h2 className='mt-2'>Sign up to unlock your business potential!</h2>
                  {/* <span>Sign up to get instant access</span> */}
                </div>
                <div className="d-flex grid-column-gap-10 mt-4">
                  <LabelInput
                    name='firstName'
                    inputStatus={touched.firstName && errors.firstName}
                    label="First Name"
                    placeholder="Your First Name" 
                    value={values.firstName} 
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <LabelInput 
                    name='lastName'
                    inputStatus={touched.lastName && errors.lastName}
                    label="Last Name" 
                    placeholder="Your Last Name" 
                    value = {values.lastName} 
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <LabelInput 
                  inputStatus={touched.companyName && errors.companyName}
                  name='companyName'
                  label="Company" 
                  placeholder="Company" 
                  value = {values.companyName} 
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <PhoneInput
                  label="Mobile Number"
                  defaultCountry="US"
                  placeholder="Enter phone number"
                  country={value}
                  onChange = {setValue}
                />
                {phoneError? <span style={{color: 'red'}}>Enter your phone number</span>: ''}
                <div className="password-input">
                  <LabelInput strength="true" label="Password" type={passwordShow ? "password" : "text"} placeholder="Password"
                    value={values.password}
                    name='password'
                    autoComplete="on"
                    onChange={handleChange}
                    inputStatus={touched.password && errors.password}
                    onBlur={handleBlur}
                  />
                  {passwordShow ? <AiFillEyeInvisible onClick={handleSetPassword} /> : <AiFillEye onClick={handleSetPassword} />}
                </div>
                <div className="password-input">
                  <LabelInput
                    autoComplete="on"
                    name='confirmPassword'
                    label="Re-type Password" 
                    type={passwordShow ? "password" : "text"} 
                    placeholder="Re-type Password" 
                    value={values.confirmPassword} 
                    onChange={handleChange}
                    inputStatus={touched.confirmPassword && errors.confirmPassword}
                    onBlur={handleBlur}
                  />
                  {passwordShow ? <AiFillEyeInvisible onClick={handleSetPassword} /> : <AiFillEye onClick={handleSetPassword} />}
                </div>
                <LabelInput
                  name='email'
                  inputStatus={touched.email && errors.email}
                  label="Email"
                  placeholder="Email@example.com"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <CheckBox
                  name='terms'
                  value = {values.terms}
                  inputStatus={touched.terms && errors.terms}
                  type="checkbox" 
                  label={<>I agree to <a>terms & conditions </a></>} 
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <Button 
                  type='submit'
                  disabled={signUpLoading}
                  text={signUpLoading? "Sending Verification Email": "Register Account"}
                  className="primary w-100" />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      </Form>
      )}
    </Formik>
    </AuthWrapper >
  )
}

export default SignUp
