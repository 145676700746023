import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  fetchingFeedJobs: false,
  savingFeedJob: false,
  uploadedOrReorders: 'Only Uploaded',
  smallOrVarietyProducts: 'All',
  reOrderSuggestionDays: 30,
  purchaseOrders: [],
  processedFeedItems: [],
  vendorFeed: {},
  total_records: 0,
  validatedOrders: [],
  selectedItems: [],
  fetchingForDrawer: false,
  companyAddresses: [],
  vendorAddresses: [],
  vendorId: null,
  vendorUserEmail: '',
  pendingPoIndex: {},
  updatedPurchaseOrder: {},
  vendorName: '',
  company: {},
  vendors: [],
  filters: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 100
  },
  vendor: null,
  po_name: '',
  invoice_number: '',
  storagePurchaseOrders: [],
  ipiStorageInformation: {
    total_standard_storage: '',
    total_oversize_storage: '',
    total_clothing_storage: '',
    total_footwear_storage: '',
    used_standard_storage: '',
    used_oversize_storage: '',
    used_clothing_storage: '',
    used_footwear_storage: '',
    last_updated_at: ''
  },
  fetchingIpiStorageInformation: false
};

const purchaseOrderDetails = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GET_PROCESSED_FEED_ITEMS_SUCCESS': {
      return {
        ...state,
        fetchingFeedJobs: false,
        processedFeedItems: action.payload
      };
    }
    case 'CREATE_FEED_JOBS_REQUEST': {
      return {
        ...state,
        savingFeedJob: true
      };
    }
    case 'CREATE_FEED_JOBS_SUCCESS': {
      return {
        ...state,
        savingFeedJob: false,
        processedFeedItems: [],
        selectedItems: []
      };
    }
    case 'CREATE_FEED_JOBS_FAILED': {
      return {
        ...state,
        savingFeedJob: false
      };
    }
    case 'GET_PROCESSED_FEED_ITEMS_REQUEST':
    case 'DELETE_FEED_JOBS_REQUEST':
    case 'GET_FEED_JOBS_REQUEST': {
      return {
        ...state,
        fetchingFeedJobs: true
      };
    }
    case 'DELETE_FEED_JOBS_SUCCESS': {
      return {
        ...state,
        fetchingFeedJobs: true,
        processedFeedItems: [],
        vendorFeed: {}
      };
    }
    case 'GET_PROCESSED_FEED_ITEMS_FAILED':
    case 'DELETE_FEED_JOBS_FAILED':
    case 'GET_FEED_JOBS_FAILED': {
      return {
        ...state,
        fetchingFeedJobs: false
      };
    }
    case 'GET_FEED_JOBS_SUCCESS': {
      const { vendor_feed: vendorFeed } = action.payload;
      return {
        ...state,
        fetchingFeedJobs: false,
        vendorFeed
      };
    }
    case 'GET_COMPANY_ADDRESSES_SUCCESS': {
      return {
        ...state,
        fetching: false,
        companyAddresses: action.payload ? [...action.payload] : []
      };
    }
    case 'CLEAR_SELECTED_ITEMS_PO_DETAILS_PAGE': {
      return {
        ...state,
        selectedItems: []
      };
    }
    case 'SET_SELECTED_ITEMS_PO_DETAILS_PAGE': {
      return {
        ...state,
        selectedItems: action.payload
      };
    }
    case 'CLEAR_UPDATED_ROW_FOR_PURCHASE_ORDER': {
      return {
        ...state,
        updatedPurchaseOrder: {}
      };
    }
    case 'SET_SMALL_OR_VARIETY':
    case 'SET_UPLOADED_OR_REORDERS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_REORDER_SUGGESTION_DAYS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_VENDOR_ID':
    case 'SET_PO_NAME':
    case 'SET_INVOICE_NAME':
    case 'SET_STORAGE_PURCHASE_ORDERS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_FILTERS_PURCHASE_ORDER_DETAILS':
    case 'SET_SORT_OPTIONS_PURCHASE_ORDER_DETAILS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_BASE_64_IMAGE_REQUEST':
    case 'VALIDATE_SELECTED_ITEMS_FOR_ORDER_CONFIRMATION_REQUEST': {
      return {
        ...state,
        fetchingForDrawer: true
      };
    }
    case 'SEND_UNGATED_ORDER_ITEMS_REQUEST': {
      return {
        ...state,
        fetchingForDrawer: true
      };
    }
    case 'GET_BASE_64_IMAGE_SUCCESS':
    case 'GET_BASE_64_IMAGE_FAILED': {
      return {
        ...state,
        fetchingForDrawer: false
      };
    }
    case 'VALIDATE_SELECTED_ITEMS_FOR_ORDER_CONFIRMATION_SUCCESS': {
      const { validatedOrders } = action.payload;
      return {
        ...state,
        validatedOrders,
        fetchingForDrawer: false
      };
    }
    case 'VALIDATE_SELECTED_ITEMS_FOR_ORDER_CONFIRMATION_FAILED': {
      return {
        ...state,
        fetchingForDrawer: false
      };
    }
    case 'SEND_UNGATED_ORDER_ITEMS_FAILED': {
      return {
        ...state,
        fetchingForDrawer: false
      };
    }
    case 'GET_EXPORT_PURCHASE_ORDER_DETAILS_REQUEST':
    case 'PURCHASE_ORDER_CONFRIM_EMAIL_REQUEST':
    case 'UPDATE_PURCHASE_ORDER_ITEM_REQUEST':
    case 'CONFIRM_PURCHASE_ORDER_ITEMS_REQUEST':
    case 'DELETE_PURCHASE_ORDER_ITEMS_REQUEST':
    case 'REFRESH_PURCHASE_ORDER_ITEMS_REQUEST':
    case 'SAVE_VENDOR_ADDRESSES_REQUEST':
    case 'GET_VENDOR_ADDRESSES_REQUEST':
    case 'SAVE_COMPANY_ADDRESSES_REQUEST':
    case 'GET_COMPANY_ADDRESSES_REQUEST':
    case 'GET_PURCHASE_ORDER_DETAILS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_IPI_STORAGE_INFORMATION_REQUEST':
    case 'UPDATE_IPI_LIMITS_FOR_STORAGE_REQUEST': {
      return {
        ...state,
        fetchingIpiStorageInformation: true
      };
    }
    case 'GET_IPI_STORAGE_INFORMATION_SUCCESS':
    case 'UPDATE_IPI_LIMITS_FOR_STORAGE_SUCCESS': {
      return {
        ...state,
        fetchingIpiStorageInformation: false,
        ...action.payload
      };
    }
    case 'GET_IPI_STORAGE_INFORMATION_FAILED':
    case 'UPDATE_IPI_LIMITS_FOR_STORAGE_FAILED': {
      return {
        ...state,
        fetchingIpiStorageInformation: false
      };
    }
    case 'CLEAR_PURCHASE_ORDERS_DETAILS_STATE': {
      return {
        ...initialState
      };
    }
    case 'GET_VENDOR_ADDRESSES_SUCCESS': {
      return {
        ...state,
        fetching: false,
        vendorAddresses: action.payload ? [...action.payload] : []
      };
    }
    case 'REFRESH_PURCHASE_ORDER_ITEMS_SUCCESS': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'DELETE_PURCHASE_ORDER_ITEMS_SUCCESS': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'CLEAR_VALIDATED_ORDER_ITEMS': {
      return {
        ...state,
        processedFeedItems: []
      };
    }
    case 'CLEAR_STORAGE_PO_ITEMS': {
      return {
        ...state,
        storagePurchaseOrders: []
      };
    }
    case 'UPDATE_PURCHASE_ORDER_ITEM_SUCCESS': {
      const { updatedPurchaseOrder } = action.payload;
      return {
        ...state,
        fetching: false,
        updatedPurchaseOrder
      };
    }
    case 'GET_PURCHASE_ORDER_DETAILS_SUCCESS': {
      const {
        total_records,
        purchase_orders,
        vendor,
        vendor_addresses,
        company
      } = action.payload;
      const { id: vendorId, name: vendorName, user, file_as_po_name } = vendor;
      const vendorUserEmail = user && user.email ? user.email : '';
      const { id, name, logo } = company;
      return {
        ...state,
        fetching: false,
        total_records,
        vendorId,
        vendorName,
        vendorUserEmail,
        purchaseOrders: purchase_orders,
        vendorAddresses: vendor_addresses,
        company: { id, name, logo },
        vendor,
        po_name: file_as_po_name
      };
    }
    case 'UPDATE_PO_NAME': {
      return {
        ...state,
        ...action.payload
      }
    }
    case 'SET_PAGINATION_PURCHASE_ORDER_DETAILS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    case 'CONFIRM_PURCHASE_ORDER_ITEMS_SUCCESS': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SEND_UNGATED_ORDER_ITEMS_SUCCESS': {
      const { pendingPoIndex } = action.payload;
      return {
        ...state,
        fetchingForDrawer: false,
        pendingPoIndex
      };
    }
    case 'CLEAR_PENDING_PO_INDEX': {
      return {
        ...state,
        pendingPoIndex: {}
      };
    }
    case 'GET_EXPORT_PURCHASE_ORDER_DETAILS_FAILED':
    case 'GET_EXPORT_PURCHASE_ORDER_DETAILS_SUCCESS':
    case 'PURCHASE_ORDER_CONFRIM_EMAIL_FAILED':
    case 'PURCHASE_ORDER_CONFRIM_EMAIL_SUCCESS':
    case 'UPDATE_PURCHASE_ORDER_ITEM_FAILED':
    case 'CONFIRM_PURCHASE_ORDER_ITEMS_FAILED':
    case 'DELETE_PURCHASE_ORDER_ITEMS_FAILED':
    case 'REFRESH_PURCHASE_ORDER_ITEMS_FAILED':
    case 'SAVE_VENDOR_ADDRESSES_FAILED':
    case 'SAVE_VENDOR_ADDRESSES_SUCCESS':
    case 'GET_VENDOR_ADDRESSES_FAILED':
    case 'SAVE_COMPANY_ADDRESSES_SUCCESS':
    case 'SAVE_COMPANY_ADDRESSES_FAILED':
    case 'GET_COMPANY_ADDRESSES_FAILED':
    case 'GET_PURCHASE_ORDER_DETAILS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    default:
      return state;
  }
};

export default purchaseOrderDetails;
