import React, { lazy } from 'react';

const CentrailContainer = lazy(() => import('../../containers/catalogs/CentralContainerForCatalogs'));
const CatalogInProcessingContainer = lazy(() => import('../../containers/catalogs/catalogInProcessesContainer'));
const ProcessedCatalogsContainer = lazy(() => import('../../containers/catalogs/processedCatalogsContainer'));
const CatalogWithErrorsContainer = lazy(() => import('../../containers/catalogs/catalogWithErrorsContainer'));

export const CATALOG_ROUTES = [
  {
    path: '/catalog-jobs',
    component: <CentrailContainer />,
    group: 'Catalog',
    reducer: ['catalogJobs', 'processedCatalogs']
  },
  {
    path: '/catalogs',
    component: <CentrailContainer />,
    group: 'Catalog',
    reducer: ['catalogJobs', 'processedCatalogs']
  },
  {
    path: '/catalogs/processing',
    component: <CatalogInProcessingContainer />,
    group: 'Catalog',
    reducer: 'processingCatalogs'
  },
  {
    path: '/catalogs/processed',
    component: <ProcessedCatalogsContainer />,
    group: 'Catalog',
    reducer: 'vendorProcessedCatalogs'
  },
  {
    path: '/catalogs/errors',
    component: <CatalogWithErrorsContainer />,
    group: 'Catalog',
    reducer: 'invalidCatalogs'
  }
];
