import React, { useState, lazy, useEffect } from 'react';
import { PageHeader, Button } from 'antd';
import { find } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import Dashboard from '../dashboard/Stats.jsx';
import './poDashboardStyle.css';

const NewPurchaseOrderContainer = lazy(() => import('../../containers/purchaseOrders/NewPurchaseOrderContainer'));
const PendingPoIndicesContainer = lazy(() => import('../../containers/purchaseOrders/PendingPoIndicesContainer'));
const AllOrdersContainer = lazy(() => import('../../containers/allOrders/allOrdersContainer'));
const PoInvoicesContainer = lazy(() => import('../../containers/PoInvoicesContainer'));
const PurchaseInvoicesContainer = lazy(() => import('../../containers/PurchasingInvoicesContainer'));

const tabsReducers = [
  {
    route: '/po/new-po-plan',
    reducer: ['uploadPurchaseOrder', 'simplePurchaseOrders', 'pendingPurchaseOrders']
  },
  {
    route: '/po/my-orders',
    reducer: ['pendingPoIndices']
  },
  {
    route: '/po/all-orders',
    reducer: ['allOrders']
  },
  {
    route: '/po/reconciliation',
    reducer: ['poInvoices']
  },
  {
    route: '/po/invoices',
    reducer: ['purchasingInvoices']
  },
  {
    route: '/po/dashboard',
    reducer: ['dashboard']
  },
]

const getCurrentTab = () => {
  const currentPath = window.location.pathname.split('/')[2];
  let currentTab = currentPath === 'new-po-plan' ? 'New PO Plan' : currentPath === 'my-orders' ? 'My Orders' :
    currentPath === 'all-orders' ? 'All Orders' : currentPath === 'reconciliation' ? 'Reconciliation' :
      currentPath === 'invoices' ? 'Invoices' : 'Dashboard';
  return currentTab;
}

const combinedPoAndDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser: user } = useSelector((state) => state?.auth || {});
  const [currentComponent, setCurrentComponent] = useState(getCurrentTab());
  const isOwner = user?.email == "steven@wraithco.com";

  useEffect(() => {
    changeTab(getCurrentTab());
  }, [window.location.pathname]);
  const changeTab = (tab) => {
    setCurrentComponent(tab);
    if (tab === 'New PO Plan') navigate('/purchase-order');
    else if (tab === 'My Orders')  navigate('/purchase-order');
    else if (tab === 'All Orders') resetAndNavigate('/po/all-orders', find(tabsReducers, { route: '/po/all-orders' })?.reducer || []);
    else if (tab === 'Reconciliation') resetAndNavigate('/po/reconciliation', find(tabsReducers, { route: '/po/reconciliation' })?.reducer || []);
    else if (tab === 'Invoices') resetAndNavigate('/po/invoices', find(tabsReducers, { route: '/po/invoices' })?.reducer || []);
    else resetAndNavigate('/po/dashboard', find(tabsReducers, { route: '/po/dashboard' })?.reducer || []);
  }

  const resetAndNavigate = async (link, reducers) => {
    if (window.location.pathname !== link) {
      await dispatch({ type: 'RESET_APP', reducers });
      navigate(link);
    }
  }

  const currentComponentContainer = () => {
    if (currentComponent === 'Dashboard') return <Dashboard />;
    if (currentComponent === 'New PO Plan') return <NewPurchaseOrderContainer />;
    if (currentComponent === 'My Orders') return <PendingPoIndicesContainer />;
    if (currentComponent === 'All Orders') return <AllOrdersContainer />;
    if (currentComponent === 'Reconciliation') return <PoInvoicesContainer />;
    if (currentComponent === 'Invoices') return <PurchaseInvoicesContainer />;
  }

  const activeStyle = { backgroundColor: '#23b7e5', color: 'white' };

  return (
    <div id='combinedPoDashboardContainer'>
      <PageHeader
        style={{ height: '54px', width: '100%', backgroundColor: '#d5dbe3' }}
        title={<div style={{ paddingLeft: 5 }}>Purchase Orders</div>}
        extra={
          <div >
            <Button.Group value={currentComponent} onClick={(e) => changeTab(e.target.value)}>
              <Button style={currentComponent === 'Dashboard' ? activeStyle : {}} value="Dashboard">Dashboard</Button>
              <Button style={currentComponent === 'New PO Plan' ? activeStyle : {}} value="New PO Plan">New PO Plan</Button>
              <Button style={currentComponent === 'My Orders' ? activeStyle : {}} value="My Orders">My Orders</Button>

              {isOwner &&
                <Button style={currentComponent === 'All Orders' ? activeStyle : {}} value="All Orders">All Orders</Button>
              }
              {isOwner &&
                <Button style={currentComponent === 'Reconciliation' ? activeStyle : {}} value="Reconciliation">Reconciliation</Button>
              }
              {isOwner &&
                <Button style={currentComponent === 'Invoices' ? activeStyle : {}} value="Invoices">Invoices</Button>
              }
            </Button.Group>
          </div>
        }
      />
      <div style={{ height: "calc(100% - 54px)" }}>
        {currentComponentContainer()}
      </div>
    </div>
  );
}

export default combinedPoAndDashboard;
