export const shipmentTypes = [
  { value: "SP", label: "SP - Small Parcel" },
  { value: "LTL", label: "LTL/FTL - Less Than Truckload/Full Truckload" },
];

export const partneredOptions = [
  { value: true, label: "Partnered" },
  { value: false, label: "Non Partnered" },
];

export const labelPrepPrefTypes = [
  { value: "SELLER_LABEL", label: "SELLER LABEL" },
  { value: "AMAZON_LABEL_ONLY", label: "AMAZON LABEL ONLY" },
  { value: "AMAZON_LABEL_PREFERRED", label: "AMAZON LABEL PREFERRED" },
];

export const boxContentPrefTypes = [
  { value: "NONE", label: "NONE" },
  { value: "FEED", label: "FEED" },
  { value: "2D_BARCODE", label: "2D BARCODE" },
];

export const partneredCarriers = [
  { value: "UNITED_PARCEL_SERVICE_INC", label: "UPS - UNITED PARCEL SERVICE INC" }
];

export const nonPartneredCarriers = [
  { value: "DHL_EXPRESS_USA_INC", label: "DHL - DHL EXPRESS USA INC" },
  { value: "FEDERAL_EXPRESS_CORP", label: "FedEx - FEDERAL EXPRESS CORP" },
  { value: "UNITED_STATES_POSTAL_SERVICE", label: "USPS - UNITED STATES POSTAL SERVICE" },
  { value: "UNITED_PARCEL_SERVICE_INC", label: "UPS - UNITED PARCEL SERVICE INC" },
  { value: "OTHER", label: "Others" },
];

export const sellerFreightClassValues = [
  { value: 50, label: '50' },
  { value: 55, label: '55' },
  { value: 60, label: '60' },
  { value: 65, label: '65' },
  { value: 70, label: '70' },
  { value: 77.5, label: '77.5' },
  { value: 85, label: '85' },
  { value: 92.5, label: '92.5' },
  { value: 100, label: '100' },
  { value: 110, label: '110' },
  { value: 125, label: '125' },
  { value: 150, label: '150' },
  { value: 175, label: '175' },
  { value: 200, label: '200' },
  { value: 250, label: '250' },
  { value: 300, label: '300' },
  { value: 400, label: '400' },
  { value: 500, label: '500' },
];
