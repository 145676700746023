import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { Tooltip, notification } from "antd";
import { ceil, uniq, flatMap } from 'lodash';
// import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import ProductImage from "../../assets/images/product-img.svg";
import MarketplaceImage from "../../assets/images/newImages/amazon-fba.svg";
import { TableWrapper } from "./style";
import EditIcon from "../../assets/images/edit-icon.svg"
import Pagination from "../../components/pagination";

import { UpdateQuantity, UpdateCost, GetTopStats } from "../../slices/fba-shipment";

let gridApi;
const Table = ({
  rowData,
  total,
  handlePagination,
  refreshGridClick,
  pagination,
  poId,
  setSelectedRows,
  selectedRows,
  className
}) => {
  const gridRef = useRef();
  const dispatch = useDispatch();
  const { page_num, per_page } = pagination;
  const [editQty, setEditQty] = useState('');
  const [editBundleQty, setEditBundleQty] = useState('');
  const [editCost, setEditCost] = useState('');

  const CustomEditor = forwardRef((props, ref) => {
    const [value, setValue] = useState(props.value);

    const inputRef = React.createRef();

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
          props.setEditQty('');
          setValue(props.value);
        } else if (!inputRef.current) {
          props.setEditQty('');
          setValue(props.value);
        }
      };
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, []);

    useImperativeHandle(ref, () => ({
      getValue() {
        return value;
      },
      afterGuiAttached() {
        // Focus on the input field when the editor is attached
        inputRef.current.focus();
      },
    }));

    const handleChange = (e) => {
      e.stopPropagation();
      if (!isNaN(e.target.value) && e.target.value >= 0) {
        if ((props.type === 'qty' || props.type === 'bundleQty') && e.target.value.indexOf('.') >= 0) return;
        setValue(e.target.value);
      }
    };

    const onKeyDownHandle = (event) => {
      event.stopPropagation();
      if (poId && event.key === 'Enter' && event.target.value != props.value && props.value >= 0) {
        if (props.type === 'qty' || props.type === 'bundleQty') {
          const { node } = props || {};
          const { quantity, bundleQTY } = node.data;
          if (props.type === 'bundleQty' && (quantity % Number(value) !== 0)) {
            notification.error({
              message: "Update Quantity",
              description: 'Quantity should be  divisible on bundle quantity.',
              top: 65
            });
            return;
          } if (props.type === 'qty' && (Number(value) % bundleQTY !== 0)) {
            notification.error({
              message: "Update Quantity",
              description: 'Quantity should be  divisible on bundle quantity.',
              top: 65
            });
            return;
          }
          dispatch(UpdateQuantity({
            id: props.id,
            params: {
              purchase_order: {
                ...(props.type === 'qty' ? { order_quantity: Number(value) } : { bundle_quantity: Number(value) })
              }
            }
          })).then(({ payload }) => {
            if (payload?.success) {
              node.data.quantity = payload?.po?.order_quantity;
              node.data.bundleQTY = payload?.po?.bundle_quantity;
              node.data.profit = payload?.po?.profit;
              dispatch(GetTopStats({ poId }));
              gridApi.redrawRows({ rowNodes: [node] });
            }
          });
          props.setEditQty('');
        } else if (props.type === 'cost') {
          dispatch(UpdateCost({
            id: props.id,
            params: {
              vendor_item: {
                cost: parseFloat(value)
              }
            }
          })).then(({ payload }) => {
            const { node } = props || {};
            node.data.cost = payload?.purchase_order?.cost;
            node.data.profit = payload?.purchase_order?.profit;
            dispatch(GetTopStats({ poId }));
            gridApi.redrawRows({ rowNodes: [node] });
          });
          props.setEditQty('');
        }
      } else if (poId && event.key === 'Enter') props.setEditQty('');
    }

    return <input className="table-input" ref={inputRef} value={value} onChange={handleChange} onKeyDown={onKeyDownHandle} />;
  });

  const UPCRenderer = ({ data }) => {
    return <Tooltip title={data.vendorUPC}><p>{data.vendorUPC || 'N/A'}</p></Tooltip>;
  };

  const NameRenderer = ({ data }) => {
    return (
      <div className="copy-wrapper">
        <img height={34} width={34} src={data.image || '/images/no-image.png'} alt="Product" />
        <Tooltip title={data.name}><p>{data.name || 'N/A'}</p></Tooltip>
      </div>
    );
  };

  const QuantityRenderer = ({ data, node }) => {
    return (
      data?.id !== editQty ? <div className="button-cell">
        {data.quantity && (
          <span>
            {String(data.quantity).length > 5 ? (
              <Tooltip title={data.quantity}>
                <p className="overflow-text-2">{data.quantity}</p>
              </Tooltip>
            ) : (
              <p>{data.quantity}</p>
            )}
          </span>
        )}
        <button onClick={(event) => {
          event.stopPropagation();
          setEditQty(data?.id);
        }} className="edit-button"><img src={EditIcon} /></button>
      </div> : <CustomEditor setEditQty={setEditQty} value={data?.quantity || 0} id={data?.id} type='qty' node={node} />
    );
  };

  const BundleQTYRenderer = ({ data, node }) => {
    return (
      data?.id !== editBundleQty ? <div className="button-cell">
        {typeof data.bundleQTY !== 'undefined' && (
          <span>
            {String(data.bundleQTY || 0).length > 5 ? (
              <Tooltip title={data.bundleQTY || 0}>
                <p className="overflow-text-2" >{data.bundleQTY || 0}</p>
              </Tooltip>
            ) : (
              <p>{data.bundleQTY || 0}</p>
            )}
          </span>
        )}
        <button onClick={(event) => {
          event.stopPropagation();
          setEditBundleQty(data?.id);
        }} className="edit-button"><img src={EditIcon} /></button>
      </div> : <CustomEditor setEditQty={setEditBundleQty} value={data?.bundleQTY || 0} id={data?.id} type='bundleQty' node={node} />
    );
  };

  const ASINRenderer = ({ data }) => {
    return <p className="text-primary" onClick={() => window.open(`https://www.amazon.com/dp/${data?.asin}`, '_blank')}>{data.asin || 'N/A'}</p>;
  };

  const MarketplaceRenderer = ({ data }) => {
    return (
      <div className="vendor-wrapper">
        <img src={MarketplaceImage || '/images/no-image.png'} alt="Marketplace" />
      </div>
    );
  };

  const SKURenderer = ({ data }) => {
    return <p>{data.SKU}</p>;
  };

  const StatusRenderer = ({ data }) => {
    let statusDisplay = data.status === 'OK' || data.status === 'TO BE CREATED' ? data?.status : 'GATED';
    let statusClass = '';

    switch (statusDisplay) {
      case 'OK':
        statusClass = 'success';
        break;
      case 'TO BE CREATED':
        statusClass = 'warning';
        break;
      case 'GATED':
        statusClass = 'gated';
        break;
      default:
        statusClass = '';
        break;
    }

    return <p className={`badges-wrapper ${statusClass}`}>{statusDisplay}</p>;
  };

  const CostRenderer = ({ data, node }) => {
    return (
      data?.id !== editCost ? <div className="button-cell">
        {typeof data.cost !== 'undefined' && (
          <span>
            {String(`$${data.cost || 0}`).length > 5 ? (
              <Tooltip title={`$${data.cost || 0}`}>
                <p className="sku-text overflow-text">${data.cost || 0}</p>
              </Tooltip>
            ) : (
              <p className="sku-text">${data.cost || 0}</p>
            )}
          </span>
        )}
        <button onClick={(event) => {
          event.stopPropagation();
          setEditCost(data?.id);
        }} className="edit-button"><img src={EditIcon} /></button>
      </div> : <CustomEditor setEditQty={setEditCost} value={data.cost || 0} id={data?.id} type='cost' node={node} />
    );
  };

  const VendorRenderer = ({ data }) => {
    return (
      <div className="product-data-item">
        <div className="vendor">
          <Tooltip title={data.vendor.vendorName}>
            <p>{data.vendor.vendorName}</p>
          </Tooltip>
          <div className="product-details">
            <Tooltip title={data.vendor.vendorAddress}>
              <h6>{data.vendor.vendorAddress}</h6>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  };

  const ProfitRenderer = ({ data }) => {
    return (
      <span>
        {typeof data.profit !== 'undefined' && (
          String(`$${data.profit}`).length > 6 ? (
            <Tooltip title={`$${data.profit}`}>
              <p className="sku-text overflow-text">{`$${data.profit}`}</p>
            </Tooltip>
          ) : (
            <p className="sku-text">{`$${data.profit}`}</p>
          )
        )}
      </span>
    );
  };

  const AvailableQuantitiesRenderer = ({ data }) => {
    return <p className="sku-text">{data.availableQuantities}</p>;
  };

  const onRowSelected = ({ data }) => {
    const selectedNodes = gridApi.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data?.id);
    setSelectedRows({
      ...selectedRows,
      [page_num]: selectedData
    });
  };

  const gridReady = (params) => {
    gridApi = params.api;
  };

  const columnDefs = useMemo(() => [
    {
      headerName: "",
      field: "checkbox",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 20,
      cellRenderer: (params) => {
        const { data, node } = params || {};
        if (flatMap(Object.values(selectedRows))?.includes(data?.id)) node.setSelected(true);
      },
      suppressMenu: true
    },
    {
      headerName: "Vendor UPC",
      field: "vendorUPC",
      resizable: true,
      minWidth: 150,
      flex: 1,
      cellRendererFramework: UPCRenderer,
      suppressMenu: true,
    },
    {
      headerName: "Name",
      field: "name",
      resizable: true,
      cellRendererFramework: NameRenderer,
      flex: 1,
      minWidth: 200,
      suppressMenu: true,
    },
    {
      headerName: "ASIN",
      field: "ASIN",
      resizable: true,
      minWidth: 135,
      suppressMenu: true,
      flex: 1,
      cellRendererFramework: ASINRenderer,
    },
    {
      headerName: "SKU",
      field: "SKU",
      resizable: true,
      minWidth: 150,
      suppressMenu: true,
      flex: 1,
      cellRendererFramework: SKURenderer,
    },
    {
      headerName: "Marketplace",
      field: "marketplace",
      resizable: true,
      minWidth: 100,
      suppressMenu: true,
      flex: 1,
      cellRendererFramework: MarketplaceRenderer,
    },
    {
      headerName: "Status",
      field: "status",
      resizable: true,
      minWidth: 160,
      suppressMenu: true,
      flex: 1,
      cellRendererFramework: StatusRenderer,
    },
    {
      headerName: "Order Quantity",
      field: "quantity",
      resizable: true,
      minWidth: 130,
      cellEditor: "agTextCellEditor",
      flex: 1,
      suppressMenu: true,
      headerClass: 'center-header',
      cellRendererFramework: QuantityRenderer,
    },
    {
      headerName: "Bundle QTY",
      field: "bundleQTY",
      resizable: true,
      minWidth: 130,
      flex: 1,
      cellEditor: "agTextCellEditor",
      suppressMenu: true,
      cellRendererFramework: BundleQTYRenderer,
    },
    {
      headerName: "Cost",
      field: "cost",
      resizable: true,
      minWidth: 130,
      suppressMenu: true,
      flex: 1,
      cellRendererFramework: CostRenderer,
    },
    {
      headerName: "Profit",
      field: "profit",
      resizable: true,
      minWidth: 130,
      flex: 1,
      suppressMenu: true,
      cellRendererFramework: ProfitRenderer,
    },
    {
      headerName: "Vendor",
      field: "vendor",
      resizable: true,
      minWidth: 150,
      suppressMenu: true,
      flex: 1,
      cellRendererFramework: VendorRenderer,
    },
  ], [
    page_num,
    editQty,
    editBundleQty,
    editCost
  ]);

  const gridOptions = {
    pagination: true,
    paginationPageSize: 8,
    autoSizeColumns: true,
    defaultColDef: {
      cellStyle: { border: "1px solid #E6E6E6", textAlign: 'center' },
    },
  };

  return (
    <>
      <TableWrapper>
        <div className={`ag-theme-material po-table ${className}`} style={{ width: "100%" }}>
          <AgGridReact
            ref={gridRef}
            gridOptions={gridOptions}
            onGridReady={gridReady}
            rowHeight={45}
            pagination={false}
            columnDefs={columnDefs}
            rowData={rowData}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            onSelectionChanged={onRowSelected}
          />
        </div>
        <Pagination
          total={total}
          totalPages={ceil(total / per_page + 1)}
          pageNumber={page_num}
          pageLimit={per_page}
          handleLimitChange={(e) => handlePagination(e, "pageLimit")}
          handlePageNumberChange={(e) => handlePagination(e, "pageNumber")}
          handlePageChangeLeft={(e) => handlePagination(e, "pageNumber")}
          handlePageChangeRight={(e) => handlePagination(e, "pageNumber")}
          refreshGridClick={refreshGridClick}
        />
      </TableWrapper>
    </>
  );
};

export default Table;
