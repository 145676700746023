import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';

import { AiOutlineInbox } from "react-icons/ai";
import { Button, Upload, DatePicker, Input, message, Spin, notification, Modal, Empty, Tooltip } from 'antd';
import { BsSearch, BsTrash, BsFillPrinterFill } from "react-icons/bs";
import { FaFileAlt } from "react-icons/fa";
import dropdownIcon from "../../../assets/images/pagination-arrow-down.svg"

import InvoiceTrash from "../../../assets/images/invoice-delete.svg"
import InvoiceView from "../../../assets/images/invoice-view.svg"
import InvoicePrint from "../../../assets/images/invoice-print.svg"
import InputComponent from '../../../components/uiComponent/inputs/labelInput/index';
import AgGrid from "../../../components/ag-grid-table/index";

import {
  SaveInvoiceUrl,
  GetPreSignedUrl,
  RemoveInvoice
} from '../../../slices/fba-inbound/purchase-order';

import { InvoicePopupWrapper } from '../style';
const { RangePicker } = DatePicker;
const InvoicePopup = ({ setInvoiceModalVisible, invoiceInfo, onSuccess, setSelectedRowId }) => {
  const dateRangePickerRef = useRef(null);
  const dispatch = useDispatch();
  const { Dragger } = Upload;
  const { loading } = useSelector((store) => store.fbaInbound);

  const [fileList, setFileList] = useState([]);
  const [fileName, setFileName] = useState('');
  const [invoicesList, setInvoicesList] = useState();
  const [invoiceUrl, setInvoiceUrl] = useState('');
  const [file, setFile] = useState('');
  const [search, setSearch] = useState('');
  const [allInvoices, setAllInvoices] = useState([]);
  const [selectedRange, setSelectedRange] = useState([]);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState('');

  const compareDates = (dates, dateToCompare) => {
    return (
      dates[0].isSameOrBefore(dateToCompare, 'day') &&
      dates[1].isSameOrAfter(dateToCompare, 'day')
    ) || (
        dates[0].isSame(dateToCompare, 'day') ||
        dates[1].isSame(dateToCompare, 'day')
      ) || (
        dates[1].isSameOrBefore(dateToCompare, 'day') &&
        dates[0].isSameOrAfter(dateToCompare, 'day')
      )
  }

  const handleRangeChange = (dates) => {
    setSelectedRange(dates);
    setOpenDatePicker(false);
    if (dates?.length === 2 && !search) {
      const newInvoicesList = allInvoices?.filter(({ invoiced_date }) => {
        const dateToCompare = moment(invoiced_date);
        return compareDates(dates, dateToCompare);
      });
      setInvoicesList(newInvoicesList);
    } else if (dates?.length === 2 && search) {
      const newInvoicesList = allInvoices?.filter(({ file_url, invoice_number, invoiced_date }) => {
        const dateToCompare = moment(invoiced_date);
        return compareDates(dates, dateToCompare) && invoice_number.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase());
      });
      setInvoicesList(newInvoicesList);
    } else {
      if (search) {
        const newInvoicesList = allInvoices?.filter(({ file_url, invoice_number }) => {
          return invoice_number.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase());
        });
        setInvoicesList(newInvoicesList);
      } else {
        setInvoicesList(allInvoices);
      }
    }
  };

  const clearFieldsAndFileData = () => {
    setFileList([]);
    setFileName("");
    setFile('');
    setOpenDatePicker(false);
    setSelectedRange([])
  }
  const handleBeforeUpload = () => {
    return false;
  };

  const handleInvoiceUpload = () => {
    dispatch(GetPreSignedUrl({
      invoice_name: fileName,
      file
    })).then((res) => {
      if (res?.payload?.url) {
        dispatch(SaveInvoiceUrl({
          pending_po_indices_id: invoiceInfo?.id,
          s3_link: res?.payload?.url,
          invoice_number: invoiceNumber,
          invoiced_date: invoiceDate
        })).then(({ payload }) => {
          setAllInvoices([...payload?.pending_po_index?.order_invoices?.reverse()]);
          clearFieldsAndFileData();
          setInvoiceNumber('');
          setInvoiceDate('');
          onSuccess();
        });
      }
    });
  };

  const disabledDate = (current) => {
    // Disable dates after today
    return current && current > moment().endOf('day');
  }

  const fileUploadProps = {
    name: "file",
    accept: '.pdf',
    fileList: fileList,
    onChange: async (info) => {
      let files = info.fileList.splice(0, 1);
      setFile(info.file);
      setFileList(files);
      setFileName(`${info.file.name}_${moment()?.format('MMMM-Do-YYYY-h:mm:ss-a')}.pdf`);
    },
    showUploadList: true,
    beforeUpload: handleBeforeUpload
  };

  const printPdf = async (pdfUrl) => {
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      const objectUrl = URL.createObjectURL(blob);

      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.src = objectUrl;
      document.body.appendChild(iframe);

      iframe.onload = () => {
        iframe.contentWindow.print();
      };
    } catch (error) {
      console.error('Error printing PDF:', error);
    }
  };
  const previewPdf = (pdfUrl) => window.open(pdfUrl, '_blank');
  const renderExtraFooter = () => {
    if (selectedRange.length === 2) {
      const start = selectedRange[0].format('MMM DD');
      const end = selectedRange[1].format('MMM DD, YYYY');
      return <div>{`${start} - ${end}`}</div>;
    }
    return null;
  };

  const handleClickOutside = (event) => {
    if (dateRangePickerRef?.current && !dateRangePickerRef?.current?.contains(event.target)) {
      setOpenDatePicker(false);
    }
  };

  useEffect(() => {
    const newInvoicesList = allInvoices?.filter(({ file_url, invoice_number, invoiced_date }) => {
      if (selectedRange?.length === 2 && !search) {
        const dateToCompare = moment(invoiced_date);
        return compareDates(selectedRange, dateToCompare);
      } else if (selectedRange?.length === 2 && search) {
        const dateToCompare = moment(invoiced_date);
        return compareDates(selectedRange, dateToCompare) && invoice_number.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase());
      } else {
        return invoice_number.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase())
      }
    });
    setInvoicesList(newInvoicesList);
  }, [search]);

  useEffect(() => {
    setAllInvoices([...invoiceInfo?.order_invoices].reverse() || []);
  }, [invoiceInfo]);

  useEffect(() => {
    const newInvoicesList = allInvoices?.filter(({ file_url, invoice_number, invoiced_date }) => {
      if (selectedRange?.length === 2 && !search) {
        const dateToCompare = moment(invoiced_date);
        return compareDates(selectedRange, dateToCompare);
      } else if (selectedRange?.length === 2 && search) {
        const dateToCompare = moment(invoiced_date);
        return compareDates(selectedRange, dateToCompare) && invoice_number.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase());
      }
      else if (search && !selectedRange?.length !== 2) return invoice_number.toLocaleLowerCase()?.includes(search?.toLocaleLowerCase());
      else return file_url;
    });
    setInvoicesList(newInvoicesList);
  }, [allInvoices]);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: true,
  };

  const columnDefinitions = useMemo(() => [
    {
      headerName: 'Invoice #',
      cellRendererFramework: ({ data }) => {
        return (
          <b>
            <Tooltip title={data?.invoice_number || ''}>
              <span>{data?.invoice_number || 'N/A'}</span>
            </Tooltip>
          </b>
        )
      },
      minWidth: 100,
      flex: 1
    },
    {
      headerName: 'Date',
      cellRendererFramework: ({ data }) => {
        return (
          <Tooltip title={data?.invoiced_date ? moment(data?.invoiced_date)?.format('DD MMM YYYY') : ''}>
            <span>{data?.invoiced_date ? moment(data?.invoiced_date)?.format('DD MMM YYYY') : 'N/A'}</span>
          </Tooltip>
        )
      },
      minWidth: 100,
      flex: 1
    },
    {
      headerName: 'Vendor',
      cellRendererFramework: () => {
        return (
          <span>{invoiceInfo?.vendor_name}</span>
        )
      },
      minWidth: 100,
      flex: 1
    },
    {
      headerName: 'User',
      cellRendererFramework: () => {
        return (
          <span>{invoiceInfo?.userName}</span>
        )
      },
      cellClass: "user-cell-class",
      minWidth: 100,
      flex: 1
    },
    {
      headerName: 'Actions',
      cellRendererFramework: ({ data }) => {
        const { id, file_url } = data || {}
        return (
          <div className='d-flex gap-3'>
            <div className='cursor-pointer'>
              <FaFileAlt color='#1565D8' onClick={() => previewPdf(file_url)} />
            </div>
            <div className='cursor-pointer'>
              <BsFillPrinterFill color='#1565D8' onClick={() => printPdf(file_url)} />
            </div>
            <div className='cursor-pointer'>
              <BsTrash color="#CF0909" onClick={() => setInvoiceUrl({
                order_invoice_id: id
              })} />
            </div>
          </div>
        )
      },
      minWidth: 120,
      flex: 1
    },
  ], [invoicesList]);

  return (
    <InvoicePopupWrapper>
      <div className="d-flex align-items-center gap-3 mb-4">
        <span className="icon-arrow-left cursor-pointer" onClick={() => {
          clearFieldsAndFileData();
          setInvoiceNumber('');
          setInvoiceDate('');
          setSearch('');
          setSelectedRange([]);
          setInvoiceModalVisible(false);
          setSelectedRowId(null);
        }}></span>
        <h2 className="mb-0 color-black">Manage Invoices</h2>
      </div>
      <div className='add-invoice-box'>
        <p className='add-invoice-heading'>Add Matching Invoice</p>
        <Row>
          <Col md={6} className='box-divider'>
            <div>
              <InputComponent
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
                label='Invoice Number'
                placeholder='Add Invoice Number'
              />
              <InputComponent
                value={invoiceDate}
                onChange={(e) => setInvoiceDate(e.target.value)}
                label='Invoice Date (optional)'
                placeholder='select Invoice Date'
                type="date"
              />
            </div>
          </Col>
          <Col md={6}>
            <div className='ms-3'>
              <label className='color-gray fs-12 mb-2'>Attach File (optional)</label>
              <div className='upload-product-item '>
                <Dragger {...fileUploadProps}>
                  <div className='d-flex gap-1 drag-icon'>
                    <p>
                      <AiOutlineInbox />
                    </p>
                    <p className="ant-upload-text">
                      Select a file or drag and drop here
                    </p>
                  </div>
                  <Button disabled={fileList?.length}>Attach File</Button>
                </Dragger>
              </div>
            </div>
          </Col>
        </Row>
        <Button
          className='save-fileButton mt-4'
          disabled={!fileList?.length || !invoiceNumber || loading}
          onClick={handleInvoiceUpload}
        >Add Invoice</Button>
      </div>
      <div className='divider'></div>

      <h3 className='inner-heading'>Recently Uploaded</h3>
      <div className='d-flex align-items-center justify-content-between'>
        <div className="search-bar-wrapper w-100">
          <Input value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search Invoice" className='w-100' />
          <BsSearch />
        </div>
        <div style={{ height: '25px', width: '172px', marginLeft: '20px' }}>
          <div
            onClick={(e) => {
              e.stopPropagation();
              setOpenDatePicker(!openDatePicker)
            }}
            style={{ cursor: 'pointer' }}
          >
            {selectedRange?.length === 2 ? `${selectedRange[0].format('MMM DD')} - ${selectedRange[1].format('DD, YYYY')}` : 'Select Date'}
            {selectedRange?.length === 2 ?
              <span
                style={{ marginLeft: '5px', color: 'rgba(21, 101, 216, 1)' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDatePicker(false);
                  setSelectedRange([]);
                  handleRangeChange([]);
                }}>X</span> :
              <img style={{ marginLeft: '5px' }} src={dropdownIcon} alt="Dropdown" />}
          </div>
          <div ref={dateRangePickerRef} onClick={(e) => e.stopPropagation()}>
            <RangePicker
              open={openDatePicker}
              style={{ visibility: 'hidden', width: '0px' }}
              onChange={handleRangeChange}
              value={selectedRange}
              renderExtraFooter={renderExtraFooter}
            />
          </div>
        </div>
      </div>
      {/* <div className='recent-uploads'>
        <Spin tip='Loading...' spinning={loading}>

          <Row className='g-3'>
            {!invoicesList?.length && <Empty />}
            {
              invoicesList?.map(({ file_url, id, created_at }) => {
                return <Col md={4}>
                  <div className='recent-upload'>
                    <embed src={file_url} type="application/pdf" width="100%" height="170px" />
                    <div className='upload-inner-wrapper'>
                      <Tooltip title={file_url?.split('/')[5]}>
                        <span className='upload-id'>{file_url?.split('/')[5]}</span>
                      </Tooltip>
                      <span className='upload-date'>{moment(created_at)?.format("DD-MMM-YYYY")}</span>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <button onClick={() => printPdf(file_url)}><img src={InvoicePrint} alt="Print Invoice" /></button>
                      <button onClick={() => previewPdf(file_url)}><img src={InvoiceView} /></button>
                      <button onClick={() => setInvoiceUrl({
                        order_invoice_id: id
                      })}><img src={InvoiceTrash} /></button>
                    </div>
                  </div>
                </Col>
              })
            }
          </Row>
        </Spin>
      </div> */}
      <div>
        <Spin tip='Loading...' spinning={loading}>
          <AgGrid
            user_referrals={invoicesList || []}
            defaultColDef={defaultColDef}
            columnDefinitions={columnDefinitions}
            rowHeight={50}
            height="630px"
          />
        </Spin>
      </div>
      <Modal
        width={380}
        bodyStyle={{ minHeight: 50 }}
        visible={invoiceUrl?.order_invoice_id ? true : false}
        centered
        onOk={() => {
          dispatch(RemoveInvoice({
            order_invoice_id: invoiceUrl?.order_invoice_id,
            pending_po_indexes_id: invoiceInfo?.id
          })).then(({ payload }) => {
            const { status, data } = payload || {};
            if (status === 200) {
              setAllInvoices([...data?.pending_po_index?.order_invoices]?.reverse())
              onSuccess();
            };
          });
          setInvoiceUrl({});
        }}
        okText="Delete"
        cancelText="Cancel"
        onCancel={() => setInvoiceUrl({})}
      >
        <p style={{
          fontWeight: 'bold'
        }}>Are you sure you want to delete items</p>
      </Modal>
    </InvoicePopupWrapper>
  );
};

export default InvoicePopup;
