import React, { Component } from "react";
import { GridWrapper } from "./style";
import { AgGridReact } from 'ag-grid-react';
// import "ag-grid-enterprise";
// import "ag-grid-community/dist/styles/ag-grid.css";
// import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

const Index = (props) => {
  const {
    gridOptions,
    gridReady,
    getRowHeight,
    defaultColDef,
    columnDefinitions,
    user_referrals,
    sidebarOpen,
    className,
    rowHeight,
    onSortChange,
    onSelectionChanged,
    rowSelection,
    getRowClass,
    height,
    masterDetail,
    collapse,
    detailCellRenderer,
    onFirstDataRendered,
    getRowNodeId,
    rowId,
    headerHeight,
    whiteSpace,
    getRowStyle,
    domLayout,
    detailRowAutoHeight,
    headerBg
  } = props
  
  return (
    <GridWrapper height={height || ''} whiteSpace={whiteSpace} headerBg={headerBg}>
      <div className={`${collapse ? ' ag-theme-material' : 'ag-theme-balham'} QB-dataTable ${className}`}>
        <AgGridReact
          gridOptions={gridOptions}
          reactNext={true}
          onGridReady={gridReady}
          deltaRowDataMode={false}
          getRowHeight={getRowHeight}
          animateRows={true}
          getRowStyle={getRowStyle}
          defaultColDef={defaultColDef}
          columnDefs={columnDefinitions}
          onSortChanged={onSortChange}
          rowData={user_referrals}
          rowHeight={rowHeight}
          headerHeight={headerHeight || 38}
          enableCellTextSelection={true}
          getRowNodeId={getRowNodeId ? getRowNodeId : ({ id }) => id}
          pagination={false}
          sideBar={sidebarOpen ? 'columns' : false}
          enableRangeSelection={true}
          suppressRowClickSelection={true}
          suppressScrollOnNewData={true}
          paginationPageSize={10}
          onSelectionChanged={onSelectionChanged}
          rowSelection={rowSelection}
          getRowClass={getRowClass}
          masterDetail={masterDetail}
          detailCellRenderer={detailCellRenderer}
          detailRowHeight={300}
          rowId={rowId}
          domLayout={domLayout}
          onFirstDataRendered={onFirstDataRendered}
          detailRowAutoHeight={detailRowAutoHeight}
        />
      </div>
    </GridWrapper>
  )
};
export default Index;
