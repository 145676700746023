import axios from "axios";

export const awsAxios = axios.create({
})

import { pendingRsponseInterceptors, pendingRequestInterceptors } from './src/utils/interseptors/pendingRequest';

const common = axios;
common.defaults.baseURL = process.env.API_BASE_URL;
common.defaults.headers.common = {
  'Access-Control-Allow-Origin': '*',
  'Accept': 'application/json'
};
common.interceptors.request.use(...pendingRequestInterceptors);
common.interceptors.response.use(...pendingRsponseInterceptors);

export const fetch = axios;
fetch.defaults.headers.common = {};

export default common;
