import React, { lazy } from 'react';

const UserGuideContainer = lazy(() => import('../../containers/reprice/UserGuideContainer'));
const DashboardContainer = lazy(() => import('../../containers/reprice/DashboardContainer'));
const Products = lazy(() => import('../../containers/reprice/Products.js'));
const RepriceContainer = lazy(() => import('../../containers/reprice/RepriceContainer'));
const StrategyListingContainer = lazy(() => import('../../containers/reprice/StrategyListingContainer'));
const PriceHistoryContainer = lazy(() => import('../../containers/reprice/PriceHistoryContainer'));
const SettingsContainer = lazy(() => import('../../containers/reprice/SettingsContainer'));
const ProductListingContainer = lazy(() => import('../../containers/reprice/ProductListingContainer'));
const Guide = lazy(() => import('../../components/reprice/guide.component.jsx'));

export const REPRICER_ROUTES = [
  {
    path: '/reprice/user-guide',
    component: <UserGuideContainer />,
    group: 'Repricer',
    reducer: ['Setting', 'Strategies']
  },
  {
    path: '/reprice/walk-through',
    component: <UserGuideContainer />,
    group: 'Repricer',
    reducer: ['Setting', 'Strategies']
  },
  {
    path: '/reprice/dashboard',
    component: <DashboardContainer />,
    group: 'Repricer',
    reducer: ['Dashboard', 'Setting', 'Products']
  },
  {
    path: '/reprice/guide',
    component: <Guide />,
    group: 'Repricer',
    reducer: ['repricerDashboard']
  },
  {
    path: '/reprice/strategy/:id',
    component: <RepriceContainer />,
    group: 'Repricer',
    reducer: ['Strategies', 'Products', 'Setting',]
  },
  {
    path: '/reprice/strategies',
    component: <StrategyListingContainer />,
    group: 'Repricer',
    reducer: ['Strategies', 'Products', 'Setting']
  },
  {
    path: '/reprice/price-history',
    component: <PriceHistoryContainer />,
    group: 'Repricer',
    reducer: ['History', 'Setting']
  },
  {
    path: '/reprice/settings',
    component: <SettingsContainer />,
    group: 'Repricer',
    reducer: ['Strategies', 'Setting', 'Products']
  },
  {
    path: '/reprice/products',
    component: <ProductListingContainer />,
    group: 'Repricer',
    reducer: ['Strategies', 'Products', 'Setting']
  },
  {
    path: '/reprice/products-listing',
    component: <Products />,
    group: 'Repricer',
    reducer: ['Strategies', 'Products', 'Setting']
  }
];
