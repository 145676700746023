import Styled from 'styled-components';
const PaymentWrapper = Styled.div`
.select_paln{
      background-color: ${({ theme }) => theme['white-color']};
      margin:24px;
      padding:24px;
      border-radius:5px;
      padding-top:65px;
      .height-calc{
        height: calc(100vh - 100px);
        overflow-y: auto;
        
      }
      .plans-scroll{
        height: calc(100vh - 167px);  
      }
       .arrow-box{
                display: flex;
                align-items:center;
          }

        .plans{
          border:2px solid #E6E6E6;
           background-color: ${({ theme }) => theme['white-color']};
          border-radius:16px;
          min-height
          &.premium{
            border:2px solid #1565D8;
          }
          .upper-header{
            &.premium_header{
              background-color: ${({ theme }) => theme['primary-color']};

              span{
                 color:${({ theme }) => theme['white-color']}!important;
              }
              .type{
              display:flex;
              justify-content:space-between;
              align-items:flex-end;
              h1{
               color:${({ theme }) => theme['white-color']}!important;
               sub{
                 font-size:${({ theme }) => theme['base-font-size-small']};
                  color:${({ theme }) => theme['mercury-color']};
               }
              }
              h6{
                    color:${({ theme }) => theme['white-color']}!important;
              }
            }
            }
            background-color: ${({ theme }) => theme['mercury-color']};
            padding:20px;
            border-top-left-radius:13px;
            border-top-right-radius:13px;
            .type{
              display:flex;
              justify-content:space-between;
              align-items:flex-end;
              h1{
                font-size:2.875rem;
                color: ${({ theme }) => theme['secondary-color']};
              }
            }
          }
          .feature{
            padding: 28px;
            ul{
              &.mh-592{
                  overflow: auto;
                  height: calc(100vh - 404px);
              }
              overflow-y: auto;
              list-style:none;
              padding-left:0;
              .disable{

                span,svg{
                     color: ${({ theme }) => theme['dusty_grey']};
                }
              }
              .enable{
                svg{
                     color: ${({ theme }) => theme['primary-color']};
                     margin-top:4px;
                     flex-shrink:0;
                }
              }
            .caption{
               color: ${({ theme }) => theme['comet-color']};
            }
          }
          }
        }
        .add-payment{
          .caption-color{
              color: ${({ theme }) => theme['cutty_sark']};
          }
        }
  }
  `;
export { PaymentWrapper };
