import React, { useState, useEffect } from 'react'
import { Spin } from 'antd'
import { object, string, ref, bool } from 'yup';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Logo from "../../assets/images/newImages/new_log.png";
import { AuthWrapper, PhoneNumberWrapper } from '../profile/style'
import { Form, Row, Col, Container } from "react-bootstrap"
import InfoVideo from '../profile/component/infoVideo'
import LabelInput from '../uiComponent/inputs/labelInput'
import PhoneInput from '../uiComponent/inputs/phoneNumberInput'
import CheckBox from '../uiComponent/checkBox'
import Button from '../uiComponent/button/index'
import { BiLeftArrowAlt } from "react-icons/bi";
import { AiFillEyeInvisible, AiFillEye, AiOutlineMenu } from "react-icons/ai";
import { setField, ListLocationItems, TransferItemToNewLocation } from "../../slices/internalStorage";
import { isEmpty, debounce } from 'lodash';
import { MobileWrapper, Header, Content, Footer, Title, InputWrapper, CardDetail, ScanFloating } from './mobile.styled';

const ScanNewLocationT = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { 
    scannedProductToTransfer, 
    selectedOldLocation, 
    selectedNewLocation, 
    listLocationLoading, 
    tranferItemLoading 
  } = useSelector(state => state?.internalStorage || {});
  const { id: newLocationId, name, location_type, items_count, units_count } = selectedNewLocation || {};
  const { id: itemId } = scannedProductToTransfer;
  const { id: oldLocationId } = selectedOldLocation;
  
  const handleTransferProduct = async () => {
    const response = await dispatch(TransferItemToNewLocation({ itemId, oldLocationId, newLocationId }));
    if (isEmpty(response.error)) {
      dispatch(setField({ field: 'selectedOldLocation', value: {} }))
      dispatch(setField({ field: 'scannedProductToTransfer', value: {} }))
      navigate('/location-details-add');
    }
  }

  const handleNewLocationScan = debounce(async(event) => {
    const value = event.target.value;
    if(!isEmpty(value)) {
      const response = await dispatch(ListLocationItems({ locationId: value }));
      if(isEmpty(response.error)) {
        const { locations } = response?.payload?.data || {};
        dispatch(setField({ field: 'selectedNewLocation', value: locations }));
      }
    }
  }, 1000)

  useEffect(() => {
    dispatch(setField({ field: 'selectedNewLocation', value: {} }));
    if (isEmpty(scannedProductToTransfer) || isEmpty(selectedOldLocation)) navigate('/choose-old-location')
  }, [])

  const isValid = itemId && oldLocationId && newLocationId;

  const spinLoading = listLocationLoading || tranferItemLoading;

  return (
      <MobileWrapper className=''>
          <div className='mobile-page'>
              <div className='container-fluid px-0'>
                  <Spin tip='Processing' spinning={spinLoading}>
                  <Content className=''>
                      <Title>
                          <h2>
                              <span
                                  className="icon-arrow-left cursor-pointer" onClick={() => navigate('/choose-old-location')}></span>
                              Scan New Location</h2>
                      </Title>
                      <InputWrapper>
                          <LabelInput className="input-with-icon" icon="icon-barcode" label="Scan Location Barcode" placeholder="Scan Barcode" onChange={(event) => handleNewLocationScan(event)} />
                      </InputWrapper>
                      {
                        !isEmpty(selectedNewLocation) && 
                        <CardDetail className='mb-card-single'>
                          <div className='card-details-head d-flex justify-content-between align-items-center'>
                              <h2>{name}</h2>
                              <span
                                  className=""></span>
                          </div>
                          <div className='card-details-content'>
                              {/* <p>{name}</p> */}
                              <ul className='d-flex'>
                                  {/* <li><span className='icon-carton'></span>{location_type}</li> */}
                                  <li><span className='icon-inventory'></span>Products<strong>{items_count}</strong></li>
                                  <li><span className='icon-total'></span>Units <strong>{units_count}</strong></li>
                              </ul>
                          </div>
                      </CardDetail>
                      }
                  </Content>
                  </Spin>
                  <Footer>
                      <Button disabled={!isValid} className="outlined btn-block" text="Confirm Transfer Product" onClick={handleTransferProduct} />
                  </Footer>
                  {/* <ScanFloating>
                      <span className='icon-barcode'></span>
                  </ScanFloating> */}

              </div>
          </div>
      </MobileWrapper>
  )
}

export default ScanNewLocationT;
