import { cloneDeep } from "lodash";
const initialState = {
  loading: false,
  pagination: {
    page_num: 1,
    per_page: 25
  },
  total: 0,
  user_referrals: [],
  invitation_link: ''
}

const referals = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'REFERAL_USERS_REQUEST': {
      return {
        ...state,
        loading: true
      }
    }
    case 'REFERAL_USERS_SUCCESS': {
      return {
        ...state,
        loading: false,
        ...action.payload,
      }
    }
    case 'REFERAL_USERS_FAILED': {
      return {
        ...state,
        loading: false
      }
    }
    case 'SET_PAGE_NUMBER_FOR_REFERAL_USERS':
    case 'SET_PAGE_SIZE_FOR_REFERAL_USERS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    default: {
      return {
        ...state
      }
    }
  }
}

export default referals;
