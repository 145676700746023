import React, { lazy } from "react";

const FbaInbound = lazy(() => import("../../containers/fbmInbound/index"));
const FbaInboundNew = lazy(() => import("../../containers/fbaInbound/index"));
const ShipmentPlan = lazy(() =>
  import("../../containers/fbmInbound/component/reviewShipmentPlan")
);
const CreateShipment = lazy(() =>
  import("../../containers/fbmInbound/component/createShipment")
);
const TransportPlan = lazy(() =>
  import("../../containers/fbmInbound/component/transportPlans")
);
const StoragePo = lazy(() =>
  import("../../containers/fbmInbound/component/storagePoDetails")
);

const StepGatedSku = lazy(() =>
  import("../../containers/fbaInbound/component/stepGatedSku")
);
export const FBA_INBOUND_NEW_ROUTES = [
  // {
  //   path: "/fba-inbound",
  //   component: <FbaInbound />,
  //   group: "FBA Inbound",
  //   reducer: [],
  // },
  {
    path: "/fba-inbound-new",
    component: <FbaInboundNew />,
    group: "FBA Inbound",
    reducer: [],
  },
  {
    path: "/fba-inbound-new/step-gated-sku/:batchId",
    component: <StepGatedSku />,
    group: "FBA Inbound",
    reducer: [],
  },
  {
    path: "/fba-inbound/shipment-plan/:user_id",
    component: <ShipmentPlan />,
    group: "FBA Inbound",
    reducer: [],
  },
  {
    path: "/fba-inbound/receive-items",
    component: <CreateShipment />,
    group: "FBA Inbound",
    reducer: [],
  },
  {
    path: "/fba-inbound/transportPlan/:shipmentID",
    component: <TransportPlan />,
    group: "FBA Inbound",
    reducer: [],
  },
  {
    path: "/fba-inbound/storage-po/:storageID",
    component: <StoragePo />,
    group: "FBA Inbound",
    reducer: [],
  },
];
