import Styled from "styled-components";
const GridWrapper = Styled.div`

.QB-dataTable{
    height: ${(props) => `calc(100vh - ${props.height || ''})`};
    font-family: "Titillium Web", sans-serif;
    border-bottom:1px solid #CFD8DC;
    overflow:auto;
    border:1px solid #CFD8DC;
    border-radius:4px;
    margin-top:10px;
}
.ag-cell-first-right-pinned {
  border-left: 1px solid #BDC3C7 !important;
}
.QB-dataTable.loading-sku{
    padding-top: 8px;
    padding-left: 0px; 
}
.QB-dataTable.all-orders{
    height: calc(100vh - 227px);  
}
.QB-dataTable.ag-theme-balham .ag-cell-label-container{
      white-space: ${(props) => (props.whiteSpace ? `${props.whiteSpace}` : 'nowrap')};  
}
.QB-dataTable.all-orders .ag-center-cols-viewport .ag-cell-value{
  padding: 12px 0px;
}
.ag-cell-focus.ag-cell-range-selected{
    background-color:transparent!important;
    border:none!important;
}
.QB-dataTable.checkin-table{
    height: calc(100vh - 400px); 
    padding-top: 5px;  
}
.QB-dataTable.loading-sku .ag-header-viewport .ag-header-cell{
    padding: 9px 8px;
}
.QB-dataTable.loading-sku .ag-center-cols-viewport .ag-cell-value{
    padding: 12px 7px;
}
.QB-dataTable.approves-match{
    height: calc(100vh - 267px);
    padding-top:10px;
    padding-left:0;
}
.QB-dataTable.arrival-table{
    padding-left:0;
}
.ag-cell-focus.ag-cell-range-selected{
    background-color:transparent!important;
    border:none!important;
}
.QB-dataTable{
    &.arrival-table{
        .ag-center-cols-viewport{
 .ag-cell-value{
            padding:8px;
        }
        }
       
    }
}
.QB-dataTable.approves-match .ag-header-viewport .ag-header-cell{
    padding: 10px 8px;
}
.QB-dataTable.approves-match .ag-center-cols-viewport .ag-cell-value{
    padding: 12px 8px;
}
.QB-dataTable.feed-table{
    padding-top:10px;
    padding-left:0;
}
.QB-dataTable.feed-file-item-table{
  height: calc(100vh - 100px);
  padding-top:10px;
  padding-left:0;
}
.QB-dataTable.listing-table{
    height: calc(100vh - 400px);
    padding-top: 3px;
    padding-left: 0;
}
.QB-dataTable.listing-table{
    height: calc(100vh - 233px);
    padding-top: 3px;
    padding-left: 0;
}
.QB-dataTable.listing-table .ag-header-viewport .ag-header-cell{
    padding: 10px 11px;
}
.QB-dataTable.listing-table .ag-center-cols-viewport .ag-cell-value{
    padding: 10px 10px;
}
.QB-dataTable.feed-table .ag-header-viewport .ag-header-cell{
    padding: 10px 17px;
}
.QB-dataTable.feed-table .ag-center-cols-viewport .ag-cell-value{
    padding: 12px 15px;
}
.QB-dataTable.feed-file-item-table .ag-header-viewport .ag-header-cell{
    padding: 10px 17px;
}
.QB-dataTable.feed-file-item-table .ag-center-cols-viewport .ag-cell-value{
    padding: 12px 15px;
}
.QB-dataTable.drawer-table{
    height: calc(100vh - 572px);
    padding-top: 25px;

}
.QB-dataTable.drawer-table .ag-header{
    min-height:30px!important;
}
.QB-dataTable .ag-header{
    font-family: "Titillium Web", sans-serif;
}
.QB-dataTable .ag-root-wrapper{
 border: 0;
}
.QB-dataTable .ag-root{
    border: none;
}
.QB-dataTable .ag-header{
    border-bottom-color: #4E5969;

}
.QB-dataTable .ag-header-viewport{
    background-color: #F8FAFB;
    border-radius: 4px 4px 0px 0px;
}
.QB-dataTable{
    &.warehouse-location{
      .ag-header-viewport{
        .ag-header-cell{
            padding: 4px 7px;
            letter-spacing: 0.5px;<span className="success-badge">success</span>
      }
   
      .ag-header-select-all{
        margin-right: 12px;
        margin-left: 5px;
        margin-top: 3px;
        .ag-icon {
            font-size:12px;
        }
      }
      }
      .table-icon-centered{
            padding-top:14px;
        }
      .ag-selection-checkbox{
        margin-left: 5px;
        margin-bottom: 5px;
      }
      .ag-cell{
        padding-left: 7px;
        display:flex;
        align-items:center;
        .ag-cell-value{
            &:first-child{
                margin-left: 9px;
            }
            padding: 12px 0px;
        }
        .ag-icon{
            font-size:12px;
        }
      }
      .table-icon{
          height:100%;
      }
    }
}
.QB-dataTable .ag-header-viewport .ag-header-cell{
    color: #616980;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    padding: 0px 11px;
}
.QB-dataTable .ag-header-viewport .ag-header-cell::after{
    background-color: #E6E6E6;
    border-right:none;
}
.QB-dataTable .ag-center-cols-viewport .ag-cell-value{
    font-size: 14px;
    line-height: 18px;
    color: #4E6069;
    display: block;
    align-items:flex-start;
    padding: 12px 6px;
}
.locationGrid {
    &.QB-dataTable .ag-center-cols-viewport .ag-cell-value{
        padding: 8px 0px !important;
    }
    &.ag-theme-balham .ag-header-cell::after, .ag-theme-balham .ag-header-group-cell::after {
        width: 0px !important;
    }
    &.QB-dataTable {
      padding-top: 0px !important;
    }
    .ag-cell-first-right-pinned {
      border-left: none !important;
    }
}
.drawer-grid {
    &.ag-theme-balham .ag-header {
        border-bottom: none;
    }
}
.QB-dataTable.po-checkin-quantites .ag-center-cols-viewport .ag-cell-inline-editing {
    padding: 5px 12px;
}
.QB-dataTable{
  &.border-remove{
    border:none;
  }

}
.QB-dataTable .ag-center-cols-viewport .ag-cell-wrapper{
    height: 100%;
    min-height: 42px;
}
.QB-dataTable  .ag-row-even,
.QB-dataTable  .ag-row-odd,
.QB-dataTable  .ag-row{
    border-bottom-color:  #EEF1F5;
    background-color: #fff;
    border-bottom:1px solid #EEF1F5;
}

.QB-dataTable  .ag-paging-panel{
background-color: #F8FAFB;
}
.QB-dataTable .ag-paging-button{
    border-left: 1px solid #C4C4C4;
    padding: 8px 8px 8px 10px;
    color: #1565D8;
}
.QB-dataTable .ag-paging-button .ag-icon{
    font-weight: bold;
}
.QB-dataTable .ag-paging-button[aria-label="First Page"],
.QB-dataTable .ag-paging-button[aria-label="Next Page"]{
    border: none;
}
.QB-dataTable .ag-paging-panel{
    height: 48px;
}

.QB-dataTable .ag-header-cell-sortable span[ref ^="eSort"]{
    color: #1565D8 ;
}
/* .QB-dataTable .ag-tool-panel-wrapper{
    width: 320px;
} */
.QB-dataTable .ag-ltr input[class^=ag-][type=text]{
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    color: #78909C;

}
.QB-dataTable .ag-tool-panel-wrapper .ag-column-select-header{
    height: 48px;
}
.QB-dataTable .ag-rtl .ag-side-bar-left,
.QB-dataTable .ag-ltr .ag-side-bar-right{
    border: top 1px;

}}
.QB-dataTable .table-badge{
    padding:0 7px;
    border: 1px solid  ${({ theme }) => theme['success-color']};
    border-radius:50px;
    margin: -1px 0;
    font-size:${({ theme }) => theme['base-font-size-small']};
    &.success{
        background-color: ${({ theme }) => theme['success-color']}05;
        color: ${({ theme }) => theme['success-color']};
        text-align: center;
    }
}
.table-actions{
    margin-top:13px;
    margin-left: -3px;
}
.QB-dataTable.warehouse-table{
    padding-top: 18px;
}
.title-source-image{
  width: 35px;
  height: 35px
}
.ag-icon-expanded{
    &::before{
          content: "\\e950";
         color: #1565d8;
         font-family: 'icomoon';
    }
}
.ag-theme-material .ag-group-contracted,.ag-group-expanded  {
    align-items: baseline!important;
}
.ag-theme-material .ag-header-viewport .ag-header-cell   {
   padding:3px 10px;
   white-space:normal;
}

.ag-theme-material .ag-icon-tree-closed::before{
          content: "\\e950";
        font-family: 'icomoon';
        color:${({ theme }) => theme['link-color']};
       font-size: ${({ theme }) => theme['base-font-size-small']};
    }
    .ag-theme-material .ag-icon-tree-open::before{
      content: "\\e951"!important;
        font-family: 'icomoon';
        color:${({ theme }) => theme['link-color']};
       font-size: ${({ theme }) => theme['base-font-size-small']};
    }
    .custom-asin-drawer{
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color:${({ theme }) => theme['secondary-color']};
        span{
            font-weight:600;
            color:${({ theme }) => theme['regent_gray']};
        }
    }
        .badge-sucess{
        background-color:${({ theme }) => theme['success-color']}10;
        border:1px solid ${({ theme }) => theme['success-color']};
        font-size:${({ theme }) => theme['base-font-size-small']};
        border-radius:50px;
        padding:0 8px;
        max-width: 75px;
        color:${({ theme }) => theme['success-color']};
        margin: 6px 0px;
    
    .product-title-box {
        span{
            width: 100%;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            white-space: normal;
            text-overflow: ellipsis;
        }
    }
    .QB-dataTable.po-checkin-quantites {
        .ag-header-viewport{
            background-color:${({ theme }) => theme['white-color']};
        }
        .ag-header-viewport .ag-header-cell::after{
            display:none;
        }
        .ag-header-viewport .ag-header-cell{
            padding-left:6px;
            padding-right:6px;
        }
        .ag-center-cols-viewport .ag-cell-value{
            padding-left:6px;
            padding-right:6px;
        }
        &.ag-theme-balham .ag-ltr .ag-cell{
            padding-left:0;
            padding-right:0;
        }
    }
  
    .purchase-order-grid {
      .ag-header{
        border-bottom: none;
      }
      .ag-center-cols-viewport .ag-cell-value {
        padding: 8px 8px 8px 0;
      }
    }
}
//   .ag-theme-balham .ag-cell, .ag-theme-balham .ag-full-width-row .ag-cell-wrapper.ag-row-group{
//         padding:0px;
// 
.QB-dataTable.po-checkin-quantites{
    .ag-cell-wrapper {
    align-items: flex-start;
}
}

.scan-table{
  .ag-header-row {
    background-color: ${(props) => (props.headerBg ? props.headerBg : '')};
  }
  &.received-table{
    margin-top: 15px;
    border:none;
    border-radius: 8px;
  }
   .ag-cell-label-container{
    white-space:pre-wrap;
   }
   .ag-cell{
    padding-left:12px;
    padding-right:12px;
    .ag-cell-value{
      padding:12px 0px;
    }
   }
   .ag-header{
    border-bottom:1px solid #78909c4a;
  }
  .ag-header-cell {
    &::after{
      display:none;
    }
  }
  &.border-bottom-none{
    .ag-row{
      border-bottom: 1px solid transparent;
    }
  }
  } 

.ag-cell:has(.check-in){
  border: 2px solid #FF9900;
  border-right:2px solid #FF9900!important; 
  border-radius:8px;
}

`
export { GridWrapper };
