import React from 'react'
import { useDispatch } from 'react-redux';
import { PaymentWrapper } from './style'
import Header from '../header/header'
import Button from "../uiComponent/button/index"
import { Row, Col } from 'react-bootstrap'
import { BiLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from 'react-router-dom'
import { AiOutlineCheck } from 'react-icons/ai'
import { setState } from '../../slices/store';
import Vbc from '../../assets/images/newImages/plans_bg.png'
import { BASIC_PLAN, PREMIUM_PLAN } from '../../constants/onBoarding';
const SelectPlans = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handlePlanId = (planId) => {
    dispatch(setState({ field: 'planId', value: planId }));
    navigate("/onboarding/add-payment");
  }

  return (
    <PaymentWrapper>
      <Header login={true} />
      <div className="select_paln" style={{
        backgroundImage: `url(${Vbc})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%"
      }}>
        <div className="arrow-box d-flex grid-column-gap-10">
          <BiLeftArrowAlt className="cursor-pointer color-primary" onClick={() => navigate(-1)} />
          <h1 className="color-secondary mb-0">Select Plan</h1>
        </div>
        <Row className="justify-content-center" >
          <Col lg={4} md={6}>
            <div className="plans">
              <div className="upper-header">
                <span className="fs-14 color-secondary">Basic</span>
                <div className="type">
                  <h1 className="color-secondary mb-0">Free</h1>
                  <h6 className="fs-14 color-secondary">Basic features to get started</h6>
                </div>
              </div>
              <div className="feature">
                <h6 className="fs-16 color-primary fw-bold">Features</h6>
                <ul className="mh-592">
                  {BASIC_PLAN.map((list, key) => {
                    return (
                      <li key={key}>
                        <div className={list.caption ? "d-flex  grid-column-gap-10 mb-3 enable" : "d-flex  grid-column-gap-10 mb-3 disable"}>
                          <AiOutlineCheck />
                          <div className="d-flex flex-column">
                            <span className="fs-14">{list.title}</span>
                            <span className="fs-12 caption">{list.caption}</span>
                          </div>
                        </div>
                      </li>
                    )
                  })}

                </ul>
                <div>
                  <Button text="Subscribe" className="primary w-100" onClick={() => handlePlanId(1)} />
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="plans premium">
              <div className="upper-header premium_header">
                <span className="fs-14 color-secondary">Premium</span>
                <div className="type">
                  <h1 className="color-secondary mb-0 "><sub>$</sub>99.00<sub>/month</sub></h1>
                  <h6 className="fs-14 color-secondary">premium features to get started</h6>
                </div>
              </div>
              <div className="feature">
                <h6 className="fs-14 color-secondary fw-bold">Note: UP TO 100k Monthly Sales. Over 100k Monthly Sales, your subscription will jump to $299 per month.</h6>
                <h6 className="fs-16 color-primary fw-bold">Features</h6>
                <ul className="mh-592">
                  {PREMIUM_PLAN.map((list, key) => {
                    return (
                      <li key={key}>
                        <div className="d-flex  grid-column-gap-10 mb-3 enable">
                          <AiOutlineCheck />
                          <div className="d-flex flex-column">
                            <span className="fs-14">{list.title}</span>
                            <span className="fs-12 caption">{list.caption}</span>
                          </div>
                        </div>
                      </li>
                    )
                  })}

                </ul>
                <div>
                  <Button className="primary w-100" text="Subscribe" onClick={() => handlePlanId(2)} />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </PaymentWrapper>
  )
}

export default SelectPlans
