import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  purchaseOrders: [],
  fetchingForVendors: false,
  fetchingForBoxData: false,
  total_records: 0,
  vendors: [],
  filters: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  poItems: {
    fetching: true,
    pendingPos: [],
    pendingPoIndex: {},
    newPendingPo: {},
    fetchingLabels: false,
    fetchingStatus: false,
    labelsStatus: '',
    labels_error_message: ''
  },
  createBulkShipment: {
    fetching: false,
    shipplan: [],
    pendingPo: {}
  },
  addIntoExistingShipment: {
    fetching: false,
    invalidItems: []
  },
  shipmentItems: {
    fetching: false,
    inboundShipmentItems: []
  },
  editCaseQty: {
    fetching: false
  },
  boxes: [],
  fetchingForBoxes: false,
  boxesPagination: {
    boxNumber: 1,
    boxSize: 10,
  },
  boxGridData: {
    current_box_id: '',
    current_box_data: [],
    current_box_package: {}
  },
  defaultSelectedBox: 0,
  pendingPoItemsHash: {
    switchValue: false,
    labelling_type: '',
    noOfBoxes: 1
  }
};

const pendingPurchaseOrders = (state = cloneDeep(initialState), action) => {
  switch (action.type) {

    case 'SET_SWITCH_VALUE':
    case 'SET_LABEL_TYPE': {
      return {
        ...state,
        pendingPoItemsHash: {
          ...state.pendingPoItemsHash,
          ...action.payload
        }
      }
    }

    case 'UPDATE_NO_OF_BOXES': {
      return {
        ...state,
        pendingPoItemsHash: {
          ...state.pendingPoItemsHash,
          ...action.payload
        }
      }
    }

    case 'CLEAR_BOX_DATA': {
      return {
        ...state,
        boxGridData: {
          ...state.boxGridData,
          current_box_data: []
        }
      }
    }

    case 'SET_FILTERS_PENDING_PURCHASE_ORDERS':
    case 'SET_SORT_OPTIONS_PENDING_PURCHASE_ORDERS':
    case 'ALL_BOXES_FAILED': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'CLEAR_CURRENT_DATA': {
      return {
        ...state,
        boxGridData: {
          ...state.boxGridData.current_box_id,
          current_box_data: []
        }
      }
    }
    case 'SET_CURRENT_BOX_ID': {
      return {
        ...state,
        defaultSelectedBox: action.payload.box_id
      }
    }

    case 'ALL_BOXES_SUCCESS': {
      return {
        ...state,
        ...action.payload,
      }
    }

    case 'ADD_NEW_BUTTON_REQUEST':
    case 'DELETE_BOX_REQUEST':
    case 'UPDATE_SHIPMENT_BOX_DIMENSIONS_REQUEST': {
      return {
        ...state,
        fetchingForBoxes: true
      }
    }
    case 'ADD_NEW_BUTTON_SUCCESS':
    case 'ADD_NEW_BUTTON_FAILED':
    case 'DELETE_BOX_SUCCESS':
    case 'DELETE_BOX_FAILED':
    case 'UPDATE_SHIPMENT_BOX_DIMENSIONS_FAILED': {
      return {
        ...state,
        fetchingForBoxes: false
      }
    }
    case 'UPDATE_SHIPMENT_BOX_DIMENSIONS_SUCCESS': {
      return {
        ...state,
        fetchingForBoxes: false,
        boxGridData: {
          ...state.boxGridData,
          current_box_package: {
            ...state.boxGridData.current_box_package,
            ...action.payload.current_box_package,
          }
        }
      }
    }
    case 'GET_VENDORS_FOR_PENDING_PURCHASE_ORDERS_REQUEST': {
      return {
        ...state,
        fetchingForVendors: true
      };
    }
    case 'GET_VENDORS_FOR_PENDING_PURCHASE_ORDERS_SUCCESS': {
      return {
        ...state,
        fetchingForVendors: false,
        vendors: action.payload ? [...action.payload] : []
      };
    }
    case 'GET_VENDORS_FOR_PENDING_PURCHASE_ORDERS_FAILED': {
      return {
        ...state,
        fetchingForVendors: false
      };
    }
    case 'GET_BASE_64_IMAGE_FOR_PENDING_PO_REQUEST':
    case 'GET_PENDING_PURCHASE_ORDERS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_BASE_64_IMAGE_FOR_PENDING_PO_SUCCESS':
    case 'GET_BASE_64_IMAGE_FOR_PENDING_PO_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_PENDING_PURCHASE_ORDERS_SUCCESS': {
      const { total_records, pending_po_indices } = action.payload;
      return {
        ...state,
        fetching: false,
        total_records,
        purchaseOrders: pending_po_indices
      };
    }
    case 'SET_PAGINATION_PENDING_PURCHASE_ORDERS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    case 'UPDATE_SHIPMENT_QTY_FOR_PENDING_PURCHASE_ORDERS_REQUEST':
    case 'GET_SHIPPLAN_FOR_PENDING_PURCHASE_ORDERS_REQUEST':
    case 'CREATE_SHIPMENT_FOR_PENDING_PURCHASE_ORDERS_REQUEST': {
      return {
        ...state,
        createBulkShipment: {
          ...state.createBulkShipment,
          fetching: true
        }
      }
    }
    case 'UPDATE_SHIPMENT_QTY_FOR_PENDING_PURCHASE_ORDERS_FAILED':
    case 'UPDATE_SHIPMENT_QTY_FOR_PENDING_PURCHASE_ORDERS_SUCCESS':
    case 'GET_SHIPPLAN_FOR_PENDING_PURCHASE_ORDERS_FAILED':
    case 'GET_SHIPPLAN_FOR_PENDING_PURCHASE_ORDERS_SUCCESS':
    case 'CREATE_SHIPMENT_FOR_PENDING_PURCHASE_ORDERS_FAILED':
    case 'CREATE_SHIPMENT_FOR_PENDING_PURCHASE_ORDERS_SUCCESS': {
      return {
        ...state,
        createBulkShipment: {
          ...state.createBulkShipment,
          fetching: false,
          ...action.payload
        }
      }
    }
    case 'ADD_REMAINING_PO_TO_SHIPMENT_FOR_PENDING_PURCHASE_ORDERS_REQUEST':
    case 'ADD_INTO_EXISTING_SHIPMENT_FOR_PENDING_PURCHASE_ORDERS_REQUEST': {
      return {
        ...state,
        addIntoExistingShipment: {
          ...state.addIntoExistingShipment,
          fetching: true
        }
      }
    }
    case 'ADD_REMAINING_PO_TO_SHIPMENT_FOR_PENDING_PURCHASE_ORDERS_FAILED':
    case 'ADD_REMAINING_PO_TO_SHIPMENT_FOR_PENDING_PURCHASE_ORDERS_SUCCESS':
    case 'ADD_INTO_EXISTING_SHIPMENT_FOR_PENDING_PURCHASE_ORDERS_FAILED':
    case 'ADD_INTO_EXISTING_SHIPMENT_FOR_PENDING_PURCHASE_ORDERS_SUCCESS': {
      return {
        ...state,
        addIntoExistingShipment: {
          ...state.addIntoExistingShipment,
          fetching: false,
          ...action.payload
        }
      }
    }
    case 'DELETE_GATED_FOR_PENDING_PURCHASE_ORDERS_REQUEST':
    case 'GET_PO_INDEX_FOR_PENDING_PURCHASE_ORDERS_REQUEST':
    case 'SUBMIT_BOX_CONTENT_REQUEST': {
      return {
        ...state,
        poItems: {
          ...state.poItems,
          fetching: true
        }
      }
    }
    case 'DELETE_GATED_FOR_PENDING_PURCHASE_ORDERS_FAILED':
    case 'DELETE_GATED_FOR_PENDING_PURCHASE_ORDERS_SUCCESS':
    case 'GET_PO_INDEX_FOR_PENDING_PURCHASE_ORDERS_FAILED':
    case 'GET_PO_INDEX_FOR_PENDING_PURCHASE_ORDERS_SUCCESS':
    case 'SUBMIT_BOX_CONTENT_SUCCESS':
    case 'SUBMIT_BOX_CONTENT_FAILED': {
      const { pendingPoIndex } = action.payload || {}
      return {
        ...state,
        poItems: {
          ...state.poItems,
          fetching: false,
          ...action.payload
        },
        pendingPoItemsHash: {
          ...state.pendingPoItemsHash,
          switchValue: pendingPoIndex?.case_packed
        }

      }
    }
    case 'SEND_SHIPMENT_LABELS_FOR_PENDING_PURCHASE_ORDERS_REQUEST': {
      return {
        ...state,
        poItems: {
          ...state.poItems,
          fetchingLabels: true
        }
      };
    }
    case 'SEND_SHIPMENT_LABELS_FOR_PENDING_PURCHASE_ORDERS_FAILED':
    case 'SEND_SHIPMENT_LABELS_FOR_PENDING_PURCHASE_ORDERS_SUCCESS': {
      return {
        ...state,
        poItems: {
          ...state.poItems,
          fetchingLabels: false
        }
      };
    }
    case 'GET_LABEL_STATUS_FOR_PENDING_PURCHASE_ORDERS_REQUEST': {
      return {
        ...state,
        poItems: {
          ...state.poItems,
          fetchingStatus: true
        }
      }
    }
    case 'GET_LABEL_STATUS_FOR_PENDING_PURCHASE_ORDERS_SUCCESS':
    case 'GET_LABEL_STATUS_FOR_PENDING_PURCHASE_ORDERS_FAILED': {
      return {
        ...state,
        poItems: {
          ...state.poItems,
          fetchingStatus: false,
          ...action.payload
        }
      }
    }
    case 'GET_SHIPMENT_ITEMS_FOR_PENDING_PURCHASE_ITEMS_REQUEST': {
      return {
        ...state,
        shipmentItems: {
          ...state.shipmentItems,
          fetching: true
        }
      };
    }
    case 'GET_SHIPMENT_ITEMS_FOR_PENDING_PURCHASE_ITEMS_FAILED':
    case 'GET_SHIPMENT_ITEMS_FOR_PENDING_PURCHASE_ITEMS_SUCCESS': {
      return {
        ...state,
        shipmentItems: {
          ...state.shipmentItems,
          fetching: false,
          ...action.payload
        }
      };
    }
    case 'UPDATE_CASE_QTY_FOR_PENDING_PURCHASE_ORDER_REQUEST': {
      return {
        ...state,
        editCaseQty: {
          ...state.editCaseQty,
          fetching: true
        }
      }
    }
    case 'UPDATE_CASE_QTY_FOR_PENDING_PURCHASE_ORDER_FAILED':
    case 'UPDATE_CASE_QTY_FOR_PENDING_PURCHASE_ORDER_SUCCESS': {
      return {
        ...state,
        editCaseQty: {
          ...state.editCaseQty,
          fetching: false
        }
      }
    }
    case 'UPDATE_PO_NAME_REQUEST': {
      return {
        ...state,
        fetching: true
      }
    }
    case 'UPDATE_PO_NAME_SUCCESS':
    case 'UPDATE_PO_NAME_FAILED': {
      return {
        ...state,
        ...action.payload,
        fetching: false
      }
    }

    case 'GET_BOX_ITEMS_REQUEST': {
      return {
        ...state,
        fetchingForBoxData: true
      }
    }

    case 'GET_BOX_ITEMS_SUCCESS': {
      return {
        ...state,
        fetchingForBoxData: false,
        boxGridData: {
          ...action.payload
        }
      }
    }
    case 'GET_BOX_ITEMS_FAILED': {
      return {
        ...state,
        fetchingForBoxData: false
      }
    }

    case 'UPDATE_QUANTITY_REQUEST': {
      return {
        ...state,
        fetching: true,
        fetchingForBoxData: true
      }
    }
    case 'UPDATE_QUANTITY_SUCCESS':
    case 'UPDATE_QUANTITY_FAILED': {
      return {
        ...state,
        fetchingForBoxData: false
      }
    }

    case 'GET_PENDING_PURCHASE_ORDERS_FAILED':
    default:
      return state;
  }
};

export default pendingPurchaseOrders;
