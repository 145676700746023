import Styled from "styled-components";

const PaginationWrapper = Styled.div`
 background-color:${({ theme }) => theme["aqua_haze"]};
 padding:7.5px 16px;
 font-size:${({ theme }) => theme["medium-font-size "]};
 border-top-left-radius: ${({ theme }) => theme["border-radius-base"]};
 border-top-right-radius: ${({ theme }) => theme["border-radius-base"]};
 .reload-btn{
  margin-left:10px;
 }
      .item-per-page{
        display: flex;
        align-items:center;
        grid-column-gap: 9px;
        .form-select-custom{
          position:relative;
          &::after{
                content:"";
                position: absolute;
                right: -10px;
                height: 29px;
                width:1px;
                top:4px;
                background: #C4C4C4;
            }
        }
        .form-select{
            width: 74px;
            border: none;
            background-color: transparent;
            font-size:${({ theme }) => theme["medium-font-size"]};
            background-image:url("https://sale-support-logos.s3.us-west-1.amazonaws.com/dropdown-icon.svg") ;
        }
        span{
            color: ${({ theme }) => theme["comet-color"]};
            &.per-page{
              margin-left: 7px;
            }
        }
        position: relative;
              
      }
      .total-items{
            margin-left: 26px;
    letter-spacing: 1px;
}
      .total-pages{
            margin-right: 17px;
    letter-spacing: 1px;
        .line-height{
              background: #C4C4C4;
              width:1px;
              height:24px;
              &.mr-12{
                margin-right:12px;
              }
            }
        .form-select{
            width: 65px;
            border: none;
            background-color: transparent;
            font-size:${({ theme }) => theme["medium-font-size"]};
                background-image:url("https://sale-support-logos.s3.us-west-1.amazonaws.com/dropdown-icon.svg") ;
        }
             span{
            color: ${({ theme }) => theme["comet-color"]};
            .pages{
              margin-right:24px;
            }
        }


            }
            .arrows{
             display: flex;
            grid-column-gap: 14px;
            margin-top:7px;
            .line-height{
              background: #C4C4C4;
              width:1px;
              height:24px;
            }
            }
`;

export { PaginationWrapper };
