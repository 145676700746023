import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  invoices: [],
  total: 0,
  filters: [],
  fetchingUsers: false,
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  users: [],
};

const purchasingInvoice = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'SET_FILTERS_PURCHASING_INVOICES': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_SORT_OPTIONS_PURCHASING_INVOICES': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_PURCHASING_INVOICES_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_PURCHASING_INVOICES_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      };
    }
    case 'GET_PURCHASING_INVOICES_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_PAGINATION_PURCHASING_INVOICES': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    default:
      return state;
  }
};

export default purchasingInvoice;
