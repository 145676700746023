import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleRejectedResponse } from './utils';
import axios from '../../axios_config';
import { notification } from 'antd';
import { findIndex } from 'lodash';

export const FetchDistroCustomers = createAsyncThunk(
  '/list_distro_users',
  async (data, { getState, rejectWithValue }) => {
    try {
      const { filters, pagination } = getState().distroCustomers;
      const { pageNumber, pageSize } = pagination || {};

      const response = await axios({
        method: 'GET',
        url: '/api/v2/purchase_orders/list_distro_users',
        params: {
          filters_hash: {
            filters: filters || []
          },
          page: pageNumber || 1,
          per_page: pageSize || 25,
          without_pagination: data
        }
      })
      return {
        ...response,
        data: { ...response.data, without_pagination: data }
      };
    }
    catch (err) {
      return handleRejectedResponse(err, 'Fetch Distro Customers', rejectWithValue);
    }
  }
)

export const FetchDistroCustomersStats = createAsyncThunk(
  '/stats_for_distro_customers',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'GET',
        url: '/api/v2/purchase_orders/stats_for_distro_customers'
      })
      return response;
    }
    catch (err) {
      return handleRejectedResponse(err, 'Fetch Distro Customers Stats', rejectWithValue);
    }
  }
)

export const UpdateDistroStatus = createAsyncThunk(
  '/update_distro_status',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        '/api/v2/purchase_orders/update_distro_status',
        data
      )
      return response;
    }
    catch (err) {
      return handleRejectedResponse(err, 'Update Distro Status', rejectWithValue);
    }
  }
)


const distroCustomer = createSlice({
  name: 'distroCustomers',
  initialState: {
    distroCustomersList: [],
    fetchingDistroCustomers: false,
    filters: [],
    pagination: {
      pageNumber: 1,
      pageSize: 25
    },
    total_records: 0,
    updatingDistroStatus: false,
    stats: {},
    fetchingDistroCustomersStats: false,
  },
  reducers: {
    setFilter(state, { payload: { field, value } }) {
      const { filters } = state;

      const filterIndex = findIndex(filters, { field });
      if (filterIndex === -1) {
        if (value) {
          filters.push({
            field,
            value
          });
        }
      } else if (value) {
        filters[filterIndex].value = value;
      } else {
        filters.splice(filterIndex, 1);
      }
    },
    setPageNumber(state, { payload: pageNumber }) {
      state.pagination.pageNumber = pageNumber;
    },
    setPageSize(state, { payload: pageSize }) {
      state.pagination.pageSize = pageSize;
    }
  },
  extraReducers: {
    [FetchDistroCustomers.pending]: (state, action) => {
      return {
        ...state,
        fetchingDistroCustomers: true
      }
    },
    [FetchDistroCustomers.fulfilled]: (state, action) => {
      const { users, total_records, without_pagination } = action.payload.data || {};

      const updatedState = {
        ...state,
        fetchingDistroCustomers: false,
      }

      if (!without_pagination){
        updatedState.distroCustomersList = users;
        updatedState.total_records = total_records;
      }

      return updatedState;
    },
    [FetchDistroCustomers.rejected]: (state, action) => {
      return {
        ...state,
        fetchingDistroCustomers: false
      }
    },
    [UpdateDistroStatus.pending]: (state, action) => {
      return {
        ...state,
        updatingDistroStatus: true
      }
    },
    [UpdateDistroStatus.fulfilled]: (state, action) => {
      return {
        ...state,
        updatingDistroStatus: false
      }
    },
    [UpdateDistroStatus.rejected]: (state, action) => {
      return {
        ...state,
        updatingDistroStatus: false
      }
    },
    [FetchDistroCustomersStats.pending]: (state, action) => {
      return {
        ...state,
        fetchingDistroCustomersStats: true
      }
    },
    [FetchDistroCustomersStats.fulfilled]: (state, action) => {
      const { stats } = action.payload.data || {};

      return {
        ...state,
        fetchingDistroCustomersStats: false,
        stats
      }
    },
    [FetchDistroCustomersStats.rejected]: (state, action) => {
      return {
        ...state,
        fetchingDistroCustomersStats: false
      }
    },
  }
});

const { reducer, actions } = distroCustomer;
export const { setFilter, setPageNumber, setPageSize } = actions;
export default reducer;
