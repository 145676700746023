import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { findIndex } from "lodash";
import axios, { fetch } from "../../axios_config";
import { notification } from "antd";
import { handleRejectedResponse } from './utils';

export const GetWarehouseCounts = createAsyncThunk(
  "/get-warehouse-counts",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token, id } = state.auth?.currentUser;
      const response = await fetch({
        method: "GET",
        url: `${process.env.AO_API_URL}/order/get-fulfillmets-counts?userId=${id}`,
        headers: {
          Authorization: `Bearer ${auto_ordering_auth_token}`,
        },
      });
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const GetAllFulfillments = createAsyncThunk(
  "/get-all-fulfillments",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token, id } = state.auth?.currentUser;
      const { pagination, filters, sorting } = state.warehouse;
      const response = await fetch({
        method: "GET",
        url: `${process.env.AO_API_URL}/order/fulfillments/all-orders/?userId=${id}&pagenum=${pagination.page_num}&perpage=${pagination.per_page}`,
        params: {
          filters: JSON.stringify(filters || []),
          sorting: JSON.stringify(sorting || []),
        },
        headers: {
          Authorization: `Bearer ${auto_ordering_auth_token}`,
        },
      });
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const ExportShippedOrders = createAsyncThunk(
  "/export-shipped-orders",
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const { filters } = state.warehouse;
      const response = await fetch({
        method: "GET",
        url: `${process.env.AO_API_URL}/order/export-shipped-orders/?userId=${id}`,
        params: {
          filters: JSON.stringify(filters || []),
        },
        headers: {
          Authorization: `Bearer ${auto_ordering_auth_token}`,
        },
      });
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const GetStorageOrders = createAsyncThunk(
  "/get-storage-orders",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token, id } = state.auth?.currentUser;
      const { pagination, filters, sorting } = state.warehouse;
      const response = await fetch({
        method: "GET",
        url: `${process.env.AO_API_URL}/order/fulfillments/storage-orders/?userId=${id}&pagenum=${pagination.page_num}&perpage=${pagination.per_page}`,
        params: {
          filters: JSON.stringify(filters || []),
          sorting: JSON.stringify(sorting || []),
        },
        headers: {
          Authorization: `Bearer ${auto_ordering_auth_token}`,
        },
      });
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const GetSingleFulfillment = createAsyncThunk(
  "/get-single-fulfillment",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token, id } = state.auth?.currentUser;
      const response = await fetch({
        method: "GET",
        url: `${process.env.AO_API_URL}/order/fulfillments/single-order/${data.orderId}?userId=${id}`,
        headers: {
          Authorization: `Bearer ${auto_ordering_auth_token}`,
        },
      });
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const GetDimensions = createAsyncThunk(
  "/get-dimensions",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await fetch({
        method: "POST",
        url: `${process.env.AO_API_URL}/order/fulfillments/get-dimensions`,
        headers: {
          Authorization: `Bearer ${auto_ordering_auth_token}`,
        },
        data,
      });
      return response;
    } catch (err) {
      const { response } = err || {};
      const { data } = response || {};
      const { message } = data || {};
      notification.error({
        message: "GET DIMENSIONS",
        description: message || "Failed To Get Dimensions",
        top: 65,
      });
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const UpdateDimensions = createAsyncThunk(
  "/update-dimensions",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await fetch({
        method: "POST",
        url: `${process.env.AO_API_URL}/order/fulfillments/update-dimensions`,
        headers: {
          Authorization: `Bearer ${auto_ordering_auth_token}`,
        },
        data,
      });
      return response;
    } catch (err) {
      const { response } = err || {};
      const { data } = response || {};
      const { message } = data || {};
      notification.error({
        message: "UPDATE DIMENSIONS",
        description: message || "Failed To Update Dimensions",
        top: 65,
      });
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const GenerateLabel = createAsyncThunk(
  "/generate-label",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await fetch({
        method: "POST",
        url: `${process.env.AO_API_URL}/order/fulfillments/generate-label?userId=${data.warehouseUserId}`,
        headers: {
          Authorization: `Bearer ${auto_ordering_auth_token}`,
        },
        data,
      });
      notification.success({
        message: "GENERATE LABEL",
        description: "Label Generated Successfully!",
        top: 65,
      });
      return response;
    } catch (err) {
      const { response } = err || {};
      const { data } = response || {};
      const { message } = data || {};
      notification.error({
        message: "GENERATE LABEL",
        description: message || "Failed To Generate Label",
        top: 65,
      });
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const VoidLabel = createAsyncThunk(
  "/generate-label",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await fetch({
        method: "POST",
        url: `${process.env.AO_API_URL}/order/fulfillments/void-label`,
        headers: {
          Authorization: `Bearer ${auto_ordering_auth_token}`,
        },
        data,
      });
      notification.success({
        message: "VOID LABEL",
        description: "Label Voided Successfully!",
        top: 65,
      });
      return response;
    } catch (err) {
      const { response } = err || {};
      const { data } = response || {};
      const { message } = data || {};
      notification.error({
        message: "VOID LABEL",
        description: message || "Failed To Void Label",
        top: 65,
      });
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const VoidLabelNew = createAsyncThunk(
  "/void-label-new",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await fetch({
        method: "POST",
        url: `${process.env.AO_API_URL}/order/fulfillments/void-label-new`,
        headers: {
          Authorization: `Bearer ${auto_ordering_auth_token}`,
        },
        data,
      });
      notification.success({
        message: "VOID LABEL",
        description: "Label Voided Successfully!",
        top: 65,
      });
      return response;
    } catch (err) {
      const { response } = err || {};
      const { data } = response || {};
      const { message } = data || {};
      notification.error({
        message: "VOID LABEL",
        description: message || "Failed To Void Label",
        top: 65,
      });
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const GetRates = createAsyncThunk(
  "/get-rates",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await fetch({
        method: "POST",
        url: `${process.env.AO_API_URL}/order/fulfillments/get-shipping-rates?userId=${data.warehouseUserId}`,
        headers: {
          Authorization: `Bearer ${auto_ordering_auth_token}`,
        },
        data,
      });
      return response;
    } catch (err) {
      const { response } = err || {};
      const { data } = response || {};
      const { message } = data || {};
      notification.error({
        message: "GET SHIPPING RATES",
        description: message || "Failed To Get Shipping Rates",
        top: 65,
      });
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const GetStrandedOrders = createAsyncThunk(
  "/get-stranded-orders",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token, id } = state.auth?.currentUser;
      const { pagination, filters } = state.warehouse;
      const response = await fetch({
        method: "GET",
        url: `${process.env.AO_API_URL}/order/stranded-orders/?userId=${id}&pagenum=${pagination.page_num}&perpage=${pagination.per_page}`,
        params: {
          filters: JSON.stringify(filters || []),
        },
        headers: {
          Authorization: `Bearer ${auto_ordering_auth_token}`,
        },
      });
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const MarkStranded = createAsyncThunk(
  "/mark-stranded",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token, id } = state.auth?.currentUser;
      const response = await fetch({
        method: "POST",
        url: `${process.env.AO_API_URL}/order/mark-stranded?userId=${id}`,
        headers: {
          Authorization: `Bearer ${auto_ordering_auth_token}`,
        },
        data,
      });
      return response;
    } catch (err) {
      const { response } = err || {};
      const { data, status } = response || {};
      const { message } = data || {};
      notification.error({
        message: "MARK STRANDED",
        description: message || "Failed To Mark Stranded",
        top: 65,
      });
      if (response) {
        return rejectWithValue({
          err: response,
          status: status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const AddItemToStaging = createAsyncThunk(
  '/add-item-to-staging',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: "POST",
        url: '/api/v2/staging_items/bulk_create',
        data
      });
      notification.success({
        message: "Add Items to Staging",
        description: "Items added to Staging Successfully!",
        top: 65,
      });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Add Item to Staging', rejectWithValue);
    }
  }
);

export const GetScannedItems = createAsyncThunk(
  '/get-scanned-items',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `/api/v2/staging_items/item_locations`,
        data
      });
      return response.data;
    } catch (err) {
      if (err?.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const AddItemsToPickList = createAsyncThunk(
  '/add-items-to-pick-list',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: "POST",
        url: `/api/v2/staging_items/bulk_add_to_pickup`,
        data
      });
      notification.success({
        message: "Add Items to Pick List",
        description: "Items added to Pick List Successfully!",
        top: 65,
      });
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Add Items to Pick List', rejectWithValue);
    }
  }
);

export const UpdateStagingItems = createAsyncThunk(
  '/update-staging-items',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'PATCH',
        url: `${process.env.AO_API_URL}/order/update-staging-items`,
        data,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetAllLocations = createAsyncThunk(
  '/storages/dropdown',
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios({
        method: 'GET',
        url: `/api/v2/storages/dropdown `,
        headers: {
          'Authorization': `Bearer ${authToken}`
        }
      });
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Fetch All Locations', rejectWithValue);
    }
  }
);

export const CreateNewLocation = createAsyncThunk(
  '/storages',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios({
        method: 'POST',
        url: `/api/v2/storages `,
        data,
        headers: {
          'Authorization': `Bearer ${authToken}`
        }
      });
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Create New Location', rejectWithValue);
    }
  }
);

export const MarkShipped = createAsyncThunk(
  '/mark-shipped',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'POST',
        url: `${process.env.AO_API_URL}/order/mark-shipped`,
        data,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetWarehousePayment = createAsyncThunk(
  '/get-warehouse-payment',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token, id } = state.auth?.currentUser;
      const response = await axios({
        method: 'GET',
        url: `${process.env.AO_API_URL}/order/get-warehouse-payment?ssId=${id}`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetWarehouseInvoices = createAsyncThunk(
  '/get-warehouse-invoices',
  async (ssUId, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const { pagination, filters } = state.warehouse;
      const response = await axios({
        method: 'GET',
        url: `${process.env.AO_API_URL}/order/get-warehouse-invoices`,
        params: {
          ssUId: ssUId || '',
          pagenum: pagination.page_num,
          perpage: pagination.per_page,
          filters: JSON.stringify(filters || [])
        },
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetWarehouseInvoiceItems = createAsyncThunk(
  "/get-warehouse-invoice-items",
  async (invoiceId, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const { pagination } = state.warehouse.warehouseInvoiceItems;
      const response = await fetch({
        method: "GET",
        url: `${process.env.AO_API_URL}/order/get-warehouse-invoice-items`,
        params: {
          invoiceId,
          pagenum: pagination.page_num,
          perpage: pagination.per_page
        },
        headers: {
          Authorization: `Bearer ${auto_ordering_auth_token}`,
        },
      });
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

export const PayWarehouseInvoice = createAsyncThunk(
  '/pay-warehouse',
  async (invoiceId, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await axios({
        method: 'GET',
        url: `${process.env.AO_API_URL}/order/pay-warehouse-invoice?invoiceId=${invoiceId}`,
        headers: {
          'Authorization': `Bearer ${auto_ordering_auth_token}`
        }
      })
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status
        });
      }
      return rejectWithValue({
        err: 'Network Error'
      });
    }
  }
);

export const GetWarehouse = createAsyncThunk(
  '/get-warehouse',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token, id } = state.auth?.currentUser;
      const response = await fetch({
        method: "POST",
        url: `${process.env.AO_API_URL}/order/get-warehouse?userId=${id}`,
        headers: {
          Authorization: `Bearer ${auto_ordering_auth_token}`,
        },
        data,
      });
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Warehouse', rejectWithValue);
    }
  }
);

export const UpdateWarehouseStatus = createAsyncThunk(
  '/update-warehouse-status',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token, id } = state.auth?.currentUser;
      const response = await fetch({
        method: "POST",
        url: `${process.env.AO_API_URL}/order/update-warehouse-status`,
        headers: {
          Authorization: `Bearer ${auto_ordering_auth_token}`,
        },
        data,
      });
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Warehouse Status', rejectWithValue);
    }
  }
);

export const ExportWarehouseInvoiceItems = createAsyncThunk(
  "/export-warehouse-invoice-items",
  async (invoiceId, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { auto_ordering_auth_token } = state.auth?.currentUser;
      const response = await fetch({
        method: "GET",
        url: `${process.env.AO_API_URL}/order/export-warehouse-invoice-items`,
        params: {
          invoiceId
        },
        headers: {
          Authorization: `Bearer ${auto_ordering_auth_token}`,
        },
      });
      return response;
    } catch (err) {
      if (err.response) {
        return rejectWithValue({
          err: err.response,
          status: err.response.status,
        });
      }
      return rejectWithValue({
        err: "Network Error",
      });
    }
  }
);

const warehouse = createSlice({
  name: "warehouse",
  initialState: {
    loading: false,
    pagination: {
      page_num: 1,
      per_page: 25,
    },
    filters: [],
    sorting: [],
    warehouseCounts: {},
    singleOrder: {},
    processingInfo: {},
    dimensions: {},
    orderRates: {},
    labelData: {},
    totalCount: 0,
    fulfillments: {
      list: [],
    },
    strandedOrders: {
      list: [],
    },
    scannedItems: [],
    warehousePayment: {},
    warehouseInvoices: [],
    warehouseInvoiceItems: {
      loading: false,
      list: [],
      totalCount: 0,
      pagination: {
        page_num: 1,
        per_page: 25,
      }
    },
    warehouse: {}
  },
  reducers: {
    setState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    clearState(state, action) {
      return {
        loading: false,
        pagination: {
          page_num: 1,
          per_page: 25,
        },
        filters: [],
        sorting: [],
        warehouseCounts: {},
        singleOrder: {},
        dimensions: {},
        orderRates: {},
        labelData: {},
        totalCount: 0,
        fulfillments: {
          list: [],
        },
        strandedOrders: {
          list: [],
        },
        scannedItems: [],
        warehousePayment: {},
        warehouseInvoices: [],
        warehouseInvoiceItems: {
          loading: false,
          list: [],
          totalCount: 0,
          pagination: {
            page_num: 1,
            per_page: 25,
          }
        },
        warehouse: {}
      };
    },
    addFilters(state, { payload: data }) {
      const filters = [...state.filters];
      const { field, operator, value } = data;
      const filterIndex = findIndex(filters, { field });
      if (filterIndex !== -1) {
        filters[filterIndex] = { field, operator, value };
      } else {
        filters.push({ field, operator, value });
      }
      state["filters"] = filters;
    },
    addSortings(state, { payload: data }) {
      const sorting = [...state.sorting];
      const { column, sort } = data;
      const index = findIndex(sorting, { column });
      if (index !== -1) {
        sorting[index] = data;
      } else {
        sorting.push(data);
      }
      state["sorting"] = sorting;
    },
    setPageNum(state, { payload: data }) {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...data,
        },
      };
    },
    setPerPage(state, { payload: data }) {
      return {
        ...state,
        pagination: {
          page_num: 1,
          ...data,
        },
      };
    },
    setInvoicesPageNum(state, { payload }) {
      const { hash, data } = payload;
      return {
        ...state,
        pagination: {
          page_num: 1,
          ...data,
        },
        [hash]: {
          ...state[hash],
          pagination: {
            ...state[hash].pagination,
            ...data,
          }
        }
      };
    },
    setInvoicesPerPage(state, { payload }) {
      const { hash, data } = payload;
      return {
        ...state,
        pagination: {
          page_num: 1,
          ...data,
        },
        [hash]: {
          ...state[hash],
          pagination: {
            page_num: 1,
            ...data,
          }
        }
      };
    },
  },
  extraReducers: {
    [GetWarehouseCounts.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [GetWarehouseCounts.fulfilled]: (state, action) => {
      const { counts } = action?.payload?.data || {};
      return {
        ...state,
        loading: false,
        warehouseCounts: counts,
      };
    },
    [GetWarehouseCounts.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    [GetAllFulfillments.pending]: (state, action) => ({
      ...state,
      loading: true,
      totalCount: 0,
      fulfillments: {
        list: [],
      },
    }),
    [GetAllFulfillments.fulfilled]: (state, action) => {
      const { success, list, totalCount } = action?.payload?.data || {};
      return {
        ...state,
        loading: false,
        totalCount,
        fulfillments: {
          list,
        },
      };
    },
    [GetAllFulfillments.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    [ExportShippedOrders.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [ExportShippedOrders.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      };
    },
    [ExportShippedOrders.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    [GetStorageOrders.pending]: (state, action) => ({
      ...state,
      loading: true,
      totalCount: 0,
      fulfillments: {
        list: [],
      },
    }),
    [GetStorageOrders.fulfilled]: (state, action) => {
      const { success, list, totalCount } = action?.payload?.data || {};
      return {
        ...state,
        loading: false,
        totalCount,
        fulfillments: {
          list,
        },
      };
    },
    [GetStorageOrders.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    [GetSingleFulfillment.pending]: (state, action) => ({
      ...state,
      singleOrder: {},
      processingInfo: {},
      loading: true,
    }),
    [GetSingleFulfillment.fulfilled]: (state, action) => {
      const { item, processingInfo } = action?.payload?.data || {};
      return {
        ...state,
        singleOrder: item,
        processingInfo: processingInfo,
        loading: false,
      };
    },
    [GetSingleFulfillment.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    [GetDimensions.pending]: (state, action) => ({
      ...state,
      dimensions: {},
      loading: true,
    }),
    [GetDimensions.fulfilled]: (state, action) => {
      const { dimensions } = action?.payload?.data || {};
      return {
        ...state,
        dimensions: dimensions,
        loading: false,
      };
    },
    [GetDimensions.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    [UpdateDimensions.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [UpdateDimensions.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    [UpdateDimensions.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    [GetRates.pending]: (state, action) => ({
      ...state,
      orderRates: {},
      loading: true,
    }),
    [GetRates.fulfilled]: (state, action) => {
      return {
        ...state,
        orderRates: action.payload?.data?.rates,
        loading: false,
      };
    },
    [GetRates.rejected]: (state, action) => {
      return {
        ...state,
        orderRates: {},
        loading: false,
      };
    },
    [GenerateLabel.pending]: (state, action) => ({
      ...state,
      labelData: {},
      loading: true,
    }),
    [GenerateLabel.fulfilled]: (state, action) => {
      return {
        ...state,
        labelData: action.payload?.data?.data,
        loading: false,
      };
    },
    [GenerateLabel.rejected]: (state, action) => {
      return {
        ...state,
        labelData: {},
        loading: false,
      };
    },
    [GetStrandedOrders.pending]: (state, action) => ({
      ...state,
      loading: true,
      totalCount: 0,
      strandedOrders: {
        list: [],
      },
    }),
    [GetStrandedOrders.fulfilled]: (state, action) => {
      const { success, list, totalCount } = action?.payload?.data || {};
      return {
        ...state,
        loading: false,
        totalCount,
        strandedOrders: {
          list,
        },
      };
    },
    [GetStrandedOrders.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    [MarkStranded.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [MarkStranded.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    [MarkStranded.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    [AddItemToStaging.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [AddItemToStaging.fulfilled]: (state, action) => {
      const { staging_items } = action?.payload || {};
      const oldScannedItems = [...state.scannedItems, ...staging_items];
      return {
        ...state,
        loading: false,
        scannedItems: oldScannedItems
      };
    },
    [AddItemToStaging.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
    [GetScannedItems.pending]: (state, action) => ({
      ...state,
      loading: true,
      scannedItems: []
    }),
    [GetScannedItems.fulfilled]: (state, action) => {
      const { staging_items } = action?.payload || {};
      return {
        ...state,
        loading: false,
        scannedItems: staging_items
      };
    },
    [GetScannedItems.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        scannedItems: []
      };
    },
    [GetAllLocations.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [GetAllLocations.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [GetAllLocations.rejected]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [CreateNewLocation.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [CreateNewLocation.fulfilled]: (state, action) => {
      const { message, success } = action?.payload?.data || {};
      // const notificationObj = {
      //   top: 65,
      //   message: 'Create New Location',
      //   description: message
      // };
      // success ? notification.success(notificationObj) : notification.error(notificationObj);
      return {
        ...state,
        loading: false
      }
    },
    [CreateNewLocation.rejected]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [MarkShipped.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [MarkShipped.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [MarkShipped.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetWarehousePayment.pending]: (state, action) => ({
      ...state,
      loading: true,
      warehousePayment: {}
    }),
    [GetWarehousePayment.fulfilled]: (state, action) => {
      const { warehousePayment } = action?.payload?.data || {};
      return {
        ...state,
        loading: false,
        warehousePayment
      }
    },
    [GetWarehousePayment.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetWarehouseInvoices.pending]: (state, action) => ({
      ...state,
      loading: true,
      warehouseInvoices: []
    }),
    [GetWarehouseInvoices.fulfilled]: (state, action) => {
      const { warehouseInvoices, totalCount } = action?.payload?.data || {};
      return {
        ...state,
        loading: false,
        warehouseInvoices,
        totalCount
      }
    },
    [GetWarehouseInvoices.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetWarehouseInvoiceItems.pending]: (state, action) => ({
      ...state,
      warehouseInvoiceItems: {
        ...state.warehouseInvoiceItems,
        loading: true,
        list: []
      },
    }),
    [GetWarehouseInvoiceItems.fulfilled]: (state, action) => {
      const { list, totalCount } = action?.payload?.data || {};
      return {
        ...state,
        loading: false,
        warehouseInvoiceItems: {
          ...state.warehouseInvoiceItems,
          loading: false,
          list,
          totalCount
        }
      }
    },
    [GetWarehouseInvoiceItems.rejected]: (state, action) => {
      return {
        ...state,
        warehouseInvoiceItems: {
          ...state.warehouseInvoiceItems,
          loading: false
        },
      }
    },
    [PayWarehouseInvoice.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [PayWarehouseInvoice.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [PayWarehouseInvoice.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetWarehouse.pending]: (state, action) => ({
      ...state,
      loading: true,
      warehouse: {}
    }),
    [GetWarehouse.fulfilled]: (state, action) => {
      const { warehouse } = action?.payload?.data || {};
      return {
        ...state,
        loading: false,
        warehouse
      }
    },
    [GetWarehouse.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        warehouse: {}
      }
    },
    [UpdateWarehouseStatus.pending]: (state, action) => ({
      ...state,
      loading: true
    }),
    [UpdateWarehouseStatus.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [UpdateWarehouseStatus.rejected]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [ExportWarehouseInvoiceItems.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [ExportWarehouseInvoiceItems.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      };
    },
    [ExportWarehouseInvoiceItems.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      };
    },
  },
});

const { reducer, actions } = warehouse;

export const {
  setState,
  clearStore,
  addFilters,
  addSortings,
  setPageNum,
  setPerPage,
  clearState,
  setInvoicesPageNum,
  setInvoicesPerPage
} = actions;

export default reducer;
