import React, { useState, useEffect } from "react";
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import { Input, Button, Spin, notification, Tooltip } from "antd";
import { BsTrash } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { DeliveryInformationWrapper } from "../style";
import { DatePicker } from 'antd';
import { isEqual } from 'lodash';
import { Checkbox, Box } from "@mui/material";

import BoxPack from "../../../assets/images/box-pack-icon.svg";
import Select from "../../../components/uiComponent/select/index";

import { nonPartneredCarriers } from "../../fbaInbound/constants";

import { UpdateDeliveryInfo } from "../../../slices/fba-inbound/purchase-order";

const DeliveryInfo = ({
  setOpenModal,
  poData,
  onSuccess,
  setSelectedRowId
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((store) => store.fbaInbound);

  const { id: poId, tracking_informations } = poData;

  const [addedDeliveryInfo, setAddedDeliveryInfo] = useState([]);
  const [checked, setChecked] = useState(false);
  const [newDate, setNewDate] = useState({
    carrier_name: "",
    delivery_date: "",
    tracking_info: ""
  });
  const [isChanged, setIsChanged] = useState(false);
  const [carrier, setCarrier] = useState('');

  const handleAddButtonClick = () => {
    const { tracking_info, delivery_date, carrier_name } = newDate;
    let error = '';
    if (!tracking_info || !delivery_date) {
      error = 'Please Add Tracking Info and Delivery Date';
    } else if (addedDeliveryInfo.filter(info => info.tracking_info.trim() === tracking_info.trim()).length > 0) {
      error = 'Tracking Info Already Added';
    }

    if (error) {
      notification.error({
        message: "Add New Delivery Date",
        description: error,
        top: 65
      });
      return;
    }

    setAddedDeliveryInfo([
      ...addedDeliveryInfo,
      {
        delivery_date: delivery_date.trim(),
        carrier_name: carrier_name.trim(),
        tracking_info: tracking_info.trim(),
      },
    ]);

    setNewDate({
      carrier_name: '',
      delivery_date: '',
      tracking_info: '',
    });
    setCarrier('')
  };

  useEffect(() => {
    if (poData && tracking_informations.length > 0) {
      setAddedDeliveryInfo(tracking_informations);
    }
  }, [poData]);

  useEffect(() => {
    if (tracking_informations) {
      const isChanged = !isEqual(addedDeliveryInfo, tracking_informations);
      setIsChanged(isChanged);
    }
  }, [tracking_informations, addedDeliveryInfo]);

  const handleDeleteEntry = (trackingInfo) => {
    const updatedDeliveryInfo = addedDeliveryInfo.filter(
      (info) => info.tracking_info !== trackingInfo
    );
    setAddedDeliveryInfo(updatedDeliveryInfo);
  };


  const handleConfirmButtonClick = () => {
    const { tracking_info, delivery_date, carrier_name } = newDate;
    const updatedDeliveryInfo = [...addedDeliveryInfo];
    if (tracking_info) {
      const alreadyExistTracking = updatedDeliveryInfo.find(info => info.tracking_info.trim() === tracking_info.trim()) || '';
      if (alreadyExistTracking) {
        notification.error({
          message: "Add New Delivery Date",
          description: 'Tracking Info Already Added',
          top: 65
        });
        return;
      }
      if ((tracking_info && !delivery_date) || (!tracking_info && delivery_date)) {
        notification.error({
          message: "Add New Delivery Date",
          description: 'Please Add Tracking Info and Delivery Date',
          top: 65
        });
        return;
      }
      updatedDeliveryInfo.push({
        delivery_date: delivery_date.trim(),
        carrier_name: carrier_name.trim(),
        tracking_info: tracking_info.trim(),
      });
    }

    const payload = {
      poId,
      pending_po_index: {
        delivery_informations_attributes: updatedDeliveryInfo.map(info => ({
          delivery_date: info.delivery_date,
          carrier_name: info.carrier_name,
          tracking_info: info.tracking_info,
          received_at: info.received_at || null
        })),
      },
    };

    dispatch(UpdateDeliveryInfo(payload)).then((res) => {
      if (res?.payload?.data?.success) {
        notification.success({
          message: 'Tracking Info Added Successfully',
          top: 65,
        });
        setOpenModal();
        onSuccess();
        setChecked(false);
        setAddedDeliveryInfo([]);
        setNewDate({
          carrier_name: '',
          delivery_date: '',
          tracking_info: '',
        });
        setCarrier('');
        setSelectedRowId(null);
      }
    });
  };

  const getFormattedDate = (date) => date ? moment(date).format("DD MMM YYYY") : "N/A";

  return (
    <DeliveryInformationWrapper>
      <div className="header">
        <h1>Confirm Delivery Information</h1>
      </div>
      <div className="delivery-row-wrapper">
        <div className="track-info-wrapper">
          <p>Tracking info</p>
          {
            <Input
              value={newDate?.tracking_info}
              onChange={(e) => {
                setNewDate({
                  ...newDate,
                  tracking_info: e.target.value?.trimStart()
                });
              }}
              placeholder="Add or edit tracking info"
            />
          }
        </div>
        <div className="track-info-wrapper">
          <p>Carrier</p>
          <Select
            placeholder="Select Carrier"
            width="165px"
            isClearable
            marginBottom="0px"
            value={carrier}
            options={nonPartneredCarriers}
            onChange={(event) => {
              setCarrier(event);
              setNewDate({
                ...newDate,
                carrier_name: event?.value || ''
              });
            }}
          />
        </div>
        <div className="estimate-ui w-100">
          <p>Estimated Delivery Date</p>
          <DatePicker
            value={newDate?.delivery_date ? moment(newDate?.delivery_date) : ''}
            disabledDate={(current) => {
              return moment().add(-1, 'days') >= current
            }}
            format="DD MMMM YYYY"
            className="custom-date-picker"
            onChange={(date, dateString) => {
              setNewDate({
                ...newDate,
                delivery_date: dateString
              });
            }}
          />
        </div>
        <Button className="add-button" onClick={handleAddButtonClick}>
          <FaPlus />
          <span className="add-button-text">Add More</span>
        </Button>
      </div>
      <Spin tip="Loading..." spinning={loading}>
        <div className="fix-height-delivery-date">
          {addedDeliveryInfo.length > 0 && (
            <div className="pt-3">
              {(addedDeliveryInfo?.map((data, index) => (
                <div className="tracking-info-inner-wrapper" key={index}>
                  <p className="tracking-info-line">{data.tracking_info}</p>
                  <p className="tracking-info-line">{data.carrier_name || 'N/A'}</p>
                  <p className="tracking-info-date">{moment(data.delivery_date).format('DD MMMM YYYY')}</p>
                  {data.received_at ? (
                    <div className="d-flex flex-column">
                      <div className="recieved-text">Received</div>
                      <span style={{ fontSize: '10px', color: '#4E5969', marginLeft: '5px' }}>{getFormattedDate(data?.received_at) || "N/A"}</span>
                    </div>
                  ) : (
                    <button className="delete-button" onClick={() => handleDeleteEntry(data.tracking_info)}>
                      <BsTrash color="#CF0909" />
                    </button>
                  )}
                </div>
              )))}
            </div>
          )}
        </div>
      </Spin>
      <div className="text-container-ss">
        <div className="text-item">
          <p>
            If you dont add this info , the warehouse will be confused and your package may get lost
          </p>
          <div className="box-wrapper">
            <Checkbox className="delivery-modal-checkbox"
              checked={checked}
              onClick={() => {
                setChecked(!checked)
              }}
            />
            <p className="outline-text">
              I acknowledge this and take all following risks on me
            </p>
          </div>
        </div>
        <img src={BoxPack} />
      </div>
      <div className="button-wrapper">
        <Button className="skip-button" onClick={() => {
          setOpenModal();
          setNewDate({ carrier_name: '', delivery_date: '', tracking_info: '' });
          setCarrier('');
          setAddedDeliveryInfo([]);
          setChecked(false);
          setSelectedRowId(null);
        }}>Cancel</Button>
        <Tooltip
          trigger='hover'
          title={((!newDate?.delivery_date && newDate?.tracking_info) || (newDate?.delivery_date && !newDate?.tracking_info)) ? 'Please Add Tracking Info and Delivery Date' : ''}
        >
          <Box>
            <Button
              className={`confirm-button ${(!checked || loading || (!newDate?.delivery_date && newDate?.tracking_info) || (newDate?.delivery_date && !newDate?.tracking_info)) ? 'disabled' : ''}`}
              onClick={handleConfirmButtonClick}
              disabled={loading || !checked || (!newDate?.delivery_date && newDate?.tracking_info) || (newDate?.delivery_date && !newDate?.tracking_info)}
            >
              {loading ? 'Loading...' : 'Confirm'}
            </Button>
          </Box>
        </Tooltip>  
      </div>
    </DeliveryInformationWrapper>
  );
};

export default DeliveryInfo
