import { createAsyncThunk } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { handleRejectedResponse } from '../utils';
import { fetch as axios } from '../../../axios_config';
import { awsAxios } from '../../../axios_config';

export const initialState = {
  PoList: null,
  loading: false,
  error: null,
  vendors: [],
  addresses: [],
  draftPosList: [],
  statuses: [],
  draftPOsCount: 0,
  poOrdersCount: 0,
  preSignedUrl: '',
  getStatusLoading: false
};

export const GetAllPos = createAsyncThunk(
  '/listAllPos',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const { filters_hash = [], page_num, per_page } = data || {};
      const response = await axios.get(
        `${process.env.API_BASE_URL}api/v2/pending_po_indices/list_all_pos?`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          params: { filters_hash: JSON.stringify(filters_hash || []), page_num, per_page },
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Fetch List All Pos', rejectWithValue);
    }
  }
);

export const GetSinglePo = createAsyncThunk(
  '/getSinglePo',
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.API_BASE_URL}api/v2/pending_po_indices/${id}/details`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Fetch Single Po', rejectWithValue);
    }
  }
);

export const GetDraftPOs = createAsyncThunk(
  '/get-draft-pos',
  async (params, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.API_BASE_URL}api/v2/draft_pos?`,
        {
          params,
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Fetch List All Pos', rejectWithValue);
    }
  }
);

export const DeleteDraftPOs = createAsyncThunk(
  '/delete-draft-po',
  async (id, { getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(`${process.env.API_BASE_URL}api/v2/draft_pos/${id}`);
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Fetch List All Pos', rejectWithValue);
    }
  }
);

export const SaveInvoiceUrl = createAsyncThunk(
  '/save-invoice',
  async ({
    pending_po_indices_id,
    s3_link,
    invoice_number,
    invoiced_date
  }, { getState, rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/api/v2/pending_po_indices/${pending_po_indices_id}/upload_invoice_file`, {
        file_link: s3_link,
        invoice_number,
        invoiced_date
      });
      return data;
    } catch (err) {
      return handleRejectedResponse(err, 'Upload Invoice', rejectWithValue);
    }
  }
);

export const getReconExcel = createAsyncThunk(
  '/get-recon-excel',
  async ({ ids }, { getState, rejectWithValue }) => {
    try {
      const { data } = await axios({
        method: 'get',
        url: '/api/v2/pending_po_indices/download_recon_file',
        params: { ids }
      });
      
      return data;
    } catch (err) {
      return handleRejectedResponse(err, 'Upload Invoice', rejectWithValue);
    }
  }
);

const uploadToS3 = ({ url, file }) => {
  return awsAxios({
    method: 'PUT',
    url,
    data: file,
    headers: {
      'Content-Type': file.type
    },
  }).then(({ data }) => {
    return true;
  }).catch((error) => {
    return false;
  });
};

export const GetPreSignedUrl = createAsyncThunk(
  '/get-pre-signed-url',
  async ({ invoice_name, file }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/api/v2/pending_po_indices/presigned_url`, {
        params: {
          file_name: `vendor_invoices/${invoice_name}`
        }
      });
      const { url } = response?.data || {};
      const urls = url.split('?');

      const uploadResult = await uploadToS3({ url, file });
      if (!uploadResult) {
        throw new Error('Failed to upload Invoice');
      }

      return { url: urls[0] };
    } catch (err) {
      return handleRejectedResponse(err, 'Upload Invoice', rejectWithValue);
    }
  }
);

export const RemoveInvoice = createAsyncThunk(
  '/remove-invoice',
  async ({ order_invoice_id, pending_po_indexes_id }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(`/api/v2/pending_po_indices/${pending_po_indexes_id}/remove_cpo_invoice`, {
        params: {
          order_invoice_id
        }
      });
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Delete Invoice', rejectWithValue);
    }
  }
);

export const GetVendors = createAsyncThunk(
  '/getPoIndices/vendors',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.API_BASE_URL}api/v2/pending_po_indices/vendors?`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Vendors', rejectWithValue);
    }
  }
);

export const UpdateDeliveryInfo = createAsyncThunk(
  '/updateDeliveryInformation',
  async ({ poId, pending_po_index }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.put(
        `${process.env.API_BASE_URL}api/v2/pending_po_indices/${poId}`,
        { pending_po_index },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Delivery Information', rejectWithValue);
    }
  }
);

export const SwitchPoAddress = createAsyncThunk(
  '/switchPoAddress',
  async ({ id, company_address_id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.API_BASE_URL}api/v2/pending_po_indices/${id}/switch_po_address`,
        { company_address_id },
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Switch Po Address', rejectWithValue);
    }
  }
);

export const GetStatusCount = createAsyncThunk(
  '/getStatusCount',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.API_BASE_URL}api/v2/pending_po_indices/status_count`,
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Status Count', rejectWithValue);
    }
  }
);

export const UpdateLastSeen = createAsyncThunk(
  '/updateLastSeen',
  async ({ status }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.post(
        `${process.env.API_BASE_URL}api/v2/pending_po_indices/update_last_seen`,
        { status },
        {
          headers: { Authorization: `Bearer ${authToken}` },
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Last Seen', rejectWithValue);
    }
  }
);

export const PosReducer = {
  [GetAllPos.pending]: (state) => {
    state.loading = true;
  },
  [GetAllPos.fulfilled]: (state, action) => {
    state.loading = false;
    state.PoList = action?.payload?.data?.pending_po_indices;
    state.poOrdersCount = action?.payload?.data?.count;
  },
  [GetAllPos.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  [GetSinglePo.pending]: (state) => {
    state.loading = true;
  },
  [GetSinglePo.fulfilled]: (state, action) => {
    state.loading = false;
    state.singlePoList = action?.payload?.data?.pending_po_index;
  },
  [GetSinglePo.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  [GetDraftPOs.pending]: (state) => {
    state.loading = true;
  },
  [GetDraftPOs.fulfilled]: (state, action) => {
    state.loading = false;
    state.draftPosList = action?.payload?.data?.draft_pos;
    state.draftPOsCount = action?.payload?.data?.count;
  },
  [GetDraftPOs.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  [DeleteDraftPOs.pending]: (state) => {
    state.loading = true;
  },
  [DeleteDraftPOs.fulfilled]: (state, action) => {
    notification.success({
      message: "Delete Draft PO",
      description: 'Draft PO Deleted successfully.',
      top: 65
    });
    state.loading = false;
  },
  [DeleteDraftPOs.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  [RemoveInvoice.pending]: (state) => {
    state.loading = true;
  },
  [RemoveInvoice.fulfilled]: (state, action) => {
    notification.success({
      message: "Delete Invoice",
      description: 'Invoice Deleted successfully.',
      top: 65
    });
    state.loading = false;
  },
  [RemoveInvoice.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  [SaveInvoiceUrl.pending]: (state) => {
    state.loading = true;
  },
  [SaveInvoiceUrl.fulfilled]: (state, action) => {
    notification.success({
      message: "Upload Invoice",
      description: 'Invoice uploaded successfully.',
      top: 65
    });
    state.loading = false;
  },
  [SaveInvoiceUrl.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  [GetPreSignedUrl.pending]: (state) => {
    state.loading = true;
  },
  [GetPreSignedUrl.fulfilled]: (state, action) => {
    state.loading = false;
    state.preSignedUrl = action?.payload?.url
  },
  [GetPreSignedUrl.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  [GetVendors.pending]: (state) => {
    state.loading = true;
  },
  [GetVendors.fulfilled]: (state, action) => {
    state.loading = false;
    state.vendors = action?.payload?.data?.vendors;
  },
  [GetVendors.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  [SwitchPoAddress.fulfilled]: (state, action) => {
    state.success = action?.payload?.data?.success;
  },
  [SwitchPoAddress.rejected]: (state, action) => {
    state.error = action.payload;
  },
  [UpdateDeliveryInfo.pending]: (state) => {
    state.loading = true;
  },
  [UpdateDeliveryInfo.fulfilled]: (state, action) => {
    state.success = action?.payload?.data?.success;
    state.loading = false;
  },
  [UpdateDeliveryInfo.rejected]: (state, action) => {
    state.error = action.payload;
  },
  [GetStatusCount.pending]: (state) => {
    state.getStatusLoading = true;
  },
  [GetStatusCount.fulfilled]: (state, action) => {
    state.getStatusLoading = false;
    state.statuses = action?.payload?.data?.statuses;
  },
  [GetStatusCount.rejected]: (state, action) => {
    state.getStatusLoading = false;
    state.error = action.payload;
  },
  [UpdateLastSeen.pending]: (state) => {
    state.loading = true;
  },
  [UpdateLastSeen.fulfilled]: (state, action) => {
    state.success = action?.payload?.data?.success;
  },
  [UpdateLastSeen.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },
  [getReconExcel.pending]: (state) => {
    state.loading = true;
  },
  [getReconExcel.fulfilled]: (state, action) => {
    state.loading = false;
  },
  [getReconExcel.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  }
};

