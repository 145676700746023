import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  fetchingSaleGraph: false,
  dailyHistory: [],
  filters: [],
  sortOptions: [],
  selectedItems: [],
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  totalItems: 0,
  saleStats: []
};

const Dashboard = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GET_DAILY_HISTORY_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_DAILY_HISTORY_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        fetching: false
      };
    }
    case 'GET_DAILY_HISTORY_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_DATA_SYNCED_FLAG_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_DATA_SYNCED_FLAG_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        fetching: false
      };
    }
    case 'GET_DATA_SYNCED_FLAG_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_SALE_STAT_FOR_REPRICER_DASHBOARD_REQUEST': {
      return {
        ...state,
        fetchingSaleGraph: true
      }
    }
    case 'GET_SALE_STAT_FOR_REPRICER_DASHBOARD_ERROR':
    case 'GET_SALE_STAT_FOR_REPRICER_DASHBOARD_SUCCESS': {
      return {
        ...state,
        fetchingSaleGraph: false,
        ...action.payload
      }
    }

    default:
      return state;
  }
};

export default Dashboard;
