import React, { useEffect, useState } from "react";

import BoxIcon from "../../../assets/images/box-icon.svg"
import HouseIcon from "../../../assets/images/house-icon.svg"
import AmazonIcon from "../../../assets/images/amazon-icon.svg"

import RadioCard from "../../../components/radioCard";

import { ShipThirdPartyLogisticsWrapper } from "../style";

const RadioCardData = [
  {
    id: 1,
    content: (
      <div className="card-data-overlay">
        <img width={64} className="m-auto" src={BoxIcon} />
        <h5>Ship to 3PL</h5>
        <p>Use this to use Sale support warehouses to Consolidate FBA shipments and Bundle Units</p>
      </div>
    ),
  },
  {
    id: 2,
    content: (
      <div className="card-data-overlay">
        <img width={64} className="m-auto" src={HouseIcon} />
        <h5 className="shipment-heading">New Shipment from my warehouse to Amazon</h5>
        <p>Use this to use your own warehouses to Consolidate FBA shipments and Bundle Units </p>
      </div>
    ),
  },
  {
    id: 3,
    content: (
      <div className="card-data-overlay disable">
        <img width={64} className="m-auto" src={AmazonIcon} />
        <h5 className="shipment-heading">Create Shipment From Vendor To Amazon</h5>
        <p>Use this to create FBA shipments for Vendor to ship to Amazon.</p>
      </div>
    ),
  },
];

const ShipThirdPartyLogistics = ({
  draft_po,
  setDraftPo,
  setWarehouseDescription,
  setAccordianKey
}) => {

  return (
    <ShipThirdPartyLogisticsWrapper>
      <div class="grid">
        {RadioCardData.map((item) => (
          <RadioCard
            key={item.id}
            checked={draft_po?.po_destination === item.id}
            onChange={() => {
              if (![1, 2]?.includes(item.id)) return;
              setDraftPo({
                ...draft_po,
                po_destination: item.id,
                ship_to_address_id: ''
              });
              setAccordianKey("2");
              setWarehouseDescription("");
            }}
          >
            {item.content}
          </RadioCard>
        ))}
      </div>
    </ShipThirdPartyLogisticsWrapper>
  );
};

export default ShipThirdPartyLogistics;
