import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  fetchingUsers: false,
  fetchingVendors: false,
  fetchingStats: false,
  purchaseOrders: [],
  fetchingOrderDetails: false,
  stats: {},
  total_records: 0,
  vendors: [],
  users: [],
  filters: [],
  selectedUserIdFilter: null,
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  inventory_level_stats: {
    stats_by_location: [],
    stats_by_po_status: []
  }
};

const pendingPoIndices = (state = cloneDeep(initialState), action) => {
  switch (action.type) {

    case 'GET_ORDERS_DETAILS_REQUEST': {
      return {
        ...state,
        fetchingOrderDetails: true
      }
    }
    case 'GET_ORDERS_DETAILS_SUCCESS': {
      return {
        ...state,
        fetchingOrderDetails: false,
        ...action.payload,
      }
    }
    case 'GET_ORDERS_DETAILS_FAILED': {
      return {
        ...state,
        fetchingOrderDetails: false
      }
    }
    case 'SET_USERID_FILTER_PENDING_PO_INDICES': {
      return {
        ...state,
        selectedUserIdFilter: action.payload
      };
    }
    case 'SET_FILTERS_PENDING_PO_INDICES':
    case 'SET_SORT_OPTIONS_PENDING_PO_INDICES': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_USERS_FOR_PENDING_PO_INDICES_REQUEST': {
      return {
        ...state,
        fetchingUsers: true
      };
    }
    case 'GET_USERS_FOR_PENDING_PO_INDICES_SUCCESS': {
      return {
        ...state,
        fetchingUsers: false,
        users: action.payload
      };
    }
    case 'GET_USERS_FOR_PENDING_PO_INDICES_FAILED': {
      return {
        ...state,
        fetchingUsers: false
      };
    }
    case 'GET_VENDORS_FOR_PENDING_PO_INDICES_REQUEST': {
      return {
        ...state,
        fetchingVendors: true
      };
    }
    case 'GET_STATS_FOR_PENDING_PO_INDICES_REQUEST': {
      return {
        ...state,
        fetchingStats: true
      };
    }
    case 'GET_STATS_FOR_PENDING_PO_INDICES_SUCCESS': {
      return {
        ...state,
        fetchingStats: false,
        stats: action.payload
      };
    }
    case 'GET_STATS_FOR_PENDING_PO_INDICES_FAILED': {
      return {
        ...state,
        fetchingStats: false
      };
    }
    case 'EXPORT_PO_FOR_EXPIRY_DATES_REQUEST':
    case 'GET_RECON_EXCEL_REQUEST':
    case 'GET_GRAND_TOTAL_EXCEL_REQUEST':
    case 'GET_EXPORT_PENDING_PO_INDICES_REQUEST':
    case 'GET_BASE_64_IMAGE_FOR_PENDING_PO_INDICES_REQUEST':
    case 'UPDATE_PENDING_PO_INDICES_REQUEST':
    case 'GET_PENDING_PO_INDICES_REQUEST':
    case 'GET_PRE_SIGNED_URL_REQUEST':
    case 'DOWNLOAD_INVOICE_REQUEST':
    case 'DELETE_VENDOR_INVOICE_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_PENDING_PO_INDICES_SUCCESS': {
      const { total_records, pending_po_indices } = action.payload;
      return {
        ...state,
        fetching: false,
        total_records,
        purchaseOrders: pending_po_indices
      };
    }
    case 'DELETE_PENDING_PO_INDICES_FAILED':
    case 'DELETE_PENDING_PO_INDICES_SUCCESS':
    case 'EXPORT_PO_FOR_EXPIRY_DATES_SUCCESS':
    case 'EXPORT_PO_FOR_EXPIRY_DATES_FAILED':
    case 'GET_RECON_EXCEL_FAILED':
    case 'GET_RECON_EXCEL_SUCCESS':
    case 'GET_GRAND_TOTAL_EXCEL_REQUEST_SUCCESS':
    case 'GET_GRAND_TOTAL_EXCEL_REQUEST_FAILED':
    case 'GET_EXPORT_PENDING_PO_INDICES_SUCCESS':
    case 'UPDATE_PENDING_PO_INDICES_SUCCESS':
    case 'GET_PRE_SIGNED_URL_FAILED':
    case 'UPLOAD_VENDOR_INVOICE_TO_S3_FAILED':
    case 'SAVE_S3_LINK_IN_DATABASE_SUCCESS':
    case 'SAVE_S3_LINK_IN_DATABASE_FAILED':
    case 'DOWNLOAD_INVOICE_SUCCESS':
    case 'DOWNLOAD_INVOICE_FAILED':
    case 'DELETE_VENDOR_INVOICE_SUCCESS':
    case 'DELETE_VENDOR_INVOICE_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_PAGINATION_PENDING_PO_INDICES': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    case 'GET_EXPORT_PENDING_PO_INDICES_FAILED':
    case 'GET_BASE_64_IMAGE_FOR_PENDING_PO_INDICES_SUCCESS':
    case 'GET_BASE_64_IMAGE_FOR_PENDING_PO_INDICES_FAILED':
    case 'UPDATE_PENDING_PO_INDICES_FAILED':
    case 'GET_PENDING_PO_INDICES_FAILED':
    case 'GET_VENDORS_FOR_PENDING_PO_INDICES_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_VENDORS_FOR_PENDING_PO_INDICES_SUCCESS': {
      return {
        ...state,
        fetchingVendors: false,
        vendors: action.payload ? [...action.payload] : []
      };
    }
    default:
      return state;
  }
};

export default pendingPoIndices;
