import Styled from 'styled-components';
const TilesPagination = Styled.div`
       background-color:${({ theme }) => theme["aqua_haze"]}; 
        padding:16px;
        text-align:center;
        border-top-left-radius:4px;
        border-top-right-radius:4px;
        color:${({ theme }) => theme["comet-color"]};
        font-size:${({ theme }) => theme["medium-font-size"]}
  `;
export { TilesPagination };