import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { fetch as axios } from '../../axios_config';
import { notification } from 'antd';
import { handleRejectedResponse } from './utils';

export const SubmitListingFile = createAsyncThunk(
  '/submit-listing-file',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.LISTER_BASE_URL}/api/save_uploaded_files`,
        data
      })
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Listing File Submission', rejectWithValue);
    }
  }
);

export const GetAllFeedFilesData = createAsyncThunk(
  '/get-all-feed-files-data',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { id: sales_support_user_id, email } = state.auth?.currentUser;
      const { pagination, filters, sorting } = state.lister;
      const { page_num, per_page } = pagination || {};
      const response = await axios({
        method: 'GET',
        url: `${process.env.LISTER_BASE_URL}/api/matches/list_files`,
        params: {
          email,
          per_page: per_page || 25,
          page: page_num || 1,
        }
      })
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get All Files Data', rejectWithValue);
    }
  }
);

export const GetAllMatches = createAsyncThunk(
  '/get-all-matches',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { email } = state.auth?.currentUser;
      const { pagination, search_term, source_type, listing_type, listing_status, two_step_status, missing_dimensions, marketplace_status } = state.lister;
      const { page_num, per_page } = pagination || {};
      const response = await axios({
        method: 'GET',
        url: `${process.env.LISTER_BASE_URL}/api/matches/list_matches`,
        headers: {
          'Authorization': process.env.LISTER_AUTH_TOKEN
        },
        params: {
          email,
          per_page: per_page || 25,
          page: page_num || 1,
          search_term: search_term || '',
          source_type: source_type || '',
          listing_type: listing_type || '',
          listing_status: listing_status || '',
          two_step_status: two_step_status || '',
          missing_dimensions: missing_dimensions || '',
          marketplace_status: marketplace_status || ''
        }
      })
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get All Matches', rejectWithValue);
    }
  }
);

export const GetSuppliers = createAsyncThunk(
  '/get-suppliers',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'GET',
        url: `${process.env.LISTER_BASE_URL}/api/supplier_platforms`
      })
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Suppliers', rejectWithValue);
    }
  }
);

export const UpdateTwoStepStatus = createAsyncThunk(
  '/update-two-step-status',
  async (data, { getState, rejectWithValue }) => {
    const { selectedRows, status } = data;
    let matches_array = selectedRows?.map(({ listing_id }) => listing_id);
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.LISTER_BASE_URL}/api/shipping_analyzer/update_two_steps_verification`,
        data: {
          matches_array,
          status
        }
      });
      let original_skus = '';
      const { data: missing_weight_items_ids } = response?.data || {}
      if (missing_weight_items_ids.length) {

        missing_weight_items_ids.forEach((item) => {
          const match = find(selectedRows, (n) => item == n.listing_id);
          original_skus += match?.listing_sku || ''
        })

        notification.warning({
          message: 'Warning',
          description: `We could not enable 2-STEP Shipping for Skus ${original_skus} because of Missing Dimensions`,
          top: 65
        })
      }
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Two Step Status', rejectWithValue);
    }
  }
);

export const UpdateMatchDetails = createAsyncThunk(
  '/update-match-details',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.LISTER_BASE_URL}/api/update_details`,
        data
      });
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Update Two Step Status', rejectWithValue);
    }
  }
);

export const GetFileItems = createAsyncThunk(
  '/get-file-items',
  async (id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const { fileItemsPagination } = state.lister;
      const { page_num, per_page } = fileItemsPagination || {};
      const response = await axios({
        method: 'GET',
        url: `${process.env.LISTER_BASE_URL}/api/matches/${id}/list_files_items`,
        params: {
          per_page: per_page || 25,
          page: page_num || 1
        }
      })
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Get File Items', rejectWithValue);
    }
  }
);

export const DeleteItem = createAsyncThunk(
  '/delete-item',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await axios({
        method: 'POST',
        url: `${process.env.LISTER_BASE_URL}/api/remove_walmart_item`,
        data
      })
      return response.data;
    } catch (err) {
      return handleRejectedResponse(err, 'Delete Item', rejectWithValue);
    }
  }
);

export const AddSupplier = createAsyncThunk(
  '/add-supplier',
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.LISTER_BASE_URL}/api/supplier/add_supplier`,
        data
      });
      notification.success({
        message: 'ADDING SOURCE',
        description: 'Source Added Successfully...',
        top: 65
      })
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Add Supplier', rejectWithValue);
    }
  }
);

const lister = createSlice({
  name: "lister",
  initialState: {
    loading: false,
    pagination: {
      page_num: 1,
      per_page: 25
    },
    totalCount: 0,
    list: [],
    fileItems: [],
    totalFileItems: 0,
    fileItemsPagination: {
      page_num: 1,
      per_page: 25
    },
    suppliers: [],
    search_term: '',
    source_type: 'Amazon',
    listing_type: 'Walmart',
    listing_status: 'All',
    two_step_status: 'All',
    missing_dimensions: false,
    marketplace_status: 'All'
  },
  reducers: {
    setState(state, { payload: { field, value } }) {
      state[field] = value;
    },
    clearState(state, action) {
      return {
        loading: false,
        pagination: {
          page_num: 1,
          per_page: 25
        },
        totalCount: 0,
        list: [],
        fileItems: [],
        totalFileItems: 0,
        fileItemsPagination: {
          page_num: 1,
          per_page: 25
        },
        suppliers: [],
        search_term: '',
        source_type: 'Amazon',
        listing_type: 'Walmart',
        listing_status: 'All',
        two_step_status: 'All',
        missing_dimensions: false,
        marketplace_status: 'All'
      }
    },
    setPageNum(state, { payload: data }) {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...data
        }
      }
    },
    setPerPage(state, { payload: data }) {
      return {
        ...state,
        pagination: {
          page_num: 1,
          ...data
        }
      }
    },
    clearFilters(state, action) {
      return {
        ...state,
        pagination: {
          page_num: 1,
          per_page: 25
        },
        fileItemsPagination: {
          page_num: 1,
          per_page: 25
        },
        // source_type: 'Amazon',
        // listing_type: 'Walmart',
        listing_status: 'All',
        two_step_status: 'All',
        missing_dimensions: false,
        marketplace_status: 'All'
      }
    }
  },
  extraReducers: {
    [GetAllMatches.pending]: (state, action) => ({
      ...state,
      loading: true,
      totalCount: 0,
      list: [],
    }),
    [GetAllMatches.fulfilled]: (state, action) => {
      let { success, data, total } = action?.payload?.data || {};
      return {
        ...state,
        loading: false,
        totalCount: total,
        list: data,
      }
    },
    [GetAllMatches.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        totalCount: 0,
        list: [],
      }
    },
    [SubmitListingFile.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [SubmitListingFile.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [SubmitListingFile.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetAllFeedFilesData.pending]: (state, action) => ({
      ...state,
      loading: true,
      totalCount: 0,
      list: [],
    }),
    [GetAllFeedFilesData.fulfilled]: (state, action) => {
      const { success, data, total } = action?.payload?.data || {};
      return {
        ...state,
        loading: false,
        totalCount: total,
        list: data,
      }
    },
    [GetAllFeedFilesData.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        totalCount: 0,
        list: [],
      }
    },
    [GetSuppliers.pending]: (state, action) => ({
      ...state,
      loading: true,
      suppliers: [],
    }),
    [GetSuppliers.fulfilled]: (state, action) => {
      const { data } = action?.payload || {};
      return {
        ...state,
        loading: false,
        suppliers: data,
      }
    },
    [GetSuppliers.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        suppliers: [],
      }
    },
    [UpdateTwoStepStatus.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [UpdateTwoStepStatus.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [UpdateTwoStepStatus.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [UpdateMatchDetails.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [UpdateMatchDetails.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [UpdateMatchDetails.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [AddSupplier.pending]: (state, action) => {
      return {
        ...state,
        loading: true,
      }
    },
    [AddSupplier.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false
      }
    },
    [AddSupplier.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [GetFileItems.pending]: (state, action) => ({
      ...state,
      loading: true,
      fileItems: [],
    }),
    [GetFileItems.fulfilled]: (state, action) => {
      const { success, data, total } = action?.payload || {};
      return {
        ...state,
        loading: false,
        fileItems: data,
        totalFileItems: total
      }
    },
    [GetFileItems.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
        fileItems: [],
        totalFileItems: 0
      }
    },
    [DeleteItem.pending]: (state, action) => ({
      ...state,
      loading: true,
    }),
    [DeleteItem.fulfilled]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
    [DeleteItem.rejected]: (state, action) => {
      return {
        ...state,
        loading: false,
      }
    },
  }
})

const { reducer, actions } = lister;

export const {
  setState,
  clearState,
  setPageNum,
  setPerPage,
  clearFilters
} = actions;

export default reducer;
