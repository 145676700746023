import React, { useEffect, useState } from 'react';

import Box from "../../../assets/images/box-icon.svg"
import ThreeBox from "../../../assets/images/boxes-3.svg"
import FTLDelivery from "../../../assets/images/ftl-delivery.svg"

import RadioCard from "../../../components/radioCard";

import { InboundShipmentwrapper } from '../style';

const InboundShipment = ({
  setDraftPo,
  draft_po,
}) => {
  const [isChecked, setIsChecked] = useState(draft_po?.inbound_type || 1);

  useEffect(() => {
    setDraftPo({
      ...draft_po,
      inbound_type: isChecked
    });
  }, [isChecked]);

  const handleRadioChange = (id) => {
    setIsChecked(id);
  };

  const RadioCardData = [
    {
      id:1,
      content: (
        <div className="card-data-overlay">
          <img width={64} className="m-auto" src={Box}/>
          <h5>Small parcel delivery</h5>
        </div>
      ),
    },
    {
      id:2,
      content: (
        <div className="card-data-overlay">
          <img width={64} className="m-auto" src={ThreeBox}/>
          <h5 className="shipment-heading">LTL pallet delivery</h5>
        </div>
      ),
    },
    {
      id:3,
      content: (
        <div className="card-data-overlay">
          <img width={64} className="m-auto" src={FTLDelivery}/>
          <h5 className="shipment-heading">FTL pallet delivery</h5>
        </div>
      ),
    },
  ];
  return (
    <InboundShipmentwrapper>
   <div class="grid">
        {RadioCardData.map((item) => (
          <RadioCard
            key={item.id}
            checked={isChecked === item.id}
            onChange={() => handleRadioChange(item.id)}
          >
            {item.content}
          </RadioCard>
        ))}
      </div>
    </InboundShipmentwrapper>
  );
}

export default InboundShipment;
