import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  items: [],
  total: 0,
  filters: [],
  sortOptions: {},
  selectedItems: [],
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  net_total: 0,
  margin: 0,
  cogs: 0,
  removal_shipment: {},
  fetched: 'no'
};

const fbaremovalshipments = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GET_FBA_REMOVAL_SHIPMENT_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_FBA_REMOVAL_SHIPMENT_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      };
    }
    case 'SET_PAGINATION_FBA_REMOVAL_SHIPMENTS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    case 'SET_FILTERS_FBA_REMOVAL_SHIPMENTS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_SORT_OPTIONS_FBA_REMOVAL_SHIPMENTS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SEARCH_REMOVAL_SHIPMENT_REQUEST': {
      return {
        ...state,
        fetching: true,
        fetched: 'no'
      }
    }
    case 'SEARCH_REMOVAL_SHIPMENT_SUCCESS': {
      return {
        ...state,
        fetching: false,
        fetched: 'success'
      };
    }
    case 'SEARCH_REMOVAL_SHIPMENT_FAILED': {
      return {
        ...state,
        fetching: false,
        fetched: 'error'
      };
    }

    default:
      return state;
  }
};

export default fbaremovalshipments;
