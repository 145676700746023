import { cloneDeep } from 'lodash';
const inittialState = {
  fetching: false,
  pagination: {
    page_num: 1,
    per_page: 25
  },
  restrictedBrandsList: [],
  filters: []
}

const reducer = (state = cloneDeep(inittialState), action) => {
  switch (action.type) {
    case 'SUBMIT_RESTRICTED_BRANDS_REQUEST': {
      return {
        ...state,
        fetching: true
      }
    }
    case 'SUBMIT_RESTRICTED_BRANDS_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      }
    }
    case 'SUBMIT_RESTRICTED_BRANDS_FALSE': {
      return {
        ...state,
        fetching: false
      }
    }
    case 'FETCHING_RESTRICTED_BRANDS_REQUEST': {
      return {
        ...state,
        restrictedBrandsList: [],
        fetching: true
      }
    }
    case 'FETCHING_RESTRICTED_BRANDS_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      }
    }
    case 'FETCHING_RESTRICTED_BRANDS_FALSE': {
      return {
        ...state,
        fetching: false
      }
    }
    case 'SET_FILTER_FOR_RESTRICTED_BRANDS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      }
    }
    case 'RESET_FILTER_FOR_RESTRICTED_BRANDS': {
      return {
        ...state,
        ...action.payload
      }
    }
    case 'CHANGE_PER_PAGE': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    case 'CHANGE_PAGE_NUM': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }

    default: {
      return state
    }
  }
}

export default reducer;
