import JsPDF from 'jspdf';
import { findIndex, padStart } from 'lodash';
import JsBarcode from 'jsbarcode';
import generateBarcode from 'pdf417';

import { mix2DBarcode } from './barcode';

const generate128Barcode = (text) => {
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, text, {
    format: 'CODE128A',
    text: text,
    font: 'sans-serif',
    fontSize: 40,
    textAlign: 'center',
    width: 5,
    // height: 85,
    margin: 1,
    displayValue: false
  });
  return canvas.toDataURL('image/png');
};

const generateMix2DLabelPdf = ({
  shipmentId,
  userName,
  pdfDoc,
  barcode,
  list,
  totalQuantity,
  shipmentName,
  title,
  is2D,
  locations,
  dateCreated,
  printLocation,
  page,
  PageSize,
  PageStartIndex,
  ship
}) => {
  
  let y = 0;
  const x = 1;
  const width = pdfDoc.internal.pageSize.getWidth();
  const height = pdfDoc.internal.pageSize.getHeight();
  const centerX = width / 2;

  const aaa = pdfDoc.getFontList()

  // amazon label part
  pdfDoc.setFontSize(8);
  pdfDoc.setFontStyle('bold');
  y += 5;

  pdfDoc.text('PLEASE LEAVE THIS LABEL UNCOVERED', centerX, y, 'center');

  y += 7;
  pdfDoc.setFontSize(17);
  pdfDoc.text('FBA', 5.5, y);

  y += 1.5;
  pdfDoc.setLineWidth(.7);
  pdfDoc.line(4.8, y, 96.6, y);

  y += 4;
  pdfDoc.setFontSize(8);
  pdfDoc.setFontStyle('normal');

  pdfDoc.text('SHIP FROM:', 5.5, y);
  pdfDoc.text('SHIP TO:', 50.3, y);

  y += 3;
  pdfDoc.text(`${ship.warehouse_name}`, 5.5, y);
  pdfDoc.text(`${ship.ship_to_name}`, 50.3, y);

  y += 3;
  pdfDoc.text(`${ship.address_line1}`, 5.5, y);
  pdfDoc.text(`${ship.destination_fulfillment_center_id}`, 50.3, y);

  y += 3;
  pdfDoc.text(`${ship.city},${ship.state_or_province_code}`, 5.5, y);
  pdfDoc.text(`${ship.ship_to_address1}`, 50.3, y);

  y += 3;
  pdfDoc.text(`${ship.country_code}`, 5.5, y);
  pdfDoc.text(`${ship.ship_to_city}, ${ship.ship_to_state}, ${ship.ship_to_postal_code}`, 50.3, y);

  y += 3;
  pdfDoc.text('United States', 50.3, y);

  y += 2.9;
  pdfDoc.setLineWidth(2.5);
  pdfDoc.line(5, y, 96.6, y);

  y += 0.65;
  pdfDoc.setTextColor('white');
  pdfDoc.setFontSize(7);
  pdfDoc.text(`${shipmentName}`, 6, y);

  pdfDoc.text(`Created: ${dateCreated} EDT (-04)`, 55.10, y);

  y += 3.5;
  const shipmentBarcode = generate128Barcode(`${shipmentId}U${padStart(PageStartIndex + page, 6, '0')}`);
  pdfDoc.addImage(shipmentBarcode, "PNG", 7, y, 59, 33,null,'FAST');

  const shipmentMix2DBarcode = generateBarcode(`${shipmentId}U${padStart(PageStartIndex + page, 6, '0')}`, 15, 15)
  pdfDoc.addImage(shipmentMix2DBarcode, "PNG", 95, y + 36.35 - 11, 52, 25, null, 'FAST', 90);

  y += 36;
  pdfDoc.setTextColor('black');
  pdfDoc.setFontSize(8);
  pdfDoc.text(`${shipmentId}U${padStart(PageStartIndex + page, 6, '0')}`, 20, y);

  y += 3;
  pdfDoc.setLineWidth(1);
  pdfDoc.line(5, y, 68.5, y);

  y += 12.5;
  pdfDoc.setLineWidth(0.24);
  pdfDoc.line(0, y, 101.6, y);

  /////////////////////

  y += 7; // top margin
  pdfDoc.setFont('sans-serif');
  pdfDoc.setFontSize(24);
  pdfDoc.text('Box Contents', centerX, y, 'center');

  y += 5;
  pdfDoc.setFontSize(13);
  pdfDoc.setFontStyle('normal');
  pdfDoc.text(`Units: ${totalQuantity}, ${userName}`, centerX, y, 'center');

  y += 2;
  pdfDoc.addImage(barcode, 'PNG', x + 1, y, width - 4, 17);

  y += 21.5;
  pdfDoc.setFontStyle('normal');
  pdfDoc.text(shipmentId || 'N/A', centerX, y, 'center');

  y += 4.5;
  pdfDoc.setFontSize(12);
  pdfDoc.setFontStyle('normal');
  pdfDoc.text(shipmentName || 'N/A', centerX, y, 'center');

  if (is2D) {
    pdfDoc = handleFnsku2D({ title, pdfDoc, list, x, y, width, locations, height, printLocation, shipmentId });
  } else {
    pdfDoc = handleFnskuMix2D({ list, pdfDoc, x, y, centerX, width, locations, height, printLocation, shipmentId });
  }

  y += 20;
  pdfDoc.setFontSize(9);
  pdfDoc.setFontStyle('normal');
  pdfDoc.text(
    'Thank you for shipping with Sales.Support',
    1 + 0.8,
    y,
    'left'
  );

  return pdfDoc;
};

const handleFnskuMix2D = ({ list, pdfDoc, x, y, centerX, width, locations, height, printLocation, shipmentId }) => {
  y += 5;
  pdfDoc.setFontSize(10);
  let count = list.length;

  if (count > 3) {
    const leftList = list.splice(0, 3);
    pdfDoc.text(leftList, x + 1, y);

    count = list.length;
    if (count > 3) {
      const rightList = list.splice(0, 3);
      rightList.push(`${count - 3} more items`);
      pdfDoc.text(rightList, centerX - 10, y);
    } else {
      pdfDoc.text(list, centerX - 10, y);
    }
    // if (printLocation) {
    //   pdfDoc.line(x + 70, height - 9, width - 3, height - 9);
    //   pdfDoc.line(x + 70, height - 1, width - 3, height - 1);
    //   pdfDoc.line(x + 70, height - 9, width - 30.6, height - 1);
    //   pdfDoc.line(x + 97.6, height - 9, width - 3.2, height - 1);
    //   pdfDoc.setFontSize(25);
    //   pdfDoc.setFontStyle('bold');
    //   x = locations.length > 3 ? x + 93 : x + 86;
    //   pdfDoc.text(`${locations || 'N/A'}`, x, y + 15, 'right');
    // }
    // pdfDoc.text(`${locations || 'N/A'}`, x + 116, y + 15, 'right');
  } else {
    pdfDoc.text(list, x + 1, y);
    // if (printLocation) {
    //   pdfDoc.line(x + 70, height - 9, width - 3, height - 9);
    //   pdfDoc.line(x + 70, height - 1, width - 3, height - 1);
    //   pdfDoc.line(x + 70, height - 9, width - 30.6, height - 1);
    //   pdfDoc.line(x + 97.6, height - 9, width - 3.2, height - 1);
    //   pdfDoc.setFontSize(25);
    //   pdfDoc.setFontStyle('bold');
    //   x = locations.length > 3 ? x + 93 : x + 86;
    //   pdfDoc.text(`${locations || 'N/A'}`, x, y + 15, 'right');

    // }
  }

  pdfDoc.line(x + 70, height - 9, width - 3, height - 9);
  pdfDoc.line(x + 70, height - 1, width - 3, height - 1);
  pdfDoc.line(x + 70, height - 9, width - 30.6, height - 1);
  pdfDoc.line(x + 97.6, height - 9, width - 3.2, height - 1);
  pdfDoc.setFontSize(20);
  pdfDoc.setFontStyle('bold');
  x += 95;
  pdfDoc.text(shipmentId.substr(-4), x, y + 15, 'right');

  return pdfDoc;
}

const handleFnsku2D = ({ title, list, pdfDoc, x, y, width, locations, height, printLocation, shipmentId }) => {
  y += 5;

  let titleLines = printLocation ? pdfDoc.splitTextToSize(title, width - 10) : pdfDoc.splitTextToSize(title, width - 7);
  titleLines = titleLines?.slice(0, 2);
  pdfDoc.setFontStyle('normal');
  pdfDoc.text(titleLines, x + 1, y);
  y += (pdfDoc.getTextDimensions(title).h + 0.4) * titleLines.length;

  pdfDoc.setFontSize(9);
  pdfDoc.text(list, x + 1, y);

  pdfDoc.rect(71, y + 3, 27, 8);
  pdfDoc.setFontSize(20);
  pdfDoc.setFontStyle('bold');
  x += 94;
  pdfDoc.text(shipmentId.substr(-4), x, height - 2.5, 'right');

  // if (printLocation) {
  //   pdfDoc.line(x + 70, height - 9, width - 3, height - 9);
  //   pdfDoc.line(x + 70, height - 1, width - 3, height - 1);
  //   pdfDoc.line(x + 70, height - 9, width - 30.6, height - 1);
  //   pdfDoc.line(x + 97.6, height - 9, width - 3.2, height - 1);
  //   pdfDoc.setFontSize(25);
  //   pdfDoc.setFontStyle('bold');
  //   x = locations.length > 3 ? x + 93 : x + 86;
  //   if (titleLines.length > 1) {
  //     pdfDoc.text(`${locations || 'N/A'}`, x, y + 3, 'right');
  //   } else {
  //     pdfDoc.text(`${locations || 'N/A'}`, x, y + 8, 'right');
  //   }
  // }

  return pdfDoc;
}

const mix2DLablePds = ({
  outbound_shipment_id,
  shipmentId,
  items,
  userName,
  numberOfBox = 1,
  shipmentName,
  title,
  is2D = false,
  locations,
  dateCreated,
  printLocation = true,
  ship,
  PageSize,
  PageStartIndex,
  // isEmailValid
}) => {
  const width = 288;
  const height = printLocation ? 432 : 432;

  let pdfDoc = new JsPDF('portrait', 'mm', [height, width], true);

  const barcode = mix2DBarcode({ shipmentId, items });

  let totalQuantity = 0;
  const list = [];

  items.forEach(({ qty, fnsku }) => {
    totalQuantity += qty;
    const index = findIndex(list, (value) => value.includes(fnsku));

    if (index !== -1) {
      const listItems = list[index].split(': ');
      const itemQty = Number(listItems.pop());
      list[index] = `${fnsku}: ${qty + itemQty}`;
    } else {
      list.push(`${fnsku}: ${qty}`);
    }
  });

  // eslint-disable-next-line no-plusplus
  for (let page = 1; page <= numberOfBox; page++) {
    pdfDoc = generateMix2DLabelPdf({
      shipmentId,
      userName,
      pdfDoc,
      barcode,
      totalQuantity,
      list,
      shipmentName,
      title,
      is2D,
      locations,
      dateCreated,
      printLocation,
      page,
      ship,
      PageSize,
      PageStartIndex,
    });

    if (page < numberOfBox) {
      pdfDoc.addPage([width, height], 'p');
    }
  }
  pdfDoc.save(`FBA_label_${outbound_shipment_id}.pdf`);
  pdfDoc = pdfDoc.output('datauristring');
  return pdfDoc;
}

export default mix2DLablePds;
