import React, { useEffect, useState } from "react";
import { Button, Form, Input, notification, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { STATES } from "../../../constants/vendors";
import { countries } from "../../../constants/Countries";

import {
  GetWarehouseAddress,
  UpdateCompanyAddress,
  CreateWarehouseAddress
} from "../../../slices/fba-shipment";

import { VenderShipmentAddressWrapper } from "../style";

const Option = Select.Option;
const WarehouseShipmentAddress = ({
  setDraftPo,
  draft_po,
  setDisableNext,
  setWarehouseDescription,
  setAccordianKey
}) => {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth || {});

  const { warehouseAddresses, loading } = useSelector((store) => store.fbaShipment);
  const [editCard, setEditCard] = useState();
  const [address, setAddress] = useState({ country: 'US' });
  const [warehouseMapping, setWarehouseMapping] = useState([]);

  const statesOptions = STATES.map(({ value, name }, index) => {
    return (
      <Option value={name} key={index} label={name}>
        {name}
      </Option>
    );
  });

  const countryList = countries?.map(({ name, code }, index) => {
    return (
      <Option key={code} value={code}>
        {name}
      </Option>
    );
  });

  useEffect(() => {
    if (currentUser?.company_id) dispatch(GetWarehouseAddress(currentUser?.company_id));
  }, []);

  useEffect(() => {
    if (editCard && editCard !== 'add new address') {
      setAddress(warehouseAddresses?.find(x => x.id === editCard));
    }
  }, [editCard]);

  useEffect(() => {
    if (warehouseAddresses?.length) {
      const defaultAddress = warehouseAddresses?.find(a => a.default_address);
      if (defaultAddress && !draft_po?.ship_to_address_id) {
        setDraftPo({
          ...draft_po,
          ship_to_address_id: defaultAddress?.id
        });
        setWarehouseDescription(defaultAddress?.title);
      }
      setWarehouseMapping([
        ...warehouseAddresses?.filter(a => a.default_address),
        ...warehouseAddresses?.filter(a => !a.default_address),
      ]);
    }

    if (!warehouseAddresses?.length) setEditCard('add new address');
    else setEditCard('');
  }, [warehouseAddresses]);

  const selectedAddress = ({ address, setAddress }) => {

    return address?.country === 'US' ? <Select
      placeholder='Select State'
      showSearch
      maxTagCount={1}
      maxTagTextLength={6}
      allowClear
      style={{ width: '100%' }}
      value={address?.state_or_province}
      onChange={(value) => {
        setAddress({
          ...address,
          state_or_province: value
        })
      }}
    >
      {statesOptions}
    </Select> : <Input
      value={address?.state_or_province}
      onChange={(e) => {
        if (e.target.value && !isNaN(e.target.value)) return;
        setAddress({
          ...address,
          state_or_province: e.target.value
        })
      }}
    />
  };

  const AddressForm = ({
    addressData
  }) => {
    const [address, setAddress] = useState(addressData);
    return <div className="input-form-wrapper">
      <div className="input-overlay-wrapper">
        <Form.Item name="name" label="Name">
          <Input
            value={address?.title}
            onChange={(e) => {
              setAddress({
                ...address,
                title: e.target.value?.trimStart()
              })
            }}
          />
        </Form.Item>
      </div>
      <Form.Item name="name" label="Street">
        <Input
          value={address?.address_line_1}
          onChange={(e) => {
            setAddress({
              ...address,
              address_line_1: e.target.value?.trimStart()
            })
          }}
        />
      </Form.Item>
      <Form.Item name="name" label="Street 2">
        <Input
          value={address?.address_line_2}
          onChange={(e) => {
            setAddress({
              ...address,
              address_line_2: e.target.value?.trimStart()
            })
          }}
        />
      </Form.Item>
      <div className="input-overlay-wrapper">
        <Form.Item name="name" label="City">
          <Input
            value={address?.city}
            onChange={(e) => {
              if (e.target.value && !isNaN(e.target.value)) return;
              setAddress({
                ...address,
                city: e.target.value
              })
            }}
          />
        </Form.Item>
        <Form.Item name="name" label="State">
          {selectedAddress({ address, setAddress })}
        </Form.Item>
      </div>
      <div className="input-overlay-wrapper">
        <Form.Item name="name" label="Country">
          <Select
            showSearch
            maxTagCount={1}
            maxTagTextLength={6}
            allowClear
            style={{ width: '100%' }}
            value={address?.country}
            filterOption={(input, option) => {
              return option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
            }}
            placeholder='Select Country'
            onChange={(value) => {
              setAddress({
                ...address,
                country: value,
                state_or_province: ''
              });
            }}
          >
            {countryList}
          </Select>
        </Form.Item>
        <Form.Item name="name" label="Zip code">
          <Input
            value={address?.zipcode}
            onChange={(e) => {
              if (isNaN(e.target.value) || e.target.value < 0) return;
              setAddress({
                ...address,
                zipcode: e.target.value
              })
            }}
          />
        </Form.Item>
      </div>
      <div className="inner-buttons-wrapper">
        <Button
          className="button"
          type="primary"
          onClick={() => {
            setDisableNext(false);
            setAddress({});
            setEditCard('');
          }}>
          Cancel
        </Button>
        <Button
          className="button"
          type="primary"
          disabled={
            !address?.title || !address?.zipcode || !address?.city || !address?.country || !address?.state_or_province ||
            !address?.address_line_1}
          onClick={() => {
            if (String(address.zipcode)?.length < 4 || String(address.zipcode)?.length > 6) {
              notification.info({
                message: "Address Validation",
                description: 'Postal length must in between 4 to 6.',
                top: 65
              });
              return;
            }
            if (editCard === 'add new address') {
              dispatch(CreateWarehouseAddress({ address, id: currentUser?.company_id })).then(({ payload }) => {
                if (payload?.success) {
                  setEditCard("");
                  setDisableNext(false);
                  if (currentUser?.company_id) dispatch(GetWarehouseAddress(currentUser?.company_id));
                }
              });
            } else {
              dispatch(UpdateCompanyAddress({ id: editCard, address })).then(({ payload }) => {
                if (payload?.success) {
                  setEditCard("");
                  setDisableNext(false);
                  if (currentUser?.company_id) dispatch(GetWarehouseAddress(currentUser?.company_id));
                }
              });
            }
          }}>
          Confirm
        </Button>
      </div>
    </div>
  }

  return (
    <VenderShipmentAddressWrapper>
      <Spin tip='Loading...' spinning={loading}>
        {editCard && (
          <AddressForm addressData={address} />
        )}
        {warehouseAddresses && (
          <>
            <Button onClick={() => {
              setEditCard('add new address');
              setDisableNext(true);
              setAddress({
                country: 'US'
              });
            }} className="add-new-address-button">Add New Address</Button>
            <div className="fix-height-warehouse-address ">
              <div className="card-overlay-wrapper">
                {
                  warehouseMapping?.map(({
                    id,
                    address_line_1,
                    city,
                    country,
                    zipcode,
                    title,
                    default_address
                  }) => {
                    return <div
                      onClick={() => {
                        setDraftPo({
                          ...draft_po,
                          ship_to_address_id: id
                        });
                        setWarehouseDescription(title);
                        setAccordianKey("3");
                      }}
                      className={`card-item ${id === draft_po?.ship_to_address_id ? "active" : null}`}
                    >
                      <div className="text-wrapper">
                        <p>{title}</p>
                        <p>{address_line_1}</p>
                        <p>{country} {city}, {zipcode}</p>
                      </div>
                      <div>
                        <Button onClick={(e) => {
                          setEditCard(id);
                          setDisableNext(true);
                        }}>Edit</Button>
                        {default_address && <div style={{ color: '#929292' }}>Default</div>}
                      </div>
                    </div>
                  })
                }
              </div>
            </div>
          </>
        )}
      </Spin>
    </VenderShipmentAddressWrapper>
  );
};

export default WarehouseShipmentAddress;
