import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  wallet: {},
  walletTransactions: [],
  walletTransactionsCount: 0,
  walletInvoices: [],
  walletInvoicesCount: 0,
  invoiceLastChargeDate: '',
  walletTransactionsFilters: [],
  walletInvoicesFilters: [],
  walletTransactionsPagination: {
    pageNumber: 1,
    pageSize: 25
  },
  walletInvoicesPagination: {
    pageNumber: 1,
    pageSize: 25
  },
};

const fbmWallet = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'ADD_FILTERS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'RESET_FILTERS': {
      return {
        ...state,
        walletTransactionsFilters: [],
        walletInvoicesFilters: [],
      };
    }
    case 'GET_WALLET_INVOICES_REQUEST': {
      return {
        ...state,
        fetching: true,
        walletInvoices: [],
      };
    }
    case 'GET_WALLET_INVOICES_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      };
    }
    case 'GET_WALLET_INVOICES_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_WALLET_TRANSACTIONS_REQUEST': {
      return {
        ...state,
        fetching: true,
        walletTransactions: [],
      };
    }
    case 'GET_WALLET_TRANSACTIONS_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      };
    }
    case 'GET_WALLET_TRANSACTIONS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_WALLET_REQUEST': {
      return {
        ...state,
        fetching: true,
        wallet: {},
      };
    }
    case 'GET_WALLET_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      };
    }
    case 'GET_WALLET_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'UPDATE_WALLET_REQUEST': {
      return {
        ...state,
        fetching: true,
      };
    }
    case 'UPDATE_WALLET_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      };
    }
    case 'UPDATE_WALLET_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_PAGE_NO': {
      if (action.payload.grid === 'invoice') {
        return {
          ...state,
          walletInvoicesPagination: {
            ...state.walletInvoicesPagination,
            pageNumber: action.payload.pageNumber
          }
        }
      } else {
        return {
          ...state,
          walletTransactionsPagination: {
            ...state.walletTransactionsPagination,
            pageNumber: action.payload.pageNumber
          }
        }
      }
    }
    case 'SET_PER_PAGE': {
      if (action.payload.grid === 'invoice') {
        return {
          ...state,
          walletInvoicesPagination: {
            pageNumber: 1,
            pageSize: action.payload.pageSize
          }
        }
      } else {
        return {
          ...state,
          walletTransactionsPagination: {
            pageNumber: 1,
            pageSize: action.payload.pageSize
          }
        }
      }
    }
    case 'CHARGING_CREDIT_REQUEST': {
      return {
        ...state,
        fetching: true,
      };
    }
    case 'CHARGING_CREDIT_SUCCESS': {
      return {
        ...state,
        fetching: false,
      };
    }
    case 'CHARGING_CREDIT_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    default:
      return state;
  }
};

export default fbmWallet;
