import React from "react";
import { TilesPagination } from "./style";

const Index = (props) => {
  const { total, onClick, offset } = props
  const isDisabled = (offset === total);

  return (
    <TilesPagination>
      {(offset > 0) ? 1 : 0} – {offset} of {total} items
      <div className="ps-2 text-center d-inline" onClick={() => !isDisabled && onClick()}>
        <span className={`${isDisabled ? '' : 'color-primary'} fw-bold ${isDisabled ? '' : 'cursor-pointer'}`}>
          <b>Load More</b>
        </span>
      </div>
    </TilesPagination>
  )
}
export default Index;
