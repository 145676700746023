import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  invoices: [],
  total_records: 0,
  filters: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  updatedInvoice: {}
};

const removalInvoices = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'SET_FILTERS_USER_REMOVAL_INVOICES': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_SORT_OPTIONS_USER_REMOVAL_INVOICES': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_REMOVAL_INVOICES_FOR_USER_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_REMOVAL_INVOICES_FOR_USER_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      };
    }
    case 'GET_REMOVAL_INVOICES_FOR_USER_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_PAGINATION_USER_REMOVAL_INVOICES': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    default:
      return state;
  }
};

export default removalInvoices;
