import React, { lazy } from 'react';

const SearchContainer = lazy(() => import('../../containers/fbaRemovalShipments/Search'));
const DetailsContainer = lazy(() => import('../../containers/fbaRemovalShipments/Details'));
const MasterRemovalOrdersContainer = lazy(() => import('../../containers/MasterRemovalOrdersContainer'));
const UserRemovalsContainer = lazy(() => import('../../containers/UserRemovalsContainer'));
const BeltRemovalContainer = lazy(() => import('../../containers/beltRemovalContainer'));
const Scan2DContainer = lazy(() => import('../../containers/fbaRemovalShipments/Scan2D'));

export const REMOVALS_ROUTES = [
  {
    path: '/fba-removal-shipments/search',
    component: <SearchContainer />,
    group: 'Removals',
    reducer: 'fbaremovalshipments'
  },
  {
    path: '/fba-removal-shipments/:id',
    component: <DetailsContainer />,
    group: 'Removals',
    reducer: 'removalDetails'
  },
  {
    path: '/received-removal-orders',
    component: <MasterRemovalOrdersContainer />,
    group: 'Removals',
    reducer: 'masterRemovalOrders'
  },
  {
    path: '/user-removals',
    component: <UserRemovalsContainer />,
    group: 'Removals',
    reducer: 'userRemovals'
  },
  {
    path: '/belt-removal/:shipmentid/:fnskus',
    component: <BeltRemovalContainer />,
    group: 'Removals',
    reducer: 'beltRemovals'
  },
  {
    path: '/belt-removal/scan',
    component: <Scan2DContainer />,
    group: 'Removals',
    reducer: []
  }
];
