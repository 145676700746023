import Styled from "styled-components";

const WarehouseWrapper = Styled.div`
.image-overlay-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    grid-gap: 16px;
    max-width:248px;
    img {
        object-fit: contain;
    }
}
.download-btn {
    font-size: 12px;
}
.product-image {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    border: 1px solid #00A3FF;
    padding: 6px;
    cursor: pointer;
}
.image-count-wrapper {
    display: flex;
    align-items: center;
    grid-gap:8px;
    span.product-count-label {
        width: 35px;
        height: 35px;
        background: aliceblue;
        padding: 6px 9px;
        color: #1565D8;
        font-weight: 600;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
.grid-column-gap-12{
    grid-column-gap:12px;
}
.recent-grey{
    color: ${({ theme }) => theme["regent_gray"]};
}
.grid-column-gap-2{
    grid-column-gap:2px;
}
.table-icon-centered{
    padding-top:8px;
}
&.unassigned-location{
    .table-icon-centered{
        padding-top:12px;
    }
}
.drawer-padding{
    padding:24px;
    height:100vh;
    overflow:auto;
    .marketplace-information{
    border: 1px solid ${({ theme }) => theme["outline-color"]};
    background-color: ${({ theme }) => theme["outline-color"]}20;
    border-radius:${({ theme }) => theme["border-radius-base"]};
    padding:16px 12px;
    @media(max-width:1400px){
        grid-column-gap:8px;
    }
    h3{
        letter-spacing:0.2px;
    }
    .drawer-img {
     max-width: 90px !important;
    }
    .info{
        margin-top:15px;
        div{
            min-width:287px;
        }
        }
        .d-flex{
        &.padding-left:nth-child(2){
            min-width:203px;
            img{
                margin-top:9px;
            }
        }
      .d-flex{
        &.padding-left:nth-child(3){
            min-width: 169px;
            .sales-badge{
                    width:64px;
                    margin-top:5px;
                    padding:0 6px;
            }
        }
    }
       .d-flex{
        &.padding-left:nth-child(4){
            min-width: 115px; 
        }
    }
    }
}
.drawer-overflow{
    overflow-x:hidden!important;
}
.upload-description
{
    max-width: 357px;
    margin: 0 auto;
    margin-top: 10px;
    padding-left: 1px;
    min-height:36px;
    p{
        font-size:${({theme})=> theme['base-font-size-small']};
    color:${({theme})=> theme['secondary-color']};
    margin-bottom:0;
    }
}
.relabel-description
{
    max-width: 357px;
    margin: 0 auto;
    margin-top: 10px;
    padding-left: 1px;
    min-height:36px;
    p{
        font-size:${({theme})=> theme['base-font-size-medium']};
    color:${({theme})=> theme['secondary-color']};
    margin-bottom:0;
    }
}
.info{
    margin-top:17px;
    margin-left:1px;
    &.cost-details{
         margin-top:16px;
           .details{
              padding-top:16px;
           }
    }
    &.source-order{
          margin-top:15px;
          margin-right:10px;
           .details{
              padding-top:13px;
           }
    }
    .details{
        flex-wrap:wrap;
        padding-top:18px;
        &.inputs{
            div{
            margin-bottom:0px;
        }
        }
        div{
            margin-bottom:8px;
        }
    }
}
}
.back-icon{
    grid-column-gap:13px;
    span{
        padding-left:6px;
    }
}

.right-content{
        margin-right: 13px;
    margin-top: 1px;
    .icon-filter{
        padding-left:21px;
    }
    .search-input{
          padding-left:12px;
    }
    .icon-column{
        padding-left:25px;
    }
    .pl-12{
        padding-left:12px;
    }
}
.date-picker{
    .rdrDateRangePickerWrapper{
    position: absolute;
    z-index: 1;
    box-shadow: 0 0 10px #ccc;
    left: -83px;
    top: 52px;  
    }
}
.search-input{
    position: absolute;
    top: 120px;
    right: 21px;
    z-index: 120;
    box-shadow: 0 0 10px #ccc;
        input{
            border: 1px solid #CFD8DC;
            color: ${({ theme }) => theme["cutty_sark"]};
            font-size: ${({ theme }) =>
        theme["medium-font-size"]};
            line-height:1.7;
            height:32px;
            min-width:288px;
            &:focus{
            border-color:#CFD8DC;
            box-shadow:none;
            color: ${({ theme }) => theme["cutty_sark"]};
            }
            &::placeholder{
            font-size: ${({ theme }) =>
        theme["medium-font-size"]};
            color: ${({ theme }) => theme["gray-color"]};
            }
    }
}
.name-details{
    background: ${({ theme }) => theme["aqua_haze"]};
    display:flex;
    padding:9px 23px;
    border: 1px solid #CFD8DC;
    border-radius:8px;
    margin-top: 21px;
    margin-left: 5px;
    justify-content:space-between;
    .info{
        span:first-child{
            font-size: ${({ theme }) => theme["base-font-size-small"]};
        }
        span:nth-child(2){
            font-size: ${({ theme }) => theme["medium-font-size"]};
            color:${({ theme }) => theme["black-color"]};
            padding-left: 5px;
            &.success-badge{
                background-color:${({ theme }) => theme["success-color"]};
                color:${({ theme }) => theme["white-color"]};
                border-radius: 50px;
                padding: 0 10px;
            }
        }
        svg{
            width:10px;
            margin-right: 5px;
            margin-bottom: 3px;
        }
    }
}

.empty-data-container{
    .no-active-store-notification{
        background: ${({ theme }) =>
        theme["info-color"]};
        border: 1px solid #00A3FF;
        border-radius: 4px;
        display: flex;
        align-items:center;
        gap: 8px;
        padding: 8px 12px;
        padding-right:18px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color:${({ theme }) =>
        theme["black-color"]};
        .notification-icon{
            width: 20px;
        }
        .notification-text{
            display: flex;
            gap:8px;
            align-items:center;
        }
        &.drawer-no-active-store-notification{
            font-size:12px;
            line-height:14px;
            .notification-icon{
                width:16px;
            }
        }
    }
}  
.platform-list {
    gap: 16px;
    .platform-check {
        width: 137px;
        height: 74px;
        border: 1px solid ${({ theme }) => theme["outline-color"]};
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor:pointer;
        img {
            width: 90px;
            height: 25px;
        }
        div {
         position: absolute;
         top: 2px;
         left: 8px;
        }
         .ant-checkbox-inner {
            width: 12px;
            height: 12px;
            &::after {
                width: 4px;
                height: 7px;
            }
         }
    }
    .active-platform {
        border: 1px solid ${({ theme }) => theme["primary-color"]};
    }
}
`;
export { WarehouseWrapper };
