import Styled from 'styled-components';
const StoreWrapper = Styled.div`
.change-background{
  background-color: ${({ theme }) => theme['aqua_haze']};   
  padding-top:61px;
  height:100vh;
  .automated-prep{
    margin: 24px;
    border-radius:4px;
    .iframe{
      padding-top:36px;
    }
    .paragrapgh-space{
      padding-bottom:36px;
    }
    .iframe-width{
      width:897px;
      height:503px;
    }
    .add-spacing{
      margin-top:36px;
    }
  }
}
  .add-store{
      background-color: ${({ theme }) => theme['white-color']};
      margin:24px;
      padding:24px;
      height: calc(100vh - 114px);
      overflow-y: auto;
      border-radius:5px;
      margin:30px 36px;
      h1{
          color: ${({ theme }) => theme['secondary-color']};
      }
      .s-mp{
        height: calc(100vh - 300px);
        h2{
          color: ${({ theme }) => theme['primary-color']};
          margin-bottom:48px;
        }

        .add_account{
          border: 1px solid #CFD8DC;
          text-align:center;
          position: relative;
          min-height: 226px;
          margin-bottom:10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius:5px;
          cursor:pointer;
          &_div{
                background-color: ${({ theme }) => theme['primary-color']};
                text-align:center;
                position: absolute;
                bottom:0;
                width:100%;
                padding:19.5px 0;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                h3{
                    color: ${({ theme }) => theme['white-color']};
                    font-size:${({ theme }) => theme['base-font-size']};
                    margin-bottom:0;
                }
          }
        }

        .make_disable {
          opacity: 0.5;
          cursor: default;
        }
        .make_disable:active {
          pointer-events: none;
        }
    }
          .divider{
          display: flex;
          margin: 16px 0;
          color: #000000d9;
          font-weight: 500;
          font-size: 16px;
          white-space: nowrap;
          text-align: center;
          border-top: 0;
          border-top-color: #0000000f;
          clear: both;
          width: 100%;
          min-width: 100%;
          margin-top:50px;
            &::before{
              position: relative;
            top: 50%;
            width: 50%;
            border-top: 1px solid transparent;
            border-top-color: inherit;
            border-bottom: 0;
            transform: translateY(50%);
            content: "";
            }
              &::after{
              position: relative;
              top: 50%;
              width: 50%;
              border-top: 1px solid transparent;
              border-top-color: inherit;
              border-bottom: 0;
              transform: translateY(50%);
              content: "";
                  }
              }
                .inner-text{
                display: inline-block;
                  padding: 0 1em;
                  border: 1px solid #f0f0f0;
                  border-radius: 50px;
                      color: ${({ theme }) => theme['secondary-color']};
                }
          }
        }
        .select-region{
              background-color: ${({ theme }) => theme['white-color']};
              margin:24px;
              padding:24px;
              height: calc(100vh - 105px);
              overflow-y: auto;
              border-radius:5px;
               h1{
                      color: ${({ theme }) => theme['secondary-color']};
                      margin-bottom:0;
                  }
              .arrow-box{
                display: flex;
                align-items:center;
                  h1{
                      color: ${({ theme }) => theme['secondary-color']};
                      margin-bottom:0;
                  }
                  svg{
                     color: ${({ theme }) => theme['primary-color']};
                  }
          }
          .caption{
            color: ${({ theme }) => theme['cutty_sark']};
              margin-bottom:24px ;
          }
          .select_region{
                 color: ${({ theme }) => theme['base-font-size-small']};
                 font-size:${({ theme }) => theme['base-font-sizes']};
          }
          .region_marketplace{
              .add_scrolll{
                max-height: 281px;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                margin-top:10px;
                border-bottom:1px solid #EEF1F5;
                margin-bottom:20px;
                .flag-name{
                      color: ${({ theme }) => theme['secondary-color']};
                      font-size: ${({ theme }) => theme['base-font-size-small']};
                      margin-left:10px;
                }
              }
          }
          .region{
            &_block{
                border: 1px solid #CFD8DC;
                border-radius:6px;
                display: flex;
                flex-direction:column;
                align-items:center;
                justify-content:center;
                padding: 16px;
                margin-bottom:16px;
                cursor: pointer;

                  &:hover{
              box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.16);
            }
                span{
                    font-size: ${({ theme }) => theme['base-font-size']};
                    color: ${({ theme }) => theme['secondary-color']};
                }
                img{
                  margin-bottom:10px;
                }
                .round {
                          position: relative;
                          margin-right: 12px;
                          margin-top: 6px;
                        }

                        .round label {
                          background-color: #fff;
                          border: 1px solid #ccc;
                          border-radius: 50%;
                          cursor: pointer;
                          height: 16px;
                          left: 0;
                          position: absolute;
                          top: 1px;
                          width: 16px;
                        }

                        .round label:after {
                            border: 2px solid #fff;
                            border-top: none;
                            border-right: none;
                            content: "";
                            height: 4px;
                            left: 2.5px;
                            opacity: 0;
                            position: absolute;
                            top: 4px;
                            transform: rotate(-45deg);
                            width: 9px;
                        }

                        .round input[type="checkbox"] {
                          visibility: hidden;
                        }

                        .round input[type="checkbox"]:checked + label {
                          background-color: #028900;
                          border-color: #028900;
                        }

                        .round input[type="checkbox"]:checked + label:after {
                          opacity: 1;
                        }

            }

          }
        }
        .map_region{
          text-align:center;
          img{
            height: 500px;
          }
        }
        .grid-row-gap-20{
          grid-row-gap:20px;
        }
        .walmart-list{
          list-style:none;
          padding-left:0;
          margin-bottom:30px;
          li{
               font-size: ${({ theme }) => theme['base-font-size']};
                color: ${({ theme }) => theme['secondary-color']};
                margin-bottom:10px;
          }
        }
  `;


export { StoreWrapper };
