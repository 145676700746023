import React, { useState, useEffect, useRef } from "react";
import { DrawerWrapper } from "../style";
import infoIcon from "../../../assets/images/exclamation-circle.svg";
import SearchInput from "../../../components/uiComponent/inputs/searchinput/index";
import AgGrid from "../../../components/ag-grid-table/index";
import tickIcon from "../../../assets/images/tick-circle.svg";
// import NoInbound from "../../../assets/images/newImages/no-inbound.svg";
import { Tooltip, Spin, notification } from "antd";
import Modal from "../../../components/uiComponent/modal/index";
import warningIcon from "../../../assets/images/warning.svg";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, debounce, toLower, filter, cloneDeep, findIndex } from "lodash";
import moment from "moment";
import {
  GetMatchedDistroPos,
  FulfillDistroPo
} from "../../../slices/fba-inbound/to-receive";
import { setField, removeDataEntry } from "../../../slices/fba-inbound";

const FulfillPos = ({
  totalQty,
  working_quantity,
  setCurrentStep,
  pending_po_id,
  arrival_item_id,
  po_index,
  received_quantity
}) => {
  const dispatch = useDispatch();
  const searchPoRef = useRef();

  const [showModal, setShowModal] = useState(false);
  const [filteredDistroPos, setFilteredDistroPos] = useState([]);
  const [currentDistroPoIndex, setCurrentDistroPoIndex] = useState(-1);

  const {
    toReceive: {
      fetchingDistroPos,
      matchedDistroPos,
      fulfillingDistroPo
    },
  } = useSelector((state) => state.fbaInbound || {});

  useEffect(() => {
    searchPoRef?.current?.value && (searchPoRef.current.value = "");
    dispatch(GetMatchedDistroPos(pending_po_id)).then(({ payload }) => {
      const { success, poResult } = payload?.data || {};
      if (success) {
        if (!(poResult?.length)) {
          setCurrentStep(2);
          notification.info({
            top: 65,
            message: 'No Distro POs To Fulfill'
          })
        }
        else setFilteredDistroPos(poResult);
      }
    });
  }, []);

  const defaultColDef = {
    resizable: true,
    sortable: false,
    suppressMenu: true,
    suppressMovable: true,
  };

  const fulfillPoColDef = [
    {
      headerName: "Charge Date",
      maxWidth: 130,
      field: 'pending_po_index.charged_at',
      cellRenderer: ({ value }) => value ? moment(value).format('DD-MM-YYYY') : "N/A",
    },
    {
      headerName: "PO #",
      field: 'pending_po_index.po_code',
      cellRenderer: ({ value }) => value || "N/A",
      maxWidth: 130,
    },
    {
      headerName: "Quantity",
      maxWidth: 150,
      cellRendererFramework: ({ data }) => {
        const { order_quantity, distro_fulfilled_qty } = data || {};
        let remainingQty = order_quantity - distro_fulfilled_qty;
        remainingQty = (remainingQty < 0) ? 0 : remainingQty;

        return (
          <div className="d-flex gap-2">
            <div className="d-flex flex-column">
              <span className="color-secondary fs-14">{order_quantity || 0}</span>
              <span className="color-regent fs-10">Order</span>
            </div>
            <div className="d-flex flex-column">
              <span className="color-secondary fs-14">{distro_fulfilled_qty || 0}</span>
              <span className="color-regent fs-10">Fulfill</span>
            </div>
            <div className="d-flex flex-column">
              <span className="color-secondary fs-14">{remainingQty}</span>
              <span className="color-regent fs-10">Remaining</span>
            </div>
          </div>
        );
      },
    },
    {
      headerName: "Note",
      maxWidth: 150,
      field: 'pending_po_index.note',
      cellRendererFramework: ({ value }) => {
        return (
          <Tooltip title={value || ''}>
            <span>{value || 'N/A'}</span>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Status",
      maxWidth: 110,
      field: 'vendoritem.seller_sku.sku_status',
      cellRendererFramework: ({ value }) => {
        return (
          <DrawerWrapper className="mt-0">
            <span className={`${value?.toLowerCase() === "ok" ? "badge-success" : "badge-danger"}`}>
              {value?.toLowerCase() === "ok" ? "Non Gated" : "Gated"}
            </span>
          </DrawerWrapper>
        );
      },
    },
    {
      headerName: "",
      maxWidth: 70,
      cellRendererFramework: ({ node }) => {
        return (
          <div onClick={() => {
            setCurrentDistroPoIndex(node.rowIndex);
            setShowModal(true)
          }} className="cursor-pointer">
            <img src={tickIcon} alt="check" />
          </div>
        );
      },
    },
  ];

  const handleSearchPo = debounce((value) => {
    if (value) {
      setFilteredDistroPos((filteredDistroPos) =>
        filter(filteredDistroPos, (obj) => {
          const { po_code } = obj?.pending_po_index || {};

          return (
            toLower(po_code)?.includes(toLower(value))
          );
        })
      );
    } else {
      setFilteredDistroPos(matchedDistroPos);
    }
  }, 800);

  const handleFulfillDistroPo = ({ distro_pending_po_id }) => {
    dispatch(FulfillDistroPo({
      distro_pending_po_id,
      storage_pending_po_id: pending_po_id,
      arrival_item_id
    }))
      .then(({ payload }) => {
        const {
          success,
          remaining_working_quantity,
          total_distro_fulfilled_qty,
          fulfilled_qty,
          completelyFulfilled
        } = payload?.data || {};

        if (success) {
          const { origin } = window.location;
          window.open(`${origin}/fba-inbound?tab=check-in&po=${po_code}`, '_blank');
      
          setShowModal(false);

          if (completelyFulfilled) {
            // Remove from local state
            setFilteredDistroPos(filteredDistroPos =>
              filter(filteredDistroPos, (obj) => obj.id !== distro_pending_po_id)
            );

            // Remove from redux state
            dispatch(
              removeDataEntry({
                module: "toReceive",
                field: "matchedDistroPos",
                key: 'id',
                value: distro_pending_po_id,
              })
            );
          }
          else {
            // Update in local state
            const posClone = cloneDeep(filteredDistroPos);
            const localStateIndex = findIndex(posClone, { id: distro_pending_po_id });
            posClone[localStateIndex].distro_fulfilled_qty = total_distro_fulfilled_qty;
            setFilteredDistroPos(posClone);

            // Update in redux state
            const reduxStateIndex = findIndex(matchedDistroPos, { id: distro_pending_po_id });
            dispatch(
              setField({
                module: "toReceive",
                field: "matchedDistroPos",
                index: reduxStateIndex,
                key: "distro_fulfilled_qty",
                value: total_distro_fulfilled_qty,
              })
            );
          }

          dispatch(
            setField({
              module: "toReceive",
              field: "toReceivePendingPos",
              index: po_index,
              key: "working_quantity",
              value: remaining_working_quantity,
            })
          );

          dispatch(
            setField({
              module: "toReceive",
              field: "toReceivePendingPos",
              index: po_index,
              key: "received_quantity",
              value: received_quantity + fulfilled_qty,
            })
          );
        }
      });
  };

  const {
    id: distro_pending_po_id,
    order_quantity,
    distro_fulfilled_qty,
    pending_po_index: { po_code } = {}
  } = filteredDistroPos?.[currentDistroPoIndex] || {};

  const remainingQtyToFulfill = order_quantity - distro_fulfilled_qty;
  let remaining_working_quantity = working_quantity,
    total_distro_fulfilled_qty = distro_fulfilled_qty,
    fulfilled_qty = 0;

  if (working_quantity < remainingQtyToFulfill) {   // Partial Case
    remaining_working_quantity = 0;
    total_distro_fulfilled_qty = distro_fulfilled_qty + working_quantity;
    fulfilled_qty = working_quantity;
  }
  else {   // Complete Case
    remaining_working_quantity = working_quantity - remainingQtyToFulfill;
    total_distro_fulfilled_qty = distro_fulfilled_qty + remainingQtyToFulfill;
    fulfilled_qty = remainingQtyToFulfill;
  }

  return (
    <DrawerWrapper>
      <div className="info-status d-flex align-items-center gap-2">
        <img src={infoIcon} alt="info" />
        <span className="fs-12 fw-semibold color-black">
          Following POs pending to be fulfilled. Fulfill each PO independently
          by clicking on the icon.
        </span>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-3">
        <div className="d-flex gap-4 fs-12 fw-semibold">
          <span className="d-flex gap-1">
            <span className="color-regent">Total Qty:</span>
            <span className="color-secondary">{totalQty || 0}</span>
          </span>
          <span className="d-flex gap-1">
            <span className="color-regent">Remaining Qty:</span>
            <span className="color-secondary">{working_quantity || 0}</span>
          </span>
        </div>
        <SearchInput placeholder="Search by PO#"
          inputRef={searchPoRef}
          onChange={({ target }) => handleSearchPo(target.value)}
        />
      </div>
      <div className="mt-3">
        <Spin tip="Loading..." spinning={fetchingDistroPos || fulfillingDistroPo}>
          <AgGrid
            user_referrals={filteredDistroPos}
            defaultColDef={defaultColDef}
            columnDefinitions={fulfillPoColDef}
            rowHeight={60}
            className="pt-0"
            height={"630px"}
          />
        </Spin>
      </div>

      <Modal
        open={showModal}
        onHide={() => setShowModal(false)}
        closeText="No"
        saveText="Yes"
        centerAlign={true}
        className="warning-modal"
        onClose={(e) => {
          e.preventDefault();
          setShowModal(false);
        }}
        onSave={() => handleFulfillDistroPo({ distro_pending_po_id })}
      >
        <div className="d-flex flex-column align-items-center">
          <img src={warningIcon} alt="warning" />
          <span className="fw-bold fs-18 text-color mt-3">
            Are you sure you want to Fulfill
            <br />
            <span className="color-primary">{fulfilled_qty} UNITS</span> to this
            <span className="color-primary"> PO: {po_code}</span>?
          </span>
        </div>
      </Modal>
    </DrawerWrapper>
  );
};

export default FulfillPos;
