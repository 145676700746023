import React from 'react'
import { Row, Col, Spin } from 'antd'
import { isEmpty } from 'lodash';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement } from '@stripe/react-stripe-js';
import { useEffect } from 'react';

const StripePyamentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  useEffect (() => {
    const { getStripeDate } = props;
    getStripeDate(stripe, elements);
  }, [stripe, elements]);

  return (
    <form>
        <Row className="m-0" style={{display: 'flex', justifyContent: 'space-between', height: '45px'}}>
          <Col className="col-md-6">
            <CardNumberElement />
          </Col>
          <Col className="col-md-2">
            <CardExpiryElement />
          </Col>
          <Col className="col-md-2">
            <CardCvcElement />
          </Col>
        </Row>
    </form>
  );
}

export default StripePyamentForm
