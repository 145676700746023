import React, { useState, useEffect } from "react";
import { Button, Steps, Collapse, notification, Spin, Modal } from "antd";
import { useLocation } from "react-router-dom";
import { BsArrowRightShort } from "react-icons/bs";
import { BsArrowLeftShort } from "react-icons/bs";
import { BiArrowBack } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";

import EditIcon from "../../assets/images/edit.svg";

import "../../components/radioCard/style.css";
import { StepperStyleWrapper } from "./style";
import AddPurchaseOrder from "./firstStepperdata/addPurchaseOrder";
import ShipThirdPartyLogistics from "./firstStepperdata/shipThirdPartyLogistics";
import UpdateProductToPurchaseOrder from "./firstStepperdata/productToPurchaseOrder";
import GatedAsin from "./firstStepperdata/gatedAsin";
import VenderShipmentAddress from "./secondStepData/venderShipmentAddress";
import WarehouseShipmentAddress from "./firstStepperdata/wharehouseShipmentAddress";
import InboundShipment from "./secondStepData/inboundShipment";
import PricingAndFees from "./secondStepData/pricingAndFees";
import EstimateDeliveryInfo from "./secondStepData/estimateDeliveryInfo";
import MapComponent from "./firstStepperdata/map";
import DeliveryInformation from "../newPurchaseOrder/confirmDeliveryInformation/deliveryInformation";

import {
  UpdatePendingPos,
  AddGatedDetail,
  GetShipToAddress
} from "../../slices/fba-shipment";

const { Step } = Steps;

const steps = ["", "", ""];
const Stepper = ({ setOpen, poData, open, page, totalProducts }) => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const { currentUser } = useSelector((state) => state.auth || {});
  const { loading, vendorAddresses, shipToAddress, topStats, gatedPos } = useSelector((store) => store.fbaShipment);

  const [current, setCurrent] = useState(0);
  const [activeItem, setActiveItem] = useState(0);
  const [draft_po, setDraftPo] = useState({});
  const [updatedGatedPos, setGatedPos] = useState([]);
  const [shipFromAddress, setShipFromAddress] = useState({});
  const [disableNext, setDisableNext] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [accordianKey, setAccordianKey] = useState(poData ? "4" : "0");
  const [warehouseDescription, setWarehouseDescription] = useState('');
  const [carrier, setCarrier] = useState('');
  const [trackingError, setTrackingError] = useState({});
  const [newDate, setNewDate] = useState({
    carrier_name: "",
    delivery_date: "",
    tracking_info: ""
  });

  useEffect(() => {
    if (!open) resetSteps();
    else {
      setDraftPo(page === "po" ? {
        user_id: currentUser?.role_id === 5 ? currentUser?.warehouse_manager_id : currentUser?.id,
        delivery_discrepancy_window: 10
      } : {
        ...poData,
        delivery_discrepancy_window: poData?.delivery_discrepancy_window || 10,
        po_destination: poData?.po_destination || ''
      });
      if (poData) setWarehouseDescription(poData?.ship_to_address_name || '');
    }
  }, [poData, open]);

  const next = () => {
    if (current === 0 && updatedGatedPos?.length) {
      const notChecked = updatedGatedPos?.find(({ putInStorage, removeSkuFromPo }) => !putInStorage && !removeSkuFromPo);
      if (notChecked) {
        notification.error({
          message: "Action Required on Gated Asin",
          description: 'Please select "Put In Storage" or "Remove SKU" from each po item',
          top: 65
        });
        return;
      }
    }
    if (current + 1 === 1 && draft_po?.ship_to_address_id) dispatch(GetShipToAddress(draft_po?.ship_to_address_id));
    setCurrent(current + 1);
  };
  const prev = () => {
    if (current === 1 && !updatedGatedPos?.length) {
      setOpen(false);
      resetSteps();
    } else setCurrent(current - 1);

    setDisableNext(false);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const resetSteps = () => {
    setCurrent(0);
    setActiveItem(0);
    setWarehouseDescription('');
    setAccordianKey(page === "po" ? "0" : "4");
    if (!openModal) {
      setDraftPo({
        ...poData,
        user_id: currentUser?.id,
        delivery_discrepancy_window: page === "po" ? 10 : draft_po?.delivery_discrepancy_window || 10
      } || {});
      setGatedData();
    }
    setNewDate({});
  }

  useEffect(() => {
    if (state) {
      setAccordianKey("0");
    }
  }, [state]);

  useEffect(() => {
    if (poData && !loading && !gatedPos.length) {
      setCurrent(1);
      if (draft_po?.ship_to_address_id) dispatch(GetShipToAddress(draft_po?.ship_to_address_id));
    }
    if (gatedPos) setGatedData();
  }, [gatedPos]);

  const setGatedData = () => {
    setGatedPos(gatedPos?.map(({
      id,
      asin,
      sku,
      order_quantity,
      vendortitle,
      image,
      image_requested,
      remove_from_po,
      to_storage
    }) => {
      return {
        id,
        asin,
        sku,
        quantity: order_quantity,
        title: vendortitle,
        image,
        requestPicture: image_requested,
        putInStorage: to_storage,
        removeSkuFromPo: remove_from_po
      }
    }));
  }

  const handleSaveToDraft = () => {
    const { tracking_info, delivery_date } = newDate || {};
    const deliveryDates = [...draft_po?.delivery_informations_attributes];
    if (tracking_info && delivery_date) {
      const ifExists = deliveryDates?.findIndex(({ tracking_info, _destroy }) => {
        return !_destroy && newDate?.tracking_info.trim() === tracking_info.trim();
      });
      if (ifExists >= 0) {
        notification.error({
          message: "Add New Delivery Date",
          description: `Tracking id "${newDate?.tracking_info.trim()}" already exists`,
          top: 65
        });
        return;
      }
      deliveryDates.push({
        carrier_name: newDate?.carrier_name.trim(),
        delivery_date: newDate?.delivery_date.trim(),
        tracking_info: newDate?.tracking_info.trim()
      });
    } else if ((newDate?.tracking_info && !newDate?.delivery_date) || (!newDate?.tracking_info && newDate?.delivery_date)) {
      notification.error({
        message: "Add Tracking Id",
        description: 'Please Add Both Tracking Id and Delivery Date',
        top: 65
      });
      return;
    }

    dispatch(UpdatePendingPos({
      draft_po: {
        ...draft_po,
        delivery_informations_attributes: deliveryDates,
        pos: ''
      },
      poId: poData?.id
    }));
    if (updatedGatedPos?.length) {
      dispatch(AddGatedDetail(updatedGatedPos?.map(({
        id,
        requestPicture,
        putInStorage,
        removeSkuFromPo
      }) => {
        return {
          id,
          image_requested: requestPicture,
          to_storage: putInStorage,
          remove_from_po: removeSkuFromPo
        }
      })
      ));
    }
    setOpen(false);
    resetSteps();
    setCurrent(0);
    setNewDate({
      carrier_name: "",
      delivery_date: "",
      tracking_info: ""
    })
  }

  const handleConfirmPO = () => {
    let error = '';
    if (!draft_po?.ship_from_address_id) error = 'Please First Select Vendor Address.'
    else if (!draft_po?.ship_to_address_id) error = 'Please First Select Warehouse Address.';
    else if (!draft_po?.po_name) error = 'Please Enter PO Name In First Step.';
    else if (!draft_po?.vendor_id) error = 'Please Select Vendor In First Step.';

    if (error) {
      notification.error({
        message: "Confirm Shipment",
        description: error,
        top: 65
      });

      return;
    }
    const { tracking_info, delivery_date } = newDate || {};
    if ((tracking_info && !delivery_date) || (!tracking_info && delivery_date)) {
      notification.error({
        message: "Add New Delivery Date",
        description: 'Please Add Tracking Info and Delivery Date',
        top: 65
      });
      return;
    }
    if (tracking_info && delivery_date) {
      const deliveryDates = [...draft_po?.delivery_informations_attributes];
      const ifExists = deliveryDates?.findIndex(({ tracking_info, _destroy }) => {
        return !_destroy && newDate?.tracking_info.trim() === tracking_info.trim();
      });
      if (ifExists >= 0) {
        notification.error({
          message: "Add New Delivery Date",
          description: `Tracking id "${newDate?.tracking_info.trim()}" already exists`,
          top: 65
        });
        return;
      }
    }

    setOpen(false)
    setOpenModal(true);
  }

  const handleAccordionSwitch = (e) => {
    let error = ''
    if (current === 0 && e === '1' && (newDate?.tracking_info || newDate?.delivery_date)) {
      const ifExists = draft_po?.delivery_informations_attributes?.findIndex(({ tracking_info, _destroy }) => {
        return !_destroy && newDate?.tracking_info.trim() === tracking_info.trim();
      });

      if (newDate?.tracking_info && !newDate?.delivery_date) error = { delivery_date: 'Delivery Date is required' }
      else if (!newDate?.tracking_info && newDate?.delivery_date) error = { tracking_info: 'Tracking info is required' }
      else if (ifExists >= 0) error = { tracking_info: `Tracking already exists` };
      if (error) {
        setTrackingError(error);
        return;
      }

      const delivery_informations_attributes = [
        ...(draft_po?.delivery_informations_attributes || []),
        {
          carrier_name: newDate?.carrier_name.trim(),
          delivery_date: newDate?.delivery_date.trim(),
          tracking_info: newDate?.tracking_info.trim()
        }
      ];
      setDraftPo({
        ...draft_po,
        delivery_informations_attributes
      });
      setNewDate({
        carrier_name: "",
        delivery_date: "",
        tracking_info: ""
      });
      setCarrier('');
      setTrackingError({});
    }
    setTrackingError({});
    setAccordianKey(e);
  }

  useEffect(() => {
    if (draft_po?.ship_from_address_id && vendorAddresses?.length) {
      setShipFromAddress(vendorAddresses?.find(x => x.id === draft_po?.ship_from_address_id))
    } else {
      setShipFromAddress({});
    }
  }, [vendorAddresses, draft_po]);

  const { Panel } = Collapse;

  const AddProductData = [
    {
      title: "Choose your option:",
      description: "Add a Purchase order",
      ...(poData ? {
        disabled: true,
      } : {
        edit: draft_po?.po_name && draft_po?.vendor_id ? true : false
      }),
      component: <AddPurchaseOrder
        setDraftPo={setDraftPo}
        draft_po={draft_po}
        visible={open}
        setNewDate={setNewDate}
        newDate={newDate}
        setTrackingError={setTrackingError}
        trackingError={trackingError}
        carrier={carrier}
        setCarrier={setCarrier}
      />,
    },
    {
      title: "Choose PO destination:",
      description: draft_po?.po_destination === 1 ? 'Ship to 3PL' : draft_po?.po_destination === 2 ?
        'New Shipment from my warehouse to Amazon' : '',
      ...(poData ? {
        disabled: true,
      } : {
        edit: draft_po?.po_destination ? true : false,
        disabled: !draft_po?.po_name || !draft_po?.vendor_id ? true : false
      }),
      component: <ShipThirdPartyLogistics
        setDraftPo={setDraftPo}
        draft_po={draft_po}
        setWarehouseDescription={setWarehouseDescription}
        setAccordianKey={setAccordianKey}
      />,
    },
    {
      title: "Choose Shipping Warehouse",
      description: warehouseDescription,
      ...(poData ? {
        disabled: true,
      } : {
        edit: draft_po?.ship_to_address_id ? true : false,
        disabled: !draft_po?.po_destination ? true : false
      }),
      component: draft_po?.po_destination === 1 ? <MapComponent
        setDraftPo={setDraftPo}
        draft_po={draft_po}
        company_id={currentUser?.company_id}
        setWarehouseDescription={setWarehouseDescription}
        setAccordianKey={setAccordianKey}
      /> : < WarehouseShipmentAddress
        setDraftPo={setDraftPo}
        draft_po={draft_po}
        setDisableNext={setDisableNext}
        setWarehouseDescription={setWarehouseDescription}
        setAccordianKey={setAccordianKey}
      />,
    },
    {
      title: "Add products to this Purchase Order",
      ...(poData ? {
        disabled: true,
        description: `Total - ${totalProducts} Products`
      } : {
        disabled: !draft_po?.ship_to_address_id ? true : false
      }),
      component: <UpdateProductToPurchaseOrder
        nextButton={true}
        setDraftPo={setDraftPo}
        draft_po={draft_po}
        poData={poData}
        open={open}
      />,
    },
    ...(poData ? [{
      title: "These are Gated ASINs",
      component: <GatedAsin gatedPos={updatedGatedPos} setGatedPos={setGatedPos} />,
    }] : []),
  ];
  const shipmentData = [
    {
      title: "How will you inbound your shipment?",
      circle: "",
      completeText: draft_po?.inbound_type === 2 ? 'LTL pallet delivery' : draft_po?.inbound_type === 1 ?
        'Small parcel delivery' : draft_po?.inbound_type === 3 ? 'FTL pallet delivery' : "",
      icon: <BiArrowBack />,
      component: <InboundShipment setDraftPo={setDraftPo} draft_po={draft_po} />,
      complete: [1, 2, 3]?.includes(draft_po?.inbound_type)
    },
    {
      title: "Pricing and fees",
      circle: "",
      completeText: `Confirmed $${(draft_po?.prep_fee?.['Total Fee'] || 0).toFixed(2)} charge`,
      icon: <BiArrowBack />,
      component: <PricingAndFees setDraftPo={setDraftPo} draft_po={draft_po} />,
      complete: draft_po?.prep_fee?.['Total Fee'] ? true : false
    },
    {
      title: "Estimated Delivery Date and Tracking Info",
      circle: "",
      completeText: '',
      icon: <BiArrowBack />,
      component: <EstimateDeliveryInfo
        setDraftPo={setDraftPo}
        draft_po={draft_po}
        openModal={open}
        setNewDate={setNewDate}
        newDate={newDate}
      />,
    },
  ];

  const AccordionPurchaseOrder = ({
    edit,
    title,
    description,
    onClickEdit,
    disabled
  }) => {
    return (
      <StepperStyleWrapper>
        <div className="item-wrapper">
          <div className="item-heading">
            <h2 style={disabled ? { opacity: 0.5 } : {}}>{title}</h2>
            <h5 style={disabled ? { opacity: 0.5 } : {}}>{description}</h5>
          </div>
          {edit ? (
            <Button onClick={onClickEdit}>
              Change <img src={EditIcon} alt="edit icon" />
            </Button>
          ) : null}
        </div>
      </StepperStyleWrapper>
    );
  };
  const AccordionShipment = ({
    icon,
    title,
    circle,
    completeText,
    active,
    onClick,
    complete
  }) => {

    return (
      <StepperStyleWrapper>
        <div onClick={onClick} className={`item-wrapper shipment-wrapper ${active === activeItem ? 'active-accordion' : 'inactive-accordion'
          } ${complete ? 'completed-accordion' : ''}`}>
          <div className="item-heading">
            <span className="circle-ui">{circle}</span>
            <h2>{title}</h2>
            {icon}
          </div>
          {complete && <p className="complete-text">{completeText}</p>}
        </div>
      </StepperStyleWrapper>
    );
  };

  return (
    <StepperStyleWrapper>
      <Steps className="modal-stepper-ui" current={current} items={items}>
        {steps.map((item, ind) => (
          <Step key={ind} title={item} />
        ))}
      </Steps>
      {current === 0 ? (
        <div>
          <header>
            <h1>Select and Add Products</h1>
            <div className="header-data">
              <p>
                Ensure your products meet our dimension requirements and are not
                in the restricted product list.
              </p>
              <p>Battery products must be pre-approved before inbounding.</p>
            </div>
          </header>
          <div>
            <Collapse
              accordion
              // defaultActiveKey={["0"]}
              activeKey={[accordianKey]}
              onChange={handleAccordionSwitch}
              className="purchase-order-popup-wrapper"
            >
              {AddProductData?.map(
                (
                  { edit, title, description, component, onClickEdit, disabled },
                  index
                ) => (
                  <Panel
                    disabled={disabled}
                    header={
                      <AccordionPurchaseOrder
                        edit={edit}
                        title={title}
                        description={description}
                        onClickEdit={onClickEdit}
                        disabled={disabled}
                      />
                    }
                    key={index}
                  >
                    {component}
                  </Panel>
                )
              )}
            </Collapse>
          </div>
        </div>
      ) : null}
      {current === 1 ? (
        <Spin tip='Loading...' spinning={loading}>
          <div>
            <header>
              <h1>Confirm Vendor Ship From Address</h1>
              <div className="header-data">
                <div className="text-wrapper">
                  <p>
                    Enter the address where you'll be shipping your inventory
                    from.
                  </p>
                  <p>
                    You can select a previously used address or enter a new one.
                  </p>
                </div>
                {/* <div className="link-overlay-wrapper">
                  <p>Learn how to</p>
                  <a href="/purchase-order"> avoid common ship-from address mistakes.</a>
                </div> */}
              </div>
            </header>
            <VenderShipmentAddress setDraftPo={setDraftPo} draft_po={draft_po} setDisableNext={setDisableNext} />
          </div>
        </Spin>
      ) : null}
      {current === 2 ? (
        <div>
          <div className="po-in-draft-wrapper">
            <div className="title-wrapper">
              <h1>PO#: <span>{poData?.po_name || 'N/A'}</span></h1> <p>In Draft</p>
            </div>
            <div className="banner-items-wrapper">
              <div className="banner-items">
                <h2>Shipment details</h2>
                <div className="text-wrapper">
                  <p>Contains {topStats?.total_skus} SKU and {topStats?.total_bundles} Unit</p>
                </div>
              </div>
              <div className="banner-items">
                <h2>Ship from</h2>
                {
                  shipFromAddress && <div className="text-wrapper">
                    <p>{shipFromAddress?.title}</p>
                    <p>{shipFromAddress?.address_line_1}</p>
                    <p>{shipFromAddress?.city}, {shipFromAddress?.state_or_province}, {shipFromAddress?.zipcode}</p>
                  </div>
                }
              </div>
              <div className="banner-items">
                <h2>Ship to</h2>
                {
                  shipToAddress && <div className="text-wrapper">
                    <p>{shipToAddress?.title}</p>
                    <p>{shipToAddress?.address_line_1}</p>
                    <p>{shipToAddress?.city}, {shipToAddress?.state_or_province}, {shipToAddress?.zipcode}</p>
                  </div>
                }
              </div>
            </div>
          </div>
          <Collapse
            accordion
            defaultActiveKey={'0'}
            className="last-step-accordion"
          >
            {shipmentData?.map(
              (
                { icon, title, completeText, component, circle, complete },
                index
              ) => (
                <Panel
                  key={index}
                  header={
                    <AccordionShipment
                      icon={icon}
                      title={title}
                      completeText={completeText}
                      circle={circle}
                      active={index}
                      complete={complete}
                      onClick={() => setActiveItem(activeItem === index ? '' : index)}
                    />
                  }
                >
                  {component}
                </Panel>
              )
            )}
          </Collapse>
        </div>
      ) : null}

      <div>{steps[current].content}</div>
      <div className="stepper-steps-wrapper">
        {poData && current < steps.length - 1 && (
          <Button
            disabled={disableNext}
            className={disableNext ? "button disable-btn-wrapper" : "button"}
            type="primary"
            onClick={() => next()}
          >
            Next <BsArrowRightShort className="next-button" />
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            className="button-wrapper"
            type="primary"
            onClick={handleConfirmPO}
          >
            Confirm Shipment
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            className="button-outline"
            onClick={handleSaveToDraft}
          >
            Save to Drafts
          </Button>
        )}
        {current > 0 && (
          <Button className="back-fix" onClick={() => prev()}>
            <BsArrowLeftShort />I need to do fixes
          </Button>
        )}
      </div>
      <Modal
        width={780}
        visible={openModal}
        centered
        className="stepper-modal-ui"
        footer={null}
        keyboard={false}
        closable={null}
        closeIcon={null}
      >
        <DeliveryInformation
          setOpenModal={setOpenModal}
          draft_po={draft_po}
          updatedGatedPos={updatedGatedPos}
          setDraftPo={setDraftPo}
          poId={poData?.id}
          resetSteps={resetSteps}
        />
      </Modal>
    </StepperStyleWrapper>
  );
};
export default Stepper;
