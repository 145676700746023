import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  storageInvoices: [],
  totalRecords: 0,
  filters: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  updatedInvoice: {}
};

const masterStorageInvoices = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GET_USERS_FOR_MASTER_STORAGE_INVOICES_REQUEST': {
      return {
        ...state
      };
    }
    case 'GET_USERS_FOR_MASTER_STORAGE_INVOICES_SUCCESS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_USERS_FOR_MASTER_STORAGE_INVOICES_FAILED': {
      return {
        ...state
      };
    }
    case 'SET_FILTERS_MASTER_STORAGE_INVOICES': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_SORT_OPTIONS_MASTER_STORAGE_INVOICES': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'MASTER_STORAGE_INVOICES_GET_STORAGE_INVOICE_REQUEST':
    case 'PAYMENT_STORAGE_INVOICES_REQUEST':
    case 'GET_MASTER_STORAGE_INVOICES_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'PAYMENT_STORAGE_INVOICES_SUCCESS': {
      const { storage_invoice } = action.payload;
      return {
        ...state,
        fetching: false,
        updatedInvoice: storage_invoice
      };
    }
    case 'GET_MASTER_STORAGE_INVOICES_SUCCESS': {
      const { total_records, storage_invoices } = action.payload;
      return {
        ...state,
        fetching: false,
        totalRecords: total_records,
        storageInvoices: storage_invoices
      };
    }
    case 'MASTER_STORAGE_INVOICES_GET_STORAGE_INVOICE_FAILED':
    case 'PAYMENT_STORAGE_INVOICES_FAILED':
    case 'GET_MASTER_STORAGE_INVOICES_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'CLEAR_UPDATED_ROW_FOR_STORAGE_INVOICE': {
      return {
        ...state,
        updatedInvoice: {}
      };
    }
    case 'SET_PAGINATION_MASTER_STORAGE_INVOICES': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    case 'CALCULATE_TOTAL_BALANCE_FOR_REMOVAL_INVOICES_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'CALCULATE_TOTAL_BALANCE_FOR_REMOVAL_INVOICES_SUCCESS': {
      return {
        ...state,
        fetching: false,
        totalBalanceToCharge: action.payload
      };
    }
    case 'CALCULATE_TOTAL_BALANCE_FOR_REMOVAL_INVOICES_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'CONFIRM_PAYMENT_FOR_STORAGE_INVOICES_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'MASTER_STORAGE_INVOICES_GET_STORAGE_INVOICE_SUCCESS':
    case 'CONFIRM_PAYMENT_FOR_STORAGE_INVOICES_SUCCESS': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'CONFIRM_PAYMENT_FOR_STORAGE_INVOICES_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    default:
      return state;
  }
};

export default masterStorageInvoices;
