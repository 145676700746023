import React, { useState } from "react";
import { ToggleButton, ButtonGroup, ToggleButtonGroup } from "react-bootstrap";
import { ToggleButtonWrapper } from "./style";
const Index = (props) => {
  const { label,children } = props;
  return (
    <ToggleButtonWrapper>
      <div className="button-group">
       {label &&  <label>{label}</label> }
        <ButtonGroup className="mb-2">
          {children}
        </ButtonGroup>
      </div>
    </ToggleButtonWrapper>
  );
};

export default Index;
