import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Header from '../../header/header'
import { StoreWrapper } from '../style'
import { upperCase } from "lodash";
import AmazonLogo from '../../../assets/images/newImages/amazon_log_arrow.png'
import Button from '../../uiComponent/button/index';
import {
  NA_MARKETPLACES,
  EU_MARKETPLACES,
  FE_MARKETPLACES
} from '../../../constants/onBoarding';
import Check from '../../../assets/images/newImages/check.svg'
import { useSelector } from 'react-redux'

const AmazonSuccess = () => {
  const navigate = useNavigate()

  const [marketplaceImages, setMarketplaceImages] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [isBackToStores, setIsBackToStores] = useState(false);

  const {
    selectedMarketplaces,
		spApiRegion
	} = useSelector((state) => state?.store || {});

  useEffect(() => {
   
    let selected = [];
    let region = '';
    if (spApiRegion === 'eu') {
      selected = EU_MARKETPLACES;
      region = 'Europe';
    }
    else if (spApiRegion === 'na') {
      selected = NA_MARKETPLACES;
      region = 'North America';
    }
    else {
      selected = FE_MARKETPLACES;
      region = 'Far East';
    }

    const marketplaces = selected.filter(item => selectedMarketplaces.includes(item.id));
    setMarketplaceImages(marketplaces);
    setSelectedRegion(region);

    setIsBackToStores(localStorage.getItem('back-to-stores'));
  
  }, []);

  return (
    <StoreWrapper>
      <Header login={true} />
      <div  className='change-background'>
      <div className="select-region">
        <div className="mb-72">
          <h1>Add Store</h1>
        </div>
        <Row className="justify-content-center align-items-center">
          <Col lg={4} md={6} sm={12}>
            <div className="justify-content-center align-items-center d-flex flex-column grid-row-gap-20">
              <div className="mb-40">
                <img src={Check} alt="no-success" />
              </div>
              <h1 className="color-primary mb-28 fs-60">Congratulations</h1>
              <h2>Permissions Granted & Store Added Successfully!</h2>
              <div className="d-flex grid-column-gap-20 align-items-center">
                <div>
                  <img src={AmazonLogo} alt="no-marketPlace" />
                </div>
                <div className="d-flex grid-column-gap-20 flex-column align-items-center">
                  <h6>{selectedRegion}</h6>
                  <div className="d-flex grid-column-gap-10 flex-wrap align-items-center justify-content-center">
                    {marketplaceImages?.map((value) => <img className="mb-2" src={value.country} alt="no-marketplace" />)}
                  </div>
                </div>
              </div>
              <caption className="fs-14">Your store has been successfully setup, ready to be used.</caption>
              <div className="d-flex grid-column-gap-30 align-items-center">
                {
                  isBackToStores ?
                    <Button text="Back To Stores" className="primary w-160" onClick={() => {
                      localStorage.removeItem('back-to-stores');
                      localStorage.removeItem('re-authorize');
                      navigate("/account?section=stores");
                    }} /> :
                    <>
                      <Button text="Select Price Plan" className="outlined w-160" onClick={() => navigate("/onboarding/select-plan")} />
                      <Button text="Add Another Store" className="primary w-160" onClick={() => navigate("/onboarding/store-add")} />
                    </>
                }
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    </StoreWrapper>
  )
}

export default AmazonSuccess
