import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from './store'
import { Provider } from 'react-redux';
import App from './App';
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { BrowserRouter } from 'react-router-dom';

const Store = configureStore();
const persistor = persistStore(Store);

ReactDOM.render(
  <Provider store={Store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
  ,
  document.getElementById('root')
);
