import { findIndex, destroy } from 'lodash';
import { cloneDeep } from "lodash";

const initialState = {
  loading: false,
  vendors: [],
  categories: [],
  total: 0,
  filters: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
};

const vendors = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'DELETE_VENDOR_REQUEST':
    case 'UPDATE_VENDORS_REQUEST':
    case 'GET_VENDOR_CATEGORIES_REQUEST':
    case 'GET_VENDORS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'DELETE_VENDOR_SUCCESS':
    case 'DELETE_VENDOR_FAILED':
    case 'UPDATE_VENDORS_SUCCESS':
    case 'UPDATE_VENDORS_FAILED':
    case 'GET_VENDOR_CATEGORIES_FAILED':
    case 'GET_VENDORS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'GET_VENDOR_CATEGORIES_SUCCESS':
    case 'GET_VENDORS_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      };
    }
    case 'SET_PAGINATION_VENDORS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    case 'SET_SORT_OPTIONS_FOR_VENDORS':
    case 'SET_FILTERS_VENDORS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'DELETE_VENDOR_ADDRESS': {
      const { vendors } = state;
      const { vendorId, addressId } = action.payload;

      const index = findIndex(vendors, { id: vendorId });
      if (index >= 0) {
        let { addresses } = vendors[index];
        addresses = addresses || [];
        const adIndex = findIndex(addresses, { id: addressId });
        if (adIndex) {
          vendors[index].addresses[adIndex].destroy = true;
        }
      }

      return {
        ...state,
        vendors
      };
    }
    default:
      return state;
  }
};

export default vendors;
