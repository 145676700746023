import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleRejectedResponse } from '../utils';
import { fetch as axios } from '../../../axios_config';

export const GetStoragePos = createAsyncThunk(
  '/list-storage-pos',
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/list-storage-pos`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          params: data
        }
      );

      return {
        ...response,
        data: { ...response.data, page: data.page }
      };
    } catch (err) {
      return handleRejectedResponse(err, 'Fetch Storage Pos', rejectWithValue);
    }
  }
);

export const GetFulfilledDistroPos = createAsyncThunk(
  '/fulfilled-distro-pos/:storage_po_id',
  async ({ page, filters, storage_po_id }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/fulfilled-distro-pos/${storage_po_id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          params: {
            page,
            filters
          }
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Fulfilled Distro Pos', rejectWithValue);
    }
  }
);

export const GetStoragePoSkus = createAsyncThunk(
  '/po-storage-detail/:storage_po_id',
  async ({ page, filters, storage_po_id }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/po-storage-detail/${storage_po_id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          params: {
            page,
            filters
          }
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Storage PO Skus', rejectWithValue);
    }
  }
);

export const GetDistroPoSkus = createAsyncThunk(
  '/distro-pending-pos/:pending_po_index_id',
  async ({ pending_po_index_id, storage_po_id, page, filters }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/distro-pending-pos/${pending_po_index_id}`,
        {
          headers: { Authorization: `Bearer ${authToken}` },
          params: {
            storage_po_id,
            page,
            filters
          }
        }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Distro PO Skus', rejectWithValue);
    }
  }
);

export const GetDistroPoShipments = createAsyncThunk(
  '/po-shipments/:distro_po_id',
  async (distro_po_id, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const authToken = state.auth.token;
      const response = await axios.get(
        `${process.env.FBA_INBOUND_API_URL}/po-shipments/${distro_po_id}`,
        { headers: { Authorization: `Bearer ${authToken}` } }
      );
      return response;
    } catch (err) {
      return handleRejectedResponse(err, 'Get Distro PO Shipments', rejectWithValue);
    }
  }
);

export const storagePoInitialState = {
  fetchingStoragePos: false,
  storagePos: [],
  totalStoragePOsCount: 0,
  completedCount: 0,

  tabsCount: {
    distroPoCount: 0,
    poCount: 0
  },

  fetchingFulfilledDistroPos: false,
  fulfilledDistroPos: [],
  totalFulfilledDistroPosCount: 0,

  fetchingStoragePoSkus: false,
  storagePoSkus: [],
  totalStoragePoSkusCount: 0,

  fetchingDistroPoSkus: false,
  distroPoSkus: [],
  totalDistroPoSkusCount: 0,

  fetchingDistroPoShipments: false,
  distroPoShipments: []
};

export const storagePosReducers = {
  [GetStoragePos.pending]: (state, action) => ({
    ...state,
    storagePo: {
      ...state.storagePo,
      fetchingStoragePos: true
    }
  }),
  [GetStoragePos.fulfilled]: (state, action) => {
    const { count, result, completedCount, page, distroPoCount, poCount } = action?.payload?.data || {};

    return {
      ...state,
      storagePo: {
        ...state.storagePo,
        fetchingStoragePos: false,
        storagePos: (page.pageNumber === 1) ? result : [...state.storagePo.storagePos, ...result],
        totalStoragePOsCount: count,
        completedCount,
        tabsCount: { distroPoCount, poCount }
      }
    }
  },
  [GetStoragePos.rejected]: (state, action) => {
    return {
      ...state,
      storagePo: {
        ...state.storagePo,
        fetchingStoragePos: false
      }
    }
  },
  [GetFulfilledDistroPos.pending]: (state, action) => ({
    ...state,
    storagePo: {
      ...state.storagePo,
      fetchingFulfilledDistroPos: true
    }
  }),
  [GetFulfilledDistroPos.fulfilled]: (state, action) => {
    const { result, count } = action?.payload?.data || {};
    return {
      ...state,
      storagePo: {
        ...state.storagePo,
        fulfilledDistroPos: result,
        fetchingFulfilledDistroPos: false,
        totalFulfilledDistroPosCount: count
      }
    }
  },
  [GetFulfilledDistroPos.rejected]: (state, action) => {
    return {
      ...state,
      storagePo: {
        ...state.storagePo,
        fetchingFulfilledDistroPos: false
      }
    }
  },
  [GetStoragePoSkus.pending]: (state, action) => ({
    ...state,
    storagePo: {
      ...state.storagePo,
      fetchingStoragePoSkus: true
    }
  }),
  [GetStoragePoSkus.fulfilled]: (state, action) => {
    const { result, count } = action?.payload?.data || {};
    return {
      ...state,
      storagePo: {
        ...state.storagePo,
        storagePoSkus: result,
        fetchingStoragePoSkus: false,
        totalStoragePoSkusCount: count
      }
    }
  },
  [GetStoragePoSkus.rejected]: (state, action) => {
    return {
      ...state,
      storagePo: {
        ...state.storagePo,
        fetchingStoragePoSkus: false
      }
    }
  },
  [GetDistroPoSkus.pending]: (state, action) => ({
    ...state,
    storagePo: {
      ...state.storagePo,
      fetchingDistroPoSkus: true
    }
  }),
  [GetDistroPoSkus.fulfilled]: (state, action) => {
    const { result, count } = action?.payload?.data || {};
    return {
      ...state,
      storagePo: {
        ...state.storagePo,
        distroPoSkus: result,
        fetchingDistroPoSkus: false,
        totalDistroPoSkusCount: count
      }
    }
  },
  [GetDistroPoSkus.rejected]: (state, action) => {
    return {
      ...state,
      storagePo: {
        ...state.storagePo,
        fetchingDistroPoSkus: false
      }
    }
  },
  [GetDistroPoShipments.pending]: (state, action) => ({
    ...state,
    storagePo: {
      ...state.storagePo,
      fetchingDistroPoShipments: true
    }
  }),
  [GetDistroPoShipments.fulfilled]: (state, action) => {
    const { result } = action?.payload?.data || {};
    return {
      ...state,
      storagePo: {
        ...state.storagePo,
        distroPoShipments: result,
        fetchingDistroPoShipments: false,
      }
    }
  },
  [GetDistroPoShipments.rejected]: (state, action) => {
    return {
      ...state,
      storagePo: {
        ...state.storagePo,
        fetchingDistroPoShipments: false
      }
    }
  },
};
