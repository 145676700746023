import Styled from 'styled-components';
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
const DrawerWrapper = Styled(Drawer)`
    .cross-icon{
        position: absolute;
        top: 6px;
        right:18px;
    }
    .drawer-bottom{
      padding-top:60px;
      grid-column-gap:36px;
    }
  `;
export { DrawerWrapper };
