import React, { useState, useEffect } from "react";
import { HeaderWrapper } from "./style";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import WhiteLogo from "../../assets/images/logo.svg";
import BlueLogo from "../../assets/images/logo-scroll.svg";
import { color } from "highcharts";

const Index = (props) => {
  const { token } = useSelector((state) => state.auth || {});
  const [header, setHeader] = useState("")
  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader("")
    } else if (window.scrollY > 70) {
      return setHeader("headerWhite")
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  return (
    <HeaderWrapper>
      <div className={`dashboard-header ${props.className || ""}`}>
        <div className="white-sticky" >
          <nav className={`navbar navbar-default navbar-fixed-top ${header}`} role="navigation">
            <div className="header-container">
              <div className="navbar-header">
                <button
                  type="button"
                  className="navbar-toggle"
                  data-toggle="collapse"
                  data-target=".navbar-ex1-collapse"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                  <span className="icon-bar"></span>
                </button>
                {/* <div className="logo"><img src={header === "headerWhite" ? BlueLogo : WhiteLogo} alt="no-logo" />  </div> */}
                {/* <img className="for-image"  >hello</img> */}
                <div className="logo"></div>
              </div>

              <div className="collapse navbar-collapse navbar-ex1-collapse">
                <ul className="nav navbar-nav navbar-right">
                  <li>
                    <Link to="/">HOME</Link>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        // if (process.env.NODE_ENV === 'staging') window.open('https://repricer.staging.sales.support/', '_blank');
                        // else window.open('https://repricer.sales.support/', '_blank');
                        window.open('/reprice', '_self')
                      }}
                    // href={process.env.REPRICER_APP_URL}
                    >
                      REPRICER
                    </a>
                  </li>
                  {/* <li>
                    <Link to="/contact-us">CONTACT</Link>
                  </li> */}
                  <li>
                    <Link to="/threepl">3PL</Link>
                  </li>
                  <li>
                    <Link to="/terms">TERMS</Link>
                  </li>
                  <li>
                    <a href="https://thefbamachine-com.mykajabi.com/"><i className="fa fa-book"></i>TRAINING COURSE</a>
                    {/* <%= link_to ('<i className="fa fa-book"></i>' + " TRAINING COURSE" ).html_safe, "https://training.thefbamachine.com/offers/zK62aB5K/checkout/", target: "_blank" %> */}
                  </li>
                  {
                    token ?
                      <li>
                        <Link to="/reprice/dashboard">
                          <button className="outline-button">DASHBOARD</button>
                        </Link>
                      </li> :
                      <>
                        <li className="signin-btn hidden-xs">
                          <Link to="/auth/sign-in">SIGN IN</Link>
                        </li>
                        <li>
                          <Link to="/auth/sign-up">
                            <button className="outline-button">SIGN UP</button>
                          </Link>
                        </li>
                      </>
                  }
                  <li className="hidden-lg hidden-md hidden-sm"></li>
                  <li className="signin-btn hidden-xs"></li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </HeaderWrapper>
  );
};
export default Index;
