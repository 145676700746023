import React from 'react';

import MobileHome from '../../components/responsive/mobileHome';
import ScanLocation from '../../components/responsive/scanLocation';
import PickListScanLocation from '../../components/responsive/scanNewLocation';
import ScanNewLocation from '../../components/responsive/scanNewLocationt';
import LocationDetails from '../../components/responsive/locationDetails';
import LocationDetailsAdd from '../../components/responsive/locationDetailsAdd';
import ListLocations from '../../components/responsive/listLocations';
import ScanAddProduct from '../../components/responsive/scanAddProduct.js';
import ScanTransferProduct from '../../components/responsive/scanTransferProduct';
import ChooseOldLocation from '../../components/responsive/chooseOldLocation';
import PickList from '../../components/responsive/pickList';
import ScanPickProduct from '../../components/responsive/scanPickProduct';
import Locations from "../../containers/warehouse-location/userLocationItems"
import WarehouseLocation from "../../containers/warehouse-location/index"
import LocationActions from '../../containers/warehouse-location/showLocationActions';
import LocationName from "../../containers/warehouse-location/location-name"

export const STORAGE_ROUTES = [
  {
    path: '/home',
    component: <MobileHome />,
    group: 'MobileStorage'
  },
  {
    path: '/scan-location',
    component: <ScanLocation />,
    group: 'MobileStorage'
  },
  {
    path: '/scan-new-location',
    component: <ScanNewLocation />,
    group: 'MobileStorage'
  },
  {
    path: '/scan-pick-location',
    component: <PickListScanLocation />,
    group: 'MobileStorage'
  },
  {
    path: '/location-details',
    component: <LocationDetails />,
    group: 'MobileStorage'
  },
  {
    path: '/location-details-add',
    component: <LocationDetailsAdd />,
    group: 'MobileStorage'
  },
  {
    path: '/pick-list-locations',
    component: <ListLocations />,
    group: 'MobileStorage'
  },
  {
    path: '/scan-add-products',
    component: <ScanAddProduct />,
    group: 'MobileStorage'
  },
  {
    path: '/scan-transfer-products',
    component: <ScanTransferProduct />,
    group: 'MobileStorage'
  },
  {
    path: '/choose-old-location',
    component: <ChooseOldLocation />,
    group: 'MobileStorage'
  },
  {
    path: '/pick-list',
    component: <PickList />,
    group: 'MobileStorage'
  },
  {
    path: '/scan-pick-products',
    component: <ScanPickProduct />,
    group: 'MobileStorage'
  },
  {
    path: '/warehouse-location',
    component: <WarehouseLocation />,
    group: 'Storage'
  },
  {
    path: '/locations',
    component: <Locations />,
    group: 'InternalStorage'
  },
  {
    path: '/location-names',
    component: <LocationName />,
    group: 'Storage'
  },
  {
    path: '/locations-history',
    component: <LocationActions />,
    group: 'Storage'
  }
];