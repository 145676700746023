import { range } from "lodash";
import React from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { Form } from "react-bootstrap";
import { PaginationWrapper } from "./style";
import { Button } from "antd";

const Index = (props) => {
  const {
    total,
    totalPages,
    pageNumber,
    pageLimit,
    defaultValue,
    handleLimitChange,
    handlePageNumberChange,
    handlePageChangeRight,
    handlePageChangeLeft,
    refreshGridClick
  } = props;
  const itemStart =
    total > 0
      ? pageNumber * pageLimit - pageLimit + 1
      : pageNumber * pageLimit - pageLimit;
  const itemEnd =
    pageNumber * pageLimit > total ? total : pageNumber * pageLimit;
  const pageRangeOptions = range(1, totalPages, 1).map((value, index) => (
    <option key={index} value={value}>
      {value}
    </option>
  ));
  return (
    <PaginationWrapper>
      <div className="d-flex justify-content-between">
        <div className="item-per-page">
          <span className="fs-14 per-page">Items per page </span>
          <div className="form-select-custom">
            <Form.Select
              value={pageLimit}
              onChange={(e) => handleLimitChange(e.target.value)}
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </Form.Select>
          </div>
          <span className="pr-1 fs-14 total-items">{`${itemStart}-${
            itemEnd > total ? total : itemEnd
          } of ${total} items`}</span>
        </div>
        <div className="d-flex  align-items-center">
          <div className="total-pages d-flex fs-12 align-items-center">
            <span className="line-height mr-12"></span>
            <div className="form-select-custom">
              <Form.Select
                value={pageNumber}
                onChange={(e) => handlePageNumberChange(e.target.value)}
              >
                {pageRangeOptions}
              </Form.Select>
            </div>
            <span className="pr-1 me-4">{` of ${totalPages - 1} pages`}</span>
            <span className="line-height"></span>
          </div>
          <div className="arrows">
            <BiChevronLeft
              className="color-primary"
              onClick={() => {
                if (pageNumber - 1 > 0) handlePageChangeLeft(pageNumber - 1);
              }}
            />
            <span className="line-height"></span>
            <BiChevronRight
              className="color-primary"
              onClick={() => {
                if (pageNumber + 1 < totalPages) handlePageChangeRight(pageNumber + 1);
              }}
            />
          </div>
          {/* <i className="fa fa-book"></i>' */}
          <Button onClick={() => refreshGridClick()} className="reload-btn" key="2" type="primary" icon="reload"></Button>
        
        </div>
      </div>
    </PaginationWrapper>
  );
};

export default Index;
