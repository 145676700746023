import Styled from "styled-components";
const WarehouseWrapper = Styled.div`
    .warehouse{
        display: flex;
        grid-column-gap: 30px;
        &.fbm-inbound{
            grid-column-gap: 30px;
            margin-bottom: 23px;
        }
        .orders{
            border-radius:${({ theme }) => theme["border-radius-base"]};
            width:30%;
            padding: 13px 21px 11px 24px;
            .white-bg{
            width: 36px;
            height: 36px;
            background-color:${({ theme }) => theme["white-color"]};
            border-radius: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            }
            h1{
                /* letter-spacing:3px; */
            }
            &.fbm-order{
                width:26%;  
                padding: 11px 20px 14px 24px;
            }
            &.total{
            background-color: ${({ theme }) => theme["primary-color"]}03; 
            border:1px solid  ${({ theme }) => theme["primary-color"]}; 
            .icon-total::before{
                color:${({ theme }) => theme["black-color"]} ; 
            } 
            }
            &.shipped{
                background-color: ${({ theme }) => theme["success-color"]}03; 
                border:1px solid  ${({ theme }) => theme["success-color"]};   
            }
            &.storage-orders{
                background-color: ${({ theme }) => theme["success-color"]}03; 
                border:1px solid  ${({ theme }) => theme["success-color"]};   
            }
            &.missing{
                background-color: ${({ theme }) => theme["warning-color"]}05; 
                border:1px solid  ${({ theme }) => theme["warning-color"]};  
            }
            &.due-date{
                background-color: ${({ theme }) => theme["pink-color"]}03; 
                border:1px solid  ${({ theme }) => theme["pink-color"]}; 
            }
            &.standard{
                background-color: ${({ theme }) => theme["dark-yellow"]}05; 
                border:1px solid  ${({ theme }) => theme["dark-yellow"]}; 
            }
        }
      
    }  
    .search-input{
        max-width:65%;
        margin: 30px auto;
        margin-bottom: 31px;
    }
    .overflow-scroll{
        height: calc(100vh - 406px);
    .table-content{
        border: 1px solid #CFD8DC;
        border-radius:8px;
        margin-left:4px;
        margin-bottom: 16px;
        min-width: fit-content;
        max-width: 100%;
        
        .table-header{
            background-color:${({ theme }) => theme["aqua_haze"]} ;
            padding:8px 23px;
            border-top-left-radius:8px;
            border-top-right-radius:8px;
            div{
                font-size:${({ theme }) => theme["base-font-size-small"]};
                color:${({ theme }) => theme["regent_gray"]};
                font-weight:${({ theme }) => theme["bold-weight"]};
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            div:nth-child(1){
                    min-width: 335px;
                }
                div:nth-child(2){
                    min-width: 50px;
                }
                div:nth-child(3){
                    min-width: 185px;
                }
                div:nth-child(4){
                    min-width: 185px;
                }
                div:nth-child(5){
                    min-width: 165px;
                }
                div:nth-child(6){
                    min-width: 135px;
                }
                div:nth-child(7){
                  min-width: 145px;
                }
                div:nth-child(8){
                  min-width: 110px;
                }
                div:nth-child(9){
                  min-width: 275px;
                }
        }
        .table-body{
            padding: 18px 23px;
            position:relative;
            padding-bottom:0px;
            .icon-arrow-right{
                position: absolute;
                position: absolute;
                right: 24px;
                top: 22px;
            } 
            .table-tr{
                
                padding-bottom:21px;
                .title{
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 18px;
                max-width: 100%
            }
          
            div{
                font-size:${({ theme }) => theme["medium-font-size"]};
                color:${({ theme }) => theme["secondary-color"]};
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            div:nth-child(1){
                min-width: 335px;
                max-width: 257px;
                }
                div:nth-child(2){
                  min-width: 50px;
                }
                div:nth-child(3){
                    min-width: 185px;
                }
                div:nth-child(4){
                    min-width: 185px;
                }
                div:nth-child(5){
                    min-width: 165px;
                }
                div:nth-child(6){
                    min-width: 135px;
                }
                div:nth-child(7){
                  min-width: 145px;
                }
                div:nth-child(8){
                  min-width: 110px;
                }
                div:nth-child(9){
                  min-width: 275px;
                }
            &:nth-child(even){
                padding-bottom:0px;
            }
            }
              
        }
    } 
}
    .header{
        grid-column-gap: 12px;
    }
    .details{
            display: flex;
            background-color:${({ theme }) => theme["aqua_haze"]};
            border: 1px solid #CFD8DC;
            padding:19px 24px;
            border-radius: 8px;
            margin-top: 34px;
            margin-left: 5px;
            flex-direction:column;
            .details-description{
                &:first-child{
                    margin-bottom:29px;
                }
            .details-info{
                display: flex;
                flex-direction:column;
                flex-wrap:wrap;
                min-width:12.5%;
                span:nth-child(1){
                    font-size: ${({ theme }) => theme["base-font-size-small"]};
                    padding-bottom:0px;
                }
                span:nth-child(2){
                    font-size: ${({ theme }) => theme["medium-font-size"]};
                    margin-top:2px;
                }
            }
        }

        }
        .notification{
            margin-top:24px;
            background-color:${({ theme }) => theme["warning-alert"]}05;
            border:1px solid ${({ theme }) => theme["warning-alert"]};
            padding:18px 24px;
            border-radius:8px;
            font-size:16px;
            margin-left:6px;
        }
        .drawer-notification{
            margin-top: 0px !important;
            margin-left: 0px !important;
            margin-bottom: 10px;
            padding: 10px 12px;
        }
        .info-notification{
          padding: 10px 12px;
          background-color:${({ theme }) => theme["info-alert"]}05;
          border:1px solid ${({ theme }) => theme["info-alert"]};
        }
        .ship-details{
            border: 1px solid #CFD8DC;
            border-radius: 8px;
            padding-left: 24px;
            padding-right: 24px;
            margin-top: 22px;
            margin-left: 6px;
            .ship-rows{
                display: flex;
                border-bottom:1px solid ${({ theme }) =>
                  theme["outline-color"]};
                padding:23px 0px;
                .product-image{
                    padding-left: 14px;
                    padding-right: 39px;
                }
                h6{
                    margin-bottom:0;
                }
                .primary-badge{
                    font-size:${({ theme }) => theme["base-font-size-small"]};
                    color:${({ theme }) => theme["primary-color"]};
                    background-color:${({ theme }) => theme["primary-color"]}05;
                    border:1px solid ${({ theme }) => theme["primary-color"]};
                    border-radius:50px;
                    font-weight:${({ theme }) => theme["bold-weight"]};
                    padding:0px 11px;
                }
                .info{
                    padding-top:21px;
                    div{
                display: flex;
                flex-direction:column;
                flex-wrap:wrap;
                &:nth-child(1){
                    min-width: 180px;
                }
                &:nth-child(2){
                    min-width: 160px;
                }
                &:nth-child(3){
                    min-width: 90px;
                }
                &:nth-child(4){
                    min-width: 200px;
                }
                &:nth-child(5){
                    min-width: 145px;
                }
                &:nth-child(6){
                    min-width: 180px;
                }
                &:nth-child(7){
                    min-width: 150px;
                }
                span:nth-child(1){
                    font-size: ${({ theme }) => theme["base-font-size-small"]};
                    padding-bottom:0px;
                }
                span:nth-child(2){
                    font-size: ${({ theme }) => theme["medium-font-size"]};
                    margin-top:2px;
                }
                }
            }
        }
    }
    .drawer-header{
        background-color:${({ theme }) => theme["aqua_haze"]} ;
        padding:24px 30px;
    }
    .drawer-body{
        padding:25px 30px;
        .add-weight{
            margin-top:18px;
            grid-column-gap:30px;
            label{
                margin-bottom:3px;
                font-size:${({ theme }) => theme["base-font-size-small"]};
                color:${({ theme }) => theme["regent_gray"]}
            }
            .form-control{
                border-right:0;
                height:32px;
                color:${({ theme }) => theme["disabled-color"]};
                font-size:${({ theme }) => theme["medium-font-size"]};
                &::placeholder{
                color:${({ theme }) => theme["disabled-color"]};
                font-size:${({ theme }) => theme["medium-font-size"]};   
                }
            }
            .input-group-text{
                background:transparent;
                color:${({ theme }) => theme["disabled-color"]};
                font-size:${({ theme }) => theme["medium-font-size"]};
                border-left:0;
                height:32px;
            }
        }
    }
    .get-rates{
        margin-top:36px;
    }
    .carries-list{
        margin-top:32px;
        .row{
            margin-top:19px;
        } 
    }
    .united-portal{
        margin-top:36px; 
        .postal-serives{
            border: 1px solid ${({ theme }) => theme["primary-color"]};
            border-radius: 6px;
            margin-top: 12px;
            padding:24px 24px 25px 22px;

        }
        .title{
            margin-top: 15px;
        }
        .generate-label{
            margin-top:35px;
        }
      
    }
    .add-to-store{
            padding-top: 35px;
        }
    .info-status {
      background: rgba(0, 163, 255, 0.05);
      border: 1px solid ${({ theme }) => theme["information-color"]};
      border-radius: 4px;
      padding: 8px 12px;
    }
    .add-box {
      border: 1px solid ${({ theme }) => theme["primary-color"]};
      border-radius: 4px;
      width: 32px;
      height: 32px;
      color: ${({ theme }) => theme["primary-color"]};
      cursor: pointer;
    }
    .warehouse-border {
      border: 1px solid ${({ theme }) => theme["outline-color"]};
      padding: 24px 24px 24px;
      // height: calc(100vh - 160px);
      border-radius: ${({ theme }) => theme["border-radius-base"]};
     .amount {
        padding-top: 14px;
        text-align: center;
        border-radius: ${({ theme }) => theme["border-radius-base"]};
        span:first-child {
            color: ${({ theme }) => theme["secondary-color"]};
        }
        .inner-amount {
            width: 100%;
            height: 62px;
            padding: 12px 0;
            box-sizing: border-box;
            span:first-child {
                color: ${({ theme }) => theme["dusty_grey"]};
            }
        }
     }
     .border-yellow {
        border:1px solid  ${({ theme }) => theme["dark-yellow"]}; 
     }
     .yellow-bg {
        background-color: ${({ theme }) => theme["dark-yellow"]}06;
     }
     .border-blue {
        border:1px solid  ${({ theme }) => theme["primary-color"]}; 
     }
     .blue-bg {
        background-color: ${({ theme }) => theme["primary-color"]}05;
     }
     .border-green {
        border:1px solid  ${({ theme }) => theme["success-color"]}; 
     }
     .green-bg {
        background-color: ${({ theme }) => theme["success-color"]}05;
     }
    }
    /* New styling */
    .inbound-bar{
        display: flex;
        gap:36px;
        justify-content:space-between;
        align-items:center;
    }
    .inbound-buttons{
        display: flex;
        align-items:center;
        gap:32px;
        .btn{
            font-size: 14px;
            line-height: 16px; 
            padding:7px 24px;
            padding: 15px 32px;
            margin-bottom:0;
        }
    }
    .inbound-stats{
        .warehouse{
            gap:8px;
            margin-bottom:0;
            .orders.fbm-order{
                width:160px;
                padding:10px;
                align-items:center;
                &.total{
                    width:134px;
                }
                &.shipped{
                    width:147px;
                }
                &.due-date{
                    width:125px;
                }
                span{
                    padding-left:10px;
                }
            }
        }
    }
`;
const WarehouseTable = Styled.div`
   .heading{
        margin-left: 6px;
        margin-top: -3px;
        .add-gap{
            grid-column-gap: 12px;
        }
    } 
 
`
const WarehouseInvoiceWrapper = Styled.div`
  .EZDrawer__container {
    height: calc(100vh - 6vh) !important;
  }
  .EZDrawer__overlay {
    height: calc(100vh - 6vh) !important;
  }
`;

export { WarehouseWrapper, WarehouseTable, WarehouseInvoiceWrapper }