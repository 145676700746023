import React, { useState, useEffect } from 'react'
import { notification, Spin } from 'antd'
import { object, string, ref, bool } from 'yup';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Logo from "../../assets/images/newImages/new_log.png";
import ProdImg from "../../assets/images/newImages/prod-img.svg";
import { AuthWrapper, PhoneNumberWrapper } from '../profile/style'
import { Form, Row, Col, Container } from "react-bootstrap"
import InfoVideo from '../profile/component/infoVideo'
import LabelInput from '../uiComponent/inputs/labelInput'
import PhoneInput from '../uiComponent/inputs/phoneNumberInput'
import CheckBox from '../uiComponent/checkBox'
import Button from '../uiComponent/button/index'
import { BiLeftArrowAlt } from "react-icons/bi";
import { AiFillEyeInvisible, AiFillEye, AiOutlineMenu } from "react-icons/ai";
import { MarkedAsPicked, GetPickListItemsDetail, setField } from "../../slices/internalStorage";
import { isEmpty, debounce } from 'lodash';
import { MobileWrapper, Header, Content, Footer, Title, InputWrapper, CardDetail, ProductDetail, ScanFloating } from './mobile.styled';

const ScanPickProduct = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { 
      pickUpItemsList, 
      pickUpLocationDetails,
      pickItemsLoading,
      markedPickLoading
    } = useSelector(state => state?.internalStorage || {});
    const { id, image_url, quantity, stored_quantity, title, identifier, pick_up_quantity, disposed } = pickUpItemsList || {};
    const { id: storageId } = pickUpLocationDetails || {};


    useEffect(() => {
       dispatch(setField({ field: 'pickUpItemsList', value: []}));
      if(isEmpty(pickUpLocationDetails)) navigate('/pick-list-locations')
    }, []);
    

    const handleScanProduct = debounce(async(event) => {
      const value = event.target.value;
      if(!isEmpty(value)) {
        await dispatch(GetPickListItemsDetail({ id: value, storageId }));
      }
    }, 1000)


    const markAsPickedProduct = async() => {
      if (id && pick_up_quantity) {
        const response = await dispatch(MarkedAsPicked({staging_item_id: id, storage_id: storageId, pick_up_quantity }))
        if (!response.error) {
          notification.success({
            message: 'Pick Product',
            description: 'Product Picekd Successfully',
            top: 65
          });
          navigate('/home')
        }
      }
    }

    const spinLoading = pickItemsLoading || markedPickLoading;
    const disableBtn = markedPickLoading || isEmpty(pickUpItemsList);

    return (
        <MobileWrapper className=''>
            <div className='mobile-page'>
                <div className='container-fluid px-0'>
                    <Spin tip='Processing...' spinning={spinLoading}>
                    <Content className=''>
                        <Title>
                            <h2>
                                <span
                                    className="icon-arrow-left cursor-pointer" onClick={()=> navigate('/pick-list')}></span>
                                Scan Product to Pick</h2>
                        </Title>
                        <div className='content-inner'>
                        <InputWrapper className='modify-margin'>
                            <LabelInput className="input-with-icon" icon="icon-barcode" label="Scan Product Barcode" placeholder="Scan Barcode" onChange={(e) => handleScanProduct(e)} />
                        </InputWrapper>
                        {
                          !isEmpty(pickUpItemsList) && 
                         <ProductDetail>
                            <div class="d-flex align-items-center">
                                <div>
                                    <img width="42" height="42" src={image_url} alt="no-product" />
                                </div>
                                <div>
                                    <span class="fs-14 color-secondary">{title}</span>
                                </div>
                            </div>
                            <ul class="d-flex">
                                <li class="">
                                    <span class="fs-12 d-block">Identifier</span>
                                    <span class="color-primary fs-14 fw-bold">{identifier}</span>
                                </li>
                                <li class="">
                                    <span class="fs-12 recent-grey d-block">Quantity</span>
                                    <span class="fs-14">{pick_up_quantity}</span>
                                </li>
                            </ul>

                        </ProductDetail> 
                        }
                        </div>
                    </Content>
                    </Spin>
                    <Footer>
                        <Button disabled={disableBtn} className="outlined btn-block" text={disposed? "Dispose Item": "Mark as Picked"} onClick={markAsPickedProduct} />
                    </Footer>
                    {/* <ScanFloating>
                        <span className='icon-barcode'></span>
                    </ScanFloating> */}
                </div>
            </div>
        </MobileWrapper>
    )
}

export default ScanPickProduct;
