import React, { useState } from "react";
import { FormGroup, Form } from "react-bootstrap";
import Select, { createFilter, components } from "react-select";
import { SelectWrapper } from "./style";
import Overlay from "../../uiComponent/overlay";
import { isObject, isArray } from "lodash";

const ReactSelect = (props) => {
  const {
    label,
    className,
    options,
    defaultValue,
    value,
    onChange,
    inputStatus,
    disabled,
    placeholder,
    isClearable,
    tooltip,
    loading,
    maxHeight,
    width,
    marginBottom,
    menuPlacement,
    isMulti,
    allowClear
  } = props;
  const filterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    trim: true,
    matchFrom: "any",
    stringify: (option) => {
      const isLabelObject = isObject(option?.label);
      const labels = isLabelObject
        ? isArray(option?.label?.props?.children)
          ? option?.label?.props?.children?.map(
              ({ props } = {}) => props?.children
            ) || []
          : option?.label?.props?.children
        : option?.label;
      return isArray(labels) ? labels.join(" ") : labels;
    },
  };
  const InputOption = ({ isSelected, children, innerProps, ...rest }) => {
    const props = {
      ...innerProps,
    };

    return (
      <components.Option {...rest} isSelected={isSelected} innerProps={props}>
        <div className="d-flex align-items-center overflow-auto">
          <input type="checkbox" checked={isSelected} className="me-2" />
          {children}
        </div>
      </components.Option>
    );
  };

  return (
    <SelectWrapper width={width} marginBottom={marginBottom}>
      <FormGroup className={`select2-container ${className}`}>
        {label && (
          <label className="label">
            {label}
            {tooltip && (
              <Overlay
                text={<span class="icon-question"></span>}
                tooltip={tooltip}
              />
            )}
          </label>
        )}
        <Select
          isSearchable={true}
          filterOption={createFilter(filterConfig)}
          isClearable={isClearable}
          styles={{
            menu: (base) => ({ ...base, fontSize: "14px", color: "#4E5969" }),
            menuList: (base) => ({
              ...base,
              maxHeight: maxHeight ? maxHeight : 300,
            }),
          }}
          isLoading={loading}
          options={options}
          classNamePrefix="select2-selection"
          defaultValue={defaultValue}
          value={value}
          onChange={onChange}
          isDisabled={disabled}
          placeholder={placeholder}
          menuPlacement={menuPlacement}
          isMulti={isMulti}
          components={
            isMulti && {
              Option: InputOption,
            }
          }
        />
      </FormGroup>
      {inputStatus && (
        <Form.Text className="text-danger danger-border">
          {inputStatus}
        </Form.Text>
      )}
    </SelectWrapper>
  );
};
export default ReactSelect;
