import moment from 'moment';

import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  products: [],
  strategies: [],
  strategiesNames: [],
  filters: [],
  sortOptions: {},
  selectedItems: [],
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  totalProducts: 0,
  allExistingProducts: null,
  productDetails: [],
  graphFilter: {
    startDate: moment().subtract(1, 'day')
  },
  allProductStat: {
    totalProducts: 0,
    totalSkusReprice: 0,
    minMaxRequired: 0,
    atMin: 0,
    atMax: 0,
    atLowest: 0
  },
  bulkLoading: false
};

const Products = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GET_REPRICE_PRODUCTS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_REPRICE_PRODUCTS_SUCCESS': {
      const { totalProducts, strategies, allExistingProducts } = action.payload;
      let { products } = action.payload;
      products = products.map((pro) => {
        const proDup = pro;
        if (!pro.reprice) {
          proDup.reprice = {};
        }
        return proDup;
      });
      return {
        ...state,
        fetching: false,
        totalProducts,
        products,
        strategies,
        allExistingProducts
      };
    }
    case 'GET_REPRICE_PRODUCTS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_PAGINATION_PRODUCTS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    case 'GET_ALL_PRODUCT_STATS_GRAPH_REQUEST':
    case 'GET_PRODUCT_DETAILS_GRAPH_REQUEST':
    case 'UPDATE_PRODUCT_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'UPDATE_PRODUCT_SUCCESS': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'UPDATE_PRODUCT_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'UPDATE_PRODUCTS_STRATEGY_REQUEST': {
      return {
        ...state,
        bulkLoading: true
      };
    }
    case 'UPDATE_PRODUCTS_STRATEGY_SUCCESS': {
      return {
        ...state,
        bulkLoading: false
      };
    }
    case 'UPDATE_PRODUCTS_STRATEGY_FAILED': {
      return {
        ...state,
        bulkLoading: false
      };
    }
    case 'SET_KEYWORD_FILTER_FOR_PRODUCTS': {
      const { filters } = action.payload;
      return {
        ...state,
        filters
      };
    }
    case 'SET_SORT_OPTIONS_FOR_PRODUCTS': {
      const { sortOptions } = action.payload;
      return {
        ...state,
        sortOptions
      };
    }
    case 'SET_SELECTED_ITEMS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_ALL_PRODUCT_STATS_GRAPH_ERROR':
    case 'GET_ALL_PRODUCT_STATS_GRAPH_SUCCESS':
    case 'GET_PRODUCT_DETAILS_GRAPH_ERROR':
    case 'GET_PRODUCT_DETAILS_GRAPH_SUCCESS': {
      return {
        ...state,
        fetching: false,
        ...action.payload
      }
    }
    case 'SET_PRODUCT_GRAPH_FILTER': {
      return {
        ...state,
        ...action.payload
      }
    }

    default:
      return state;
  }
};

export default Products;
