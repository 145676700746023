import React from "react";
import { Form, Button } from "react-bootstrap";
import { ButtonWrapper } from "./style";
const Index = (props) => {
  const {
    variant,
    type,
    className,
    text,
    disabled,
    onClick,
    icon,
    startIcon,
    marginBottom,
    endIcon,
    flexColumn,
    minWidth,
    height,
    fontSize
  } = props

  return (
    <ButtonWrapper
      onClick={onClick}
      variant={variant}
      height={height}
      type={type}
      className={className}
      disabled={disabled}
      marginBottom={marginBottom}
      minWidth={minWidth}
      fontSize={fontSize}
    >
      <div className={`d-flex align-items-center justify-content-center gap-1 ${flexColumn ? 'flex-column' : ''}`}>
     {startIcon}
     {text && <span> {text}</span> }
        {endIcon}
      </div>
    </ButtonWrapper>
  )
};

export default Index;
