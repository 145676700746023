import { cloneDeep } from "lodash";
const initialState = {
  fetchingForGrid: false,
  fetchingForGraphData: false,
  products: [],
  productEvents: [],
  vendors: [],
  total_records: 0,
  productEventsTotalRecord: 0,
  filters: [],
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  combined_data: {},
  graph_data: {},
  skus_without_cost: 0,
  miscellaneous_charges: {
    fetchingMiscellaneousChargesData: false,
    pagination: {
      pageNumber: 1,
      pageSize: 25
    },
    miscellaneous_charges_data: [],
    miscellaneous_charges_count: 0,
  },
  startDate: '',
  endDate: ''
};

const sales = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GET_VENDOR_NAMES_REQUEST_FOR_SALES': {
      return {
        ...state
      };
    }
    case 'GET_VENDOR_NAMES_SUCCESS_FOR_SALES': {
      return {
        ...state,
        vendors: action.payload ? [...action.payload] : []
      };
    }
    case 'GET_VENDOR_NAMES_FAILED_FOR_SALES': {
      return {
        ...state
      };
    }
    case 'GET_GRAPH_DATA_REQUEST': 
    case 'GET_FILTERED_MARKET_PLACE_DATA_REQUEST': {
      return {
        ...state,
        fetchingForGraphData: true
      };
    }
    case 'GET_GRAPH_DATA_SUCCESS': {
      const { data } = action.payload
      return {
        ...state,
        ...data,
        fetchingForGraphData: false
      };
    }
    case 'GET_FILTERED_MARKET_PLACE_DATA_SUCCESS':{
      const { data } = action.payload
      const { combinedData, graphData } = data;
      return {
        ...state,
        combined_data: combinedData,
        graph_data: graphData,
        fetchingForGraphData: false
      };
    }
    case 'GET_GRAPH_DATA_FAILED': 
    case 'GET_FILTERED_MARKET_PLACE_DATA_FAILED': {
      return {
        ...state,
        fetchingForGraphData: false
      };
    }
    case 'GET_EXPORT_SALES_REQUEST':
    case 'GET_EXPORT_PRODUCTS_EVENT_REQUEST':
    case 'GET_SALES_REQUEST':
    case 'GET_FILTERED_MARKET_PLACE_PROD_REQUEST': {
      return {
        ...state,
        fetchingForGrid: true
      };
    }
    case 'GET_FILTERED_MARKET_PLACE_PROD_SUCCESS': {
      const { data } = action.payload
      const { products, count, missingCount } = data;
      return {
        ...state,
        products,
        total_records: count,
        skus_without_cost: missingCount,
        fetchingForGrid: false
      };
    }
    case 'GET_SALES_SUCCESS': {
      const { total_records, products } = action.payload;
      return {
        ...state,
        fetchingForGrid: false,
        total_records,
        products
      };
    }
    case 'GET_PRODUCTS_OTHER_EVENT_REQUEST': {
      return {
        ...state,
        fetchingForGrid: true,
        productEventsTotalRecord: 0,
        productEvents: []
      };
    }
    case 'GET_PRODUCTS_OTHER_EVENT_SUCCESS': {
      const { total_count, other_events } = action.payload;
      return {
        ...state,
        fetchingForGrid: false,
        productEventsTotalRecord: total_count,
        productEvents: other_events
      };
    }
    case 'GET_EXPORT_SALES_SUCCESS':
    case 'GET_EXPORT_SALES_FAILED':
    case 'GET_EXPORT_PRODUCTS_EVENT_SUCCESS':
    case 'GET_EXPORT_PRODUCTS_EVENT_FAILED':
    case 'GET_SALES_FAILED': 
    case 'GET_PRODUCTS_OTHER_EVENT_FAILED': 
    case 'GET_FILTERED_MARKET_PLACE_PROD_FAILED': {
      return {
        ...state,
        fetchingForGrid: false
      };
    }
    case 'UPDATE_PRODUCT_INFO_FAILED': {
      return {
        ...state,
        fetchingForGrid: false
      };
    }
    case 'SET_PAGINATION_SALES': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      }
    }
    case 'SET_FILTERS_SALES': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_SORT_OPTIONS_SALES': 
    case 'SET_DATES': {
      return {
        ...state,
        ...action.payload
      };
    }

    case 'POST_MISCELLANEOUS_CHARGE_SUCCESS': {
      return {
        ...state
      };
    }

    case 'DELETE_MISCELLANEOUS_CHARGE_SUCCESS': {
      return {
        ...state
      }
    }

    case 'UPDATE_MISCELLANEOUS_CHARGE_SUCCESS': {
      return {
        ...state
      }
    }

    case 'GET_MISCELLANEOUS_CHARGES_REQUEST': {
      return {
        ...state,
        miscellaneous_charges: {
          ...state.miscellaneous_charges,
          fetchingMiscellaneousChargesData: true
        }
      }
    }
    case 'GET_MISCELLANEOUS_CHARGES_SUCCESS':
    case 'GET_MISCELLANEOUS_CHARGES_FAILED': {
      return {
        ...state,
        miscellaneous_charges: {
          ...state.miscellaneous_charges,
          ...action.payload,
          fetchingMiscellaneousChargesData: false
        }
      };
    }

    case 'SET_PAGE_NUMBER_MISCELLAN_EXP':
    case 'SET_PAGE_SIZE_MISCELLAN_EXP': {
      return {
        ...state,
        miscellaneous_charges: {
          ...state.miscellaneous_charges,
          pagination: {
            ...state.miscellaneous_charges.pagination,
            ...action.payload
          }
        }
      }
    }

    default:
      return state;
  }
};

export default sales;
