import React, { useState } from 'react'
import { InputWrapper } from '../inputs/style'
import { Form, ProgressBar } from "react-bootstrap"

const Index = (props) => {
  const { label, type, placeholder, inputStatus, strength, className, onChange, value, disabled, min } = props

  return (
    <InputWrapper marginBottom="0px">
      <Form.Group>
        <div className="d-flex justify-content-between">
          <Form.Label>{label}</Form.Label>
        </div>
        <span className={className}>
          <Form.Control
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            disabled={disabled}
            min={min}
          />
        </span>
        <Form.Text>{inputStatus}</Form.Text>
      </Form.Group>
    </InputWrapper>
  )
}

export default Index
