import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { sumBy, isEmpty, isObject, set } from "lodash";
import { Icon, Select as LocationSelect, Spin, notification, Popconfirm, Tooltip } from "antd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Button from '../../../components/uiComponent/button'
import { BsArrowRightCircle, BsArrowLeftCircle } from "react-icons/bs";
import AgGrid from '../../../components/ag-grid-table'
import { Box, Divider, Select as MuiSelect, MenuItem, Grid } from '@mui/material'
import { InputGroup, Form, } from 'react-bootstrap'
import Input from '../../../components/uiComponent/inputs/labelInput'
import Select from '../../../components/uiComponent/select'
import { StorageWrapper, DrawerWrapper } from '../style'
import Trash from '../../../assets/images/trash.svg'
import ProductInfo from '../../../assets/images/newImages/product-info.svg';
import {FaSave} from 'react-icons/fa'

import { generateStorageBarcode } from "../../../utils/locationLabels";
import LocationModal from "../../../components/uiComponent/modal/index";

import { IsValidateAsin } from "../../../slices/fba-shipment"
import { GetVendors, AddManualProductsToStorage } from "../../../slices/fba-inbound/to-check-in"
import { GetPreSignedUrl } from "../../../slices/fba-inbound-new"
import {
  CreateNewLocation
} from "../../../slices/fba-inbound/to-receive"
const { Option } = LocationSelect;


const Storage = ({
  itemDimensions,
  userList,
  locationsList,
  setLocationsList,
  setShowStorage,
  visible
}) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const inputRef = useRef(null);

  const {
    toCheckIn: {
      checkInLoading,
      getVendorsLoading
    }
  } = useSelector((state) => state.fbaInbound || {});
  const { uploadLoading } = useSelector((state) => state.fbaInboundShipment || {});
  const [currentSlide, setCurrentSlide] = useState(0);
  const [assignedLocations, setAssignedLocations] = useState({
    quantity: '',
    // unsellable_quantity: '',
    storage_id: '',
    note: ''
  })
  const [storage, setStorage] = useState({
    identifier: '',
    quantity: '',
    vendorId: '',
    assigned_user_id: '',
  });
  const [storageData, setStorageData] = useState({
    identifier: '',
    assigned_user_id: '',
    quantity: '',
    height: '',
    width: '',
    length: '',
    weight: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [enteredData, setEnteredData] = useState([]);
  const [vendorId, setVendorId] = useState('');
  const [case_quantity, setCaseQuantity] = useState('');
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [name, setName] = useState('');
  const [vendorsList, setVendorsList] = useState([])
  const [files, setFiles] = useState([]);
  const [locName, setLocName] = useState("");
  const dimensionsValue = storageData.length !== '' || storageData.width !== '' || storageData.height !== '' ? `${storageData.length} x ${storageData.width} x ${storageData.height}` : "";
  const totalSlides = files.length;

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const resetAddToStorage = () => {
    setStorageData({
      identifier: '',
      assigned_user_id: '',
      height: '',
      quantity: '',
      width: '',
      length: '',
      weight: '',
    });
    setStorage({
      identifier: '',
      quantity: '',
      vendorId: '',
      assigned_user_id: '',
    });
    setAssignedLocations({
      quantity: '',
      // unsellable_quantity: '',
      storage_id: '',
      note: ''
    });
    setFiles([]);
    setName('');
    setImage(null);
    setCaseQuantity('');
    setEnteredData([]);
    setShowStorage(false);
  }

  const generateTooltipTitle = () => {
    if (!image || !name) {
      return "Please provide correct ASIN/UPC.";
    } else if (!storageData?.identifier) {
      return "Asin is required.";
    } else if (!storageData?.assigned_user_id) {
      return "User is required.";
    } else if (!storageData?.height || !storageData?.length || !storageData?.width || !storageData?.weight) {
      return "Dimensions are required.";
    } else if (isEmpty(enteredData)) {
      return "Quantity is required.";
    } else {
      return "";
    }
  };

  const onNameChange = (event) => {
    setLocName(event.target.value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAssignedLocations({ ...assignedLocations, [name]: value });
  };

  const handleDownloadLocationLabel = (data) => {
    const { uuid, name, warehouse_user } = data || {};
    generateStorageBarcode({
      warehouseName: warehouse_user,
      locationName: name,
      uuid,
      labelType: 'location'
    })
  };


  const handleLocationChange = (selectedOption) => {
    setAssignedLocations({
      ...assignedLocations,
      storage_id: selectedOption,
    })
  };

  const addLocation = (e) => {
    setShowModal(false);
    e.preventDefault();
    dispatch(CreateNewLocation({ storage: { name: locName } }))
      .then(({ payload }) => {
        const { success, location } = payload?.data || {};
        if (success) {
          handleDownloadLocationLabel(location);
          handleLocationChange(location.id);
          setLocationsList(locationsList => [...locationsList, [location.id, location.name]]);
          setLocName("");
          inputRef?.current?.value && (inputRef.current.value = "");
        }
      });
  };

  const handleAddItem = () => {
    if (assignedLocations.quantity > 0) {
      const newRow = { ...assignedLocations }
      setEnteredData([...enteredData, newRow])
      setAssignedLocations({
        quantity: '',
        // unsellable_quantity: '',
        storage_id: '',
        note: ''
      })
    } else {
      notification.error({
        top: 65,
        message: 'Location',
        description: 'Please add quantity.'
      });
    }
  }

  const handleDeleteItem = (index) => {
    const updatedEnteredData = [...enteredData];
    updatedEnteredData.splice(index, 1);
    setEnteredData(updatedEnteredData);
  };

  const handleGenerateStorageLabels = ({ staging_item, labelQty, expiryDate }) => {
    if (!isEmpty(staging_item)) {
      const { title, uuid, quantity, identifier, warehouse_user, stored_quantity } = staging_item || {};
      const warehouseName = isObject(warehouse_user)
        ? !isEmpty(warehouse_user)
          ? `${warehouse_user?.first_name || ""} ${warehouse_user?.last_name || ""}`
          : "N/A"
        : warehouse_user || "N/A";

      generateStorageBarcode({
        warehouseName,
        uuid,
        title,
        stored_quantity: labelQty || quantity,
        identifier,
        labelType: "product",
      });
    }
  };

  const findLocationLabel = (storageId) => {
    const location = locationsList.find(location => location[0] === storageId);
    return location ? location[1] : '';
  };

  const handleAddToStorage = () => {
    let totalQuantity = 0;
    // let totalUnsellableQuantity = 0;
    let imageUrls = [];
    totalQuantity = sumBy(enteredData, o => Number(o.quantity || 0));
    // totalUnsellableQuantity = sumBy(enteredData, o => Number(o.unsellable_quantity || 0));

    if (![10, 12].includes(storageData?.identifier?.length)) {
      return notification.error({
        top: 65,
        message: 'ASIN/UPC Validation',
        description: 'ASIN/UPC length should be equal to 10 or 12'
      });
    }

    const updatedLocations = enteredData?.map(location => {
      return {
        ...location,
        storage_id: location.storage_id || null,
        quantity: Number(location.quantity),
        // unsellable_quantity: Number(location.unsellable_quantity) || 0,
      };
    });

    if (!isEmpty(files)) {
      const fileObjects = files.map(file => ({
        content_type: file.type,
        file_name: file.name
      }));
      dispatch(GetPreSignedUrl({ files, file_list: fileObjects })).then(({ payload }) => {
        const { success, urls } = payload || {};
        imageUrls = urls.map(entry => {
          const splitUrl = entry.url.split('?');
          return splitUrl[0];
        });
        if (success) {
          addToStorage(imageUrls, totalQuantity, updatedLocations);
        } else {
          notification.error({
            top: 65,
            message: 'Upload Product Pictures',
            description: 'Failed to upload product pictures.'
          });
          return;
        }
      }
      );
    }
    else {
      addToStorage(imageUrls, totalQuantity, updatedLocations);
    }
  };

  const addToStorage = (imageUrls, totalQuantity, updatedLocations) => {
    dispatch(AddManualProductsToStorage({
      staging_item: {
        ...storageData,
        quantity: totalQuantity,
        image_urls: imageUrls,
        // unsellable_quantity: totalUnsellableQuantity || 0,
        case_quantity: case_quantity || 0,
      },
      locations: updatedLocations || [],
      vendor_id: vendorId || null,
    })).then(({ payload }) => {
      const { success, staging_item } = payload?.data || {};
      if (success) {
        setShowStorage(false);
        handleGenerateStorageLabels({ staging_item });
        notification.success({
          top: 65,
          message: 'Add To Storage',
          description: 'Product added to storage successfully.'
        });
        resetAddToStorage();
      }
    });
  };
  const CustomPrevArrow = (props) => (
    <div
      onClick={props.onClick}
      className={`slick-arrow-prev ${currentSlide + 3 === totalSlides ? 'disabled' : ''}`}
    >
      <BsArrowRightCircle />
    </div>
  );

  const CustomNextArrow = (props) => (
    <div
      onClick={props.onClick}
      className={`slick-arrow-next ${currentSlide === 0 ? 'disabled' : ''}`}
    >
           <BsArrowLeftCircle /> 
    </div>
  );
  const defaultColDef = {
    resizable: true,
    sortable: true,
    suppressMenu: true,
    suppressMovable: true,
  };
  const checkInPosColDef = [
    {
      headerName: "Date",
      cellStyle: { padding: "0px 1px" },
      cellRenderer: ({ value }) => (value ? value : "13 Oct 2023"),
      width: 187,
    },
    {
      headerName: "Time",
      cellStyle: { padding: 0 },
      cellRenderer: ({ value }) => (value ? value : "10:20 AM"),
      width: 121,
    },
    {
      headerName: "Status",
      cellStyle: { "justify-content": "flex-end", padding: 0 },
      cellRenderer: ({ value }) => (value ? value : "Location 2"),
      width: 100,
    },
    {
      headerName: "Qty",
      cellStyle: { padding: 0, margin: -3 },
      cellRenderer: ({ value }) => (value ? value : "20"),
      width: 90,
    },
  ];

  const sliderSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomPrevArrow />,
    prevArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    beforeChange: (oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    }
  };

  const handleDimensionsChange = (e) => {
    const dimensions = e.target.value.split('x')?.map(dim => Number(dim.trim()));
    const [length, width, height] = dimensions;

    setStorageData({
      ...storageData,
      length: Number(length || 0),
      width: Number(width || 0),
      height: Number(height || 0)
    });
  };

  const handleUserChange = (e) => {
    const userId = e.value;
    setStorageData({
      ...storageData,
      assigned_user_id: userId
    });
    setStorage({
      ...storage,
      assigned_user_id: e,
      vendorId: ''
    });
    setVendorId('');
    dispatch(GetVendors(userId))
      .then(({ payload }) => {
        const { vendors } = payload?.data || {};
        if (vendors?.length) {
          setVendorsList(vendors);
        }
      });
  };

  const handleAsinChange = async (e) => {
    const inputValue = e.target.value;

    setStorageData({
      ...storageData,
      identifier: inputValue
    });

    setStorage({
      ...storage,
      identifier: inputValue
    });

    if (inputValue.length === 10 || inputValue.length === 12) {
      setLoading(true)
      const { payload: asinPayload } = await dispatch(IsValidateAsin({ asin: inputValue }));
      const { small_image, name, packagelength, packagewidth, packageheight, packageweight } = asinPayload?.vendorasin || {};
      setImage(small_image);
      setName(name);

      setStorageData({
        ...storageData,
        identifier: inputValue,
        height: packageheight || '',
        length: packagelength || '',
        weight: packageweight || '',
        width: packagewidth || ''
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!visible) {
      resetAddToStorage();
    }
  }, [visible]);

  return (
    <StorageWrapper>
      <Box sx={{ height: 'calc(100vh -200px', overflow: 'auto' }}>
        <Spin tip="Loading..." spinning={checkInLoading || uploadLoading}>
          {itemDimensions ? ""

            : <>
              <Box>
                <Input
                  label="Enter ASIN/UPC"
                  className="h-32"
                  placeholder="ASIN/UPC"
                  value={storage?.identifier}
                  onChange={handleAsinChange}
                />
              </Box>
              <Box display="flex" gap={1}>
                <Select
                  label="Users"
                  placeholder="Select User"
                  options={(userList || [])?.map(({ id, full_name }) => ({
                    value: id,
                    label: full_name
                  }))}
                  value={storage?.assigned_user_id}
                  onChange={handleUserChange}
                />
                <Select
                  label="Vendors"
                  placeholder="Vendors"
                  loading={getVendorsLoading}
                  disabled={!storageData?.assigned_user_id || !vendorsList?.length || getVendorsLoading}
                  options={vendorsList?.map(({ id, name }) => {
                    return {
                      value: id,
                      label: name
                    }
                  }) || []
                  }
                  value={storage?.vendorId}
                  onChange={(e) => {
                    setVendorId(e.value);
                    setStorage({
                      ...storage,
                      vendorId: e
                    });
                  }}
                />
              </Box>
              {!isEmpty(image) && !isEmpty(name) && (
                <Spin tip='Loading...' spinning={loading}>
                  <Box display="flex" gap={1} alignItems="center">
                    <img src={image} alt="no-product" />
                    <Box fontSize="14px" color="#1565D8" fontWeight="600">
                      {name}
                    </Box>
                  </Box>
                </Spin>
              )}
              <Divider sx={{ margin: '24px 0px', borderColor: '#00A3FF', borderWidth: '1px' }} />
            </>}
          <Box>
            <h3 className="color-secondary">Add Item Dimensions</h3>
            <Spin tip='Loading...' spinning={loading}>
              <Box display="flex" gap={2}>
                <Box position='relative' className='dimensions-input'>
                  <Input
                      label="Dimensions (LxWxH)"
                      className="h-32"
                      placeholder="00 x 00 x 00"
                      disabled={storageData?.identifier ? false : true}
                      value={dimensionsValue}
                      onChange={handleDimensionsChange}
                    />
                  <span className='inches-text'>Inches</span>
                </Box>
                <DrawerWrapper>
                  <div className="d-flex add-weight">
                    <div className="d-flex flex-column">
                      <Form.Label htmlFor="basic-url">Weight</Form.Label>
                      <InputGroup className="no-wrap">
                        <Input
                          className="h-32"
                          placeholder="00"
                          value={storageData.weight}
                          onChange={(e) => {
                            const { value } = e.target;
                            if (isNaN(value) || Number(value) < 0) return;
                            setStorageData({
                              ...storageData,
                              weight: Number(value)
                            });
                          }}
                        />
                        <Select
                          value={{ value: 'lb', label: 'lb' }}
                          disabled={true}
                          options={[
                            { value: 'lb', label: 'lb' },
                            { value: 'oz', label: 'oz' },
                          ]}
                          className="input-select"
                        />
                      </InputGroup>
                    </div>
                  </div>
                </DrawerWrapper>
              </Box>
            </Spin>
            <Divider sx={{ margin: '8px 0px', borderColor: '#00A3FF', borderWidth: '1px' }} />
            <Box>
              <h3 className="color-secondary">Quantity</h3>
              <Box display="flex" gap={1.3} alignItems="flex-end">
                <Input
                  name="quantity"
                  label="Sellable Qty"
                  value={assignedLocations.quantity}
                  onChange={(e) => {
                    if (isNaN(e.target.value) || Number(e.target.value) < 0) return;
                    setAssignedLocations({ ...assignedLocations, quantity: e.target.value });
                  }}
                  className="h-32 w-60"
                  placeholder="00"
                  marginBottom='8px'
                />
                {/* <Input
                  label="Unsellable Qty"
                  name="unsellable_quantity"
                  value={assignedLocations.unsellable_quantity}
                  onChange={(e) => {
                    if (isNaN(e.target.value) || Number(e.target.value) < 0) return;
                    setAssignedLocations({ ...assignedLocations, unsellable_quantity: e.target.value });
                  }}
                  className="h-32 w-60"
                  placeholder="00"
                  marginBottom='8px'
                /> */}
                <Input
                  label="Notes (optional)"
                  name="note"
                  value={assignedLocations.note}
                  onChange={handleInputChange}
                  className="h-32"
                  placeholder="Note"
                  marginBottom='8px'
                />
                <div className="d-flex mb-2">
                  <div className="d-flex flex-column mt-1">
                    <span className="fs-12 color-regent mb-1">Location (optional)</span>
                    <LocationSelect
                      showSearch
                      optionFilterProp='label'
                      style={{ width: 148 }}
                      placeholder="Select Location"
                      listHeight={50}
                      allowClear={!!assignedLocations.storage_id}
                      value={assignedLocations.storage_id || ''}
                      className="location-select"
                      dropdownRender={(menu) => {
                        return <div>
                          {menu}
                          <Divider style={{ margin: "4px 0" }} />
                          <div
                            style={{ padding: "4px 8px 8px", cursor: "pointer" }}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => {
                              setShowModal(true);
                              setTimeout(() => { inputRef.current?.focus(); }, 0);
                            }}
                            className="d-flex align-items-center gap-2 color-primary fw-semibold"
                          >
                            <Icon type="plus" />
                            <span style={{ fontSize: '13px' }}>Add New Location</span>
                          </div>
                        </div>
                      }}
                      onChange={(val) => {
                        handleLocationChange(val);
                      }}
                    >
                      {locationsList?.map((loc) => (
                        <Option key={loc[0]} value={loc[0]} label={loc[1]}>
                          <Tooltip title={loc[1]}>
                            <span>{loc[1]}</span>
                          </Tooltip>
                        </Option>
                      )) || []}
                    </LocationSelect>
                  </div>
                  <div>
                  </div>
                </div>
                <Box>
                  <Button
                    onClick={handleAddItem}
                    startIcon={<FaSave />}
                    className="outlined padding-increase"
                    minWidth="20px"
                  />
                </Box>
              </Box>
            </Box>
            <Box className="table-data-wrapper">
              {enteredData?.map((data, index) => (
                <Box className="table-data">
                  <p>{data.quantity}</p>
                  {/* <p>{data.unsellable_quantity || '0'}</p> */}
                  <p className='note-text'>{data.note}</p>
                  <p className='location-text'>{data.storage_id ? findLocationLabel(data.storage_id) : ''}</p>
                  <Button
                    onClick={() => handleDeleteItem(index)}
                    startIcon={<img src={Trash} alt="no-delete-icon" />}
                    className="icon-button delete-icon-btn"
                    minWidth="20px"
                  />
                </Box>
              ))}
            </Box>
            <Divider sx={{ margin: '24px 0px', borderColor: '#00A3FF', borderWidth: '1px' }} />
            <h3 className="color-secondary">Pictures</h3>
            {itemDimensions && (
              <Box display="flex" gap={2}>
                <Grid container>
                  <Box
                    color="#CF0909"
                    backgroundColor="rgba(207, 9, 9, 0.10)"
                    padding="8px 12px"
                    border="1px solid #CF0909"
                    borderRadius="8px"
                    display="flex"
                    gap={1}
                    alignItems="center"
                    my={1}
                  >
                    <Box
                      width="32px"
                      height="32px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      backgroundColor="#CF0909"
                      borderRadius="50px"
                    >
                      <img src={ProductInfo} alt="no-info" />
                    </Box>
                    <Box>
                      <Box fontSize="12px" fontWeight="600" color="#000000">
                        You must upload the picture of your product to measure it. At least 6 pictures.
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Box>
            )}
            <Slider className="product-slider" {...sliderSettings}>
              {files.map((file, index) => (
                <div key={index} className='product-slider-img'>
                  <img src={URL.createObjectURL(file)} alt={`product-${index}`} width={48} height={48} />
                </div>
              ))}
            </Slider>

            <Box display="flex" justifyContent="center" gap={1} mt={1}>
              {!isEmpty(files) && (
                <Popconfirm
                  onConfirm={() => {
                    setFiles([]);
                    fileInputRef.current.value = '';
                  }}
                  placement="bottom"
                  title={`Are you sure, you want to clear the pictures ?`}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button startIcon={<span class="icon-refresh"></span>} className="outlined" minWidth="20px" />
                </Popconfirm>
              )}
              <div>
                <input
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  accept="image/*"
                  className="outlined"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                />
                <Button
                  text="Upload Product Pictures"
                  className="outlined"
                  onClick={triggerFileInput}
                />
              </div>
            </Box>
            <Box my={3}>
              <Box fontSize="18px" color="#4E5969" className='fw-bold' >Other (optional)</Box>
              <Input
                className='w-194 h-32'
                label='Case Quantity'
                placeholder='00'
                value={case_quantity}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setCaseQuantity(value);
                }}
              />
            </Box>
            {itemDimensions ?
              <>
                <Box fontSize="18px" color="#4E5969" className='fw-bold' >Previously Added</Box>
                <AgGrid
                  user_referrals={[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]}
                  defaultColDef={defaultColDef}
                  columnDefinitions={checkInPosColDef}
                  rowHeight={38}
                  height="569px"
                  className="po-checkin-quantites"
                />
              </> : ""}
            <Box display="flex" justifyContent="flex-end" gap={1} mt={3}>
              <Button className="outlined" text="Cancel" onClick={resetAddToStorage} />
              <Tooltip
                trigger='hover'
                title={generateTooltipTitle()}
              >
                <Box>
                  <Button
                    text="Add to Storage/Get Labels"
                    disabled={!storageData?.assigned_user_id || !storageData?.identifier || !storageData?.height || !storageData?.length ||
                      !storageData?.weight || !storageData?.width || isEmpty(enteredData) || !name || !image}
                    onClick={handleAddToStorage}
                  />
                </Box>
              </Tooltip>
            </Box>
          </Box>
        </Spin>
      </Box>
      <LocationModal
        size="sm"
        open={showModal}
        onHide={() => setShowModal(false)}
        saveText="Cancel"
        onSave={() => setShowModal(false)}
      >
        <DrawerWrapper>
          <span className="fs-12 fw-semibold color-gray mb-1">
            Create New Location
          </span>
          <div className="d-flex align-items-center gap-2">
            <Input
              placeholder="Enter New Location Name"
              width="210px"
              height="32px"
              marginBottom="0px"
              onChange={onNameChange}
              inputRef={inputRef}
            />
            <div
              className="add-box d-flex align-items-center justify-content-center"
              style={locName.trim() ? {} : { opacity: 0.5, pointerEvents: 'none' }}
              onClick={addLocation}
            >
              <span className="icon-save" />
            </div>
          </div>
        </DrawerWrapper>
      </LocationModal>
    </StorageWrapper>
  )
}
export default Storage;