/* eslint-disable import/no-extraneous-dependencies */
import axios, { awsAxios } from '../../axios_config';
// import ReactOnRails from 'react-on-rails';
import { message, notification } from 'antd';
import { findIndex, isEmpty, isArray } from 'lodash';
import {
  downloadSubscriptionInvoicePDF,
  downloadCustomerInvoice,
  downloadRemovalInvoice
} from './utils/generatePDF';

export const createUserCard = ({  platform, payment_type, token, card_name, address }) => (dispatch) => {
  dispatch({ type: 'CREATE_USER_CARD_FOR_PAYMENT_REQUEST' });

  return axios({
    method: 'post',
    url: '/api/v2/charges/add_card',
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      platform,
      payment_type,
      token,
      card_name,
      address   
    }
  }).then(({ data }) => {
    const { success, error } = data;
    if (success) {
      notification.success({
        message: 'Create Card',
        description: 'Card created successfully and set as default payment method!',
        top: 65
      });
      return dispatch({ type: 'CREATE_USER_CARD_FOR_PAYMENT_SUCCESS', success });
    }
    notification.error({
      message: 'Create Card',
      description: error,
      top: 65
    });
    return dispatch({ type: 'CREATE_USER_CARD_FOR_PAYMENT_FAILED', success });
  }).catch((error) => {
    if (axios.isCancel(error)) return dispatch({ type: 'CREATE_USER_CARD_FOR_PAYMENT_FAILED' });

    let errorMessage = null;
    const { response } = error;
    const { data, status } = response || {};
    errorMessage = data && data.message;
    let message = status === 400 ? data?.error?.[0] || 'Invalid card data': null;
    notification.error({
      message: 'Create Card',
      description: message || errorMessage || error.message,
      top: 65
    });

    return dispatch({ type: 'CREATE_USER_CARD_FOR_PAYMENT_FAILED' });
  });
};

export const updateUserCard = ({ body }) => (dispatch) => {
  dispatch({ type: 'UPDATE_USER_CARD_FOR_PAYMENT_REQUEST' });

  return axios({
    method: 'post',
    url: '/api/v2/charges/update_card',
    headers: {
      'Content-Type': 'application/json'
    },
    data: body
  }).then(({ data }) => {
    const { success, error } = data;
    if (success) {
      notification.success({
        message: 'Update Card',
        description: 'Card updated successfully!',
        top: 65
      });
      return dispatch({ type: 'UPDATE_USER_CARD_FOR_PAYMENT_SUCCESS', success });
    }
    notification.error({
      message: 'Update Card',
      description: error,
      top: 65
    });
    return dispatch({ type: 'UPDATE_USER_CARD_FOR_PAYMENT_FAILED', success });
  }).catch((error) => {
    let errorMessage = null;
    const { response } = error;
    const { data } = response || {};
    errorMessage = data && data.message;

    notification.error({
      message: 'Update Card',
      description: errorMessage || error.message,
      top: 65
    });

    return dispatch({ type: 'Update_USER_CARD_FOR_PAYMENT_FAILED' });
  });
};

export const initializeLoading = () => (dispatch) => {
  dispatch({ type: 'INITIALIZE_LOADING_FOR_TOKEN_GENERATION' });
};

export const terminateLoading = () => (dispatch) => {
  dispatch({ type: 'TERMINATE_LOADING_FOR_TOKEN_GENERATION' });
};

export const removeCardFromCustomer = ({ source_id, platform, payment_type, object }) => (dispatch) => {
  dispatch({ type: 'DELETE_CARD_REQUEST' });
  const { cardType } = object || {};
  const text = (cardType ? 'card' : 'bank_account');

  return axios({
    method: 'delete',
    url: `/api/v2/charges/delete_card`,
    params: {
      source_id,
      platform,
      payment_type
    }
  }).then(({ data }) => {
    const { success, error } = data;
    if (success) {
      notification.success({
        message: `Remove ${text}`,
        description: `${text} removed successfully!`,
        top: 65
      });
      return dispatch({ type: 'DELETE_CARD_SUCCESS' });
    }
    notification.warning({
      message: `Remove ${text}`,
      description: error,
      top: 65
    });

    return dispatch({ type: 'DELETE_CARD_FAILED' });
  }).catch((error) => {
    if (axios.isCancel(error)) return dispatch({ type: 'DELETE_CARD_FAILED' });

    notification.error({
      message: `Remove ${text}`,
      description: error.message,
      top: 65
    });

    return dispatch({ type: 'DELETE_CARD_FAILED' });
  });
};


export const retryShipmentInvoices = () => (dispatch) => {
  dispatch({ type: 'RETRY_SHIPMENT_INVOICES_REQUEST' });

  return axios({
    method: 'POST',
    url: `api/v2/invoices/retry_shipment_invoices`,
    params: {}
  }).then(({ data }) => {
    const { success, error } = data;
    if (success) {
      notification.success({
        message: 'Retry Shipment Invoices',
        description: 'Processed Successfully',
        top: 65
      });
      return dispatch({ type: 'RETRY_SHIPMENT_INVOICES_SUCCESS', success });
    }
    notification.warning({
      message: 'Retry Shipment Invoices',
      description: error || 'Request Failed',
      top: 65
    });

    return dispatch({ type: 'RETRY_SHIPMENT_INVOICES_FAILED' });
  }).catch((error) => {
    if (axios.isCancel(error)) return dispatch({ type: 'RETRY_SHIPMENT_INVOICES_FAILED' });

    notification.error({
      message: 'Retry Shipment Invoices',
      description: error.message,
      top: 65
    });

    return dispatch({ type: 'RETRY_SHIPMENT_INVOICES_FAILED' });
  });
};

export const getCardsForUser = (id, platform, payment_type) => (dispatch) => {
  dispatch({ type: 'GET_CUSTOMER_CARDS_REQUEST' });

  return axios({
    method: 'get',
    url: `/api/v2/charges/list_cards`,
    params: {
      platform,
      payment_type
    }
  }).then(({ data }) => {
    const { success, sources, default_card } = data;

    let cards = [];
    if (platform === 'qb') {
      cards = sources;
    } else if(platform === 'square') {
      cards = sources.map((source) => {
        const { last_4, exp_month, exp_year, billing_address, card_type, cardholder_name, id } = source;
        const { address_line_1, postal_code, country, locality, administrative_district_level_1  } = billing_address || {};

        return {
          id,
          name: cardholder_name,
          expMonth: exp_month,
          expYear: exp_year,
          cardType: card_type,
          number: last_4,
          address: {
            city: locality,
            country,
            postalCode: postal_code,
            region: administrative_district_level_1,
            streetAddress: address_line_1
          }
        }
      });
    } else if(platform === 'nmi') {
        if (!isEmpty(sources)){
          const { cc_number, cc_exp, cc_type, first_name, id, city, country, postal_code, state, address_1 } = sources;
          cards = [{
            id,
            name: first_name,
            expMonth: cc_exp.substr(0, 2),
            expYear: cc_exp.substr(-2),
            cardType: cc_type,
            number: cc_number,
            address: {
              city,
              country,
              postalCode: postal_code,
              region: state,
              streetAddress: address_1
            }
          }]
        }       
    } else {
      cards = sources.map((source) => {
        const { 
          last4, 
          exp_month, 
          exp_year, 
          address_line1, 
          address_line2, 
          address_city, 
          address_country,
          address_state, 
          address_zip, 
          brand, 
          name, 
          id } = source;

        return {
          id,
          name,
          expMonth: exp_month,
          expYear: exp_year,
          cardType: brand,
          number: last4,
          address: {
            city: address_city,
            country: address_country,
            postalCode: address_zip,
            region: address_state,
            streetAddress: address_line1
          }
        }
      });
    }

    if (success === true) {
      return dispatch({ type: 'GET_CUSTOMER_CARDS_SUCCESS', payload: { cards, default_card } });
    }
    notification.warning({
      message: 'GET CUSTOMER CARDS',
      description: 'user stripeID does not exist !',
      top: 65
    });

    return dispatch({ type: 'GET_CUSTOMER_CARDS_FAILED' });
  }).catch((error) => {
    if (axios.isCancel(error)) return;

    const { response } = error;
    const { data } = response || {};
    const { message } = data || {};

    notification.error({
      message: 'GET CUSTOMER CARDS',
      description: message || error.message,
      top: 65
    });

    return dispatch({ type: 'GET_CUSTOMER_CARDS_FAILED' });
  });
};

export const updateCustomersDefaultCard = (card_id, card, platform, payment_type) => (dispatch) => {
  dispatch({ type: 'UPDATE_CUSTOMER_DEFAULT_CARD_REQUEST' });
  const source_type = card.cardType ? 'card' : 'bank_account'
  const card_name = card?.name || null;

  return axios({
    method: 'post',
    url: '/api/v2/charges/update_default_card',
    params: {
      'source_id': card_id,
      platform,
      card_name,
      payment_type
    }
  }).then(({ data }) => {
    const { success, customer, error } = data;

    if (success) {
      notification.success({
        message: 'Update Customer Default Payment Source',
        description: 'Default Payment Source successfully updated !',
        top: 65
      });
      return dispatch({ type: 'UPDATE_CUSTOMER_DEFAULT_CARD_SUCCESS', payload: { default_source: card_id } })
    }

    notification.error({
      message: 'Update Customer Default Payment Source',
      description: error,
      top: 65
    });

    return dispatch({ type: 'UPDATE_CUSTOMER_DEFAULT_CARD_FAILED' });
  }).catch((error) => {
    if (axios.isCancel(error)) return dispatch({ type: 'UPDATE_CUSTOMER_DEFAULT_CARD_FAILED' });

    notification.error({
      message: 'Update Customer Default Payment Source',
      description: error.message,
      top: 65
    });

    return dispatch({ type: 'UPDATE_CUSTOMER_DEFAULT_CARD_FAILED' });
  });
};

export const deleteSubscription = (subscriptionId) => (dispatch) => {
  dispatch({ type: 'CANCEL_SUBSCRIPTION_REQUEST' });

  return axios({
    method: 'delete',
    url: `/api/v2/subscriptions/${subscriptionId}`
  }).then(({ data }) => {
    const { sources } = data;

    const { success, cards, banks } = data;
    if (success) {
      notification.success({
        message: 'DELETE NOTIFICATION',
        description: 'Subscription Cancellation Succeeded',
        top: 65
      })
      return dispatch({ type: 'CANCEL_SUBSCRIPTION_SUCCESS' });
    } else {
      notification.error({
        message: 'DELETE NOTIFICATION',
        description: 'Subscription Cancellation Failed',
        top: 65,
      })
    }
  }).catch((error) => {
    if (axios.isCancel(error)) return dispatch({ type: 'CANCEL_SUBSCRIPTION_FAILED' });

    notification.error({
      message: 'GET CUSTOMER CARDS',
      description: error.message,
      top: 65
    });

    return dispatch({ type: 'CANCEL_SUBSCRIPTION_FAILED' });
  });
}

export const getSubscription = () => (dispatch) => {
  dispatch({ type: 'GET_SUBSCRIPTION_REQUEST' });

  axios({
    method: 'get',
    url: '/api/v2/subscriptions'
  }).then(({ data }) => {
    const { status = 200, error, cancelled_subscription, active_subscription, stand_by_subscription } = data || {};
    if (status === 200) {
      return dispatch({
        type: 'GET_SUBSCRIPTION_SUCCESS',
        payload: { active_subscription, cancelled_subscription, stand_by_subscription }
      });
    }

    if (status === 401) {
      notification.error({
        message: 'GET SUBSCRIPTION',
        description: error,
        top: 65
      });

      return dispatch({
        type: 'GET_SUBSCRIPTION_FAILED_WITH_401'
      });
    }

    notification.error({
      message: 'GET SUBSCRIPTION',
      description: error,
      top: 65
    });

    return dispatch({ type: 'GET_SUBSCRIPTION_FAILED' });
  }).catch((error) => {
    if (axios.isCancel(error)) return dispatch({ type: 'GET_SUBSCRIPTION_FAILED' });

    notification.error({
      message: 'GET SUBSCRIPTION',
      description: error.message,
      top: 65
    });

    return dispatch({ type: 'GET_SUBSCRIPTION_FAILED' });
  });
};

export const getPaymentInvoices = () => (dispatch, getState) => {
  dispatch({ type: 'GET_PAYMENT_INVOICE_REQUEST' });

  const {
    pageSize,
    pageNo,
    filters,
    sortOptions,
    invoicesType
  } = getState().payment.invoice;

  axios({
    method: 'get',
    url: '/api/v2/combined_invoices',
    params: {
      invoice_type: invoicesType,
      filters_hash: {
        filters,
        sortOptions
      },
      page: pageNo,
      per: pageSize
    }
  }).then(({ data, status }) => {
    if (status === 200) {
      return dispatch({
        type: 'GET_PAYMENT_INVOICE_SUCCESS',
        payload: data
      });
    }

    notification.error({
      message: 'GET PAYMENT INVOICE',
      description: 'Something went wrong',
      top: 65
    });

    return dispatch({ type: 'GET_PAYMENT_INVOICE_FAILED' });
  }).catch((error) => {
    if (axios.isCancel(error)) return;

    notification.error({
      message: 'GET PAYMENT INVOICE',
      description: error.message,
      top: 65
    });

    return dispatch({ type: 'GET_PAYMENT_INVOICE_FAILED' });
  });
};

export const setPageNumber = (pageNo) => (dispatch) => {
  dispatch({
    type: 'SET_INVOICE_PAGE_NUMBER',
    payload: { pageNo }
  });
};

export const setPageSize = (pageSize) => (dispatch) => {
  dispatch({
    type: 'SET_INVOICE_PAGE_SIZE',
    payload: { pageSize }
  });
};

export const setInvoiceTypeFilter = (invoicesType) => (dispatch) => {
  dispatch({
    type: 'SET_INVOICE_TYPE',
    payload: { invoicesType }
  });
};

export const setInvoiceFilter = (invoiceType) => (dispatch, getState) => {
  const { filters } = getState().payment.invoice;
  const filterIndex = findIndex(filters, { field: 'invoiceable_type' });
  if (filterIndex === -1) {
    if (invoiceType) {
      filters.push({
        field: 'invoiceable_type',
        operator: 'in',
        value: invoiceType
      });
    }
  } else if (invoiceType) {
    filters[filterIndex].value = invoiceType;
  } else if (!invoiceType) {
    filters.splice(filterIndex, 1);
  }

  dispatch({ type: 'SET_FILTERS_PAYMENT_INVOICES', payload: { filters } });
};

export const setPaidFilter = (isPaid) => (dispatch, getState) => {
  const { filters } = getState().payment.invoice;
  const filterIndex = findIndex(filters, { field: 'paid' });
  if (filterIndex === -1) {
    if (isPaid) {
      filters.push({
        field: 'paid',
        operator: 'equal',
        value: isPaid
      });
    }
  } else if (isPaid) {
    filters[filterIndex].value = isPaid;
  } else if (!isPaid) {
    filters.splice(filterIndex, 1);
  }

  dispatch({ type: 'SET_FILTERS_PAYMENT_INVOICES', payload: { filters } });
};

export const setSortOptions = (sortOptions) => (dispatch) => {
  dispatch({ type: 'SET_SORT_OPTIONS_PAYMENT_INVOICES', payload: { sortOptions } });
};

export const setKeywordFilter = (keyword) => (dispatch, getState) => {
  const { filters } = getState().payment.invoice;

  const filterIndex = findIndex(filters, { field: 'keyword' });
  if (filterIndex === -1) {
    if (keyword) {
      filters.push({
        field: 'keyword',
        operator: 'contains',
        value: keyword
      });
    }
  } else if (keyword) {
    filters[filterIndex].value = keyword;
  } else {
    filters.splice(filterIndex, 1);
  }

  dispatch({ type: 'SET_FILTERS_PAYMENT_INVOICES', payload: { filters } });
};

export const downloadSubscriptionInvoice = (invoice) => (dispatch) => {
  const {
    description,
    invoice_name: invoiceName,
    amount_due: amountDue,
    charged_card_name: chargedCardName,
    amount_paid: amountPaid,
    created_at: createdAt,
    invoice_from: invoiceFrom,
    invoice_to: invoiceTo,
    // user: { full_name: userName } = {}
  } = invoice;

  const userName = invoice?.user?.full_name || '';

  downloadSubscriptionInvoicePDF({
    invoiceName,
    userName,
    createdAt,
    description,
    chargedCardName,
    amountPaid,
    amountDue,
    invoiceFrom,
    invoiceTo
  });
  dispatch({ type: 'DOWNLOAD_SUBSCRIPTION_INVOICE' });
};

export const downloadShipmentInvoice = (invoiceId, { companyAddressObj, companyName, companyLogo }) => (dispatch, getState) => {
  dispatch({ type: 'DOWNLOAD_SHIPMENT_REQUEST' });

  return axios({
    method: 'get',
    url: `/api/v2/invoices/${invoiceId}`
  }).then(({ data }) => {
    const { shipment_invoice, shipment_invoice_items } = data;

    downloadCustomerInvoice({
      invoice: shipment_invoice,
      invoiceItems: shipment_invoice_items,
      chargeList: shipment_invoice.preparation_charges || [],
      companyAddressObj,
      companyName,
      companyLogo
    });

    return dispatch({ type: 'DOWNLOAD_SHIPMENT_SUCCESS' });
  }).catch((error) => {
    if (axios.isCancel(error)) return dispatch({ type: 'DOWNLOAD_SHIPMENT_FAILED' });

    notification['error']({
      placement: 'bottomRight',
      message: 'Get Invoice',
      description: error.message,
      top: 65
    });

    return dispatch({ type: 'DOWNLOAD_SHIPMENT_FAILED' });
  });
};

export const downloadRemovalInvoices = (invoice) => (dispatch) => {
  downloadRemovalInvoice(invoice);
  dispatch({ type: 'DOWNLOAD_REMOVAL_INVOICE' });
};

export const verifyAndAddCoupon = (coupon) => (dispatch) => {
  dispatch({ type: 'ADD_COUPON_REQUEST' });

  return axios({
    method: 'post',
    url: '/stripe/check_valid_coupon_for_user',
    headers: {
      // 'X-CSRF-Token': ReactOnRails.authenticityToken()
    },
    params: {
      coupon_code: coupon
    }
  }).then(({ data }) => {
    const { success, error } = data;
    if (success) {
      notification.success({
        message: 'Add Coupon Code',
        description: 'Completed successfully!',
        top: 65
      });
      return dispatch({ type: 'ADD_COUPON_SUCCESS', success });
    }
    notification.error({
      message: 'Add Coupon Code',
      description: error,
      top: 65
    });
    return dispatch({ type: 'ADD_COUPON_FAILED', success });
  }).catch((error) => {
    if (axios.isCancel(error)) return dispatch({ type: 'ADD_COUPON_FAILED' });

    let errorMessage = error.message || '';
    if (error.response && error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message || '';
    }

    notification.error({
      message: 'Add Coupon Code',
      description: errorMessage,
      top: 65
    });

    return dispatch({ type: 'ADD_COUPON_FAILED' });
  });
};

export const deleteAcount = () => (dispatch) => {
  dispatch({ type: 'DELETE_ACCOUNT_REQUEST' });

  return axios({
    method: 'DELETE',
    url: '/api/v2/users/remove_account'
  })
    .then(() => {
      const { origin } = window.location;
      window.location = origin;

      notification.success({
        message: 'DELETE ACCOUNT',
        description: 'Completed successfully!',
        top: 65
      });
      dispatch({ type: 'DELETE_ACCOUNT_SUCCESS' });
    })
    .catch((error) => {
      if (axios.isCancel(error)) return dispatch({ type: 'DELETE_ACCOUNT_FAILED' });

      const { response } = error || {};
      const { data } = response || {};
      const { message } = data || {};

      notification.error({
        message: 'DELETE ACCOUNT',
        description: error.message || message,
        top: 65
      });

      return dispatch({ type: 'DELETE_ACCOUNT_FAILED' });
    });
};

export const addBankAccountWithIntuit = ({ token, userId }) => (dispatch) => {
  dispatch({ type: 'ADD_BANK_ACCOUNT_WITH_INTUIT_REQUEST' });

  return axios({
    method: 'POST',
    url: '/api/v2/distro_charges/add_bank',
    data: {
      token,
      user_id: userId
    }
  }).then(({ data }) => {
    const { success } = data || {};
    if (success) {
      notification.success({
        message: 'ADD BANK ACCOUNT',
        description: 'Bank Account has been saved successfully',
        top: 65
      });
      return dispatch({ type: 'ADD_BANK_ACCOUNT_WITH_INTUIT_SUCCESS', payload: { isAdded: true } });
    }
  }).catch((error) => {
    if (axios.isCancel(error)) return dispatch({ type: 'ADD_BANK_ACCOUNT_WITH_INTUIT_FAILED' });

    const { response } = error || {};
    const { data } = response || {};
    const { message } = data || {};

    notification.error({
      message: 'ADD BANK ACCOUNT',
      description: error.message || message,
      top: 65
    });

    dispatch({ type: 'ADD_BANK_ACCOUNT_WITH_INTUIT_FAILED' });
    return { error };
  })
};

export const clearPaymentReducer = (reducers) => (dispatch) => {
  dispatch({
    type: 'RESET_APP',
    reducers
  });
};

export const getPlans = () => (dispatch) => {
  dispatch({ type: 'GET_SUBSCRIPTION_PLANS_REQUEST' });

  return axios({
    method: 'GET',
    url: '/api/v2/subscriptions/plans'
  })
    .then(({ data }) => {
      const { plans } = data;

      dispatch({
        type: 'GET_SUBSCRIPTION_PLANS_SUCCESS',
        payload: { plans }
      })
    })
    .catch((error) => {
      if (axios.isCancel(error)) return dispatch({ type: 'GET_SUBSCRIPTION_PLANS_FAILED' });

      const { response } = error || {};
      const { data } = response || {};
      const { message } = data || {};

      notification.error({
        message: 'GET PLANS',
        description: message || error.message,
        top: 65
      });

      return dispatch({ type: 'GET_SUBSCRIPTION_PLANS_FAILED' });
    });
};

export const subscribeToPlan = ({ planId }) => (dispatch, getState) => {
  const { id: user_id } = getState().auth.currentUser || {};
  dispatch({ type: 'SUBDCRIBE_TO_PLAN_REQUEST' });

  return axios({
    method: 'POST',
    url: '/api/v2/subscriptions',
    data: {
      subscription: {
        subscription_plan_id: planId,
        user_id
      }
    }
  })
    .then(({ data }) => {
      const { success, message } = data || {};
      dispatch({ type: 'SUBDCRIBE_TO_PLAN_SUCCESS' });

      if (success) {
        const mes = isArray(message) ? message[0] : message;
        notification.success({
          message: 'SUBSCRIBE TO PLAN',
          description: mes || 'Plan has been subscribed successfully.',
          top: 65
        });
        return { isSuccess: success || true };
      } else {
        const mes = isArray(message) ? message[0] : message;   
        notification.error({
          message: 'SUBSCRIBE TO PLAN',
          description: mes || 'Plan cannot be subscribed',
          top: 65
        })
        return { isSuccess: success || false }
      }
    })
    .catch((error) => {
      if (axios.isCancel(error)) return dispatch({ type: 'SUBDCRIBE_TO_PLAN_FAILED' });

      const { response } = error || {};
      const { data } = response || {};
      const { message } = data || {};

      notification.error({
        message: 'SUBSCRIBE TO PLAN',
        description: message || error.message,
        top: 65
      });

      dispatch({ type: 'SUBDCRIBE_TO_PLAN_FAILED' });
      return { error };
    });
};

export const getAccessToken = ({ number, expiryMonth, expiryYear, cvc, city, postalCode, country, name, region, streetAddress }) => (dispatch) => {
  dispatch({ type: 'ACCESS_TOKEN_REQUEST' })

  return awsAxios({
    method: 'POST',
    url: `${process.env.INTUIT_URL}/quickbooks/v4/payments/tokens`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      card: {
        number,
        expMonth: expiryMonth,
        expYear: expiryYear,
        default: true,
        cvc,
        address: {
          city,
          postalCode,
          country,
          region,
          streetAddress
        },
        name,
      }
    }
  }).then(({ data }) => {
    const { value } = data || {};
    if (value) {
      notification.success({
        message: 'GETTING TOKEN SUCCESS',
        description: 'Access token has been successfully fetched',
        top: 65
      })
      return dispatch({ type: 'ACCESS_TOKEN_SUCCESSFUL', payload: { token: value } })
    }
  }).catch((error) => {
    if (axios.isCancel(error)) return dispatch({ type: 'ACCESS_TOKEN_FAILED' });

    let errorMessage = null;
    const { response } = error;
    const { data } = response || {};
    const { errors = [] } = data || {};

    const errorMessages = errors.map(({ message }) => message);
    if (errorMessages.length) errorMessage = errorMessages.join(', ');

    notification.error({
      message: 'GETTING TOKEN FAILED',
      description: errorMessage || error.message,
      top: 65
    })
    return dispatch({ type: 'ACCESS_TOKEN_FAILED' })
  })
}

export const saveMisc = ({ userId, misc }) => (dispatch) => {
  dispatch({ type: 'SAVE_MISC_REQUEST' });
  return axios({
    method: 'PUT',
    url: `/api/v2/users/${userId}/cc_info`,
    data: {
      users_data: misc
    }
  }).then(({ data }) => {
    const { status } = data;
    if (status) {
      return dispatch({ type: 'SAVE_MISC_SUCCESS', status });
    }
    notification.error({
      message: 'Save User Info',
      description: 'Unable to save.',
      top: 65
    });
    return dispatch({ type: 'SAVE_MISC_FAILED', status });
  }).catch((error) => {
    if (axios.isCancel(error)) return dispatch({ type: 'SAVE_MISC_FAILED' });

    notification.error({
      message: 'Save User Info',
      description: 'Unable to save.',
      top: 65
    });
    return dispatch({ type: 'SAVE_MISC_FAILED' });
  });
};

export const getBankAccountToken = ({ accountNo, name, phoneNo, rountingNumber, type }) => (dispatch) => {
  dispatch({ type: 'ACCESS_TOKEN_REQUEST' });

  return awsAxios({
    method: 'POST',
    url: `${process.env.INTUIT_URL}/quickbooks/v4/payments/tokens`,
    headers: {
      'Content-Type': 'application/json'
    },
    data: {
      bankAccount: {
        name: name,
        accountNumber: accountNo,
        phone: phoneNo,
        accountType: type.toUpperCase(),
        routingNumber: rountingNumber
      }
    }
  }).then(({ data }) => {
    const { value } = data || {};
    if (value) {
      notification.success({
        message: 'GETTING TOKEN SUCCESS',
        description: 'Access token has been successfully fetched',
        top: 65
      })
      return dispatch({ type: 'ACCESS_TOKEN_SUCCESSFUL', payload: { token: value } })
    }
    notification.error({
      message: 'GETTING TOKEN FAILED',
      description: 'Something went wrong !!',
      top: 65
    })
    return dispatch({ type: 'ACCESS_TOKEN_FAILED' })
  }).catch((error) => {
    if (axios.isCancel(error)) return dispatch({ type: 'ACCESS_TOKEN_FAILED' });

    let errorMessage = null;
    const { response } = error;
    const { data } = response || {};
    const { errors = [] } = data || {};

    const errorMessages = errors.map(({ message }) => message);
    if (errorMessages.length) errorMessage = errorMessages.join(', ');

    notification.error({
      message: 'GETTING TOKEN FAILED',
      description: errorMessage || error.message,
      top: 65
    })
    return dispatch({ type: 'ACCESS_TOKEN_FAILED' })
  })
}

export const retryInvoice = (subscription_id) => (dispatch, getState) => {
  dispatch({ type: 'RETRY_INVOICE_REQUEST' })
  return axios({
    method: 'POST',
    url: 'api/v2/subscriptions/charge_subscription_manually',
    data: { subscription_id }
  }).then(({ data }) => {
    const { success, message } = data || {};
    if (success) {
      notification.success({
        message: 'RETRY INVOICE',
        description: message || 'Invoice Successfull',
        top: 65
      });
      return dispatch({ type: 'RETRY_INVOICE_SUCCESS', payload: { success } })
    } else {
      notification.error({
        message: 'RETRY INVOICE',
        description: message || 'Invoice Failed',
        top: 65
      });
      dispatch({ type: 'RETRY_INVOICE_FAILED', payload: { success } })
    }
  }).catch((error) => {
    if (axios.isCancel(error)) return dispatch({ type: 'RETRY_INVOICE_FAILED' });

    const { response } = error || {};
    const { data } = response || {};
    const { message } = data || {};

    notification.error({
      message: 'RETRY INVOICE',
      description: message || error.message,
      top: 65
    });

    dispatch({ type: 'RETRY_INVOICE_FAILED' });
    return { error };

  })
}

export const getPrivacyPolicyStatus = (policy_type) => (dispatch, getState) => {
  dispatch({ type: 'GET_PRIVACY_POLICY_STATUS_REQUEST' })

  return axios({
    method: 'GET',
    url: '/api/v2/own_information/policy_status',
    params: {
      policy_type
    }
  }).then(({ data }) => {
    const { success, status } = data || {};
    if (success) {
      return dispatch({ type: 'GET_PRIVACY_POLICY_STATUS_SUCCESS', payload: { privacyPolicyStatus: status } })
    }
    return dispatch({ type: 'GET_PRIVACY_POLICY_STATUS_FAILED' })
  }).catch((error) => {
    if (axios.isCancel(error)) return dispatch({ type: 'GET_PRIVACY_POLICY_STATUS_FAILED' });

    const { response } = error || {};
    const { data } = response || {};
    const { message } = data || {};

    notification.error({
      message: `Privacy Policy For ${policy_type}`,
      description: message || error.message,
      top: 65
    });

    return dispatch({ type: 'GET_PRIVACY_POLICY_STATUS_FAILED' });
  })
}

export const updatePrivacyPolicyStatus = (policy_type) => (dispatch, getState) => {
  dispatch({ type: 'UPDATE_PRIVACY_POLICY_STATUS_REQUEST' })

  const own_information = {};
  if (policy_type === 'fbm') own_information.fbm_policy = true;
  else if (policy_type === 'subscription') own_information.subscription_policy = true;
  else if (policy_type === '3pl') own_information.three_pl_policy = true;

  return axios({
    method: 'PATCH',
    url: '/api/v2/own_information/policy_acknowledgement',
    data: {
      own_information
    }
  }).then(({ data }) => {
    const { success, message } = data || {};
    if (success) {
      notification.success({
        message: `Privacy Policy for ${policy_type}`,
        description: message || 'Acknowledged Successfull',
        top: 65
      });
      return dispatch({ type: 'UPDATE_PRIVACY_POLICY_STATUS_SUCCESS', payload: { success } })
    }
    notification.error({
      message: `Privacy Policy For ${policy_type}`,
      description: message || 'Failed To Acknowledged',
      top: 65
    });
    return dispatch({ type: 'UPDATE_PRIVACY_POLICY_STATUS_FAILED', payload: { success } })
  }).catch((error) => {
    if (axios.isCancel(error)) return dispatch({ type: 'UPDATE_PRIVACY_POLICY_STATUS_FAILED', payload: { success: false } });

    const { response } = error || {};
    const { data } = response || {};
    const { message } = data || {};

    notification.error({
      message: `Privacy Policy for ${policy_type}`,
      description: message || error.message || 'Failed To Acknowledged',
      top: 65
    });

    return dispatch({ type: 'UPDATE_PRIVACY_POLICY_STATUS_FAILED', payload: { success: false } });
  })
}
