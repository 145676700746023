
import React, { useState, useMemo } from "react";
import { Formik } from 'formik';
import { notification } from "antd";
import { object, string, ref } from 'yup';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import Header from "../../header/header";
import { AuthWrapper, PhoneNumberWrapper } from "../style";
import { Row, Col, Container, Form } from "react-bootstrap";
import InfoVideo from "./infoVideo";
import LabelInput from "../../uiComponent/inputs/labelInput";
import Button from "../../uiComponent/button/index";
import { BiLeftArrowAlt } from "react-icons/bi";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import { SaveNewPassword } from "../../../slices/auth";
const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { newPasswordLoading } = useSelector(state => state?.auth || {});
  const [passwordShow, SetPasswordShow] = useState(true);

  const savePassword = async (values) => {

    const { password, confirmPassword } = values;
    const query = new URLSearchParams(window.location.search);
    const reset_password_token = query.get('reset_password_token')

    if (isEmpty(reset_password_token)) {
      navigate('/auth/password-email-verification');
      return;
    };
   
    const user = {
      reset_password_token,
      password: password,
      password_confirmation: confirmPassword
    }
  
    const response = await dispatch(SaveNewPassword({user}));
    if(!response.error) navigate('/auth/sign-in');
  }

  const handleSetPassword = () => {
    SetPasswordShow(!passwordShow);
  };

  const schema = object().shape({
    password: string()
    .required('Please Enter your password')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{12,})/,
      "Must Contain 12 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    confirmPassword: string()
    .required()
    .oneOf([ref("password"), null], "Passwords must match")
  });

  return (
    <AuthWrapper>
      <Formik
      validationSchema={schema}
      onSubmit={(values) => savePassword(values)}
      initialValues={{
        password: '',
        confirmPassword: ''
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched
      }) => (
      <Form onSubmit={handleSubmit}>
      <Header login={false} />
      <div className="conatiner">
        <Row className="m-0">
          <Col lg={8} sm={12}>
            <InfoVideo />
          </Col>
          <Col lg={4} sm={12}>
            <div className="bg-albaster">
              <div>
                <div className="mb-35">
                  <h2>Enter New Password</h2>
                </div>
                <div className="password-input">
                  <LabelInput
                    inputStatus={touched.password && errors.password}
                    name='password'
                    label="Type New Password"
                    type={passwordShow ? "password" : "text"}
                    placeholder="Type Password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {passwordShow ? (
                    <AiFillEyeInvisible onClick={handleSetPassword} />
                  ) : (
                    <AiFillEye onClick={handleSetPassword} />
                  )}
                </div>
                <div className="password-input">
                  <LabelInput
                    inputStatus={touched.confirmPassword && errors.confirmPassword}
                    name='confirmPassword'
                    label="Re-Type New Password"
                    type={passwordShow ? "password" : "text"}
                    placeholder="Your Password Here"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {passwordShow ? (
                    <AiFillEyeInvisible onClick={handleSetPassword} />
                  ) : (
                    <AiFillEye onClick={handleSetPassword} />
                  )}
                </div>
                <div className="mt-40">
                <Button
                  type="submit"
                  disabled={newPasswordLoading}
                  text={newPasswordLoading? "Saving new password...":"Save New Password"}
                  className="primary w-100"
                />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      </Form>
      )}
      </Formik>
    </AuthWrapper>
  );
};

export default SignIn;
