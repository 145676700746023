import React, { lazy } from "react";

const Dashboard = lazy(() => import("../../containers/warehouse/dashboard"));
const DueDate = lazy(() => import("../../containers/warehouse/dueDate"));
const Missing = lazy(() => import("../../containers/warehouse/missing"));
const ShipOrder = lazy(() => import("../../containers/warehouse/shipOrder"));
const Shipped = lazy(() => import("../../containers/warehouse/shipped"));
const StorageOrder = lazy(() => import("../../containers/warehouse/storageOrder"));
const Standard = lazy(() => import("../../containers/warehouse/standard"));
const WarehouseDetails = lazy(() => import("../../containers/warehouse/warehouseDetails"))
const Inbound = lazy(() => import("../../containers/warehouse/inbound"))

export const ORDER_FULFILLMET_ROUTES = [
  {
    path: "/orders-fulfillment",
    component: <Dashboard />,
    group: "App",
    reducer: [],
  },
  {
    path: "/orders-fulfillment/due-date",
    component: <DueDate />,
    group: "App",
    reducer: "",
  },
  {
    path: "/orders-fulfillment/ship/:id",
    component: <ShipOrder />,
    group: "App",
    reducer: "",
  },
  {
    path: "/orders-fulfillment/missing",
    component: <Missing />,
    group: "App",
    reducer: "",
  },
  {
    path: "/orders-fulfillment/shipped",
    component: <Shipped />,
    group: "App",
    reducer: "",
  },
  {
    path: "/orders-fulfillment/stranded",
    component: <Standard />,
    group: "App",
    reducer: "",
  },
  {
    path: "/orders-fulfillment/storage-orders",
    component: <StorageOrder />,
    group: "App",
    reducer: "",
  },
  {
    path: "/orders-fulfillment/warehouse-details",
    component: <WarehouseDetails />,
    group: "App",
    reducer: "",
  },
  {
    path: "/orders-fulfillment/inbound",
    component: <Inbound />,
    group: "App",
    reducer: "",
  }
];
