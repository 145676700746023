import React from 'react';
import { Drawer, Input, Button } from 'antd';

const CustomModal = ({ visible, onCancel, onEmailSubmit, setEmail, email, className }) => {

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = () => {
    onEmailSubmit(email);
  };

  return (
    <Drawer
      className={className} 
      visible={visible}
      onCancel={onCancel}
      centered={false}
      closable= {false}
    >
      <div>
        <p className='inner-text'>
          Provide your email so that we notify you about the completion of processing
        </p>
      </div>
      <div className='w-100' style={{ padding: '32px 0' }}>
        <Input
          placeholder="E-Mail"
          value={email}
          onChange={handleEmailChange}
          style={{ width: '100%' }}
        />
      </div>
      <p className='inner-text-blue'>
        Once the processing is complete, press the “Confirm PO” button again
      </p>
      <div className='buttons-wrapper'>
      <Button className='w-50 h-100' key="cancel" onClick={onCancel} style={{ border: 'none', boxShadow: 'none', color: "#1565D8" }}>
          Cancel
        </Button>
        <Button className='w-50 h-100' style={{ background: '#1565D8' }} disabled={!email} key="submit" type="primary" onClick={handleSubmit}>
          Submit E-Mail
        </Button>
      </div>
    </Drawer>
  );
};

export default CustomModal;
