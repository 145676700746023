import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  repricerEnabled: null,
  isRepricerLive: null,
  dataSynced: null
};


const Settings = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GET_REPRICER_STATUS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_REPRICER_STATUS_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        fetching: false
      };
    }
    case 'GET_REPRICER_STATUS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'DISABLE_REPRICER_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'DISABLE_REPRICER_SUCCESS': {
      return {
        ...state,
        repricerEnabled: false,
        fetching: false
      };
    }
    case 'DISABLE_REPRICER_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'ENABLE_REPRICER_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'ENABLE_REPRICER_SUCCESS': {
      return {
        ...state,
        repricerEnabled: true,
        fetching: false
      };
    }
    case 'ENABLE_REPRICER_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_REPRICER_MODE_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'SET_REPRICER_MODE_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        fetching: false
      };
    }
    case 'SET_REPRICER_MODE_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }

    default:
      return state;
  }
};

export default Settings;
