
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  Select,
  Spin,
  DatePicker,
  PageHeader
} from "antd";
import moment from "moment";
import { ProgressBar, Row, Col } from "react-bootstrap";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import SuperSelect from '../customComponents/SuperSelect.jsx';
import { DashboardWrapper } from "./style";
import { round } from 'lodash';

import {
  GetUserList,
  GetWarehouseList,
  GetSummaryData,
  GetShipmentData
} from '../../slices/dashboard';
import clearRequestArray from '../../utils/interseptors/pendingRequest';
import axios from '../../../axios_config';

let controller = null;

const Stats = () => {

  const dispatch = useDispatch();

  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const { dashboard } = useSelector(state => state || {});


  const [filters, setFilters] = useState({
    startDate: moment().subtract(1, 'weeks').toString(),
    endDate: moment(new Date()).toString(),
    selectedUser: null,
    selectedWarehouse: null
  });

  const {
    userList,
    warehouseList,
    summaryData,
    shipmentData,
    userLoading,
    warehouseLoading,
    summaryLoading,
    shipmentLoading
  } = dashboard || {};

  const formatAmount = (amount) => {
    let value = 'N/A';
    if (amount || amount === 0) {
      value = round(amount, 2).toFixed(2);
      value = `$${value}`;
    }
    return value;
  }

  const { at_vendor, in_transit_to_3pl, at_3pl_arrived, at_3pl_working, shipped_summary } = summaryData || {};
  const { at_vendor: vendorUnits, at_vendor_cost: vendorCost, } = at_vendor || {};
  const { in_transit_to_3pl: transitUnits, in_transit_to_3pl_cost: transitCost } = in_transit_to_3pl || {};
  const { at_3pl_arrived: arrivedUnits, at_3pl_arrived_cost: arrivedCost } = at_3pl_arrived || {};
  const { at_3pl_working: workingUnits, at_3pl_working_cost: workingCost } = at_3pl_working || {};
  const { shipped_summary: shippedUnits, shipped_summary_cost: shippedCost } = shipped_summary || {};

  const { four_panels, graph_data } = shipmentData || {};
  const { arrived_panel, received_panel, in_progress_panel, shipped_panel } = four_panels || {};
  const { arrived_units_graph, recieved_units_graph, shipped_units_graph } = graph_data || {};
  const { arriving_shipment, arriving_unit, arriving_bundles, arriving_cost } = arrived_panel || {};
  const { recieved_shipment, recieved_unit, recieved_bundles, received_cost } = received_panel || {};
  const { in_progress_shipment, in_progress_unit, in_progress_bundles, in_progress_cost } = in_progress_panel || {};
  const { shipped_shipment, shipped_unit, shipped_bundles, shipped_cost } = shipped_panel || {};

  const { startDate, endDate, selectedUser, selectedWarehouse } = filters;

  const getDashboardData = async () => {
    const warehouseBody = {
      user_id: selectedUser
    };
    const summaryBody = {
      user_id: selectedUser,
      warehouse_id: selectedWarehouse
    }
    const shipmentBody = {
      user_id: selectedUser,
      warehouse_id: selectedWarehouse,
      start_date: startDate,
      end_date: endDate,
    }
    dispatch(GetUserList({}));
    dispatch(GetWarehouseList(warehouseBody));
    dispatch(GetSummaryData(summaryBody));
    dispatch(GetShipmentData(shipmentBody));
  };

  useEffect(() => {
    controller = new AbortController();
    axios.defaults.signal = controller?.signal;
    getDashboardData();

    return () => {
      clearRequestArray();
      controller.abort();
      controller = null;
      axios.defaults.signal = null;
    };
  }, []);

  const handleDateChange = async (dates) => {
    const startAt = moment(dates[0]._d).toString();
    const endAt = moment(dates[1]._d).toString();
    setFilters({
      ...filters,
      startDate: startAt,
      endDate: endAt
    });
    const shipmentBody = {
      user_id: selectedUser,
      warehouse_id: selectedWarehouse,
      start_date: startAt,
      end_date: endAt,
    }
    await dispatch(GetShipmentData(shipmentBody));
  }

  const graphArrivedUnits = arrived_units_graph?.map((item) => item.arriving_unit);
  const graphReceivedUnits = recieved_units_graph?.map((item) => item.recieved_unit);
  const graphShippedUnits = shipped_units_graph?.map((item) => item.shipped_unit);
  const graphDates = shipped_units_graph?.map((item) => item.dashboard_date);

  const userOptions = userList?.map(({ id, full_name, email }) => {
    full_name = ((full_name === '' || full_name === ' ') ? null : full_name);

    let label = email;
    if (full_name) label = `${full_name}${email}`;

    return (<Option label={label} key={id} value={id}>
      <div>{full_name}</div>
      <div className="selected" style={{ fontSize: '13px' }}>{email}</div>
    </Option>);
  });

  const warehouseOptions = warehouseList?.map(({ id, title }) => {
    return (<Option label={title} key={id} value={id}>
      <div>{title}</div>
    </Option>);
  });

  const handleWarehouseChange = (warehouseId) => {
    setFilters({
      ...filters,
      selectedWarehouse: warehouseId
    });
    const summaryBody = {
      user_id: selectedUser,
      warehouse_id: warehouseId
    }
    const shipmentBody = {
      user_id: selectedUser,
      warehouse_id: warehouseId,
      start_date: startDate,
      end_date: endDate,
    }
    dispatch(GetSummaryData(summaryBody));
    dispatch(GetShipmentData(shipmentBody));
  }

  const handleUserChange = (userId) => {
    setFilters({
      ...filters,
      selectedUser: userId
    });
    const warehouseBody = {
      user_id: userId
    };
    const summaryBody = {
      user_id: userId,
      warehouse_id: selectedWarehouse
    }
    const shipmentBody = {
      user_id: userId,
      warehouse_id: selectedWarehouse,
      start_date: startDate,
      end_date: endDate,
    }
    dispatch(GetWarehouseList(warehouseBody));
    dispatch(GetSummaryData(summaryBody));
    dispatch(GetShipmentData(shipmentBody));
  }

  Highcharts.setOptions({
    lang: {
      thousandsSep: ','
    }
  });

  return (
    <DashboardWrapper>
      <div className="affilateDashboard">
        <div className="add-scroll">
          <div className="d-flex justify-content-between mb-3">
            <h3 className="heading">Dashboard</h3>
            <div className="d-flex gap-3">
              <SuperSelect
                loading={userLoading ? true : false}
                disabled={userLoading ? true : false}
                allowClear
                showSearch
                optionFilterProp="label"
                placeholder="Select a User"
                style={{ width: '200px' }}
                onChange={handleUserChange}
              >
                {userOptions}
              </SuperSelect>
              <SuperSelect
                loading={warehouseLoading ? true : false}
                disabled={warehouseLoading ? true : false}
                allowClear
                showSearch
                optionFilterProp="label"
                placeholder="Select a Warehouse"
                style={{ width: '200px' }}
                onChange={handleWarehouseChange}
              >
                {warehouseOptions}
              </SuperSelect>
            </div>
          </div>
          <div className="summary">
            <Spin tip="Please wait..." spinning={summaryLoading ? true : false}>
              <div className="inner-content">
                <div className="text-center">
                  <h4>Summary</h4>
                </div>
                <div className="icons-portion">
                  <div className="active">
                    <span className="icon-at_vendor icons"></span>
                  </div>
                  <div className="active">
                    <span className="icon-In_transit icons"></span>
                  </div>
                  <div className="active">
                    <span className="icon-at_3pl icons"></span>
                  </div>
                  <div className="active">
                    <span className="icon-pl_working icons"></span>
                  </div>
                  <div className="active">
                    <span className="icon-shipped icons"></span>
                  </div>
                </div>
                <ProgressBar />
                <div className="info-portion">
                  <div>
                    <h5 className="title">At Vendor</h5>
                    <div>
                      <div>
                        <span>{vendorUnits || 0}</span>
                        <span>Units</span>
                      </div>
                      <div>
                        <span className="color-green">{formatAmount(vendorCost || 0)}</span>
                        <span>Amounts</span>
                      </div>
                    </div>
                  </div>
                  <div className="active">
                    <h5 className="title">In transit to 3PL</h5>
                    <div>
                      <div>
                        <span>{transitUnits || 0}</span>
                        <span>Units</span>
                      </div>
                      <div>
                        <span className="color-green">{formatAmount(transitCost || 0)}</span>
                        <span>Amounts</span>
                      </div>
                    </div>
                  </div>
                  <div className="active">
                    <h5 className="title">At 3PL</h5>
                    <div>
                      <div>
                        <span>{arrivedUnits || 0}</span>
                        <span>Units</span>
                      </div>
                      <div>
                        <span className="color-green">{formatAmount(arrivedCost || 0)}</span>
                        <span>Amounts</span>
                      </div>
                    </div>
                  </div>
                  <div className="active">
                    <h5 className="title">3PL Working</h5>
                    <div>
                      <div>
                        <span>{workingUnits || 0}</span>
                        <span>Units</span>
                      </div>
                      <div>
                        <span className="color-green">{formatAmount(workingCost || 0)}</span>
                        <span>Amounts</span>
                      </div>
                    </div>
                  </div>
                  <div className="active">
                    <h5 className="title">Shipped</h5>
                    <div>
                      <div>
                        <span>{shippedUnits || 0}</span>
                        <span>Units</span>
                      </div>
                      <div>
                        <span className="color-green">{formatAmount(shippedCost || 0)}</span>
                        <span>Amounts</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
          </div>

          <div className="date-picker">
            <h4 className="mb-0">3PL</h4>
            <div>
              <RangePicker
                allowClear={false}
                disabledDate={d => !d || d.isAfter(moment(new Date()))}
                defaultValue={[moment(startDate), moment(endDate)]}
                format='MM/DD/YYYY'
                onChange={handleDateChange}
              />
              {/* <Input type="date" className="h-32" /> */}
            </div>
          </div>
          <Spin tip="Please wait... " spinning={shipmentLoading ? true : false}>
            <Row className="m-0">
              <Col md={6} sm={24} className="ps-0">
                <div className="shipped_today">
                  <h6>Checked In</h6>
                  <div className="shipped-detail">
                    <div className="shipment">
                      <span className="icon-shipment icons"></span>
                      <span>{arriving_shipment || 0}</span>
                      <span>Shipments</span>
                    </div>

                    <div className="shipment">
                      <span className="icon-unit icons"></span>
                      <span>{arriving_unit || 0}</span>
                      <span>Units</span>
                    </div>
                    <div className="shipment">
                      <span className="icon-unit icons"></span>
                      <span>{arriving_bundles || 0}</span>
                      <span>Bundles</span>
                    </div>
                    <div className="shipment">
                      <span className="icon-amount icons"></span>
                      <span>{formatAmount(arriving_cost || 0)}</span>
                      <span>Amount</span>
                    </div>
                  </div>
                </div>
                <div className="shipped_today">
                  <h6>Received</h6>
                  <div className="shipped-detail">
                    <div className="shipment">
                      <span className="icon-shipment icons"></span>
                      <span>{recieved_shipment || 0}</span>
                      <span>Shipments</span>
                    </div>

                    <div className="shipment">
                      <span className="icon-unit icons"></span>
                      <span>{recieved_unit || 0}</span>
                      <span>Units</span>
                    </div>
                    <div className="shipment">
                      <span className="icon-unit icons"></span>
                      <span>{recieved_bundles || 0}</span>
                      <span>Bundles</span>
                    </div>
                    <div className="shipment">
                      <span className="icon-amount icons"></span>
                      <span>{formatAmount(received_cost || 0)}</span>
                      <span>Amount</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} sm={24} className="pe-0">
                <div className="shipped_today">
                  <h6>Working</h6>
                  <div className="shipped-detail">
                    <div className="shipment">
                      <span className="icon-shipment icons"></span>
                      <span>{in_progress_shipment || 0}</span>
                      <span>Shipments</span>
                    </div>

                    <div className="shipment">
                      <span className="icon-unit icons"></span>
                      <span>{in_progress_unit || 0}</span>
                      <span>Units</span>
                    </div>
                    <div className="shipment">
                      <span className="icon-unit icons"></span>
                      <span>{in_progress_bundles || 0}</span>
                      <span>Bundles</span>
                    </div>
                    <div className="shipment">
                      <span className="icon-amount icons"></span>
                      <span>{formatAmount(in_progress_cost || 0)}</span>
                      <span>Amount</span>
                    </div>
                  </div>
                </div>
                <div className="shipped_today">
                  <h6>Shipped</h6>
                  <div className="shipped-detail">
                    <div className="shipment">
                      <span className="icon-shipment icons"></span>
                      <span>{shipped_shipment || 0}</span>
                      <span>Shipments</span>
                    </div>

                    <div className="shipment">
                      <span className="icon-unit icons"></span>
                      <span>{shipped_unit || 0}</span>
                      <span>Units</span>
                    </div>
                    <div className="shipment">
                      <span className="icon-unit icons"></span>
                      <span>{shipped_bundles || 0}</span>
                      <span>Bundles</span>
                    </div>
                    <div className="shipment">
                      <span className="icon-amount icons"></span>
                      <span>{formatAmount(shipped_cost || 0)}</span>
                      <span>Amount</span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Spin>
          <div className="unit_received">
            <Spin tip="Please wait... " spinning={shipmentLoading ? true : false}>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  title: {
                    text: "Units",
                  },
                  chart: {
                    type: "area",
                  },
                  xAxis: {
                    categories: graphDates,
                  },
                  tooltip: {
                    shared: true,
                    valueSuffix: " units",
                  },
                  credits: {
                    enabled: false,
                  },
                  plotOptions: {
                    areaspline: {
                      fillOpacity: 0.5,
                    },
                  },
                  series: [
                    {
                      name: "Arrived",
                      data: graphArrivedUnits,
                      color: "#728FCE",
                      type: 'line'
                    },
                    {
                      name: "Received",
                      data: graphReceivedUnits,
                      color: "#93FFE8",
                      type: 'line'
                    },
                    {
                      name: "Shipped",
                      data: graphShippedUnits,
                      color: "#E2F516",
                      type: 'line'
                    },
                  ],
                  credits: {
                    enabled: false,
                  },
                }}
              />
            </Spin>
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default Stats;
