import React, { useState, useEffect } from 'react'
import { Spin } from 'antd';
import { object, string, ref, bool } from 'yup';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Logo from "../../assets/images/newImages/new_log.png";
import ProdImg from "../../assets/images/newImages/prod-img.svg";
import { AuthWrapper, PhoneNumberWrapper } from '../profile/style'
import { Form, Row, Col, Container } from "react-bootstrap"
import InfoVideo from '../profile/component/infoVideo'
import LabelInput from '../uiComponent/inputs/labelInput'
import PhoneInput from '../uiComponent/inputs/phoneNumberInput'
import CheckBox from '../uiComponent/checkBox'
import Button from '../uiComponent/button/index'
import { BiLeftArrowAlt } from "react-icons/bi";
import { AiFillEyeInvisible, AiFillEye, AiOutlineMenu } from "react-icons/ai";
import { GetScannedItem, AddItemToLocation, ListLocationItems } from "../../slices/internalStorage";
import { isEmpty, debounce } from 'lodash';
import { MobileWrapper, Header, Content, Footer, Title, InputWrapper, CardDetail, ProductDetail, ScanFloating } from './mobile.styled';

const ScanAddProduct = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { scannedItem, selectedLocationId, addItemLoading, scannedItemLoading } = useSelector(state => state?.internalStorage || {});

    const { identifier, quantity, title, image_url, id } = scannedItem || {};
    const [value, setValue] = useState(null)
    
    const handleUpdateProducts = debounce(async(event) => {
      const value = event.target.value;
      if(!isEmpty(value)) {
        await dispatch(GetScannedItem({ id: value }));
      }
    }, 1000)
 
    const handleAddItemToLocation = async() => {   
      const { id: locationId } = selectedLocationId || {}; 
      if (value > 0) {
        const staged_storage = {
          storage_id: locationId,
          staging_item_id: id,
          quantity_stored: Number(value)
        }
        const response = await dispatch(AddItemToLocation({ staged_storage }));
        if(isEmpty(response.error)) {
          await dispatch(ListLocationItems({ locationId }));
          navigate('/location-details')
        }
      }
    }

    useEffect(() => {
      if(isEmpty(selectedLocationId)) navigate('/scan-location')
    }, [])

    const addDisable = addItemLoading || value <= 0

    const spinLoading = addItemLoading || scannedItemLoading;

    return (
        <MobileWrapper className=''>
            <div className='mobile-page'>
                <div className='container-fluid px-0'>
                    <Spin tip='Processing...' spinning={spinLoading}>
                    <Content className=''>
                        <Title>
                            <h2>
                                <span
                                  className="icon-arrow-left cursor-pointer"
                                  onClick={() => navigate('/location-details')}
                                ></span>
                                Scan Product to Add</h2>
                        </Title>
                        <div className='content-inner'>
                        <InputWrapper className='modify-margin'>
                            <LabelInput className="input-with-icon" icon="icon-barcode" label="Scan Product Barcode" placeholder="Scan Barcode" onChange={(event) => handleUpdateProducts(event) } />
                        </InputWrapper>
                        {
                            !isEmpty(scannedItem) && 
                            <ProductDetail>
                                <div class="d-flex align-items-center">
                                    <div>
                                        <img width="42" height="42" src={image_url} alt="no-product" />
                                    </div>
                                    <div>
                                        <span class="fs-14 color-secondary">{title}</span>
                                    </div>
                                </div>
                                <ul class="d-flex">
                                    <li class="">
                                        <span class="fs-12 d-block">Identifier</span>
                                        <span class="color-primary fs-14 fw-bold">{identifier}</span>
                                    </li>
                                    <li class="">
                                        <span class="fs-12 recent-grey d-block">Quantity</span>
                                        <span class="fs-14">
                                        <LabelInput type='number' value={value} onChange={(e) => setValue(e.target.value)} />
                                        </span>
                                    </li>
                                </ul>
                            </ProductDetail>
                        }                     
                        </div> 
                    </Content>
                    </Spin>
                    <Footer>
                        <Button 
                          disabled = {addDisable} 
                          className="outlined btn-block" 
                          text={addItemLoading? "Adding...": "Add"}
                          onClick={handleAddItemToLocation} 
                        />
                    </Footer>
                    {/* <ScanFloating>
                        <span className='icon-barcode'></span>
                    </ScanFloating> */}
                </div>
            </div>
        </MobileWrapper>
    )
}

export default ScanAddProduct;
