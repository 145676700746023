import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { BsTrash, BsCheck2, BsX } from "react-icons/bs";
import {
  Spin,
  notification,
  Popover,
  Tooltip,
  Divider,
  Icon,
  Popconfirm,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  GetPendingPoIndices,
  GetUsersList,
  GetVendorsList,
  GetTrackingNumbers,
  AddTrackingNumbers,
  GetPendingPos,
  AddCheckedInQuantities,
  SaveCheckInQuantity,
  UpdatePoCompleteStatus,
  SyncCheckInQuantity,
} from "../../../slices/fba-inbound/to-check-in";
import { setField, GetStats, clearField } from "../../../slices/fba-inbound";
import { CONDITION_TYPE } from "../../../constants/products";
import moment from "moment";
import {
  findIndex,
  filter,
  isEmpty,
  debounce,
  cloneDeep,
  indexOf,
  isUndefined,
  isFinite,
  truncate,
  findKey,
  orderBy,
  toLower,
  find,
  isEqual,
  trim,
} from "lodash";
import thirtyUpLabels from "../../../utils/utils";
import zebraLabels from "../../../utils/zebraLabels";
//style
import { TilesWrapper } from "../style";
//component
import DatePicker from "../../../components/uiComponent/datePicker/index";
import SearchInput from "../../../components/uiComponent/inputs/searchinput/index";
import Select from "../../../components/uiComponent/select/index";
import Drawer from "../../../components/uiComponent/drawer/index";
import Input from "../../../components/uiComponent/inputs/labelInput";
import Button from "../../../components/uiComponent/button/index";
import AgGrid from "../../../components/ag-grid-table/index";
import TilesPagination from "../../../components/uiComponent/tilesPagination/index";
import TotalCount from "../../../components/uiComponent/totalRecord/index";
import Switch from "../../../components/uiComponent/switch/index";
//assets
import History from "../../../assets/images/newImages/timer.svg";
import IconInfo from "../../../assets/images/newImages/icon-info.svg";
import Product from "../../../assets/images/newImages/product.png";
//icons
import { BsCheckCircle, BsCheckCircleFill } from "react-icons/bs";
import NoInbound from "../../../assets/images/newImages/no-inbound.svg";

const PrintLabelsAction = ({ fnsku, handleGenerateProductLabels, data, isValidExpiryDate }) => {
  const [labelQty, setLabelQty] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const disablePrintLabelBtn =
    !labelQty || !isFinite(Number(labelQty)) || labelQty <= 0;

  const clearLabelStates = () => {
    setLabelQty("");
    setExpiryDate("");
  };

  return (
    <Popover
      placement="bottom"
      content={
        <div>
          <h6 className="mb-0 fs-14">Print Label</h6>
          <Divider className="my-2" />
          <Row>
            <Col md={6}>
              <Input
                label="No. of Labels"
                className="h-32"
                value={labelQty}
                onChange={({ target }) => setLabelQty(target.value)}
              />
            </Col>
            <Col md={6}>
              <DatePicker
                label="Expiry Date"
                type="date"
                min={moment().add(1, 'days').format('YYYY-MM-DD')}
                className="h-32"
                value={expiryDate}
                onChange={({ target }) => setExpiryDate(target.value)}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end gap-3">
            <Button
              text="Zebra Labels"
              className="primary"
              disabled={disablePrintLabelBtn}
              onClick={() => {
                if (!isValidExpiryDate(expiryDate)) return;
                handleGenerateProductLabels({
                  isZebra: true,
                  labelQty,
                  expiryDate,
                  additional: true,
                  data,
                });
                clearLabelStates();
              }}
            />
            <Button
              text="30 UP Labels"
              className="primary"
              disabled={disablePrintLabelBtn}
              onClick={() => {
                if (!isValidExpiryDate(expiryDate)) return;
                handleGenerateProductLabels({
                  isZebra: false,
                  labelQty,
                  expiryDate,
                  additional: true,
                  data,
                });
                clearLabelStates();
              }}
            />
          </div>
        </div>
      }
      trigger={fnsku ? "click" : ""}
    >
      <Tooltip title={fnsku ? "Print Product Labels" : "No FNSKU"}>
        <div style={{ display: "inline-block" }}>
          <Button
            className="icon-button"
            text={<span class="icon-printer" />}
            disabled={!fnsku}
            onClick={() => clearLabelStates()}
          />
        </div>
      </Tooltip>
    </Popover>
  );
};

let gridApi, columnApi;

const CheckIn = () => {
  const navigate = useNavigate();
  const poSearchRef = useRef();
  const checkedInPoRef = useRef();

  const [history, setHistory] = useState(false);
  const [currentPendingPoIndex, setCurrentPendingPoIndex] = useState(0);
  const [storageStatus, setStorageStatus] = useState("");
  const [checkInQuantites, setCheckInQuantites] = useState(false);
  const [filters, setFilters] = useState([]);
  const [page, setPage] = useState({ pageSize: 25, pageNumber: 1 });
  const [trackingNumbers, setTrackingNumbers] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [currentLabelType, setCurrentLabelType] = useState({
    value: "Zebra",
    label: "Zebra Labels",
  });
  const [pendingPosToCheckIn, setPendingPosToCheckIn] = useState([]);
  const [simplePoTrackingNumber, setSimplePoTrackingNumber] = useState("");
  const [storagePoTrackingNumber, setStoragePoTrackingNumber] = useState("");
  const [parsedCheckInPos, setParsedCheckInPos] = useState([]);
  const [pendingPos, setPendingPos] = useState([]);

  const dispatch = useDispatch();
  const {
    toCheckIn: {
      pendingPoIndices,
      fetchingPendingPoIndices,
      usersList,
      fetchingUsersList,
      vendorsAddressList,
      fetchingVendorsList,
      fetchingTrackingNumbers,
      pendingPoArriveds,
      addingTrackingNumbers,
      totalCount,
      fetchingPendingPos,
      addingCheckedInQuantities,
      savingCheckInQuantity,
      updatingPoCompleteStatus,
      syncingCheckInQuantity,
      completedCount
    },
  } = useSelector((state) => state.fbaInbound || {});

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const po = query.get("po");
    if (po) {
      poSearchRef.current.value = po;
      const filter = { isDropDown: false, field: "", value: po };
      handleFilter(filter);
    }
    else {
      dispatch(GetPendingPoIndices({ filters, page }));
    }
    dispatch(GetUsersList());
    dispatch(GetVendorsList());
  }, []);

  useEffect(() => {
    const { value } = checkedInPoRef.current || {};
    if (!isEmpty(pendingPos) && !value) parseDataByCheckInQuantity();
  }, [pendingPos]);

  const getFormattedDate = (date) => date ? moment(date).format("DD MMM, YYYY HH:mm") : "N/A";

  const {
    storage_status,
    po_code,
    created_at,
    user,
    vendor,
    sumOrderQuantity,
    sumRecievedQuantity,
    id,
    invoiceQuantitySum,
    workingQuantitySum,
    last_checked_in_at
  } = pendingPoIndices?.[currentPendingPoIndex] || {};

  const { company, first_name, last_name, id: user_id } = user || {};
  const user_name = `${first_name ?? ""} ${last_name ?? ""}`;
  const { name: company_name } = company || {};
  const { name: vendor_name, addresses } = vendor || {};
  const {
    address_line_1,
    address_line_2,
    city,
    country,
    state_or_province,
    zipcode,
  } = addresses?.[0] || {};
  const vendor_address = `${address_line_1 ?? ""} ${address_line_2 ?? ""
    } ${city ?? ""} ${state_or_province ?? ""}`;

  const remainingQuantity =
    Number(sumOrderQuantity) < Number(sumRecievedQuantity)
      ? 0
      : Number(sumOrderQuantity) - Number(sumRecievedQuantity);

  const parseDataByCheckInQuantity = () => {
    const pendingPosClone = cloneDeep(pendingPos);
    const parsedData = orderBy(
      pendingPosClone,
      ["checked_in_quantity"],
      ["desc"]
    );
    setParsedCheckInPos(parsedData);
  };

  const tilesHeader = [
    "PO#",
    "User",
    "Vendor",
    "Quantity",
    "Order ID",
    "Invoice #",
    "Last Checkedin at",
    "Expected Delivery Date",
    "Created At",
    "",
  ];

  const defaultColDef = {
    resizable: true,
    sortable: true,
    suppressMenu: true,
    suppressMovable: true,
  };

  const checkInColDef = [
    {
      headerName: "Sr#",
      valueGetter: "node.rowIndex + 1",
      width: 80,
    },
    {
      headerName: "Tracking/Pro Number",
      cellRenderer: ({ data }) => (data ? data : "N/A"),
      width: 650,
    },
    {
      headerName: "",
      cellStyle: { "justify-content": "flex-end" },
      cellRendererFramework: ({ data: tracking_number }) => {
        return (
          <span className="color-danger text-end">
            <BsTrash
              onClick={() => {
                setTrackingNumbers((trackingNumbers) =>
                  filter(trackingNumbers, (value) => value != tracking_number)
                );
              }}
            />
          </span>
        );
      },
      width: 50,
    },
  ];

  const gridReady = (params) => {
    window.grid = params;
    gridApi = params.api;
    columnApi = params.columnApi;
  };

  const updateSaveBtn = debounce(
    ({ bundle_quantity, checked_in_quantity, node }) => {
      if (bundle_quantity) {
        node.data.bundle_quantity = Number(bundle_quantity);
        gridApi.redrawRows({
          rowNodes: [node],
          columns: ["bundle_quantity"],
        });
      } else {
        node.data.checked_in_quantity = Number(checked_in_quantity);
        gridApi.redrawRows({
          rowNodes: [node],
          columns: ["checked_in_quantity"],
        });
      }
    },
    800
  );

  const handlePoExpiryDate = debounce(({ value, node }) => {
    node.data.expiry_date = value;
    gridApi.redrawRows({
      rowNodes: [node],
      columns: ["expiry_date"],
    });
  }, 800);

  const isValidExpiryDate = (expiryDate) => {
    if (expiryDate && (moment(expiryDate).format('YYYY-MM-DD') < moment().add(1, 'days').format('YYYY-MM-DD'))) {
      notification.error({
        top: 65,
        message: "Expiry Date Must Be Of Future",
      });
      return false;
    }
    return true;
  };

  const checkInPosColDef = [
    {
      headerName: "Title",
      cellStyle: { padding: "0px 1px" },
      cellRendererFramework: ({ data }) => {
        const { vendoritem } = data || {};
        const { vendorasin } = vendoritem || {};
        const { name: product_title, small_image, asin } = vendorasin || {};

        return (
          <span
            className="d-flex flex-row"
            onClick={() => { window.open(`https://www.amazon.com/gp/product/${asin}`, "_blank"); }}
          >
            <img
              width={40}
              height={40}
              src={small_image || "/images/no-image.png"}
              alt="no-product"
              style={{ minWidth: "40px" }}
            />

            <span className="product-title-box ms-2">
              <Tooltip title={product_title || ""}>
                <span className="color-primary fs-14">
                  {truncate(product_title, { length: 35 }) || "N/A"}
                </span>
              </Tooltip>
            </span>
          </span>
        );
      },
      width: 187,
    },
    {
      headerName: "SKU",
      cellStyle: { padding: 0 },
      cellRendererFramework: ({ data }) => {
        const { vendoritem } = data || {};
        const { vendorasin, seller_sku } = vendoritem || {};
        const { asin } = vendorasin || {};
        const { sku } = seller_sku || {};

        if (sku) return (
          <>
            <div className="custom-sku-drawer">{sku}</div>
            <span className="custom-asin-drawer"><span>ASIN:</span>{asin}</span>
          </>
        );
        return (
          <span className="d-flex flex-column">
            <span className="badge-info">To Be Created</span>
            <span className="custom-asin-drawer"><span>ASIN:</span>{asin}</span>
          </span>
        );
      },
      width: 121,
    },
    {
      headerName: "Status",
      cellStyle: { "justify-content": "flex-end", padding: 0 },
      field: "vendoritem.seller_sku.sku_status",
      cellRendererFramework: ({ value }) => {
        return (
          <Tooltip title={value || "To Be Created"}>
            <span
              className={`${value?.toLowerCase() === "ok" ? "badge-sucess" : "badge-danger"
                }`}
            >
              {value?.toLowerCase() === "ok" ? "Non Gated" : "Gated"}
            </span>
          </Tooltip>
        );
      },
      width: 94,
    },
    {
      headerName: "Quantity",
      cellStyle: { "justify-content": "flex-end", padding: 0 },
      cellRendererFramework: ({ data }) => {
        const {
          order_quantity,
          quantity_on_invoice,
          working_units,
          received_quantity,
        } = data || {};
        return (
          <span className="d-flex gap-3">
            <span className="d-flex flex-column">
              <span className="">{order_quantity || 0}</span>
              <span className="fs-10 color-secondary">Order</span>
            </span>
            <span className="d-flex flex-column">
              <span className="">{quantity_on_invoice || 0}</span>
              <span className="fs-10 color-secondary">Invoice</span>
            </span>
            <span className="d-flex flex-column">
              <span className="">{working_units || 0}</span>
              <span className="fs-10 color-secondary">Working</span>
            </span>
            <span className="d-flex flex-column">
              <span className="">{received_quantity || 0}</span>
              <span className="fs-10 color-secondary">Received</span>
            </span>
          </span>
        );
      },
      width: 205,
    },
    {
      headerName: "Expiry Date",
      field: "expiry_date",
      cellStyle: { padding: 0, margin: -7 },
      cellRendererFramework: ({ value, data, node }) => {
        const { id: pending_po_id } = data;

        return (
          <DatePicker
            type="date"
            value={value}
            min={moment().add(1, 'days').format('YYYY-MM-DD')}
            className="h-32"
            onChange={({ target }) => handlePoExpiryDate({ value: target.value, node })}
          />
        );
      },
      width: 138,
    },
    {
      headerName: "Pack Of",
      cellStyle: { padding: 0, margin: -3 },
      field: "bundle_quantity",
      cellRendererFramework: ({ value, node }) => {
        return (
          <div>
            <Input
              name="bundle-quantity"
              className="h-32"
              width="100%"
              defaultValue={value}
              onChange={({ target }) =>
                updateSaveBtn({ bundle_quantity: target.value, node })
              }
            />
          </div>
        );
      },
      width: 90,
    },
    {
      headerName: "Check-In Qty",
      cellStyle: { padding: 0, margin: -3 },
      field: "checked_in_quantity",
      cellRendererFramework: ({ value, node }) => {
        return (
          <div>
            <Input
              name="check-in-quantity"
              className="h-32"
              width="100%"
              defaultValue={value}
              onChange={({ target }) =>
                updateSaveBtn({ checked_in_quantity: target.value, node })
              }
            />
          </div>
        );
      },
      width: 90,
    },
    {
      cellStyle: { "justify-content": "flex-end" },
      cellRendererFramework: ({ data }) => {
        const {
          vendoritem,
          id: pending_po_id,
          checked_in_quantity,
          bundle_quantity,
        } = data || {};
        const { seller_sku } = vendoritem || {};
        const { fnsku, sku_status } = seller_sku || {};

        const isInvalid =
          (!checked_in_quantity && checked_in_quantity != 0) ||
          !isFinite(Number(checked_in_quantity)) ||
          checked_in_quantity < 0 ||
          checked_in_quantity % bundle_quantity !== 0;

        let isSameValue = null;
        setParsedCheckInPos((parsedCheckInPos) => {
          const obj = find(parsedCheckInPos, { id: pending_po_id });
          isSameValue = obj?.checked_in_quantity == checked_in_quantity;
          return parsedCheckInPos;
        });
        // const disableSaveCheckInBtn = isInvalid || isSameValue;

        const disableSaveCheckInBtn = isInvalid;

        return (
          <span className="color-danger text-end d-flex gap-3 align-items-center">
            <span
              class="icon-floppy fs-16 cursor-pointer"
              style={disableSaveCheckInBtn ? { opacity: 0.5 } : {}}
              onClick={() =>
                !disableSaveCheckInBtn && handleSaveCheckInPo(data)
              }
            ></span>

            {sku_status?.toLowerCase() === "ok" && (
              <PrintLabelsAction
                fnsku={fnsku}
                data={data}
                isValidExpiryDate={isValidExpiryDate}
                handleGenerateProductLabels={handleGenerateProductLabels}
              />
            )}
          </span>
        );
      },
      width: 70,
    },
  ];

  const checkInHistoryColDef = [
    {
      headerName: "Check In at",
      field: "created_at",
      cellRenderer: ({ value }) => getFormattedDate(value),
      width: 200,
    },
    {
      headerName: "Tracking/Pro Number",
      field: "tracking_number",
      cellRendererFramework: ({ value }) => {
        if (isEmpty(value)) return <div>N/A</div>;
        const trackingNumbers = value.join(",  ");

        return (
          <Popover
            title="Tracking Numbers: "
            content={value?.map((element) => (
              <div>{element}</div>
            ))}
          >
            <span>{trackingNumbers}</span>
          </Popover>
        );
      },
      width: 400,
    },
  ];

  const handleFilter = debounce(({ isDropDown, field, value }) => {
    const filtersArray = cloneDeep(filters);
    const filterIndex = findIndex(filtersArray, { field });

    const query = new URLSearchParams(window.location.search);
    const po = query.get('po');
    const { pathname } = window.location || {};

    if (filterIndex === -1) {
      if (value) {
        filtersArray.push({
          field,
          type: isDropDown ? "drop-down" : "search",
          value: value === "FBA" && field === "storage_status" ? null : value,
        });
      }
    } else if (value) {
      filtersArray[filterIndex].value = value === "FBA" && field === "storage_status" ? null : value;
      if (po && !isDropDown) {
        window.history.pushState('', '', `${pathname}?tab=check-in&po=${value}`);
      }
    } else {
      filtersArray.splice(filterIndex, 1);
      if (po && !isDropDown) {
        window.history.pushState('', '', `${pathname}?tab=check-in`);
      }
    }
    setFilters(filtersArray);
    const newPaginationHash = { ...page, pageNumber: 1 };
    setPage(newPaginationHash);
    dispatch(GetPendingPoIndices({ filters: filtersArray, page: newPaginationHash }));
  }, 800);

  const handleSearchPo = debounce((value) => {
    if (value) {
      setParsedCheckInPos((parsedCheckInPos) =>
        filter(parsedCheckInPos, (obj) => {
          const { vendoritem } = obj || {};
          const { seller_sku, vendor_upc, vendorasin } = vendoritem || {};
          const { asin, upc, name: product_title } = vendorasin || {};
          const { fnsku, sku } = seller_sku || {};

          return (
            toLower(vendor_upc)?.includes(toLower(value)) ||
            toLower(asin)?.includes(toLower(value)) ||
            toLower(upc)?.includes(toLower(value)) ||
            toLower(fnsku)?.includes(toLower(value)) ||
            toLower(sku)?.includes(toLower(value)) ||
            toLower(product_title)?.includes(toLower(value))
          );
        })
      );
    } else {
      parseDataByCheckInQuantity();
    }
  }, 800);

  const handleTrackingNumbers = (isSimplePo) => {
    const tracking_number = isSimplePo
      ? simplePoTrackingNumber
      : storagePoTrackingNumber;
    if (tracking_number) {
      const index = indexOf(trackingNumbers, tracking_number);
      if (index === -1) {
        if (isSimplePo) {
          // dispatch(AddTrackingNumbers({
          //   tracking_numbers: [tracking_number],
          //   pending_po_index_id: pendingPoIndices[currentPendingPoIndex].id,
          //   save_data: false
          // })).then(({ payload }) => {
          //   const { data } = payload || {};
          //   const { success } = data || {};
          //   if (success) {
          setTrackingNumbers((trackingNumbers) => [
            ...trackingNumbers,
            tracking_number,
          ]);
          setSimplePoTrackingNumber("");
          //   }
          // });
        } else {
          setTrackingNumbers((trackingNumbers) => [
            ...trackingNumbers,
            tracking_number,
          ]);
          setStoragePoTrackingNumber("");
        }
      } else {
        notification.error({
          top: 65,
          message: "Tracking Number Added Already",
        });
      }
    } else {
      notification.error({
        top: 65,
        message: "Invalid Tracking Number",
      });
    }
  };

  const clearCheckedInQuantityStates = (clearMainGridData) => {
    if (clearMainGridData) {
      checkedInPoRef?.current?.value && (checkedInPoRef.current.value = "");
      // dispatch(clearField({
      //   module: "toCheckIn",
      //   field: "pendingPos",
      // }));
      setParsedCheckInPos([]);
      setPendingPos([]);
    }
  };

  const handleSubmitTrackingNumbers = ({
    isRedirectToShipment,
    storage_status,
  }) => {
    if (storage_status ? !isEmpty(trackingNumbers) : true) {
      dispatch(
        AddTrackingNumbers({
          tracking_numbers: trackingNumbers,
          pending_po_index_id: pendingPoIndices[currentPendingPoIndex].id,
          save_data: true,
        })
      ).then(({ payload }) => {
        const { data } = payload || {};
        const { success, last_checked_in_at } = data || {};
        if (success) {
          dispatch(
            setField({
              module: "toCheckIn",
              field: "pendingPoIndices",
              index: currentPendingPoIndex,
              key: "last_checked_in_at",
              value: last_checked_in_at,
            })
          );
          clearCheckedInQuantityStates(true);
          setCheckInQuantites(false);
          setStorageStatus("");
          setTrackingNumbers([]);
          storage_status
            ? setStoragePoTrackingNumber("")
            : setSimplePoTrackingNumber("");
          dispatch(GetStats());
          isRedirectToShipment && navigate(`/fba-inbound/shipment-plan/${user_id}`);
          storage_status && navigate(`/fba-inbound/receive-items?po=${pendingPoIndices[currentPendingPoIndex].po_code}`);
        }
      });
    } else {
      notification.error({
        top: 65,
        message: "Please Add Tracking Number First",
      });
    }
  };

  const handlePagination = () => {
    const newPaginationHash = { ...page, pageNumber: page.pageNumber + 1 };
    setPage(newPaginationHash);
    dispatch(GetPendingPoIndices({ filters, page: newPaginationHash }));
  };

  const poTypes = [
    { value: "Storage Po", label: "Storage Po" },
    { value: "FBA", label: "FBA" },
  ];

  const users =
    usersList?.map(
      ({ id, first_name, last_name, email, company: { name } }) => {
        return {
          value: id,
          label: (
            <>
              <h3 className="fs-14 mb-0">{first_name + last_name}</h3>
              <span className="fs-12 color-grey d-block">{`${name} `}</span>
              <span className="fs-12 color-grey">{`${email} `}</span>
            </>
          ),
        };
      }
    ) || [];

  const vendors =
    vendorsAddressList?.map(({ name, addresses }) => {
      const {
        id,
        title,
        address_line_1,
        address_line_2,
        city,
        zipcode,
        state_or_province,
        country,
      } = addresses?.[0] || {};
      return {
        value: id,
        label: (
          <>
            <h3 className="fs-14 mb-0">{name}</h3>
            <span className="fs-12 color-grey">{`${address_line_1}${address_line_2} ${city} ${state_or_province} ${zipcode} ${country} `}</span>
          </>
        ),
      };
    }) || [];

  const labelTypes = [
    { value: "Zebra", label: "Zebra Labels" },
    { value: "30 Up", label: "30 Up Labels" },
  ];

  const handleSaveCheckInPo = (poData) => {
    const {
      id: pending_po_id,
      checked_in_quantity,
      expiry_date,
      bundle_quantity,
    } = poData || {};

    if (!isValidExpiryDate(expiry_date)) return;

    dispatch(
      SaveCheckInQuantity({
        pending_po_id,
        checked_in_quantity,
        bundle_quantity,
      })
    ).then(({ payload }) => {
      const { data } = payload || {};
      const { success } = data || {};
      if (success) {
        setParsedCheckInPos((parsedCheckInPos) => {
          const checkInPosClone = cloneDeep(parsedCheckInPos);
          const index = findIndex(checkInPosClone, { id: pending_po_id });
          if (index !== -1) {
            checkInPosClone[index]["checked_in_quantity"] = checked_in_quantity;
            checkInPosClone[index]["expiry_date"] = expiry_date;
            checkInPosClone[index]["bundle_quantity"] = bundle_quantity;
          }
          return checkInPosClone;
        });

        setPendingPos((pendingPos) => {
          const pendingPosClone = cloneDeep(pendingPos);
          const index = findIndex(pendingPosClone, { id: pending_po_id });
          if (index !== -1) {
            pendingPosClone[index]["checked_in_quantity"] = checked_in_quantity;
            pendingPosClone[index]["expiry_date"] = expiry_date;
            pendingPosClone[index]["bundle_quantity"] = bundle_quantity;
          }
          return pendingPosClone;
        });

        clearCheckedInQuantityStates();
        checked_in_quantity && handleGenerateProductLabels({ data: poData });
      }
    });
  };

  const handleGenerateProductLabels = ({
    labelQty,
    expiryDate,
    isZebra,
    data,
    additional,
  }) => {
    const {
      id: pending_po_id,
      vendoritem,
      bundle_quantity,
      checked_in_quantity,
      expiry_date,
    } = data || {};
    const { seller_sku, vendorasin } = vendoritem || {};
    const { fnsku, item_condition, item_name, prepinstruction } =
      seller_sku || {};
    const { name: product_title } = vendorasin || {};

    if (!fnsku) return;

    const conditationKey = findKey(
      CONDITION_TYPE,
      ({ value }) => value == item_condition
    );
    const { label } = CONDITION_TYPE[conditationKey] || {};

    let numberOfLabels = 0;
    if (labelQty) numberOfLabels = labelQty;
    else numberOfLabels = checked_in_quantity / bundle_quantity;

    const labelsData = {
      fnsku,
      title: item_name || product_title,
      condition: label,
      expiryDate: additional
        ? (expiryDate && moment(expiryDate).format("MM-DD-YYYY")) || "N/A"
        : (expiry_date && moment(expiry_date).format("MM-DD-YYYY")) || "N/A",
      packageQuantity: bundle_quantity,
      numberOfLabels,
      username: user_name,
      companyName: company_name,
      prepInstruction: prepinstruction,
    };

    if (
      isZebra ||
      (isUndefined(isZebra) && currentLabelType?.value?.includes("Zebra"))
    )
      zebraLabels(labelsData);
    else thirtyUpLabels(labelsData);
  };

  const handleUpdatePoCompleteStatus = ({
    pending_po_index_id,
    marked_as_hide,
  }) => {
    dispatch(
      UpdatePoCompleteStatus({
        pending_po_index_id,
        marked_as_hide,
      })
    ).then(({ payload }) => {
      const { data } = payload || {};
      const { success } = data || {};
      if (success) dispatch(GetPendingPoIndices({ filters, page }));
    });
  };

  const handleSyncCheckInQuantity = (syncWith) => {
    const pending_po_index_id = pendingPoIndices[currentPendingPoIndex].id;
    dispatch(SyncCheckInQuantity({ pending_po_index_id, syncWith })).then(
      ({ payload }) => {
        const { data } = payload || {};
        const { success } = data || {};
        if (success) {
          dispatch(GetPendingPos({ pending_po_index_id })).then(
            ({ payload }) => {
              const { data } = payload || {};
              const { success, result } = data || {};
              if (success) setPendingPos(result);
            }
          );
        }
      }
    );
  };

  const completedPosToggleStatus = find(filters, { field: "marked_as_hide" })?.value;

  return (
    <>
      <Row className="mt-4 align-items-baseline">
        <Col md={4}>
          <SearchInput
            placeholder="Search by Invoice #, Vendor Upc, Asin, Sku, Upc, PO #, Order ID, Tracking No."
            inputRef={poSearchRef}
            onChange={({ target }) =>
              handleFilter({
                isDropDown: false,
                field: "",
                value: target.value,
              })
            }
          />
        </Col>
        <Col md={2}>
          <Select
            placeholder="Vendor address"
            loading={fetchingVendorsList}
            disabled={fetchingVendorsList}
            value={selectedVendor}
            onChange={(event) => {
              const value = event?.value || "";
              const label =
                event?.label?.props?.children?.[0]?.props?.children || "";
              setSelectedVendor(value ? { value, label } : "");
              handleFilter({
                isDropDown: true,
                field: "vendor_address_id",
                value,
              });
            }}
            className="h-32"
            options={vendors}
            isClearable={true}
          />
        </Col>
        <Col md={2}>
          <Select
            placeholder="User"
            loading={fetchingUsersList}
            disabled={fetchingUsersList}
            value={selectedUser}
            onChange={(event) => {
              const value = event?.value || "";
              const label =
                trim(event?.label?.props?.children?.[0]?.props?.children) ||
                trim(event?.label?.props?.children?.[2]?.props?.children) ||
                "";
              setSelectedUser(value ? { value, label } : "");
              handleFilter({ isDropDown: true, field: "user_id", value });
            }}
            className="h-32"
            options={users}
            isClearable={true}
          />
        </Col>
        <Col md={2}>
          <Select
            placeholder="PO Type"
            onChange={(event) =>
              handleFilter({
                isDropDown: true,
                field: "storage_status",
                value: event?.value || "",
              })
            }
            className="h-32"
            options={poTypes}
            isClearable={true}
          />
        </Col>
        <Col md={2}>
          <Switch
            className="checkin-main-switch"
            text={["Show Completed PO’s ", <strong>({completedCount || 0})</strong>]}
            onChange={(event) => handleFilter({ isDropDown: true, field: "marked_as_hide", value: event })}
            checked={completedPosToggleStatus}
          />
        </Col>
      </Row>
      <Spin
        tip="Loading..."
        spinning={fetchingPendingPoIndices || updatingPoCompleteStatus}
      >
        {pendingPoIndices.length ? (
          <>
            <TilesWrapper height="433px">
              {pendingPoIndices?.map(
                (
                  {
                    storage_status,
                    po_code,
                    order_id,
                    invoice_number,
                    expected_delivery_at,
                    last_checked_in_at,
                    created_at,
                    user,
                    vendor,
                    id,
                    marked_as_hide,
                    sumOrderQuantity,
                    sumRecievedQuantity,
                    invoiceQuantitySum,
                    workingQuantitySum,
                  },
                  index
                ) => {
                  const { company, first_name, last_name } = user || {};
                  const user_name = `${first_name ?? ""} ${last_name ?? ""}`;
                  const { name: company_name } = company || {};
                  const { name: vendor_name, addresses } = vendor || {};
                  const {
                    address_line_1,
                    address_line_2,
                    city,
                    country,
                    state_or_province,
                    zipcode,
                  } = addresses?.[0] || {};
                  const vendor_address = `${address_line_1 ?? ""} ${address_line_2 ?? ""
                    } ${city ?? ""} ${state_or_province ?? ""}`;

                  return (
                    <div className="tiles-box">
                      <Table responsive className="tiles-table">
                        <thead>
                          <tr>
                            {tilesHeader.map((header) => (
                              <th className={header === "Quantity" ? "checkin-quantity-column" : ""}>
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span
                                className="fw-bold color-primary"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  window.open(
                                    `${process.env.REACT_APP_BASE_URL}po/my-orders/${id}`,
                                    "_blank"
                                  );
                                }}
                              >
                                {po_code || "N/A"}
                              </span>
                              <span
                                className={
                                  storage_status
                                    ? "badge-sucess"
                                    : "badge-primary"
                                }
                              >
                                {storage_status || "FBA"}
                              </span>
                            </td>
                            <td>
                              <span>{user_name || "N/A"}</span>
                              <span>{company_name || "N/A"}</span>
                            </td>
                            <td>
                              <span>{vendor_name || "N/A"}</span>
                              <span>{vendor_address || "N/A"}</span>
                            </td>
                            <td>
                              <span className="d-flex gap-3">
                                <span className="d-flex flex-column">
                                  <span className="">{sumOrderQuantity || 0}</span>
                                  <span className="fs-10 color-secondary">
                                    Order
                                  </span>
                                </span>
                                <span className="d-flex flex-column">
                                  <span className="">{invoiceQuantitySum || 0}</span>
                                  <span className="fs-10 color-secondary">
                                    Invoice
                                  </span>
                                </span>
                                <span className="d-flex flex-column">
                                  <span className="">{workingQuantitySum || 0}</span>
                                  <span className="fs-10 color-secondary">
                                    Working
                                  </span>
                                </span>
                                <span className="d-flex flex-column">
                                  <span className="">{sumRecievedQuantity || 0}</span>
                                  <span className="fs-10 color-secondary">
                                    Received
                                  </span>
                                </span>
                              </span>
                            </td>
                            <td>
                              <span>{order_id || "N/A"}</span>
                            </td>
                            <td>
                              <span>{invoice_number || "N/A"}</span>
                            </td>
                            <td>
                              <span>
                                {getFormattedDate(last_checked_in_at)}
                              </span>
                            </td>
                            <td>
                              <span>
                                {getFormattedDate(expected_delivery_at)}
                              </span>
                            </td>
                            <td>
                              <span>
                                {getFormattedDate(created_at)}
                              </span>
                            </td>
                            <td className="action-icons">
                              <div className="d-flex flex-row align-items-center gap-3">
                                <Tooltip title="Check In History">
                                  <img
                                    src={History}
                                    alt="no-timer"
                                    className="cursor-pointer"
                                    onClick={() => {
                                      setCurrentPendingPoIndex(index);
                                      setHistory(true);
                                      dispatch(GetTrackingNumbers(id));
                                    }}
                                  />
                                </Tooltip>
                                {!marked_as_hide && (
                                  <Tooltip title="Check In">
                                    <div style={{ display: "inline-block" }}>
                                      <span
                                        class="icon-arrow-right cursor-pointer"
                                        onClick={() => {
                                          setCurrentPendingPoIndex(index);
                                          setStorageStatus(
                                            storage_status || "FBA"
                                          );
                                        }}
                                      ></span>
                                    </div>
                                  </Tooltip>
                                )}
                                <Popconfirm
                                  key="po_complete_status"
                                  placement="left"
                                  title={`Are you sure, you want to Mark Po ${marked_as_hide ? "In-Complete" : "Complete"
                                    } ?`}
                                  onConfirm={() =>
                                    handleUpdatePoCompleteStatus({
                                      pending_po_index_id: id,
                                      marked_as_hide: !marked_as_hide,
                                    })
                                  }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Tooltip
                                    title={
                                      marked_as_hide
                                        ? "Mark as Incomplete"
                                        : "Mark as Complete"
                                    }
                                  >
                                    {marked_as_hide ? (
                                      <BsX className="fs-25 cursor-pointer text-primary" />
                                    ) : (
                                      <BsCheck2 className="fs-25 cursor-pointer text-primary" />
                                    )}
                                  </Tooltip>
                                </Popconfirm>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  );
                }
              )}
            </TilesWrapper>
            <TilesPagination
              total={totalCount}
              offset={pendingPoIndices.length}
              onClick={handlePagination}
            />
          </>
        ) : (
          <div className="empty-data-container">
            {(!completedPosToggleStatus && completedCount && !pendingPoIndices.length && !fetchingPendingPoIndices) ?
              <div className="nodata-notification justify-content-between">
                <div className="notification-text">
                  <img className="notification-icon" src={IconInfo} />
                  There are {completedCount} number of PO’s in completed PO’s.
                </div>
                <div className="notification-toggle">
                  <a onClick={() => { handleFilter({ isDropDown: true, field: "marked_as_hide", value: true }); return false; }}>
                    Click Here to View
                  </a>
                </div>
              </div> : null
            }
            <img
              src={NoInbound}
              alt="no-data"
              className="mx-auto d-block checkin-main-nodataimg"
            />
          </div>
        )}
      </Spin>

      <Drawer show={storageStatus === "Storage Po"} size="70%">
        <TilesWrapper className="mt-0">
          <div className="drawer-padding">
            <div className="d-flex align-items-center  header">
              <span
                class="icon-arrow-left cursor-pointer"
                onClick={() => {
                  setStorageStatus("");
                  setStoragePoTrackingNumber("");
                  setTrackingNumbers([]);
                }}
              ></span>
              <h2 className="mb-0 color-black">Check In</h2>
            </div>
            <div className="marketplace-information mb-3 p-4">
              <h3
                className="color-primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_BASE_URL}po/my-orders/${id}`,
                    "_blank"
                  );
                }}
              >
                PO# {po_code || "N/A"}
              </h3>
              <div className="details">
                <div>
                  <span>PO Type</span>
                  <span
                    className={
                      storage_status ? "badge-sucess" : "badge-primary"
                    }
                  >
                    {storage_status || "FBA"}
                  </span>
                </div>
                <div>
                  <span>Username</span>
                  <span>{user_name || "N/A"}</span>
                </div>
                <div>
                  <span>company Name</span>
                  <span>{company_name || "N/A"}</span>
                </div>
                <div>
                  <span>Vendor Name</span>
                  <span>{vendor_name || "N/A"}</span>
                </div>
              </div>
              <div className="details">
                <div className="mb-0">
                  <span>created Date</span>
                  <span>{getFormattedDate(created_at)}</span>
                </div>
                <div className="mb-0">
                  <span>Ordered Units</span>
                  <span>{sumOrderQuantity}</span>
                </div>
                <div className="mb-0">
                  <span>Received Units</span>
                  <span>{sumRecievedQuantity}</span>
                </div>
                <div className="mb-0 ">
                  <span>Remaining Units</span>
                  <span>{remainingQuantity}</span>
                </div>
              </div>
            </div>

            <Spin
              tip="Loading..."
              spinning={addingTrackingNumbers || addingCheckedInQuantities}
            >
              <div className="d-flex input-space align-items-center">
                <Input
                  name="sourceOrderId"
                  label="Tracking/Pro Number"
                  placeholder="Enter Tracking/Pro  Number"
                  className="h-32"
                  width="100%"
                  value={storagePoTrackingNumber}
                  onChange={({ target }) => {
                    setStoragePoTrackingNumber(target.value);
                  }}
                />
                <Button
                  text="Add"
                  className="primary add-space"
                  onClick={() => handleTrackingNumbers()}
                />
              </div>
              <AgGrid
                user_referrals={trackingNumbers}
                defaultColDef={defaultColDef}
                columnDefinitions={checkInColDef}
                rowHeight={42}
                className="checkin-table"
              />
              <div className="d-flex justify-content-between align-items-center mt-3 gap-3">
                <TotalCount total={trackingNumbers?.length || 0} />
                <div className="d-flex drawer-header-buttons gap-3">
                  <Button
                    text="Check-in and Receive"
                    className="outlined"
                    onClick={() => handleSubmitTrackingNumbers({ storage_status })}
                  />
                  {
                    last_checked_in_at &&
                    <Button
                      text="Skip and Proceed to Receive"
                      className="outlined"
                      onClick={() => navigate(`/fba-inbound/receive-items?po=${pendingPoIndices[currentPendingPoIndex].po_code}`)}
                    />
                  }
                </div>
              </div>
            </Spin>
          </div>
        </TilesWrapper>
      </Drawer>

      <Drawer show={storageStatus === "FBA"} size="1058px">
        <TilesWrapper className="mt-0">
          <div className="drawer-padding drawer-checkin">
            <div className="d-flex align-items-center justify-content-between drawer-header">
              <div className="d-flex align-items-center  header">
                <span
                  class="icon-arrow-left cursor-pointer"
                  onClick={() => {
                    setStorageStatus("");
                    setCheckInQuantites(false);
                    setSimplePoTrackingNumber("");
                    setTrackingNumbers([]);
                    clearCheckedInQuantityStates(true);
                  }}
                ></span>
                <h2 className="mb-0 color-black">Check In</h2>
              </div>

              {checkInQuantites && (
                <div className="d-flex drawer-header-buttons">
                  <Popconfirm
                    key="sync_check_in_with_order_quantity"
                    placement="left"
                    title={`Are you sure, you want to sync check-in with Order Quantity ?`}
                    onConfirm={() => handleSyncCheckInQuantity("Order")}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      text="Check-In With Order Quantity"
                      className="outlined"
                    />
                  </Popconfirm>
                  <Popconfirm
                    key="sync_check_in_with_on_invoice_quantity"
                    placement="left"
                    title={`Are you sure, you want to sync check-in with On-Invoice Quantity ?`}
                    onConfirm={() => handleSyncCheckInQuantity("On-Invoice")}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      text="Check-In With On-Invoice Quantity"
                      className="outlined"
                    />
                  </Popconfirm>
                </div>
              )}
            </div>
            <div className="marketplace-information mb-3">
              <div className="details justify-content-between width-0">
                <div className="d-flex flex-column">
                  <span>PO# </span>
                  <span
                    className="fw-bold color-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_BASE_URL}po/my-orders/${id}`,
                        "_blank"
                      );
                    }}
                  >
                    {po_code || "N/A"}
                  </span>
                  <span
                    className={
                      storage_status ? "badge-sucess" : "badge-primary"
                    }
                  >
                    {storage_status || "FBA"}
                  </span>
                </div>

                <div className="d-flex flex-column">
                  <span>Username </span>
                  <span>{user_name || "N/A"}</span>
                  <span>{company_name || "N/A"}</span>
                </div>

                <div>
                  <span>Vendor </span>
                  <span>{vendor_name || "N/A"}</span>
                  <span>{vendor_address || "N/A"}</span>
                </div>
                <div>
                  <span>Units</span>
                  <div className="d-flex unit-details">
                    <div>
                      <span>{sumOrderQuantity || 0}</span>
                      <span>Order</span>
                    </div>
                    <div>
                      <span>{invoiceQuantitySum || 0}</span>
                      <span>Invoice</span>
                    </div>
                    <div>
                      <span>{workingQuantitySum || 0}</span>
                      <span>Working</span>
                    </div>
                    <div>
                      <span>{sumRecievedQuantity || 0}</span>
                      <span>Received</span>
                    </div>
                    <div>
                      <span>{remainingQuantity || 0}</span>
                      <span>Remaining</span>
                    </div>
                  </div>
                </div>
                {/* <div className="mb-0">
                  <span>Order Units</span>
                  <span>{sumOrderQuantity || 0}</span>
                </div> */}
                {/* <div className="mb-0">
                  <span>Invoice Units</span>
                  <span>{invoiceQuantitySum || 0}</span>
                </div> */}
                {/* <div className="mb-0">
                  <span>Working Units</span>
                  <span>{workingQuantitySum || 0}</span>
                </div> */}
                {/* <div className="mb-0">
                  <span>Received Units</span>
                  <span>{sumRecievedQuantity || 0}</span>
                </div> */}
                {/* <div className="mb-0 ">
                  <span>Remaining Units</span>
                  <span>{remainingQuantity || 0}</span>
                </div> */}
                <div className="mb-0">
                  <span>created Date</span>
                  <span>{moment(created_at).format("DD MMM, YYYY")}</span>
                  <span>at {moment(created_at).format("HH:mm:ss")}</span>
                </div>
              </div>
            </div>
            <div className="stepper">
              <span className="d-flex flex-column align-items-center position-relative">
                {checkInQuantites ? (
                  <BsCheckCircleFill className="selected" />
                ) : (
                  <BsCheckCircle className="color-primary" />
                )}
                <label
                  className={`title left ${checkInQuantites ? "" : "fw-bold color-black"
                    }`}
                >
                  Add Tracking/Pro #
                </label>
              </span>
              <div className="stepper-border"></div>
              <span className="d-flex flex-column align-items-center position-relative">
                {checkInQuantites ? (
                  <BsCheckCircle className="color-primary" />
                ) : (
                  <BsCheckCircle className="color-grey" />
                )}
                <label
                  className={`title right ${checkInQuantites ? "fw-bold color-black" : "color-grey"
                    }`}
                >
                  Add Checkin Quantities
                </label>
              </span>
            </div>
            {checkInQuantites && (
              <>
                <Row className="" style={{ marginTop: "5px !important" }}>
                  <Col md={9}>
                    <SearchInput
                      placeholder="Title / UPC / ASIN / SKU / FNSKU / Vendor UPC"
                      inputRef={checkedInPoRef}
                      onChange={({ target }) => handleSearchPo(target.value)}
                    />
                  </Col>
                  <Col md={3}>
                    <Select
                      value={currentLabelType}
                      onChange={(event) => setCurrentLabelType(event)}
                      options={labelTypes}
                    />
                  </Col>
                </Row>
                <Row className="" style={{ marginTop: "0px !important" }}>
                  <Col md={12}>
                    <div className="empty-data-container">
                      <div className="nodata-notification drawer-nodata-notification">
                        <img className="notification-icon" src={IconInfo} />
                        Please make sure to click save icon after entering Check
                        In quantity to get the labels and save permanently
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            <Spin
              tip="Loading..."
              spinning={
                addingTrackingNumbers ||
                fetchingPendingPos ||
                addingCheckedInQuantities ||
                savingCheckInQuantity ||
                syncingCheckInQuantity
              }
            >
              {!checkInQuantites ? (
                <>
                  <div className="d-flex input-space align-items-center my-4">
                    <Input
                      name="sourceOrderId"
                      placeholder="Enter Tracking/Pro  Number"
                      className="h-32"
                      marginBottom="0"
                      width="100%"
                      value={simplePoTrackingNumber}
                      onChange={({ target }) => {
                        setSimplePoTrackingNumber(target.value);
                      }}
                    />
                    <Button
                      text="Add"
                      className="outlined mb-0"
                      onClick={() => handleTrackingNumbers(true)}
                    />
                  </div>
                  <AgGrid
                    user_referrals={trackingNumbers}
                    defaultColDef={defaultColDef}
                    columnDefinitions={checkInColDef}
                    rowHeight={42}
                    className="checkin-table"
                  />
                </>
              ) : (
                <AgGrid
                  user_referrals={parsedCheckInPos}
                  defaultColDef={defaultColDef}
                  columnDefinitions={checkInPosColDef}
                  gridReady={gridReady}
                  rowHeight={58}
                  height="381px"
                  className="po-checkin-quantites"
                />
              )}
              <div className="d-flex justify-content-between align-items-center mt-3 gap-3">
                <TotalCount
                  total={
                    checkInQuantites
                      ? parsedCheckInPos?.length || 0
                      : trackingNumbers?.length || 0
                  }
                />
                <div className="d-flex gap-3">
                  {checkInQuantites ? (
                    <>
                      <Button
                        text="Complete Check-In"
                        className="outlined"
                        // disabled={isEqual(parsedCheckInPos, pendingPos)}
                        onClick={() =>
                          handleSubmitTrackingNumbers({ storage_status })
                        }
                      />
                      <Button
                        text="Complete Check-In and Create Shipments"
                        className="primary"
                        // disabled={isEqual(parsedCheckInPos, pendingPos)}
                        onClick={() =>
                          handleSubmitTrackingNumbers({
                            storage_status,
                            isRedirectToShipment: true,
                          })
                        }
                      />
                    </>
                  ) : (
                    <Button
                      text="Add Checkin Quantities"
                      className="outlined"
                      // disabled={!trackingNumbers?.length}
                      onClick={() => {
                        setCheckInQuantites(true);
                        dispatch(
                          GetPendingPos({
                            pending_po_index_id:
                              pendingPoIndices[currentPendingPoIndex].id,
                          })
                        ).then(({ payload }) => {
                          const { data } = payload || {};
                          const { success, result } = data || {};
                          if (success) setPendingPos(result);
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            </Spin>
          </div>
        </TilesWrapper>
      </Drawer>

      <Drawer show={history} size="666px">
        <TilesWrapper className="mt-0">
          <div className="drawer-padding">
            <div className="d-flex align-items-center header">
              <span
                class="icon-arrow-left cursor-pointer"
                onClick={() => setHistory(false)}
              ></span>
              <h2 className="mb-0 color-black">Check In History</h2>
            </div>
            <div className="marketplace-information mb-3">
              <h3
                className="color-primary"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_BASE_URL}po/my-orders/${id}`,
                    "_blank"
                  );
                }}
              >
                PO# {po_code || "N/A"}
              </h3>
              <div className="details">
                <div>
                  <span>PO Type</span>
                  <span
                    className={
                      storage_status ? "badge-sucess" : "badge-primary"
                    }
                  >
                    {storage_status || "FBA"}
                  </span>
                </div>
                <div>
                  <span>Username</span>
                  <span>{user_name || "N/A"}</span>
                </div>
                <div>
                  <span>company Name</span>
                  <span>{company_name || "N/A"}</span>
                </div>
                <div>
                  <span>Vendor Name</span>
                  <span>{vendor_name || "N/A"}</span>
                </div>
              </div>
              <div className="details">
                <div className="mb-0">
                  <span>created Date</span>
                  <span>{getFormattedDate(created_at)}</span>
                </div>
                <div className="mb-0">
                  <span>Ordered Units</span>
                  <span>{sumOrderQuantity}</span>
                </div>
                <div className="mb-0">
                  <span>Received Units</span>
                  <span>{sumRecievedQuantity}</span>
                </div>
                <div className="mb-0">
                  <span className="text-truncate w-75">Remaining Units</span>
                  <span>{remainingQuantity}</span>
                </div>
              </div>
            </div>
            <Spin tip="Fetching..." spinning={fetchingTrackingNumbers}>
              <AgGrid
                user_referrals={pendingPoArriveds}
                defaultColDef={defaultColDef}
                columnDefinitions={checkInHistoryColDef}
                rowHeight={42}
                height={"250px"}
              />
            </Spin>
          </div>
        </TilesWrapper>
      </Drawer>
    </>
  );
};
export default CheckIn;
