import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";

// import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { PricingAndFeesWrapper } from "../style";

const PricingAndFees = ({
  setDraftPo,
  draft_po
}) => {
  const [rowData, setRowData] = useState([]);
  const columnDefs = [
    {
      headerName: "Prep Fees",
      field: "Prep Fees",
      cellRendererFramework: ({ data }) => {
        return <p className="sku-text">{data?.label}</p>;
      },
      width: 330,
    },
    {
      headerName: "Quantity	",
      field: "quantity",
      cellRendererFramework: ({ data }) => {
        return <p className="sku-text">{data?.value}</p>;
      },
      width: 120,
    },
    {
      headerName: "Unit cost",
      field: "unit cost",
      cellRendererFramework: ({ data }) => {
        return <p className="sku-text">${data?.unitCost}</p>;
      },
      width: 120,
    },
    {
      headerName: "Amount",
      field: "amount",
      cellRendererFramework: ({ data }) => {
        return <p className="sku-text">${data?.total}</p>;
      },
      width: 120,
    },
  ];

  useEffect(() => {
    if (draft_po?.prep_fee) {
      const newData = [];
      for (const key in draft_po?.prep_fee) {
        if (key !== 'Total Fee') {
          const { charges, quantity } = draft_po?.prep_fee?.[key] || {};
          if (!quantity) continue;
          newData.push({
            id: key,
            label: key,
            value: quantity,
            unitCost: charges,
            total: Math.round((quantity * charges + Number.EPSILON) * 100) / 100
          });
        }
      }
      setRowData(newData);
    }
  }, [draft_po]);

  return (
    <PricingAndFeesWrapper>
      <div className="price-accordion-table">
        <div className="ag-theme-material">
          <AgGridReact
            columnDefs={columnDefs}
            rowData={rowData}
            rowHeight={64}
            domLayout="autoHeight"
          />
        </div>
        <div className="total-fee-cost-wrapper">
          <h6>Total Prep Fees</h6>
          <div className="right-section">
            <p>${(draft_po?.prep_fee?.['Total Fee']).toFixed(2)}</p>
          </div>
        </div>
      </div>
    </PricingAndFeesWrapper>
  );
};

export default PricingAndFees;
