import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { DrawerWrapper } from "../style";
import infoIcon from "../../../assets/images/exclamation-circle.svg";
import Input from "../../../components/uiComponent/inputs/input/index";
import { BsTrash } from "react-icons/bs";
import { Select, Divider, Icon, notification, Spin } from "antd";
import Modal from "../../../components/uiComponent/modal/index";
import { useSelector, useDispatch } from "react-redux";
import {
  GetAllLocations,
  CreateNewLocation,
  AddToStorage
} from "../../../slices/fba-inbound/to-receive";
import { generateStorageBarcode } from "../../../utils/locationLabels";

const { Option } = Select;

const PutToStorage = forwardRef(({
  totalQty,
  qtyAssignToPos,
  qtyToStorage,
  staging_item,
  handleClearStorageStates,
  handleGenerateStorageLabels
}, ref) => {

  useImperativeHandle(ref, () => ({
    handleAddToStorage() {
      const { disableBtn, qtyAssignedToLocations } = getSubmitBtnDisableStatus();
      const notificationObj = {
        top: 65,
      };
      if (disableBtn) {
        notificationObj.message = 'Please assign quantity to a location';
      }
      else if (qtyAssignedToLocations > qtyToStorage) {
        notificationObj.message = 'Locations assigned Quantity cannot be greater than the available Storage-Qty';
      }
      else {
        return dispatch(AddToStorage({
          staging_item,
          locations: assignedLocations || []
        }))
          .then(({ payload }) => {
            const { success, staging_item } = payload?.data || {};
            if (success) {
              handleGenerateStorageLabels({ staging_item });
              handleClearStorageStates();
              setAssignedLocations([{ storage_id: '', quantity: 0 }]);
            }
          });
      }
      notificationObj.hasOwnProperty('message') && notification.error(notificationObj);
    }
  }));

  const dispatch = useDispatch();

  const [assignedLocations, setAssignedLocations] = useState([{ storage_id: '', quantity: 0 }]);
  const [locationsList, setLocationsList] = useState([]);
  const [locName, setLocName] = useState("");
  const [currentNewLocationIndex, setCurrentNewLocationIndex] = useState(-1);
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef(null);

  const {
    toReceive: {
      fetchingAllLocations,
      creatingNewLocation,
      addingToStorage
    }
  } = useSelector((state) => state.fbaInbound || {});

  useEffect(() => {
    dispatch(GetAllLocations())
      .then(({ payload }) => {
        const { success, locations } = payload?.data || {};
        if (success) {
          setLocationsList(locations);
        }
      });
  }, []);

  const onNameChange = (event) => {
    setLocName(event.target.value);
  };

  const getSubmitBtnDisableStatus = () => {
    let qtyAssignedToLocations = 0;
    let disableBtn = false;
    assignedLocations.forEach((obj) => {
      const { storage_id, quantity } = obj || {};
      qtyAssignedToLocations += (quantity || 0);
      if ((storage_id && !quantity) || (!storage_id && quantity)) {
        disableBtn = true
      }
    });

    return { disableBtn, qtyAssignedToLocations };
  };

  const handleDownloadLocationLabel = (data) => {
    const { uuid, name, warehouse_user } = data || {};
    generateStorageBarcode({
      warehouseName: warehouse_user,
      locationName: name,
      uuid,
      labelType: 'location'
    })
  };

  const addLocation = (e) => {
    e.preventDefault();
    dispatch(CreateNewLocation({ storage: { name: locName } }))
      .then(({ payload }) => {
        const { success, location } = payload?.data || {};
        if (success) {
          handleDownloadLocationLabel(location);
          handleLocationChange(location.id, currentNewLocationIndex);
          setLocationsList(locationsList => [...locationsList, [location.id, location.name]]);
          setLocName("");
          inputRef?.current?.value && (inputRef.current.value = "");
          setShowModal(false);
        }
      });
  };

  const handleQtyChange = (e, index) => {
    const { value } = e.target;
    const data = [...assignedLocations];
    data[index].quantity = Number(value);
    setAssignedLocations(data);
  };

  const handleLocationChange = (val, index) => {
    const data = [...assignedLocations];
    data[index].storage_id = val;
    setAssignedLocations(data);
  };

  const handleRemoveClick = (index) => {
    const data = [...assignedLocations];
    data.splice(index, 1);
    setAssignedLocations(data);
  };

  const handleAddClick = () => {
    setAssignedLocations([...assignedLocations, { storage_id: '', quantity: 0 }]);
  };

  return (
    <DrawerWrapper>
      <div className="info-status d-flex align-items-center gap-2">
        <img src={infoIcon} alt="info" />
        <span className="fs-12 fw-semibold color-black">
          Remaining units need to put to storage.
        </span>
      </div>
      <div className="d-flex gap-4 fs-12 fw-semibold mt-3">
        <span className="d-flex gap-1">
          <span className="color-regent">Total Qty:</span>
          <span className="color-secondary">{totalQty}</span>
        </span>
        <span className="d-flex gap-1">
          <span className="color-regent">Qty Assigned to POs</span>
          <span className="color-secondary">{qtyAssignToPos || 0}</span>
        </span>
        <span className="d-flex gap-1">
          <span className="color-regent">Qty to Storage:</span>
          <span className="color-secondary">{qtyToStorage || 0}</span>
        </span>
      </div>
      <div className="mt-3 mb-2">
        <span className="fw-bold color-black">Location Distribution</span>
      </div>
      <div className="box-height">
        <Spin tip='Loading...' spinning={addingToStorage}>
          {assignedLocations.map((item, index) => (
            <div className="d-flex gap-4 mb-3" key={index}>
              <div className="d-flex flex-column mt-1">
                {index === 0 && (
                  <span className="fs-12 color-regent mb-1">Location</span>
                )}
                <Select
                  showSearch
                  optionFilterProp='label'
                  style={{ width: 334, marginTop: index === 0 ? "-2px" : "-4px" }}
                  placeholder="Select Location"
                  listHeight={50}
                  value={item.storage_id || ''}
                  className="location-select"
                  loading={fetchingAllLocations || creatingNewLocation}
                  disabled={fetchingAllLocations || creatingNewLocation}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{ padding: "4px 8px 8px", cursor: "pointer" }}
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={() => {
                          setCurrentNewLocationIndex(index);
                          setShowModal(true);
                          setTimeout(() => { inputRef.current?.focus(); }, 0);
                        }}
                        className="d-flex align-items-center gap-2 color-primary fw-semibold"
                      >
                        <Icon type="plus" />
                        <span>Add New Location</span>
                      </div>
                    </div>
                  )}
                  onChange={(val) => handleLocationChange(val, index)}
                >
                  {locationsList?.map((loc) => (
                    <Option key={loc[0]} value={loc[0]} label={loc[1]}>{loc[1]}</Option>
                  )) || []}
                </Select>
              </div>
              <div>
                {index === 0 && (
                  <span className="fs-12 color-regent mb-1">Quantity</span>
                )}
                <Input
                  height="32px"
                  width="93px"
                  marginBottom="4px"
                  value={item.quantity || ''}
                  onChange={(e) => handleQtyChange(e, index)}
                />
              </div>
              {index === 0 ? (
                <div
                  className="add-box d-flex align-items-center justify-content-center mt-4"
                  onClick={handleAddClick}
                >
                  <span className="icon-add fs-12" />
                </div>
              ) : (
                <div
                  className="add-box d-flex align-items-center justify-content-center"
                  onClick={() => handleRemoveClick(index)}
                >
                  <BsTrash color="#CF0909" />
                </div>
              )}
            </div>
          ))}
        </Spin>
      </div>
      <Modal
        size="sm"
        open={showModal}
        onHide={() => setShowModal(false)}
        saveText="Cancel"
        onSave={() => setShowModal(false)}
      >
        <DrawerWrapper>
          <span className="fs-12 fw-semibold color-gray mb-1">
            Create New Location
          </span>
          <div className="d-flex align-items-center gap-2">
            <Input
              placeholder="Enter New Location Name"
              width="210px"
              height="32px"
              marginBottom="0px"
              onChange={onNameChange}
              inputRef={inputRef}
            />
            <div
              className="add-box d-flex align-items-center justify-content-center"
              style={locName.trim() ? {} : { opacity: 0.5, pointerEvents: 'none' }}
              onClick={addLocation}
            >
              <span className="icon-save" />
            </div>
          </div>
        </DrawerWrapper>
      </Modal>
    </DrawerWrapper>
  );
});

export default PutToStorage;
