import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import Header from "../fbaHeader/index";
import { ContactWrapper } from "./style";
import { isEmpty } from 'lodash';
import axios from '../../../axios_config';
import { notification } from 'antd';

const Index = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleFormSubmit = async () => {
    return axios({
      method: 'POST',
      url: `/api/v2/contacts/information`,
      data: {
        contact_information: {
          name,
          email,
          message
        }
      },
      headers: {
        Authorization: ''
      }
    }).then(() => {
      setName('');
      setEmail('');
      setMessage('');
    }).catch((error) => {
      if (axios.isCancel(error)) return;
      notification.error({
        message: 'CONTACT US',
        description: error.message || 'Failed To Submit',
        top: 65
      })
    })
  }

  return (
    <ContactWrapper>
      <Header className="white-header" />
      <div className="sales-form">
        <h2>We’d Love to Hear From You!</h2>
        <div className="info">
          We’re happy to answer and question you may have about ecomdash. Fill
          out the form and we will get in touch with you as soon as possible.
        </div>
        <div className="form-section">
          <Form.Group className="mb-3">
            <Form.Control type="text" placeholder="Name" onChange={({ target }) => setName(target.value)} />
          </Form.Group>
          <Form.Group className="mb-3" >
            <Form.Control type="text" placeholder="Email" onChange={({ target }) => setEmail(target.value)} />
          </Form.Group>
          <Form.Group className="mb-3" >
            <Form.Control as="textarea" placeholder="Your Message" onChange={({ target }) => setMessage(target.value)} rows={3} />
          </Form.Group>
          <div>
            <Button className="purple-btn" disabled={isEmpty(name.trim()) || isEmpty(email.trim()) || isEmpty(message.trim())}
              onClick={handleFormSubmit}>
              Improve My Sales
            </Button>
          </div>
        </div>

        <div className="contact-errors">
          <span className="text-danger"></span>
        </div>
        <div className="clearfix"></div>
        <hr/>
        <div className="form-section">
          <div className="form-group"><strong> Daily Distro LLC </strong> </div>
          <div className="form-group">4211 35th St, Long Island City</div>
          <div className="form-group">New York, 111012301 2012557995</div>
          <div className="copyright">© 2018-All Rights reserved. Support.Sales</div>
        </div>
      </div>
    </ContactWrapper>
  );
};
export default Index;
