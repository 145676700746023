import { cloneDeep, filter } from "lodash";
const initialState = {
  combinedOrders: [],
  pendingPoStats: [],
  users: [],
  cards: [],
  asins: [],
  creditSummaryData: [],
  payment_history: [],
  fetching: false,
  fetchingUsers: false,
  fetchingForPaymentHistory: false,
  fetchingForCreditSummary: false,
  fetchingPendingPoIndices: false,
  fetchingForAttachingPo: false,
  fetchingForNewCard: false,
  fetchingForCharging: false,
  fetchingForEmail: false,
  fetchingForApprove: false,
  fetchingForUpdate: false,
  fetchingForCards: false,
  fetchingForMarkingInvoice: false,
  fetchingPendingPosDrawerData: false,
  addingAddToWorkOrder: false,
  disableAddToWorkOrderButton: false,
  fetchingApprovedInvoices: false,
  disabledApproveButton: false,
  total_records: 0,
  filters: [],
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  token: '',
  re_approved_invoice_count: 0,
  fetchingForCardsAndBanks: false,
  cardsAndBanks: [],
  sendingEmailToClient: false,
  fetchingUsersList: false,
  usersList: [],
  fetchingPaymentStatus: false,
  fetchingDistributionDetails: false,
  fetchingDistroDataListForCreditAmount: false,
  syncingDistroCost: false,
  orderedUnitsAlreadyAddedToPickList: false,
  updatingDistroPoCompleteStatus: false,
  completed_pos_count: 0
};

const combinedOrders = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'GET_USER_CARDS_REQUEST': {
      return {
        ...state,
        fetchingForCards: true
      };
    }
    case 'GET_USER_CARDS_AND_BANKS_REQUEST': {
      return {
        ...state,
        fetchingForCardsAndBanks: true
      };
    }
    case 'GET_USER_CARDS_AND_BANKS_SUCCESS': {
      return {
        ...state,
        fetchingForCardsAndBanks: false,
        cardsAndBanks: action.payload
      };
    }
    case 'GET_USER_CARDS_AND_BANKS_FAILED': {
      return {
        ...state,
        fetchingForCardsAndBanks: false
      };
    }
    case 'EMAIL_CHARGE_LINK_REQUEST': {
      return {
        ...state,
        sendingEmailToClient: true
      };
    }
    case 'EMAIL_CHARGE_LINK_SUCCESS': {
      return {
        ...state,
        sendingEmailToClient: false
      };
    }
    case 'EMAIL_CHARGE_LINK_FAILED': {
      return {
        ...state,
        sendingEmailToClient: false
      };
    }
    case 'GET_APPROVED_INVOICE_REQUEST': {
      return {
        ...state,
        fetchingApprovedInvoices: true
      }
    }
    case 'GET_APPROVED_INVOICE_SUCCESS':
    case 'GET_APPROVED_INVOICE_FAILED': {
      return {
        ...state,
        ...action.payload,
        fetchingApprovedInvoices: false
      }
    }
    case 'GET_CREDIT_SUMMARY_REQUEST': {
      return {
        ...state,
        fetchingForCreditSummary: true
      };
    }
    case 'RESET_CREDIT_SUMMARY_DATA': {
      return {
        ...state,
        creditSummaryData: []
      };
    }
    case 'GET_CREDIT_SUMMARY_SUCCESS': {
      return {
        ...state,
        fetchingForCreditSummary: false,
        creditSummaryData: action.payload
      };
    }
    case 'GET_CREDIT_SUMMARY_FAILED': {
      return {
        ...state,
        fetchingForCreditSummary: false
      };
    }
    case 'GET_USER_CARDS_SUCCESS': {
      return {
        ...state,
        fetchingForCards: false,
        cards: action.payload
      };
    }
    case 'GET_USER_CARDS_FAILED': {
      return {
        ...state,
        fetchingForCards: false
      };
    }
    case 'RESET_CARDS': {
      return {
        ...state,
        cards: []
      };
    }
    case 'UPDATE_PENDING_PO_INDICES_COMBINED_ORDERS_REQUEST': {
      return {
        ...state,
        fetchingForUpdate: true
      };
    }
    case 'UPDATE_PENDING_PO_INDICES_COMBINED_ORDERS_FAILED':
    case 'UPDATE_PENDING_PO_INDICES_COMBINED_ORDERS_SUCCESS': {
      return {
        ...state,
        fetchingForUpdate: false
      };
    }
    case 'RESET_UNIQUE_ASINS': {
      return {
        ...state,
        asins: []
      };
    }
    case 'APPROVE_INVOICE_COMBINED_ORDERS_REQUEST':
    case 'SAVE_APPROVED_INVOICE_COMBINED_ORDERS_REQUEST': {
      return {
        ...state,
        fetchingForApprove: true,
        disabledApproveButton: true,
      };
    }
    case 'APPROVE_INVOICE_COMBINED_ORDERS_FAILED':
    case 'APPROVE_INVOICE_COMBINED_ORDERS_SUCCESS':
    case 'SAVE_APPROVED_INVOICE_COMBINED_ORDERS_SUCCESS': {
      return {
        ...state,
        fetchingForApprove: false,
        disabledApproveButton: true
      };
    }
    case 'SAVE_APPROVED_INVOICE_COMBINED_ORDERS_FAILED': {
      return {
        ...state,
        fetchingForApprove: false,
        disabledApproveButton: false
      }
    }
    case 'REVERT_DISABLE_BUTTON': {
      return {
        ...state,
        disabledApproveButton: false
      }
    }
    case 'GET_UNIQUE_ASINS_REQUESTS': {
      return {
        ...state,
        fetchingUniqueAsins: true
      };
    }
    case 'GET_UNIQUE_ASINS_FAILED': {
      return {
        ...state,
        fetchingUniqueAsins: false
      };
    }
    case 'GET_UNIQUE_ASINS_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        fetchingUniqueAsins: false
      };
    }
    case 'SEND_EMAIL_INVOICE_PDF_REQUESTS': {
      return {
        ...state,
        fetchingForEmail: true
      };
    }
    case 'SEND_EMAIL_INVOICE_PDF_SUCCESS':
    case 'SEND_EMAIL_INVOICE_PDF_FAILED': {
      return {
        ...state,
        fetchingForEmail: false
      };
    }
    case 'MARK_AS_INVOICE_CHARGED_REQUEST': {
      return {
        ...state,
        fetchingForMarkingInvoice: true
      };
    }
    case 'MARK_AS_INVOICE_CHARGED_FAILED':
    case 'MARK_AS_INVOICE_CHARGED_SUCCESS': {
      return {
        ...state,
        fetchingForMarkingInvoice: false
      };
    }
    case 'CONFIRM_CREDIT_PAYMENT_COMBINED_ORDER_REQUEST':
    case 'CONFIRM_PAYMENT_COMBINED_ORDER_REQUEST': {
      return {
        ...state,
        fetchingForCharging: true
      };
    }
    case 'CONFIRM_CREDIT_PAYMENT_COMBINED_ORDER_SUCCESS':
    case 'CONFIRM_CREDIT_PAYMENT_COMBINED_ORDER_FAILED':
    case 'CONFIRM_PAYMENT_COMBINED_ORDER_SUCCESS':
    case 'CONFIRM_PAYMENT_COMBINED_ORDER_FAILED': {
      return {
        ...state,
        fetchingForCharging: false
      };
    }
    case 'ADD_NEW_CARD_REQUEST':
    case 'CARD_ACCESS_TOKEN_REQUEST': {
      return {
        ...state,
        fetchingForNewCard: true
      };
    }
    case 'CARD_ACCESS_TOKEN_SUCCESS': {
      return {
        ...state,
        ...action.payload
      }
    }
    case 'ADD_NEW_CARD_FAILED':
    case 'ADD_NEW_CARD_SUCCESS':
    case 'CARD_ACCESS_TOKEN_FAILED': {
      return {
        ...state,
        fetchingForNewCard: false
      };
    }
    case 'ATTACH_MULTIPLE_POS_REQUEST': {
      return {
        ...state,
        fetchingForAttachingPo: true
      };
    }
    case 'ATTACH_MULTIPLE_POS_SUCCESS':
    case 'ATTACH_MULTIPLE_POS_FAILED': {
      return {
        ...state,
        fetchingForAttachingPo: false
      };
    }
    case 'GET_STATS_BY_ORDER_ID_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_STATS_BY_ORDER_ID_SUCCESS': {
      const { records, total_records, completed_pos_count } = action.payload;
      return {
        ...state,
        fetching: false,
        pendingPoStats: records,
        total_records,
        completed_pos_count
      };
    }
    case 'GET_REAPPROVED_INVOICES_COUNT_SUCCCESS': {
      return {
        ...state,
        ...action.payload
      }
    }
    case 'GET_STATS_BY_ORDER_ID_SUCCESS_WITHOUT_SAVE': {
      return {
        ...state,
        fetching: false
      }
    }
    case 'GET_STATS_BY_ORDER_ID_FAILED': {
      return {
        ...state,
        fetching: false,
        pendingPoStats: []
      };
    }
    case 'GET_USERS_COMBINED_ORDERS_REQUEST': {
      return {
        ...state,
        fetchingUsers: true
      };
    }
    case 'UPDATE_DISTRO_COST_REQUEST':
    case 'GET_COMBINED_ORDERS_PENDING_PO_INDICES_REQUEST': {
      return {
        ...state,
        fetchingPendingPoIndices: true
      };
    }
    case 'GET_COMBINED_ORDERS_PENDING_PO_INDICES_SUCCESS':
    case 'UPDATE_DISTRO_COST_SUCCESS':
    case 'UPDATE_DISTRO_COST_FAILED':
    case 'GET_COMBINED_ORDERS_PENDING_PO_INDICES_FAILED': {
      return {
        ...state,
        fetchingPendingPoIndices: false
      };
    }
    case 'GET_USERS_COMBINED_ORDERS_FAILED': {
      return {
        ...state,
        fetchingUsers: false
      };
    }
    case 'GET_USERS_COMBINED_ORDERS_SUCCESS': {
      const { users } = action.payload;
      return {
        ...state,
        fetchingUsers: false,
        users: users || []
      };
    }
    case 'SAVE_VENDOR_FILE_REQUEST':
    case 'UPLOAD_VENDOR_FILE_REQUEST':
    case 'DELETE_PURHCASE_ORDER_SUBGRID_REQUESTS':
    case 'EXPORT_DATA_AS_XLSX_REQUEST':
    case 'GET_COMBINED_ORDERS_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_COMBINED_ORDERS_SUCCESS': {
      const { records, total_records } = action.payload;
      return {
        ...state,
        fetching: false,
        combinedOrders: records,
        total_records
      };
    }
    case 'DELETE_PURHCASE_ORDER_SUBGRID_SUCCESS': {
      const { updatedPendingPoStats } = action.payload;
      return {
        ...state,
        pendingPoStats: updatedPendingPoStats,
        fetching: false
      };
    }
    case 'SAVE_VENDOR_FILE_FAILED':
    case 'SAVE_VENDOR_FILE_SUCCESS':
    case 'UPLOAD_VENDOR_FILE_FAILED':
    case 'UPLOAD_VENDOR_FILE_SUCCESS':
    case 'DELETE_PURHCASE_ORDER_SUBGRID_FAILED':
    case 'EXPORT_DATA_AS_XLSX_SUCCESS':
    case 'EXPORT_DATA_AS_XLSX_FAILED':
    case 'GET_COMBINED_ORDERS_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_FILTERS_FOR_COMBINED_ORDERS': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'SET_PAGINATION_COMBINED_ORDERS': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    case 'ADD_ITEMS_TO_WORK_ORDER_REQUEST': {
      return {
        ...state,
        addingAddToWorkOrder: true
      }
    }
    case 'ADD_ITEMS_TO_WORK_ORDER_SUCCESS':
    case 'ADD_ITEMS_TO_WORK_ORDER_FAILED': {
      return {
        ...state,
        addingAddToWorkOrder: false
      }
    }
    case 'GET_PENDING_POS_REQUEST':
    case 'UPDATE_VENDOR_SKU_REQUEST': {
      return {
        ...state,
        fetchingPendingPosDrawerData: true,
        fetching: true
      }
    }
    case 'GET_PENDING_POS_SUCCESS':
    case 'GET_PENDING_POS_FAILED':
    case 'UPDATE_VENDOR_SKU_SUCCESS':
    case 'UPDATE_VENDOR_SKU_FAILED': {
      return {
        ...state,
        fetchingPendingPosDrawerData: false,
        fetching: false,
        ...action.payload
      }
    }
    case 'GET_PAYMENT_HISTORY_REQUEST': {
      return {
        ...state,
        fetchingForPaymentHistory: true
      }
    }
    case 'GET_PAYMENT_HISTORY_SUCCESS':
    case 'GET_PAYMENT_HISTORY_FAILED': {
      return {
        ...state,
        ...action.payload,
        fetchingForPaymentHistory: false
      }
    }

    case 'COMBINED_ORDERS_FETCH_USERS_LIST_REQUEST': {
      return {
        ...state,
        fetchingUsersList: true
      }
    }
    case 'COMBINED_ORDERS_FETCH_USERS_LIST_SUCCESS': {
      return {
        ...state,
        fetchingUsersList: false,
        ...action.payload
      }
    }
    case 'COMBINED_ORDERS_FETCH_USERS_LIST_FAILED': {
      return {
        ...state,
        fetchingUsersList: false
      }
    }
    case 'SET_FILTERS_FOR_DISTRO_PAGE': {
      return {
        ...state,
        ...action.payload
      }
    }

    case 'GET_PAYMENT_STATUS_REQUEST': {
      return {
        ...state,
        fetchingPaymentStatus: true
      }
    }
    case 'GET_PAYMENT_STATUS_SUCCESS':
    case 'GET_PAYMENT_STATUS_FAILED': {
      return {
        ...state,
        fetchingPaymentStatus: false
      }
    }

    case 'GET_DISTRIBUTION_DETAILS_REQUEST': {
      return {
        ...state,
        fetchingDistributionDetails: true
      }
    }
    case 'GET_DISTRIBUTION_DETAILS_SUCCESS':
    case 'GET_DISTRIBUTION_DETAILS_FAILED': {
      return {
        ...state,
        fetchingDistributionDetails: false
      }
    }

    case 'GET_CREDIT_DATA_LIST_REQUEST': {
      return {
        ...state,
        fetchingDistroDataListForCreditAmount: true
      }
    }
    case 'GET_CREDIT_DATA_LIST_SUCCESS':
    case 'GET_CREDIT_DATA_LIST_FAILED': {
      return {
        ...state,
        fetchingDistroDataListForCreditAmount: false
      }
    }

    case 'SYNC_DISTRO_COST_REQUEST': {
      return {
        ...state,
        syncingDistroCost: true
      }
    }
    case 'SYNC_DISTRO_COST_SUCCESS':
    case 'SYNC_DISTRO_COST_FAILED': {
      return {
        ...state,
        syncingDistroCost: false
      }
    }

    case 'UPDATE_DISTRO_PO_COMPLETE_STATUS_REQUEST': {
      return {
        ...state,
        updatingDistroPoCompleteStatus: true
      }
    }
    case 'UPDATE_DISTRO_PO_COMPLETE_STATUS_SUCCESS': {
      const { pending_po_index_id, marked_as_hide } = action.payload || {};

      return {
        ...state,
        updatingDistroPoCompleteStatus: false,
        pendingPoStats: filter(state.pendingPoStats, (obj) => obj.pending_po_index_id !== pending_po_index_id) || [],
        total_records: state.total_records - 1,
        completed_pos_count: marked_as_hide ? state.completed_pos_count + 1 : state.completed_pos_count - 1
      }
    }
    case 'UPDATE_DISTRO_PO_COMPLETE_STATUS_FAILED': {
      return {
        ...state,
        updatingDistroPoCompleteStatus: false
      }
    }

    default:
      return state;
  }
};

export default combinedOrders;
