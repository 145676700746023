import moment from 'moment';

import { cloneDeep } from "lodash";
const initialState = {
  fetching: false,
  fetchingUsers: false,
  affiliate_manager_id: null,
  selectedUser: null,
  currentRegenerateInvoiceType: '',
  invoices: [],
  total_records: 0,
  totalInvoices: 0,
  filters: [{
    field: 'month',
    value: moment().format('MMMM')
  }, {
    field: 'year',
    value: moment().format('YYYY')
  }],
  isRegenerateInvoice: false,
  sortOptions: {},
  pagination: {
    pageNumber: 1,
    pageSize: 25
  },
  regenertateInvoices: {
    fetching: false
  }
};

const affiliateInvoices = (state = cloneDeep(initialState), action) => {
  switch (action.type) {
    case 'SET_PAGINATION_FOR_AFFILIATE_INVOICES': {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload
        }
      };
    }
    case 'SET_FILTERS_AFFILIATE_INVOICES':
    case 'SET_REGENERATE_INVOICE_TYPE': {
      return {
        ...state,
        ...action.payload
      };
    }
    case 'GET_AFFILIATE_INVOICES_REQUEST': {
      return {
        ...state,
        fetching: true
      };
    }
    case 'GET_AFFILIATE_INVOICES_SUCCESS': {
      const { invoices, totalInvoices, isRegenerateInvoice } = action.payload;
      return {
        ...state,
        fetching: false,
        invoices,
        totalInvoices,
        isRegenerateInvoice
      };
    }
    case 'GET_AFFILIATE_INVOICES_FAILED': {
      return {
        ...state,
        fetching: false
      };
    }
    case 'SET_AFFILIATE_ID_FOR_AFFILIATE_INVOICES': {
      return {
        ...state,
        ...action.payload,
        fetching: false
      };
    }
    case 'SET_SELECTED_USER_FOR_AFFILIATE_INVOICES': {
      return {
        ...state,
        selectedUser: action.payload
      };
    }
    case 'GET_USERS_FOR_AFFILIATE_INVOICES_REQUEST': {
      return {
        ...state,
        fetching: true,
        fetchingUsers: true
      };
    }
    case 'GET_USERS_FOR_AFFILIATE_INVOICES_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        fetching: false,
        fetchingUsers: false
      };
    }
    case 'GET_USERS_FOR_AFFILIATE_INVOICES_FAILED': {
      return {
        ...state,
        fetching: false,
        fetchingUsers: false
      };
    }
    case 'REGENERATE_PREVIOUS_MONTH_AFFILIATE_INVOICES_REQUEST': {
      return {
        ...state,
        regenertateInvoices: {
          ...state.regenertateInvoices,
          fetching: true
        }
      };
    }
    case 'REGENERATE_PREVIOUS_MONTH_AFFILIATE_INVOICES_FAILED':
    case 'REGENERATE_PREVIOUS_MONTH_AFFILIATE_INVOICES_SUCCESS': {
      return {
        ...state,
        ...action.payload,
        regenertateInvoices: {
          ...state.regenertateInvoices,
          fetching: false
        }
      };
    }
    default:
      return state;
  }
};

export default affiliateInvoices;
