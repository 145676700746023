import React, { useState, useEffect, useRef } from "react";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { round, debounce, ceil } from "lodash";
import { WarehouseWrapper } from "./style";
import AgGrid from "../../components/ag-grid-table/index";
import { BsShopWindow } from "react-icons/bs";
import Pagination from "../../components/pagination/index";
import SearchInput from "../../components/uiComponent/inputs/searchinput/index";
import {
    GetLocationHistories
} from "../../slices/internalStorage";
import Product from "../../assets/images/newImages/noimage.png";
const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    searchValue: '',
    pagination: {
      pageNumber: 1,
      pageLimit: 25
    }
  });

  const {
    storageHistories,
    locationHistoryLoading,
    historyCount
  } = useSelector((state) => state?.internalStorage || {});

  const { pagination, searchValue } = filters;
  const { pageNumber, pageLimit} = pagination;


  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const locationId = params.get('id');
    if (locationId) {
      dispatch(GetLocationHistories({ ...filters, locationId }))
      setFilters({
        ...filters,
        locationId
      });
    }
    else navigate("/warehouse-location")
  }, []);

  const refreshGridClick = () => {
    dispatch(GetLocationHistories(filters))
  }

  const columnDefinitions = [
    {
      headerName: "Name",
      field: "storage_name",
      width: 300,
      cellRenderer: ({ value }) => (value ? value : "N/A"),
    },
    {
      headerName: "Warehouse",
      field: "warehouse_user",
      width: 300,
      sortable: false,
      cellRenderer: ({ value }) => (value ? value : "N/A"),
    },
    {
      headerName: "User",
      field: "action_user",
      width: 300,
      sortable: false,
      cellRenderer: ({ value }) => (value ? value : "N/A"),
    },
    {
      headerName: "Actions",
      field: 'action',
      width: 400,
      cellRenderer: ({ value }) =>  (value ? value : "N/A")
    },
    {
      headerName: "Crreated At",
      field: 'created_at',
      width: 300,
      sortable: false,
      cellRenderer: ({ value }) => (value ? value : "N/A"),
    },
  ];
  const defaultColDef = {
    resizable: true,
    sortable: true,
  };

  const handlePagination = (number, key) => {
    const value = Number(number);
    let filter = { ...filters }
    if (key === 'pageLimit') filter = { ...filters, pagination: { pageNumber: 1, pageLimit: value }};
    else filter = { ...filters, pagination: { ...pagination, pageNumber: value }};
    setFilters(filter);
    dispatch(GetLocationHistories(filter));
  }

  const handleSearchValue = debounce((event) => {
    const value = event.target.value;
    const filter = { ...filters, pagination:{ ...pagination, pageNumber: 1 }, searchValue: value };
    setFilters(filter);
    dispatch(GetLocationHistories(filter));
  }, 1000)

  return (
    <WarehouseWrapper>
      <Spin size='large' tip="Processing..." spinning={locationHistoryLoading}>
      <div className="d-flex justify-content-between">
        <div className="d-flex gap-3 align-items-center">
          <span
            className="icon-arrow-left cursor-pointer"
            onClick={() => navigate("/warehouse-location")}
          ></span>
          <h2 className="color-secondary mb-0">Location Histories</h2>
        </div>
        <SearchInput defaultValue={searchValue} onChange={handleSearchValue} placeholder="Search for Name" />
      </div>
      <div>
          <AgGrid
            user_referrals={storageHistories || []}
            defaultColDef={defaultColDef}
            columnDefinitions={columnDefinitions}
            // onSortChange={onSortChange}
            rowHeight={55}
            height="165px"
          />
          
      </div>
      <Pagination
        total={historyCount}
        totalPages={ceil(historyCount / pageLimit + 1)}
        pageNumber={pageNumber}
        pageLimit={pageLimit}
        handleLimitChange={(e) => handlePagination(e, 'pageLimit')}
        handlePageNumberChange={(e) => handlePagination(e, 'pageNumber')}
        handlePageChangeLeft={(e) => handlePagination(e, 'pageNumber')}
        handlePageChangeRight={(e) => handlePagination(e, 'pageNumber')}
        refreshGridClick={refreshGridClick}
      />
      </Spin>
    </WarehouseWrapper>
  );
};

export default Index;