import React, { useState, useEffect } from 'react';

import { Box } from "@mui/material";
import { BsArrowRight } from "react-icons/bs";
import moment from 'moment';

import Warning from '../../assets/images/newImages/trail-warning.svg'
import Button from '../../components/uiComponent/button';

const Index = ({ trialEndDate, setShowTrialPopup, onClick }) => {
  const [remainingDays, setRemainingDays] = useState();

  useEffect(() => {
    if (trialEndDate) setRemainingDays(moment(trialEndDate)?.diff(moment(), 'days'));
  }, [trialEndDate]);
  return (
  <Box className="trail-popover" bgcolor="#0B2447" padding="18px 12px" borderRadius="16px">
    <Box display="flex" gap={2} alignItems="center">
    <Box display="flex" gap={1}>
      <img src={Warning} alt="no-icon" />
      <Box display="flex" flexDirection="column">
        <Box component="span" color="#F0E996" fontSize="16px">
          {remainingDays > 0 ? `You have ${remainingDays} days remaining in your trial. `: 'Your trial period has ended!'}
          </Box>
          <Box component="span" color="#F0E996" fontSize="16px">
          {' Add card details to keep Repricer working!'}
          </Box>
        </Box>
      </Box>
      <Box
        style={remainingDays > 0 ? { cursor: 'pointer' } : { opacity: '0.5' }}
      onClick={() => {
        if (remainingDays > 0) setShowTrialPopup(false)
      }}
      color="#fff"
      fontSize="16px"
      >
       Close
      </Box>
      <Button
      onClick={onClick}
      width="173px"
      height="48px"
      marginBottom="0px"
      endIcon={<BsArrowRight />}
      text="Add Card Details"  
    />
      </Box>
  </Box>
  )
}

export default Index
