import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap'
import Header from '../../header/header'
import { StoreWrapper } from '../style'
import Amazon from '../../../assets/images/newImages/amazon_logo.png'
import Walmart from '../../../assets/images/newImages/walmart_logo.png'
import Shopify from '../../../assets/images/newImages/shopify-logo.png'
import { useNavigate } from 'react-router-dom'
import { WarehouseStatusUpdate, clearStore } from '../../../slices/store';
import { GetCurrentUser } from '../../../slices/auth';
import { Spin, Tooltip } from 'antd';
import { find, isEmpty } from 'lodash';
import { GetAllStores } from '../../../slices/account';

const SelectStore = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { warehouseLoading, getStoreLoading } = useSelector(state => state?.store || {});
  const { storesHash: { fetchingStores } } = useSelector(state => state?.account || {});

  const [disableAmazon, setDisableAmazon] = useState(false);
  const [disableWalmart, setDisableWalmart] = useState(false);
  const [disableShopify, setDisableShopify] = useState(false);
  
  useEffect(() => {
    dispatch(GetAllStores()).then(({ payload }) => {
      const { data } = payload || {};
      const { stores } = data || {};
      setDisableAmazon(!(isEmpty(find(stores, { platform_type: 'Amazon' }))));
      setDisableWalmart(!(isEmpty(find(stores, { platform_type: 'Walmart' }))));
      setDisableShopify(!(isEmpty(find(stores, { platform_type: 'Shopify' }))));
    });
  }, []);

  const handleSkipWarehouse = async () => {
    const response = await dispatch(WarehouseStatusUpdate({}));
    if(!response.error) await dispatch(GetCurrentUser({}));
  };

  const getStoresAndNavigate = async (platformType) => {
    dispatch(clearStore());
    if (platformType === 'Amazon') navigate(`/onboarding/region-add`);
    else if (platformType === 'Walmart') navigate("/onboarding/walmart-market-place");
    else navigate(`/onboarding/add-shopify-store`);
  };

  const loading = warehouseLoading || getStoreLoading || fetchingStores;

  return (
    <StoreWrapper>
      <Header login={true} />
      <div className='change-background'>
      <div className="add-store">
        <h1>Add Store</h1>
        <Spin tip='Please wait...' spinning={loading}>
        <Row className="align-items-center justify-content-center s-mp">
          <Col lg={10} md={12} sm={12}>
            <div className="text-center">
              <h2>Select Market Place</h2>
              <Row>
                <Col md={4} sm={12}>
                  <Tooltip title={disableAmazon ? 'Amazon Store Already Added' : ''}>
                    <div className={`add_account ${disableAmazon ? 'make_disable' : ''}`}
                      onClick={() => getStoresAndNavigate('Amazon')} disabled={disableAmazon}>
                      <img src={Amazon} alt="no-marketplace" />
                      <div className="add_account_div">
                        <h3>Add Account</h3>
                      </div>
                    </div>
                  </Tooltip>
                </Col>
                <Col md={4} sm={12}>
                  <Tooltip title={disableWalmart ? 'Walmart Store Already Added' : ''}>
                    <div className={`add_account ${disableWalmart ? 'make_disable' : ''}`}
                      onClick={() => getStoresAndNavigate('Walmart')} disabled={disableWalmart}>
                      <img src={Walmart} alt="no-marketplace" />
                      <div className="add_account_div">
                        <h3>Add Account</h3>
                      </div>
                    </div>
                  </Tooltip>
                </Col>
                <Col md={4} sm={12}>
                  <Tooltip title={disableShopify ? 'Shopify Store Already Added' : ''}>
                    <div className={`add_account ${disableShopify ? 'make_disable' : ''}`}
                      onClick={() => getStoresAndNavigate('Shopify')} disabled={disableShopify}>
                      <img src={Shopify} alt="no-marketplace" width="232px" height="82px" />
                      <div className="add_account_div">
                        <h3>Add Account</h3>
                      </div>
                    </div>
                  </Tooltip>
                </Col>
              </Row>
              {/* <div className="divider">
                <span className="inner-text">OR</span>
              </div>
              <div className="mt-40 text-center" >
                <span 
                  className="color-primary fw-bold cursor-pointer" 
                  onClick={handleSkipWarehouse}
                > Skip to  Warehouse Setup</span>
              </div> */}
              {(disableAmazon || disableWalmart) ?
                <>
                  <div className="divider">
                    <span className="inner-text">OR</span>
                  </div>
                  <div className="mt-40 text-center" >
                    <span
                      className="color-primary fw-bold cursor-pointer"
                      onClick={() => localStorage.getItem('back-to-stores') ? navigate("/account?section=stores") : navigate("/onboarding/select-plan")}
                    >SKIP</span>
                  </div>
                </>
                : null
              }
            </div>
          </Col>
        </Row>
      </Spin>
      </div>
      </div>
    </StoreWrapper>
  )
}

export default SelectStore
