import React from "react";
import { DrawerWrapper } from "./style";
import "react-modern-drawer/dist/index.css";
const Index = (props) => {
  const { show, size, onHide, children, crossIcon, style } = props;

  return (
    <DrawerWrapper style={style} open={show} size={size} direction="right">
      {crossIcon && <span className="icon-cross-circle cross-icon cursor-pointer" onClick={onHide}></span>}
      {children}
    </DrawerWrapper>
  );
};
export default Index;
