import JsPDF from 'jspdf';
import JsBarcode from 'jsbarcode';
import generateBarcode from 'pdf417';
import { chunk } from 'lodash';

const instructionsForLocationLabel = (pdfDoc, barcode, warehouseName, locationName) => {
  let y = 13;
  let x = 0;


  pdfDoc.setFontSize(7);
  pdfDoc.setFontStyle('bold');
  pdfDoc.text('Storage Label', 55, y);

  y += 12;
  pdfDoc.setFontSize(7);
  pdfDoc.setFontStyle('bold');
  pdfDoc.text('Location: ', 13, y);
  pdfDoc.setFontStyle('normal');
  pdfDoc.text(`${locationName}.`, 46, y);

  y += 6
  pdfDoc.setFontSize(9);
  pdfDoc.addImage(barcode, 'PNG', 15, y, 126, 45);

  y += 53;
  pdfDoc.setFontSize(7);
  pdfDoc.setFontStyle('normal');
  pdfDoc.text(`${warehouseName}.`, 37, y);
}

const instructionsForProductLabel = (pdfDoc, barcode, warehouseName, title, identifier, expiryDate) => {
  let y = 13;
  let x = 0;

  pdfDoc.setFontSize(7);
  pdfDoc.setFontStyle('bold');
  pdfDoc.text('Storage Item Label', 50, y);
  pdfDoc.setFontSize(6);
  y += 9;
  pdfDoc.setFontStyle('normal');
  const titleWithIdentifier = `${identifier} - ${title}`
  const firstLineForTitles = titleWithIdentifier.substr(0, 45);
  const secondLineForTitles = titleWithIdentifier.substr(45, 45);
  pdfDoc.text(`${firstLineForTitles}`, 9, y);
  if (secondLineForTitles) {
    y += 7;
    pdfDoc.text(`${secondLineForTitles}${(firstLineForTitles.length + secondLineForTitles.length < titleWithIdentifier.length) ? '...' : ''}`, 9, y);
  }

  y += 6;

  pdfDoc.setFontSize(9);
  pdfDoc.addImage(barcode, 'PNG', 15, y, 126, 40);


  y += 49;
  pdfDoc.setFontSize(7);
  pdfDoc.text(warehouseName, 10, y);
  if (expiryDate) {
    pdfDoc.text('EXP:', 70, y);
    pdfDoc.text(expiryDate, 90, y);
  }
}

const genrateFNSkuBarCode = (uuid) => {
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, uuid, {
    // format: 'CODE128A',
    text: uuid,
    font: 'sans-serif',
    fontSize: 40,
    textAlign: 'center',
    width: 4,
    margin: 1,
    displayValue: false
  });
  return canvas.toDataURL('image/png');
};

const generateLocationBarcode = (uuid) => {
  return generateBarcode(uuid, 15, 15);
}

export const generateStorageBarcode = ({ 
  warehouseName, 
  uuid, 
  title,
  stored_quantity: quantity,
  identifier,
  labelType,
  locationName,
  expiryDate
}) => {
  let pdfDoc = new JsPDF('landscape', 'pt', [162, 90]);
  const barcode = genrateFNSkuBarCode(uuid);
  let fileName = '';
  if (labelType === 'product') {
    fileName =  identifier
    for (let count = 1; count <= quantity; count ++ ) {
      instructionsForProductLabel(pdfDoc, barcode, warehouseName, title, identifier, expiryDate); 
      if (count + 1 <= quantity) pdfDoc.addPage([162, 90], 'landscape');
    }
  } else if (labelType === 'location') {
    fileName = locationName || 'StorageLocation'
    instructionsForLocationLabel(pdfDoc, barcode, warehouseName, locationName);
  }

  pdfDoc.save(`${fileName}.pdf`);
}

const formatpdf = ({ pdfDoc, locations }) => {
  let x = 40;
  let y = 30;
  let circle = 1;

  const numberOfLabels = locations.length || 0;

  for (let index = 0; index < numberOfLabels; index++) {
    const { warehouse_user: warehouseName, name: locationName, uuid } = locations[index] || {};
    const barcode = genrateFNSkuBarCode(uuid);

    if (index > 9 && index < 19 && circle == 1) {
      x += 200;
      y = 30
      circle = 2;
    } else if (index >= 20 && index < 29 && circle == 2) {
      x += 200;
      y = 30
      circle = 3
    }

    pdfDoc.setFontSize(7);
    pdfDoc.setFontStyle('bold');
    pdfDoc.text('Location: ', x, y);
    pdfDoc.setFontStyle('normal');
    pdfDoc.text(`${locationName}.`, x + 30, y);

    pdfDoc.setFontSize(9);
    pdfDoc.addImage(barcode, 'PNG', x, y + 3, 126, 40);
    y += 50;
    pdfDoc.setFontSize(7);
    pdfDoc.setFontStyle('normal');
    pdfDoc.text(`${warehouseName}.`, x + 10, y);
    y += 18;
  }
  return pdfDoc;
};

export const generate30UpLabels = ({
  locations
}) => {

  let pdfDoc = new JsPDF('p', 'pt', 'letter');
  pdfDoc.setFont('times', 'normal');
  
  const locationChunks = chunk(locations, 30);
  const noOfChunks = locationChunks.length || 0;

  for (let index = 0; index < noOfChunks; index++) {      
    pdfDoc = formatpdf({ pdfDoc, locations: locationChunks[index] });
    if (index !== (noOfChunks - 1)) pdfDoc.addPage('letter', 'p');
  }

  pdfDoc.save('Bulk-Storage-Locations-30Up-Labels.pdf');
};

export const generateZebraLabels = ({
  locations
}) => {

  let pdfDoc = new JsPDF('landscape', 'pt', [162, 90]);
  const noOfLocations = locations?.length || 0;
  for (let index = 0; index < noOfLocations; index++) {
    const { warehouse_user: warehouseName, name: locationName, uuid } = locations[index] || {};
    const barcode = genrateFNSkuBarCode(uuid);
    instructionsForLocationLabel(pdfDoc, barcode, warehouseName, locationName);
    if (index !== (locations.length - 1)) pdfDoc.addPage([162, 90], 'landscape');
  }

  pdfDoc.save('Bulk-Storage-Locations-Zebra-Labels.pdf');
};
