import JsPDF from 'jspdf';
import JsBarcode from 'jsbarcode';

const genrateFNSkuBarCode = (sku) => {
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, sku, {
    format: 'CODE128A',
    text: sku,
    font: 'sans-serif',
    fontSize: 40,
    textAlign: 'center',
    width: 4,
    margin: 1,
    displayValue: false
  });
  return canvas.toDataURL('image/png');
};


const formatpdf = ({ pdfDoc, barcode, fnsku, packageQuantity, condition, expiryDate, title, username, prepInstruction, numberOfLabels, pageNumber, bundleQty }) => {
  let x = 40;
  let y = 30;
  let circle = 1;

  for (let i = 0; i < numberOfLabels; i++) {
    if (i > 9 && i < 19 && circle == 1) {
      x += 200;
      y = 40
      circle = 2;
    } else if (i >= 20 && i < 29 && circle == 2) {
      x += 200;
      y = 40
      circle = 3
    }

    if (i == 0 && (pageNumber && pageNumber - 1 == 0)) {
      pdfDoc = instructionLabel(pdfDoc, username, prepInstruction, packageQuantity, title, x, y, bundleQty);
      y += 78
    }
    // else {
    pdfDoc.setFontSize(8);
    if (i == 0 && (pageNumber && pageNumber - 1 != 0)) {
      y += 10
    }
    pdfDoc.addImage(barcode, 'PNG', x, y, 142, 40);
    pdfDoc.setFontSize(6.8);
    y += 50;
    pdfDoc.text(`${fnsku}, Pack of ${packageQuantity}, ${condition ? condition : 'New'}, EXP. ${expiryDate}`, x, y);
    const firstLineForTitles = title.substring(0, 42);
    const secondLineForTitles = title.substring(42, 84);
    y += 8;
    pdfDoc.text(firstLineForTitles, x, y);
    if (secondLineForTitles) {
      y += 8;
      pdfDoc.text(`${secondLineForTitles} ${(firstLineForTitles.length + secondLineForTitles.length < title.length) ? '...' : ''}`, x, y);
    }
    y += 10;
    // }
  }
  return pdfDoc;
};

const instructionLabel = (pdfDoc, username, prepInstruction, packageQuantity, title, xaxis, yaxis, bundleQty) => {

  let y = yaxis;
  const centerX = 81;
  const x = xaxis;

  let titleLines = pdfDoc.splitTextToSize(title, 250);

  titleLines = titleLines.slice(0, 2).join('')

  pdfDoc.setFont('helvetica', 'normal');

  y += 15;
  pdfDoc.setFontSize(12);
  pdfDoc.setFontStyle('bold');
  pdfDoc.text(username, centerX + 10, y, 'center')

  const instructions = pdfDoc.splitTextToSize(`Prep. Instructions: ${prepInstruction ? prepInstruction.toString() : 'N/A'}`, 170);
  y += 10;
  pdfDoc.setFontSize(8);
  pdfDoc.setFontStyle('bold');
  pdfDoc.text(instructions, x, y);
  y += 5;

  const product = pdfDoc.splitTextToSize(`Pack of ${packageQuantity}, ${titleLines}`, 170);
  y += 15;
  pdfDoc.setFontSize(7);
  pdfDoc.setFontStyle('normal');
  pdfDoc.text(product, x, y);

  y += 24;
  pdfDoc.setFontSize(8);
  pdfDoc.setFontStyle('bold');
  pdfDoc.text(`Total Bundles: ${bundleQty}`, x, y)

  pdfDoc.setFontSize(7);
  pdfDoc.setFontStyle('normal');

  return pdfDoc;
};

const thirtyUpLabels = ({
  fnsku,
  title,
  condition,
  packageQuantity,
  expiryDate,
  numberOfLabels,
  bundleQty,
  username,
  companyName,
  prepInstruction = []
}) => {

  let totalNumberOfLabels = Math.ceil(numberOfLabels);
  const labelsPerPage = 30;

  let numberOfLabelsPerPage = numberOfLabels;
  let pdfDoc = new JsPDF('p', 'pt', 'letter');
  let pageNumber = 1;

  const barcode = genrateFNSkuBarCode(fnsku);
  // pdfDoc.setFont('times', 'normal');

  if (numberOfLabels >= 30) {
    for (let i = 0; i < (Math.ceil(numberOfLabels / labelsPerPage) + 1); i++) {
      pdfDoc = formatpdf({ pdfDoc, barcode, fnsku, packageQuantity, condition, expiryDate, title, username, prepInstruction, numberOfLabels: (numberOfLabelsPerPage > 30 && numberOfLabelsPerPage > 0) ? labelsPerPage : numberOfLabelsPerPage, pageNumber, bundleQty });
      pageNumber++;
      numberOfLabelsPerPage = (i == 0 ? numberOfLabelsPerPage - 29 : numberOfLabelsPerPage - 30);
      if (numberOfLabelsPerPage > 0) pdfDoc.addPage('letter', 'p');
    }
  }
  else {
    pdfDoc = formatpdf({ pdfDoc, barcode, fnsku, packageQuantity, condition, expiryDate, title, username, prepInstruction, numberOfLabels: numberOfLabelsPerPage, pageNumber, bundleQty });

  };
  pdfDoc.save(`${fnsku}.pdf`);
}

export default thirtyUpLabels;
