import moment from 'moment';
import { isEmpty } from 'lodash';
import { AUTH_ROUTES } from '../routes/AuthRoute';
import { ONBOARDING_ROUTES } from '../routes/PostSignUpRoute';
import { PRIVATE_APP_ROUTES } from '../routes/AppRouteContainer';

const handleRouteValidations = (data, currentPath) => {
  const { user, settings } = data || {};

  const {
    amazonSettings,
    walmartSettings,
    shopifySettings
  } = settings || {};

  const {
    qb_subscription_id,
    qb_accounts,
    role, confirmed_at,
    stores_count,
    trial_start_at,
    email,
    walmart_consumer_key,
    walmart_secret_key,
    refresh_token,
    auto_ordering_auth_token,
    shopify_access_token,
    platform
  } = user || {};

  const isTrialEnded = moment(trial_start_at).add(30, 'days').isBefore(moment.now())

  const isOwner = user?.email == 'steven@wraithco.com';
  const isOwnerWorker = user?.warehouse_manager_id == 5;
  const isAdmin = (user?.role_id == 1 || user?.role_id == 3);
  const isWarehouseWorker = user?.warehouse_manager_id;
  const isWorker = user?.role_id == 5;
  const isNotSpecialUser = user?.email != "test@sales.support.com";
  const isAffiliatManager = user?.is_affiliate_manager;
  const specialUser = (role?.name === 'Warehouse' || isOwner || isAffiliatManager || isWarehouseWorker);
  const { is_public_warehouse, is_affiliate_manager } = user || {};
  const allowRemovalPages = is_public_warehouse || is_affiliate_manager;

  let message = 'You are not allowed to do this action';
  let reducers = ['catalogJobs', 'processedCatalogs'];
  let redirectTo = '/reprice/dashboard';

  const basicGroups = ['Affiliate', 'Catalog', 'Removals', 'Invoices', 'Settings', 'Distro', 'PO'];
  const basicPaths = ['/referal-users', '/vendors', '/account'];

  let group = [...AUTH_ROUTES, ...ONBOARDING_ROUTES, ...PRIVATE_APP_ROUTES]
    .find(({ path }) => path === currentPath)?.group;

  if (!isAdmin && user?.qb_subscription_id?.plan?.name === 'Basic' && (!basicGroups.includes(group) && !basicPaths.includes(currentPath))) {
    return {
      success: false,
      message: "You are currently on the 'Basic' plan which only allows access to Catalog Analyzer. Please upgrade to 'Professional Plan' to use this page.",
      redirectTo: '/payments',
      reducers: ['payment', 'affiliateAccountSettings']
    }
  }
  const isQbSourceExists = user.qb_accounts?.find((element) => (element?.name === 'dd_llc' && element?.source_id));

  if (!isWorker && !isAdmin && !isWarehouseWorker && !isAffiliatManager) {
    if (!(user.valid_subscription) && !isEmpty(user.qb_subscription_id) && (user.qb_subscription_id?.charge_status != "CAPTURED") && !isEmpty(isQbSourceExists) && currentPath !== '/payments' && group !== 'Invoices') {
      message = 'Your subscription payment is declined, please add a valid payment details (Credit Card)!';
      reducers = ['payment', 'affiliateAccountSettings']
      redirectTo = '/payments'
      return { success: false, message, redirectTo, reducers };
    }
    else if ((!(user.valid_subscription) || isEmpty(user.qb_subscription_id)) && isTrialEnded && currentPath !== '/payments' && group !== 'Invoices') {
      message = 'Seems like your subscription has expired! Please add valid payment details to re-subscribe and use our features!';
      reducers = ['payment', 'affiliateAccountSettings']
      redirectTo = '/payments'
      return { success: false, message, redirectTo, reducers };
    }
    // else if ((!(user.mws_key_valid) || !(user.amazon_store_status)) && currentPath !== '/account') {
    else if ((user.stores_count === 0) && currentPath !== '/account') {
      if (currentPath?.split('/')?.[1] !== 'fbm' && currentPath !== '/payments' && group !== 'Invoices') {
        // message = 'Please provide MWS credentials before proceeding to next steps.';
        message = 'No Store Connected, Please Add Store.';
        reducers = []
        redirectTo = '/account?section=stores'
        return { success: false, message, redirectTo, reducers };
      }
    }
  }

  if (currentPath === '/admin/user' && !isOwner) return { success: false, message, redirectTo };

  if (group === 'Affiliate') {
    message = 'This feature is not enabled for you. Please contact support through our chatbot to get the feature on.';
    if (!user.is_affiliate_manager && !isOwner) return { success: false, message, redirectTo };
    else if (currentPath === '/affiliate/account-settings' && !user.master_shipment_enabled) return { success: false, message, redirectTo };
  }

  if (currentPath === '/vendors' && (isWarehouseWorker || isWorker)) return { success: false, message, redirectTo };

  // if (currentPath === '/warehouse-vendors' && !isAdmin) return { success: false, message, redirectTo };

  if (['/po/all-orders', '/po/reconciliation', '/po/invoices'].includes(currentPath) && !isOwner) return { success: false, message, redirectTo };

  if (currentPath === '/orders-fulfillment' && (!user.is_owner_warehouse && user.email !== 'mikeakrel@gmail.com')) return { success: false, message, redirectTo };

  if (group === 'Distro' && (user.email != 'mikeakrel@gmail.com' && user.email != 'steven@dailydistro.com' && user?.email != "makapakashopify@gmail.com")) {
    message = 'This feature is not enabled for you. Please contact support through our chatbot to get the feature on.';
    return { success: false, message, redirectTo };
  }

  if (currentPath === '/sales' && (isWarehouseWorker || isWorker)) return { success: false, message, redirectTo };

  if (group === 'Repricer') {
    if (isWarehouseWorker || isWorker) return { success: false, message, redirectTo };
    if (isOwner && !currentPath.includes('dashboard')) return { success: false, message, redirectTo };
    if (!isOwner && ((user.is_listing_synced && !user?.repricer?.is_repricer_enabled) || !user.is_listing_synced) && !currentPath.includes('guide')) {
      if (user?.qb_subscription_id?.plan?.name !== 'Basic') {
        message = 'Please Set-up Repricer First';
        redirectTo = '/reprice/guide';
      }
      return { success: false, message, redirectTo };
    }
  }

  if (currentPath === '/3pl-payments' && !isOwner) return { success: false, message, redirectTo };

  // if (currentPath === '/incomming-pos' && isWarehouseWorker) return { success: false, message, redirectTo };

  if (group === 'Removals') {
    if (isWarehouseWorker || isWorker) return { success: false, message, redirectTo };
    if (['/fba-removal-shipments/search', '/received-removal-orders'].includes(currentPath) && !allowRemovalPages) return { success: false, message, redirectTo };
  }

  if (currentPath === '/users' && isWorker) return { success: false, message, redirectTo };
  if (currentPath === '/payments' && isWorker) return { success: false, message, redirectTo };
  if (["/fbm", "/fbm/orders", "/fbm/orders/fulfillment", "/fbm/wallet", "/fbm/lister"].includes(currentPath) && !auto_ordering_auth_token) {
    message = 'FBM not enabled on account';
    redirectTo = '/fbm/settings'
    return { success: false, message, redirectTo };
  }
  if (["/fbm", "/fbm/orders", "/fbm/orders/fulfillment", "/fbm/wallet", "/fbm/lister"].includes(currentPath)
    && !((refresh_token || (walmart_consumer_key && walmart_secret_key) || shopify_access_token)
      && ((isEmpty(amazonSettings) || amazonSettings?.enable_marketplace)
        || (isEmpty(walmartSettings) || walmartSettings?.enable_marketplace)
        || (isEmpty(shopifySettings) || shopifySettings?.enable_marketplace)))) {
    message = 'FBM not enabled on account';
    redirectTo = '/fbm/settings'
    return { success: false, message, redirectTo };
  }
  if (group === 'Invoices') {
    if (isWarehouseWorker || isWorker) return { success: false, message, redirectTo };
    // else if (currentPath === '/invoices' && !isAdmin) return { success: false, message, redirectTo };
    else if (currentPath === '/invoices' && (!isAdmin && !user.master_shipment_enabled)) return { success: false, message, redirectTo };
    // else if (currentPath === '/user-invoices' && !isNotSpecialUser) return { success: false, message, redirectTo };
    else if (currentPath === '/master-removal-invoices' && !isAdmin) return { success: false, message, redirectTo };
    // else if (currentPath === '/removal-invoices' && !isNotSpecialUser) return { success: false, message, redirectTo };
    else if (currentPath === '/two-step-master-invoices' && !isAdmin) return { success: false, message, redirectTo };
    // else if (currentPath === '/two-step-invoices' && !isNotSpecialUser) return { success: false, message, redirectTo };
    else if (currentPath === '/master-storage-invoices' && !user.is_warehouse_owner_admin) return { success: false, message, redirectTo };
    // else if (currentPath === '/storage-invoices' && !isNotSpecialUser) return { success: false, message, redirectTo };
    else if (currentPath === '/invoices/settings' && !isAdmin) return { success: false, message, redirectTo };
  }

  if (group === 'MobileStorage' || group === 'Storage') {
    const { is_public_warehouse, is_affiliate_manager_worker, is_public_warehouse_worker, is_affiliate_manager } = user || {};
    const allowMobileScreens = is_public_warehouse || is_public_warehouse_worker || is_affiliate_manager || is_affiliate_manager_worker
    if (!allowMobileScreens) return { success: false, message, redirectTo };
  }

  return { success: true };
}

export default handleRouteValidations;
