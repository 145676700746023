import React from "react";
import { Link } from "react-router-dom"
import { useSelector } from 'react-redux';
import Header from "../../components/fbaHeader/index"
import { HomePageWrapper } from "./style";
import Screen from "../../assets/images/screen.png"
import HalfScreen from "../../assets/images/half-screen.png"
import CsvScreen from "../../assets/images/csv-screen.png"
import AddAmazon from "../../assets/images/add-amazon.png"
import SalesManagement from "../../assets/images/sales-management.svg"
import SupplierIcon from "../../assets/images/supplier-icon.svg"
import WholesaleAnalyzer from "../../assets/images/wholesale-analyzer.svg"
import WhosaleIcon from "../../assets/images/whosale-icon.svg"
import InventryManger from "../../assets/images/inventory-manager.svg"
import InventryIcon from "../../assets/images/inventory-icon.svg"
import WareHouseAutomation from "../../assets/images/warehouse-automation.svg"
import WareHouseIcon from "../../assets/images/warehouse-icon.svg"
import SalesProfit from "../../assets/images/sale-profit.svg"
import SalesProfitIcon from "../../assets/images/sale-profit-icon.svg"
import AutoMateFba from "../../assets/images/automate-fba.svg"
import AutoMateIcon from "../../assets/images/automate-icon.svg"
import RemovalScreen from "../../assets/images/removel-reimbursement.svg"
import RemovalIcon from "../../assets/images/removel-icon.svg"

const Index = () => {
  const { token } = useSelector((state) => state.auth || {});

  return (
    <HomePageWrapper>
      <Header />
      <div className="blue-back">
        <div className="amazon-screens hidden-xs">
          <div className="screen1">
            <img src={Screen} alt="no-index" />
          </div>
          <div className="screen2">
            <img src={HalfScreen} alt="no-index" />
          </div>
        </div>
        <div className="custom-container">
          <div className="business-amazon">
            <div className="row">
              <div className="col-md-12">
                <div className="homepage-video  text-center">
                  <iframe
                    className="Homepage-innerVideo"
                    src="https://player.vimeo.com/video/463695159?autoplay=1&loop=1&autopause=0"
                    frameborder="0"
                    scrolling="no"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="right-content">
                  <div className="title">Jumpstart your business on Amazon</div>
                  <div className="info">
                    Imagine having exactly what you need to start your Amazon
                    Wholesale business today
                  </div>
                  <Link to={token ? '/catalog-jobs' : '/auth/sign-up'}>
                    <button type="button" className="btn btn-wrapper">
                      Get started now
                    </button>
                  </Link>
                  <a className="free-call-link" target='_blank' href="https://calendly.com/fba-steve/">Book a FREE strategy call Today</a>
                  {/* <%= link_to "Book a FREE strategy call Today!", "https://calendly.com/fba-steve/", className: "free-call-link", target: "_blank" %> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="esential-tools">
        <div className="esential-amazon hidden-xs">
          <div className="screen1">
            <img src={CsvScreen} alt="no-screen" />

          </div>
          <div className="screen2">
            <img src={AddAmazon} alt="no-screen" />
          </div>
        </div>
        <div className="custom-container">
          <div className="business-section">
            <div className="essential-text">
              <h2>Essential tools to start your Amazon wholesale business</h2>
              <p>
                Starting an Amazon Wholesale business may seem like a daunting
                project. You may have some goals in mind but aren’t quite sure
                how to get there. With our Amazon Warehouse Kickstarter, you
                will get the essential tools you need to get your Amazon
                Wholesale Business off the ground.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="right-section">
        <div className="text-center">
          <h2>Sales.Support Dashboard</h2>
          <p>
            Check out the complete toolkit you get when you sign up for our
            program
          </p>
        </div>
        <div className="section-outer mt-20">
          <div className="support-screen f-right hidden-xs">
            <img src={SalesManagement} alt="no-screen" />
          </div>

          <div className="custom-container">
            <div className="text-section">
              <div className="support-text">
                <div className="heading-wrapper">
                  <div className="heading-icon">
                    <img src={SupplierIcon} alt="no-screen" />
                  </div>
                  <h2>Supplier Management</h2>
                </div>
                <p>
                  Manage your wholesale vendor list here in real-time to get a
                  bird’s eye view of the business activity. You can also manage
                  sales & products by suppliers to track down exactly where your
                  sales are coming from.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left-section">
        <div className="section-outer">
          <div className="support-screen f-left hidden-xs">
            <img src={WholesaleAnalyzer} alt="no-screen" />
          </div>
          <div className="custom-container">
            <div className="text-section">
              <div className="support-text">
                <div className="heading-wrapper">
                  <div className="heading-icon">
                    <img src={WhosaleIcon} alt="no-scrren" />
                  </div>
                  <h2>Wholesale List Analyzer</h2>
                </div>
                <p>
                  Using our aggregated data points, you can now analyze any
                  catalog you have to determine the profitability of a wide
                  range of products without having to do any research yourself.
                  Simply upload the catalog file you have from any distributor
                  account to instantly get information on which items are
                  profitable & which ones are not.You can now make sound
                  business decisions based on data analytics.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="right-section large-img">
        <div className="section-outer">
          <div className="support-screen f-right hidden-xs">
            <img src={InventryManger} alt="no-screen" />
          </div>

          <div className="custom-container">
            <div className="text-section">
              <div className="support-text">
                <div className="heading-wrapper">
                  <div className="heading-icon">
                    {/* <%= image_tag "inventory-icon.svg" %> */}
                    <img src={InventryIcon} alt="no-icon" />
                  </div>
                  <h2>Inventory Manager</h2>
                </div>
                <p>
                  Keep track of all of the inventory that your business is
                  handling. See your products’ inventory stock and sales in
                  real-time right here. If you see a product is selling well &
                  inventory is low, hit the re-order button right inside this
                  section of the dashboard.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left-section large-img">
        <div className="section-outer">
          <div className="support-screen f-left hidden-xs">
            {/* <%= image_tag "warehouse-automation.svg" %> */}
            <img src={WareHouseAutomation} alt="no-screen" />
          </div>
          <div className="custom-container">
            <div className="text-section">
              <div className="support-text">
                <div className="heading-wrapper">
                  <div className="heading-icon">
                    {/* <%= image_tag "warehouse-icon.svg" %> */}
                    <img src={WareHouseIcon} alt="no-screen" />
                  </div>
                  <h2>Warehouse Automation </h2>
                </div>
                <p>
                  Once you’ve determined what products you want to sell, you can
                  keep track of all orders here in this section of the
                  dashboard. Manage all of your fulfilled purchases & upcoming
                  orders all in one spot with this 3PL automation. Our 3PL
                  system also connects to the warehouse so that when you upload
                  a purchase order, it is automatically sent to the warehouse so
                  the order can be shipped.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="right-section large-img">
        <div className="section-outer">
          <div className="support-screen f-right hidden-xs">
            {/* <%= image_tag "sale-profit.svg" %> */}
            <img src={SalesProfit} alt="no-screen" />
          </div>

          <div className="custom-container">
            <div className="text-section">
              <div className="support-text">
                <div className="heading-wrapper">
                  <div className="heading-icon">
                    {/* <%= image_tag "sale-profit-icon.svg" %> */}
                    <img src={SalesProfitIcon} alt="no-screen" />
                  </div>
                  <h2>Sales & Profit Analysis</h2>
                </div>
                <p>
                  Beyond getting a summary of your sales, our dashboard also
                  breaks down the details for you so you can understand exactly
                  how much profit you are making. In this section, you will
                  obtain key information such as how much products cost you,
                  what you are paying in Amazon fees, and how much you are
                  spending on promotions, among other information.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="left-section large-img">
        <div className="section-outer">
          <div className="support-screen f-left hidden-xs">
            {/* <%= image_tag "automate-fba.svg" %> */}
            <img src={AutoMateFba} alt="no-screen" />
          </div>
          <div className="custom-container">
            <div className="text-section">
              <div className="support-text">
                <div className="heading-wrapper">
                  <div className="heading-icon">
                    {/* <%= image_tag "automate-icon.svg" %> */}
                    <img src={AutoMateIcon} alt="no-screen" />
                  </div>
                  <h2>Automate FBA Shipments into Amazon</h2>
                </div>
                <p>
                  Get a full overview of the inbound shipment of your goods to
                  Amazon. Most importantly you can set your shipping plans to
                  see what the best options there are for you when sending
                  goods, in order to save money on transportation.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="right-section large-img">
        <div className="section-outer">
          <div className="support-screen f-right hidden-xs">
            {/* <%= image_tag "removel-reimbursement.svg" %> */}
            <img src={RemovalScreen} alt="no-screen" />
          </div>
        </div>
        <div className="custom-container">
          <div className="text-section">
            <div className="support-text">
              <div className="heading-wrapper">
                <div className="heading-icon">
                  {/* <%= image_tag "removel-icon.svg" %> */}
                  <img src={RemovalIcon} alt="no-screen" />
                </div>
                <h2>Removal Reimbursements</h2>
              </div>
              <p>
                Removed goods on Amazon are often inevitable, so we want to make
                it as easy as possible for you. The more units you sell the more
                returns you are prone to which is a time consuming & costly
                issue. You can take photos, make the required claims and ship it
                back to Amazon to claim your reimbursement. With this tool, you
                can make the removal process much smoother and keep your margin
                higher than if you would have to do it on your own.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="pro-sold-section">
        <div className="custom-container">
          <a className="sold-btn">
            64M products sold
          </a>
          <div className="amazon-section">
            <div className="kick-amazon">
              Ready to kick your Amazon Warehouse business into high gear?
            </div>
            <Link to={token ? '/catalog-jobs' : '/auth/sign-up'}>
              <div className="try">
                <button type="button" className="btn btn-wrapper">
                  Try Now
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="custom-container-footer">
        <footer className="d-flex justify-conent-center">
          <div className="footer-link justify-content-center w-100">
            <a href="/terms" className="contact-link">Terms - Privacy policy - Refund policy</a>
          </div>
        </footer>
      </div>
    </HomePageWrapper>
  );
};
export default Index;
