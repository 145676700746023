import React, { useState, useEffect } from 'react'
import { object, string, ref, bool } from 'yup';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Logo from "../../assets/images/newImages/new_log.png";
import ProdImg from "../../assets/images/newImages/prod-img.svg";
import { AuthWrapper, PhoneNumberWrapper } from '../profile/style'
import { Form, Row, Col, Container } from "react-bootstrap"
import InfoVideo from '../profile/component/infoVideo'
import LabelInput from '../uiComponent/inputs/labelInput'
import PhoneInput from '../uiComponent/inputs/phoneNumberInput'
import CheckBox from '../uiComponent/checkBox'
import Button from '../uiComponent/button/index'
import { BiLeftArrowAlt } from "react-icons/bi";
import { AiFillEyeInvisible, AiFillEye, AiOutlineMenu } from "react-icons/ai";
import { SignUp as UserSignUp, setField } from "../../slices/auth";
import { isEmpty } from 'lodash';
import { MobileWrapper, Header, Content, Footer, Title, InputWrapper, CardDetail, ProductDetail, ScanFloating } from './mobile.styled';

const PickList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { pickUpLocationDetails } = useSelector(state => state?.internalStorage || {});

    const { items_count , location_type, staging_items, units_count, name } = pickUpLocationDetails || {};

    const [value, setValue] = useState()

    useEffect(() => {
      if (isEmpty(pickUpLocationDetails)) navigate('/scan-pick-location');
    }, [])


    return (
        <MobileWrapper className=''>
            <div className='mobile-page'>
                <div className='container-fluid px-0'>
                    <Content className=''>
                        <Title>
                            <h2>
                                <span
                                    className="icon-arrow-left cursor-pointer" onClick={() => navigate('/scan-pick-location')}></span>
                                Pick List</h2>
                        </Title>
                        <div className='content-inner'>
                        <CardDetail className='mb-card-single'>
                            <div className='card-details-head d-flex justify-content-between align-items-center'>
                                <h2>{name}</h2>
                                <span
                                    className=""></span>
                            </div>
                            <div className='card-details-content'>
                                {/* <p></p> */}
                                <ul className='d-flex'>
                                    {/* <li><span className='icon-carton'></span>{location_type}</li> */}
                                    <li><span className='icon-inventory'></span>Products<strong>{items_count}</strong></li>
                                    <li><span className='icon-total'></span>Units <strong>{units_count}</strong></li>
                                </ul>
                            </div>
                        </CardDetail>
                        { !isEmpty(staging_items)
                            ? 
                            staging_items.map((item ) => {
                              const { image_url, title, identifier, quantity, pick_up_quantity, hold_reason } = item;
                              return (
                                <ProductDetail>
                                <div class="d-flex align-items-center">
                                    <div>
                                        <img width="42" height="42" src={image_url} alt="no-product" />
                                    </div>
                                    <div>
                                        <span class="fs-14 color-secondary">{title}</span>
                                    </div>
                                </div>
                                <ul class="d-flex">
                                    <li class="">
                                        <span class="fs-12 d-block">Identifier</span>
                                        <span class="color-primary fs-14 fw-bold">{identifier}</span>
                                    </li>
                                    <li class="">
                                        <span class="fs-12 recent-grey d-block">Quantity</span>
                                        <span class="fs-14">{pick_up_quantity}</span>
                                    </li>
                                    <li class="">
                                        <span class="fs-12 recent-grey d-block">Destination</span>
                                        <span class="fs-14">{hold_reason || 'N/A'}</span>
                                    </li>
                                </ul>
                            </ProductDetail>
                              )
                            })                         
                            : 'No Item Found'
                        }
                        {/* <ProductDetail>
                            <div class="d-flex align-items-center">
                                <div>
                                    <img width="42" height="42" src={ProdImg} alt="no-product" />
                                </div>
                                <div>
                                    <span class="fs-14 color-secondary">Sliding Cellar Six Bottle In Cabinet Wine Rack
                                        Six Bottle In Cabinet Wine Rack....</span>
                                </div>
                            </div>
                            <ul class="d-flex">
                                <li class="">
                                    <span class="fs-12 d-block">ASIN</span>
                                    <span class="color-primary fs-14 fw-bold">B07NNTER97</span>
                                </li>
                                <li class="">
                                    <span class="fs-12 recent-grey d-block">Quantity</span>
                                    <span class="fs-14">115</span>
                                </li>
                            </ul>

                        </ProductDetail>
                        <ProductDetail>
                            <div class="d-flex align-items-center">
                                <div>
                                    <img width="42" height="42" src={ProdImg} alt="no-product" />
                                </div>
                                <div>
                                    <span class="fs-14 color-secondary">Sliding Cellar Six Bottle In Cabinet Wine Rack
                                        Six Bottle In Cabinet Wine Rack....</span>
                                </div>
                            </div>
                            <ul class="d-flex">
                                <li class="">
                                    <span class="fs-12 d-block">ASIN</span>
                                    <span class="color-primary fs-14 fw-bold">B07NNTER97</span>
                                </li>
                                <li class="">
                                    <span class="fs-12 recent-grey d-block">Quantity</span>
                                    <span class="fs-14">115</span>
                                </li>
                            </ul>

                        </ProductDetail>
                        <ProductDetail>
                            <div class="d-flex align-items-center">
                                <div>
                                    <img width="42" height="42" src={ProdImg} alt="no-product" />
                                </div>
                                <div>
                                    <span class="fs-14 color-secondary">Sliding Cellar Six Bottle In Cabinet Wine Rack
                                        Six Bottle In Cabinet Wine Rack....</span>
                                </div>
                            </div>
                            <ul class="d-flex">
                                <li class="">
                                    <span class="fs-12 d-block">ASIN</span>
                                    <span class="color-primary fs-14 fw-bold">B07NNTER97</span>
                                </li>
                                <li class="">
                                    <span class="fs-12 recent-grey d-block">Quantity</span>
                                    <span class="fs-14">115</span>
                                </li>
                            </ul>

                        </ProductDetail> */}
                        </div>
                    </Content>
                    <Footer>
                        <Button className="outlined btn-block" text="Go Back to Locations" onClick={() => navigate('/pick-list-locations')} />
                    </Footer>
                    <ScanFloating>
                        <span className='icon-barcode cursor-pointer' onClick={() => navigate('/scan-pick-products')}></span>
                    </ScanFloating>
                </div>
            </div>
        </MobileWrapper>
    )
}

export default PickList;
